export default (theme) => ({
  root: {},
  portletFooter: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  caption: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 3,
  },
  captionTitle: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 3,
  },
  field: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
});
