export default (theme) => ({
  clipMenuForm: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    minWidth: "300px",
  },
  clipMenuButton: {
    width: "fit-content",
    marginLeft: "auto",
  },
});
