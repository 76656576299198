import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";

import { Typography, Tooltip } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab";
import { Help } from "@material-ui/icons";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import ReactGA from "react-ga";
import { gaCategoryDashboard } from "helpers/gaUtil";

import { toneToValue } from "helpers/meetingTone";
import { wpmCutoffs } from "helpers/meetingPacing";
import { dateFormatter, getDateRangeString } from "helpers/time";
import theme from "theme";

const rangeFormat = { month: "2-digit", day: "2-digit" };

const tabs = {
  sentiment: "sentiment",
  energy: "energy",
  fillers: "fillers",
  tone: "tone",
  pacing: "pacing",
};

const energyYOffset = 55;
const fillerYMax = 17.5;

class Analytics extends Component {
  state = {
    analyticsTab: tabs.sentiment,
    sentimentData: [],
    energyData: [],
    fillersData: [],
    toneData: [],
    pacingData: [],
  };
  timeSpentHover = new Date();

  componentDidMount() {
    const sentimentData = [];
    const energyData = [];
    const fillersData = [];
    const toneData = [];
    const pacingData = [];
    const numMeetings = this.props.meetings.length;

    let userEmail = localStorage.getItem("email");
    if (this.props.memberEmail) {
      userEmail = this.props.memberEmail;
    }

    for (let i = 0; i < numMeetings; i++) {
      const meeting = this.props.meetings[numMeetings - i - 1];
      const participant = meeting.participants_details.find(
        (participant) => participant.user_email === userEmail
      );
      if (!participant) {
        continue;
      }
      const speakerTag = participant.speaker_tag;

      // Sentiment data
      if (
        meeting.transcript_results.length > 0 &&
        meeting.transcript_results[0].polarity != null
      ) {
        let polarity = 0;
        let numResults = 0;
        for (const transcriptResult of meeting.transcript_results) {
          if (transcriptResult.speaker_tag === speakerTag) {
            polarity += transcriptResult.polarity;
            numResults++;
          }
        }
        sentimentData.push({
          x: `${meeting.title} - ${dateFormatter(meeting.start_time)}`,
          y: numResults > 0 ? polarity / numResults : 0,
          uuid: meeting.uuid,
          firstHalf: i < numMeetings / 2,
        });
      }

      // Energy data
      if (meeting.analytics && meeting.analytics.intensity_level) {
        const energyLevelObj = meeting.analytics.intensity_level.find(
          (i) => i.speaker_tag === speakerTag
        );
        if (energyLevelObj) {
          const energyLevel = energyLevelObj.intensity_level;
          energyData.push({
            meeting: `${meeting.title} - ${dateFormatter(meeting.start_time)}`,
            energy: Math.max(energyLevel - energyYOffset, 0),
            uuid: meeting.uuid,
          });
        }
      }

      // Fillers data
      if (meeting.analytics && meeting.analytics.fillers) {
        const fillersObj = meeting.analytics.fillers.find(
          (f) => f.speaker_tag === speakerTag
        );
        if (fillersObj) {
          const fillers = fillersObj.fillers;
          let totalFillers = 0;
          for (const filler of fillers) {
            totalFillers += filler.count;
          }
          const speakingDuration = this.getSpeakingDuration(
            meeting.transcript_results,
            speakerTag
          );
          fillersData.push({
            meeting: `${meeting.title} - ${dateFormatter(meeting.start_time)}`,
            fillersPerMin:
              speakingDuration > 0 ? (totalFillers / speakingDuration) * 60 : 0,
            uuid: meeting.uuid,
          });
        }
      }

      // Tone data
      if (meeting.analytics && meeting.analytics.tone) {
        const toneObj = meeting.analytics.tone.find(
          (t) => t.speaker_tag === speakerTag
        );
        if (toneObj) {
          toneData.push({
            x: `${meeting.title} - ${dateFormatter(meeting.start_time)}`,
            y: toneToValue(toneObj.tone),
            uuid: meeting.uuid,
            firstHalf: i < numMeetings / 2,
          });
        }
      }

      // Pacing data
      if (meeting.analytics && meeting.analytics.words_per_min) {
        const pacingObj = meeting.analytics.words_per_min[speakerTag];
        if (pacingObj) {
          const wpm =
            pacingObj.duration > 0
              ? (pacingObj.word_count / pacingObj.duration) * 60
              : 0;
          pacingData.push({
            x: `${meeting.title} - ${dateFormatter(meeting.start_time)}`,
            y: wpm,
            uuid: meeting.uuid,
            firstHalf: i < numMeetings / 2,
          });
        }
      }
    }

    this.setState({
      sentimentData,
      energyData,
      fillersData,
      toneData,
      pacingData,
    });
  }

  getSpeakingDuration = (transcriptResults, speakerTag) => {
    let currentSpeaker = 0;
    let startTimestamp = 0;
    let duration = 0;

    transcriptResults.forEach((result, idx) => {
      if (idx === 0) {
        currentSpeaker = result.speaker_tag;
        startTimestamp = result.timestamp;
      } else if (
        currentSpeaker !== result.speaker_tag ||
        idx === transcriptResults.length - 1
      ) {
        if (currentSpeaker === speakerTag) {
          duration += result.timestamp - startTimestamp;
        }
        currentSpeaker = result.speaker_tag;
        startTimestamp = result.timestamp;
      }
    });

    return duration;
  };

  handleTabChange = (tab) => {
    this.setState({ analyticsTab: tab });
  };

  handleOnClickSentiment = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Sentiment Tab",
      label: "Analytics",
    });
  };

  handleOnClickEnergy = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Energy Tab",
      label: "Analytics",
    });
  };

  handleOnClickFillers = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Fillers Tab",
      label: "Analytics",
    });
  };

  handleOnClickTone = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Tone Tab",
      label: "Analytics",
    });
  };

  handleOnClickPacing = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Pacing Tab",
      label: "Analytics",
    });
  };

  handleOnMouseEnter = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeave = (action) => {
    let timeSpent = new Date() - this.timeSpentHover;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: action,
      value: timeSpent,
    });
  };

  shortenedFormat = (maxLength) => (label) => {
    let idx = label.lastIndexOf(" - ");
    if (idx !== -1) {
      label = label.slice(0, idx);
    }
    if (label.length > maxLength) {
      return label.slice(0, maxLength) + "...";
    } else {
      return label;
    }
  };

  renderSentiment = () => {
    const { classes, startDate, endDate } = this.props;
    let { sentimentData } = this.state;
    const isEmpty = sentimentData.length === 0;
    const rangeSpecified = startDate && endDate;

    // Adjust the shape of data to work with the line graph
    sentimentData = [{ id: "data", data: sentimentData }];

    return (
      <>
        <div
          className={classes.graphWrapper + (isEmpty ? " isEmpty" : "")}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={() =>
            this.handleOnMouseLeave("Sentiment Graph Hovered")
          }
        >
          <ResponsiveLine
            data={sentimentData}
            yScale={{
              type: "linear",
              min: -1,
              max: 1,
            }}
            margin={{ top: 10, right: 40, bottom: 40, left: 60 }}
            xScale={{ type: "point" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickRotation: 0,
              tickPadding: 10,
              legend: "Meetings",
              legendPosition: "middle",
              legendOffset: 20,
              format: () => "",
            }}
            axisLeft={{
              tickValues: [-1, 0, 1],
              format: (value) => {
                if (value === 1) {
                  return "Positive";
                } else if (value === 0) {
                  return "Neutral";
                } else if (value === -1) {
                  return "Negative";
                }
              },
              legendPosition: "middle",
            }}
            gridYValues={[-1, -0.25, -0.5, -0.75, 0, 0.25, 0.5, 0.75, 1]}
            colors={theme.palette.nivoDark2[4]}
            pointSize={3}
            pointColor={{ theme: "background" }}
            pointBorderWidth={1}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            enableSlices={false}
            useMesh={true}
            crosshairType="bottom"
            tooltip={(e) => {
              let meetingTitle = e.point.data.x;
              let endIdx = meetingTitle.lastIndexOf(" - ");
              if (endIdx !== -1) {
                meetingTitle = meetingTitle.slice(0, endIdx);
              }
              const style = {
                top: e.point.data.y > 0 && 20,
                bottom: e.point.data.y <= 0 && 20,
                left: e.point.data.firstHalf && 0,
                right: !e.point.data.firstHalf && 0,
              };
              return (
                <div className={this.props.classes.tooltip} style={style}>
                  <strong>{meetingTitle}</strong>
                </div>
              );
            }}
            onClick={(point) => {
              ReactGA.event({
                category: gaCategoryDashboard(),
                action: "Sentiment graph clicked",
              });
              this.props.handleNavigateToMeeting(point.data.uuid);
            }}
          />
        </div>
        {isEmpty && (
          <Typography className={classes.emptyTextContainer}>
            <Alert severity="warning" className={classes.emptyText}>
              {rangeSpecified
                ? `There are no meetings ${getDateRangeString(
                    startDate,
                    endDate,
                    rangeFormat
                  )}.`
                : "Use Sonero in a meeting to see sentiment data."}
            </Alert>
          </Typography>
        )}
      </>
    );
  };

  renderEnergy = () => {
    const { classes, startDate, endDate } = this.props;
    const { energyData } = this.state;
    const isEmpty = energyData.length === 0;
    const rangeSpecified = startDate && endDate;

    return (
      <>
        <div
          className={classes.graphWrapper + (isEmpty ? " isEmpty" : "")}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={() => this.handleOnMouseLeave("Energy Graph Hovered")}
        >
          <ResponsiveBar
            data={energyData}
            keys={["energy"]}
            layout="vertical"
            groupMode="grouped"
            padding={0.5}
            innerPadding={25}
            margin={{ top: 10, right: 40, bottom: 40, left: 80 }}
            colors={theme.palette.nivoDark2[1]}
            colorBy="indexValue"
            indexBy="meeting"
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Meetings",
              legendPosition: "middle",
              legendOffset: 20,
              format: () => "",
            }}
            // The tick values (0, 5, 10, 15, 20) corresponds to the threshold values subtracted by energyYOffset
            axisLeft={{
              tickValues: [0, 5, 10, 15, 20],
              format: (value) => {
                if (value === 0) {
                  return "Very Low";
                } else if (value === 5) {
                  return "Low";
                } else if (value === 10) {
                  return "Moderate";
                } else if (value === 15) {
                  return "High";
                } else if (value === 20) {
                  return "Very High";
                } else {
                  return "";
                }
              },
            }}
            maxValue={20}
            enableGridX={true}
            enableGridY={true}
            gridXValues={[0]}
            gridYValues={[0, 5, 10, 15, 20]}
            label={() => ""}
            tooltip={(bar) => {
              let meetingTitle = bar.data.meeting;
              let endIdx = meetingTitle.lastIndexOf(" - ");
              if (endIdx !== -1) {
                meetingTitle = meetingTitle.slice(0, endIdx);
              }
              return (
                <div>
                  <strong>{meetingTitle}</strong>
                </div>
              );
            }}
            onClick={(bar) => {
              ReactGA.event({
                category: gaCategoryDashboard(),
                action: "Energy graph clicked",
              });
              this.props.handleNavigateToMeeting(bar.data.uuid);
            }}
          />
        </div>
        {isEmpty && (
          <Typography className={classes.emptyTextContainer}>
            <Alert severity="warning" className={classes.emptyText}>
              {rangeSpecified
                ? `There are no meetings ${getDateRangeString(
                    startDate,
                    endDate,
                    rangeFormat
                  )}.`
                : "Use Sonero in a meeting to see energy data."}
            </Alert>
          </Typography>
        )}
      </>
    );
  };

  renderFillers = () => {
    const { classes, startDate, endDate } = this.props;
    const { fillersData } = this.state;
    const isEmpty = fillersData.length === 0;
    const fillerCutoff = this.props.constants.filler_cutoff;
    const rangeSpecified = startDate && endDate;

    return (
      <>
        <div
          className={classes.graphWrapper + (isEmpty ? " isEmpty" : "")}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={() => this.handleOnMouseLeave("Fillers Graph Hovered")}
        >
          <ResponsiveBar
            data={fillersData}
            keys={["fillersPerMin"]}
            layout="vertical"
            groupMode="grouped"
            padding={0.5}
            innerPadding={25}
            margin={{ top: 10, right: 40, bottom: 40, left: 60 }}
            colors={theme.palette.warning.main}
            colorBy="indexValue"
            indexBy="meeting"
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Meetings",
              legendPosition: "middle",
              legendOffset: 20,
              format: () => "",
            }}
            axisLeft={{
              tickValues: [fillerCutoff.low, fillerCutoff.moderate, fillerYMax],
              format: (value) => {
                if (value === fillerCutoff.low) {
                  return "Great";
                } else if (value === fillerCutoff.moderate) {
                  return "Good";
                } else if (value === fillerYMax) {
                  return "Poor";
                }
              },
            }}
            enableGridX={true}
            enableGridY={true}
            gridXValues={[0]}
            gridYValues={[
              0,
              fillerCutoff.low,
              fillerCutoff.moderate,
              fillerYMax,
            ]}
            maxValue={fillerYMax}
            label={() => ""}
            tooltip={(bar) => {
              let meetingTitle = bar.data.meeting;
              let endIdx = meetingTitle.lastIndexOf(" - ");
              if (endIdx !== -1) {
                meetingTitle = meetingTitle.slice(0, endIdx);
              }
              return (
                <div>
                  <strong>{meetingTitle}</strong>
                </div>
              );
            }}
            onClick={(bar) => {
              ReactGA.event({
                category: gaCategoryDashboard(),
                action: "Fillers graph clicked",
              });
              this.props.handleNavigateToMeeting(bar.data.uuid);
            }}
          />
        </div>
        {isEmpty && (
          <Typography className={classes.emptyTextContainer}>
            <Alert severity="warning" className={classes.emptyText}>
              {rangeSpecified
                ? `There are no meetings ${getDateRangeString(
                    startDate,
                    endDate,
                    rangeFormat
                  )}.`
                : "Use Sonero in a meeting to see filler data."}
            </Alert>
          </Typography>
        )}
      </>
    );
  };

  renderTone = () => {
    const { classes, startDate, endDate } = this.props;
    let { toneData } = this.state;
    const isEmpty = toneData.length === 0;
    const rangeSpecified = startDate && endDate;

    toneData = [{ id: "data", data: toneData }];

    return (
      <>
        <div
          className={classes.graphWrapper + (isEmpty ? " isEmpty" : "")}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={() => this.handleOnMouseLeave("Tone Graph Hovered")}
        >
          <ResponsiveLine
            data={toneData}
            yScale={{
              type: "linear",
              min: -1.25,
              max: 1.25,
            }}
            margin={{ top: 10, right: 40, bottom: 40, left: 90 }}
            xScale={{ type: "point" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickRotation: 10,
              tickPadding: 10,
              legend: "Meetings",
              legendPosition: "middle",
              legendOffset: 20,
              format: () => "",
            }}
            axisLeft={{
              tickValues: [-1, 0, 1],
              format: (value) => {
                if (value > 0) {
                  return "Passionate";
                } else if (value === 0) {
                  return "Conversational";
                } else {
                  return "Unvaried";
                }
              },
              legendPosition: "middle",
            }}
            gridYValues={[
              -1.25,
              -1,
              -0.75,
              -0.5,
              -0.25,
              0,
              0.25,
              0.5,
              0.75,
              1,
              1.25,
            ]}
            colors={theme.palette.nivoDark2[2]}
            pointSize={3}
            pointColor={{ theme: "background" }}
            pointBorderWidth={1}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            enableSlices={false}
            useMesh={true}
            crosshairType="bottom"
            tooltip={({ point }) => {
              let meetingTitle = point.data.x;
              let endIdx = meetingTitle.lastIndexOf(" - ");
              if (endIdx !== -1) {
                meetingTitle = meetingTitle.slice(0, endIdx);
              }
              const style = {
                top: point.data.y > 0 && 20,
                bottom: point.data.y <= 0 && 20,
                left: point.data.firstHalf && 0,
                right: !point.data.firstHalf && 0,
              };
              return (
                <div className={this.props.classes.tooltip} style={style}>
                  <strong>{meetingTitle}</strong>
                </div>
              );
            }}
            onClick={(point) => {
              ReactGA.event({
                category: gaCategoryDashboard(),
                action: "Tone graph clicked",
              });
              this.props.handleNavigateToMeeting(point.data.uuid);
            }}
          />
        </div>
        {isEmpty && (
          <Typography className={classes.emptyTextContainer}>
            <Alert severity="warning" className={classes.emptyText}>
              {rangeSpecified
                ? `There are no meetings ${getDateRangeString(
                    startDate,
                    endDate,
                    rangeFormat
                  )}.`
                : "Use Sonero in a meeting to see tone data."}
            </Alert>
          </Typography>
        )}
      </>
    );
  };

  renderPacing = () => {
    const { classes, startDate, endDate } = this.props;
    let { pacingData } = this.state;
    const isEmpty = pacingData.length === 0;
    const rangeSpecified = startDate && endDate;

    pacingData = [{ id: "data", data: pacingData }];
    return (
      <>
        <div
          className={classes.graphWrapper + (isEmpty ? " isEmpty" : "")}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={() => this.handleOnMouseLeave("Pacing Graph Hovered")}
        >
          <ResponsiveLine
            data={pacingData}
            yScale={{
              type: "linear",
              min: 0,
              max: 200,
            }}
            margin={{ top: 10, right: 40, bottom: 40, left: 100 }}
            xScale={{ type: "point" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickRotation: 10,
              tickPadding: 10,
              legend: "Meetings",
              legendPosition: "middle",
              legendOffset: 20,
              format: () => "",
            }}
            axisLeft={{
              tickValues: [0, 40, 80, 120, 160, 200],
              format: (value) => {
                if (value === wpmCutoffs.optimal) {
                  return `Optimal: ${value}`;
                } else if (value === wpmCutoffs.fast) {
                  return `Fast: ${value}`;
                } else {
                  return value;
                }
              },
              legend: "Words / Minute",
              legendOffset: -90,
              legendPosition: "middle",
            }}
            enableGridY={true}
            gridYValues={[0, 40, 80, 120, 160, 200]}
            colors={theme.palette.primary.cyan}
            pointSize={3}
            pointColor={{ theme: "background" }}
            pointBorderWidth={1}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            enableSlices={false}
            useMesh={true}
            crosshairType="bottom"
            tooltip={({ point }) => {
              let meetingTitle = point.data.x;
              let endIdx = meetingTitle.lastIndexOf(" - ");
              if (endIdx !== -1) {
                meetingTitle = meetingTitle.slice(0, endIdx);
              }
              const style = {
                top: point.data.y > 100 && 20,
                bottom: point.data.y <= 100 && 20,
                left: point.data.firstHalf && 0,
                right: !point.data.firstHalf && 0,
              };
              return (
                <div className={this.props.classes.tooltip} style={style}>
                  <strong>{meetingTitle}</strong>
                </div>
              );
            }}
            onClick={(point) => {
              ReactGA.event({
                category: gaCategoryDashboard(),
                action: "Pacing graph clicked",
              });
              this.props.handleNavigateToMeeting(point.data.uuid);
            }}
          />
        </div>
        {isEmpty && (
          <Typography className={classes.emptyTextContainer}>
            <Alert severity="warning" className={classes.emptyText}>
              {rangeSpecified
                ? `There are no meetings ${getDateRangeString(
                    startDate,
                    endDate,
                    rangeFormat
                  )}.`
                : "Use Sonero in a meeting to see pacing data."}
            </Alert>
          </Typography>
        )}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const { analyticsTab, sentimentData } = this.state;
    const numMeetings = sentimentData.length;

    return (
      <>
        <header className={classes.sectionHeader}>
          ANALYTICS
          <Tooltip
            title={`Your meeting analytics are based off of your behaviour in your last ${numMeetings} meetings`}
          >
            <Help />
          </Tooltip>
        </header>
        <div className={classes.analyticsContent}>
          <ToggleButtonGroup
            value={analyticsTab}
            onChange={(_event, newValue) => this.handleTabChange(newValue)}
            exclusive
          >
            <ToggleButton
              value={tabs.sentiment}
              className={classes.tabButton}
              classes={{ selected: classes.selected }}
              disabled={analyticsTab === tabs.sentiment}
              onClick={this.handleOnClickSentiment}
            >
              SENTIMENT
            </ToggleButton>

            <ToggleButton
              value={tabs.energy}
              className={classes.tabButton}
              classes={{ selected: classes.selected }}
              disabled={analyticsTab === tabs.energy}
              onClick={this.handleOnClickEnergy}
            >
              ENERGY
            </ToggleButton>

            <ToggleButton
              value={tabs.fillers}
              className={classes.tabButton}
              classes={{ selected: classes.selected }}
              disabled={analyticsTab === tabs.fillers}
              onClick={this.handleOnClickFillers}
            >
              FILLERS
            </ToggleButton>

            <ToggleButton
              value={tabs.tone}
              className={classes.tabButton}
              classes={{ selected: classes.selected }}
              disabled={analyticsTab === tabs.tone}
              onClick={this.handleOnClickTone}
            >
              TONE
            </ToggleButton>

            <ToggleButton
              value={tabs.pacing}
              className={classes.tabButton}
              classes={{ selected: classes.selected }}
              disabled={analyticsTab === tabs.pacing}
              onClick={this.handleOnClickPacing}
            >
              PACING
            </ToggleButton>
          </ToggleButtonGroup>

          <div className={classes.tabContent}>
            {analyticsTab === tabs.sentiment && this.renderSentiment()}
            {analyticsTab === tabs.energy && this.renderEnergy()}
            {analyticsTab === tabs.fillers && this.renderFillers()}
            {analyticsTab === tabs.tone && this.renderTone()}
            {analyticsTab === tabs.pacing && this.renderPacing()}
          </div>
        </div>
      </>
    );
  }
}

Analytics.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Analytics);
