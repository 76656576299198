import { fade } from "@material-ui/core";
export default (theme) => ({
  trackerContent: {
    maxHeight: "400px",
    overflow: "auto",
  },
  emptyText: {
    textAlign: "center",
    padding: theme.spacing(8),
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  keywordSection: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${fade(theme.palette.border, 0.5)}`,
    "&:nth-child(even)": {
      backgroundColor: theme.palette.background.lightGrey,
    },
  },
  keywordHeader: {
    marginBottom: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  keywordLabel: {
    fontSize: "20px",
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  keywordCount: {
    fontSize: "12px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    display: "grid",
    placeItems: "center",
  },
  keywordContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.5),
    position: "relative",
  },
  keywordInstance: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    cursor: "pointer",
  },
  keywordTimestamp: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    width: "40px",
  },
  keywordSentence: {
    width: "100%",
  },
  scrollTip: {
    width: "100%",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    textAlign: "center",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    borderTop: `1px solid ${theme.palette.border}`,
  },
});
