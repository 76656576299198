export default theme => ({
  root: {
      padding: theme.spacing.unit * 3
  },
  refreshButton: {
    margin: -theme.spacing.unit * 2
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit 
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  difference: {
    alignItems: 'center',
    color: theme.palette.danger.dark,
    display: 'inline-flex',
    fontWeight: 700
  },
  phrase: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    fontWeight: 600,
    display: "flex"
  },
  edit: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 600,
    display: "flex",
  },
  list: {
    listStyleType: "circle",
    marginLeft: theme.spacing.unit,
  },
  editIcons: {
    marginLeft: "auto",
    display: "flex"
  },
  changeIcons: {
    marginLeft: theme.spacing.unit * 3,
  },
  sentencebox: {
    display: "flex",
    width: "100%",
  },
  editSentence: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    marginLeft: theme.spacing.unit * 3,
    width: "80%",
    height: "auto",
    resize: "none",
    padding: theme.spacing.unit,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
  answer: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2,
  },
  specificAnswer: {
    color: theme.palette.primary.brown,
    fontWeight: 600,
    cursor: "pointer",
  },
});
  