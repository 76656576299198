// Given an array of transcript results, return the corresponding array of transcript segments
// The transcript segments is the transcript results grouped by speaker
export const getTranscriptSegments = (transcriptResults) => {
  const transcriptSegments = [];
  let currentSpeakerTag = transcriptResults[0].speaker_tag;
  let currentTranscript = [];
  transcriptResults.forEach((result, index) => {
    if (currentSpeakerTag === result.speaker_tag) {
      currentTranscript.push(result);
    } else {
      transcriptSegments.push({
        speaker: currentSpeakerTag,
        transcript: currentTranscript,
      });
      currentSpeakerTag = result.speaker_tag;
      currentTranscript = [];
      currentTranscript.push(result);
    }

    if (index === transcriptResults.length - 1) {
      transcriptSegments.push({
        speaker: currentSpeakerTag,
        transcript: currentTranscript,
      });
    }
  });
  return transcriptSegments;
};

export const stringifyTimeRange = (startTime, endTime, prefix) => {
  let timeRange = "";
  for (let i = startTime; i < endTime; i++) {
    timeRange += ` ${prefix}${i}`;
  }
  return timeRange;
};
