import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import { meetingParticipants } from "helpers";

// Material helpers
import {
  Paper,
  Popover,
  MenuItem,
  Snackbar,
  withStyles,
} from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

import { joinAdHocMeeting } from "services/speech";

import ReactGA from "react-ga";

// Component styles
import styles from "./styles";

let invalidLinkText =
  "This meeting link is invalid. Please enter a valid meeting link";
let exceededQuotaText =
  "You've exceeded your monthly quota for meeting uploads";
let generalErrorText = "Something's gone wrong, please try again later";
class JoinAdHoc extends Component {
  state = {
    showJoinAdhocForm: false,
    fieldNameLink: "",
    fieldNameTitle: "",
    fieldNameTextError: "",
    snackbarOpen: false,
    snackbarMessage: "",
    anchorEl: null,
    numOfParticipants: 2,
    isJoining: false,
  };
  popperRef = React.createRef();

  handleChange = (id, text) => {
    if (id === "link") {
      this.setState({
        fieldNameLink: text,
        fieldNameTextError: "",
      });
    } else if (id === "title") {
      this.setState({
        fieldNameTitle: text,
        fieldNameTextError: "",
      });
    }
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  handleJoinMeeting = () => {
    this.setState({
      isJoining: true,
    });
    joinAdHocMeeting(
      this.state.fieldNameLink,
      this.state.fieldNameTitle,
      this.state.numOfParticipants
    ).then(
      function(response) {
        this.setState({
          isJoining: false,
        });
        if (response.status === 200) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage:
              "Your AI Assistant will join your meeting shortly. This may take a couple of minutes.",
            fieldNameTitle: "",
            fieldNameLink: "",
          });
          this.handleToggleFormContent();
          ReactGA.event({
            category: "Adhoc",
            action: "Bot Joined AdHoc Meeting",
          });
        } else if (response.status === 400) {
          this.setState({
            fieldNameTextError: invalidLinkText,
            fieldNameLink: "",
          });
        } else if (response.status === 403) {
          this.setState({
            fieldNameTextError: exceededQuotaText,
          });
        } else if (response.status === 429) {
          let snackbarErrorMessage = response.data.message
            ? response.data.message
            : "You've exceeded the amount of bots allowed at one time. Please try again soon.";
          this.setState({
            snackbarOpen: true,
            snackbarMessage: snackbarErrorMessage,
          });
          this.handleToggleFormContent();
          ReactGA.event({
            category: "Adhoc",
            action: "User Denied Access From Join AdHoc Meeting",
          });
        } else {
          ReactGA.event({
            category: "Failure",
            action: "Bot Failed To Join AdHoc Meeting",
          });
          this.setState({
            fieldNameTextError: generalErrorText,
          });
        }
        return response;
      }.bind(this)
    );
  };

  handleTextFieldEnter = (e) => {
    e.preventDefault();
    this.handleJoinMeeting();
  };

  handleClose = () => {
    this.setState({
      showJoinAdhocForm: false,
      fieldNameTextError: "",
    });
  };

  handleNumOfParticipantsChange = (value) => {
    this.setState({ numOfParticipants: value });
  };

  renderFormContent = () => {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    if (this.state.showJoinAdhocForm) {
      return (
        <Popover
          open={this.state.showJoinAdhocForm}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: { maxWidth: "30%", minWidth: "250px" },
          }}
          container={this.state.anchorEl.parentNode}
        >
          <Paper elevation={0}>
            <Portlet {...rest}>
              <PortletHeader>
                <PortletLabel title="Join Meeting" />
              </PortletHeader>
              <form
                autoComplete="off"
                noValidate
                onSubmit={(e) => this.handleTextFieldEnter(e)}
              >
                <PortletContent className={classes.content}>
                  <Typography className={classes.caption}>
                    <strong>Join a meeting in progress!</strong> Paste your
                    meeting details below and the Sonero AI Assistant will join
                    your call momentarily to provide insights.
                  </Typography>
                  <div className={classes.field}>
                    <TextField
                      className={classes.textField}
                      label="Meeting Title (Optional)"
                      margin="dense"
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      variant="outlined"
                      size="small"
                      value={this.state.fieldNameTitle}
                      id="title"
                      InputProps={{
                        classes: {
                          root: classes.textField,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelField,
                        },
                      }}
                    />
                  </div>
                  <TextField
                    className={classes.textField}
                    label="Meeting Link"
                    margin="dense"
                    onChange={(event) =>
                      this.handleChange(event.target.id, event.target.value)
                    }
                    required
                    variant="outlined"
                    size="small"
                    value={this.state.fieldNameLink}
                    id="link"
                    error={
                      this.state.fieldNameTextError &&
                      this.state.fieldNameTextError === invalidLinkText
                    }
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelField,
                      },
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    label="Number of Participants"
                    margin="dense"
                    onChange={(event) =>
                      this.handleNumOfParticipantsChange(event.target.value)
                    }
                    required
                    select
                    value={this.state.numOfParticipants}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.textField,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelField,
                      },
                    }}
                  >
                    {meetingParticipants.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {this.state.fieldNameTextError && (
                    <Typography className={classes.fieldError} variant="body2">
                      {this.state.fieldNameTextError}
                    </Typography>
                  )}
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                  <Button
                    className={classes.submitButton}
                    color="primary"
                    size="small"
                    variant="text"
                    type="submit"
                    disabled={this.state.isJoining}
                  >
                    Join Meeting Now
                  </Button>
                </PortletFooter>
              </form>
            </Portlet>
          </Paper>
        </Popover>
      );
    }
    return <></>;
  };

  handleToggleFormContent = () => {
    this.setState((prevState) => ({
      showJoinAdhocForm: !prevState.showJoinAdhocForm,
      anchorEl: this.popperRef.current,
    }));
  };

  renderSnackBar = () => {
    const { classes } = this.props;
    let vertical = "top";
    let horizontal = "center";

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar,
          }}
          message={this.state.snackbarMessage}
          action={
            <Button
              style={{ color: "white" }}
              size="small"
              onClick={this.handleSnackbarClose}
            >
              CLOSE
            </Button>
          }
        />
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button
          onClick={() => this.handleToggleFormContent()}
          ref={this.popperRef}
          color="primary"
          variant="outlined"
          size="small"
          className={classes.joinMeetingButton}
        >
          Join Meeting
        </Button>
        {this.renderFormContent()}
        {this.renderSnackBar()}
      </div>
    );
  }
}

JoinAdHoc.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JoinAdHoc);
