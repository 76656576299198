import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Typography, Divider, CircularProgress } from "@material-ui/core";
import { unsubscribeFromEmails, resubscribeToEmails } from "services/speech";

function UnsubscribeFromEmails(props) {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const unsubUuid = props.match.params.uuid;

  useEffect(() => {
    setIsLoading(true);
    unsubscribeFromEmails(unsubUuid).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        setIsSubscribed(false);
      } else {
        setError(
          "Sorry, we could not unsubscribe you at the moment. Please contact us or try again later."
        );
      }
    });
  }, []);

  const handleResubscribe = () => {
    setIsLoading(true);
    resubscribeToEmails(unsubUuid).then((response) => {
      setIsLoading(false);
      if (response.status === 200) {
        setIsSubscribed(true);
      } else {
        setError(
          "Sorry, we could not resubscribe you at the moment. Please contact us or try again later."
        );
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoWrapper}>
        <a href="https://sonero.ai/" target="_blank" rel="noopener noreferrer">
          <img
            alt="Sonero logo"
            height="100px"
            className={classes.logoImage}
            src="/sonero-static/images/logo_with_name.png"
          />
        </a>
      </div>
      <Divider className={classes.logoDivider} />
      <div className={classes.content}>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography className={classes.contentBody} color="error">
            {error}
          </Typography>
        ) : isSubscribed ? (
          <Typography className={classes.contentBody}>
            You have been resubscribed to Sonero emails.
          </Typography>
        ) : (
          <>
            <Typography className={classes.contentBody}>
              You have been unsubscribed from Sonero emails.
            </Typography>
            <Typography className={classes.contentFooter}>
              Didn't mean to unsubscribe?
              <span
                className={classes.resubscribeLink}
                onClick={handleResubscribe}
              >
                Resubscribe
              </span>
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(UnsubscribeFromEmails);
