import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, LinearProgress } from '@material-ui/core';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletContent
  } from 'components';

// Component styles
import styles from './styles';

class QuickLook extends Component {

    state = {
        pacing: "0",
        energy: "0",
        delivery: "0",
        fillers: "0",
        expressions: "0",
        overall: "0",
        pacingScore: null,
        energyScore: null,
        deliveryScore: null,
        fillersScore: null,
        expressionsScore: null,
        overallScore: null
    }

    componentWillMount() {
        const words_per_min = this.props.data.words_per_min;
        const fillers = this.props.data.fillers;
        const intensity = this.props.data.intensity;
        const delivery = this.props.data.delivery_tone;
        const emotions = this.props.data.emotions;
        const duration = this.props.data.duration;

        var fillerValues = fillers.map(function(e) {
            return e.count;
        })
        var totalFillers = fillerValues.reduce((a, b) => a + b, 0)
        var fillersPerMin = totalFillers

        if (duration > 0) {
            var duration_min = duration/60
            fillersPerMin = Math.round((totalFillers/duration_min)*10)/10
        }
        
        var newState = this.state
        var pacingPercent = 0
        var energyPercent = 0
        var deliveryPercent = 0
        var fillerPercent = 0
        var expressionPercent = 0
        var overallPercent = 0

        if (words_per_min === 0) {
            pacingPercent = 0
        } else if (words_per_min > 160 || words_per_min < 120) {
            pacingPercent = 50
        } else if (words_per_min >= 120 && words_per_min <= 160) {
            pacingPercent = 100
        }
        newState.pacingScore = Math.round(pacingPercent/10)
        newState.pacing = pacingPercent.toString()

        if (fillersPerMin === 0) {
            fillerPercent = 100
        } else if (fillersPerMin === 1) {
            fillerPercent = 66
        } else if (fillersPerMin > 1) {
            fillerPercent = 33
        }
        newState.fillersScore = Math.round(fillerPercent/10)
        newState.fillers = fillerPercent.toString()

        if (intensity === "low") {
            energyPercent = 33
        } else if (intensity === "moderate") {
            energyPercent = 66
        } else if (intensity === "high") {
            energyPercent = 100
        }
        newState.energyScore = Math.round(energyPercent/10)
        newState.energy = energyPercent.toString()

        if (delivery === "no_emotion") {
            deliveryPercent = 33
        } else if (delivery === "conversational") {
            deliveryPercent = 66
        } else if (delivery === "passionate") {
            deliveryPercent = 100
        }
        newState.deliveryScore = Math.round(deliveryPercent/10)
        newState.delivery = deliveryPercent.toString()

        if (this.props.data.is_meeting == false) {
            var emotionValues = emotions.map(function(e) {
                return e.percent;
            })
            var totalEmotions = emotionValues.reduce((a, b) => a + b, 0)
    
            var goodEmotions = emotions.filter(emotion => (emotion.type === "neutral" || emotion.type === "happy" || emotion.type === "surprised"))
            var goodEmotionValues = goodEmotions.map(function(e) {
                return e.percent;
            })
            var totalGoodEmotions = goodEmotionValues.reduce((a, b) => a + b, 0)
    
            if (totalEmotions > 0) {
                expressionPercent = (totalGoodEmotions/totalEmotions)*100
            }
            newState.expressionsScore = Math.floor(expressionPercent/10)
            newState.expressions = expressionPercent.toString()
        }

        if (this.props.data.is_meeting == false) {
            overallPercent = (pacingPercent + energyPercent + deliveryPercent + fillerPercent + expressionPercent)/5
        } else {
            overallPercent = (pacingPercent + energyPercent + deliveryPercent + fillerPercent)/4
        }

        newState.overall = overallPercent.toString()
        newState.overallScore = Math.floor(overallPercent/10)

        this.setState({newState})
    }

    renderFacialExpressions = () => {
        if (this.props.data.is_meeting == true) {
            return
        }
        const { classes, className, ...rest } = this.props;
        return (
            <div className={classes.resultContent}>
                    <div className={classes.resultScore}>
                        <Typography
                            className={classes.title}
                            variant="body2"
                            >
                            FACIAL EXPRESSIONS
                        </Typography>
                        <Typography
                            className={classes.score}
                            variant="body2"
                            >
                            {this.state.expressionsScore}
                        </Typography>
                    </div>
                    <LinearProgress
                        className={classes.progress}
                        value={this.state.expressions}
                        variant="determinate"
                    />
                </div>
        )
    }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
        <Portlet
          {...rest}
          className={rootClassName}
        >
          <PortletHeader>
            <PortletLabel title="Delivery Scorecard" />
          </PortletHeader>
          <PortletContent className={classes.content}>
            <div className={classes.contentBody}>
                <div className={classes.resultContent}>
                    <div className={classes.resultScore}>
                        <Typography
                            className={classes.title}
                            variant="body2"
                            >
                            PACING
                        </Typography>
                        <Typography
                            className={classes.score}
                            variant="body2"
                            >
                            {this.state.pacingScore}
                        </Typography>
                    </div>
                    <LinearProgress
                        className={classes.progress}
                        value={this.state.pacing}
                        variant="determinate"
                    />
                </div>
                <div className={classes.resultContent}>
                    <div className={classes.resultScore}>
                        <Typography
                            className={classes.title}
                            variant="body2"
                            >
                            ENERGY
                        </Typography>
                        <Typography
                            className={classes.score}
                            variant="body2"
                            >
                            {this.state.energyScore}
                        </Typography>
                    </div>
                    <LinearProgress
                        className={classes.progress}
                        value={this.state.energy}
                        variant="determinate"
                    />
                </div>
                <div className={classes.resultContent}>
                    <div className={classes.resultScore}>
                        <Typography
                            className={classes.title}
                            variant="body2"
                            >
                            DELIVERY
                        </Typography>
                        <Typography
                            className={classes.score}
                            variant="body2"
                            >
                            {this.state.deliveryScore}
                        </Typography>
                    </div>
                    <LinearProgress
                        className={classes.progress}
                        value={this.state.delivery}
                        variant="determinate"
                    />
                </div>
                <div className={classes.resultContent}>
                    <div className={classes.resultScore}>
                        <Typography
                            className={classes.title}
                            variant="body2"
                            >
                            FILLERS
                        </Typography>
                        <Typography
                            className={classes.score}
                            variant="body2"
                            >
                            {this.state.fillersScore}
                        </Typography>
                    </div>
                    <LinearProgress
                        className={classes.progress}
                        value={this.state.fillers}
                        variant="determinate"
                    />
                </div>
                {this.renderFacialExpressions()}
                <div className={classes.resultContent}>
                    <div className={classes.resultScore}>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            >
                            OVERALL
                        </Typography>
                        <Typography
                            className={classes.score}
                            variant="h6"
                            >
                            {this.state.overallScore}
                        </Typography>
                    </div>
                    <LinearProgress
                        className={classes.progress}
                        value={this.state.overall}
                        variant="determinate"
                    />
                </div>
            </div>
          </PortletContent>
        </Portlet>
      );
  }
}

QuickLook.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(QuickLook);
