import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  sectionHeader: {
    padding: theme.spacing.unit * 2,
    fontWeight: 700,
    background: theme.palette.background.blue,
    color: theme.palette.text.dark,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
  },
  analyticsContent: {
    padding: theme.spacing.unit * 1.5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing.unit * 3,
    height: "calc(100% - 54px)",
  },
  tabButton: {
    color: theme.palette.primary.main,
    padding: "8px",
    fontSize: "12px",
  },
  tabContent: {
    height: "240px",
    width: "100%",
    position: "relative",
  },
  selected: {
    "&&": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
  tooltip: {
    background: theme.palette.common.white,
    padding: theme.spacing.unit,
    borderRadius: "3px",
    boxShadow: `0 3px 6px ${theme.palette.background.grey}`,
    position: "absolute",
    width: "200px",
  },
  graphWrapper: {
    height: "240px",
    "&.isEmpty": {
      opacity: "0.5",
    },
  },
  emptyTextContainer: {
    color: theme.palette.text.secondary,
    width: "100%",
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
  },
  emptyText: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    margin: "auto",
  },
});
