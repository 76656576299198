export const checkStatuses = {
  none: 0,
  checking: 1,
  valid: 2,
  invalid: 3,
};

export const getDiscountTooltip = (discount) => {
  let tooltip = "";
  if (discount.valid) {
    if (discount.percent_off != null) {
      tooltip += `${discount.percent_off}% off, `;
    }
    if (discount.amount_off != null) {
      tooltip += `$${discount.amount_off} off, `;
    }

    if (discount.duration === "once") {
      tooltip += "one time";
    } else if (discount.duration === "repeating") {
      tooltip += `for ${discount.duration_in_months} months`;
    } else {
      tooltip = tooltip.slice(0, -2);
    }
  } else {
    tooltip = "Invalid discount code";
  }
  return tooltip;
};