export default (theme) => ({
  root: {},
  container: {
    height: "100%",
  },
  clipsHeader: {
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.elements.currentInsight.lightGrey,
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& *": {
      fontSize: "16px",
      color: theme.palette.text.grey,
      fontWeight: 700,
    },
  },
  clipMoreIcon: {
    color: theme.palette.elements.currentInsight.blue,
  },
  clipsList: {
    padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit * 4,
  },
  clip: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
  },
  clipHeader: {
    paddingLeft: theme.spacing.unit * 2,
    background: theme.palette.background.darkerLightGrey,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&.shared": {
      paddingBottom: theme.spacing.unit,
    },
  },
  clipTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  clipContent: {
    display: "flex",
    gap: theme.spacing.unit * 2,
    overflow: "visible",
    position: "relative",
  },
  clipContainer: {
    width: "45%",
  },
  thumbnailCanvas: {
    width: "100%",
    posiiton: "absolute",
  },
  clipSnippet: {
    maxHeight: "150px",
    overflow: "hidden",
    width: "55%",
  },
  snippetSpeaker: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    "&:first-child": {
      marginTop: 0,
    },
  },
  snippetBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    "& > p": {
      marginBottom: theme.spacing.unit,
      lineHeight: "1.5",
      cursor: "pointer",
    },
  },
  actionMenu: {
    padding: theme.spacing.unit * 0.5,
    minWidth: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  deleteIcon: {
    color: theme.palette.danger.mellow,
  },
  emptyView: {
    padding: theme.spacing.unit * 2,
    margin: `${theme.spacing.unit * 4}px 0`,
  },
  emptyText: {
    color: theme.palette.text.secondary,
    width: "fit-content",
    margin: "auto",
  },
});
