import React, { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import { Paper } from "components";
import { getSpeakerString } from "helpers/meetingParticipants";
import styles from "./styles";
import { ResponsiveTreeMap } from "@nivo/treemap";

class WordMap extends Component {
  wordMap = new Map();

  state = {
    showDetailedMap: false,
    interactive: true,
    data: {},
  };

  componentWillMount() {
    var transcript = this.props.data.transcript_results;
    this.props.data.freq_noun_phrases.forEach((phrase, _) => {
      this.wordMap.set(phrase, []);
    });
    this.props.data.general_summary.forEach((summary, _) => {
      this.wordMap.set(summary.topic, []);
    });
    var speakerSet = new Set();
    var currentSpeaker = 0;
    transcript.forEach((result, _) => {
      currentSpeaker = result.speaker_tag;
      speakerSet.add(currentSpeaker);
      for (let key of this.wordMap.keys()) {
        if (result.sentence.toLowerCase().indexOf(key) >= 0) {
          var tracker = this.wordMap.get(key);
          var found = false;
          tracker.forEach((value, index) => {
            if (value.speaker === currentSpeaker) {
              value.count++;
              tracker[index] = value;
              this.wordMap.set(key, tracker);
              found = true;
            }
          });
          if (!found) {
            tracker.push({ speaker: currentSpeaker, count: 1 });
            this.wordMap.set(key, tracker);
          }
        }
      }
    });

    this.setState({
      data: this.getTopicMapData(),
      interactive: speakerSet.size > 1,
    });
  }

  getTreeMapData() {
    var participantsDetails = this.props.data.participants_details;
    var output = [];
    for (let [key, value] of this.wordMap.entries()) {
      var children = value.map((x) => ({
        id: getSpeakerString(x.speaker, participantsDetails),
        value: x.count,
      }));
      output.push({ id: key, children: children });
    }
    output = { id: this.props.data.title, children: [...output] };
    return output;
  }

  getTopicMapData() {
    var output = [];
    for (let [key, value] of this.wordMap.entries()) {
      var total = 0;
      value.forEach((child, _) => {
        total = total + child.count;
      });
      output.push({ id: key, value: total });
    }
    output = { id: this.props.data.title, children: [...output] };
    return output;
  }

  getDetailedMapData = (topic) => {
    var participantsDetails = this.props.data.participants_details;
    var value = this.wordMap.get(topic);
    var children = value.map((x) => ({
      id: getSpeakerString(x.speaker, participantsDetails),
      value: x.count,
    }));
    return { id: topic, children: children };
  };

  getMapLabel = (node) => {
    if (this.state.showDetailedMap) {
      var total = 0;
      this.state.data.children.forEach((child, _) => {
        total = total + child.value;
      });
      return (
        node.id + " (" + ("%.2f", (node.value / total) * 100).toFixed(0) + "%)"
      );
    } else {
      return node.id;
    }
  };

  getMapClassName = (classes) => {
    if (this.state.interactive) {
      return classes.interactiveContent;
    }
    return classes.content;
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <div>
        {
          <Paper {...rest} className={rootClassName}>
            <div className={classes.firstContent}>
              <div className={classes.iconWrapper}>
                <MapIcon className={classes.icon} />
              </div>
              <div className={classes.details}>
                <Typography className={classes.title} variant="h5">
                  Topic Map
                </Typography>
              </div>
            </div>
            <div className={this.getMapClassName(classes)}>
              <ResponsiveTreeMap
                data={this.state.data}
                colors={{ scheme: "category10" }}
                label={(e) => this.getMapLabel(e)}
                leavesOnly={!this.state.showDetailedMap}
                isInteractive={this.state.interactive}
                onClick={(node, _) => {
                  if (this.state.showDetailedMap) {
                    this.setState({
                      showDetailedMap: false,
                      data: this.getTopicMapData(),
                    });
                  } else {
                    this.setState({
                      showDetailedMap: true,
                      data: this.getDetailedMapData(node.id),
                    });
                  }
                }}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                labelSkipSize={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.2]] }}
                parentLabelTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                borderColor={{ from: "color", modifiers: [["darker", 0.1]] }}
              />
            </div>
          </Paper>
        }
      </div>
    );
  }
}

WordMap.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WordMap);
