import React, { useRef, useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { ClipPlayer } from "components";
import {
  Modal,
  Typography,
  Button,
  TextField,
  Collapse,
  Tooltip,
  IconButton,
  Box,
  TextareaAutosize,
} from "@material-ui/core";
import { Close, Edit, Delete, CheckCircle, Cancel } from "@material-ui/icons";

import { stringifyTimeRange } from "helpers/meetingTranscript";
import { getSpeakerString } from "helpers/meetingParticipants";
import { getTranscriptSegments } from "helpers/meetingTranscript";

import palette from "theme/palette";
import { convertMilisecsToHigherOrder } from "helpers/time";

function ViewClipModal(props) {
  const {
    classes,
    modalOpen,
    modalClipIndex,
    clips,
    data,
    handleCloseModal,
    handlePrevClip,
    handleNextClip,
    handleAddComment,
    handleUpdateComment,
    handleDeleteComment,
    handleUpdateReadComment,
    isSharedMeetingView,
  } = props;
  const clipVideo = useRef();
  const [clip, setClip] = useState({});
  const [clipSegments, setClipSegments] = useState([]);
  const [comment, setComment] = useState("");
  const [commentFocused, setCommentFocused] = useState(false);
  const [isEditing, setIsEditing] = useState([]);
  const [editingComment, setEditingComment] = useState({});
  const clipTopCommentSectionRef = useRef(null);

  useEffect(() => {
    const clip = clips[modalClipIndex];
    if (!clip) {
      setClip({});
      setClipSegments([]);
      return;
    }
    const transcriptResults = data.transcript_results;
    const startIdx = data.transcriptionResultsMap[clip.start_time.toString()];
    const endIdx =
      data.duration === +clip.end_time
        ? transcriptResults.length
        : data.transcriptionResultsMap[clip.end_time.toString()];
    if (startIdx === -1 || endIdx === -1) {
      setClip({});
      setClipSegments([]);
      return;
    }
    const slicedResults = transcriptResults.slice(startIdx, endIdx);

    // Annotate the transcript results with start and end time
    slicedResults.forEach((result, idx) => {
      result.start_time = Math.round(result.timestamp);
      if (idx + 1 < slicedResults.length) {
        result.end_time = Math.round(slicedResults[idx + 1].timestamp - 1);
      } else {
        result.end_time = Math.round(clip.end_time);
      }
    });

    clipSegments[clip.uuid] = getTranscriptSegments(slicedResults);
    setClipSegments(clipSegments);
    setClip(clip);
    setComment("");
    setCommentFocused(false);
    setIsEditing([]);
    setEditingComment({});
    handleMarkCommentsAsRead(clip.uuid);
  }, [clips, modalClipIndex]);

  useEffect(() => {
    handleResetCommentSectionScroll();
  });

  const handleResetCommentSectionScroll = () => {
    if (clipTopCommentSectionRef.current) {
      clipTopCommentSectionRef.current.scrollIntoView();
    }
  };

  const clipTimeUpdateCallback = (videoPlayer) => {
    let newSentence;
    let currentSentence;
    let currentTime;

    return () => {
      currentTime = Math.round(videoPlayer.currentTime);
      newSentence = document.querySelector(`.clip-t${currentTime}`);
      if (newSentence) {
        if (currentSentence) currentSentence.style.backgroundColor = "";
        currentSentence = newSentence;
        currentSentence.style.backgroundColor = palette.highlight;
      }
    };
  };

  const handleCommentFocus = (focused) => {
    setCommentFocused(focused);
  };

  const handleCommentChange = (value) => {
    setComment(value);
  };

  const handleCommentCancel = () => {
    handleCommentFocus(false);
    setComment("");
  };

  const handleConfirmAddComment = () => {
    handleAddComment(clip.uuid, comment).then((response) => {
      if (response.status === 200) {
        setComment("");
      }
    });
  };

  const handleCheckEnterConfirmAddComment = (event) => {
    if (event.key === "Enter") {
      handleConfirmAddComment();
      handleCommentFocus(false);
    }
  };

  const handleEditComment = (comment) => {
    let isEditingTemp = [...isEditing];
    let editingCommentTemp = { ...editingComment };
    if (!isEditingTemp.includes(comment.uuid)) {
      isEditingTemp.push(comment.uuid);
    }
    if (!(comment.uuid in editingCommentTemp)) {
      editingCommentTemp[comment.uuid] = comment.context;
    }
    setIsEditing(isEditingTemp);
    setEditingComment(editingCommentTemp);
  };

  const editCommentOnChange = (value, commentUuid) => {
    let editingCommentTemp = { ...editingComment };
    editingCommentTemp[commentUuid] = value;
    setEditingComment(editingCommentTemp);
  };

  const handleConfirmUpdateComment = (comment) => {
    if (editingComment[comment.uuid].trim() === comment.context) {
      exitEditComment(comment.uuid);
    } else {
      handleUpdateComment(
        clip.uuid,
        comment.uuid,
        editingComment[comment.uuid]
      ).then((response) => {
        if (response.status === 200) {
          exitEditComment(comment.uuid);
        }
      });
    }
  };

  const exitEditComment = (commentUuid) => {
    let isEditingTemp = [...isEditing];
    let editingCommentTemp = { ...editingComment };
    let index = isEditingTemp.indexOf(commentUuid);
    if (index !== -1) {
      isEditingTemp.splice(index, 1);
    }
    delete editingCommentTemp[commentUuid];
    setIsEditing(isEditingTemp);
    setEditingComment(editingCommentTemp);
  };

  const handleConfirmDeleteComment = (clipUuid, commentUuid) => {
    handleDeleteComment(clipUuid, commentUuid);
  };

  const handleMarkCommentsAsRead = (clipUuid) => {
    if (handleUpdateReadComment) {
      handleUpdateReadComment(clipUuid);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={modalOpen}
      onClose={handleCloseModal}
    >
      <div className={classes.modalContent}>
        <Typography className={classes.modalHeader}>
          {clip && clip.name ? clip.name : `Clip ${modalClipIndex + 1}`}
          <Close className={classes.closeIcon} onClick={handleCloseModal} />
        </Typography>
        <div
          className={
            !isSharedMeetingView
              ? classes.modalBody
              : classes.modalBodySharedView
          }
        >
          <div className={classes.modalVideoContainer}>
            <ClipPlayer
              url={data.recording_url}
              startTime={clip ? clip.start_time : 0}
              endTime={clip ? clip.end_time : 0}
              timeUpdateCallback={clipTimeUpdateCallback}
              ref={clipVideo}
              length={clip ? clip.end_time - clip.start_time : 0}
            />
          </div>
          {clip && clipSegments[clip.uuid] && (
            <div className={classes.modalTranscriptContainer}>
              <div className={classes.modalTranscriptSnippet}>
                {clipSegments[clip.uuid].map((segment) => (
                  <>
                    <Typography variant="h6" className={classes.snippetSpeaker}>
                      {getSpeakerString(
                        segment.speaker,
                        data.participants_details
                      )}
                    </Typography>
                    <Typography variant="body1" className={classes.snippetBody}>
                      {segment.transcript.map((transcriptSentence, i) => (
                        <p
                          key={i}
                          className={stringifyTimeRange(
                            transcriptSentence.start_time,
                            transcriptSentence.end_time,
                            "clip-t"
                          )}
                          onClick={() => {
                            if (clipVideo.current)
                              clipVideo.current.seekTo(
                                transcriptSentence.start_time
                              );
                          }}
                        >
                          {transcriptSentence.sentence}
                        </p>
                      ))}
                    </Typography>
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={classes.modalSpaceDivision} />
        {!isSharedMeetingView && (
          <div className={classes.modalCommentSection}>
            <div ref={clipTopCommentSectionRef} />
            <div className={classes.modalAddComment}>
              <TextField
                className={classes.modalCommentInput}
                placeholder="Add comment"
                variant="standard"
                value={comment}
                InputProps={{
                  className: classes.modalCommentInputField,
                }}
                onFocus={() => handleCommentFocus(true)}
                onBlur={() => handleCommentFocus(false)}
                onChange={(event) => handleCommentChange(event.target.value)}
                onKeyPress={handleCheckEnterConfirmAddComment}
              />
              <div className={classes.modalCommentButtons}>
                <Collapse in={commentFocused}>
                  <div className={classes.modalCommentButtonsWrapper}>
                    <Button
                      style={{
                        color: palette.text.lightGrey,
                      }}
                      variant="text"
                      onClick={handleCommentCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleConfirmAddComment}
                    >
                      Comment
                    </Button>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={classes.modalCommentList}>
              {clip.clip_comments &&
                [...clip.clip_comments].reverse().map((comment) => (
                  <div className={classes.modalCommentContainer}>
                    <Typography
                      variant="h6"
                      className={classes.modalCommentHeader}
                    >
                      <span className={classes.modalCommentName}>
                        {comment.user.first_name +
                          " " +
                          comment.user.last_name +
                          " "}
                      </span>
                      <span className={classes.modalCommentTimestamp}>
                        {convertMilisecsToHigherOrder(
                          Math.abs(new Date() - new Date(comment.created))
                        )}{" "}
                        ago
                      </span>
                      {comment.edited && (
                        <span className={classes.modalCommentEditedStatus}>
                          (edited)
                        </span>
                      )}
                    </Typography>
                    {isEditing.includes(comment.uuid) ? (
                      <div className={classes.modalCommentContextContainer}>
                        <Box className={classes.modalCommentEditContext}>
                          <TextareaAutosize
                            className={classes.modalCommentEditContextField}
                            value={editingComment[comment.uuid]}
                            onChange={(event) =>
                              editCommentOnChange(
                                event.target.value,
                                comment.uuid
                              )
                            }
                            autoFocus
                          />
                        </Box>
                        <div className={classes.modalCommentContextButtons}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => handleConfirmUpdateComment(comment)}
                          >
                            <CheckCircle color="primary" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => exitEditComment(comment.uuid)}
                          >
                            <Cancel color="primary" />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.modalCommentContextContainer}>
                        <Typography
                          variant="body1"
                          className={classes.modalCommentContext}
                        >
                          {comment.context}
                        </Typography>
                        {localStorage.getItem("email") ===
                          comment.user.email && (
                          <div className={classes.modalCommentContextButtons}>
                            <Tooltip title="Edit comment">
                              <IconButton
                                size="small"
                                onClick={() => handleEditComment(comment)}
                              >
                                <Edit className={classes.editIcon} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete comment">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleConfirmDeleteComment(
                                    clip.uuid,
                                    comment.uuid
                                  )
                                }
                              >
                                <Delete className={classes.deleteIcon} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className={classes.modalFooter}>
          <Button
            color="primary"
            onClick={() => {
              if (clipVideo.current) clipVideo.current.pause();
              handlePrevClip();
            }}
            disabled={modalClipIndex <= 0}
          >
            Previous Clip
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (clipVideo.current) clipVideo.current.pause();
              handleNextClip();
            }}
            disabled={modalClipIndex >= clips.length - 1}
          >
            Next Clip
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(ViewClipModal);
