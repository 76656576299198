import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Typography, Divider, CircularProgress } from "@material-ui/core";
import { optOutOfMeetingByParticipant } from "services/speech";
import { dateFormatter } from "helpers/time";

function DoNotJoin(props) {
  const { classes } = props;
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingStartTime, setMeetingStartTime] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const meetingUuid = props.match.params.uuid;
    const queryParams = new URLSearchParams(props.location.search);
    const participantId = queryParams.get("participant_id");

    optOutOfMeetingByParticipant(meetingUuid, participantId).then(
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setMeetingTitle(response.data.meeting_title);
          setMeetingStartTime(response.data.meeting_start_time);
        } else if (response.status === 404) {
          setError("Sorry, the requested meeting was not found.");
        } else {
          setError("Sorry, an error occurred. Please try again.");
        }
      }
    );
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.logoWrapper}>
        <a href="https://sonero.ai/" target="_blank" rel="noopener noreferrer">
          <img
            alt="Sonero logo"
            height="100px"
            className={classes.logoImage}
            src="/sonero-static/images/logo_with_name.png"
          />
        </a>
      </div>
      <Divider className={classes.logoDivider} />
      <div className={classes.content}>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography className={classes.contentBody} color="error">
            {error}
          </Typography>
        ) : (
          <>
            <Typography className={classes.contentBody}>
              The AI Assistant will not join <strong>{meetingTitle}</strong>
              {meetingStartTime ? ` on ${dateFormatter(meetingStartTime)}` : ""}
              .
            </Typography>
            <Typography className={classes.contentBody}>
              We've sent an email letting the owner know of this.
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(DoNotJoin);
