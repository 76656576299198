import { fade } from "@material-ui/core";

export default (theme) => ({
  clipTooltip: {
    background: theme.palette.background.blue,
    maxWidth: "300px",
    padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    overflow: "visible",
    position: "relative",
    "&::before": {
      content: `''`,
      position: "absolute",
      bottom: "-15px",
      left: "calc(50% - 15px)",
      width: 0,
      height: 0,
      border: "15px solid transparent",
      borderBottom: "none",
      borderTop: `15px solid ${theme.palette.background.blue}`,
    },
    "&::after": {
      content: `''`,
      position: "absolute",
      bottom: "-12px",
      left: "calc(50% - 9px)",
      width: "18px",
      height: "18px",
      transform: "rotate(45deg)",
      boxShadow: `0 0 5px 0 rgba(0, 0, 0, 0.5)`,
      zIndex: -1,
    },
  },
  shareTooltip: {
    background: theme.palette.background.blue,
    maxWidth: "300px",
    padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    "&::before": {
      content: `''`,
      position: "absolute",
      right: "50px",
      top: "-15px",
      width: 0,
      height: 0,
      border: "12px solid transparent",
      borderTop: "none",
      borderBottom: `15px solid ${theme.palette.background.blue}`,
    },
  },
  transcriptTooltip: {
    background: theme.palette.background.blue,
    maxWidth: "300px",
    padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    overflow: "visible",
    position: "relative",
    "&::before": {
      content: `''`,
      position: "absolute",
      right: "-15px",
      top: "calc(50% - 10px)",
      width: 0,
      height: 0,
      border: `12px solid transparent`,
      borderRight: "none",
      borderLeft: `15px solid ${theme.palette.background.blue}`,
    },
  },
  convoTooltip: {
    background: theme.palette.background.blue,
    maxWidth: "300px",
    padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    overflow: "visible",
    position: "relative",
    "&::before": {
      content: `''`,
      position: "absolute",
      left: "-15px",
      top: "calc(50% - 12px)",
      width: 0,
      height: 0,
      border: "12px solid transparent",
      borderLeft: "none",
      borderRight: `15px solid ${theme.palette.background.blue}`,
    },
  },
  trackerTooltip: {
    background: theme.palette.background.blue,
    maxWidth: "300px",
    padding: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    overflow: "visible",
    position: "relative",
    "&::before": {
      content: `''`,
      position: "absolute",
      left: "-15px",
      top: "calc(50% - 12px)",
      width: 0,
      height: 0,
      border: "12px solid transparent",
      borderLeft: "none",
      borderRight: `15px solid ${theme.palette.background.blue}`,
    },
  },
  tooltipButton: {
    marginLeft: "auto",
  },
  dimOverlay: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: fade(theme.palette.common.black, 0.5),
    zIndex: 10,
  },
});
