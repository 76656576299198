export default theme => ({
    root: {
        padding: theme.spacing.unit * 3
    },
    refreshButton: {
      margin: -theme.spacing.unit * 2
    },
    chartWrapper: {
      position: 'relative',
      height: '300px'
    },
    stats: {
      marginTop: theme.spacing.unit * 2,
      display: 'flex',
      justifyContent: 'center'
    },
    device: {
      textAlign: 'center',
      padding: theme.spacing.unit
    },
    deviceIcon: {
      color: theme.palette.common.neutral
    },
    content: {
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      marginLeft: theme.spacing.unit,
      color: theme.palette.text.secondary,
      fontWeight: 700
    },
    value: {
      marginTop: theme.spacing.unit,
      color: theme.palette.success.dark
    },
    iconWrapper: {
      alignItems: 'center',
      // backgroundColor: theme.palette.primary.cyan,
      borderRadius: '50%',
      display: 'inline-flex',
      height: '2rem',
      justifyContent: 'center',
      // marginLeft: 'auto',
      width: '2rem'
    },
    icon: {
      color: theme.palette.text.secondary,
      fontSize: '2rem',
      height: '2rem',
      width: '2rem'
    },
    footer: {
      alignItems: 'center'
    },
    difference: {
      alignItems: 'center',
      color: theme.palette.danger.dark,
      display: 'inline-flex',
      fontWeight: 700
    },
    actionItem: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing.unit * 4,
        marginLeft: theme.spacing.unit * 2,
        fontWeight: 600
    },
    phrase: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
    },
    frequentWords: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
      textTransform: 'capitalize'
    },
    pills: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing.unit * 2,
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    summaryTitle: {
      marginTop: theme.spacing.unit * 2,
      color: theme.palette.text.secondary,
    }
  });
  