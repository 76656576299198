import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import { pricingTiers } from "helpers";

import ReactGA from "react-ga";

class CreateOrg extends Component {
  state = {
    showError: false,
    orgName: "",
    openFreeTierDialog: false,
  };

  handleOrgName = (text) => {
    this.setState({ orgName: text });
  };

  validate = () => {
    if (this.state.orgName.trim()) {
      return true;
    }
    this.setState({ showError: true });
    return false;
  };

  handleSubmit = () => {
    if (this.validate()) {
      this.props.createOrg(this.state.orgName);
    }
  };

  handleCloseFreeTierDialog = () => {
    this.setState({
      openFreeTierDialog: false,
    });
  };

  renderFreeTierDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.openFreeTierDialog}
        onClose={() => this.handleCloseFreeTierDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">UPGRADE TO SONERO PRO</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please upgrade to Sonero Pro tier to create an organization and
            collaborate with your team members. You can upgrade by going to the
            Billing page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleCloseFreeTierDialog()}
            className={classes.submitButton}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderFormContent = () => {
    const { classes, className, ...rest } = this.props;

    return (
      <PortletContent className={classes.content}>
        <form autoComplete="off" noValidate>
          <Typography className={classes.caption} variant="h5">
            Create an organization to add your team members and collaborate with
            them. You will become the account owner and manage billing for your
            team.
          </Typography>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Organization Name"
              margin="dense"
              onChange={(event) => this.handleOrgName(event.target.value)}
              required
              variant="outlined"
              value={this.state.orgName}
            />
            {this.state.showError && (
              <Typography className={classes.fieldError} variant="body2">
                This field is required
              </Typography>
            )}
          </div>
        </form>
      </PortletContent>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Create Organization" />
        </PortletHeader>
        {this.renderFormContent()}
        <PortletFooter className={classes.portletFooter}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="small"
            variant="text"
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </PortletFooter>
        {this.renderFreeTierDialog()}
      </Portlet>
    );
  }
}

CreateOrg.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateOrg);
