import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {
  Box,
  IconButton,
  Link,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
  TextareaAutosize,
  Tooltip,
  withStyles,
} from "@material-ui/core";

// Material components
import { Menu, MenuItem, ListItem } from "@material-ui/core";

import theme from "theme";

// Component styles
import styles from "./styles";
import {
  Cancel,
  CheckCircle,
  Delete,
  Done,
  Email,
  Schedule,
  Reply,
  Call,
  AssignmentTurnedIn,
  MoreVert,
} from "@material-ui/icons";

import { timeFormatter } from "../../../../../../helpers/time";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

const highlightTypes = {
  coref: "coref",
  due: "due date",
  task: "task",
};

const ListItemWithWiderSecondaryAction = withStyles({
  secondaryAction: {
    paddingRight: "16px",
  },
})(ListItem);

class ActionItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsMenuAnchorEl: null,
      item: this.props.item,
      owner: this.props.owner,
      target: this.props.target,
      isEditing: this.props.isEditing ?? false,
      selectingTarget: false,
      color: this.props.color,
      completeTitle: this.props.title,
      actionItemType: this.props.actionItemType,
      menuAnchorEl: null,
    };
  }

  componentDidMount() {
    this.setState({
      color: this.props.color,
      completeTitle: this.props.title,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.item.uuid !== prevProps.item.uuid ||
      this.props.item.task !== prevProps.item.task ||
      this.props.owner !== prevProps.owner ||
      this.props.target !== prevProps.target
    ) {
      this.setState({
        item: this.props.item,
        owner: this.props.owner,
        target: this.props.target,
        isEditing: this.props.isEditing ?? false,
        color: this.props.color,
        completeTitle: this.props.title,
        actionItemType: this.props.actionItemType,
      });
    }
  };

  handleOptionsButtonClick = (event) => {
    event.stopPropagation();
    this.setState({
      optionsMenuAnchorEl: event.currentTarget,
      selectingTarget: false,
    });
  };

  handleTargetOptionsButtonClick = (event) => {
    event.stopPropagation();
    this.setState({
      optionsMenuAnchorEl: event.currentTarget,
      selectingTarget: true,
    });
  };

  handleCloseOptionsMenu = () => {
    this.setState({ optionsMenuAnchorEl: null, selectingTarget: false });
  };

  handleActionItemSelectionChange = (participantId) => {
    if (this.state.selectingTarget) {
      this.props.handleActionItemTargetChange(participantId, this.state.item);
    } else {
      this.props.handleActionItemOwnerChange(participantId, this.state.item);
    }
    this.handleCloseOptionsMenu();
    this.handleCloseOverflowMenu();
  };

  renderOwnerMenu = () => {
    return (
      <Menu
        anchorEl={this.state.optionsMenuAnchorEl}
        open={Boolean(this.state.optionsMenuAnchorEl)}
        onClose={this.handleCloseOptionsMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          value={null}
          onClick={() => {
            this.handleActionItemSelectionChange(null, this.state.item);
          }}
        >
          Assign Owner
        </MenuItem>
        {this.props.participants.map((participant) => {
          return (
            <MenuItem
              value={participant.id}
              onClick={() => {
                this.handleActionItemSelectionChange(
                  participant.id,
                  this.state.item
                );
              }}
            >
              {participant.name}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  actionItemChildClick = (event) => {
    if (this.state.isEditing || !this.props.editingEnabled) {
      event.stopPropagation();
      return;
    }
  };

  handleConfirmIconClick = () => {
    this.props.handleActionItemChange(this.state.item, this.state.editingTask);
    this.setState({
      isEditing: false,
      disableRipple: false,
      editingTask: "",
    });
  };

  handleCancelIconClick = () => {
    this.props.handleActionItemCancel(this.state.item, this.state.editingTask);
    this.setState({
      isEditing: false,
      disableRipple: false,
      editingTask: "",
    });
  };

  handleTextAreaClick = (event) => {
    event.stopPropagation();
  };

  handleDeleteActionItem = (event) => {
    event.stopPropagation();
    this.props.handleDeleteActionItem(this.state.item);
  };

  handleTextClicked = (event) => {
    const timestamp = this.state.item.timestamp;
    if (!this.state.isEditing) {
      if (timestamp && this.props.showTimestamps)
        this.props.handleTimestampClicked(this.state.item.timestamp);
      this.props.handleActionItemClicked(this.state.item);
    }
  };

  handleCompleteActionItem = (event) => {
    event.stopPropagation();
    this.props.handleCompleteActionItem(this.state.item);
    if (this.props.isComplete) {
      this.setState({
        color: theme.palette.primary.main,
      });
    } else {
      this.setState({
        color: theme.palette.success.main,
      });
    }
  };

  getIconForActionItemType = (actionItemType) => {
    if (actionItemType === "send_email") {
      return <Email />;
    } else if (actionItemType === "schedule_meeting") {
      return <Schedule />;
    } else if (actionItemType === "follow_up") {
      return <Reply />;
    } else if (actionItemType === "call") {
      return <Call />;
    } else {
      return <AssignmentTurnedIn />;
    }
  };

  getHeaderForActionItemType = (actionItemType) => {
    if (actionItemType === "send_email") {
      return "Send an email: ";
    } else if (actionItemType === "schedule_meeting") {
      return "Schedule a meeting: ";
    } else if (actionItemType === "follow_up") {
      return "Follow up: ";
    } else if (actionItemType === "call") {
      return "Call: ";
    } else if (actionItemType === "user_action_item") {
      return "Custom action item: ";
    }
    return "General action item: ";
  };

  handleCloseOverflowMenu = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleOpenOverflowMenu = (anchorEl) => {
    this.setState({
      menuAnchorEl: anchorEl,
    });
  };

  renderOverflowMenu = () => {
    return (
      <Menu
        anchorEl={this.state.menuAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(this.state.menuAnchorEl)}
        onClose={this.handleCloseOverflowMenu}
      >
        <MenuItem onClick={this.handleEditIconClick}>Edit Description</MenuItem>
        <MenuItem onClick={this.handleOptionsButtonClick}>
          Change Owner
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const { classes } = this.props;
    if (!this.props.editingEnabled) {
      return (
        <>
          <ListItem
            button
            inset
            selected={this.props.selected}
            className={classes.nested}
            disableRipple={this.state.disableRipple}
            onClick={this.handleTextClicked}
          >
            <ListItemIcon className={classes.typeIcon}>
              {this.getIconForActionItemType(this.state.actionItemType)}
            </ListItemIcon>
            <ListItemText
              className={classes.textInset}
              primary={this.annotateReferences(this.state.item)}
            />
          </ListItem>
          {this.renderOwnerMenu()}
          {this.renderOverflowMenu()}
        </>
      );
    }

    return (
      <>
        <ListItemWithWiderSecondaryAction
          button
          inset
          selected={this.props.selected}
          className={classes.nested}
          disableRipple={this.state.disableRipple}
          onClick={this.handleTextClicked}
        >
          {this.state.isEditing ? (
            <Box className={classes.textAreaInset}>
              <TextareaAutosize
                className={classes.actionItemEditingTask}
                value={this.state.editingTask}
                onChange={(event) =>
                  this.setState({ editingTask: event.target.value })
                }
                autoFocus
              />
            </Box>
          ) : (
            <>
              <ListItemIcon className={classes.typeIcon}>
                {this.getIconForActionItemType(this.state.actionItemType)}
              </ListItemIcon>
              <ListItemText
                className={classes.textInset}
                primary={this.annotateReferences(this.state.item)}
              />
            </>
          )}
          <>
            {!this.state.isEditing && this.props.editingEnabled && (
              <div className={classes.overflowMenu}>
                <Tooltip title={this.state.completeTitle}>
                  <IconButton
                    size="small"
                    onClick={this.handleCompleteActionItem}
                    style={{ color: this.state.color }}
                  >
                    <Done />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    onClick={this.handleDeleteActionItem}
                  >
                    <Delete className={classes.deleteIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="More Settings">
                  <IconButton
                    className={classes.overflowButton}
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleOpenOverflowMenu(event.currentTarget);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </>
          <ListItemSecondaryAction>
            {this.state.isEditing && (
              <>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => this.handleConfirmIconClick()}
                >
                  <CheckCircle color="primary" />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => this.handleCancelIconClick()}
                >
                  <Cancel color="primary" />
                </IconButton>
              </>
            )}
          </ListItemSecondaryAction>
        </ListItemWithWiderSecondaryAction>
        {this.renderOwnerMenu()}
        {this.renderOverflowMenu()}
      </>
    );
  }

  handleEditIconClick = (event) => {
    if (this.state.isEditing || !this.props.editingEnabled) {
      event.stopPropagation();
      return;
    }
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Modify Action Items",
      label: "Insights",
    });
    this.setState((prevState) => {
      return {
        isEditing: true,
        disableRipple: true,
        editingTask: prevState.item.task,
      };
    });
    this.handleCloseOverflowMenu();
  };

  annotateReferences = (actionItem) => {
    const { classes } = this.props;
    var annotations = [];
    if (actionItem.references && actionItem.references.length > 0) {
      annotations = annotations.concat(
        actionItem.references.map((r) => ({ ...r, type: highlightTypes.coref }))
      );
    }
    if (actionItem.dates && actionItem.dates.length > 0) {
      annotations = annotations.concat(
        actionItem.dates.map((d) => ({ ...d, type: highlightTypes.due }))
      );
    }
    annotations = annotations.concat(this.getTaskAnnotations(actionItem));
    annotations.sort((a, b) => {
      return a["start"] - b["start"];
    });
    if (annotations.length > 0) {
      var sentenceBlocks = [];
      var lastIndex = 0;
      for (var i in annotations) {
        let reference = annotations[i];
        if (lastIndex >= reference["start"]) {
          continue;
        }
        let previousBlock = actionItem.task.slice(
          lastIndex,
          reference["start"]
        );
        var wordBlock = actionItem.task.slice(
          reference["start"],
          reference["end"]
        );
        sentenceBlocks.push(previousBlock);
        if (reference.type === highlightTypes.coref) {
          let tooltipTitle =
            "Insight: " +
            reference["original"] +
            " -> " +
            reference["reference"];
          sentenceBlocks.push(
            <>
              {
                <Tooltip title={tooltipTitle}>
                  <span className={classes.reference}>{wordBlock}</span>
                </Tooltip>
              }
            </>
          );
        } else if (reference.type === highlightTypes.due) {
          sentenceBlocks.push(
            <>
              {
                <Tooltip title="Insight: Due Date">
                  <span className={classes.reference}>{wordBlock}</span>
                </Tooltip>
              }
            </>
          );
        } else if (reference.type === highlightTypes.task) {
          sentenceBlocks.push(
            <span className={classes.reference}>{wordBlock}</span>
          );
        } else {
          sentenceBlocks.push(wordBlock);
        }
        lastIndex = reference["end"];
      }
      var lastBlock = actionItem.task.slice(lastIndex);
      sentenceBlocks.push(lastBlock);
      return (
        <>
          <span className={classes.actionItemText}>
            <Tooltip title="Insight: Action Type">
              <span className={classes.actionType}>
                {this.getHeaderForActionItemType(this.state.actionItemType)}
              </span>
            </Tooltip>
            "
            {sentenceBlocks.map((block) => {
              return block;
            })}
            "
          </span>
          {this.setTimestampLink(actionItem.timestamp)}
        </>
      );
    }
    return (
      <>
        <span className={classes.actionItemText}>
          <Tooltip title="Insight: Action Type">
            <span className={classes.actionType}>
              {this.getHeaderForActionItemType(this.state.actionItemType)}
            </span>
          </Tooltip>
          "{actionItem.task}"
        </span>
        {this.setTimestampLink(actionItem.timestamp)}
      </>
    );
  };

  getTaskAnnotations = (actionItem) => {
    const annotations = [];
    const svo = actionItem.task_svo ? actionItem.task_svo[0] : null;
    const genericTask = actionItem.generic_task ?? null;
    const block = actionItem.task;

    // Task came from an SVO
    if (svo && svo.verb && svo.object) {
      if (svo.verb_index && svo.object_index && !actionItem.edited) {
        annotations.push({
          start: svo.verb_index,
          end: svo.verb_index + svo.verb.length,
          text: actionItem.verb,
          type: highlightTypes.task,
        });
        annotations.push({
          start: svo.object_index,
          end: svo.object_index + svo.object.length,
          text: actionItem.object,
          type: highlightTypes.task,
        });
      } else {
        let svoSentence = "";
        let svoOffset = 0;
        const sentences = block
          .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
          .split("|");
        for (let sentence of sentences) {
          const lowercaseSentence = sentence.toLowerCase();
          if (
            lowercaseSentence.includes(svo.verb) &&
            lowercaseSentence.includes(svo.object)
          ) {
            svoSentence = sentence;
            svoOffset = block.indexOf(sentence);
          }
        }
        const regex = new RegExp(`${svo.verb}(?!\\w)`);
        const verbPosition = svoSentence.search(regex);
        if (verbPosition >= 0) {
          annotations.push({
            start: svoOffset + verbPosition,
            end: svoOffset + verbPosition + svo.verb.length,
            text: svo.verb,
            type: highlightTypes.task,
          });
          svoSentence = svoSentence.slice(verbPosition + svo.verb.length);
          svoOffset += verbPosition + svo.verb.length;
        }
        const objectRegex = new RegExp(`${svo.object}(?!\\w)`);
        const objectPosition = svoSentence.search(objectRegex);
        if (objectPosition >= 0) {
          annotations.push({
            start: svoOffset + objectPosition,
            end: svoOffset + objectPosition + svo.object.length,
            text: svo.object,
            type: highlightTypes.task,
          });
        }
      }
    }
    // Task came from a generic task
    else if (genericTask) {
      const taskPosition = block.toLowerCase().indexOf(genericTask);
      if (taskPosition >= 0) {
        annotations.push({
          start: taskPosition,
          end: taskPosition + genericTask.length,
          text: genericTask,
          type: highlightTypes.task,
        });
      }
    }
    return annotations;
  };

  setTimestampLink = (timestamp) => {
    const { classes } = this.props;
    if (!timestamp || !this.props.showTimestamps) {
      return;
    }
    return (
      <Link
        component="button"
        className={classes.phraseTime}
        onClick={(event) => {
          event.stopPropagation();
          this.props.handleTimestampClicked(timestamp);
        }}
      >
        ({timeFormatter(timestamp)})
      </Link>
    );
  };
}

ActionItem.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionItem);
