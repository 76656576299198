export default (theme) => ({
  root: {},
  progressWrapper: {
    paddingTop: "48px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  progressWrapperContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  scheduledMeetingsActions: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 1.5,
  },
  scheduledMeetingsDisplayToggle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    margin: theme.spacing.unit,
  },
  tableRow: {
    height: "64px",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  tableCellInner: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px",
  },
  nameText: {
    cursor: "pointer",
  },
  addMeetingIcon: {
    height: "30px",
    width: "30px",
    color: theme.palette.primary.main,
  },
  folderTitle: {
    marginLeft: theme.spacing.unit * 0.5,
  },
  folderIcon: {
    color: theme.palette.text.secondary,
  },
  sharedIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing.unit * 0.5,
  },
  folderActionIcon: {
    color: theme.palette.primary.main,
  },
  childMeetingTitle: {
    marginLeft: "30px",
  },
  tableToolbar: {
    paddingLeft: "8px",
  },
  actionIcon: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    "&.disabled": {
      color: theme.palette.text.secondary,
    },
  },
  disabled: {
    opacity: "0.5",
  },
  willNotJoinIcon: {
    color: theme.palette.danger.main,
  },
  removeAssistantIcon: {
    color: theme.palette.primary.red,
  },
  addAssistantIcon: {
    color: theme.palette.primary.green,
  },
});
