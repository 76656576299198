import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Button,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components

import { UploadVideo, MeetingAgenda } from "./components";

// Shared components
import { Portlet, PortletContent } from "components";

// Shared services
import { uploadMeeting, getProfile } from "services/speech";

// Shared helpers
import { speechStatus } from "helpers";
import { pricingTiers } from "helpers";
import { meetingLimits } from "helpers";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  freeTierCaption: {
    color: theme.palette.text.secondary,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  progressBar: {
    margin: "auto",
    width: "80%",
    height: theme.spacing.unit * 4,
    border: `3px solid ${theme.palette.border}`,
    borderRadius: "5px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > h4": {
      fontFamily: "Roboto",
      zIndex: 1,
    },
  },
  currentProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
  },
  uploadingMessage: {
    fontFamily: "Roboto",
    width: "max-content",
    margin: "auto",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  limitReachedBanner: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "5px",
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  limitReachedText: {
    fontSize: "14px",
  },
});

class NewMeeting extends Component {
  speechId = null;
  agenda = [];

  state = {
    results: {},
    videoBlob: null,
    title: "",
    type: "",
    speechStatus: null,
    isLoading: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    uploadProgress: -1,
  };

  componentWillMount() {
    this.setState({
      isLoading: true,
    });
    getProfile().then(
      function(response) {
        if (response.status === 200) {
          if (response.data.meeting_duration_left === 0) {
            this.setError(
              "You've reached the meeting limit for your tier. Please upgrade to Sonero Pro tier to remove this restriction."
            );
          }
          if (response.data.is_enrolled === true) {
            this.setState({ isEnrolled: true });
          }
          ReactGA.event({
            category: "Upload Meeting",
            action: "Get user profile API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          // TODO: Handle error
          ReactGA.event({
            category: "Failure",
            action: "Get user profile API failed",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  }

  uploadSpeech = (blob, title, numOfParticipants) => {
    this.setState({ isLoading: true, videoBlob: blob });
    uploadMeeting(
      blob,
      title,
      this.agenda,
      numOfParticipants,
      this.handleUploadProgressChange
    ).then(
      function(response) {
        if (response.status === 202) {
          var data = response.data.id;
          this.setState({ speechStatus: speechStatus.processing });
          this.speechId = data;
          ReactGA.event({
            category: "Upload Meeting",
            action: "Upload meeting API success",
          });
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Upload meeting API failed",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  setError = (error) => {
    this.setState({ error: error });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      enrollDialogOpen: false,
    });
  };

  handleUploadProgressChange = (data) => {
    this.setState({
      uploadProgress: Math.round((100 * data.loaded) / data.total),
    });
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Upload Meeting">
        <div className={classes.root}>
          <div className={classes.content}>
            <Portlet>
              <PortletContent className={classes.content}>
                <Typography className={classes.error} variant="body1">
                  {this.state.error}
                </Typography>
              </PortletContent>
            </Portlet>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Upload Meeting">
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
            {this.state.uploadProgress >= 0 &&
              this.state.uploadProgress <= 100 && (
                <>
                  <div className={classes.uploadingMessage}>
                    Uploading your meeting...
                  </div>
                  <div className={classes.progressBar}>
                    <div
                      className={classes.currentProgress}
                      style={{ width: `${this.state.uploadProgress}%` }}
                    ></div>
                    <h4>{this.state.uploadProgress}%</h4>
                  </div>
                  <div className={classes.uploadingMessage}>
                    Please don’t navigate away from this page while uploading is
                    in progress
                  </div>
                </>
              )}
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderProcessing = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography
              className={classes.freeTierCaption}
              variant="h5"
              align="left"
            >
              Your recording is currently being processed and analyzed. This may
              take some time depending on the length of the recording.
              <br />
              <br />
              We will send you an email once processing is complete.
            </Typography>
          </PortletContent>
        </Portlet>
      </div>
    );
  };

  renderFreeTier = () => {
    const pricingTier = localStorage.getItem("pricingTier");
    if (pricingTier != pricingTiers.free) {
      return;
    }
    const { classes } = this.props;
    return (
      <Grid item lg={7} md={7} xl={9} xs={12}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.freeTierCaption} variant="body1">
              {`You are using Sonero free tier. You are limited to 
              ${meetingLimits.freeTier.numMeetings} meetings per month that have a 
              maximum duration of ${meetingLimits.freeTier.duration} minutes 
              each. Please upgrade to Sonero Pro to remove this restriction.`}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
    );
  };

  renderUpload = () => {
    const { classes } = this.props;
    const meetingDurationLeft = localStorage.getItem("meetingDurationLeft");
    return (
      <>
        {meetingDurationLeft != null && +meetingDurationLeft === 0 && (
          <PortletContent className={classes.limitReachedBanner}>
            <Typography className={classes.limitReachedText} variant="h6">
              You have reached the meeting limit for this month.
            </Typography>
          </PortletContent>
        )}
        <Grid container spacing={4}>
          <Grid item lg={5} md={5} xl={3} xs={12}>
            <UploadVideo
              className={classes.item}
              uploadSpeech={this.uploadSpeech}
              agenda={this.agenda}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  renderView = (status) => {
    if (status === speechStatus.processing) {
      return this.renderProcessing();
    } else if (status === speechStatus.failed) {
      return this.renderError();
    }

    return this.renderUpload();
  };

  render() {
    const { classes } = this.props;

    if (this.state.error != null) {
      return this.renderError();
    }

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <DashboardLayout title="Upload Meeting">
        <div className={classes.root}>
          {this.renderView(this.state.speechStatus)}
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
        </div>
      </DashboardLayout>
    );
  }
}

NewMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewMeeting);
