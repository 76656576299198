import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";

// Material components
import { Checkbox, Typography, Button, Switch, Box } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

class SharingPrefs extends Component {
  state = {
    isAutomaticInternalSharingEnabled: false,
    isAutomaticSharingEnabled: false,
    chooseMeetingGranularity: false,
    shareRecording: true,
    shareInsights: true,
    shareQuestions: true,
    shareTranscript: true,
    shareAnalytics: true,
    shareNotes: true,
    shareAttendees: true,
    shareScore: true,
    isRecordingEnabled: true,
    isOrgSettingsEnabled: false,
    isGuestRequestOutputEnabled: true,
  };

  componentDidMount() {
    const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
    this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
    if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
      this.setPrefs();
    } else {
      this.setOrgPrefs(isOrgSettingsEnabled);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.onOrgTab !== this.props.onOrgTab ||
      prevProps.orgSettings !== this.props.orgSettings
    ) {
      const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
      this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
      if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
        this.setPrefs();
      } else {
        this.setOrgPrefs(isOrgSettingsEnabled);
      }
    }
  }

  setPrefs = () => {
    var isAutomaticInternalSharingEnabled = JSON.parse(
      localStorage.getItem("isAutomaticInternalSharingEnabled")
    );
    var isAutomaticSharingEnabled = JSON.parse(
      localStorage.getItem("isAutomaticSharingEnabled")
    );
    var userShareGranularity = JSON.parse(
      localStorage.getItem("userShareGranularity")
    );
    var isRecordingEnabled = JSON.parse(
      localStorage.getItem("isRecordingEnabled")
    );
    var isGuestRequestOutputEnabled = JSON.parse(
      localStorage.getItem("isGuestRequestOutputEnabled")
    );

    let shareAll = true;
    Object.values(userShareGranularity).forEach((value) => {
      shareAll = shareAll && value;
    });

    this.setState({
      isAutomaticInternalSharingEnabled: isAutomaticInternalSharingEnabled,
      isAutomaticSharingEnabled: isAutomaticSharingEnabled,
      chooseMeetingGranularity: !shareAll,
      shareRecording: userShareGranularity.share_recording,
      shareInsights: userShareGranularity.share_insights,
      shareQuestions: userShareGranularity.share_questions,
      shareTranscript: userShareGranularity.share_transcript,
      shareAnalytics: userShareGranularity.share_analytics,
      shareNotes: userShareGranularity.share_notes,
      shareAttendees: userShareGranularity.share_attendees,
      shareScore: userShareGranularity.share_score,
      isRecordingEnabled: isRecordingEnabled,
      isGuestRequestOutputEnabled: isGuestRequestOutputEnabled,
    });
  };

  setOrgPrefs = (isOrgSettingsEnabled) => {
    if (isOrgSettingsEnabled) {
      const { orgSettings, orgSharePrefs } = this.props;

      let shareAll = true;
      Object.values(orgSharePrefs).forEach((value) => {
        if (value != null) shareAll = shareAll && value;
      });

      this.setState({
        isAutomaticInternalSharingEnabled:
          orgSettings.is_automatic_internal_sharing_enabled,
        isAutomaticSharingEnabled: orgSettings.is_automatic_sharing_enabled,
        chooseMeetingGranularity: !shareAll,
        shareRecording: orgSharePrefs.share_recording,
        shareInsights: orgSharePrefs.share_insights,
        shareQuestions: orgSharePrefs.share_questions,
        shareTranscript: orgSharePrefs.share_transcript,
        shareAnalytics: orgSharePrefs.share_analytics,
        shareNotes: orgSharePrefs.share_notes,
        shareAttendees: orgSharePrefs.share_attendees,
        shareScore: orgSharePrefs.share_score,
        isRecordingEnabled: orgSettings.is_recording_enabled,
        isGuestRequestOutputEnabled:
          orgSettings.is_guest_request_output_enabled,
      });
    } else {
      this.setState({
        isAutomaticInternalSharingEnabled: false,
        isAutomaticSharingEnabled: false,
        chooseMeetingGranularity: false,
        shareRecording: true,
        shareInsights: true,
        shareQuestions: true,
        shareTranscript: true,
        shareAnalytics: true,
        shareNotes: true,
        shareAttendees: true,
        shareScore: true,
        isRecordingEnabled: true,
        isGuestRequestOutputEnabled: false,
      });
    }
  };

  isOrgSettingsEnabled = () => {
    const { orgSettings, orgSharePrefs } = this.props;
    return (
      orgSettings.is_automatic_internal_sharing_enabled != null &&
      orgSharePrefs.share_recording != null &&
      orgSharePrefs.share_insights != null &&
      orgSharePrefs.share_questions != null &&
      orgSharePrefs.share_transcript != null &&
      orgSharePrefs.share_analytics != null &&
      orgSharePrefs.share_notes != null &&
      orgSharePrefs.share_attendees != null &&
      orgSharePrefs.share_score != null
    );
  };

  handleSave = () => {
    var prefs = {
      isAutomaticInternalSharingEnabled: this.state
        .isAutomaticInternalSharingEnabled,
      isAutomaticSharingEnabled: this.state.isAutomaticSharingEnabled,
      isRecordingEnabled: this.state.isRecordingEnabled,
      isGuestRequestOutputEnabled: this.state.isGuestRequestOutputEnabled,
    };

    const shareGranularity = {
      share_recording: this.state.shareRecording,
      share_insights: this.state.shareInsights,
      share_questions: this.state.shareQuestions,
      share_transcript: this.state.shareTranscript,
      share_analytics: this.state.shareAnalytics,
      share_notes: this.state.shareNotes,
      share_attendees: this.state.shareAttendees,
      share_score: this.state.shareScore,
    };
    if (!this.state.chooseMeetingGranularity) {
      shareGranularity.share_recording = true;
      shareGranularity.share_insights = true;
      shareGranularity.share_questions = true;
      shareGranularity.share_transcript = true;
      shareGranularity.share_analytics = true;
      shareGranularity.share_notes = true;
      shareGranularity.share_attendees = true;
      shareGranularity.share_score = true;
    }

    this.props.postSharePrefsToRemote(shareGranularity).then(
      function() {
        return this.props.saveHandler(prefs);
      }.bind(this)
    );
  };

  handleOrgSettingsToggle = (event) => {
    if (event.target.checked) {
      this.setState({
        isOrgSettingsEnabled: true,
      });
    } else {
      const prefs = {
        isAutomaticInternalSharingEnabled: null,
        isAutomaticSharingEnabled: null,
        isRecordingEnabled: null,
        isGuestRequestOutputEnabled: null,
      };
      const shareGranularity = {};
      shareGranularity.share_recording = null;
      shareGranularity.share_insights = null;
      shareGranularity.share_questions = null;
      shareGranularity.share_transcript = null;
      shareGranularity.share_analytics = null;
      shareGranularity.share_notes = null;
      shareGranularity.share_attendees = null;
      shareGranularity.share_score = null;

      this.props
        .postSharePrefsToRemote(shareGranularity)
        .then(
          function() {
            return this.props.saveHandler(prefs);
          }.bind(this)
        )
        .then(
          function() {
            this.setState({ isOrgSettingsEnabled: false });
            this.props.openSnackbar("Settings updated");
          }.bind(this)
        );
    }
  };

  handleInternalShareChange = (event) => {
    this.setState({
      isAutomaticInternalSharingEnabled: event.target.checked,
    });
    ReactGA.event({
      category: "Settings",
      action:
        (event.target.checked ? "Enabled " : "Disabled ") + "internal sharing",
    });
  };

  handleExternalShareChange = (event) => {
    this.setState({
      isAutomaticSharingEnabled: event.target.checked,
    });
  };

  handleChooseGranularityChange = (event) => {
    this.setState({
      chooseMeetingGranularity: event.target.checked,
    });
  };

  handleShareGranularityChange = (event, shareOption) => {
    this.setState({
      [shareOption]: event.target.checked,
    });
    if (!event.target.checked) {
      const option = shareOption.replace("share", "").toLowerCase();
      ReactGA.event({
        category: "Settings",
        action: `Disabled sharing ${option} in global share settings`,
      });
    }
  };

  handleRecordingChange = (event) => {
    this.setState({
      isRecordingEnabled: !event.target.checked,
    });
  };

  handleGuestRequestOutputChange = (event) => {
    this.setState({
      isGuestRequestOutputEnabled: event.target.checked,
    });
  };

  renderShareGranularities = () => {
    const { classes, onOrgTab } = this.props;

    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gridAutoRows="1fr"
      >
        <div className={classes.subfield}>
          <Checkbox
            color="primary"
            onChange={(e) =>
              this.handleShareGranularityChange(e, "shareRecording")
            }
            checked={this.state.shareRecording}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          />
          <Typography variant="body1">Recording</Typography>
        </div>
        <div className={classes.subfield}>
          <Checkbox
            color="primary"
            onChange={(e) =>
              this.handleShareGranularityChange(e, "shareInsights")
            }
            checked={this.state.shareInsights}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          />
          <Typography variant="body1">Insights</Typography>
        </div>
        <div className={classes.subfield}>
          <Checkbox
            color="primary"
            onChange={(e) =>
              this.handleShareGranularityChange(e, "shareTranscript")
            }
            checked={this.state.shareTranscript}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          />
          <Typography variant="body1">Transcript</Typography>
        </div>
        <div className={classes.subfield}>
          <Checkbox
            color="primary"
            onChange={(e) =>
              this.handleShareGranularityChange(e, "shareAnalytics")
            }
            checked={this.state.shareAnalytics}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          />
          <Typography variant="body1">Analytics</Typography>
        </div>
        <div className={classes.subfield}>
          <Checkbox
            color="primary"
            onChange={(e) =>
              this.handleShareGranularityChange(e, "shareAttendees")
            }
            checked={this.state.shareAttendees}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          />
          <Typography variant="body1">Attendees</Typography>
        </div>
        <div className={classes.subfield}>
          <Checkbox
            color="primary"
            onChange={(e) => this.handleShareGranularityChange(e, "shareScore")}
            checked={this.state.shareScore}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          />
          <Typography variant="body1">Efficiency Score</Typography>
        </div>
      </Box>
    );
  };

  render() {
    const { classes, className, onOrgTab, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Meeting Preferences" />
          {onOrgTab && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Off</Typography>
              <Switch
                color="primary"
                checked={this.state.isOrgSettingsEnabled}
                onChange={this.handleOrgSettingsToggle}
              />
              <Typography>On</Typography>
            </div>
          )}
        </PortletHeader>
        {!onOrgTab && this.state.isOrgSettingsEnabled && (
          <PortletContent className={classes.info}>
            <Typography variant="h6">
              Your settings and preferences are managed by your organization
              owner. <br />
              Please contact them for any changes.
            </Typography>
          </PortletContent>
        )}
        <PortletContent noPadding>
          <form className={classes.form}>
            <div className={classes.group}>
              <div className={classes.firstField}>
                <Checkbox
                  color="primary"
                  onChange={this.handleInternalShareChange}
                  checked={this.state.isAutomaticInternalSharingEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Share meetings with my team automatically
                  </Typography>
                  <Typography variant="caption">
                    We can automatically share your meetings with your team
                    members. Your team can view your meetings under the "My
                    Team's Meetings" tab in the "My Meetings" page. You need to
                    be a part of an organization in Sonero for this to work.
                  </Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleExternalShareChange}
                  checked={this.state.isAutomaticSharingEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Share meeting insights with all attendees
                  </Typography>
                  <Typography variant="caption">
                    We can automatically share your meeting insights with all
                    your meeting attendees.
                  </Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleChooseGranularityChange}
                  checked={this.state.chooseMeetingGranularity}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <Typography variant="body1">
                  I want to choose what others can see when I share my meetings
                </Typography>
              </div>
              {this.state.chooseMeetingGranularity &&
                this.renderShareGranularities()}
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleRecordingChange}
                  checked={!this.state.isRecordingEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Remove meeting recordings after processing
                  </Typography>
                  <Typography variant="caption">
                    If enabled, all your future meetings will{" "}
                    <strong>not</strong> contain the audio/video recording for
                    playback purposes. You will still get all our AI insights,
                    but the recording will be removed for increased privacy and
                    security.
                  </Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleGuestRequestOutputChange}
                  checked={this.state.isGuestRequestOutputEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Allow attendees to request meeting output
                  </Typography>
                  <Typography variant="caption">
                    If enabled, Sonero will send attendees an email letting them
                    know an output is available for the meeting they attended.
                    They may request access to this meeting output.
                  </Typography>
                </div>
              </div>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            onClick={this.handleSave}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

SharingPrefs.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SharingPrefs);
