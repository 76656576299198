export default (theme) => ({
  shareOptionButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit,
  },
  loadingText: {
    width: "max-content",
    margin: "auto",
  },
  emailContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  recipientsContainer: {
    maxWidth: "500px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border}`,
    marginBottom: theme.spacing.unit * 4,
  },
  emailRecipientsInput: {
    display: "block",
    width: "100%",
    border: "none",
    fontSize: "16px",
    padding: theme.spacing.unit,
    "&:focus": {
      border: `1px solid ${theme.palette.primary}`,
    },
  },
  emailSection: {
    width: "500px",
  },
  emailSubject: {
    width: "100%",
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "4px",
    fontSize: "14px",
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    fontFamily: "Arial",
    backgroundColor: theme.palette.primary.light,
  },
  emailBody: {
    width: "100%",
    minHeight: "200px",
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "4px",
    fontSize: "14px",
    padding: theme.spacing.unit,
    fontFamily: "Arial",
    backgroundColor: theme.palette.primary.light,
    whiteSpace: "pre-line",
  },
  emailChip: {
    margin: theme.spacing.unit * 0.5,
  },
  shareDialogContainer: {
    "& .MuiDialog-paper": {
      minWidth: "500px",
    },
  },
});
