import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { updateMeetingSummary } from "services/speech";

import styles from "./styles";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class AddMeetingSummary extends Component {
  state = {
    showError: false,
    isLoading: false,
    input: "",
  };

  setError = (message) => {
    this.setState({ showError: true, errorMsg: message });
  };

  handleClose = () => {
    this.setState({
      showError: false,
      input: "",
    });
    this.props.closeHandler();
  };

  handleUpdateSummarySuccess = (response) => {
    const summary = this.props.isAgenda
      ? response.data.agenda_summary
      : response.data.general_summary;
    this.props.successHandler(summary);
    this.handleClose();
  };

  handleUpdateSummary = () => {
    if (!this.state.input) {
      var error = "Please provide a " + this.getDialogLabel().toLowerCase();
      this.setError(error);
      return;
    }
    this.setState({ isLoading: true, showError: false });
    const summary = [...this.props.summary];

    if (this.props.summaryPos >= 0) {
      const topic = summary[this.props.summaryPos];
      if (topic.summary) {
        topic.summary.push({ phrase: this.state.input });
      } else {
        topic.summary = [{ phrase: this.state.input }];
      }
    } else {
      if (summary) {
        if (this.props.isAgenda) {
          summary.push({ agenda_item: this.state.input, summary: [] });
        } else {
          summary.push({ topic: this.state.input, summary: [] });
        }
      }
    }
    updateMeetingSummary(
      this.props.meetingId,
      summary,
      this.props.isAgenda
    ).then(
      function(response) {
        this.setState({ isLoading: false });
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          this.setError(response.data.message);
          ReactGA.event({
            category: "Failure",
            action: "Update meeting summary API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Update meeting summary API success",
          });
          this.handleUpdateSummarySuccess(response);
        }
      }.bind(this)
    );
  };

  handleInputChange = (value) => {
    this.setState({ input: value });
  };

  getDialogLabel = () => {
    if (this.props.summaryPos >= 0) {
      return "Topic Item";
    } else {
      return "Topic";
    }
  };

  renderDialog = () => {
    const { classes, className, ...rest } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <a>Add </a>
          <a>{this.getDialogLabel()}</a>
        </DialogTitle>
        <DialogContent>
          {this.state.showError && (
            <Typography className={classes.fieldError} variant="body2">
              {this.state.errorMsg}
            </Typography>
          )}
          <TextField
            className={classes.addMeetingSummaryTextField}
            label={this.getDialogLabel()}
            onChange={(event) => this.handleInputChange(event.target.value)}
            required
            value={this.state.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={() => this.handleUpdateSummary()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    return <div>{this.renderDialog()}</div>;
  }
}

AddMeetingSummary.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddMeetingSummary);
