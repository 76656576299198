import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { addParticipant } from "services/speech";
import validate from "validate.js";

import styles from "./styles";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class AddParticipant extends Component {
  state = {
    name: "",
    email: "",
    showError: false,
    errorMsg: "This field is required",
    isLoading: false,
  };

  componentWillMount() {}

  handleNameChange = (value) => {
    this.setState({ name: value });
  };

  handleEmailChange = (value) => {
    this.setState({ email: value });
  };

  setError = (message) => {
    this.setState({ showError: true, errorMsg: message });
  };

  handleClose = () => {
    this.setState({
      name: "",
      email: "",
      showError: false,
    });
    this.props.closeHandler();
  };

  handleAddParticipantSuccess = (response) => {
    this.props.successHandler(response.data.data);
    this.handleClose();
  };

  handleAddParticipant = () => {
    if (!this.state.name) {
      this.setError("Please provide an attendee name");
      return;
    }

    if (this.state.email) {
      var constraints = {
        email: {
          email: true,
        },
      };
      const errors = validate(this.state, constraints);
      const emailValid = errors ? false : true;

      if (emailValid !== true) {
        this.setError("Attendee email is not a valid email");
        return;
      }
    }

    this.setState({ isLoading: true, showError: false });

    addParticipant(
      this.props.meetingId,
      this.state.name,
      this.state.email
    ).then(
      function(response) {
        this.setState({ isLoading: false });
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          this.setError(response.data.message);
          ReactGA.event({
            category: "Failure",
            action: "Add participant API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Add participant API success",
          });
          this.handleAddParticipantSuccess(response);
        }
      }.bind(this)
    );
  };

  renderDialog = () => {
    const { classes, className, ...rest } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Attendee</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="participant_name"
            label="Attendee Name"
            onChange={(event) => this.handleNameChange(event.target.value)}
            required
            fullWidth
          />
          <TextField
            margin="dense"
            id="participant_email"
            label="Attendee Email"
            type="email"
            onChange={(event) => this.handleEmailChange(event.target.value)}
            fullWidth
          />
          {this.state.showError && (
            <Typography className={classes.fieldError} variant="body2">
              {this.state.errorMsg}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          {this.state.isLoading ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <Button onClick={this.handleAddParticipant} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    return <div>{this.renderDialog()}</div>;
  }
}

AddParticipant.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddParticipant);
