import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import { renameMeeting } from "services/speech";

import ReactGA from "react-ga";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  },
  snackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  whiteSnackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
    backgroundColor: "white",
    color: "black",
  },
  renameMeetingTextField: {
    width: "350px",
  },
});

class RenameMeetingDialog extends Component {
  state = {
    newMeetingTitle: this.props.meetingTitle,
    anchorEl: null,
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.meetingTitle !== this.props.meetingTitle) {
      this.setState({ newMeetingTitle: this.props.meetingTitle });
    }
  }

  handleSaveRenameMeeting = () => {
    var title = this.state.newMeetingTitle;
    if (!title.trim()) {
      return;
    }
    this.setLoading(true);
    return renameMeeting(this.props.id, title).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.props.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          this.setLoading(false);
          ReactGA.event({
            category: "Failure",
            action: "Rename meeting API failed",
          });
          return response;
        }
        var newTitle = response.data.title;
        this.props.handleRenameMeeting(newTitle, this.props.id);
        this.setLoading(false);
        this.props.openSnackbar("Meeting successfully renamed");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Rename meeting API success",
        });
        this.props.onClose();
        return response;
      }.bind(this)
    );
  };

  handleMeetingTitleChange = (value) => {
    this.setState({ newMeetingTitle: value });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
    this.props.setLoading(isLoading);
  };

  render = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rename Meeting</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.renameMeetingTextField}
            label="New Meeting Title"
            onChange={(event) =>
              this.handleMeetingTitleChange(event.target.value)
            }
            required
            value={this.state.newMeetingTitle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button
              onClick={() => this.handleSaveRenameMeeting()}
              color="primary"
            >
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

RenameMeetingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(RenameMeetingDialog);
