import React, { useRef, useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

// Component modified from this answer https://stackoverflow.com/a/59364492
const OverflowTooltip = (props) => {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef();

  const compareSize = () => {
    setHover(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    );
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <Tooltip
      title={props.title}
      placement={props.placement}
      disableHoverListener={!hoverStatus}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
