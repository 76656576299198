import React, { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";
import { Paper } from "components";
import { getMeetingSentimentFromPolarity } from "../../../../helpers/meetingSentiment";
import { timeFormatter } from "../../../../helpers/time";
import { getSpeakerString } from "helpers/meetingParticipants";
import { getTranscriptSegments } from "helpers/meetingTranscript";
import { isSoneroUser } from "services/speech";
import styles from "./styles";
import theme from "theme";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { CustomLinesLayer } from "components/Nivo/CustomLinesLayer";
import { CustomPointsLayer } from "components/Nivo/CustomPointsLayer";

const tooltipCharsThreshold = 100;
const highlightTypes = {
  0: "Sentiment variance",
  1: "Shared positive sentiment",
  2: "Shared negative sentiment",
};

class SentimentBreakdown extends Component {
  state = {
    activeSpeaker: "",
    activeSpeakerColor: "",
    pieData: [],
    timelineData: [],
    highlightTooltipX: null,
    highlightTooltipType: null,
    highlightSections: [],
    highlightHoveredSection: -1,
    point: null,
  };
  sentimentSummary = new Map();
  sentimentSummaryTotal = 0;
  sentimentMap = new Map();
  highlightOverlayRef = React.createRef();

  componentWillMount() {
    var transcript = this.props.data.transcript_results;
    transcript.forEach((result, _) => {
      var sentiment = getMeetingSentimentFromPolarity(result.polarity);
      if (sentiment) {
        let sentimentCount = 0;
        if (this.sentimentSummary.has(sentiment)) {
          sentimentCount = this.sentimentSummary.get(sentiment);
        }
        this.sentimentSummary.set(sentiment, sentimentCount + 1);
        this.sentimentSummaryTotal++;
      }

      var speakerSentiment = [];
      if (this.sentimentMap.has(result.speaker_tag)) {
        speakerSentiment = this.sentimentMap.get(result.speaker_tag);
        speakerSentiment.push({ x: result.timestamp, y: result.polarity });
        this.sentimentMap.set(result.speaker_tag, speakerSentiment);
      } else {
        speakerSentiment.push({ x: result.timestamp, y: result.polarity });
        this.sentimentMap.set(result.speaker_tag, speakerSentiment);
      }
    });
    if (this.props.data.analytics.moments) {
      var moments = [];
      for (var i in this.props.data.analytics.moments) {
        var moment = this.props.data.analytics.moments[i];
        moments.push({
          x: moment.timestamp,
          y: moment.score,
        });
      }
      this.sentimentMap.set("moments", moments);
    }
    this.getSummaryChartDataFromSentimentSummary();
    this.getChartDataFromSentimentMap();
  }

  getSummaryChartDataFromSentimentSummary = () => {
    var output = [];
    for (let [key, value] of this.sentimentSummary.entries()) {
      output.push({ id: key, label: key, value: value });
    }
    const sortedOutput = output.sort(function(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    this.setState({ pieData: sortedOutput });
  };

  getChartDataFromSentimentMap = () => {
    var participantsDetails = this.props.data.participants_details;
    var output = [];
    var bucketTimeSliceSecs = 30;
    for (let [key, value] of this.sentimentMap.entries()) {
      if (key === "moments") {
        output.push({
          id: key,
          data: value,
        });
        continue;
      }
      var buckets = [];
      var currentTimeSliceSentiment = 0;
      var currentTimeSlice = 0;
      var currentTimeSliceCount = 0;
      var currentTimeSliceSpikeX = 0;
      var currentTimeSliceSpikeY = 0;
      var currentBucketTimeSlice = bucketTimeSliceSecs;
      for (var sentimentData of value) {
        if (currentTimeSlice >= currentBucketTimeSlice) {
          buckets.push({
            x: currentTimeSliceSpikeX,
            y: currentTimeSliceSentiment / currentTimeSliceCount,
          });
          currentTimeSliceSentiment = sentimentData.y;
          currentTimeSliceCount = 1;
          currentTimeSlice = sentimentData.x;
          currentTimeSliceSpikeX = sentimentData.x;
          currentTimeSliceSpikeY = Math.abs(sentimentData.y);
          while (currentTimeSlice >= currentBucketTimeSlice) {
            currentBucketTimeSlice += bucketTimeSliceSecs;
          }
        } else {
          currentTimeSliceSentiment += sentimentData.y;
          currentTimeSliceCount++;
          currentTimeSlice = sentimentData.x;
          if (Math.abs(sentimentData.y) > currentTimeSliceSpikeY) {
            currentTimeSliceSpikeX = sentimentData.x;
            currentTimeSliceSpikeY = Math.abs(sentimentData.y);
          }
        }
      }
      buckets.push({
        x: currentTimeSliceSpikeX,
        y: currentTimeSliceSentiment / currentTimeSliceCount,
      });
      output.push({
        id: getSpeakerString(key, participantsDetails),
        data: buckets,
        speakerTag: key,
      });
    }
    const sortedOutput = output.sort(function(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    this.getHighlightSections(sortedOutput);
    this.setState({
      timelineData: sortedOutput,
    });
  };

  handleToggleClick = (speaker, speakerColor) => {
    if (this.state.activeSpeaker === speaker) {
      this.setState({
        activeSpeaker: "",
        activeSpeakerColor: "",
      });
    } else {
      this.setState({
        activeSpeaker: speaker,
        activeSpeakerColor: speakerColor,
      });
    }
  };

  resetHighlightTooltipPos = () => {
    this.setState({
      highlightTooltipX: null,
      highlightTooltipType: null,
      highlightHoveredSection: -1,
      point: null,
    });
  };

  setHighlightTooltipPos = (e) => {
    const rect = this.highlightOverlayRef.current.getBoundingClientRect();
    const posX = ((e.clientX - rect.left) / rect.width) * 100;
    for (const [index, section] of this.state.highlightSections.entries()) {
      if (posX >= section.low && posX <= section.high) {
        this.setState({
          highlightTooltipX: posX,
          highlightTooltipType: section.type,
          highlightHoveredSection: index,
        });
        return;
      }
    }
    this.resetHighlightTooltipPos();
  };

  hoveredHighlightSectionClassName = (className, hovered = false) => {
    const { classes } = this.props;
    if (hovered) {
      if (className === classes.highlightVariance) {
        return classes.hoveredHighlightVariance;
      } else if (className === classes.highlightPositive) {
        return classes.hoveredHighlightPositive;
      } else {
        return classes.hoveredHighlightNegative;
      }
    } else {
      return className;
    }
  };

  getHighlightSections = (pointData) => {
    // Step 1: Fetch initial data and transform them into desired format
    let data = [];
    pointData.forEach((speaker) => {
      data = data.concat(
        speaker.data.map((element) => ({ speaker: speaker.id, ...element }))
      );
    });
    data = data
      .filter((element, index) => {
        return data.indexOf(element) == index;
      })
      .sort((a, b) => {
        if (a.x > b.x) {
          return 1;
        } else if (a.x < b.x) {
          return -1;
        } else {
          return 0;
        }
      });

    // Step 2: Grouping all seperated elements into groups of potential segments
    const { classes } = this.props;
    const startBound = 0;
    const endBound = data.length !== 0 ? data[data.length - 1].x : 0;
    let varianceSegments = [];
    let positiveSegments = [];
    let negativeSegments = [];
    let varianceLastIndex = 0;
    let positiveLastIndex = 0;
    let negativeLastIndex = 0;

    data.forEach((element) => {
      const varianceNum = varianceSegments.length;
      const positiveNum = positiveSegments.length;
      const negativeNum = negativeSegments.length;

      if (element.y > 0) {
        // For positive sentiment point

        // Analyze if the positive sentiment point should be added in a variance segment
        if (varianceNum <= varianceLastIndex) {
          varianceSegments.push([element]);
        } else {
          const varianceLastSegment = varianceSegments[varianceLastIndex];
          const lastIndex = varianceLastSegment.length - 1;
          if (
            varianceLastSegment[lastIndex].speaker !== element.speaker &&
            varianceLastSegment[lastIndex].y < 0 &&
            element.x - varianceLastSegment[lastIndex].x <= endBound * 0.05
          ) {
            varianceSegments[varianceLastIndex].push(element);
          } else {
            varianceSegments.push([element]);
            if (varianceNum !== varianceLastIndex) {
              varianceLastIndex++;
            }
          }
        }

        // Analyze if the positive sentiment point should be added in a positive segment
        if (positiveNum <= positiveLastIndex) {
          positiveSegments.push([element]);
        } else {
          const positiveLastSegment = positiveSegments[positiveLastIndex];
          const lastIndex = positiveLastSegment.length - 1;
          if (
            positiveLastSegment[lastIndex].speaker !== element.speaker &&
            element.x - positiveLastSegment[lastIndex].x <= endBound * 0.05 &&
            Math.abs(positiveLastSegment[0].y - element.y) <= 0.1
          ) {
            positiveSegments[positiveLastIndex].push(element);
          } else {
            positiveSegments.push([element]);
            if (positiveNum !== positiveLastIndex) {
              positiveLastIndex++;
            }
          }
        }

        // Since this is a positive sentiment point, open a new slot in negative segment list
        if (negativeNum !== negativeLastIndex) {
          negativeLastIndex++;
        }
      } else if (element.y < 0) {
        // For negative sentiment point

        // Analyze if the negative sentiment point should be added in a variance segment
        if (varianceNum <= varianceLastIndex) {
          varianceSegments.push([element]);
        } else {
          const varianceLastSegment = varianceSegments[varianceLastIndex];
          const lastIndex = varianceLastSegment.length - 1;
          if (
            varianceLastSegment[lastIndex].speaker !== element.speaker &&
            varianceLastSegment[lastIndex].y > 0 &&
            element.x - varianceLastSegment[lastIndex].x <= endBound * 0.05
          ) {
            varianceSegments[varianceLastIndex].push(element);
          } else {
            varianceSegments.push([element]);
            if (varianceNum !== varianceLastIndex) {
              varianceLastIndex++;
            }
          }
        }

        // Analyze if the negative sentiment point should be added in a negative segment
        if (negativeNum <= negativeLastIndex) {
          negativeSegments.push([element]);
        } else {
          const negativeLastSegment = negativeSegments[negativeLastIndex];
          const lastIndex = negativeLastSegment.length - 1;
          if (
            negativeLastSegment[lastIndex].speaker !== element.speaker &&
            element.x - negativeLastSegment[lastIndex].x <= endBound * 0.05 &&
            Math.abs(negativeLastSegment[0].y - element.y) <= 0.1
          ) {
            negativeSegments[negativeLastIndex].push(element);
          } else {
            negativeSegments.push([element]);
            if (negativeNum !== negativeLastIndex) {
              negativeLastIndex++;
            }
          }
        }

        // Since this is a negative sentiment point, open a new slot in positive segment list
        if (positiveNum > positiveLastIndex) {
          positiveLastIndex++;
        }
      } else {
        // For neutral sentiment point, open a new slot in all segment lists
        if (varianceNum > varianceLastIndex) {
          varianceLastIndex++;
        }
        if (positiveNum > positiveLastIndex) {
          positiveLastIndex++;
        }
        if (negativeNum > negativeLastIndex) {
          negativeLastIndex++;
        }
      }
    });

    // Step 3: Filter which segments qualifies for output and convert all the qualifies segments into html div DOMs
    let highlightCategories = [
      varianceSegments,
      positiveSegments,
      negativeSegments,
    ];
    let highlightTimelineSections = highlightCategories
      .map((highlightCategory, highlightCategoryIndex) =>
        highlightCategory.map((highlightBlock) => {
          if (
            highlightBlock.length > 1 &&
            !(
              (highlightCategoryIndex === 1 || highlightCategoryIndex === 2) &&
              (data.indexOf(highlightBlock[0]) === 0 ||
                data.indexOf(highlightBlock[highlightBlock.length - 1]) ===
                  data.length - 1)
            )
          ) {
            let startX = startBound;
            let endX = endBound;
            const startIndex = data.indexOf(highlightBlock[0]);
            const endIndex = data.indexOf(
              highlightBlock[highlightBlock.length - 1]
            );
            if (startIndex > 0) {
              startX = (data[startIndex - 1].x + data[startIndex].x) / 2;
            }
            if (endIndex < data.length - 1) {
              endX = (data[endIndex].x + data[endIndex + 1].x) / 2;
            }
            const startPercentage = (startX / endBound) * 100;
            const endPercentage = (endX / endBound) * 100;
            const category =
              highlightCategoryIndex === 0
                ? classes.highlightVariance
                : highlightCategoryIndex === 1
                ? classes.highlightPositive
                : classes.highlightNegative;
            return {
              category: category,
              low: startPercentage,
              high: endPercentage,
              type: highlightTypes[highlightCategoryIndex],
            };
          }
        })
      )
      .flat(1)
      .filter((element) => element !== undefined);

    // Step 4: Update final segments list
    this.setState({
      highlightSections: highlightTimelineSections,
    });
  };

  renderTranscriptSegments = (transcriptResults) => {
    if (transcriptResults.length === 0) {
      return <></>;
    }

    const { classes } = this.props;
    const transcriptSegments = getTranscriptSegments(transcriptResults);
    const participantsDetails = this.props.data.participants_details;
    return (
      <>
        {transcriptSegments.map((segment) => (
          <div>
            <Typography variant="h6" className={classes.snippetSpeaker}>
              {getSpeakerString(segment.speaker, participantsDetails)}
            </Typography>
            <Typography variant="body1" className={classes.snippetBody}>
              {segment.transcript.map((transcriptSentence) => (
                <p>{transcriptSentence.sentence}</p>
              ))}
            </Typography>
          </div>
        ))}
      </>
    );
  };

  hoverPointAssign = ({ point }) => {
    this.setState({
      point: point,
    });
    return <></>;
  };

  renderTimelineTooltip = () => {
    const point = this.state.point;
    if (!point) {
      return <></>;
    }

    const { classes } = this.props;
    const sentimentLabel = getMeetingSentimentFromPolarity(point.data.y);
    const transcriptResults = this.props.data.transcript_results;
    const timestamp = point.data.x;

    const shareGranularity = this.props.data.share_granularity;
    if (!shareGranularity.share_transcript) {
      return (
        <div className={classes.tooltipContainer}>
          <div className={classes.tooltipHeader}>
            <div className={classes.sentimentLabel + " " + sentimentLabel}>
              {sentimentLabel}
            </div>
            <Typography className={classes.tooltipText} variant="body">
              at <span>{timeFormatter(point.data.x)}</span>
            </Typography>
          </div>
        </div>
      );
    }

    const index = this.props.data.transcriptionResultsMap[timestamp.toString()];
    const results = [];
    const speakers = new Set();
    let totalNumChars = 0;
    if (index >= 0) {
      for (let i = index; i > index - 3; i--) {
        if (i < 0) break;
        if (totalNumChars > tooltipCharsThreshold) break;
        results.unshift(transcriptResults[i]);
        speakers.add(transcriptResults[i].speaker_tag);
        totalNumChars += transcriptResults[i].sentence.length;
      }
    }

    // Don't show the tooltip if this speaker didn't talk at the hovered timestamp
    const speakerData = this.state.timelineData.find(
      (d) => d.id === point.serieId
    );
    const isMoment = point.serieId === "moments";
    if (!isMoment && (!speakerData || !speakers.has(speakerData.speakerTag))) {
      return <></>;
    }

    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipHeader}>
          <div className={classes.sentimentLabel + " " + sentimentLabel}>
            {sentimentLabel}
          </div>
          {isMoment && <div className={classes.momentLabel}>Moment</div>}
          <Typography className={classes.tooltipText} variant="body">
            at <span>{timeFormatter(point.data.x)}</span>
          </Typography>
        </div>
        {this.renderTranscriptSegments(results)}
      </div>
    );
  };

  renderPieGraph = () => {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    return (
      <Paper
        {...rest}
        className={rootClassName}
        onMouseEnter={this.props.handleOnMouseEnter}
        onMouseLeave={() => this.props.handleOnMouseLeave("Sentiment Pie")}
      >
        <div className={classes.content} style={{ height: 200 }}>
          <ResponsivePie
            data={this.state.pieData}
            margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
            innerRadius={0.5}
            padAngle={1.5}
            cornerRadius={3}
            enableRadialLabels={true}
            valueFormat={(val) => {
              return Math.round((val / this.sentimentSummaryTotal) * 100) + "%";
            }}
            colors={{ scheme: "set1" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: "color" }}
            radialLabelsLinkDiagonalLength={8}
            radialLabelsLinkHorizontalLength={12}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#FFFFFF"
            sliceLabel={(e) => {
              return (
                Math.round((e.value / this.sentimentSummaryTotal) * 100) + "%"
              );
            }}
            height={200}
          />
        </div>
      </Paper>
    );
  };

  renderTimeline = () => {
    const { classes, className, ...rest } = this.props;
    const {
      highlightTooltipX,
      highlightTooltipType,
      highlightSections,
      highlightHoveredSection,
    } = this.state;
    const rootClassName = classNames(classes.root, className);
    let data = this.state.timelineData;
    if (this.state.activeSpeaker) {
      data = data.filter(
        (timelineObj) => timelineObj.id === this.state.activeSpeaker
      );
    }
    return (
      <Paper {...rest} className={rootClassName}>
        <div
          className={classes.content}
          style={{ height: 350, position: "relative" }}
          onMouseMove={this.setHighlightTooltipPos}
          onMouseLeave={this.resetHighlightTooltipPos}
        >
          <div
            className={classes.highlightOverlay}
            ref={this.highlightOverlayRef}
          >
            {isSoneroUser() &&
              highlightSections.map((section, index) => (
                <div
                  className={this.hoveredHighlightSectionClassName(
                    section.category,
                    index === highlightHoveredSection
                  )}
                  style={{
                    left: String(section.low) + "%",
                    width: String(section.high - section.low) + "%",
                  }}
                />
              ))}
          </div>
          <ResponsiveLine
            onMouseEnter={this.props.handleOnMouseEnter}
            onMouseLeave={() =>
              this.props.handleOnMouseLeave("Sentiment Timeline")
            }
            data={data}
            yScale={{
              type: "linear",
              min: -1,
              max: 1,
            }}
            curve="monotoneX"
            margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
            xScale={{ type: "linear" }}
            colors={
              this.state.activeSpeakerColor
                ? this.state.activeSpeakerColor
                : theme.palette.nivoDark2
            }
            pointSize={5}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            axisBottom={{
              format: (value) => timeFormatter(value),
              legend: "Duration",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            layers={[
              "grid",
              "markers",
              "areas",
              CustomLinesLayer,
              "slices",
              "axes",
              CustomPointsLayer,
              "crosshair",
              "mesh",
              "legends",
            ]}
            axisLeft={{
              tickValues: [-1, 0, 1],
              format: (value) => {
                if (value > 0) {
                  return "Positive";
                } else if (value == 0) {
                  return "Neutral";
                } else {
                  return "Negative";
                }
              },
              legend: "",
              legendOffset: 0,
            }}
            enableSlices={false}
            useMesh={true}
            crosshairType="bottom"
            tooltip={this.hoverPointAssign}
            onClick={(point) => {
              this.props.setMediaPlayerCurrentTimeAndPlay(
                point.data.x,
                "Sentiment Timeline"
              );
            }}
          />
          {isSoneroUser() && (
            <div
              className={classes.timelineTooltip}
              style={{
                left: `calc(${highlightTooltipX}% - 150px)`,
                display: highlightTooltipX ? "block" : "none",
              }}
            >
              {highlightTooltipType}
            </div>
          )}
        </div>
      </Paper>
    );
  };

  renderToggle = () => {
    const { classes } = this.props;
    let speakers = this.state.timelineData
      .filter((timeline) => timeline.id !== "moments")
      .map((timeline) => timeline.id);
    const nivoDark2 = theme.palette.nivoDark2;

    return (
      <>
        {speakers.map((speaker, idx) => {
          const speakerColor = nivoDark2[idx % nivoDark2.length];
          return (
            <div
              className={
                classes.speakerToggle +
                (this.state.activeSpeaker === speaker ? " active" : "")
              }
              onClick={() => this.handleToggleClick(speaker, speakerColor)}
            >
              <span style={{ backgroundColor: speakerColor }}></span> {speaker}
            </div>
          );
        })}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const { point } = this.state;
    return (
      <div>
        {this.props.data.sentiment.polarity && (
          <div className={classes.widgetGrid}>
            {point ? (
              <div className={classes.tooltipOverlay}>
                {this.renderTimelineTooltip()}
              </div>
            ) : (
              <>
                <div className={classes.gridPie}>{this.renderPieGraph()}</div>
                <div className={classes.gridToggle}>{this.renderToggle()}</div>
              </>
            )}
            <div className={classes.gridTimeline}>{this.renderTimeline()}</div>
          </div>
        )}
      </div>
    );
  }
}

SentimentBreakdown.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SentimentBreakdown);
