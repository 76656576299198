export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  firstContent: {
    alignItems: "center",
    display: "flex",
  },
  content: {
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "Roboto",
    height: "400px",
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
});
