import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import styles from "./styles";
import classNames from "classnames";
import ReactGA from "react-ga";

import {
  subScoreLabels,
  getEnergyCopy,
  getToneCopy,
  getFillerCopy,
  getAgendaTopicsCopy,
  getActionItemsCopy,
  getInactiveAttendeesCopy,
  getSpeakingDurationCopy,
  getSentimentCopy,
  getMeetingNecessityCopy,
  getInviteeAttendanceCopy,
  getDecisionDrivenCopy,
  getProductiveMeetingCopy,
  getScoreColor,
  hasMeetingScore,
  getLabelDescriptor,
} from "helpers/meetingScore";
import { MetricBar, Portlet, PortletHeader, PortletLabel } from "components";
import { Typography, IconButton, ListItem } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class MeetingScore extends Component {
  timeSpentHover = new Date();

  state = {
    overallScore: 0,
    performanceScore: 0,
    efficiencyScore: 0,
    engagementScore: 0,
    feedbackScore: 0,
    myPerformance: {},
    meetingEfficiency: {},
    audienceEngagement: {},
    attendeeFeedback: {},
    expanded: "",
    sharedView: false,
    isInit: true,
  };

  componentDidMount() {
    if (!hasMeetingScore(this.props.data)) {
      return;
    }

    const meetingScore = this.props.data.analytics.meeting_score;
    const sharedView = this.props.sharedView;
    this.setState({
      overallScore: meetingScore.overall_score,
      performanceScore: meetingScore.sub_scores.my_performance,
      efficiencyScore: meetingScore.sub_scores.meeting_efficiency,
      engagementScore: meetingScore.sub_scores.audience_engagement,
      feedbackScore: meetingScore.sub_scores.attendee_feedback,
      myPerformance: meetingScore.score_labels.my_performance,
      meetingEfficiency: meetingScore.score_labels.meeting_efficiency,
      audienceEngagement: meetingScore.score_labels.audience_engagement,
      attendeeFeedback: meetingScore.score_labels.attendee_feedback,
      sharedView,
    });
  }

  handleExpand = (subScore) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked " + subScore,
      label: "Meeting Info",
    });
    this.setState({ expanded: subScore, isInit: false });
  };

  handleCollapse = () => {
    this.setState({ expanded: "", isInit: false });
  };

  handleCreateAccount = (event) => {
    ReactGA.event({
      category: "View Shared Meeting",
      action: "Sign up button clicked",
    });
    event.stopPropagation();
  };

  handleOnMouseEnter = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeave = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: `User Hovered Meeting Score Widget`,
      value: timeSpent,
    });
  };

  renderOverallView = () => {
    const { classes } = this.props;
    const { overallScore, sharedView, isInit } = this.state;

    const noPerformanceScore = this.state.performanceScore < 0;
    const performanceScore = noPerformanceScore
      ? 0
      : this.state.performanceScore;

    const noEfficiencyScore = this.state.efficiencyScore < 0;
    const efficiencyScore = noEfficiencyScore ? 0 : this.state.efficiencyScore;

    const noEngagementScore = this.state.engagementScore < 0;
    const engagementScore = noEngagementScore ? 0 : this.state.engagementScore;

    const noFeedbackScore = this.state.feedbackScore < 0;
    const feedbackScore = noFeedbackScore ? 0 : this.state.feedbackScore;
    const urlRateMeeting = `/rate-meeting/${this.props.data.uuid}`;

    return (
      <div
        className={classes.overallView + (isInit ? " no-animation" : "")}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <ListItem
          button
          className={classes.individualScore}
          onClick={() => this.handleExpand(subScoreLabels.performance)}
          disabled={noPerformanceScore}
        >
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              My Performance{" "}
              {!noPerformanceScore && (
                <span>
                  {Math.round(performanceScore)}
                  <span>/10</span>
                </span>
              )}
            </Typography>
            <MetricBar
              barPercentage={performanceScore * 10}
              barColor={getScoreColor(performanceScore)}
              height="10px"
              fillAnimation={!isInit}
            />
          </div>
          <IconButton
            color="primary"
            style={{ backgroundColor: "transparent" }}
          >
            <ChevronRight className={classes.expandIcon} />
          </IconButton>
        </ListItem>

        <ListItem
          button
          className={classes.individualScore}
          onClick={() => this.handleExpand(subScoreLabels.efficiency)}
          disabled={noEfficiencyScore}
        >
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Meeting Efficiency{" "}
              <span>
                {Math.round(efficiencyScore)}
                <span>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={efficiencyScore * 10}
              barColor={getScoreColor(efficiencyScore)}
              height="10px"
              fillAnimation={!isInit}
            />
          </div>
          <IconButton
            color="primary"
            style={{ backgroundColor: "transparent" }}
          >
            <ChevronRight className={classes.expandIcon} />
          </IconButton>
        </ListItem>

        <ListItem
          button
          className={classes.individualScore}
          onClick={() => this.handleExpand(subScoreLabels.engagement)}
          disabled={noEngagementScore}
        >
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Audience Engagement{" "}
              <span>
                {Math.round(engagementScore)}
                <span>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={engagementScore * 10}
              barColor={getScoreColor(engagementScore)}
              height="10px"
              fillAnimation={!isInit}
            />
          </div>
          <IconButton
            color="primary"
            style={{ backgroundColor: "transparent" }}
          >
            <ChevronRight className={classes.expandIcon} />
          </IconButton>
        </ListItem>

        <ListItem
          button
          className={classes.individualScore}
          onClick={() => this.handleExpand(subScoreLabels.feedback)}
          disabled={!sharedView && noFeedbackScore}
        >
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Attendee Feedback{" "}
              {sharedView && noFeedbackScore && (
                <a href={urlRateMeeting}>
                  <Tooltip title="Give feedback on this meeting.">
                    <Button
                      className={classes.performanceSignUp}
                      style={{ backgroundColor: "transparent" }}
                    >
                      ADD FEEDBACK
                    </Button>
                  </Tooltip>
                </a>
              )}
              {(!sharedView || !noFeedbackScore) && (
                <span>
                  {Math.round(feedbackScore)}
                  <span>/10</span>
                </span>
              )}
            </Typography>
            <MetricBar
              barPercentage={feedbackScore * 10}
              barColor={getScoreColor(feedbackScore)}
              height="10px"
              fillAnimation={!isInit}
            />
          </div>
          <IconButton
            color="primary"
            style={{ backgroundColor: "transparent" }}
          >
            <ChevronRight className={classes.expandIcon} />
          </IconButton>
        </ListItem>

        <section className={classes.overallScore}>
          <Typography variant="h6" className={classes.overallScoreLabel}>
            Overall{" "}
            <span>
              {Math.round(overallScore)}
              <span>/10</span>
            </span>
          </Typography>
          <MetricBar
            barPercentage={overallScore * 10}
            barColor={getScoreColor(overallScore)}
            height="14px"
          />
        </section>
      </div>
    );
  };

  renderMyPerformanceView = () => {
    const { classes } = this.props;
    const { myPerformance } = this.state;

    const noPerformanceScore = this.state.performanceScore < 0;
    const performanceScore = noPerformanceScore
      ? 0
      : this.state.performanceScore;

    let fillerWpm = 0;
    const meetingScoreObj = this.props.data.analytics.meeting_score.score_obj;
    if (
      meetingScoreObj.my_performance &&
      meetingScoreObj.my_performance.filler_per_min != null
    ) {
      fillerWpm = meetingScoreObj.my_performance.filler_per_min;
    }

    return (
      <div className={classes.detailedView}>
        <div className={classes.backButton} onClick={this.handleCollapse}>
          <ChevronLeft className={classes.expandIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              My Performance{" "}
              {!noPerformanceScore && (
                <span>
                  {Math.round(performanceScore)}
                  <span>/10</span>
                </span>
              )}
            </Typography>
            <MetricBar
              barPercentage={performanceScore * 10}
              barColor={getScoreColor(performanceScore)}
              height="10px"
              fillAnimation
            />

            {noPerformanceScore && (
              <div className={classes.details}>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " label"}
                >
                  You need a Sonero account to see a score for My Performance.
                </Typography>
                <a href="/sign-up?ref=share-view">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={this.handleCreateAccount}
                  >
                    CREATE ACCOUNT
                  </Button>
                </a>
              </div>
            )}

            <div className={classes.details}>
              {!noPerformanceScore && myPerformance.intensity != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Energy
                    {getLabelDescriptor(myPerformance.intensity)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getEnergyCopy(myPerformance.intensity, false)}
                  </Typography>
                </div>
              )}

              {!noPerformanceScore && myPerformance.tone != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Delivery Tone
                    {getLabelDescriptor(myPerformance.tone)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getToneCopy(myPerformance.tone, false)}
                  </Typography>
                </div>
              )}

              {!noPerformanceScore && myPerformance.filler_per_min != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Fillers
                    {getLabelDescriptor(myPerformance.filler_per_min)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getFillerCopy(myPerformance.filler_per_min, fillerWpm)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  };

  renderMeetingEfficiencyView = () => {
    const { classes } = this.props;
    const { efficiencyScore, meetingEfficiency } = this.state;

    return (
      <div className={classes.detailedView}>
        <div className={classes.backButton} onClick={this.handleCollapse}>
          <ChevronLeft className={classes.expandIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Meeting Efficiency{" "}
              <span>
                {Math.round(efficiencyScore)}
                <span>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={efficiencyScore * 10}
              barColor={getScoreColor(efficiencyScore)}
              height="10px"
              fillAnimation
            />

            <div className={classes.details}>
              {meetingEfficiency.topics_discussed != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Agenda Topics
                    {getLabelDescriptor(meetingEfficiency.topics_discussed)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAgendaTopicsCopy(meetingEfficiency.topics_discussed)}
                  </Typography>
                </div>
              )}

              {meetingEfficiency.action_items != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Action Items
                    {getLabelDescriptor(meetingEfficiency.action_items)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getActionItemsCopy(meetingEfficiency.action_items)}
                  </Typography>
                </div>
              )}

              {meetingEfficiency.inactive_participants != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Attendee participation
                    {getLabelDescriptor(
                      meetingEfficiency.inactive_participants
                    )}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getInactiveAttendeesCopy(
                      meetingEfficiency.inactive_participants
                    )}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  };

  renderAudienceEngagementView = () => {
    const { classes } = this.props;
    const { engagementScore, audienceEngagement } = this.state;

    return (
      <div className={classes.detailedView}>
        <div className={classes.backButton} onClick={this.handleCollapse}>
          <ChevronLeft className={classes.expandIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Audience Engagement{" "}
              <span>
                {Math.round(engagementScore)}
                <span>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={engagementScore * 10}
              barColor={getScoreColor(engagementScore)}
              height="10px"
              fillAnimation
            />

            <div className={classes.details}>
              {audienceEngagement.duration != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Speaking Duration
                    {getLabelDescriptor(audienceEngagement.duration)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getSpeakingDurationCopy(audienceEngagement.duration)}
                  </Typography>
                </div>
              )}

              {audienceEngagement.sentiment && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Sentiment
                    {getLabelDescriptor(audienceEngagement.sentiment)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getSentimentCopy(audienceEngagement.sentiment)}
                  </Typography>
                </div>
              )}

              {audienceEngagement.intensity && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Energy
                    {getLabelDescriptor(audienceEngagement.intensity)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getEnergyCopy(audienceEngagement.intensity, true)}
                  </Typography>
                </div>
              )}

              {audienceEngagement.tone && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Delivery Tone
                    {getLabelDescriptor(audienceEngagement.tone)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getToneCopy(audienceEngagement.tone, true)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  };

  renderAttendeeFeedbackView = () => {
    const { classes } = this.props;
    const { feedbackScore, attendeeFeedback, sharedView } = this.state;

    const noFeedbackScore = this.state.feedbackScore < 0;
    const urlRateMeeting = `/rate-meeting/${this.props.data.uuid}`;
    return (
      <div className={classes.detailedView}>
        <div className={classes.backButton} onClick={this.handleCollapse}>
          <ChevronLeft className={classes.expandIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.score}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Attendee Feedback{" "}
              {(!sharedView || !noFeedbackScore) && (
                <span>
                  {Math.round(feedbackScore)}
                  <span>/10</span>
                </span>
              )}
            </Typography>
            <MetricBar
              barPercentage={feedbackScore * 10}
              barColor={getScoreColor(feedbackScore)}
              height="10px"
              fillAnimation
            />

            {sharedView && noFeedbackScore && (
              <div className={classes.details}>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " label"}
                >
                  No feedback has been submitted for this meeting yet.
                </Typography>
                <a href={urlRateMeeting}>
                  <Button variant="outlined" color="primary" size="small">
                    ADD FEEDBACK
                  </Button>
                </a>
              </div>
            )}

            <div className={classes.details}>
              {(!sharedView || !noFeedbackScore) &&
                attendeeFeedback.meeting_need != null && (
                  <div className={classes.subScoreContainer}>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " label"}
                    >
                      Meeting Necessity
                      {getLabelDescriptor(attendeeFeedback.meeting_need)}
                    </Typography>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " detail"}
                    >
                      {getMeetingNecessityCopy(attendeeFeedback.meeting_need)}
                    </Typography>
                  </div>
                )}

              {(!sharedView || !noFeedbackScore) &&
                attendeeFeedback.attendee_importance && (
                  <div className={classes.subScoreContainer}>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " label"}
                    >
                      Invitee Attendance
                      {getLabelDescriptor(attendeeFeedback.attendee_importance)}
                    </Typography>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " detail"}
                    >
                      {getInviteeAttendanceCopy(
                        attendeeFeedback.attendee_importance
                      )}
                    </Typography>
                  </div>
                )}

              {(!sharedView || !noFeedbackScore) &&
                attendeeFeedback.clear_decisions && (
                  <div className={classes.subScoreContainer}>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " label"}
                    >
                      Decision Driven
                      {getLabelDescriptor(attendeeFeedback.clear_decisions)}
                    </Typography>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " detail"}
                    >
                      {getDecisionDrivenCopy(attendeeFeedback.clear_decisions)}
                    </Typography>
                  </div>
                )}

              {(!sharedView || !noFeedbackScore) &&
                attendeeFeedback.meeting_productivity && (
                  <div className={classes.subScoreContainer}>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " label"}
                    >
                      Productive Meeting
                      {getLabelDescriptor(
                        attendeeFeedback.meeting_productivity
                      )}
                    </Typography>
                    <Typography
                      variant="body"
                      className={classes.subScoreText + " detail"}
                    >
                      {getProductiveMeetingCopy(
                        attendeeFeedback.meeting_productivity
                      )}
                    </Typography>
                  </div>
                )}
            </div>
          </div>
        </section>
      </div>
    );
  };

  render() {
    if (!hasMeetingScore(this.props.data)) {
      return <></>;
    }
    const { classes, className, height, wrapInPortlet } = this.props;
    const rootClassName = classNames(classes.root, className);
    const { expanded } = this.state;

    const meetingScoreComponent = (
      <div
        className={classes.meetingScoreContainer}
        style={height && { height }}
      >
        <div className={classes.transitionContainer}>
          {expanded === "" && this.renderOverallView()}
          {expanded === subScoreLabels.performance &&
            this.renderMyPerformanceView()}
          {expanded === subScoreLabels.efficiency &&
            this.renderMeetingEfficiencyView()}
          {expanded === subScoreLabels.engagement &&
            this.renderAudienceEngagementView()}
          {expanded === subScoreLabels.feedback &&
            this.renderAttendeeFeedbackView()}
        </div>
      </div>
    );

    if (wrapInPortlet) {
      return (
        <Portlet className={rootClassName}>
          <PortletHeader>
            <PortletLabel title="Efficiency Score" />
          </PortletHeader>
          {meetingScoreComponent}
        </Portlet>
      );
    }
    return meetingScoreComponent;
  }
}

MeetingScore.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MeetingScore);
