import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  firstContent: {
    alignItems: "center",
    display: "flex",
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  widgetGrid: {
    display: "grid",
    gridTemplateColumns: "33% 66%",
    padding: theme.spacing.unit * 2,
  },
  gridToggle: {
    gridColumn: 1,
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridAutoFlow: "row",
    gap: theme.spacing.unit * 0.5,
    margin: "auto",
    "@media (max-width: 500px)": {
      gridTemplateColumns: "auto",
    },
  },
  gridPie: {
    gridColumn: 1,
  },
  gridTimeline: {
    gridColumn: 2,
    gridRow: "1 / span 2",
  },
  "@media (max-width: 750px)": {
    gridToggle: {
      gridColumn: 1,
      gridRow: 1,
    },
    gridPie: {
      gridColumn: 2,
      gridRow: 1,
    },
    gridTimeline: {
      gridColumn: "1 / span 2",
      gridRow: 2,
    },
  },
  speakerToggle: {
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    padding: theme.spacing.unit,
    borderRadius: "5px",
    "& span": {
      display: "inline-block",
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      backgroundColor: "lightblue",
    },
  },
  timelineContent: {
    position: "relative",
    "&::before": {
      content: `''`,
      position: "absolute",
      left: "calc(50% - 1px)",
      height: "calc(100% - 40px)",
      borderLeft: `2px dashed #DCDCDC`,
      zIndex: 1,
    },
  },
  timelineContainer: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    "&:first-child": {
      marginTop: 0,
    },
    "& > .timelineSpeaker": {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing.unit * 0.5,
    },
  },
  timeline: {
    position: "relative",
    width: "100%",
    height: "14px",
    backgroundColor: theme.palette.border,
  },
  speakerSegment: {
    position: "absolute",
    top: "0px",
    height: "100%",
    cursor: "pointer",
  },
  timestampsContainer: {
    width: "100%",
    marginTop: "45px",
    fontFamily: "Roboto",
    fontWeight: "500",
    color: theme.palette.text.secondary,
    position: "relative",
  },
  startTimestamp: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "5px",
    borderRadius: "0 5px 5px 5px",
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    "&::before": {
      content: `''`,
      position: "absolute",
      left: 0,
      top: "-8px",
      width: 0,
      height: 0,
      border: "8px solid transparent",
      borderBottomColor: fade(theme.palette.primary.main, 0.15),
      borderTop: 0,
      borderLeft: 0,
    },
  },
  midTimestamp: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    "&::before": {
      content: `''`,
      position: "absolute",
      left: "calc(50% - 6px)",
      top: "-6px",
      width: 0,
      height: 0,
      border: "6px solid transparent",
      borderBottomColor: fade(theme.palette.primary.main, 0.15),
      borderTop: 0,
    },
  },
  endTimestamp: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: "5px",
    borderRadius: "5px 0 5px 5px",
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    "&::before": {
      content: `''`,
      position: "absolute",
      right: 0,
      top: "-8px",
      width: 0,
      height: 0,
      border: "8px solid transparent",
      borderBottomColor: fade(theme.palette.primary.main, 0.15),
      borderTop: 0,
      borderRight: 0,
    },
  },
});
