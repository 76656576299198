export default (theme) => ({
  meetingsContainer: {
    height: "inherit",
  },
  sectionHeader: {
    padding: theme.spacing.unit * 2,
    background: theme.palette.background.blue,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  headerOption: {
    color: theme.palette.text.light,
    fontWeight: 700,
    cursor: "pointer",
    "&.active": {
      color: theme.palette.text.dark,
    },
  },
  headerSeparator: {
    height: "20px",
    width: "1px",
    background: theme.palette.text.dark,
  },
  meetingsContent: {
    height: "calc(100% - 52px)",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
  },
  meetingsList: {
    display: "flex",
    flexDirection: "column",
  },
  dateHeader: {
    padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2}px`,
    backgroundColor: theme.palette.background.blue,
  },
  meetingRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.text.secondary,
    fontSize: "14px",
    backgroundColor: theme.palette.primary.light,
    borderBottom: `1px solid ${theme.palette.common.white}`,
  },
  meetingTitle: {
    width: "40%",
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "&.bold": {
      fontWeight: "700",
    },
    "&.disabled": {
      color: theme.palette.text.secondary,
    },
  },
  meetingTime: {
    width: "40%",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    color: "inherit",
    fontSize: "inherit",
    margin: "auto",
  },
  meetingParticipants: {
    color: "inherit",
    fontSize: "inherit",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.25,
    marginRight: theme.spacing.unit * 0.5,
  },
  meetingActionItemsCount: {
    fontSize: "inherit",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.25,
    marginRight: theme.spacing.unit * 0.5,
  },
  actionItemsNone: {
    color: theme.palette.primary.grey,
  },
  actionItemsExist: {
    color: theme.palette.primary.lightGreen,
  },
  seeMoreButton: {
    marginTop: "auto",
    fontSize: "15px",
    padding: theme.spacing.unit * 1.5,
  },
  emptyView: {
    padding: theme.spacing.unit * 4,
    margin: "auto",
  },
  emptyCopy: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: "18px",
    "& > *": {
      marginBottom: theme.spacing.unit,
    },
    "& > button:last-child": {
      marginTop: theme.spacing.unit * 2,
    },
  },
  indicator: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.2),
    color: theme.palette.warning.main,
    fontSize: "14px",
  },
  indicatorIcon: {
    width: "16px",
    height: "16px",
    animation: "$wiggle 10s linear infinite",
  },
  meetingActionItems: {
    marginBottom: theme.spacing.unit,
    "& > li": {
      listStyleType: "none",
    },
  },
  expandButton: {
    display: "flex",
    marginRight: theme.spacing.unit * 2,
    marginLeft: "auto",
    fontSize: "12px",
  },
  disabled: {
    opacity: "0.5",
  },
  willNotJoinIcon: {
    color: theme.palette.danger.main,
  },
  removeAssistantIcon: {
    color: theme.palette.primary.red,
  },
  addAssistantIcon: {
    color: theme.palette.primary.green,
  },
  "@keyframes wiggle": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "2%": {
      transform: "rotate(15deg)",
    },
    "3%": {
      transform: "rotate(-15deg)",
    },
    "5%": {
      transform: "rotate(0deg)",
    },
  },
});
