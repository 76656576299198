import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

class SpeakerEnrollment extends Component {
  state = {
    statusMsg: "",
    statusButtonTitle: "ENROLL MY SPEAKER PROFILE",
    isEnabled: false,
  };

  componentDidMount() {
    var isEnrolled = localStorage.getItem("isEnrolled") === "true";
    if (isEnrolled) {
      this.setState({
        statusMsg: "Status: Enrolled",
        isEnabled: true,
        statusButtonTitle: "UPDATE MY SPEAKER PROFILE",
      });
    } else {
      this.setState({
        statusMsg: "Status: Not Enrolled",
        isEnabled: false,
        statusButtonTitle: "ENROLL MY SPEAKER PROFILE",
      });
    }
  }

  handleEnroll = () => {
    if (this.state.isEnabled == false) {
      ReactGA.event({
        category: "User",
        action: "User clicked enroll speaker profile button",
      });
    }
    this.props.enrollSpeaker();
  };

  renderFooter = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <PortletFooter className={classes.portletFooter}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="small"
          variant="text"
          onClick={this.handleEnroll}
        >
          {this.state.statusButtonTitle}
        </Button>
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Speaker Identification & Enrollment" />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <div className={classes.instructions}>
            <Typography className={classes.caption} variant="body1">
              We can automatically identify you from other participants in your
              meetings to provide better insights. To enable this, please enroll
              your speaker profile.
            </Typography>
          </div>
          <div className={classes.instructions}>
            <Typography
              className={
                this.state.isEnabled
                  ? classes.enabledStatus
                  : classes.disabledStatus
              }
              variant="h5"
            >
              {this.state.statusMsg}
            </Typography>
          </div>
        </PortletContent>
        {this.renderFooter()}
      </Portlet>
    );
  }
}

SpeakerEnrollment.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpeakerEnrollment);
