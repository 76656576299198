import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Checkbox,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
} from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";

import ReactGA from "react-ga";
// Component styles
import styles from "./styles";
import { meetingParticipantTypes } from "helpers";

class AssistantPrefs extends Component {
  state = {
    isAutomaticCallInEnabled: true,
    joinNonHostMeetings: false,
    isMeetingAssistantNotificationEnabled: false,
    meetingAssistantNotificationValue: 60,
    isBotChimeEnabled: true,
    isOrgSettingsEnabled: false,
    processMeetingParticipantLevel: meetingParticipantTypes.all,
  };

  componentDidMount() {
    const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
    this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
    if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
      this.setPrefs();
    } else {
      this.setOrgPrefs(isOrgSettingsEnabled);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.onOrgTab !== this.props.onOrgTab ||
      prevProps.orgSettings !== this.props.orgSettings
    ) {
      const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
      this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
      if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
        this.setPrefs();
      } else {
        this.setOrgPrefs(isOrgSettingsEnabled);
      }
    }
  }

  setPrefs = () => {
    var isAutomaticCallInEnabled = JSON.parse(
      localStorage.getItem("isAutomaticCallInEnabled")
    );
    var isBotChimeEnabled = JSON.parse(
      localStorage.getItem("isBotChimeEnabled")
    );
    var processMeetingParticipantLevel = JSON.parse(
      localStorage.getItem("processMeetingParticipantLevel")
    );
    var joinNonHostMeetings = JSON.parse(
      localStorage.getItem("joinNonHostMeetings")
    );
    var isMeetingAssistantNotificationEnabled = JSON.parse(
      localStorage.getItem("isMeetingAssistantNotificationEnabled")
    );
    var meetingAssistantNotificationValue = JSON.parse(
      localStorage.getItem("meetingAssistantNotificationValue")
    );

    this.setState({
      isAutomaticCallInEnabled: isAutomaticCallInEnabled,
      joinNonHostMeetings: joinNonHostMeetings,
      isBotChimeEnabled: isBotChimeEnabled,
      processMeetingParticipantLevel: processMeetingParticipantLevel,
      isMeetingAssistantNotificationEnabled: isMeetingAssistantNotificationEnabled,
      meetingAssistantNotificationValue: meetingAssistantNotificationValue,
    });
  };

  setOrgPrefs = (isOrgSettingsEnabled) => {
    if (isOrgSettingsEnabled) {
      const { orgSettings } = this.props;

      this.setState({
        isAutomaticCallInEnabled: orgSettings.is_automatic_call_in_enabled,
        joinNonHostMeetings: orgSettings.join_non_host_meetings,
        isBotChimeEnabled: orgSettings.is_bot_chime_enabled,
        processMeetingParticipantLevel:
          orgSettings.process_meeting_participant_level,
        isMeetingAssistantNotificationEnabled:
          orgSettings.is_meeting_assistant_notification_enabled,
        meetingAssistantNotificationValue:
          orgSettings.meeting_assistant_notification_value,
      });
    } else {
      this.setState({
        isAutomaticCallInEnabled: false,
        joinNonHostMeetings: false,
        isBotChimeEnabled: false,
        processMeetingParticipantLevel: meetingParticipantTypes.all,
        isMeetingAssistantNotificationEnabled: false,
        meetingAssistantNotificationValue: 60,
      });
    }
  };

  isOrgSettingsEnabled = () => {
    const { orgSettings } = this.props;
    return (
      orgSettings.is_automatic_call_in_enabled != null &&
      orgSettings.join_non_host_meetings != null &&
      orgSettings.is_bot_chime_enabled != null &&
      orgSettings.is_meeting_assistant_notification_enabled != null
    );
  };

  handleSave = () => {
    var prefs = {
      isAutomaticCallInEnabled: this.state.isAutomaticCallInEnabled,
      joinNonHostMeetings: this.state.joinNonHostMeetings,
      isBotChimeEnabled: this.state.isBotChimeEnabled,
      processMeetingParticipantLevel: this.state.processMeetingParticipantLevel,
      isMeetingAssistantNotificationEnabled: this.state
        .isMeetingAssistantNotificationEnabled,
      meetingAssistantNotificationValue: this.state
        .meetingAssistantNotificationValue,
    };
    this.props.saveHandler(prefs);
    ReactGA.event({
      category: "Bot",
      action:
        "Bot is " + (prefs.isAutomaticCallInEnabled ? "enabled" : "disabled"),
    });
  };

  handleOrgSettingsToggle = (event) => {
    if (event.target.checked) {
      this.setState({
        isOrgSettingsEnabled: true,
        processMeetingParticipantLevel: null,
      });
    } else {
      const prefs = {
        isAutomaticCallInEnabled: null,
        joinNonHostMeetings: null,
        processMeetingParticipantLevel: null,
        isBotChimeEnabled: null,
        isMeetingAssistantNotificationEnabled: null,
        meetingAssistantNotificationValue: null,
      };
      this.props.saveHandler(prefs).then(() => {
        this.setState({ isOrgSettingsEnabled: false });
        this.props.openSnackbar("Settings updated");
      });
    }
  };

  handleCallInChange = (event) => {
    this.setState({
      isAutomaticCallInEnabled: event.target.checked,
    });
  };

  handleJoinNonHostChange = (event) => {
    this.setState({
      joinNonHostMeetings: event.target.checked,
    });
  };

  handleBotChimeChange = (event) => {
    this.setState({
      isBotChimeEnabled: event.target.checked,
    });
  };

  handleProcessMeetingParticipantLevelChange = (event) => {
    this.setState({
      processMeetingParticipantLevel: event.target.value,
    });
  };

  handleMeetingAssistantNotificationChange = (event) => {
    this.setState({
      isMeetingAssistantNotificationEnabled: event.target.checked,
      meetingAssistantNotificationValue: event.target.checked ? 60 : null,
    });
  };

  handleMeetingAssistantNotificationValueChange = (event) => {
    this.setState({
      meetingAssistantNotificationValue: event.target.value,
    });
  };

  render() {
    const { classes, className, onOrgTab, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Sonero AI Assistant Preferences" />
          {onOrgTab && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Off</Typography>
              <Switch
                color="primary"
                checked={this.state.isOrgSettingsEnabled}
                onChange={this.handleOrgSettingsToggle}
              />
              <Typography>On</Typography>
            </div>
          )}
        </PortletHeader>
        {!onOrgTab && this.state.isOrgSettingsEnabled && (
          <PortletContent className={classes.info}>
            <Typography variant="h6">
              Your settings and preferences are managed by your organization
              owner. <br />
              Please contact them for any changes.
            </Typography>
          </PortletContent>
        )}
        <PortletContent noPadding>
          <form className={classes.form}>
            <div className={classes.group}>
              <div className={classes.firstField}>
                <Checkbox
                  color="primary"
                  onChange={this.handleCallInChange}
                  checked={this.state.isAutomaticCallInEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    I want the Sonero AI Assistant to join my meetings
                    automatically (Recommended)
                  </Typography>
                  <Typography variant="caption">
                    Our AI Assistant will join your meetings as a participant.
                    You can easily remove the assistant from the call if needed.
                    If this option is unchecked (not recommended), you need to
                    add <b>hello@sonero.ai</b> as a participant in the calendar
                    invite for the the AI Assistant to join the meeting.
                  </Typography>
                </div>
              </div>
              {this.state.isAutomaticCallInEnabled && (
                <div className={classes.indentedField}>
                  <Typography variant="body1">
                    Join and process these types of meetings
                  </Typography>
                  <RadioGroup
                    value={this.state.processMeetingParticipantLevel}
                    onChange={this.handleProcessMeetingParticipantLevelChange}
                    className={classes.radioSubfield}
                  >
                    <FormControlLabel
                      value={meetingParticipantTypes.all}
                      control={<Radio color="primary" />}
                      label="All"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                    <FormControlLabel
                      value={meetingParticipantTypes.internal}
                      control={<Radio color="primary" />}
                      label="Internal Only"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                    <FormControlLabel
                      value={meetingParticipantTypes.external}
                      control={<Radio color="primary" />}
                      label="External Only"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                  </RadioGroup>
                </div>
              )}

              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleJoinNonHostChange}
                  checked={this.state.joinNonHostMeetings}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Allow the Sonero AI Assistant to join meetings for which I
                    am <strong>not</strong> the host
                  </Typography>
                  <Typography variant="caption">
                    Our AI Assistant will only join if you have accepted the
                    meeting invitation.
                  </Typography>
                </div>
              </div>

              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleMeetingAssistantNotificationChange}
                  checked={this.state.isMeetingAssistantNotificationEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Notify meeting attendees about the AI Assistant
                  </Typography>
                  <Typography variant="caption">
                    We will send an email to the other meeting participants
                    letting them know that your assistant will be joining the
                    call.
                  </Typography>
                </div>
              </div>
              {this.state.isMeetingAssistantNotificationEnabled && (
                <div className={classes.indentedField}>
                  <Typography variant="body1">
                    How long before the meeting should we notify participants?
                  </Typography>
                  <RadioGroup
                    value={"" + this.state.meetingAssistantNotificationValue}
                    onChange={
                      this.handleMeetingAssistantNotificationValueChange
                    }
                    className={classes.radioSubfield}
                  >
                    <FormControlLabel
                      value="60"
                      control={<Radio color="primary" />}
                      label="60 minutes before"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                    <FormControlLabel
                      value="30"
                      control={<Radio color="primary" />}
                      label="30 minutes before"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                    <FormControlLabel
                      value="15"
                      control={<Radio color="primary" />}
                      label="15 minutes before"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio color="primary" />}
                      label="5 minutes before"
                      disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                    />
                  </RadioGroup>
                </div>
              )}
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            onClick={this.handleSave}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

AssistantPrefs.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssistantPrefs);
