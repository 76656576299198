import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Menu, TextField, Button } from "@material-ui/core";

function ClipCreateForm(props) {
  const { classes, open, mouseY, mouseX, onClose, onCreateClip } = props;
  const [clipName, setClipName] = useState("");

  useEffect(() => {
    setClipName("");
  }, [props]);

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY != null && mouseX != null
          ? { top: mouseY, left: mouseX }
          : { top: 0, left: 0 }
      }
      open={open}
      onClose={onClose}
    >
      <form className={classes.clipMenuForm} onSubmit={onCreateClip(clipName)}>
        <TextField
          label="&#8203;Name (Optional)"
          variant="outlined"
          size="small"
          autoFocus
          value={clipName}
          onChange={(e) => setClipName(e.target.value)}
        />
        <Button
          className={classes.clipMenuButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          Create Clip
        </Button>
      </form>
    </Menu>
  );
}

export default withStyles(styles)(ClipCreateForm);
