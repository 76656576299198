import { fade } from "@material-ui/core";

export default (theme) => ({
  sectionHeader: {
    padding: theme.spacing.unit * 2,
    fontWeight: 700,
    background: theme.palette.background.blue,
    color: theme.palette.text.dark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  graphViewTabs: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
  tabButton: {
    color: theme.palette.primary.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    fontSize: "12px",
  },
  selected: {
    "&&": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
  overviewGraphContainer: {
    height: "330px",
    width: "100%",
  },
  trackerContainer: {
    height: "360px",
    width: "100%",
    overflowY: "scroll",
  },
  trackerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.primary.light,
  },
  trackerContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  },
  trackerMeetingTitle: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  trackerSentence: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
    borderRadius: "5px",
    cursor: "pointer",
    transition: "margin-left 0.25s",
    "&:hover": {
      marginLeft: theme.spacing(2.5),
    },
    "&:hover > *": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  boldText: {
    fontWeight: 600,
    fontSize: "18px",
  },
  highlightText: {
    fontWeight: 600,
    color: theme.palette.primary.orange,
    backgroundColor: "transparent",
  },
  emptyView: {
    height: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  emptyText: {
    color: theme.palette.text.secondary,
    fontSize: "18px",
    fontWeight: 500,
  },
  overtimeDropdown: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
  },
  dropdownSelectRoot: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.5),
    minWidth: "90px",
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
  },
  overtimeGraphContainer: {
    height: "270px",
    width: "100%",
  },
  tooltip: {
    position: "absolute",
    background: theme.palette.background.lightGrey,
    borderRadius: "5px",
    padding: theme.spacing.unit,
    boxShadow: `0 2px 8px 1px ${theme.palette.border}`,
    width: "200px",
    overflow: "hidden",
  },
});
