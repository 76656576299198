import React from "react";
import { withStyles } from "@material-ui/styles";
import { ResponsiveLine } from "@nivo/line";
import ReactGA from "react-ga";
import theme from "theme";

const style = (theme) => ({
  tooltip: {
    position: "absolute",
    background: theme.palette.background.lightGrey,
    borderRadius: "5px",
    padding: theme.spacing.unit,
    boxShadow: `0 2px 8px 1px ${theme.palette.border}`,
    width: "200px",
    overflow: "hidden",
  },
  tooltipMeetingTitle: {
    display: "block",
    marginBottom: theme.spacing.unit * 0.5,
  },
  tooltipScore: {
    display: "inline-block",
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 1.5}px`,
    borderRadius: "5px",
    "&.good": {
      backgroundColor: theme.palette.success.main,
    },
    "&.ok": {
      backgroundColor: theme.palette.warning.main,
    },
    "&.bad": {
      backgroundColor: theme.palette.danger.mellow,
    },
  },
});

function OverTimeGraph(props) {
  const { classes, data, handleNavigateToMeeting } = props;
  return (
    <ResponsiveLine
      data={data}
      yScale={{
        type: "linear",
        min: 0,
        max: 10,
      }}
      margin={{ top: 40, right: 60, bottom: 60, left: 70 }}
      xScale={{ type: "point" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickRotation: 0,
        tickPadding: 10,
        legend: "Meetings",
        legendPosition: "middle",
        legendOffset: 20,
        format: () => "",
      }}
      axisLeft={{
        tickValues: [0, 2, 4, 6, 8, 10],
        legend: "Score",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      colors={theme.palette.nivoDark2[0]}
      pointSize={3}
      pointColor={{ theme: "background" }}
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      enableSlices={false}
      useMesh={true}
      crosshairType="bottom"
      tooltip={(e) => {
        // Format meeting title and score
        let meetingTitle = e.point.data.x;
        let endIdx = meetingTitle.lastIndexOf(" - ");
        if (endIdx !== -1) {
          meetingTitle = meetingTitle.slice(0, endIdx);
        }
        const score = Math.round(e.point.data.y);
        const scoreLabel = score >= 7 ? "good" : score >= 4 ? "ok" : "bad";
        // Update tooltip position
        const style = {
          top: e.point.data.y > 5 && 20,
          bottom: e.point.data.y <= 5 && 20,
          left: e.point.data.firstHalf && 0,
          right: !e.point.data.firstHalf && 0,
        };
        return (
          <div className={classes.tooltip} style={style}>
            <strong className={classes.tooltipMeetingTitle}>
              {meetingTitle}
            </strong>
            <div>
              Score:{" "}
              <strong className={`${classes.tooltipScore} ${scoreLabel}`}>
                {score}
              </strong>
            </div>
          </div>
        );
      }}
      onClick={(point) => {
        ReactGA.event({
          category: "Dashboard",
          action: "Meeting Efficiency Over time graph clicked",
        });
        handleNavigateToMeeting(point.data.uuid);
      }}
    />
  );
}

export default withStyles(style)(OverTimeGraph);
