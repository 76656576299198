import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Button, Typography } from "@material-ui/core";

// Shared components
import { Portlet, PortletContent, IOSSwitch } from "components";

import { meetingLimits } from "helpers";
import pricingTiers, { getPrice } from "helpers/pricingTiers";

// Component styles
import styles from "./styles";

class ProTier extends Component {
  handleStartProfessional = () => {
    this.props.handleChangePricingTier(pricingTiers.professional);
    this.props.handleScrollToCheckoutForm();
  };

  handleStartBusiness = () => {
    this.props.handleChangePricingTier(pricingTiers.business);
    this.props.handleScrollToCheckoutForm();
  };

  handleStartEssential = () => {
    this.props.handleChangePricingTier(pricingTiers.essential, 1);
    this.props.handleScrollToCheckoutForm();
  };

  renderCallToActionIfNeeded = () => {
    var cta = "";
    if (
      this.props.pricingTier == pricingTiers.proTrial ||
      this.props.pricingTier == pricingTiers.timeboxTrial
    ) {
      cta =
        "You are currently using the trial version of Sonero Pro. You may upgrade to our paid subscription at your convenience before the trial period ends.";
    } else if (this.props.pricingTier === pricingTiers.free) {
      cta = `You are currently using Sonero Free tier. You get free analysis for ${meetingLimits.freeTier.numMeetings} meetings per month with a maximum duration of ${meetingLimits.freeTier.duration} minutes each. You may upgrade to Sonero Pro for unlimited usage.`;
    } else {
      return;
    }

    const { classes, className, ...rest } = this.props;

    return (
      <Portlet {...rest} className={classes.proTrialCTAContainer}>
        <PortletContent>
          <Typography className={classes.proTrialCTAText} variant="h6">
            {cta}
          </Typography>
        </PortletContent>
      </Portlet>
    );
  };

  render() {
    const { classes, billedAnnually, handleToggleAnnualBilling } = this.props;
    const professionalPrice = getPrice(
      pricingTiers.professional,
      billedAnnually
    );
    const businessPrice = getPrice(pricingTiers.business, billedAnnually);
    const essentialPrice = getPrice(pricingTiers.essential, billedAnnually);

    return (
      <>
        {this.renderCallToActionIfNeeded()}
        <div className={classes.content}>
          <div className={classes.infoContainer}>
            <div className={classes.annualToggle}>
              <Typography className={classes.monthlyText}>
                Billed monthly
              </Typography>
              <IOSSwitch
                checked={billedAnnually}
                onChange={handleToggleAnnualBilling}
              />
              <Typography className={classes.annuallyText}>
                Billed annually
              </Typography>
            </div>
            <Typography className={classes.infoText}>
              Save up to 20% with annual plans
            </Typography>
          </div>
          <div className={classes.optionsContainer}>
            <div className={classes.option}>
              <Typography className={classes.optionTitle}>Essential</Typography>
              <Typography className={classes.optionPrice}>
                <span>${essentialPrice}</span>/ per seat / month
              </Typography>
              <Typography className={classes.optionBody}>
                Personal license
              </Typography>
              <Typography className={classes.optionBody}>
                20 hours a month, incl. uploads
              </Typography>
              <Typography className={classes.optionBody}>
                2 hours per meeting
              </Typography>
              <Typography className={classes.optionBody}>
                Rich insights and analytics
              </Typography>
              <Button
                variant="outlined"
                className={classes.optionButton}
                color="primary"
                onClick={this.handleStartEssential}
              >
                START ESSENTIAL
              </Button>
            </div>
            <div className={`${classes.option} ${classes.popular}`}>
              <Typography className={classes.optionTitle}>
                Professional
              </Typography>
              <Typography className={classes.optionPrice}>
                <span>${professionalPrice}</span>/ per seat / month
              </Typography>
              <Typography className={classes.optionBody}>
                Up to 5 licenses
              </Typography>
              <Typography className={classes.optionBody}>
                50 hours a month, incl. uploads
              </Typography>
              <Typography className={classes.optionBody}>
                2 hours per meeting
              </Typography>
              <Typography className={classes.optionBody}>
                Rich insights and analytics
              </Typography>
              <Button
                variant="contained"
                className={classes.optionButton}
                color="primary"
                onClick={this.handleStartProfessional}
              >
                START PROFESSIONAL
              </Button>
            </div>
            <div className={classes.option}>
              <Typography className={classes.optionTitle}>Business</Typography>
              <Typography className={classes.optionPrice}>
                <span>${businessPrice}</span>/ per seat / month
              </Typography>
              <Typography className={classes.optionBody}>
                Up to 20 licenses
              </Typography>
              <Typography className={classes.optionBody}>
                Unlimited meetings
              </Typography>
              <Typography className={classes.optionBody}>
                Unlimited uploads
              </Typography>
              <Typography className={classes.optionBody}>
                Unlimited meeting duration
              </Typography>
              <Typography className={classes.optionBody}>
                Custom assistant name
              </Typography>
              <Button
                variant="outlined"
                className={classes.optionButton}
                color="primary"
                onClick={this.handleStartBusiness}
              >
                START BUSINESS
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProTier.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProTier);
