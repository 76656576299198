import React, { Component, Fragment } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles, TextareaAutosize } from "@material-ui/core";

// Material components
import {
  Typography,
  Link,
  IconButton,
  Card,
  Box,
  Tooltip,
  Popover,
} from "@material-ui/core";

// Material icons
import {
  AddCircle as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  VpnKey,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  SpeakerNotes as FormatListNumberedIcon,
} from "@material-ui/icons";

// Services
import { updateMeetingSummary } from "services/speech";

// Shared components
import { Paper } from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";
import { timeFormatter } from "../../../../helpers/time";

// Material TreeView
import TreeItem from "@material-ui/lab/TreeItem";

import {
  AddMeetingSummary,
  DeleteMeetingSummary,
  QuestionAnswerButton,
} from "views/ViewMeeting/components";

import { isElementInVisibleRatio } from "../../../../helpers/metricScrollBounds";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class Summary extends Component {
  state = {
    data: {},
    title: "MEETING RECAP",
    summary: [],
    isGeneralSummary: false,
    hasKeyPoints: false,
    hoveredNodeId: "",
    openAddSummaryDialog: false,
    updateSummary: [],
    addTopicPos: -1,
    openDeleteSummaryDialog: false,
    deleteSummaryItem: false,
    questionAnswerPopoverOpen: false,
    questionAnswerPopoverAnswer: "",
    questionAnswerPopoverAnchorEl: null,
    editingBodyPos: -1,
    editingItemPos: -1,
    editingText: "",
    hasKeyPoints: false,
  };
  intersectTargetKeyPoints = React.createRef();
  intersectTargetTopics = React.createRef();
  idToIndex = {
    keyPoints: 0,
    topics: 1,
  };
  observerScrollNotes = null;
  timeSpentArr = new Array(2).fill(0);

  mapIdToTimeSpent = (target) => {
    if (target in this.idToIndex) {
      return this.timeSpentArr[this.idToIndex[target]];
    }
    return 0;
  };

  componentWillMount() {
    var summary = this.props.data.agenda_summary;
    if (summary == null || summary.length == null || summary.length == 0) {
      summary = this.props.data.general_summary;
      this.setState({
        summary: summary,
        title: "MEETING RECAP",
        isGeneralSummary: true,
        hasKeyPoints: this.checkKeyPoints(summary, true),
      });
    } else {
      this.setState({
        summary: summary,
      });
    }

    const opts = {
      root: null,
      rootMargin: "0px 0px 0px",
      threshold: [0.35],
    };

    // Callback is triggered based on how much of threshold is seen
    const callback = (list) => {
      list.forEach((entry) => {
        let currId = entry.target.id;
        if (entry.isIntersecting && this.mapIdToTimeSpent(currId) === 0) {
          this.timeSpentArr[this.idToIndex[currId]] = new Date();
        } else {
          let intRatio = parseFloat(entry.intersectionRatio);
          if (
            isElementInVisibleRatio(intRatio) &&
            this.mapIdToTimeSpent(currId) !== 0
          ) {
            let currTime = new Date();
            let spentTime = currTime - this.mapIdToTimeSpent(currId);
            ReactGA.event({
              category: gaCategoryViewMeeting(),
              action: "Spent time on " + entry.target.id + " is " + spentTime,
              label: "Notes",
            });
            this.timeSpentArr[this.idToIndex[currId]] = 0;
          }
        }
      });
    };
    this.observerScrollNotes = new IntersectionObserver(callback, opts);
  }

  componentDidMount() {
    if (this.observerScrollNotes && this.intersectTargetKeyPoints.current) {
      this.observerScrollNotes.observe(this.intersectTargetKeyPoints.current);
    }
    if (this.observerScrollNotes && this.intersectTargetTopics.current) {
      this.observerScrollNotes.observe(this.intersectTargetTopics.current);
    }
  }

  capitalizeFirstLetter(string) {
    if (string.length > 1) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else if (string.length === 1) {
      return string.charAt(0).toUpperCase();
    } else {
      return string;
    }
  }

  addSummary = () => {
    const { classes, className, ...rest } = this.props;
    if (
      this.state.summary == null ||
      this.state.summary.length == null ||
      this.state.summary.length == 0
    ) {
      return this.addEmptyState();
    }
    if (this.state.isGeneralSummary == true) {
      var keyPoints = [];
      var groups = [];
      if (this.state.hasKeyPoints) {
        keyPoints = this.state.summary[0].summary;
        if (this.state.summary.length > 1) groups = this.state.summary.slice(1);
      } else {
        groups = this.state.summary;
      }
      return (
        <>
          {this.addKeyPoints(keyPoints)}
          <div ref={this.intersectTargetTopics} id="topics">
            {groups.map((e, bodyPos) => {
              return this.addLinearSummaryBody(e, bodyPos);
            })}
          </div>
        </>
      );
    }
    return (
      <div>
        {this.state.summary.map((e, bodyPos) => {
          return this.addAgendaSummaryBody(
            e.agenda_item,
            e.agenda_item_notes,
            e.summary,
            e.frequent_words,
            bodyPos
          );
        })}
      </div>
    );
  };

  addKeyPoints = (keyPoints) => {
    const { classes, className, data, ...rest } = this.props;
    const bodyPos = -1;
    if (keyPoints.length === 0) {
      return;
    }
    return (
      <Card className={classes.keyPointsCard} elevation={0}>
        <Box className={classes.keyPointsBox} flexGrow={1}>
          <Typography className={classes.keyPointsHeader} variant="h5">
            Key Points
            {data.is_participant_editing_enabled && (
              <Fragment>
                <IconButton
                  className={`${classes.addIcon} iconOption`}
                  aria-label="add"
                  onClick={() => {
                    this.addNewSummaryTopicItem(bodyPos);
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
                <IconButton
                  className={`${classes.deleteIcon} iconOption`}
                  aria-label="delete"
                  onClick={() => {
                    this.deleteSummaryTopic(bodyPos);
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Fragment>
            )}
          </Typography>
          <div>
            <ul ref={this.intersectTargetKeyPoints} id="keyPoints">
              {keyPoints.map((e, itemPos) => {
                return this.addKeyItem(
                  e.phrase,
                  e.timestamp,
                  itemPos,
                  false,
                  e.isAnswer,
                  e.isQuestion,
                  e.answer,
                  e.question
                );
              })}
            </ul>
          </div>
        </Box>
      </Card>
    );
  };

  addLinearSummaryBody = (group, bodyPos) => {
    const { classes, className, data, ...rest } = this.props;
    var topic = group.topic;
    var summaryItems = group.summary ?? [];
    return (
      <Card className={classes.agendaItem} elevation={0}>
        <Box className={classes.box} flexGrow={1}>
          <Typography className={classes.agendaTopic} variant="h5">
            {topic
              ? "Topic: " + this.capitalizeFirstLetter(topic)
              : "Key Point"}
            {data.is_participant_editing_enabled && (
              <Fragment>
                <IconButton
                  className={`${classes.addIcon} iconOption`}
                  aria-label="add"
                  onClick={() => {
                    this.addNewSummaryTopicItem(bodyPos);
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
                <IconButton
                  className={`${classes.deleteIcon} iconOption`}
                  aria-label="delete"
                  onClick={() => {
                    this.deleteSummaryTopic(bodyPos);
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Fragment>
            )}
          </Typography>
          <div>
            <ul>
              {summaryItems.map((e, itemPos) => {
                return this.addSummaryItem(
                  e.phrase,
                  e.timestamp,
                  bodyPos,
                  itemPos,
                  e.important,
                  e.isAnswer,
                  e.isQuestion,
                  e.answer,
                  e.question
                );
              })}
            </ul>
          </div>
        </Box>
      </Card>
    );
  };

  addAgendaSummaryBody = (
    agendaItem,
    agendaItemNotes,
    summary,
    frequentWords,
    bodyPos
  ) => {
    const { classes, className, data, ...rest } = this.props;
    summary.sort((a, b) => {
      if (a.timestamp && b.timestamp) {
        return a.timestamp > b.timestamp ? 1 : -1;
      }
      // Push custom items with no timestamp to end
      return a.timestamp ? -1 : 1;
    });
    var summaryTitle =
      summary.length === 0
        ? "This item was not discussed in this meeting."
        : "Discussion Recap:";
    return (
      <Card className={classes.agendaItem} elevation={0}>
        <Box className={classes.box} flexGrow={1}>
          <Typography className={classes.agendaTopic} variant="h5">
            {agendaItem}
            {data.is_participant_editing_enabled && (
              <Fragment>
                <IconButton
                  className={`${classes.addIcon} iconOption`}
                  aria-label="add"
                  onClick={() => {
                    this.addNewSummaryTopicItem(bodyPos);
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
                <IconButton
                  className={`${classes.deleteIcon} iconOption`}
                  aria-label="delete"
                  onClick={() => {
                    this.deleteSummaryTopic(bodyPos);
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Fragment>
            )}
          </Typography>
          {agendaItemNotes && agendaItemNotes !== "" && (
            <Typography className={classes.notes} variant="body1">
              {agendaItemNotes}
            </Typography>
          )}
          <Typography className={classes.summaryTitle} variant="body1">
            {summaryTitle}
          </Typography>
          <div>
            <ul>
              {summary.map((e, itemPos) => {
                return this.addSummaryItem(
                  e.phrase,
                  e.timestamp,
                  bodyPos,
                  itemPos
                );
              })}
            </ul>
          </div>
        </Box>
      </Card>
    );
  };

  addGeneralSummaryItem = (topic, phrases, pos) => {
    const { classes, className, ...rest } = this.props;
    return (
      <TreeItem
        nodeId={pos}
        onLabelClick={(event) => {
          event.preventDefault();
        }}
        onMouseEnter={() => this.setState({ hoveredNodeId: pos + ", " + "-1" })}
        onMouseLeave={() => this.setState({ hoveredNodeId: "" })}
        label={
          <div className={classes.summaryItemWrapper}>
            <div className={classes.summaryItemTitle}>
              <Typography className={classes.summaryItem} variant="h5">
                Topic: {topic}
              </Typography>
              {this.props.data.is_participant_editing_enabled && (
                <IconButton
                  className="iconOption"
                  style={{ visibility: "hidden" }}
                  aria-label="add"
                  onClick={() => {
                    this.addNewSummaryTopicItem(pos);
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
              )}
            </div>
            {this.props.data.is_participant_editing_enabled &&
              this.state.hoveredNodeId === pos + ", " + -1 && (
                <IconButton
                  className="iconOption"
                  aria-label="delete"
                  size="small"
                  onClick={() => this.deleteSummaryTopic(pos)}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              )}
          </div>
        }
      >
        {phrases &&
          phrases.map((e) => {
            return this.addPhrase(
              e.phrase,
              e.timestamp,
              pos,
              phrases.indexOf(e)
            );
          })}
      </TreeItem>
    );
  };

  addPhrase = (phrase, timestamp, pos, phrasePos) => {
    const { classes, className, ...rest } = this.props;
    return (
      <TreeItem
        nodeId={pos + "," + phrasePos}
        onMouseEnter={() =>
          this.setState({ hoveredNodeId: pos + ", " + phrasePos })
        }
        onMouseLeave={() => this.setState({ hoveredNodeId: pos + ", " + "-1" })}
        onLabelClick={() => {
          if (timestamp) {
            this.handleTimestampClicked(timestamp);
          }
        }}
        label={
          <Typography className={classes.summaryPhrase} variant="body1">
            <a>{phrase}</a>
            <a>{this.setTimestampLink(timestamp)}</a>
            {this.props.data.is_participant_editing_enabled &&
              this.state.hoveredNodeId === pos + ", " + phrasePos && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={classes.phraseDeleteButton}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.deleteSummaryTopicItem(pos, phrasePos);
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              )}
          </Typography>
        }
      />
    );
  };

  deleteSummaryTopic = (pos) => {
    const { hasKeyPoints } = this.state;
    const summary = JSON.parse(JSON.stringify(this.state.summary));

    // + 1 to handle key points
    if (hasKeyPoints) {
      pos += 1;
    }
    if (pos !== -1) {
      summary.splice(pos, 1);
      this.setState({
        openDeleteSummaryDialog: true,
        updateSummary: summary,
        deleteSummaryItem: false,
      });
    }
  };

  deleteSummaryTopicItem = (pos, phrasePos) => {
    const summary = JSON.parse(JSON.stringify(this.state.summary));
    if (pos !== -1 && phrasePos !== -1) {
      summary[pos].summary.splice(phrasePos, 1);
      this.setState({
        openDeleteSummaryDialog: true,
        updateSummary: summary,
        deleteSummaryItem: true,
      });
    }
  };

  handleShowAnswer = (target, answer, elem) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Insight Answers " + elem,
      label: "Notes",
    });
    this.setState({
      questionAnswerPopoverOpen: true,
      questionAnswerPopoverAnswer: answer,
      questionAnswerPopoverQuestion: "",
      questionAnswerPopoverAnchorEl: target,
    });
  };

  handleShowQuestion = (target, question, elem) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Insight Questions " + elem,
      label: "Notes",
    });
    this.setState({
      questionAnswerPopoverOpen: true,
      questionAnswerPopoverAnswer: "",
      questionAnswerPopoverQuestion: question,
      questionAnswerPopoverAnchorEl: target,
    });
  };

  handleHideAnswer = () => {
    this.setState({
      questionAnswerPopoverOpen: false,
      questionAnswerPopoverAnswer: "",
      questionAnswerPopoverQuestion: "",
      questionAnswerPopoverAnchorEl: null,
    });
  };

  handleEditIconClick = (bodyPos, itemPos, phrase, elem) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Edit " + elem,
      label: "Notes",
    });
    this.setState({
      editingBodyPos: bodyPos,
      editingItemPos: itemPos,
      editingText: phrase,
    });
  };

  handleDeleteSummaryItem = (bodyPos, itemPos, elem) => {
    var { summary, hasKeyPoints } = this.state;
    // + 1 to account for key points
    if (hasKeyPoints) {
      bodyPos += 1;
    }
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Delete " + elem,
      label: "Notes",
    });

    summary[bodyPos].summary.splice(itemPos, 1);
    this.handleUpdateSummary(summary);
  };

  handleUpdateSummaryItem = () => {
    var {
      summary,
      editingBodyPos,
      editingItemPos,
      editingText,
      hasKeyPoints,
    } = this.state;
    // + 1 to account for key points
    if (hasKeyPoints) {
      editingBodyPos += 1;
    }
    summary[editingBodyPos].summary[editingItemPos].phrase = editingText;
    this.handleUpdateSummary(summary);
  };

  handleUpdateSummary = (summary) => {
    const { isGeneralSummary } = this.state;
    updateMeetingSummary(
      this.props.data.meeting_id,
      summary,
      !isGeneralSummary
    ).then(
      function(response) {
        if (response.status === 200) {
          this.props.summaryUpdateResponseHandler(summary, !isGeneralSummary);

          this.setState({ summary: summary });
        }
        this.handleEditingComplete();
      }.bind(this)
    );
  };

  handleEditingComplete = () => {
    this.setState({
      editingBodyPos: -1,
      editingItemPos: -1,
      editingText: "",
    });
  };

  addSummaryItem = (
    phrase,
    timestamp,
    bodyPos,
    itemPos,
    important,
    isAnswer,
    isQuestion,
    answer,
    question
  ) => {
    const { classes, className, data, ...rest } = this.props;

    if (
      this.state.editingBodyPos === bodyPos &&
      this.state.editingItemPos === itemPos &&
      data.is_participant_editing_enabled
    ) {
      return (
        <div className={classes.summaryItemContainer}>
          <TextareaAutosize
            className={classes.summaryItemEditing}
            value={this.state.editingText}
            onChange={(event) =>
              this.setState({ editingText: event.target.value })
            }
            autoFocus
          />
          <div>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={this.handleUpdateSummaryItem}
            >
              <CheckCircleIcon color="primary" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={this.handleEditingComplete}
            >
              <CancelIcon color="primary" />
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.summaryItemContainer}>
        <Typography className={classes.summaryItemPhrase}>
          <li>
            {phrase}
            {this.setTimestampLink(timestamp)}
            {data.is_participant_editing_enabled && (
              <Fragment>
                <IconButton
                  className="iconOption"
                  size="small"
                  style={{ visibility: "hidden" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleEditIconClick(bodyPos, itemPos, phrase, "Topic");
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  className="iconOption"
                  size="small"
                  style={{ visibility: "hidden" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleDeleteSummaryItem(bodyPos, itemPos, "Topic");
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Fragment>
            )}
          </li>
        </Typography>
        {important && (
          <Tooltip title="Insight: Key Point">
            <IconButton>
              <VpnKey className={classes.summaryItemImportant} />
            </IconButton>
          </Tooltip>
        )}
        {isAnswer && (
          <QuestionAnswerButton
            sentence={question}
            tooltip="Insight: Question"
            onClick={this.handleShowQuestion}
            keyPointOrTopic="Topic"
          />
        )}
        {isQuestion && (
          <QuestionAnswerButton
            sentence={answer}
            tooltip="Insight: Answer"
            onClick={this.handleShowAnswer}
            keyPointOrTopic="Topic"
          />
        )}
      </div>
    );
  };

  addKeyItem = (
    phrase,
    timestamp,
    itemPos,
    important,
    isAnswer,
    isQuestion,
    answer,
    question
  ) => {
    const { classes, className, data, ...rest } = this.props;
    const bodyPos = -1;
    if (
      this.state.editingBodyPos === bodyPos &&
      this.state.editingItemPos === itemPos &&
      data.is_participant_editing_enabled
    ) {
      return (
        <div className={classes.summaryItemContainer}>
          <TextareaAutosize
            className={classes.summaryItemEditing}
            value={this.state.editingText}
            onChange={(event) =>
              this.setState({ editingText: event.target.value })
            }
            autoFocus
          />
          <div>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={this.handleUpdateSummaryItem}
            >
              <CheckCircleIcon color="primary" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={this.handleEditingComplete}
            >
              <CancelIcon color="primary" />
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.summaryItemContainer}>
        <Typography className={classes.keyItemPhrase}>
          <li>
            {phrase}
            {this.setTimestampLink(timestamp)}
            {data.is_participant_editing_enabled && (
              <Fragment>
                <IconButton
                  className="iconOption"
                  size="small"
                  style={{ visibility: "hidden" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleEditIconClick(
                      bodyPos,
                      itemPos,
                      phrase,
                      "Keypoint"
                    );
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton
                  className="iconOption"
                  size="small"
                  style={{ visibility: "hidden" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleDeleteSummaryItem(bodyPos, itemPos, "Keypoint");
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Fragment>
            )}
          </li>
        </Typography>
        {important && (
          <Tooltip title="Insight: Key Point">
            <IconButton>
              <VpnKey className={classes.summaryItemImportant} />
            </IconButton>
          </Tooltip>
        )}
        {isAnswer && (
          <QuestionAnswerButton
            sentence={question}
            tooltip="Insight: Question"
            onClick={this.handleShowQuestion}
            keyPointOrTopic="KeyPoint"
          />
        )}
        {isQuestion && (
          <QuestionAnswerButton
            sentence={answer}
            tooltip="Insight: Answer"
            onClick={this.handleShowAnswer}
            keyPointOrTopic="KeyPoint"
          />
        )}
      </div>
    );
  };

  handleTimestampClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Summary",
    });
  };

  setTimestampLink = (timestamp) => {
    const { classes, className, ...rest } = this.props;
    if (
      timestamp === undefined ||
      !this.props.data._is_recording_enabled ||
      (this.props.isSharedMeetingView &&
        !this.props.data.share_granularity.share_recording)
    ) {
      return;
    }
    return (
      <Link
        component="button"
        className={classes.phraseTime}
        onClick={() => this.handleTimestampClicked(timestamp)}
      >
        ({timeFormatter(timestamp)})
      </Link>
    );
  };

  addEmptyState = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <div>
        <Typography className={classes.phrase} variant="body1">
          It didn't seem like there were any important meeting notes!
        </Typography>
      </div>
    );
  };

  addNewSummaryTopic = () => {
    const summary = JSON.parse(JSON.stringify(this.state.summary));
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Add Topic",
      label: "Notes",
    });
    this.setState({
      openAddSummaryDialog: true,
      updateSummary: summary,
      addTopicPos: -1,
    });
  };

  addNewSummaryTopicItem = (addTopicPos) => {
    const summary = JSON.parse(JSON.stringify(this.state.summary));

    // Account for key points
    if (this.state.hasKeyPoints) {
      addTopicPos += 1;
    }

    if (addTopicPos !== -1) {
      this.setState({
        openAddSummaryDialog: true,
        updateSummary: summary,
        addTopicPos: addTopicPos,
      });
    }
  };

  handleCloseAddSummary = () => {
    this.setState({
      addTopicPos: -1,
      openAddSummaryDialog: false,
      updateSummary: [],
    });
  };

  handleAddSummarySuccess = (summary) => {
    const { isGeneralSummary } = this.state;
    this.props.summaryUpdateResponseHandler(summary, !isGeneralSummary);
    this.setState({
      addTopicPos: -1,
      summary: summary,
    });
  };

  handleDeleteSummarySuccess = (summary) => {
    const { isGeneralSummary } = this.state;
    this.props.summaryUpdateResponseHandler(summary, !isGeneralSummary);
    this.setState({
      summary: summary,
      hasKeyPoints: this.checkKeyPoints(summary, isGeneralSummary),
    });
  };

  checkKeyPoints = (summary, isGeneralSummary) => {
    return isGeneralSummary && summary.length > 0 && summary[0].is_key_points;
  };

  renderAddSummaryDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <AddMeetingSummary
        open={this.state.openAddSummaryDialog}
        closeHandler={this.handleCloseAddSummary}
        summary={this.state.updateSummary}
        meetingId={this.props.data.meeting_id}
        successHandler={this.handleAddSummarySuccess}
        summaryPos={this.state.addTopicPos}
        isAgenda={!this.state.isGeneralSummary}
      />
    );
  };

  renderDeleteSummaryDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <DeleteMeetingSummary
        open={this.state.openDeleteSummaryDialog}
        closeHandler={this.handleCloseDeleteSummary}
        summary={this.state.updateSummary}
        meetingId={this.props.data.meeting_id}
        successHandler={this.handleDeleteSummarySuccess}
        summaryItem={this.state.deleteSummaryItem}
        isAgenda={!this.state.isGeneralSummary}
      />
    );
  };

  handleCloseDeleteSummary = () => {
    this.setState({
      openDeleteSummaryDialog: false,
      updateSummary: [],
      deleteSummaryItem: false,
    });
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    var insight = "";
    var sentence = "";

    if (this.state.questionAnswerPopoverQuestion) {
      insight = "Question";
      sentence = this.state.questionAnswerPopoverQuestion;
    } else if (this.state.questionAnswerPopoverAnswer) {
      insight = "Answer";
      sentence = this.state.questionAnswerPopoverAnswer;
    }

    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.content}>
          <div className={classes.iconWrapper}>
            <FormatListNumberedIcon className={classes.icon} />
          </div>
          <div className={classes.details}>
            <Typography className={classes.title} variant="h5">
              {this.state.title}
            </Typography>
          </div>
          {this.props.data.is_participant_editing_enabled && (
            <IconButton
              aria-label="add"
              onClick={() => {
                this.addNewSummaryTopic();
              }}
            >
              <AddIcon color="primary" />
            </IconButton>
          )}
        </div>
        <div className={classes.footer}>{this.addSummary()}</div>
        {this.renderAddSummaryDialog()}
        {this.renderDeleteSummaryDialog()}
        <Popover
          open={this.state.questionAnswerPopoverOpen}
          anchorEl={this.state.questionAnswerPopoverAnchorEl}
          onClose={this.handleHideAnswer}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Paper className={classes.questionPaper} elevation={0}>
            <Typography className={classes.insightTitle}>
              Insight: {insight}
            </Typography>
            <Typography className={classes.insightPhrase} variant="body1">
              {sentence}
            </Typography>
          </Paper>
        </Popover>
      </Paper>
    );
  }
}

Summary.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Summary);
