import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  textFieldsContainer: {
    display: "flex",
  },
  textField: {
    width: "200px",
    marginRight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
  },
  textFieldDescription: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  fieldMessage: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
    "& > ul": {
      paddingLeft: theme.spacing.unit * 4,
    }
  },
});

class MoveMeetingDialog extends Component {
  state = {
    isLoading: false,
  };

  renderDialog = () => {
    const { classes, className, ...rest } = this.props;
    let folderTitle = 'Folder'
    let meetings = []
    if (this.props.folderTitle) {
      folderTitle = this.props.folderTitle;
    }
    if (this.props.meetings) {
      meetings = this.props.meetings;
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.closeHandler}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Move Meeting to Folder</DialogTitle>
        <DialogContent>
          <Typography className={classes.fieldMessage} variant="body1">
            "{folderTitle}" is a shared folder. The meeting(s) you have selected 
            will be shared as well once they are moved to the folder. Do you still
            want to move them?
            <ul>
              {meetings.map((meeting) => <li>{meeting.title}</li>)}
            </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeHandler} color="primary">
            No
          </Button>
          {this.state.isLoading ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <Button onClick={this.props.handleMoveMeeting} color="primary">
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    return <div>{this.renderDialog()}</div>;
  }
}

MoveMeetingDialog.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MoveMeetingDialog);
