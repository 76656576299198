export default (theme) => ({
  container: {
    width: "max(60%, 300px)",
    margin: `${theme.spacing(9)}px auto`,
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    flexShrink: 0,
    marginBottom: theme.spacing.unit * 3,
  },
  logoImage: {
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    width: "fit-content",
    margin: `${theme.spacing(1.5)}px auto`,
    padding: theme.spacing(3),
  },
  contentBody: {
    fontSize: "1.5rem",
    lineHeight: "1.6",
  },
});
