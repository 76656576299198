import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import theme from "theme";

import { Dashboard as DashboardLayout } from "layouts";
import { Paper } from "components";
import {
  Analytics,
  MeetingScore,
  Meetings,
  Header,
  FAQ,
  Trackers,
} from "./components";
import { Box, Button, Snackbar, CircularProgress } from "@material-ui/core";

import {
  getDashboardData,
  getDashboardFAQ,
  deleteActionItem,
  completeActionItem,
  logViewMeetingDashboardMetric,
  optOutOfMeetingByHost,
  optInMeetingByHost,
} from "services/speech";
import { isOnboardingComplete } from "helpers/user";
import { Stopwatch, dateFormatter } from "helpers/time";
import { pricingTiers, verifyUserPricingTier } from "helpers/pricingTiers";
import ReactGA from "react-ga";

const rangeFormatGA = { month: "2-digit", day: "2-digit", year: "numeric" };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pastMeetings: [],
      upcomingMeetings: [],
      meetingScore: {},
      trackers: [],
      constants: {},
      startDate: null,
      endDate: null,
      selectingStartDate: new Date(),
      selectingEndDate: new Date(),
      selectingDate: false,
      datePickerAnchor: null,
      snackbarMessage: "",
      autoHideDuration: "5000",
      isLoading: true,
      faq: null,
    };
  }
  dashboardRef = React.createRef();
  meetingsBoxRef = React.createRef();
  faqBoxRef = React.createRef();
  scoreBoxRef = React.createRef();
  observer = null;
  stopwatch = new Stopwatch(100);
  onlyDemoOrEmpty = true;

  componentDidMount() {
    this.updateDashboardData();
    this.setupObserver();

    const query = new URLSearchParams(this.props.location.search);
    const finishedOnboarding = query.get("finished-onboarding");
    if (finishedOnboarding === "") {
      this.handleSnackbarOpen("You've successfully completed onboarding.");
    }
  }

  componentDidUpdate() {
    if (this.observer && this.dashboardRef.current) {
      this.observer.observe(this.dashboardRef.current);
    }
    // Update the height of the meetings widget based on the widget next to it
    if (
      this.meetingsBoxRef.current &&
      this.faqBoxRef.current &&
      this.scoreBoxRef.current
    ) {
      const height = this.faqBoxRef.current.offsetHeight;
      this.meetingsBoxRef.current.style.height = `${height}px`;
    }
  }

  componentWillUnmount() {
    this.stopwatch.stop();
    this.stopwatch.unregisterCallback();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  updateDashboardData = (startDate, endDate) => {
    this.setState({ isLoading: true, faq: null });
    if (startDate) {
      startDate = startDate.toISOString();
    }
    if (endDate) {
      endDate = endDate.toISOString();
    }
    getDashboardData(startDate, endDate).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          this.props.history.push("/sign-in");
          return;
        }
        this.handleSnackbarOpen(
          "Sorry, but an unknown error occurred. Please try again."
        );
        return;
      }
      const data = response.data.data;
      const constants = response.data.constants;
      const pastMeetings = data.past_meetings;
      const upcomingMeetings = data.upcoming_meetings;
      const meetingScore = data.meeting_score;
      const trackers = data.trackers;
      if (pastMeetings) {
        for (var index in pastMeetings) {
          if (pastMeetings[index].meeting_type !== "demo") {
            this.onlyDemoOrEmpty = false;
            break;
          }
        }
      }
      if (this.onlyDemoOrEmpty) {
        const redirectedToOnboarding =
          localStorage.getItem("redirectedToOnboarding") === "true";
        if (!isOnboardingComplete() && !redirectedToOnboarding) {
          localStorage.setItem("redirectedToOnboarding", "true");
          this.props.history.push("/setup");
          return;
        }
      }

      this.setState({
        pastMeetings,
        upcomingMeetings,
        meetingScore,
        trackers,
        constants,
        isLoading: false,
      });

      // Have the stopwatch log a view for each meeting when it hits 15s
      this.stopwatch.reset();
      this.stopwatch.registerCallback(() => {
        const logMeetings =
          pastMeetings.length > 10 ? pastMeetings.slice(0, 10) : pastMeetings;
        logMeetings.forEach((meeting) => {
          logViewMeetingDashboardMetric(meeting.uuid);
        });
      }, 15);
    });

    getDashboardFAQ(startDate, endDate).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          this.props.history.push("/sign-in");
        }
        this.setState({ faq: { asked_by_me: [], asked_by_attendee: [] } });
        return;
      }
      this.setState({ faq: response.data.data });
    });
  };

  optOutOfMeetingByHost = (meetingUuid, optOut) => {
    if (optOut == true) {
      return optOutOfMeetingByHost(meetingUuid).then(
        function (response) {
          if (response.status === 200) {
            var newUpcomingMeetings = this.state.upcomingMeetings.map((value) => {
              if (value.uuid === meetingUuid) {
                return {
                  ...value,
                  user_opted_out_of_assistant: response.data.user_opted_out_of_assistant,
                };
              }
              return value;
            });
            this.handleSnackbarOpen(
              'AI assistant has been removed from the meeting "' +
                response.data.meeting_title + '"'
            );
            ReactGA.event({
              category: "Dashboard",
              action: "Opt out of meeting by host API success",
            });
            this.setState({
              upcomingMeetings: newUpcomingMeetings,
            });
            return response;
          } else if (response.status === 404) {
            this.handleSnackbarOpen(
              "Sorry, the requested meeting was not found."
            );
          } else {
            this.handleSnackbarOpen(
              "Sorry, an error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Opt out of meeting by host API failed",
          });
          return response;
        }.bind(this)
      );
    } else {
      return optInMeetingByHost(meetingUuid).then(
        function (response) {
          if (response.status === 200) {
            var newUpcomingMeetings = this.state.upcomingMeetings.map((value) => {
              if (value.uuid === meetingUuid) {
                return {
                  ...value,
                  user_opted_out_of_assistant: response.data.user_opted_out_of_assistant,
                };
              }
              return value;
            });
            this.handleSnackbarOpen(
              'AI assistant has been added to the meeting "' +
                response.data.meeting_title + '"'
            );
            ReactGA.event({
              category: "Dashboard",
              action: "Opt in meeting by host API success",
            });
            this.setState({
              upcomingMeetings: newUpcomingMeetings,
            });
            return response;
          } else if (response.status === 404) {
            this.handleSnackbarOpen(
              "Sorry, the requested meeting was not found."
            );
          } else {
            this.handleSnackbarOpen(
              "Sorry, an error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Opt in meeting by host API failed",
          });
          return response;
        }.bind(this)
      );
    }
  };

  setupObserver = () => {
    // Setup an observer that will detect when the user has the dashboard in view
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === this.dashboardRef.current) {
          if (entry.isIntersecting) {
            this.stopwatch.start();
          } else {
            this.stopwatch.stop();
          }
        }
      });
    }, options);
  };

  handleNavigateToMeeting = (meetingUuid, queryParams = "") => {
    this.props.history.push(`/view-meeting/${meetingUuid}?`);
  };

  handleDeleteActionItem = (actionItem) => {
    const { pastMeetings } = this.state;

    return deleteActionItem(actionItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.handleSnackbarOpen(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Delete action item API failed",
          });
          return response;
        }

        const meeting = pastMeetings.find(
          (meeting) => meeting.meeting_id === actionItem.meeting_id
        );
        if (meeting) {
          meeting.action_items = meeting.action_items.filter(
            (item) => item.uuid !== actionItem.uuid
          );
          this.setState({ pastMeetings });
        }

        ReactGA.event({
          category: "Dashboard",
          action: "Delete action item API success",
        });
        this.handleSnackbarOpen("Deleted action item");

        return response;
      }.bind(this)
    );
  };

  handleCompleteActionItem = (actionItem) => {
    const { pastMeetings } = this.state;

    return completeActionItem(actionItem).then(
      function(response) {
        if (response.status === 200) {
          const meeting = pastMeetings.find(
            (meeting) => meeting.meeting_id === actionItem.meeting_id
          );
          if (meeting) {
            meeting.action_items = meeting.action_items.filter(
              (item) => item.uuid !== actionItem.uuid
            );
            this.setState({ pastMeetings });
          }
        }
      }.bind(this)
    );
  };

  handleSnackbarOpen = (message) => {
    this.setState({ snackbarMessage: message });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: "" });
  };

  handleOpenDatePicker = (event) => {
    this.setState({
      datePickerAnchor: event.currentTarget,
    });
  };

  handleCloseDatePicker = () => {
    this.setState({
      datePickerAnchor: null,
    });
  };

  handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;
    if (this.state.selectingDate || startDate !== endDate) {
      this.setState(
        {
          startDate: new Date(startDate.setHours(0, 0, 0, 0)),
          endDate: new Date(endDate.setHours(23, 59, 59, 999)),
          selectingStartDate: startDate,
          selectingEndDate: endDate,
          selectingDate: false,
        },
        () => {
          this.updateDashboardData(this.state.startDate, this.state.endDate);
          this.handleCloseDatePicker();
          ReactGA.event({
            category: "Dashboard",
            action: "Date range adjusted",
            label: `${dateFormatter(
              startDate,
              rangeFormatGA
            )} - ${dateFormatter(endDate, rangeFormatGA)}`,
          });
        }
      );
    } else {
      this.setState({
        selectingStartDate: startDate,
        selectingEndDate: endDate,
        selectingDate: true,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      pastMeetings,
      upcomingMeetings,
      meetingScore,
      trackers,
      faq,
      constants,
      isLoading,
      startDate,
      endDate,
      selectingStartDate,
      selectingEndDate,
      datePickerAnchor,
    } = this.state;

    return (
      <DashboardLayout title="Dashboard">
        <div className={classes.dashboardContainer}>
          <Header
            pastMeetings={pastMeetings}
            isLoading={isLoading}
            onlyDemoOrEmpty={this.onlyDemoOrEmpty}
            startDate={startDate}
            endDate={endDate}
            selectingStartDate={selectingStartDate}
            selectingEndDate={selectingEndDate}
            datePickerAnchor={datePickerAnchor}
            handleOpenDatePicker={this.handleOpenDatePicker}
            handleCloseDatePicker={this.handleCloseDatePicker}
            handleDateChange={this.handleDateChange}
          />
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Box
                ref={this.dashboardRef}
                className={classes.dashboardGrid}
                display="grid"
                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                gridAutoRows="auto"
                gridGap={theme.spacing.unit * 4}
              >
                <Box ref={this.meetingsBoxRef}>
                  <Paper className={classes.dashboardSection}>
                    <Meetings
                      pastMeetings={pastMeetings}
                      upcomingMeetings={upcomingMeetings}
                      history={this.props.history}
                      handleNavigateToMeeting={this.handleNavigateToMeeting}
                      handleSnackbarOpen={this.handleSnackbarOpen}
                      startDate={startDate}
                      endDate={endDate}
                      handleDeleteActionItem={this.handleDeleteActionItem}
                      handleCompleteActionItem={this.handleCompleteActionItem}
                      openSnackbar={this.handleSnackbarOpen}
                      optOutOfMeetingByHost={this.optOutOfMeetingByHost}
                    />
                  </Paper>
                </Box>
                <Box ref={this.faqBoxRef}>
                  <Paper className={classes.dashboardSection}>
                    <FAQ
                      faq={faq}
                      history={this.props.history}
                      openSnackbar={this.handleSnackbarOpen}
                    />
                  </Paper>
                </Box>
                <Box ref={this.scoreBoxRef}>
                  <Paper className={classes.dashboardSection}>
                    <MeetingScore
                      pastMeetings={pastMeetings}
                      meetingScore={meetingScore}
                      handleNavigateToMeeting={this.handleNavigateToMeeting}
                      startDate={startDate}
                      endDate={endDate}
                      history={this.props.history}
                    />
                  </Paper>
                </Box>
                {!verifyUserPricingTier(pricingTiers.free) && (
                  <Box>
                    <Paper
                      className={classes.dashboardSection}
                      style={{ overflow: "visible" }}
                    >
                      <Trackers
                        pastMeetings={pastMeetings}
                        trackers={trackers}
                        history={this.props.history}
                      />
                    </Paper>
                  </Box>
                )}
                <Box>
                  <Paper className={classes.dashboardSection}>
                    <Analytics
                      meetings={pastMeetings}
                      handleNavigateToMeeting={this.handleNavigateToMeeting}
                      constants={constants}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Paper>
                </Box>
              </Box>
            </>
          )}
        </div>

        <Snackbar
          open={this.state.snackbarMessage !== ""}
          autoHideDuration={this.state.autoHideDuration}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar,
          }}
          message={this.state.snackbarMessage}
          action={
            <Button
              className={classes.snackbarActionButton}
              size="small"
              onClick={this.handleSnackbarClose}
            >
              CLOSE
            </Button>
          }
        />
      </DashboardLayout>
    );
  }
}

Dashboard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
