export default (theme) => ({
  signUpButton: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
    width: "max-content",
  },
  signUpIconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
    marginRight: theme.spacing.unit,
  },
  signUpIcon: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  signUpText: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& h5": {
      width: "inherit",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "@media (max-width: 900px)": {
    signUpText: {
      display: "none",
    },
  },
});
