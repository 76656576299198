export default (theme) => ({
  detailedView: {
    width: "60%",
    height: "100%",
    display: "flex",
    overflowY: "scroll",
  },
  detailedViewContent: {
    margin: "auto",
  },
  backButton: {
    width: "max-content",
    marginRight: "auto",
    cursor: "pointer",
    borderRadius: "8px",
    padding: theme.spacing.unit,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
    "& > div": {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "500",
      color: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  detailedScore: {
    width: "95%",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: 0,
    marginBottom: theme.spacing.unit,
    borderRadius: "5px",
  },
  scoreLabel: {
    fontSize: "16px",
    color: theme.palette.text.darkGrey,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: theme.spacing.unit * 0.5,
    "& > span": {
      float: "right",
    },
  },
  score: {
    fontWeight: "700",
    fontSize: "28px",
    color: theme.palette.text.darkGrey,
    "&.overall": {
      fontSize: "42px",
    },
  },
  outOfTen: {
    fontSize: "14px",
    color: theme.palette.text.light,
  },
  details: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
  },
  subScoreContainer: {
    width: "100%",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  subScoreText: {
    display: "block",
    fontFamily: "Roboto",
    fontSize: "14px",
    "&.label": {
      color: theme.palette.text.darkGrey,
    },
    "&.detail": {
      color: theme.palette.text.light,
    },
  },
  largerIcon: {
    transform: "scale(1.2)",
  },
});
