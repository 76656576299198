import React, { useState } from "react";
import { Menu } from "@material-ui/core";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function DatePicker(props) {
  const {
    anchor,
    startDate,
    endDate,
    handleCloseDatePicker,
    handleDateChange,
  } = props;
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectingStartDate, setSelectingStartDate] = useState(startDate);
  const [selectingEndDate, setSelectingEndDate] = useState(endDate);

  const onDateChange = (item) => {
    const { startDate, endDate } = item.selection;
    if (isSelecting || startDate !== endDate) {
      handleDateChange(startDate, endDate);
      setIsSelecting(false);
      handleCloseDatePicker();
    } else {
      setSelectingStartDate(startDate);
      setSelectingEndDate(endDate);
      setIsSelecting(true);
    }
  };

  return (
    <Menu
      anchorEl={anchor}
      open={anchor != null}
      onClose={handleCloseDatePicker}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ transform: "translateY(130px)" }}
    >
      <DateRangePicker
        onChange={onDateChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={[
          {
            startDate: selectingStartDate,
            endDate: selectingEndDate,
            key: "selection",
          },
        ]}
        direction="horizontal"
      />
    </Menu>
  );
}

export default DatePicker;
