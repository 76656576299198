import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  withStyles,
  Tooltip,
  Tabs,
  Tab,
} from "@material-ui/core";

// Material components
import { IconButton, Typography, Button, List } from "@material-ui/core";

import theme from "theme";

// Material icons
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  EmojiObjects,
  Send,
  Share,
  Add,
} from "@material-ui/icons";

// Shared components
import { Paper, ProcessingInProgress } from "components";

// Shared services
import { updateIndicatorProTipFeedback } from "services/speech";

// Component styles
import styles from "./styles";

import { isParticipant } from "../../../../helpers/user";

import ReactGA from "react-ga";

import { ActionItem } from "./components";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

import { speechStatus } from "helpers";

const actionItemLimit = 5;
const tabs = {
  open: 0,
  completed: 1,
};
class ActionItems extends Component {
  state = {
    caption: "",
    actionItems: [],
    openActionItems: [],
    completedActionItems: [],
    participants: [],
    isSavingActionItems: {},
    editingItem: "",
    editingTask: "",
    open: false,
    actionItemLimit: 0,
    showActionItemTip: true,
    openNotifyDialog: false,
    openNewActionItemDialog: false,
    newActionItemDescription: null,
    newActionItemOwner: null,
    showIndicatorProTip: false,
    showIndicatorText: true,
    tab: tabs.open,
    notifyUser: null,
  };
  actionItemRefs = [];
  intersectTargetActionItems = React.createRef();

  observerScrollAI = null;
  timeSpent = 0;
  actionItemsShown = 0;
  timeSpentHover = new Date();

  constructor(props) {
    super(props);
    this.actionItemContainer = React.createRef();
  }

  componentWillMount() {
    if (this.props.meetingStatus === speechStatus.processing) {
      return;
    }
    const participants = this.props.data.participants_details;

    const currentUserParticipant = isParticipant(this.props.data);

    let showTip = true;
    const hiddenTips = JSON.parse(localStorage.getItem("hiddenActionItemTips"));
    if (hiddenTips && hiddenTips.includes("per-meeting")) {
      showTip = false;
    }

    let showIndicatorText = localStorage.hasOwnProperty(
      "hasClickedIndicatorProTip"
    );

    this.setState({
      participants: participants,
      newActionItemOwner: currentUserParticipant
        ? currentUserParticipant.id
        : null,
      showActionItemTip: showTip,
      showIndicatorText: !showIndicatorText,
      actionItems: this.props.data.action_items,
    });
  }

  componentDidMount() {
    if (this.props.scrollToActionItem) {
      this.handleScrollActionItemIntoView();
    }
  }

  componentDidUpdate() {
    if (this.props.data.action_items !== this.state.actionItems) {
      this.setState({ actionItems: this.props.data.action_items });
    }
  }

  getActionItemType = (actionItem) => {
    if (
      actionItem.type === "generic" &&
      actionItem.task_svo &&
      actionItem.task_svo.length > 0 &&
      actionItem.task_svo[0]["verb"] &&
      actionItem.task_svo[0]["object"]
    )
      return "generic_svo";
    return actionItem.type;
  };

  groupActionItemsByOwner = (actionItems, participants) => {
    var newActionItems = [];
    actionItems.forEach((actionItem) => {
      var owner = actionItem.owner_participant_id;
      var participant = null;
      if (owner != null) {
        participant = this.getParticipantById(owner, participants);
      }
      var group = newActionItems.filter((e) => e.owner === owner);
      if (group.length > 0) {
        actionItem.participant = participant;
        group[0].actionItems.push(actionItem);
      } else {
        newActionItems.push({
          owner: owner,
          participant: participant,
          actionItems: [actionItem],
        });
      }
    });
    newActionItems.sort((first, second) => {
      if (first.participant && second.participant) {
        return first.participant.name.localeCompare(second.participant.name);
      } else if (!first.participant && !second.participant) {
        return 0;
      } else if (!first.participant) {
        return 1;
      } else if (!second.participant) {
        return -1;
      }
    });
    newActionItems.forEach((group) => {
      group.actionItems = this.groupActionItemsByType(group.actionItems);
    });
    return newActionItems;
  };

  groupActionItemsByType = (actionItems) => {
    var newActionItems = [];
    actionItems.forEach((actionItem) => {
      var type = this.getActionItemType(actionItem);
      var actionItemType = newActionItems.filter((e) => e.type === type);
      if (actionItemType.length > 0) {
        actionItemType[0].actionItems.push(actionItem);
      } else {
        newActionItems.push({
          type: type,
          actionItems: [actionItem],
        });
      }
    });
    return newActionItems;
  };

  addActionItems = () => {
    const { classes, className, ...rest } = this.props;

    let actionItemsOnTab = this.groupActionItemsByOwner(
      this.state.actionItems.filter(
        (item) =>
          (this.state.tab === tabs.open && !item.is_completed) ||
          (this.state.tab === tabs.completed && item.is_completed)
      ),
      this.state.participants
    );

    if (actionItemsOnTab.length === 0) {
      return this.addEmptyState();
    }
    return (
      <List
        onClick={this.handleOnMouseClick}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        {actionItemsOnTab.map((e, idx) => this.addActionItemsByUser(e, idx))}
      </List>
    );
  };

  addActionItemsByUser = (actionItemsByUser, typePos) => {
    const { classes, className, ...rest } = this.props;
    var user = this.getParticipantById(
      actionItemsByUser.owner,
      this.state.participants
    );
    var actionItemsByType = actionItemsByUser.actionItems;
    return (
      <>
        <ListItem button className={classes.actionItemOwner}>
          <ListItemText
            classes={{ primary: classes.actionItemText }}
            primary={user ? "For " + user.name : "Needs Owner"}
          />
          {user && (
            <Tooltip title={"Notify " + user.name + " via Email"}>
              <IconButton
                className={classes.shareUserButton}
                onClick={() => this.handleOpenNotifyDialog(user)}
              >
                <Share className={classes.shareIcon} />
              </IconButton>
            </Tooltip>
          )}
        </ListItem>
        {actionItemsByType.map((e, idx) => this.addActionItemsByType(e, idx))}
      </>
    );
  };

  addActionItemsByType = (actionItemsByType) => {
    var actionItems = actionItemsByType.actionItems;
    return (
      <>
        {actionItems &&
          actionItems.map((e, idx) =>
            this.actionItemListItem(e, actionItemsByType.type)
          )}
      </>
    );
  };

  limitActionItemsShown = (setLimit) => {
    const limit = setLimit ? actionItemLimit : 0;
    if (!limit) {
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: "User Clicked Show All Action Items",
        label: "Insights",
      });
    }
    this.setState({ actionItemLimit: limit });
  };

  actionItemListItem = (actionItem, actionItemType) => {
    const { classes, className, ...rest } = this.props;
    let owner = this.getParticipantById(
      actionItem.owner_participant_id,
      this.state.participants
    );
    let target = this.getParticipantById(
      actionItem.target_participant_id,
      this.state.participants
    );
    const isEditing = this.state.editingItem === actionItem.uuid;
    const showTimestamps = !(
      !this.props.data.is_recording_enabled ||
      (this.props.isSharedMeetingView &&
        !this.props.data.share_granularity.share_recording)
    );

    if (!actionItem.index && actionItem.type !== "user_action_item") {
      this.actionItemsShown += 1;
      actionItem.index = this.actionItemsShown;
    }

    if (
      this.state.actionItemLimit &&
      actionItem.index > this.state.actionItemLimit
    ) {
      return <></>;
    }

    const selected = this.state.selectedActionItem
      ? this.state.selectedActionItem.uuid === actionItem.uuid
      : false;

    let color = theme.palette.primary.main;
    let title = "Mark as Complete";
    if (actionItem.is_completed) {
      color = theme.palette.success.main;
      title = "Mark as Open";
    }
    return (
      <div
        ref={(instance) => {
          this.actionItemRefs[actionItem.uuid] = instance;
        }}
      >
        <ActionItem
          item={actionItem}
          owner={owner}
          target={target}
          participants={this.state.participants}
          handleActionItemOwnerChange={this.handleActionItemOwnerChange}
          handleActionItemTargetChange={this.handleActionItemTargetChange}
          handleActionItemChange={this.handleConfirmIconClick}
          handleActionItemCancel={this.handleCancelIconClick}
          handleDeleteActionItem={this.handleDeleteActionItem}
          handleCompleteActionItem={this.handleCompleteActionItem}
          handleToggleActionItem={this.handleToggleActionItem}
          isEditing={isEditing}
          open={true}
          selected={selected}
          showTimestamps={showTimestamps}
          handleTimestampClicked={this.handleTimestampClicked}
          handleActionItemClicked={this.handleActionItemClicked}
          editingEnabled={this.props.data.is_participant_editing_enabled}
          isComplete={actionItem.is_completed}
          color={color}
          title={title}
          actionItemType={actionItemType}
        />
      </div>
    );
  };

  handleActionItemClicked = (item) => {
    this.setState({ selectedActionItem: item });
    this.props.handleSelectedActionItem(item);
  };

  getParticipantById = (id, participants) => {
    return participants.find((p) => p.id === id);
  };

  handleAddIconClick = () => {
    const actionItem = {
      uuid: "new-item",
      task: "",
      type: "user_action_item",
    };
    let newActionItems = this.state.actionItems;
    newActionItems.push(actionItem);
    this.setState({
      editingItem: actionItem.uuid,
      editingTask: actionItem.task,
      actionItems: newActionItems,
      openNotifyDialog: false,
    });
  };

  handleEditIconClick = (actionItem) => {
    if (this.state.editingItem === "new-item") {
      let newActionItems = this.state.actionItems.filter(
        (actionItem) => actionItem.uuid !== "new-item"
      );
      this.setState({
        editingItem: actionItem.uuid,
        editingTask: actionItem.task,
        actionItems: newActionItems,
      });
    } else {
      this.setState({
        editingItem: actionItem.uuid,
        editingTask: actionItem.task,
      });
    }
  };

  removeUnsavedActionItem = (actionItem) => {
    let newActionItems = this.state.actionItems.filter(
      (item) => item.uuid !== actionItem.uuid
    );
    this.setState({
      editingItem: "",
      editingTask: "",
      actionItems: newActionItems,
    });
  };

  handleConfirmIconClick = (actionItem, task) => {
    if (actionItem.uuid === "new-item") {
      if (task === "") {
        this.removeUnsavedActionItem(actionItem);
      } else {
        this.handleAddActionItem(task);
      }
    } else {
      this.handleUpdateActionItem(actionItem, task);
    }
  };

  handleCancelIconClick = (actionItem) => {
    if (actionItem.uuid === "new-item") {
      this.removeUnsavedActionItem(actionItem);
    }
  };

  handleAddActionItem = (task, owner_participant_id, type) => {
    this.props.addActionItem(task, owner_participant_id, type).then(
      function(response) {
        let newActionItems = this.state.actionItems;
        newActionItems = newActionItems.filter(
          (actionItem) => actionItem.uuid !== "new-item"
        );

        this.setState({
          newActionItemDescription: null,
          openNewActionItemDialog: false,
          actionItems: newActionItems,
        });
      }.bind(this)
    );
  };

  handleUpdateActionItem = (actionItem, modifiedTask) => {
    this.props.updateActionItem(actionItem, modifiedTask).then(
      function(response) {
        if (response.status === 200) {
          let newActionItems = this.state.actionItems;
          newActionItems = newActionItems.map((item) => {
            if (item.uuid === actionItem.uuid) {
              item.task = response.data.data.task;
            }
            return item;
          });

          this.setState({
            editingItem: "",
            editingTask: "",
            actionItems: newActionItems,
          });
        }
      }.bind(this)
    );
  };

  handleDeleteActionItem = (actionItem) => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "Delete Action Items",
      label: "Next Steps",
    });
    this.props.deleteActionItem(actionItem).then(
      function(response) {
        if (response.status === 200) {
          const deletedActionItem = response.data.data;
          let newActionItems = this.state.actionItems;
          newActionItems = newActionItems.filter(
            (item) => item.uuid !== deletedActionItem.uuid
          );

          this.setState({
            actionItems: newActionItems,
          });
        }
      }.bind(this)
    );
  };

  handleActionItemOwnerChange = (participantId, actionItem) => {
    if (actionItem.owner_participant_id === participantId) {
      return;
    }
    let isSavingActionItems = { ...this.state.isSavingActionItems };
    isSavingActionItems[actionItem.uuid] = true;
    this.setState({
      isSavingActionItems: isSavingActionItems,
    });
    const previousOwnerId = actionItem.owner_participant_id;
    actionItem.owner_participant_id = participantId;
    this.props.assignActionItemOwner(actionItem).then(
      function(response) {
        let isSavingActionItems = { ...this.state.isSavingActionItems };
        isSavingActionItems[actionItem.uuid] = false;
        this.setState({
          isSavingActionItems: isSavingActionItems,
        });
        if (response.status !== 200) {
          actionItem.owner_participant_id = previousOwnerId;
        } else {
          var newActionItems = this.props.data.action_items.filter(
            (ai) => ai.uuid !== actionItem.uuid
          );
          newActionItems.push(actionItem);
          // We also remove the action item limit here to expand the widget and not hide
          // action items that are moved out of view.
          this.setState({
            actionItemLimit: 0,
            actionItems: newActionItems,
          });
        }
      }.bind(this)
    );
  };

  handleActionItemTargetChange = (participantId, actionItem) => {
    if (actionItem.target_participant_id === participantId) {
      return;
    }
    let isSavingActionItems = { ...this.state.isSavingActionItems };
    isSavingActionItems[actionItem.uuid] = true;
    this.setState({
      isSavingActionItems: isSavingActionItems,
    });
    actionItem.target_participant_id = participantId;
    this.props.assignActionItemTarget(actionItem).then(
      function(response) {
        let isSavingActionItems = { ...this.state.isSavingActionItems };
        isSavingActionItems[actionItem.uuid] = false;
        this.setState({
          isSavingActionItems: isSavingActionItems,
        });
      }.bind(this)
    );
  };

  handleCompleteActionItem = (actionItem) => {
    this.props.completeActionItem(actionItem).then(
      function(response) {
        if (response.status === 200) {
          const completedActionItem = response.data.data;
          const newActionItems = this.state.actionItems.map((item) => {
            if (item.uuid === completedActionItem.uuid) {
              return completedActionItem;
            } else {
              return item;
            }
          });
          this.setState({
            actionItems: newActionItems,
          });
        }
      }.bind(this)
    );
  };

  handleChangeTab = (_event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleOnMouseEnter = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeave = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Hovered Action Items",
      value: timeSpent,
    });
  };

  handleOnMouseClick = () => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked an Action Item",
    });
  };

  handleTimestampClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Next Steps",
    });
  };

  handleCloseActionItemTip = () => {
    let hiddenTips = JSON.parse(localStorage.getItem("hiddenActionItemTips"));
    if (hiddenTips) {
      hiddenTips.push("per-meeting");
    } else {
      hiddenTips = ["per-meeting"];
    }
    localStorage.setItem("hiddenActionItemTips", JSON.stringify(hiddenTips));
    this.setState({ showActionItemTip: false });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User dismissed action item tip",
    });
  };

  handleScrollActionItemIntoView = () => {
    const uuid = this.props.scrollToActionItem;
    if (this.actionItemRefs[uuid]) {
      this.actionItemRefs[uuid].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      this.actionItemRefs[uuid].style.backgroundColor =
        theme.palette.warning.light;
    }
  };

  moveActionItemsToEnd = (type) => {
    const genericIndex = this.state.actionItems.findIndex(
      (actionItem) => actionItem.type === type
    );
    if (genericIndex !== -1) {
      const genericActionItems = this.state.actionItems.splice(
        genericIndex,
        1
      )[0];
      this.state.actionItems.push(genericActionItems);
    }
  };

  addEmptyState = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <div>
        <Typography className={classes.phrase} variant="body1">
          {this.state.tab === tabs.open && (
            <>
              It didn't seem like there were any next steps or action items from
              the meeting!
            </>
          )}
          {this.state.tab === tabs.completed && (
            <>There are no completed action items from the meeting!</>
          )}
        </Typography>
      </div>
    );
  };

  displayActionItemHint = () => {
    if (!this.state.hintDisplayed && this.props.openSnackbar) {
      this.props.openSnackbar(
        `Did we miss an action item? Try saying "That's an action item" following an occurrence in your next meeting!`
      );
      this.setState({ hintDisplayed: true });
    }
  };

  totalActionItems = () => {
    if (
      this.state.actionItems == null ||
      this.state.actionItems.length == null ||
      this.state.actionItems.length === 0
    ) {
      return 0;
    } else {
      var total = 0;
      for (const idx in this.state.actionItems) {
        const owner = this.state.actionItems[idx];
        for (const i in owner.actionItems) {
          const group = owner.actionItems[i];
          if (group.type !== "user_action_item") {
            total += group.actionItems.length;
          }
        }
      }
      return total;
    }
  };

  renderToggleButton = () => {
    const { classes } = this.props;
    const isCollapsed = Boolean(this.state.actionItemLimit);
    if (this.totalActionItems() <= actionItemLimit) {
      return <></>;
    }
    return (
      <Button
        onClick={() => {
          this.limitActionItemsShown(!isCollapsed);
          this.displayActionItemHint();
        }}
        color="primary"
        variant="outlined"
        endIcon={isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      >
        {isCollapsed ? "Show all action items" : "Show less action items"}
      </Button>
    );
  };

  handleOpenNotifyDialog = (user = null) => {
    this.setState({
      openNotifyDialog: true,
      notifyUser: user === null ? null : user,
    });
  };

  handleCloseNotifyDialog = () => {
    this.setState({
      openNotifyDialog: false,
    });
  };

  handleCreateNewActionItem = () => {
    this.handleAddActionItem(
      this.state.newActionItemDescription,
      this.state.newActionItemOwner ? this.state.newActionItemOwner : null,
      "user_action_item"
    );
  };

  handleOpenNewActionItemDialogFromTranscript = (sentence) => {
    this.setState({
      openNewActionItemDialog: true,
      newActionItemDescription: sentence,
    });
  };

  handleOpenNewActionItemDialog = () => {
    this.setState({ openNewActionItemDialog: true });
  };

  handleCloseNewActionItemDialog = () => {
    this.setState({ openNewActionItemDialog: false });
  };

  notifyActionItemOwners = (user = null, isCompleted = -1) => {
    this.props.notifyActionItemOwners(user, isCompleted);
    this.handleCloseNotifyDialog();
  };

  handleNewActionItemTypeChange = (event) => {
    this.setState({ newActionItemType: event.target.value });
  };

  handleNewActionItemOwnerChange = (event) => {
    this.setState({ newActionItemOwner: event.target.value });
  };

  handleNewActionItemDescriptionChange = (event) => {
    this.setState({
      newActionItemDescription: event.target.value,
    });
  };

  renderNotifyDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.openNotifyDialog}
        onClose={() => this.handleCloseNotifyDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          NOTIFY ACTION ITEM OWNER
          {this.state.notifyUser ? "" : "S"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Send an email notifying{" "}
            {this.state.notifyUser
              ? this.state.notifyUser.name + " who has"
              : "the people who have"}{" "}
            been assigned action items along with a shareable link to view the
            meeting
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleCloseNotifyDialog()}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              this.state.notifyUser
                ? this.notifyActionItemOwners(
                    this.state.notifyUser,
                    this.state.tab
                  )
                : this.notifyActionItemOwners()
            }
            color="primary"
            variant="outlined"
            endIcon={<Send />}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderNewActionItemDialog = () => {
    const { classes, className, ...rest } = this.props;
    var defaultParticipant = isParticipant(this.props.data);
    return (
      <Dialog
        open={this.state.openNewActionItemDialog}
        onClose={() => this.handleCloseNewActionItemDialog()}
        fullWidth="true"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">NEW ACTION ITEM</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To keep track of something that didn't come up in the meeting add
            your action item here. Remember you can also notify owners of their
            assigned action items.
          </DialogContentText>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid container item>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  id="standard-basic"
                  value={this.state.newActionItemDescription}
                  onChange={this.handleNewActionItemDescriptionChange}
                  label="What needs to be done?"
                />
              </FormControl>
            </Grid>
            <Grid container item>
              <FormControl className={classes.formControl}>
                <InputLabel id="new-action-item-select-owner-label">
                  Owner
                </InputLabel>
                <Select
                  labelId="new-action-item-select-owner-label"
                  id="new-action-item-select-owner"
                  value={this.state.newActionItemOwner}
                  onChange={this.handleNewActionItemOwnerChange}
                  label="Owner"
                  defaultValue={defaultParticipant ? defaultParticipant.id : 0}
                >
                  <MenuItem value={0}>No Owner</MenuItem>
                  {this.state.participants.map((participant) => {
                    return (
                      <MenuItem value={participant.id}>
                        {participant.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseNewActionItemDialog} color="primary">
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={this.handleCreateNewActionItem}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleClickFeedbackButton = (feedbackBool) => {
    let subject = "action_items_pro_tip";
    updateIndicatorProTipFeedback(feedbackBool, subject).then(
      function(response) {
        this.setState({
          showIndicatorProTip: false,
          showIndicatorText: false,
        });
        if (response.status === 200) {
          if (this.props.openSnackbar) {
            this.props.openSnackbar(`Thank you for your feedback!`);
          }
          logGAEvent({
            category: gaCategoryViewMeeting(),
            action: "User Submitted Indicator Pro Tip Feedback",
          });
        } else {
          ReactGA.event({
            category: "Failure",
            action: "Indicator Pro-Tip Feedback Submit failed",
          });
        }
      }.bind(this)
    );
  };

  handleToggleShowIndicatorProTip = () => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Indicator Pro Tip",
    });
    this.setState((prevState) => ({
      showIndicatorProTip: !prevState.showIndicatorProTip,
    }));
  };

  render() {
    const { classes, className, meetingStatus, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const { tab } = this.state;

    return (
      <div
        id="actionItems"
        className={classes.container}
        ref={this.intersectTargetActionItems}
      >
        <Paper {...rest} className={rootClassName}>
          <div className={classes.header}>
            <div className={classes.headerWithIcon}>
              <Typography className={classes.title} variant="h5">
                NEXT STEPS & ACTION ITEMS
              </Typography>
              <Tooltip
                title={
                  <span>
                    In your calls, preface action items with, “As an action
                    item…”. This will ensure we capture it here!
                  </span>
                }
              >
                <EmojiObjects
                  className={classes.aiLightbulbIcon}
                ></EmojiObjects>
              </Tooltip>
            </div>
            {this.props.data.is_participant_editing_enabled && (
              <div className={classes.actionButtons}>
                <Tooltip title="New Action Item">
                  <IconButton
                    className={classes.addActionButton}
                    onClick={this.handleOpenNewActionItemDialog}
                  >
                    <Add className={classes.addIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notify Owners via Email">
                  <IconButton
                    className={classes.shareActionButton}
                    onClick={() => this.handleOpenNotifyDialog()}
                  >
                    <Share className={classes.shareIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div ref={this.actionItemContainer} className={classes.rootContent}>
            <div>
              <Tabs
                value={tab}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                className={classes.tabs}
              >
                <Tab
                  className={classes.tab}
                  label="OPEN"
                  value={tabs.open}
                ></Tab>
                <Tab
                  className={classes.tab}
                  label="COMPLETED"
                  value={tabs.completed}
                ></Tab>
              </Tabs>
            </div>
            {meetingStatus === speechStatus.completed && this.addActionItems()}
            {meetingStatus === speechStatus.processing && (
              <ProcessingInProgress copy="Processing Insights..." />
            )}
          </div>
        </Paper>
        {this.renderNotifyDialog()}
        {this.renderNewActionItemDialog()}
      </div>
    );
  }
}

ActionItems.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionItems);
