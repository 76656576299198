export default (theme) => ({
  backdrop: {
    backgroundColor: theme.palette.background.lightGrey,
    width: "100%",
    border: 0,
  },
  container: {
    backgroundColor: theme.palette.common.white,
    margin: "auto",
    width: "max(50%, 450px)",
    padding: theme.spacing(5),
    fontSize: "16px",
  },
  logoLink: {
    margin: "auto",
    display: "block",
    width: "max-content",
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "35px",
    fontFamily: "Roboto",
    lineHeight: "40px",
    letterSpacing: "-0.24px",
    paddingBottom: "20px",
  },
  content: {
    padding: theme.spacing(2),
  },
  ctaButton: {
    display: "block",
    width: "fit-content",
    color: "white",
    textDecoration: "none",
    fontSize: "14px",
    background: theme.palette.primary.main,
    padding: "10px 20px",
    borderRadius: "7px",
    margin: "auto",
    fontWeight: 600,
  },
  cardsContainer: {
    textAlign: "center",
  },
  card: {
    display: "inline-block",
    width: "min(max(33%, 160px), 232px)",
    align: "center",
    boxSizing: "border-box",
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px 0px`,
  },
  cardContent: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: theme.palette.background.lightGrey,
  },
  cardHeader: {
    height: "40px",
    fontSize: "22px",
    fontWeight: 700,
    color: "#6B6B6B",
    marginTop: "12px",
    marginBottom: "50px",
  },
  cardValue: {
    color: theme.palette.primary.main,
    fontSize: "40px",
    fontWeight: 400,
    margin: "50px 5px 12px",
  },
});
