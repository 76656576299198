export default (theme) => ({
  dashboardContainer: {
    padding: theme.spacing.unit * 4,
  },
  header: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  headerLeft: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    "& > *": {
      fontSize: "18px",
      fontWeight: 500,
    },
  },
  headerRight: {
    width: "200px",
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  dashboardSection: {
    fontFamily: "Roboto",
    height: "100%",
    borderRadius: "15px",
    overflow: "hidden",
    zIndex: 2,
  },
  snackbar: {
    height: "auto",
    fontSize: "16px",
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  snackbarActionButton: {
    color: "white",
  },
  emptyView: {
    margin: theme.spacing.unit * 16,
    marginLeft: theme.spacing.unit * 12,
    marginRight: theme.spacing.unit * 12,
  },
  emptyText: {
    color: theme.palette.text.secondary,
    fontSize: "24px",
    marginBottom: theme.spacing.unit * 6,
  },
  loadingContainer: {
    width: "100%",
    height: "50vh",
    display: "grid",
    placeItems: "center",
  },
  "@media (max-width: 900px)": {
    dashboardGrid: {
      gridTemplateColumns: "minmax(0, 1fr)",
    },
  },
});
