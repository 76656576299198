import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";

import { IconButton, CircularProgress, Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Replay5Icon from "@material-ui/icons/Replay5";
import Forward5Icon from "@material-ui/icons/Forward5";
import { timeFormatter } from "helpers/time";

const ClipPlayer = forwardRef((props, ref) => {
  const [playing, setPlaying] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    const video = videoRef.current;
    const parentTimeUpdateCallback = props.timeUpdateCallback
      ? props.timeUpdateCallback(video)
      : null;

    const timeUpdateCallback = () => {
      // Logic to ensure the video will only play from the start to end time of the clip
      if (video.currentTime >= props.endTime + 1) {
        video.currentTime = props.endTime;
        pause();
      } else if (video.currentTime <= props.startTime - 1) {
        video.currentTime = props.startTime;
      }
      // Run the parent timeUpdateCallback function if given
      if (parentTimeUpdateCallback) parentTimeUpdateCallback();
    };
    const playCallback = () => {
      setPlaying(true);
    };
    const pauseCallback = () => {
      setPlaying(false);
    };

    const onLoad = () => {
      if (video.readyState >= 3) {
        setLoaded(true);
      }
    };

    if (video) {
      video.addEventListener("timeupdate", timeUpdateCallback);
      video.addEventListener("play", playCallback);
      video.addEventListener("pause", pauseCallback);
      video.addEventListener("loadeddata", onLoad);
    }

    return () => {
      if (video) {
        video.removeEventListener("timeupdate", timeUpdateCallback);
        video.removeEventListener("play", playCallback);
        video.removeEventListener("pause", pauseCallback);
        video.removeEventListener("loadeddata", onLoad);
      }
    };
  }, [props]);

  const play = () => {
    if (!videoRef.current) return;
    if (
      videoRef.current.currentTime >= props.endTime ||
      videoRef.current.currentTime <= props.startTime
    ) {
      videoRef.current.currentTime = props.startTime;
    }
    videoRef.current.play();
    setPlaying(true);
  };

  const pause = () => {
    if (!videoRef.current) return;
    videoRef.current.pause();
    setPlaying(false);
  };

  const seek = (secs) => {
    if (!videoRef.current) return;
    const newTimestamp = videoRef.current.currentTime + secs;
    if (newTimestamp >= props.endTime) {
      videoRef.current.currentTime = props.endTime;
    } else if (newTimestamp <= props.startTime) {
      videoRef.current.currentTime = props.startTime;
    } else {
      videoRef.current.currentTime = newTimestamp;
    }
  };

  const seekTo = (timestamp) => {
    if (!videoRef.current) return;
    if (timestamp >= props.endTime) {
      videoRef.current.currentTime = props.endTime;
    } else if (timestamp <= props.startTime) {
      videoRef.current.currentTime = props.startTime;
    } else {
      videoRef.current.currentTime = timestamp;
    }
  };

  // Imperative handles allow the parent component to access the following methods in ClipPlayer with a ref
  useImperativeHandle(ref, () => ({ seekTo, pause, play }));

  const url = `${props.url}#t=${props.startTime},${props.endTime}`;
  const { classes } = props;
  return (
    <div className={classes.clipContainer}>
      <div className={classes.clipLengthContainer}>
        <Typography variant="h6" className={classes.clipLength}>
          Clip length ({timeFormatter(props.length)})
        </Typography>
      </div>
      {!loaded && <CircularProgress className={classes.loadingSpinner} />}
      <video
        src={url}
        className={classes.videoClip}
        preload="metadata"
        ref={videoRef}
      ></video>
      <div className={classes.controls}>
        <IconButton
          className={classes.controlButtonIcon}
          onClick={() => seek(-5)}
        >
          <Replay5Icon />
        </IconButton>
        {playing ? (
          <IconButton className={classes.controlButtonIcon} onClick={pause}>
            <PauseIcon />
          </IconButton>
        ) : (
          <IconButton className={classes.controlButtonIcon} onClick={play}>
            <PlayArrowIcon />
          </IconButton>
        )}
        <IconButton
          className={classes.controlButtonIcon}
          onClick={() => seek(5)}
        >
          <Forward5Icon />
        </IconButton>
      </div>
    </div>
  );
});

ClipPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  // Optional prop: timeUpdateCallback
  // Allows the parent component to specify a function to run while the video plays
  // Must be a function that takes in a video element as the only param
  // Must return a function that will run in the video's timeupdate event listener
  timeUpdateCallback: PropTypes.func,
};

export default withStyles(styles)(ClipPlayer);
