import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { TextField, MenuItem, Button } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

class NewPassword extends Component {
  state = {
    newPassword1: "",
    newPassword2: "",
    showPassword1Error: false,
    showPassword2Error: false,
    showPasswordsNotEqualError: false,
  };

  handleNewPassword1 = (text) => {
    this.setState({
      newPassword1: text,
      showPassword1Error: false,
      showPasswordsNotEqualError: false,
    });
  };

  handleNewPassword2 = (text) => {
    this.setState({
      newPassword2: text,
      showPassword2Error: false,
      showPasswordsNotEqualError: false,
    });
  };

  validate = () => {
    if (this.state.newPassword1 == "") {
      this.setState({ showPassword1Error: true });
      return false;
    }

    if (this.state.newPassword2 == "") {
      this.setState({ showPassword2Error: true });
      return false;
    }

    if (this.state.newPassword1 !== this.state.newPassword2) {
      this.setState({ showPasswordsNotEqualError: true });
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    if (this.validate()) {
      this.props.changePassword(
        this.state.newPassword1,
        this.state.newPassword2
      );
    }
  };

  renderFormContent = () => {
    const { classes, className, ...rest } = this.props;

    return (
      <PortletContent className={classes.content}>
        <form autoComplete="off" noValidate>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="New Password"
              margin="dense"
              onChange={(event) => this.handleNewPassword1(event.target.value)}
              required
              variant="outlined"
              type="password"
              value={this.state.newPassword1}
            />
            {this.state.showPassword1Error && (
              <Typography className={classes.fieldError} variant="body2">
                This field is required
              </Typography>
            )}
          </div>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Confirm New Password"
              margin="dense"
              onChange={(event) => this.handleNewPassword2(event.target.value)}
              required
              variant="outlined"
              type="password"
              value={this.state.newPassword2}
            />
            {this.state.showPassword2Error && (
              <Typography className={classes.fieldError} variant="body2">
                This field is required
              </Typography>
            )}
            {this.state.showPasswordsNotEqualError && (
              <Typography className={classes.fieldError} variant="body2">
                Passwords do not match
              </Typography>
            )}
          </div>
        </form>
      </PortletContent>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Change Password" />
        </PortletHeader>
        {this.renderFormContent()}
        <PortletFooter className={classes.portletFooter}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="small"
            variant="text"
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

NewPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewPassword);
