const insightColourOnHover = "#415aab";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  transcriptEditSentence: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    width: "80%",
    height: "auto",
    resize: "none",
    padding: theme.spacing.unit,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
  transcriptEditSentenceContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
  },
  transcriptSpeakerSegmentContainer: {},
  transcriptSpeakerTitle: {
    position: "-webkit-sticky" /* Safari */,
    position: "sticky",
    top: 0,
    color: theme.palette.text.secondary,
    fontWeight: 700,
    whiteSpace: "pre-wrap",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
  },
  transcriptSpeakerSegmentBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit,
  },
  transcriptSentenceContainer: {
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    display: "inline",
    borderRadius: "3px",
  },
  transcriptSentenceActionButton: {
    height: theme.spacing.unit * 2.5,
    width: theme.spacing.unit * 2.5,
    marginRight: theme.spacing.unit * 0.5,
  },
  transcriptSentence: {
    color: theme.palette.text.secondary,
    whiteSpace: "normal",
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    paddingRight: theme.spacing.unit * 0.5,
    borderRadius: "3px",
    lineHeight: "22px",
    textAlign: "left",
    position: "relative",
    "&:hover": {
      background: "#ededed",
      cursor: "pointer",
    },
    "&.actions-menu-enabled:hover > $overflowMenu": {
      display: "flex",
      animation: "$show 0s linear 0.3s forwards",
    },
  },
  overflowMenu: {
    position: "absolute",
    left: 0,
    top: "-40px",
    height: "40px",
    borderRadius: "5px",
    backgroundColor: theme.palette.background.lightGrey,
    padding: theme.spacing.unit,
    boxShadow: `0 1px 2px ${theme.palette.common.black}`,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    zIndex: 2,
    "&:hover": {
      display: "flex",
      opacity: 1,
    },
  },
  scrollTip: {
    width: "100%",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    textAlign: "center",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    borderTop: `1px solid ${theme.palette.border}`,
    verticalAlign: "middle",
    // height: "40px",
  },
  transcriptTime: {
    color: theme.palette.text.primary,
  },
  speakerParagraph: {
    marginTop: theme.spacing.unit,
  },
  buttonIcon: {
    marginLeft: theme.spacing(0.25),
    borderRadius: "50%",
    cursor: "pointer",
    "&.small": {
      width: "20px",
      height: "20px",
      padding: "4px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  containsTopic: {
    backgroundColor: theme.palette.elements.topics.normal,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsQuestion: {
    backgroundColor: theme.palette.elements.questions.normal,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsAnswer: {
    backgroundColor: theme.palette.elements.answers.normal,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsTakeaway: {
    backgroundColor: theme.palette.elements.takeaways.normal,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsWin: {
    backgroundColor: theme.palette.elements.wins.normal,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsOpportunity: {
    backgroundColor: theme.palette.elements.opportunities.normal,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsActionItem: {
    backgroundColor: `${theme.palette.elements.actionItem.normal} !important`,
    "& a": {
      backgroundColor: `${theme.palette.elements.actionItem.normal} !important`,
      color: theme.palette.common.black,
      "&:hover": {
        backgroundColor: `${theme.palette.elements.actionItem.dark} !important`,
      },
    },
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  containsCurrentInsight: {
    "& $containsTopic": {
      backgroundColor: theme.palette.elements.topics.dark,
    },
    "& $containsQuestion": {
      backgroundColor: theme.palette.elements.questions.dark,
    },
    "& $containsAnswer": {
      backgroundColor: theme.palette.elements.answers.dark,
    },
    "& $containsTakeaway": {
      backgroundColor: theme.palette.elements.takeaways.dark,
    },
    "& $containsWin": {
      backgroundColor: theme.palette.elements.wins.dark,
    },
    "& $containsOpportunity": {
      backgroundColor: theme.palette.elements.opportunities.dark,
    },
    "& a": {
      color: theme.palette.common.black,
    },
    color: theme.palette.common.black,
    "&:hover": {
      background: insightColourOnHover,
    },
  },
  tipContainer: {
    marginLeft: "auto",
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(0.25),
  },
  tipPill: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.25),
    color: theme.palette.common.white,
    padding: `0 ${theme.spacing(1)}px`,
    fontSize: "9px",
    borderRadius: "5px",
    "&.positive": {
      backgroundColor: theme.palette.success.main,
    },
    "&.negative": {
      backgroundColor: theme.palette.danger.mellow,
    },
    "&.takeaway": {
      backgroundColor: theme.palette.elements.takeaways.saturated,
    },
    "&.question": {
      backgroundColor: theme.palette.elements.questions.saturated,
    },
    "&.answer": {
      backgroundColor: theme.palette.elements.answers.saturated,
    },
  },
  tipIcon: {
    width: 12,
    height: 12,
  },
  "@keyframes show": {
    "100%": {
      opacity: 1,
    },
  },
});
