import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, CircularProgress, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
  ViewVideo,
  Pacing,
  Energy,
  Delivery,
  Fillers,
  Emotions,
  QuickLook,
  ContentSentiment,
  ContentPositiveWords,
  ContentNegativeWords
} from 'views/NewSpeech/components';

// Shared components
import {
  Portlet,
  PortletContent,
} from 'components';

// Shared services
import { getSpeech } from 'services/speech';

// Shared helpers
import { speechStatus } from 'helpers';

import ReactGA from 'react-ga';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  item: {
    height: '100%'
  },
  progressWrapper: {
    paddingTop: '100px',
    paddingBottom: '50px',
    display: 'flex',
    justifyContent: 'center'
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: '100px',
  },
});

class ViewSpeech extends Component {

  timeout = null
  speechId = null
  pollingAttempts = 0

  state = {
    results: {},
    videoBlob: null,
    isLoading: false,
    error: null,
    speechStatus: null
  };

  componentDidMount() {
      this.speechId = this.props.location.state.id
      this.getSpeechFromRemote()
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  getSpeechFromRemote = () => {
    this.setState({isLoading: true})
    this.pollingAttempts += 1
    getSpeech(this.speechId).then(function(response) {
      if (response.status !== 200) {
        if (response.status == 401) {
          this.props.history.push('/sign-in');
          ReactGA.event({
            category: 'Failure',
            action: 'Refresh token expired'
          });
          return
        }
        this.setError("Sorry, but an unknown error occurred. Please try again.")
        ReactGA.event({
          category: 'Failure',
          action: 'Could not get speech results in view-speech'
        });
        return
      }

      var status = response.data.status

      if (status === speechStatus.processing) {
        this.setState({isLoading: false, speechStatus: speechStatus.processing})
        if (this.pollingAttempts < 2) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 10000)
        } else if (this.pollingAttempts >= 2 && this.pollingAttempts < 4) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 15000)
        } else if (this.pollingAttempts >= 4 && this.pollingAttempts < 6) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 20000)
        } else if (this.pollingAttempts >= 6 && this.pollingAttempts < 10) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 30000)
        } else {
          this.setError("Sorry, but analysis is taking longer than expected. Please check back at a later time. We apologize for the inconvenience.")
          ReactGA.event({
            category: 'Failure',
            action: 'Speech processing taking a long time'
          });
        }
        return
      }
      
      this.pollingAttempts = 0

      if (status === speechStatus.completed) {
        var data = response.data.data
        this.setState({
            speechStatus: speechStatus.completed,
            results: data
        })
        ReactGA.event({
          category: 'Speech',
          action: 'Get speech success'
        });
      } else if (status === speechStatus.failed) {
        this.setState({
          speechStatus: speechStatus.failed,
          error: "Sorry, but processing failed because of an unknown reason. We apologize for the inconvenience."
        })
        ReactGA.event({
          category: 'Failure',
          action: 'Speech processing failed'
        });
      } else {
        this.setError("Sorry, but an unknown error occurred. Please try again.")
        ReactGA.event({
          category: 'Failure',
          action: 'Unknown error in get speech'
        });
      }
      this.setState({isLoading: false})
    }.bind(this));
  }

  setError = (error) => {
    this.setState({error: error})
  }

  renderError = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Recording Feedback">
          <div className={classes.root}>
              <div className={classes.content}>
                <Portlet
                >
                  <PortletContent className={classes.content}>
                    <Typography
                      className={classes.error}
                      variant="body1"
                    >
                      {this.state.error}
                    </Typography>
                  </PortletContent>
                </Portlet>
              </div>
          </div>
      </DashboardLayout>
    )
  }

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Recording Feedback">
          <div className={classes.root}>
              <div className={classes.content}>
                  <div className={classes.progressWrapper}>
                      <CircularProgress />
                  </div>
              </div>
          </div>
      </DashboardLayout>
    )
  }

  renderProcessing = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Recording Feedback">
          <div className={classes.root}>
              <div className={classes.content}>
                <Portlet
                >
                  <PortletContent className={classes.item}>
                    <div className={classes.progressWrapper}>
                        <CircularProgress />
                    </div>
                    <Typography
                      className={classes.caption}
                      variant="h4"
                      align="center"
                    >
                      Your recording is currently being processed and analyzed. This may take some time depending on the length of the recording. <br/><br/>

                      The page will automatically update with the results once analysis is complete. Thank you!
                    </Typography>
                  </PortletContent>
                </Portlet>
              </div>
          </div>
      </DashboardLayout>
    )
  }

  renderEmotions = () => {
    const { classes } = this.props;

    return (
      <Grid
      item
      lg={6}
      md={6}
      xl={3}
      xs={12}
    >
          <Emotions className={classes.item} data={this.state.results}/>
      </Grid>
    )
  }

  renderContentSentiment = () => {
    const { classes } = this.props;

    if (this.state.results.sentiment_polarity != null) {
      return (
        <Grid
        item
        lg={4}
        sm={6}
        xl={3}
        xs={12}
      >
            <ContentSentiment className={classes.item} data={this.state.results}/>
        </Grid>
      )
    }
  }

  renderContentPositiveWords = () => {
    const { classes } = this.props;
    const tokens = this.state.results.sentiment_positive_tokens

    if (tokens != null) {
      if (tokens.length > 0) {
        return (
          <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
              <ContentPositiveWords className={classes.item} data={this.state.results}/>
          </Grid>
        )
      }
    }
  }

  renderContentNegativeWords = () => {
    const { classes } = this.props;
    const tokens = this.state.results.sentiment_negative_tokens
    
    if (tokens != null) {
      if (tokens.length > 0) {
        return (
          <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
              <ContentNegativeWords className={classes.item} data={this.state.results}/>
          </Grid>
        )
      }
    }
  }

  renderSpeechResults = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Recording Feedback">
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={7}
              md={7}
              xl={9}
              xs={12}
            >
              <ViewVideo className={classes.item} videoBlob={this.state.videoBlob} data={this.state.results}/>
            </Grid>
            <Grid
              item
              lg={5}
              md={5}
              xl={3}
              xs={12}
            >
              <QuickLook className={classes.item} data={this.state.results}/>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={3}
              xs={12}
            >
              <Pacing className={classes.item} data = {this.state.results}/>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={3}
              xs={12}
            >
              <Energy className={classes.item} data = {this.state.results}/>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={3}
              xs={12}
            >
              <Delivery className={classes.item} data={this.state.results}/>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xl={3}
              xs={12}
            >
              <Fillers className={classes.item} data={this.state.results}/>
            </Grid>
            {this.renderEmotions()}
            {this.renderContentSentiment()}
            {this.renderContentPositiveWords()}
            {this.renderContentNegativeWords()}
          </Grid>
        </div>
      </DashboardLayout>
    );
  }

  render() {
    const isLoading = this.state.isLoading;

    if (this.state.error != null) {
      return this.renderError()
    }

    if (isLoading) {
      return this.renderLoading()
    }

    if (this.state.speechStatus === speechStatus.processing) {
      return this.renderProcessing()
    } else if (this.state.speechStatus === speechStatus.failed) {
      return this.renderError()
    }

    return this.renderSpeechResults()
  }
}

ViewSpeech.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewSpeech);
