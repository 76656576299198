export default (theme) => ({
  root: {},
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  group: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  groupLabel: {
    paddingLeft: theme.spacing.unit * 2,
  },
  field: {
    display: "flex",
    alignItems: "center",
  },
  textField: {
    width: "320px",
    maxWidth: "100%",
    marginRight: theme.spacing.unit * 3,
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  checkboxLabelOption: {
    marginTop: theme.spacing.unit * 2,
  },
  info: {
    backgroundColor: theme.palette.background.grey,
  },
});
