export const takeawaysColour = "#fab86e";
export const winsColour = "#1acfa9";
export const opportunitiesColour = "#fa808b";
export const painPointsColour = "#f86868";
export const questionsColour = "#5e81f5";
export const answersColour = "#cc9966";

export default (theme) => ({
  conversationHeader: {
    padding: theme.spacing.unit * 2,
    paddingRight: theme.spacing(0.625),
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.elements.currentInsight.lightGrey,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "53px",
  },
  headerText: {
    fontSize: "16px",
    color: theme.palette.text.grey,
    fontWeight: 700,
  },
  conversationDownloadIcon: {
    color: theme.palette.elements.currentInsight.blue,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  tab: {
    color: theme.palette.elements.currentInsight.darkGrey,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  options: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  viewToggle: {
    display: "flex",
    alignItems: "center",
  },
  toggleOption: {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: 120,
  },
});
