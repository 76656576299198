export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  textFieldsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    width: "25%",
  },
  headerLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textFieldDescription: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  table: {
    width: "100%",
  },
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing.unit * 18,
    "& *": {
      color: theme.palette.text.secondary,
    },
  },
  emptyStateIcon: {
    transform: "scale(4) translateY(20px)",
  },
});
