import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Externals
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from "validate.js";
import _ from "underscore";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";

// Shared services
import { login, resendConfirmEmail } from "services/speech";

import ReactGA from "react-ga";

class SignIn extends Component {
  state = {
    values: {
      email: "",
      password: "",
    },
    touched: {
      email: false,
      password: false,
    },
    errors: {
      email: null,
      password: null,
    },
    isValid: false,
    isLoading: false,
    submitError: null,
    confirmEmailError: null,
    isLoadingConfirmEmail: false,
    resendConfirmEmailSuccess: false,
    redirect: "",
  };

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      const redirect = query.get("redirect");
      if (redirect) {
        this.setState({ redirect });
      }
    }
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleSignIn();
    }
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  validateForm = () => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.confirmEmailError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSignIn = () => {
    const { history } = this.props;
    const { values } = this.state;

    this.setState({ isLoading: true });

    this.validateForm();

    if (this.state.isValid) {
      login(values.email, values.password).then(
        function(response) {
          if (response.status === 200) {
            history.push(this.state.redirect ? this.state.redirect : "/");
            ReactGA.event({
              category: "User",
              action: "Sign in API success",
            });
          } else if (response.status === 401) {
            this.setState({
              isLoading: false,
              submitError:
                'Wrong username or password. Try again or click "Forgot Password" to reset it. ',
            });
            ReactGA.event({
              category: "Failure",
              action: "Sign in API failed because of invalid credentials",
            });
          } else {
            const data = response.data;
            if (data != null) {
              const errors = data.non_field_errors;
              if (
                errors != null &&
                errors.includes("E-mail is not verified.")
              ) {
                this.setState({
                  isLoading: false,
                  confirmEmailError:
                    "Please verify your email before signing in. ",
                });
                ReactGA.event({
                  category: "User",
                  action: "Sign in API failed because email not verified",
                });
                return;
              }
            }
            this.setState({
              isLoading: false,
              submitError: "Sorry, unable to sign in due to an error",
            });
            ReactGA.event({
              category: "Failure",
              action: "Sign in API failed",
            });
          }
        }.bind(this)
      );
    } else {
      this.setState({
        isLoading: false,
        submitError: "Invalid email or password. Please try again. ",
      });
    }
  };

  handleResendConfirmationEmail = () => {
    const { values } = this.state;

    this.setState({ isLoadingConfirmEmail: true });

    resendConfirmEmail(values.email).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            isLoadingConfirmEmail: false,
            confirmEmailError: null,
            resendConfirmEmailSuccess: true,
          });
          ReactGA.event({
            category: "User",
            action: "Resend confirmation email API success",
          });
        } else {
          this.setState({
            isLoadingConfirmEmail: false,
            confirmEmailError: null,
            submitError: "Sorry, could not send email confirmation",
          });
          ReactGA.event({
            category: "Failure",
            action: "Resend confirmation email API failed",
          });
        }
      }.bind(this)
    );
  };

  render() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      confirmEmailError,
      isLoading,
      isLoadingConfirmEmail,
      resendConfirmEmailSuccess,
    } = this.state;

    if (resendConfirmEmailSuccess === true) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <div className={classes.logoWrapper}>
                  <Link className={classes.logoLink} to="/">
                    <img
                      alt="Sonero logo"
                      height="100px"
                      className={classes.logoImage}
                      src="/sonero-static/images/logo_with_name.png"
                    />
                  </Link>
                </div>
                <Divider className={classes.logoDivider} />
                <Typography className={classes.title} variant="h4">
                  A verification email has been sent to your email address.
                </Typography>
              </form>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form}>
              <div className={classes.logoWrapper}>
                <a
                  href="https://sonero.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Sonero logo"
                    height="100px"
                    className={classes.logoImage}
                    src="/sonero-static/images/logo_with_name.png"
                  />
                </a>
              </div>
              <Divider className={classes.logoDivider} />
              <Typography className={classes.title} variant="h2">
                Sign in
              </Typography>
              <div className={classes.fields}>
                <TextField
                  className={classes.textField}
                  label="Email address"
                  name="email"
                  onChange={(event) =>
                    this.handleFieldChange("email", event.target.value)
                  }
                  type="text"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label="Password"
                  name="password"
                  onChange={(event) =>
                    this.handleFieldChange("password", event.target.value)
                  }
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <div className={classes.policy}>
                  <Typography className={classes.policyText} variant="body1">
                    By signing in, you agree to the&nbsp;
                    <a
                      className={classes.policyUrl}
                      href="https://sonero.ai/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms&nbsp;
                    </a>
                    and&nbsp;
                    <a
                      className={classes.policyUrl}
                      href="https://www.mediafly.com/legal/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                </div>
              </div>
              {confirmEmailError && (
                <Typography className={classes.submitError} variant="body2">
                  {confirmEmailError}
                  {isLoadingConfirmEmail ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      color="primary"
                      onClick={this.handleResendConfirmationEmail}
                    >
                      Resend Confirmation Email
                    </Button>
                  )}
                </Typography>
              )}
              {submitError && (
                <Typography className={classes.submitError} variant="body2">
                  {submitError}
                </Typography>
              )}
              {isLoading ? (
                <CircularProgress className={classes.progress} />
              ) : (
                <Button
                  className={classes.signInButton}
                  color="primary"
                  onClick={this.handleSignIn}
                  size="large"
                  variant="contained"
                >
                  Sign in now
                </Button>
              )}
              {
                <Typography className={classes.signUp} variant="body1">
                  Don't have an account?{" "}
                  <Link className={classes.signUpUrl} to="/sign-up">
                    Sign up
                  </Link>
                </Typography>
              }
              {
                <Typography className={classes.signUp} variant="body1">
                  <Link className={classes.signUpUrl} to="/reset-password">
                    Forgot Password?
                  </Link>
                </Typography>
              }
            </form>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(SignIn);
