import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Typography, Snackbar } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';

import { pricingTiers } from 'helpers';

import ReactGA from 'react-ga';

class RecordVideo extends Component {

  camStream = null;
  recorder = null;
  videoBlob = null;

  state = {
    showVideo: true,
    isRecording: false,
    recordButtonText: "Record",
    submitButtonDisabled: true,
    snackbarOpen: false
  };

  componentDidMount() {
    if (!('MediaRecorder' in window)) {
      this.props.clientError("Sorry, but your browser is currently not supported for video recording. Please try using Google Chrome.")
      return
    }
    if (this.props.videoBlob != null) {
      this.setupSpeechVideo(this.props.videoBlob)
      this.setState({submitButtonDisabled: false})
    } else {
      this.getCameraStream()
    }
  }

  componentWillUnmount() {
    var camStream = this.camStream
    if (camStream != null) {
      camStream.getTracks().forEach(track => track.stop());
    }
  }

  getCameraStream = () => {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    })
    .then(function(stream) {
      this.camStream = stream
      var videoPlayer = document.getElementById('videoPlayer');
      videoPlayer.src = null
      videoPlayer.srcObject = this.camStream;
      videoPlayer.controls = false
      videoPlayer.muted = true
      videoPlayer.play();
      return stream
    }.bind(this))
    .catch(function(err){
      if (err.name === "NotAllowedError") {
        this.props.clientError("Please grant permission to use your microphone and camera to record")
      } else if (err.name === "NotFoundError") {
        this.props.clientError("We could not find an available microphone or camera to record")
      } else {
        this.props.clientError("Sorry, but an unknown error occurred")
      }
    }.bind(this))
  }

  handleRecord = () => {
    if (this.state.isRecording) {
      this.recorder.stop();
      this.setState({
        isRecording: false,
        recordButtonText: "Record",
        submitButtonDisabled: false
      })
    } else {
      if (this.camStream == null) {
        this.getCameraStream().then(function(stream) {
          this.setupPlayerForRecording()
        }.bind(this))
      } else {
        this.setupPlayerForRecording()
      }
    }
  };

  setupPlayerForRecording = () => {
    var videoPlayer = document.getElementById('videoPlayer');

    videoPlayer.muted = true
    videoPlayer.controls = false
    if (this.state.showVideo) {
      if (videoPlayer.srcObject === null) {
        videoPlayer.srcObject = this.camStream;
        videoPlayer.play();
      }
    } else {
      videoPlayer.srcObject = null;
    }
    var chunks = [];
    this.recorder = new MediaRecorder(this.camStream);
    this.recorder.ondataavailable = e => chunks.push(e.data);
    this.recorder.onstart = e => this.startTimerIfNeeded();
    this.recorder.onstop = e => this.setupSpeechVideo(new Blob(chunks));
    this.recorder.start();
    this.setState({
      isRecording: true,
      recordButtonText: "Stop"
    })
  }

  setupSpeechVideo = (blob) => {
    var videoPlayer = document.getElementById('videoPlayer');
    this.videoBlob = blob;
    videoPlayer.srcObject = null;
    videoPlayer.src = URL.createObjectURL(this.videoBlob)
    videoPlayer.controls = true
    videoPlayer.muted = false
    this.setState({showVideo: true})
  }

  startTimerIfNeeded = () => {
    const pricingTier = localStorage.getItem('pricingTier')
    if (pricingTier == pricingTiers.free) {
      setTimeout(this.stopRecording, 59999)
    }
  }

  stopRecording = () => {
    if (this.state.isRecording) {
      this.recorder.stop();
      this.setState({
        isRecording: false,
        recordButtonText: "Record",
        submitButtonDisabled: false,
        snackbarOpen: true
      })
    }
  }

  openSnackbar = () => {
    this.setState({
      snackbarOpen: true,
    })
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    })
  }

  handleUpload = () => {
    if (this.videoBlob != null) {
      this.upload(this.videoBlob);
    }
  }

  upload = (blob) => {
    this.props.uploadSpeech(blob)
  };

  handleToggle = () => {
    var videoPlayer = document.getElementById('videoPlayer');

    if (this.state.showVideo) {
      this.setState({
        showVideo: false
      })
      videoPlayer.srcObject = null
      videoPlayer.src = null
      ReactGA.event({
        category: 'User',
        action: 'Turn off video'
      });
    } else {
      this.setState({
        showVideo: true
      })
      if (this.state.isRecording) {
        videoPlayer.srcObject = this.camStream
        videoPlayer.play()
      } else {
        if (this.videoBlob != null) {
          videoPlayer.srcObject = null;
          videoPlayer.src = URL.createObjectURL(this.videoBlob)
        } else {
          videoPlayer.srcObject = this.camStream
          videoPlayer.play()
        }
      }
      ReactGA.event({
        category: 'User',
        action: 'Turn on video'
      });
    }
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel title="Record" />
        </PortletHeader>
        <PortletContent className={classes.content}>
            <div className={classes.videoWrapper}>
              <video id="videoPlayer" className={classes.videoRecorder} muted></video>
            </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button className={classes.recordButton}
            color="primary"
            size="small"
            variant="text"
            onClick={this.handleRecord}
          >
            {this.state.recordButtonText}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={this.handleToggle}
          >
            Toggle Video
          </Button>
          <Button
            color="primary"
            size="small"
            variant="text"
            disabled={this.state.submitButtonDisabled}
            onClick={this.handleUpload}
          >
            Submit
          </Button>
        </PortletFooter>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration="6000"
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar
          }}
          message="Recording stopped. Please upgrade to Sonero Pro to record for more than 1 minute."
        />
      </Portlet>
    );
  }
}

RecordVideo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RecordVideo);
