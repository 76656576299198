import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import validate from "validate.js";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  Snackbar,
} from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";

// Component styles
import styles from "./styles";

import { requestFeedbackMessage } from "services/speech";

// Form validation schema
import schema from "./schema";

import ReactGA from "react-ga";

import Highlighter from "react-highlight-words";

// Palette
import palette from "theme/palette";

import { gaCategoryViewMeeting } from "helpers/gaUtil";

class ViewVideo extends Component {
  mediaURL = "";

  state = {
    title: "Recording",
    speechId: null,
    openDialog: false,
    openTranscriptDialog: false,
    email: null,
    isSubmitEmailButtonDisabled: true,
    isLoading: false,
    showErrorResponse: false,
    showSnackbar: false,
    isMeeting: false,
  };

  componentDidMount() {
    if (this.props.isMeeting == true) {
      this.setState({
        speechId: this.props.data.meeting_id,
        isMeeting: true,
      });
      this.mediaURL = this.props.data.recording_url;
    } else {
      this.setState({
        title: this.props.data.title,
        speechId: this.props.data.speech_id,
      });
      this.mediaURL = this.props.data.video_url;
    }

    if (this.props.videoBlob != null) {
      this.setupSpeechVideoWithBlob(this.props.videoBlob);
    } else {
      this.setupSpeechVideoWithURL(this.mediaURL);
    }
  }

  setupSpeechVideoWithBlob = (blob) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.srcObject = null;
    videoPlayer.src = URL.createObjectURL(blob);
  };

  setupSpeechVideoWithURL = (url) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.srcObject = null;
    videoPlayer.src = url;
  };

  setMediaPlayerCurrentTimeAndPlay = (timestamp) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.currentTime = timestamp;
    videoPlayer.play();
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Play Video",
    });
  };

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleOpenTranscript = () => {
    this.setState({ openTranscriptDialog: true });
  };

  handleCloseTranscript = () => {
    this.setState({ openTranscriptDialog: false });
  };

  handleFieldChange = (value) => {
    const errors = validate({ email: value }, schema);
    if (errors == null) {
      this.setState({ isSubmitEmailButtonDisabled: false });
    } else {
      this.setState({ isSubmitEmailButtonDisabled: true });
    }
    this.setState({ email: value });
  };

  handleSubmit = () => {
    this.setState({
      isLoading: true,
      showErrorResponse: false,
      showSnackbar: false,
    });
    requestFeedbackMessage(this.state.speechId, this.state.email).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            showErrorResponse: false,
            showSnackbar: true,
            openDialog: false,
            isLoading: false,
            email: null,
            isSubmitEmailButtonDisabled: true,
          });
          ReactGA.event({
            category: "Feedback Messages",
            action: "Request feedback message success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
          ReactGA.event({
            category: "Failure",
            action: "Refresh token expired",
          });
        } else {
          this.setState({
            showErrorResponse: true,
            showSnackbar: false,
            isLoading: false,
          });
          ReactGA.event({
            category: "Failure",
            action: "Request feedback message failed",
          });
        }
      }.bind(this)
    );
  };

  handleSnackbarClose = () => {
    this.setState({
      showSnackbar: false,
    });
  };

  renderTranscriptButton = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Button
        className={classes.recordButton}
        color="primary"
        size="small"
        variant="text"
        onClick={() => this.handleOpenTranscript()}
      >
        TRANSCRIPT
      </Button>
    );
  };

  renderShareDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.openDialog}
        onClose={() => this.handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Share Recording</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address of the person that you would like to
            get feedback from on your recording
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={this.state.email}
            onChange={(event) => this.handleFieldChange(event.target.value)}
          />
          {this.state.showErrorResponse && (
            <Typography className={classes.fieldError} variant="body2">
              Could not send the email because an error occurred. Please try
              again.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            Cancel
          </Button>
          {this.state.isLoading ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <Button
              disabled={this.state.isSubmitEmailButtonDisabled}
              onClick={() => this.handleSubmit()}
              color="primary"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  renderTranscriptWithHighlighter = (transcript) => {
    if (this.state.isMeeting == true) {
      return transcript;
    } else {
      return (
        <Highlighter
          highlightStyle={{
            backgroundColor: "transparent",
            color: palette.danger.main,
          }}
          searchWords={[
            "\\bi mean\\b",
            "\\bso\\b",
            "\\bbasically\\b",
            "\\byou know\\b",
            "\\blike\\b",
            "\\bright\\b",
            "\\buh\\b",
            "\\buhm\\b",
            "\\bum\\b",
          ]}
          autoEscape={false}
          textToHighlight={transcript}
        />
      );
    }
  };

  renderTranscriptDialog = () => {
    const { classes, className, ...rest } = this.props;
    var transcript = this.props.data.transcript;
    if (transcript == null) {
      transcript = "";
    }
    return (
      <Dialog
        open={this.state.openTranscriptDialog}
        onClose={() => this.handleCloseTranscript()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Transcript</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {this.renderTranscriptWithHighlighter(transcript)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleCloseTranscript()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderPortletFooter = () => {
    if (this.state.isMeeting == true) {
      return;
    }
    const { classes, className, ...rest } = this.props;
    return (
      <PortletFooter className={classes.portletFooter}>
        {this.renderTranscriptButton()}
        {/* <Button className={classes.recordButton}
            color="primary"
            size="small"
            variant="text"
            onClick={() => this.handleOpen()}
          >
            SHARE
          </Button>
          <Snackbar
            open={this.state.showSnackbar}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar
            }}
            message="Email sent!"
          /> */}
        {this.renderShareDialog()}
        {this.renderTranscriptDialog()}
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title={this.state.title} />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <div className={classes.videoWrapper}>
            <video
              id="videoPlayer"
              className={classes.videoRecorder}
              controls
              controlsList="nodownload"
            ></video>
          </div>
        </PortletContent>
        {this.renderPortletFooter()}
      </Portlet>
    );
  }
}

ViewVideo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewVideo);
