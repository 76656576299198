import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { DoubleArrow as DoubleArrowIcon } from "@material-ui/icons";
import { Typography, Button } from "@material-ui/core";
import { OverflowTooltip } from "components";

function ExternalSignUpLink(props) {
  const { classes, onSignUp } = props;
  const signUpCopy =
    "Don't be jealous. Make your meetings awesome using Sonero!";
  return (
    <>
      <div className={classes.signUpText}>
        <div className={classes.signUpIconWrapper}>
          <DoubleArrowIcon className={classes.signUpIcon} />
        </div>
        <OverflowTooltip title={signUpCopy} placement="bottom-start">
          <Typography variant="h5">{signUpCopy}</Typography>
        </OverflowTooltip>
      </div>
      <a href="/sign-up?ref=share-view">
        <Button
          className={classes.signUpButton}
          color="primary"
          variant="contained"
          onClick={onSignUp}
        >
          SIGN UP FOR FREE
        </Button>
      </a>
    </>
  );
}

export default withStyles(styles)(ExternalSignUpLink);
