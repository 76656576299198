import React, { Component } from "react";
import styles from "./styles";
import { Box, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import { Paper } from "components";

import Transcript from "../Transcript";
import {
  ConversationHighlights,
  ActionItems,
  TrackerNotes,
  WordCloud,
  Clips,
} from "views/ViewMeeting/components";
import classNames from "classnames";
import { getTranscriptSegments } from "helpers/meetingTranscript";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { conversationHighlightTypes, speechStatus } from "helpers";
import { emotions, getEmotionPrediction } from "helpers/emotions";

class Overview extends Component {
  state = {
    questions: [],
    takeaways: [],
    keywords: [],
    resultsWithMetadata: [],
    selectedTopic: null,
    selectedHighlights: "",
    topicCloud: [],
    takeawayAdded: false,
  };
  timeSpentHover = new Date();
  transcript = null;
  transcriptWidgetRef = React.createRef();

  componentWillMount() {
    var resultsWithMetadata = this.props.data.transcript_results;
    if (this.props.meetingStatus === speechStatus.completed) {
      resultsWithMetadata = this.getQuestionAndAnswers(resultsWithMetadata);
      resultsWithMetadata = this.getTakeaways(resultsWithMetadata);
      resultsWithMetadata = this.getKeyTopics(resultsWithMetadata);
    }
    this.setState({
      resultsWithMetadata: resultsWithMetadata,
    });
    if (this.props.preSelectedKeyTopic) {
      this.handleSelectedTopic(this.props.preSelectedKeyTopic);
    }
  }

  componentDidMount() {
    if (this.props.preSelectedKeyTopic && !this.state.preSelectedKeyTopic) {
      if (this.transcriptWidgetRef && this.transcriptWidgetRef.current) {
        this.transcriptWidgetRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        this.setState({
          preSelectedKeyTopic: true,
        });
      }
    }
    if (this.props.scrollToWidget) {
      this.handleScrollWidgetIntoView();
    }
    if (typeof this.props.setTrackerNotesRect === "function") {
      this.props.setTrackerNotesRect(
        document.querySelector("#trackerNotes").getBoundingClientRect()
      );
    }
  }

  getQuestionAndAnswers = (results) => {
    const questions = [];
    const rawQuestions = this.props.data.questions;
    const resultsWithMetadata = results;
    const transcriptionResultsMap = this.props.data.transcriptionResultsMap;

    if (
      Array.isArray(rawQuestions) &&
      rawQuestions.length > 0 &&
      rawQuestions[0].questions
    ) {
      for (const question of rawQuestions[0].questions) {
        let transcriptSegments = [];
        if (question.question_timestamps) {
          let questionStartIdx =
            transcriptionResultsMap[question.question_timestamps[0].toString()];
          let questionEndIdx =
            transcriptionResultsMap[
              question.question_timestamps[
                question.question_timestamps.length - 1
              ].toString()
            ];

          transcriptSegments = getTranscriptSegments(
            resultsWithMetadata.slice(questionStartIdx, questionEndIdx + 1)
          );
          for (let i = questionStartIdx; i < questionEndIdx + 1; i++) {
            resultsWithMetadata[i].isQuestion = true;
          }
        } else {
          // Backwards Compatibility: old meetings won't have question_timestamps
          const idx = transcriptionResultsMap[question.timestamp.toString()];
          if (idx === -1) continue;
          transcriptSegments = getTranscriptSegments(
            resultsWithMetadata.slice(Math.max(idx - 1, 0), idx + 1)
          );
          resultsWithMetadata[idx].isQuestion = true;
        }

        let answerTimestamp = -1;
        let answerSegments = [];
        if (
          Array.isArray(question.answer_timestamps) &&
          question.answer_timestamps.length > 0
        ) {
          answerTimestamp = question.answer_timestamps[0];
          const answerResults = [];
          var previousIdx = -1;
          for (let timestamp of question.answer_timestamps) {
            const answerIdx = transcriptionResultsMap[timestamp.toString()];
            resultsWithMetadata[answerIdx].isAnswer = true;
            if (previousIdx !== -1 && answerIdx - previousIdx > 1) {
              answerResults.push({
                sentence: "...",
                sentence_type: "placeholder",
                speaker_tag: resultsWithMetadata[answerIdx - 1].speaker_tag,
              });
            }
            answerResults.push(resultsWithMetadata[answerIdx]);
            previousIdx = answerIdx;
          }
          answerSegments = getTranscriptSegments(answerResults);
        }

        questions.push({
          type: conversationHighlightTypes.questions,
          timestamp: question.timestamp,
          transcriptSegments: transcriptSegments,
          answerTimestamp: answerTimestamp,
          answerTimestamps: question.answer_timestamps,
          answerSegments: answerSegments,
          directAnswer: question.specific_answer,
        });
      }
      this.setState({
        questions,
      });
    }
    return resultsWithMetadata;
  };

  getMeetingDuration = () => {
    let duration = this.props.data.duration;
    const transcriptResults = this.props.data.transcript_results;
    // For local testing, when the transcript length doesn't match the duration of the meeting
    if (
      transcriptResults.length > 0 &&
      transcriptResults[transcriptResults.length - 1].timestamp > duration
    ) {
      duration = transcriptResults[transcriptResults.length - 1].timestamp + 10;
    }
    return duration;
  };

  getTakeaways = (transcriptResults) => {
    let takeaways = this.props.data.takeaways ?? [];
    let wins = this.props.data.wins;
    let opportunities = this.props.data.opportunities;
    let painPoints = this.props.data.pain_points ?? [];

    // Backwards compatibility: old meetings that dont have wins and opportunities extracted from BE
    if (wins == null || opportunities == null) {
      [wins, opportunities] = getWinsAndOpportunitiesFromTranscriptResults(
        transcriptResults,
        this.props.data.freq_noun_phrases,
        this.getMeetingDuration()
      );
      this.setState({ takeaways: [...wins, ...opportunities] });
      return transcriptResults;
    }

    const transform = (subtype) => (takeaway) => {
      const startIdx = this.props.data.transcriptionResultsMap[
        takeaway.timestamps[0].toString()
      ];
      const endIdx = this.props.data.transcriptionResultsMap[
        takeaway.timestamps[takeaway.timestamps.length - 1].toString()
      ];
      const segments = getTranscriptSegments(
        transcriptResults.slice(startIdx, endIdx + 1)
      );
      for (let i = startIdx; i < endIdx + 1; i++) {
        transcriptResults[i].isTakeaway = true;
      }

      return {
        type: conversationHighlightTypes.takeaways,
        subtype: subtype,
        timestamp: takeaway.timestamps[0],
        transcriptSegments: segments,
        facialEmotion: takeaway.facial_emotion,
      };
    };
    takeaways = takeaways.map(transform(conversationHighlightTypes.takeaways));
    wins = wins.map(transform(conversationHighlightTypes.wins));
    opportunities = opportunities.map(
      transform(conversationHighlightTypes.opportunities)
    );
    painPoints = painPoints.map(
      transform(conversationHighlightTypes.painPoints)
    );

    this.setState({
      takeaways: [...takeaways, ...wins, ...opportunities, ...painPoints],
    });
    return transcriptResults;
  };

  getKeyTopics = (transcriptResults) => {
    const { topics } = this.props.data;
    let topicCloud = [];

    topicCloud = topics.map((topic, idx) => {
      return {
        text: topic.topic,
        value: topics.length - idx,
        breakdown: topic.breakdown,
        sentiment: topic.sentiment,
        numPositiveSentences: topic.num_positive_sentences,
        numNegativeSentences: topic.num_negative_sentences,
        sentimentRange: topic.sentiment_range,
      };
    });

    transcriptResults = transcriptResults.map((result) => {
      var topicDict = {};
      topicCloud.forEach((topic, idx) => {
        const includesTopic = result.sentence
          .toLowerCase()
          .includes(topic.text.toLowerCase());
        topicDict[topic.text] = includesTopic;
      });
      return {
        ...result,
        containsTopics: topicDict,
      };
    });

    this.setState({ topicCloud });
    return transcriptResults;
  };

  handleScrollWidgetIntoView = () => {
    const widget = this.props.scrollToWidget;
    if (widget === "ACTION-ITEMS") {
      this.actionItems.intersectTargetActionItems.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    } else if (widget === "CLIPS") {
      this.clips.intersectTargetClips.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  handleMouseEnterWidget = () => {
    this.timeSpentHover = new Date();
  };

  handleMouseLeaveWidget = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "Time spent on Conversation Highlights",
      value: timeSpent,
    });
  };

  handleSelectedTopic = (topic) => {
    this.setState({
      selectedHighlights: "",
      selectedHighlight: null,
      selectedTopic: topic,
    });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Key Topic",
    });
  };

  handleSelectedHighlights = (highlights) => {
    if (highlights === this.state.selectedHighlights) {
      this.setState({
        selectedHighlights: "",
        selectedHighlight: null,
        selectedTopic: null,
      });
    } else {
      this.setState({
        selectedHighlights: highlights,
        selectedHighlight: null,
        selectedTopic: null,
      });
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: `User filtered for ${highlights} in Conversation Highlights`,
      });
    }
  };

  handleSelectedActionItem = (item) => {
    if (this.transcript) {
      this.transcript.current.goToActionItem(item);
      this.setState({ selectedActionItem: item });
    }
  };

  // Insight could be a question, win, or opportunity object, identified by type
  handleInsightOnClick = (insight, type) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(insight.timestamp);
    this.setState(
      {
        selectedHighlight: { insight, type },
        selectedTopic: null,
      },
      () => {
        // After we set the highlight, scroll to it!
        this.transcript.current.goToInsight(insight.timestamp);
      }
    );
  };

  handleOpenAddActionItem = (sentence) => {
    this.actionItems.handleOpenNewActionItemDialogFromTranscript(sentence);
  };

  handleOpenAddTakeaway = (transcriptResults, sentence, endTime) => {
    this.conversationHighlights.handleOpenNewTakeawayDialog(
      transcriptResults,
      sentence,
      endTime
    );
  };

  handleAddTakeaway = (transcriptResults, type, endTime, sentence) => {
    return this.props
      .addTakeaway(transcriptResults, type, endTime, sentence)
      .then((response) => {
        if (response.status === 200) {
          var resultsWithMetadata = this.props.data.transcript_results;
          resultsWithMetadata = this.getTakeaways(resultsWithMetadata);
          this.setState({
            resultsWithMetadata: resultsWithMetadata,
          });
        }
        return response;
      });
  };

  handleDeleteTakeaway = (takeaway) => {
    return this.props.deleteTakeaway(takeaway).then((response) => {
      if (response.status === 200) {
        var resultsWithMetadata = this.props.data.transcript_results;
        resultsWithMetadata = this.getTakeaways(resultsWithMetadata);
        this.setState({
          resultsWithMetadata: resultsWithMetadata,
        });
      }
      return response;
    });
  };

  renderWordCloud = () => {
    const { classes } = this.props;
    return (
      <WordCloud
        className={classes.overviewSection}
        data={this.props.data}
        topics={this.state.topicCloud}
        handleSelectedTopic={this.handleSelectedTopic}
      />
    );
  };

  renderActionItems() {
    const { classes } = this.props;
    return (
      <ActionItems
        ref={(instance) => {
          this.actionItems = instance;
        }}
        className={classes.overviewSection}
        data={this.props.data}
        meetingStatus={this.props.meetingStatus}
        setMediaPlayerCurrentTimeAndPlay={
          this.props.setMediaPlayerCurrentTimeAndPlay
        }
        addActionItem={this.props.addActionItem}
        updateActionItem={this.props.updateActionItem}
        deleteActionItem={this.props.deleteActionItem}
        completeActionItem={this.props.completeActionItem}
        assignActionItemOwner={this.props.assignActionItemOwner}
        assignActionItemTarget={this.props.assignActionItemTarget}
        notifyActionItemOwners={this.props.notifyActionItemOwners}
        openSnackbar={this.props.openSnackbar}
        handleSelectedActionItem={this.handleSelectedActionItem}
        scrollToActionItem={this.props.scrollToActionItem}
      />
    );
  }

  renderTrackerNotes() {
    const { classes } = this.props;
    return (
      <TrackerNotes
        className={classes.overviewSection}
        ref={this.trackerNotes}
        data={this.props.data}
        meetingStatus={this.props.meetingStatus}
        handleInsightOnClick={this.handleInsightOnClick}
        keywords={this.props.data.keywords}
        updateDecisionNote={this.props.updateDecisionNote}
        deleteDecisionNote={this.props.deleteDecisionNote}
        assignDecisionNoteOwner={this.props.assignDecisionNoteOwner}
        scrollToTracker={this.props.scrollToTracker}
        scrollToDecision={this.props.scrollToDecision}
        scrollToNote={this.props.scrollToNote}
      />
    );
  }

  renderTranscript() {
    const { classes } = this.props;
    this.transcript = React.createRef();
    return (
      <>
        <Transcript
          className={classes.transcriptSection}
          ref={this.transcript}
          data={this.props.data}
          resultsWithMetadata={this.state.resultsWithMetadata}
          initWithTranscriptSearchValue={
            this.props.initWithTranscriptSearchValue
          }
          setMediaPlayerCurrentTimeAndPlay={
            this.props.setMediaPlayerCurrentTimeAndPlay
          }
          meetingTitle={this.props.meetingTitle}
          mapParticipantResponseHandler={
            this.props.mapParticipantResponseHandler
          }
          transcriptUpdateResponseHandler={
            this.props.transcriptUpdateResponseHandler
          }
          transcriptUpdateFailureHandler={
            this.props.transcriptUpdateFailureHandler
          }
          questionUpdateResponseHandler={
            this.props.questionUpdateResponseHandler
          }
          openCreateBookmark={this.props.openCreateBookmark}
          openActionItemForm={(sentence) => {
            this.handleOpenAddActionItem(sentence);
          }}
          selectedTopic={this.state.selectedTopic}
          selectedHighlights={this.state.selectedHighlights}
          selectedHighlight={this.state.selectedHighlight}
          selectedActionItem={this.state.selectedActionItem}
          isSharedMeetingView={this.props.isSharedMeetingView}
          createClip={this.props.createClip}
          openTakeaway={(transcriptResult, sentence, endTime) => {
            this.handleOpenAddTakeaway(transcriptResult, sentence, endTime);
          }}
          openSnackbar={this.props.openSnackbar}
        />
      </>
    );
  }

  renderConversationHighlights = () => {
    const { classes } = this.props;
    return (
      <>
        <ConversationHighlights
          ref={(instance) => {
            this.conversationHighlights = instance;
          }}
          className={classes.overviewSection}
          data={this.props.data}
          meetingStatus={this.props.meetingStatus}
          setMediaPlayerCurrentTimeAndPlay={
            this.props.setMediaPlayerCurrentTimeAndPlay
          }
          questions={this.state.questions}
          takeaways={this.state.takeaways}
          onSelectedHighlights={this.handleSelectedHighlights}
          selectedHighlights={this.state.selectedHighlights}
          onMouseEnter={() => this.handleMouseEnterWidget()}
          onMouseLeave={() => this.handleMouseLeaveWidget()}
          handleInsightOnClick={this.handleInsightOnClick}
          handleAddTakeaway={this.handleAddTakeaway}
          handleDeleteTakeaway={this.handleDeleteTakeaway}
          scrollToQuestion={this.props.scrollToQuestion}
          isSharedMeetingView={this.props.isSharedMeetingView}
        />
      </>
    );
  };

  renderClips = () => {
    const {
      classes,
      data,
      isSharedMeetingView,
      deleteClip,
      deleteAllClips,
      openClip,
      openSnackbar,
      renameClip,
      scrollToClip,
    } = this.props;

    if (isSharedMeetingView && !data.share_granularity.share_recording) {
      return <></>;
    }
    return (
      <Clips
        ref={(instance) => {
          this.clips = instance;
        }}
        className={classes.overviewSection}
        data={data}
        deleteClip={deleteClip}
        deleteAllClips={deleteAllClips}
        isSharedMeetingView={isSharedMeetingView}
        openSnackbar={openSnackbar}
        openClip={openClip}
        renameClip={renameClip}
        scrollToClip={scrollToClip}
      />
    );
  };

  render() {
    const { classes, className } = this.props;
    const rootClassName = classNames(classes.root, className);
    const shareGranularity = this.props.data.share_granularity;
    const showTranscript =
      !this.props.isSharedMeetingView || shareGranularity.share_transcript;
    const showInsights =
      !this.props.isSharedMeetingView || shareGranularity.share_insights;
    if (showTranscript) {
      return (
        <Paper className={rootClassName}>
          <Box className={classes.parent}>
            {showInsights && (
              <Box className={classes.contentColumn}>
                <Box>{this.renderConversationHighlights()}</Box>
                <Box>{this.renderActionItems()}</Box>
                <Box>{this.renderTrackerNotes()}</Box>
                <Box>{this.renderWordCloud()}</Box>
                <Box>{this.renderClips()}</Box>
              </Box>
            )}
            <Box
              className={classes.transcriptBox}
              ref={this.transcriptWidgetRef}
            >
              {this.renderTranscript()}
            </Box>
          </Box>
        </Paper>
      );
    } else {
      return (
        <Paper className={rootClassName}>
          <Box className={classes.parent}>
            <Box className={classes.contentColumn}>
              {this.renderConversationHighlights()}
              {this.renderWordCloud()}
            </Box>
            <Box className={classes.contentColumn}>
              {this.renderActionItems()}
              {this.renderTrackerNotes()}
            </Box>
          </Box>
        </Paper>
      );
    }
  }
}

// Backwards Compatibility: old meetings won't have takeaways (wins and opportunities) extracted on BE

// Constants and helpers for extracting takeaways
const POPOVER_MAX_LENGTH = 400;

const winsLimit = (duration) => {
  const numOf30Mins = Math.ceil(duration / 1800);
  return numOf30Mins * 5;
};

const opportunitiesLimit = (duration) => {
  const numOf30Mins = Math.ceil(duration / 1800);
  return numOf30Mins * 5;
};

// Function to extract takeaways from transcript results
const getWinsAndOpportunitiesFromTranscriptResults = (
  transcriptResults,
  freqNounPhrases,
  duration
) => {
  let wins = [];
  let opportunities = [];
  let addedIndices = new Set();

  // Append the index to each transcript result
  transcriptResults = transcriptResults.map((result, idx) => ({
    ...result,
    index: idx,
  }));

  // Define a function that extracts the important transcript results
  const extractImportantResults = (isPositive) => (result) => {
    const index = result.index;
    const importantResults = [];

    // Find the first occurrence of a freq noun phrase in the previous 5 sentences
    // The transcript segment will span from this occurrence to the important moment
    let importantIndex;
    for (let i = index; i >= Math.max(0, index - 4); i--) {
      const sentence = transcriptResults[i].sentence.toLowerCase();
      if (freqNounPhrases.some((topic) => sentence.includes(topic))) {
        importantIndex = i;
      }
    }

    if (importantIndex && !addedIndices.has(importantIndex)) {
      importantResults.unshift(transcriptResults[index]);
      let numChars = transcriptResults[index].sentence.length;
      for (let i = index - 1; i >= importantIndex; i--) {
        numChars += transcriptResults[i].sentence.length;
        if (numChars >= POPOVER_MAX_LENGTH) break;
        importantResults.unshift(transcriptResults[i]);
      }

      // Gauge the emotions at the sentence to decide if we include this result
      if (transcriptResults[index].emotions) {
        const emotionPrediction = getEmotionPrediction(
          transcriptResults[index].emotions
        );

        if (isPositive && emotionPrediction === emotions.joy) {
          const segments = getTranscriptSegments(importantResults);
          wins.push({
            type: conversationHighlightTypes.takeaways,
            subtype: conversationHighlightTypes.wins,
            timestamp: segments[0].transcript[0].timestamp,
            transcriptSegments: segments,
          });
          addedIndices.add(importantIndex);
        } else if (
          !isPositive &&
          emotionPrediction !== emotions.joy &&
          emotionPrediction !== emotions.neutral
        ) {
          const segments = getTranscriptSegments(importantResults);
          opportunities.push({
            type: conversationHighlightTypes.takeaways,
            subtype: conversationHighlightTypes.opportunities,
            timestamp: segments[0].transcript[0].timestamp,
            transcriptSegments: segments,
          });
          addedIndices.add(importantIndex);
        }
      }
      // Backwards compatibility: existing meetings that dont have emotion sentiments will skip that step
      else {
        const segments = getTranscriptSegments(importantResults);
        if (isPositive) {
          wins.push({
            type: conversationHighlightTypes.takeaways,
            subtype: conversationHighlightTypes.wins,
            timestamp: segments[0].transcript[0].timestamp,
            transcriptSegments: segments,
          });
        } else {
          opportunities.push({
            type: conversationHighlightTypes.takeaways,
            subtype: conversationHighlightTypes.opportunities,
            timestamp: segments[0].transcript[0].timestamp,
            transcriptSegments: segments,
          });
        }
        addedIndices.add(importantIndex);
      }
    }
  };

  // Get the positive moments (Wins), sorted from most to least positive
  let positiveResults = transcriptResults
    .filter((result) => result.polarity >= 0.7)
    .sort((first, second) => {
      if (first.polarity < second.polarity) {
        return 1;
      } else if (first.polarity > second.polarity) {
        return -1;
      } else {
        return 0;
      }
    });
  positiveResults.forEach(extractImportantResults(true));

  // Get the negative moments (Opportunities), sorted from most to least negative
  let negativeResults = transcriptResults
    .filter((result) => result.polarity <= -0.35)
    .sort((first, second) => {
      if (first.polarity < second.polarity) {
        return -1;
      } else if (first.polarity > second.polarity) {
        return 1;
      } else {
        return 0;
      }
    });
  negativeResults.forEach(extractImportantResults(false));

  const limitedWins = wins.slice(0, winsLimit(duration) + 1);
  const limitedOpportunities = opportunities.slice(
    0,
    opportunitiesLimit(duration) + 1
  );

  limitedWins.forEach((element) => {
    element.transcriptSegments.forEach((element) => {
      element.transcript.forEach((sentence) => {
        transcriptResults[sentence.index].isTakeaway = true;
      });
    });
  });
  limitedOpportunities.forEach((element) => {
    element.transcriptSegments.forEach((element) => {
      element.transcript.forEach((sentence) => {
        transcriptResults[sentence.index].isTakeaway = true;
      });
    });
  });

  return [limitedWins, limitedOpportunities];
};

Overview.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Overview);
