import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material icons
import {
  AccountCircle as AccountCircleIcon,
  Folder,
  TrackChanges,
} from "@material-ui/icons";

// Material components
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";

// Material icons
import {
  Person as AccountBoxIcon,
  Settings as SettingsIcon,
  ContactSupport as HelpIcon,
  People as PeopleIcon,
  Apps as AppsIcon,
  Payment as PaymentIcon,
  PowerSettingsNew as SignOutIcon,
  Star as StarIcon,
  Dashboard as DashboardIcon,
  GroupWork as GroupWorkIcon,
} from "@material-ui/icons";

// Component styles
import styles from "./styles";

// Shared services
import { isSoneroUser, logout, isUserLoggedIn } from "services/speech";
import { getNextUpgradeOnUserPricingTier } from "helpers/pricingTiers";

import {
  isUserInOrg,
  isUserOrgOwner,
  isUserOrgOwnerOrAdmin,
  isOnboardingComplete,
} from "helpers/user";

class Sidebar extends Component {
  isLoggedIn = isUserLoggedIn();

  handleSignOut = () => {
    logout();
  };

  handleSupport = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    } else {
      window.open("https://sonero.ai/contact/", "_blank");
    }
  };

  renderBilling = () => {
    if (isUserInOrg() && isUserOrgOwner() === false) {
      return;
    }
    const { classes, className, fullName } = this.props;
    return (
      <ListItem
        activeClassName={classes.activeListItem}
        className={classes.listItem}
        component={NavLink}
        to="/billing"
        disabled={!this.isLoggedIn}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Billing"
        />
      </ListItem>
    );
  };

  renderFreeMeetingLeft = () => {
    const { classes } = this.props;
    const meetingDurationLeft = localStorage.getItem("meetingDurationLeft");
    if (meetingDurationLeft == null || +meetingDurationLeft < 0) {
      return <></>;
    }
    const hoursLeft = +meetingDurationLeft / 3600;
    let timeLeft = "";
    if (hoursLeft > 0 && hoursLeft < 1) {
      timeLeft = "Less than an hour remaining!";
    } else if (hoursLeft >= 1 && Math.round(hoursLeft) === 1) {
      timeLeft = "1 hour remaning!";
    } else {
      timeLeft = `${Math.round(hoursLeft)} hours remaining!`;
    }
    const nextTier = getNextUpgradeOnUserPricingTier().toLowerCase();

    return (
      <div className={classes.freeQuotaContainer}>
        <Typography className={classes.freeQuotaText}>{timeLeft}</Typography>
      </div>
    );
  };

  renderProfileInfo = () => {
    const { classes, fullName, email } = this.props;
    if (!this.isLoggedIn) {
      return (
        <div className={classes.signUpCTA}>
          <Typography variant="h6">Don't have an account?</Typography>
          <Link to="/sign-up">
            <Button color="primary">Sign up</Button>
          </Link>
        </div>
      );
    }
    return (
      <>
        <Typography className={classes.nameText} variant="h6">
          {fullName}
        </Typography>
        <Typography className={classes.emailText} variant="h6">
          {email}
        </Typography>
      </>
    );
  };

  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);

    let showOnboarding = !isOnboardingComplete();

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link className={classes.logoLink} to="/">
            <Typography className={classes.logoName} variant="h1">
              sonero
            </Typography>
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          <Link to="/">
            <AccountCircleIcon className={classes.avatar} />
          </Link>
          {this.renderProfileInfo()}
          {this.renderFreeMeetingLeft()}
        </div>
        <Divider className={classes.profileDivider} />
        <List component="div" disablePadding>
          {showOnboarding && (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/setup"
              disabled={!this.isLoggedIn}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <StarIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Get Started"
              />
            </ListItem>
          )}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/dashboard"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          {isUserOrgOwnerOrAdmin() && (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/team-dashboard"
              disabled={!this.isLoggedIn}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <GroupWorkIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Team Dashboard"
              />
            </ListItem>
          )}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/my-meetings"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="My Meetings"
            />
          </ListItem>
          {isSoneroUser() && (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/accounts"
              disabled={!this.isLoggedIn}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Folder />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Client Accounts"
              />
            </ListItem>
          )}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/trackers"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TrackChanges />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Trackers"
            />
          </ListItem>
        </List>
        <Divider className={classes.listDivider} />
        <List component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/integrations"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Integrations"
            />
          </ListItem>
        </List>
        <Divider className={classes.listDivider} />
        <List component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/account"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="My Account"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/settings"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Settings"
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            button={true}
            onClick={this.handleSupport}
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Support"
            />
          </ListItem>
        </List>
        <Divider className={classes.listDivider} />
        <List component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            onClick={this.handleSignOut}
            to="/sign-in"
            disabled={!this.isLoggedIn}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SignOutIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Log Out"
            />
          </ListItem>
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);
