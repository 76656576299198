export const WEEK_IN_MILLISECS = 604800000;

export const timeFormatter = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export const timeFormatterText = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "hrs " + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + "mins " + (secs < 10 ? "0" : "");
    ret += "" + secs + "secs";
    return ret;
}

export const getDateTimeFromSeconds = (seconds) => {
    const val = new Date(seconds * 1000);
    return val.toISOString().substr(11, 8);
};

// Time String is a string of at most 6 digits, for example '103025', which reprensents 10hours 30mins 25secs
export const convertStringToDuration = (timeString) => {
    const secs = +timeString.slice(-2);
    const mins = +timeString.slice(0, -2).slice(-2);
    const hours = +timeString.slice(0, -4).slice(-2);
    const duration = secs + (mins * 60) + (hours * 3600);
    return duration;
};

export const convertDurationToString = (duration) => {
    const hours = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let timeString = 
        (hours ? (hours < 10 && "0") + hours : "00") +
        (mins ? (mins < 10 && "0") + mins : "00") +
        (secs ? (secs < 10 && "0") + secs : "00");
    timeString = timeString.replace(/^0+/, "");
    return timeString;
};

export const formatTimeString = (timeString) => {
    const secs = +timeString.slice(-2);
    const mins = +timeString.slice(0, -2).slice(-2);
    const hours = +timeString.slice(0, -4).slice(-2);

    let formattedTimeString =
        (hours ? (hours < 10 && "0") + hours : "00") + ":" +
        (mins ? (mins < 10 && "0") + mins : "00") + ":" +
        (secs ? (secs < 10 && "0") + secs : "00");
    return formattedTimeString;
};

export const dateFormatter = (date, options={}) => {
    const localDate = new Date(date);
    return localDate.toLocaleString("en-US", options);
}

export const getDateRangeString = (startDate, endDate, format) => {
    const formattedStartDate = dateFormatter(startDate, format);
    const formattedEndDate = dateFormatter(endDate, format);
    if (formattedStartDate === formattedEndDate) {
        return `on ${formattedStartDate}`;
    }
    return `from ${formattedStartDate} to ${formattedEndDate}`;
};

export function Stopwatch(refreshRate) {
    this.time = 0;
    this.intervalId = null;
    this.callback = () => {};
    this.callbackTime = -1;

    this.start = () => {
        const secs = refreshRate / 1000;
        this.intervalId = setInterval(() => {
            this.time += secs;
            if (
                this.time >= this.callbackTime && 
                this.time < this.callbackTime + secs
            ) {
                this.callback();
            }
        }, refreshRate);
    };
    this.stop = () => {
        clearInterval(this.intervalId);
    };
    this.registerCallback = (callback, time) => {
        this.callback = callback;
        this.callbackTime = time;
    };
    this.unregisterCallback = () => {
        this.callback = () => {};
        this.callbackTime = -1;
    };
    this.reset = () => {
        this.unregisterCallback();
        this.time = 0;
    };
}

export function getMonday(date) {
    // Taken from https://stackoverflow.com/a/4156516
    date = new Date(date.setHours(0, 0, 0, 0));
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}

export const convertMilisecsToHigherOrder = (duration) => {
    const secs = Math.floor(duration / 1000);
    const mins = Math.floor(duration / 60000);
    const hours = Math.floor(duration / 3600000);
    const days = Math.floor(duration / 86400000);
    const weeks = duration / 604800000;
    const months = duration / 2628000000;
    const years = Math.floor(duration / 31540000000);

    if (secs <= 1) {
        return "1 second";
    } else if (secs < 60) {
        return secs + " seconds";
    } else if (mins < 60) {
        return mins + " minute" + (mins > 1 ? "s" : "");
    } else if (hours < 24) {
        return hours + " hour" + (hours > 1 ? "s" : "");
    }else if (days < 30) {
        return days + " day" + (days > 1 ? "s" : "");
    } else if (weeks < (2628000000 / 604800000)) {
        return Math.floor(weeks) + " week" + (Math.floor(weeks) > 1 ? "s" : "");
    } else if (months < (31540000000 / 2628000000)) {
        return Math.floor(months) + " month" + (Math.floor(months) > 1 ? "s" : "");
    } else {
        return years + " year" + (years > 1 ? "s" : "");
    }
}