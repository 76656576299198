import React, { Component } from "react";
import MaterialTable from "material-table";

// Externals
import PropTypes from "prop-types";

import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ViewColumn from "@material-ui/icons/ViewColumn";
import EditIcon from "@material-ui/icons/Edit";
import BookmarksIcon from "@material-ui/icons/Bookmarks";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";

// Shared components
import { PortletLabel } from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

const tableIcons = {
  Add: React.forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} color="primary" />
  )),
  Check: React.forwardRef((props, ref) => (
    <Check {...props} ref={ref} color="primary" />
  )),
  Clear: React.forwardRef((props, ref) => (
    <Clear {...props} ref={ref} color="primary" />
  )),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} color="primary" />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} color="primary" />
  )),
  Edit: React.forwardRef((props, ref) => (
    <Edit {...props} ref={ref} color="primary" />
  )),
  Export: React.forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref} color="primary" />
  )),
  Filter: React.forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} color="primary" />
  )),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} color="primary" />
  )),
  LastPage: React.forwardRef((props, ref) => (
    <LastPage {...props} ref={ref} color="primary" />
  )),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} color="primary" />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} color="primary" />
  )),
  ResetSearch: React.forwardRef((props, ref) => (
    <Clear {...props} ref={ref} color="primary" />
  )),
  Search: React.forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} color="primary" />
  )),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} color="primary" />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <Remove {...props} ref={ref} color="primary" />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} color="primary" />
  )),
};

class ViewBookmarks extends Component {
  onRowDelete = (oldData) => {
    return this.props.handleBookmarkDelete(oldData);
  };

  onRowEdit = (event, bookmark) => {
    this.props.handleBookmarkEdit(bookmark);
  };

  handleRowClick = (event, rowData) => {
    var bookmarks = this.props.bookmarks;
    var index = this.props.bookmarks.indexOf(rowData);
    this.props.handleBookmarkSelect(
      bookmarks[index].start_time,
      bookmarks[index].end_time
    );
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked bookmark to play",
    });
  };

  getFormattedTime = (duration) => {
    // return emtpy string if no duration (i.e. no end time)
    if (duration == null) {
      return "";
    }

    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  renderEmptyBookmarksView = () => {
    const { classes } = this.props;
    return (
      <div className={classes.emptyStateContainer}>
        <BookmarksIcon className={classes.emptyStateIcon} />
        <Typography variant="h5" align="center">
          There are no bookmarks available for the meeting
        </Typography>
      </div>
    );
  };

  renderBookmarksTable = () => {
    const { classes, hideHint } = this.props;
    return (
      <div className={classes.table}>
        <MaterialTable
          icons={tableIcons}
          title={<PortletLabel title="Click on a bookmark to play" />}
          columns={[
            {
              title: "Description",
              field: "description",
              width: "50%",
              cellStyle: { lineHeight: "20px" },
            },
            {
              title: "Start Time",
              field: "start_time",
              defaultSort: "asc",
              render: (rowData) => this.getFormattedTime(rowData.start_time),
            },
            {
              title: "End Time",
              field: "end_time",
              render: (rowData) => this.getFormattedTime(rowData.end_time),
            },
          ]}
          data={this.props.bookmarks}
          options={{
            rowStyle: {
              fontFamily: "Roboto",
              fontSize: "14px",
            },
            search: false,
            header: true,
            paging: false,
            actionsColumnIndex: -1,
            toolbar: !hideHint,
          }}
          localization={{
            body: {
              emptyDataSourceMessage:
                "No bookmarks have been created for this meeting",
            },
            toolbar: {
              searchPlaceholder: "Filter by Title",
            },
          }}
          style={{
            boxShadow: "none",
          }}
          onRowClick={this.handleRowClick}
          editable={
            !this.props.isSharedMeetingView && {
              onRowDelete: this.onRowDelete,
            }
          }
          actions={
            !this.props.isSharedMeetingView && [
              {
                icon: () => <EditIcon color="primary" />,
                tooltip: "Edit",
                onClick: this.onRowEdit,
              },
            ]
          }
        />
      </div>
    );
  };

  renderDialog = () => {
    const { bookmarks } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={this.props.open}
        onClose={this.props.closeHandler}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          {bookmarks.length > 0
            ? this.renderBookmarksTable()
            : this.renderEmptyBookmarksView()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeHandler} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { bookmarks, isModal } = this.props;
    if (!isModal) {
      return (
        <div>
          {bookmarks.length === 0
            ? this.renderEmptyBookmarksView()
            : this.renderBookmarksTable()}
        </div>
      );
    }
    return <div>{this.renderDialog()}</div>;
  }
}

ViewBookmarks.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewBookmarks);
