import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";

import { Typography, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import {
  Assignment,
  Person,
  AssignmentTurnedIn,
  PriorityHigh,
} from "@material-ui/icons";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

import ReactGA from "react-ga";

function HighlightMenu(props) {
  const [highlightStart, setHighlightStart] = useState();
  const [highlightEnd, setHighlightEnd] = useState();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (props.transcriptBody) {
      props.transcriptBody.addEventListener("mouseup", handleOnHighlight);
    }

    return () => {
      if (props.transcriptBody) {
        props.transcriptBody.removeEventListener("mouseup", handleOnHighlight);
      }
    };
  }, [props.transcriptBody]);

  const handleOnHighlight = (event) => {
    const selection = document.getSelection();
    if (selection && selection.type === "Range") {
      const transcriptSentences = document.querySelectorAll(
        ".transcript-sentence"
      );

      // Find the start and end transcript elements that were selected
      let startSentence;
      let endSentence;
      let i = 0;
      for (i; i < transcriptSentences.length; i++) {
        if (selection.containsNode(transcriptSentences[i], true)) {
          startSentence = transcriptSentences[i];
          break;
        }
      }
      if (!startSentence) return;
      for (i; i < transcriptSentences.length; i++) {
        if (!selection.containsNode(transcriptSentences[i], true)) {
          endSentence = transcriptSentences[i];
          break;
        }
      }

      // Find the corresponding timestamps of the start and end transcript elements
      let startTimestamp = null;
      let endTimestamp = endSentence ? null : props.data.duration;

      for (let j = 0; j < startSentence.classList.length; j++) {
        const className = startSentence.classList[j];
        if (className.startsWith("timestamp-")) {
          startTimestamp = className.slice(10);
          break;
        }
      }
      if (startTimestamp == null) return;

      if (endSentence) {
        for (let j = 0; j < endSentence.classList.length; j++) {
          const className = endSentence.classList[j];
          if (className.startsWith("timestamp-")) {
            endTimestamp = className.slice(10);
            break;
          }
        }
      }
      if (endTimestamp == null) return;

      // Update states to show the clip menu
      if (
        highlightStart === +startTimestamp &&
        highlightEnd === +endTimestamp
      ) {
        handleCloseMenu();
      } else {
        setMenuOpen(true);
        setHighlightStart(+startTimestamp);
        setHighlightEnd(+endTimestamp);
        props.updateMousePosition(event.clientX - 2, event.clientY - 4);
      }
    }
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setHighlightStart(null);
    setHighlightEnd(null);
    props.updateMousePosition(null, null);
  };

  const handleClickClipOption = (event) => {
    handleCloseMenu();
    props.openClipCreateForm(event, highlightStart, highlightEnd);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User creating clip via highlighting",
      label: "Transcript",
    });
  };

  const handleClickAssignSpeakerOption = (event) => {
    handleCloseMenu();
    props.openSpeakerMenu(event.target, getHighlightedTimestamps(), -1);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User assigning speaker via highlighting",
      label: "Transcript",
    });
  };

  const handleClickActionItem = (event) => {
    handleCloseMenu();
    const highlightedResults = getHighlightedTranscriptResults();
    const sentence = highlightedResults.reduce(
      (acc, result) => acc + " " + result.sentence,
      ""
    );
    props.openAddActionItem(sentence);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User adding action item via highlighting",
      label: "Transcript",
    });
  };

  const handleClickTakeaway = (event) => {
    handleCloseMenu();
    const highlightedResults = getHighlightedTranscriptResults();
    const sentence = highlightedResults.reduce(
      (acc, result) => acc + " " + result.sentence,
      ""
    );
    props.openAddTakeaway(highlightedResults, sentence);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User adding takeaway via highlighting",
      label: "Transcript",
    });
  };

  const getHighlightedTimestamps = () => {
    const transcriptResults = props.data.transcript_results;
    const startIdx = this.props.data.transcriptionResultsMap[
      highlightStart.toString()
    ];
    const endIdx = this.props.data.transcriptionResultsMap[
      highlightEnd.toString()
    ];
    const highlightedTimestamps = [];
    for (let i = startIdx; i < endIdx; i++) {
      highlightedTimestamps.push(transcriptResults[i].timestamp);
    }
    return highlightedTimestamps;
  };

  const getHighlightedTranscriptResults = () => {
    const transcriptResults = props.data.transcript_results;
    const startIdx = this.props.data.transcriptionResultsMap[
      highlightStart.toString()
    ];
    const endIdx = this.props.data.transcriptionResultsMap[
      highlightEnd.toString()
    ];
    return transcriptResults.slice(startIdx, endIdx);
  };

  const { mouseX, mouseY } = props;
  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY != null && mouseX != null
          ? { top: mouseY, left: mouseX }
          : { top: 0, left: 0 }
      }
      open={menuOpen}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleClickClipOption}>
        <ListItemIcon>
          <Assignment fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Clip</Typography>
      </MenuItem>
      <MenuItem onClick={handleClickAssignSpeakerOption}>
        <ListItemIcon>
          <Person fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Assign Speaker</Typography>
      </MenuItem>
      <MenuItem onClick={handleClickActionItem}>
        <ListItemIcon>
          <AssignmentTurnedIn fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Add Action Item</Typography>
      </MenuItem>
      <MenuItem onClick={handleClickTakeaway}>
        <ListItemIcon>
          <PriorityHigh fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Add Takeaway</Typography>
      </MenuItem>
    </Menu>
  );
}

export default withStyles(styles)(HighlightMenu);
