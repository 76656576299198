import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Dashboard as DashboardLayout } from "layouts";
import { MemberDashboard, DatePicker } from "./components";
import {
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
} from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import RemoveIcon from "@material-ui/icons/Remove";
import { getTeamDashboardData } from "services/speech";
import { getMonday, getDateRangeString, WEEK_IN_MILLISECS } from "helpers/time";
import ReactGA from "react-ga";

const startOfWeek = getMonday(new Date());
const endOfWeek = new Date(startOfWeek.getTime() + WEEK_IN_MILLISECS - 1);
const dateFormat = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};

function TeamDashboard(props) {
  const { classes, history } = props;
  const [orgName, setOrgName] = useState("");
  const [teamData, setTeamData] = useState([]);
  const [teamOrgData, setTeamOrgData] = useState([]);
  const [members, setMembers] = useState({});
  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(endOfWeek);
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [dashboardMemberId, setDashboardMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  let hoverStart = new Date();

  useEffect(() => {
    setIsLoading(true);
    getTeamDashboardData(startDate, endDate).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          history.push("/sign-in");
        } else if (response.status === 400) {
          setError("Please ensure the date range is within 3 months");
        }
        return;
      }
      const orgName = response.data.org_name;
      const teamData = response.data.data.map((d) => ({
        id: d.id,
        name: d.name,
        email: d.email,
        numCompleted: d.num_completed_meetings,
        numUpcoming: d.num_upcoming_meetings,
        numWithoutActionItems: d.num_meetings_without_action_items,
        meetingScore: d.avg_meeting_score,
        meetingScoreTrend: d.meeting_score_trend,
        sentiment: d.avg_sentiment,
        sentimentTrend: d.sentiment_trend,
        talkPercentage: d.talk_ratio * 100,
        listenPercentage: d.listen_ratio * 100,
      }));
      const teamOrgData = {
        totalNumScheduledMeetings:
          response.data.org_data.total_num_scheduled_meetings,
        totalNumCompletedMeetings:
          response.data.org_data.total_num_completed_meetings,
        totalNumMeetingsWithoutActionItems:
          response.data.org_data.total_num_meetings_without_action_items,
        avgMeetingScore: response.data.org_data.avg_meeting_score,
        overallSentiment: response.data.org_data.overall_sentiment,
        scheduledMeetingsTrend: response.data.org_data.scheduled_meetings_trend,
        completedMeetingsTrend: response.data.org_data.completed_meetings_trend,
        meetingsWithoutActionItemsTrend:
          response.data.org_data.meetings_without_action_items_trend,
        meetingScoreTrend: response.data.org_data.meeting_score_trend,
        sentimentTrend: response.data.org_data.sentiment_trend,
      };
      const members = {};
      teamData.forEach((d) => {
        members[d.id] = {
          name: d.name,
          email: d.email,
        };
      });
      setOrgName(orgName);
      setTeamData(teamData);
      setTeamOrgData(teamOrgData);
      setMembers(members);
      setIsLoading(false);
      setError("");
    });
  }, [startDate, endDate]);

  const getSentimentLabel = (sentimentValue) => {
    if (sentimentValue === 0) {
      return "Neutral";
    } else if (sentimentValue > 0) {
      return "Positive";
    } else if (sentimentValue < 0) {
      return "Negative";
    } else {
      return "N/A";
    }
  };

  const getTrendIcon = (trend, swapArrow = false) => {
    if (swapArrow) {
      if (trend > 0) {
        return <ArrowDropDownIcon className={classes.arrowPositive} />;
      } else if (trend < 0) {
        return <ArrowDropUpIcon className={classes.arrowNegative} />;
      } else {
        return <RemoveIcon className={classes.dashNeutral} />;
      }
    } else {
      if (trend > 0) {
        return <ArrowDropUpIcon className={classes.arrowPositive} />;
      } else if (trend < 0) {
        return <ArrowDropDownIcon className={classes.arrowNegative} />;
      } else {
        return <RemoveIcon className={classes.dashNeutral} />;
      }
    }
  };

  const handleCloseDatePicker = () => {
    setDatePickerAnchor(null);
  };

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    ReactGA.event({
      category: "Team Dashboard",
      action: "User adjusted date range",
    });
  };

  const handleViewMemberDashboard = (id) => {
    setDashboardMemberId(id);
    ReactGA.event({
      category: "Team Dashboard",
      action: "User clicked into a member's dashboard",
    });
  };

  const handleCloseMemberDashboard = () => {
    setDashboardMemberId(null);
  };

  const handleMouseEnterCell = () => {
    hoverStart = new Date();
  };

  const handleMouseLeaveCell = (column) => {
    const timeSpent = new Date() - hoverStart;
    ReactGA.event({
      category: "Team Dashboard",
      action: `User hovered table column: ${column}`,
      value: timeSpent,
    });
  };

  return (
    <DashboardLayout title="Team Dashboard">
      {dashboardMemberId ? (
        <MemberDashboard
          id={dashboardMemberId}
          members={members}
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
          handleBack={handleCloseMemberDashboard}
          handleChangeMember={handleViewMemberDashboard}
          history={history}
        />
      ) : (
        <div className={classes.container}>
          <header className={classes.banner}>
            <Typography className={classes.bannerHeavyText}>
              <span>{orgName}</span> Dashboard
            </Typography>
            <Typography className={classes.bannerLightText}>
              See what your team is up to these past few days!
            </Typography>
            <img
              src="/sonero-static/images/meeting_illustration.png"
              alt=""
              className={classes.bannerImage}
            />
          </header>
          <Typography className={classes.dateFilter}>
            Meetings {getDateRangeString(startDate, endDate, dateFormat)}
            <IconButton
              onClick={(event) => setDatePickerAnchor(event.currentTarget)}
            >
              <EventNoteIcon color="primary" />
            </IconButton>
            {error && (
              <Typography variant="h6" color="error">
                {error}
              </Typography>
            )}
          </Typography>
          <DatePicker
            anchor={datePickerAnchor}
            startDate={startDate}
            endDate={endDate}
            handleCloseDatePicker={handleCloseDatePicker}
            handleDateChange={handleDateChange}
          />
          <Box className={classes.statContainer}>
            <Tooltip title={'"Scheduled Meeting" is calculated off of the meetings that Sonero will join based on your settings.'}>
              <Box className={classes.stat}>
                <Typography className={classes.statCellHeader}>
                  Scheduled Meetings
                </Typography>
                <Typography className={classes.statCell}>
                  {teamOrgData.totalNumScheduledMeetings}{" "}
                  {getTrendIcon(teamOrgData.scheduledMeetingsTrend)}
                </Typography>
              </Box>
            </Tooltip>
            <Box className={classes.stat}>
              <Typography className={classes.statCellHeader}>
                Completed Meetings
              </Typography>
              <Typography className={classes.statCell}>
                {teamOrgData.totalNumCompletedMeetings}{" "}
                {getTrendIcon(teamOrgData.completedMeetingsTrend)}
              </Typography>
            </Box>
            <Box className={classes.stat}>
              <Typography className={classes.statCellHeader}>
                w/o Action Items
              </Typography>
              <Typography className={classes.statCell}>
                {teamOrgData.totalNumMeetingsWithoutActionItems}{" "}
                {getTrendIcon(
                  teamOrgData.meetingsWithoutActionItemsTrend,
                  true
                )}
              </Typography>
            </Box>
            <Box className={classes.stat}>
              <Typography className={classes.statCellHeader}>
                Meeting Score
              </Typography>
              <Typography className={classes.statCell}>
                {teamOrgData.avgMeetingScore !== undefined &&
                teamOrgData.avgMeetingScore !== null
                  ? Math.round(teamOrgData.avgMeetingScore) + "/10"
                  : "N/A"}{" "}
                {getTrendIcon(teamOrgData.meetingScoreTrend)}
              </Typography>
            </Box>
            <Box className={classes.stat}>
              <Typography className={classes.statCellHeader}>
                Sentiment
              </Typography>
              <Typography className={classes.statCell}>
                {getSentimentLabel(teamOrgData.overallSentiment)}{" "}
                {getTrendIcon(teamOrgData.sentimentTrend)}
              </Typography>
            </Box>
          </Box>
          <div className={classes.table}>
            {!isLoading && (
              <div className={classes.content}>
                <div className={classes.row}>
                  <Typography className={classes.cell + " header"}>
                    Member
                  </Typography>
                  <Tooltip title={'"Scheduled Meeting" is calculated off of the meetings that Sonero will join based on your settings.'} placement="top">
                    <Typography className={classes.cell + " header"}>
                      Completed
                    </Typography>
                  </Tooltip>
                  <Typography className={classes.cell + " header"}>
                    w/o Action Items
                  </Typography>
                  <Typography className={classes.cell + " header"}>
                    Meeting Score
                  </Typography>
                  <Typography className={classes.cell + " header"}>
                    Sentiment
                  </Typography>
                  <Typography className={classes.cell + " header"}>
                    Talk : Listen Ratio
                  </Typography>
                </div>
                {teamData.map((data) => (
                  <div
                    className={classes.row + " selectable"}
                    onClick={() =>
                      handleViewMemberDashboard(data.id, data.name)
                    }
                  >
                    <Typography
                      className={classes.cell}
                      onMouseEnter={handleMouseEnterCell}
                      onMouseLeave={() => handleMouseLeaveCell("Member")}
                    >
                      {data.name}
                    </Typography>
                    <Typography
                      className={classes.cell}
                      onMouseEnter={handleMouseEnterCell}
                      onMouseLeave={() => handleMouseLeaveCell("Completed")}
                    >
                      <div>
                        {data.numCompleted}{" "}
                        <span className={classes.secondaryText}>
                          / {data.numCompleted + data.numUpcoming}
                        </span>
                      </div>
                    </Typography>
                    <Typography
                      className={classes.cell}
                      onMouseEnter={handleMouseEnterCell}
                      onMouseLeave={() =>
                        handleMouseLeaveCell("w/o Action Items")
                      }
                    >
                      {data.numWithoutActionItems}
                    </Typography>
                    <Typography
                      className={classes.cell}
                      onMouseEnter={handleMouseEnterCell}
                      onMouseLeave={() => handleMouseLeaveCell("Meeting Score")}
                    >
                      {data.meetingScore != null
                        ? `${Math.round(data.meetingScore)}/10`
                        : "N/A"}
                      {getTrendIcon(data.meetingScoreTrend)}
                    </Typography>
                    <Typography
                      className={classes.cell}
                      onMouseEnter={handleMouseEnterCell}
                      onMouseLeave={() => handleMouseLeaveCell("Sentiment")}
                    >
                      {getSentimentLabel(data.sentiment)}
                      {getTrendIcon(data.sentimentTrend)}
                    </Typography>
                    <Typography
                      className={classes.cell}
                      onMouseEnter={handleMouseEnterCell}
                      onMouseLeave={() =>
                        handleMouseLeaveCell("Talk Listen Rato")
                      }
                    >
                      {Math.round(data.talkPercentage)} :{" "}
                      {Math.round(data.listenPercentage)}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
            {isLoading && (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default withStyles(styles)(TeamDashboard);
