export default (theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "63px",
    flexShrink: 0,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 3,
  },
  logoName: {
    color: theme.palette.text.main,
  },
  signUpLink: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  content: {
    margin: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing.unit * 4,
  },
  clipInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing.unit * 4,
  },
  clipComment: {
    width: "100%",
    justifyContent: "center",
    padding: "0 10% 0",
  },
  videoContainer: {
    width: `calc(50% - ${theme.spacing.unit * 2}px)`,
  },
  videoPlayer: {
    width: "100%",
    height: "336px",
  },
  clipTranscriptContainer: {
    width: `calc(50% - ${theme.spacing.unit * 2}px)`,
    overflow: "hidden",
    height: "336px",
  },
  clipTranscriptHeader: {
    display: "none",
    background: theme.palette.background.grey,
    padding: theme.spacing.unit * 2,
  },
  clipTranscriptSnippet: {
    overflowY: "scroll",
    height: "100%",
  },
  snippetSpeaker: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    "&:first-child": {
      marginTop: 0,
    },
  },
  snippetBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    "& > p": {
      marginBottom: theme.spacing.unit,
      lineHeight: "1.5",
      cursor: "pointer",
    },
  },
  commentSectionContainer: {
    width: "100%",
    overflow: "hidden",
    height: "336px",
  },
  commentSectionHeader: {
    display: "none",
    background: theme.palette.background.grey,
    padding: theme.spacing.unit * 2,
  },
  commentSectionSnippet: {
    overflowY: "scroll",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit * 3,
  },
  commentSectionCommentContainer: {
    width: "100%",
  },
  commentSectionCommentHeader: {
    width: "100%",
    display: "flex",
    gap: theme.spacing.unit * 2,
    margin: `0 0 ${theme.spacing.unit * 0.5}px`,
  },
  commentSectionCommentName: {
    fontWeight: 700,
    color: theme.palette.text.darkGrey,
  },
  commentSectionCommentTimestamp: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  commentSectionCommentEditedStatus: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  },
  commentSectionCommentContext: {
    width: "100%",
  },
  errorText: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 2,
  },
  "@media (max-width: 900px)": {
    videoContainer: {
      width: "100%",
      margin: "auto",
    },
    clipTranscriptContainer: {
      width: "100%",
      margin: "auto",
      borderRadius: "7px",
      border: `1px solid ${theme.palette.border}`,
    },
    clipTranscriptHeader: {
      display: "flex",
    },
    clipTranscriptSnippet: {
      padding: theme.spacing.unit * 2,
    },
    commentSectionContainer: {
      width: "100%",
      margin: "auto",
      borderRadius: "7px",
      border: `1px solid ${theme.palette.border}`,
    },
    commentSectionHeader: {
      display: "flex",
    },
    commentSectionSnippet: {
      padding: theme.spacing.unit * 2,
    },
    clipInfo: {
      flexDirection: "column",
      width: "max(80%, 300px)",
      margin: "auto",
    },
    clipComment: {
      width: "max(80%, 300px)",
      margin: "auto",
      padding: "0px",
    },
  },
});
