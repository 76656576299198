export default (theme) => ({
  root: { position: "relative", height: "100%" },
  container: {
    height: "100%",
  },
  header: {
    padding: theme.spacing.unit * 2,
    paddingRight: theme.spacing(0.625),
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.elements.currentInsight.lightGrey,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "53px",
  },
  headerText: {
    fontSize: "16px",
    color: theme.palette.text.grey,
    fontWeight: 700,
  },
  downloadIcon: {
    color: theme.palette.elements.currentInsight.blue,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  tab: {
    color: theme.palette.elements.currentInsight.darkGrey,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  phrase: {
    color: theme.palette.text.secondary,
    margin: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
  },
});
