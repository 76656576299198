import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography, Button, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import { isUserOrgOwnerOrAdmin } from "helpers/user";
import { verifyUserPricingTier, pricingTiers } from "helpers/pricingTiers";

import ReactGA from "react-ga";

class Hubspot extends Component {
  state = {
    connectedMsg: "",
    isConnected: false,
  };

  componentDidMount() {
    if (this.props.isConnected === true) {
      this.setState({ connectedMsg: "Status: Connected", isConnected: true });
    } else {
      this.setState({
        connectedMsg: "Status: Not Connected",
        isConnected: false,
      });
    }
  }

  handleSubmit = () => {
    const isFreeTier = verifyUserPricingTier(pricingTiers.free);
    if (isFreeTier) {
      this.props.handleOpenUpgradeDialog();
      return;
    }

    if (this.state.isConnected === false) {
      ReactGA.event({
        category: "Hubspot",
        action: "User clicked Connect button",
      });
      this.props.authorize();
    }
  };

  handleDisconnect = () => {
    if (this.state.isConnected) {
      ReactGA.event({
        category: "Hubspot",
        action: "User clicked Disconnect button",
      });
      this.props.disconnect();
    }
  };

  renderFooter = () => {
    const { classes } = this.props;
    const { isConnected } = this.state;
    const isOrgOwnerOrAdmin = isUserOrgOwnerOrAdmin();
    return (
      <PortletFooter className={classes.portletFooter}>
        {!isOrgOwnerOrAdmin && (
          <Tooltip title="Only your organization owner or admins can integrate with Hubspot">
            <HelpIcon className={classes.helpIcon} />
          </Tooltip>
        )}
        <Button
          className={classes.submitButton}
          color="primary"
          size="small"
          variant="text"
          onClick={isConnected ? this.handleDisconnect : this.handleSubmit}
          disabled={!isOrgOwnerOrAdmin}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </Button>
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Hubspot" />
        </PortletHeader>
        <div style={{ position: "relative" }}>
          <PortletContent className={classes.content}>
            <div className={classes.instructions}>
              <Typography className={classes.caption} variant="body1">
                Integrate with Hubspot to get Sonero's insights into your CRM or
                project management tools.
              </Typography>
            </div>
            <div className={classes.instructions}>
              <Typography className={classes.status} variant="h5">
                {this.state.connectedMsg}
              </Typography>
            </div>
          </PortletContent>
          {this.renderFooter()}
        </div>
      </Portlet>
    );
  }
}

Hubspot.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hubspot);
