export default (theme) => ({
  proTipAndFeedbackContainer: {
    position: "relative",
  },
  actionItemTip: {
    background: theme.palette.background.lightGrey,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.border}`,
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  tipIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.primary.yellow,
  },
  tipText: {
    width: "90%",
    fontWeight: 350,
  },
  buttonTogglePopper: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "350",
    color: theme.palette.primary.main,
    background: theme.palette.background.lightGrey,
    border: "none",
    "&:hover": {
      background: theme.palette.common.white,
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  fade: {
    zIndex: 2,
    position: "absolute",
    left: 0,
    right: 0,
    display: "flex",
    marginTop: "-20px",
    marginLeft: "auto",
    marginRight: 0,
  },
  indicatorProTip: {
    gap: "8px",
    border: `1px solid ${theme.palette.background.purple}`,
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
    zIndex: 2,
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "35%",
    minWidth: "230px",
    background: theme.palette.background.purple,
    borderRadius: "10px",
    gridArea: "main",
    justifySelf: "center",
  },
  indicatorText: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "350",
  },
  indicatorHeading: {
    textAlign: "center",
    fontFamily: "Roboto",
    paddingBottom: theme.spacing.unit * 1.5,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  tipIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.primary.yellow,
  },
  upArrow: {
    zIndex: 2,
    position: "relative",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: `8px solid ${theme.palette.background.purple}`,
    justifySelf: "center",
    gridArea: "header",
  },
  indicatiorProTipIcon: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.background.grey,
    },
  },
  thumbs: {
    paddingLeft: theme.spacing.unit * 0.5,
  },
});
