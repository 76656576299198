import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Externals
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from "validate.js";
import _ from "underscore";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
  MenuItem,
  Divider,
} from "@material-ui/core";

// Shared utilities
import validators from "common/validators";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";

// Shared services
import { signup, login } from "services/speech";

import ReactGA from "react-ga";

import queryString from "query-string";

validate.validators.checked = validators.checked;

class SignUp extends Component {
  signUpSource = "";
  copyMeeting = null;
  participantId = null;
  toBilling = null;
  sharedUuid = null;
  sharedPassword = null;
  sharedTitle = null;
  sharedOwner = null;

  state = {
    values: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      policy: false,
    },
    touched: {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      policy: null,
    },
    errors: {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      policy: null,
    },
    isValid: false,
    isLoading: false,
    submitError: null,
    isSignUpSuccess: false,
    emailVerified: false,
    sharedTitle: "",
    sharedOwner: "",
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let signUpSource = params.ref;
    if (signUpSource) {
      this.signUpSource = signUpSource;
    }
    let copyMeeting = params.copy;
    if (copyMeeting != null) {
      this.copyMeeting = copyMeeting;
    }
    let participantId = params.pid;
    if (participantId != null) {
      this.participantId = participantId;
    }
    let toBilling = params.billing;
    if (toBilling != null) {
      this.toBilling = toBilling;
    }
    let sharedUuid = params.sharedUuid;
    if (sharedUuid != null) {
      this.sharedUuid = sharedUuid;
    }
    let sharedPassword = params.sharedPassword;
    if (sharedPassword != null) {
      this.sharedPassword = sharedPassword;
    }
    let sharedTitle = params.sharedTitle;
    let sharedOwner = params.sharedOwner;
    if (sharedTitle != null && sharedOwner != null) {
      this.setState({ sharedTitle, sharedOwner });
    }
  }

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleCopyMeetingSignIn = () => {
    const { values } = this.state;
    login(values.email, values.password).then((response) => {
      if (response.status === 200) {
        this.props.history.push(`/setup?copy=${this.copyMeeting}`);
      } else {
        // Failed to login, display sign up successful and email verified message
        this.setSignedUpState(true);
      }
    });
  };

  handleSharedViewSignIn = () => {
    const { values } = this.state;
    login(values.email, values.password).then((response) => {
      if (response.status === 200) {
        this.props.history.push(
          `view-meeting/share/${this.sharedUuid}?password=${this
            .sharedPassword ?? ""}`
        );
      } else {
        // Failed to login, display sign up successful message
        this.setSignedUpState(false);
      }
    });
  };

  handleNavigateToSignIn = () => {
    if (this.sharedUuid) {
      this.props.history.push(
        `/sign-in?redirect=/view-meeting/share/${this.sharedUuid}`
      );
    } else {
      this.props.history.push("/sign-in");
    }
  };

  setSignedUpState = (emailVerified) => {
    this.setState({
      values: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        policy: false,
      },
      touched: {
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        policy: null,
      },
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        policy: null,
      },
      isValid: false,
      isLoading: false,
      submitError: null,
      isSignUpSuccess: true,
      emailVerified: emailVerified,
    });
  };

  handleSignUp = () => {
    const { values } = this.state;

    this.setState({ isLoading: true });

    signup(
      values.email,
      values.password,
      values.firstName,
      values.lastName,
      this.signUpSource,
      this.participantId,
      this.sharedUuid
    ).then(
      function(response) {
        if (response.status === 201) {
          ReactGA.event({
            category: "User",
            action: "Create account API success",
          });
          const emailVerified = Boolean(response.data.verified_email);
          if (this.copyMeeting && emailVerified) {
            this.handleCopyMeetingSignIn();
            return;
          }
          if (this.sharedUuid) {
            this.handleSharedViewSignIn();
            return;
          }
          this.setSignedUpState(emailVerified);
        } else {
          const data = response.data;
          if (data != null) {
            const emailErrors = data.email;
            if (emailErrors != null) {
              if (
                emailErrors.includes(
                  "A user is already registered with this e-mail address."
                )
              ) {
                this.setState({
                  isLoading: false,
                  submitError:
                    "Sorry, unable to create account because a user is already registered with this e-mail address",
                });
                ReactGA.event({
                  category: "User",
                  action: "Create account failed because user exists",
                });
                return;
              } else if (emailErrors.includes("Restricted domain")) {
                this.setState({
                  isLoading: false,
                  submitError:
                    "Please use your work email to create account. We do not accept personal emails at this time.",
                });
                ReactGA.event({
                  category: "User",
                  action:
                    "Create account failed because email domain is restricted",
                });
                return;
              }
            }

            const passwordErrors = data.password1;
            if (passwordErrors != null) {
              if (
                passwordErrors.includes(
                  "This password is too short. It must contain at least 8 characters."
                )
              ) {
                this.setState({
                  isLoading: false,
                  submitError:
                    "Sorry, the password is too short. It must contain at least 8 characters.",
                });
                ReactGA.event({
                  category: "User",
                  action: "Create account failed because password is too short",
                });
                return;
              }
            }

            this.setState({
              isLoading: false,
              submitError: "Sorry, unable to create account due to an error",
            });
            ReactGA.event({
              category: "Failure",
              action: "Create account API failed",
            });
          }
        }
      }.bind(this)
    );
  };

  render() {
    const { classes } = this.props;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading,
      isSignUpSuccess,
      emailVerified,
      sharedTitle,
      sharedOwner,
    } = this.state;

    if (isSignUpSuccess === true) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <div className={classes.logoWrapper}>
                  <Link className={classes.logoLink} to="/">
                    <img
                      alt="Sonero logo"
                      height="100px"
                      className={classes.logoImage}
                      src="/sonero-static/images/logo_with_name.png"
                    />
                  </Link>
                </div>
                <Divider className={classes.logoDivider} />
                <Typography className={classes.title} variant="h4">
                  Thank you for creating your account!{" "}
                  {emailVerified ? (
                    <span>
                      We've verified your email. Log in to get started.
                    </span>
                  ) : (
                    <span>
                      A verification email has been sent to your email address.
                    </span>
                  )}
                </Typography>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <div className={classes.logoWrapper}>
                  <a
                    href="https://sonero.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Sonero logo"
                      height="100px"
                      className={classes.logoImage}
                      src="/sonero-static/images/logo_with_name.png"
                    />
                  </a>
                </div>
                <Divider className={classes.logoDivider} />
                {sharedTitle && sharedOwner && (
                  <Typography variant="body1" className={classes.shareHint}>
                    To view the output for <span>{sharedTitle}</span> shared by{" "}
                    <span>{sharedOwner}</span>, you'll need a Sonero account!
                    Sign up below.
                  </Typography>
                )}
                <Typography className={classes.title} variant="h2">
                  Sign up
                </Typography>
                <Typography className={classes.subtitle} variant="h6">
                  Use your work email to create new account...
                </Typography>
                <div className={classes.fields}>
                  <TextField
                    className={classes.textField}
                    required
                    label="First name"
                    name="firstName"
                    onChange={(event) =>
                      this.handleFieldChange("firstName", event.target.value)
                    }
                    value={values.firstName}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    required
                    label="Last name"
                    onChange={(event) =>
                      this.handleFieldChange("lastName", event.target.value)
                    }
                    value={values.lastName}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    required
                    label="Email address"
                    name="email"
                    onChange={(event) =>
                      this.handleFieldChange("email", event.target.value)
                    }
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    required
                    label="Password"
                    onChange={(event) =>
                      this.handleFieldChange("password", event.target.value)
                    }
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <div className={classes.policy}>
                    <Checkbox
                      checked={values.policy}
                      className={classes.policyCheckbox}
                      color="primary"
                      name="policy"
                      onChange={() =>
                        this.handleFieldChange("policy", !values.policy)
                      }
                    />
                    <Typography className={classes.policyText} variant="body1">
                      I have read the&nbsp;
                      <a
                        className={classes.policyUrl}
                        href="https://sonero.ai/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions&nbsp;
                      </a>
                      and&nbsp;
                      <a
                        className={classes.policyUrl}
                        href="https://www.mediafly.com/legal/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  </div>
                </div>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
                {isLoading ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  <Button
                    className={classes.signUpButton}
                    color="primary"
                    disabled={!isValid}
                    onClick={this.handleSignUp}
                    size="large"
                    variant="contained"
                  >
                    {sharedTitle && sharedOwner
                      ? "Sign Up to View Meeting"
                      : "Sign up now"}
                  </Button>
                )}
                <Typography className={classes.signIn} variant="body1">
                  Have an account?{" "}
                  <div
                    className={classes.signInUrl}
                    onClick={this.handleNavigateToSignIn}
                  >
                    Sign In
                  </div>
                </Typography>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

SignUp.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(SignUp);
