import { fade } from "@material-ui/core";
export default (theme) => ({
  elementTipsAndShare: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.lightGrey,
    padding: theme.spacing(2),
  },
  elementTips: {
    display: "flex",
    gap: theme.spacing(1),
  },
  elementInfoIcon: {
    color: fade(theme.palette.text.secondary, 0.5),
  },
  elementTipsText: {
    color: theme.palette.text.lightGrey,
  },
  elementMainContent: {
    maxHeight: "400px",
    overflow: "auto",
  },
  emptyText: {
    display: "block",
    alignItems: "center",
    padding: theme.spacing(6.75),
  },
  phrase: {
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "center",
  },
  elementOwner: {
    display: "flex",
    justifyContent: "space-between",
  },
  elementText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  elementSection: {
    borderTop: `1px solid ${fade(theme.palette.border, 0.5)}`,
    borderBottom: `1px solid ${fade(theme.palette.border, 0.5)}`,
  },
  elementContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  elementInstance: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  elementInfo: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "flex-start",
  },
  elementTimestamp: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    width: "40px",
    textAlign: "right",
  },
  elementSentence: {
    width: "100%",
  },
  elementButtons: {
    display: "flex",
  },
  deleteIcon: {
    color: theme.palette.danger.mellow,
  },
  scrollTip: {
    width: "100%",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    textAlign: "center",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    borderTop: `1px solid ${theme.palette.border}`,
  },
  textAreaInset: {
    display: "flex",
    paddingLeft: theme.spacing.unit * 2,
    width: "calc(100% - 60px)",
    alignItems: "center",
  },
  elementEditingTask: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    width: "100%",
    height: "auto",
    resize: "none",
    padding: theme.spacing.unit,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
});