import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Chip } from '@material-ui/core';

// Material icons
import {
    SentimentSatisfiedAlt as SentimentSatisfiedAltIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class ContentPositiveWords extends Component {
  state = {
    result: "",
    caption: "",
    valueClassName: ""
  }

  componentWillMount() {
    this.setState({
        caption: `These are some of the positive words that you used in your recording. They are great words to use when communicating with a positive outlook! Keep up the great work!`
    })
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h6"
            >
              POSITIVE WORDS
            </Typography>
            <Typography
              className={this.state.valueClassName}
              variant="h4"
            >
              {this.state.result}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <SentimentSatisfiedAltIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.chipsWrapper}>
            {this.props.data.sentiment_positive_tokens.map((value, index) => {
                return <Chip key={index} label={value} className={classes.chips} />
            })}
        </div>
        <div className={classes.footer}>
          <Typography
            className={classes.caption}
            variant="body1"
          >
            {this.state.caption}
          </Typography>
        </div>
      </Paper>
    );
  }
}

ContentPositiveWords.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContentPositiveWords);
