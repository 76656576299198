import {
  IconButton,
  Link,
  TextareaAutosize,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Delete,
  Edit as EditIcon,
} from "@material-ui/icons";
import { getSpeakerString } from "helpers/meetingParticipants";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import styles from "./styles";

class TopQuestionsSegment extends Component {
  state = {
    questionSegment: null,
    answerSegment: null,
    modifiedSentence: null,
    hoveredSentence: null,
    editSentence: null,
  };
  timeSpentHover = new Date();

  componentWillMount() {
    this.setState({
      questionSegment: this.props.question.phrase,
      answerSegment: this.props.question.answer,
      modifiedSentence: {
        sentence: this.props.question.phrase,
        answer: this.props.question.answer,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionSegment !== this.props.questionSegment) {
      this.setState({ questionSegment: this.props.questionSegment });
    }
    if (prevProps.answerSegment !== this.props.answerSegment) {
      this.setState({ answerSegment: this.props.answerSegment });
    }
  }

  handleTranscriptSentenceClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Q & A",
    });
  };

  getSentenceWithTimestamp = (result, timeStamp) => {
    const { classes, className, ...rest } = this.props;

    let sentence = this.props.question.phrase;
    return (
      <div className={classes.sentencebox}>
        <li key={sentence} className={classes.list}>
          {getSpeakerString(
            this.props.question.phrase_speaker_tag,
            this.props.speakers,
            true
          )}
          : "{sentence}"
        </li>
        {this.props.setTimestampLink(timeStamp)}
        <div className={classes.editIcons}>
          {this.props.data.is_participant_editing_enabled &&
            // Uncomment below lines if we want to include icons appearing on hover
            // (this.state.hoveredSentence === sentence ||
            //   this.state.hoveredSentence === result.answer) &&
            this.state.editSentence === null && (
              <>
                {this.props.questionAndAnswerUpdateHandler && (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    className={classes.transcriptSentenceActionButton}
                    onClick={() => this.handleSentenceEdit(result)}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                )}
                {this.props.handleDeleteIcon && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.handleDeleteQuestionandAnswer();
                    }}
                  >
                    <Delete htmlColor="red" />
                  </IconButton>
                )}
              </>
            )}
        </div>
      </div>
    );
  };

  handleOnMouseEnterKeyInsight = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeaveKeyInsight = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Hovered Key Insights",
      value: timeSpent,
    });
  };

  addAnswer = (question) => {
    if (question.answer == null) {
      return;
    }
    var splitAnswer = null;
    if (question.specific_answer) {
      let indexStart = question.answer.indexOf(question.specific_answer);
      if (indexStart >= 0) {
        splitAnswer = [
          question.answer.slice(0, indexStart),
          question.answer.slice(
            indexStart,
            indexStart + question.specific_answer.length
          ),
          question.answer.slice(indexStart + question.specific_answer.length),
        ];
      }
    }
    const { classes, className, ...rest } = this.props;
    var speakerName = "";
    if (question.answer_speaker_tag) {
      speakerName =
        getSpeakerString(question.answer_speaker_tag, this.props.speakers) +
        ": ";
    }
    if (splitAnswer) {
      return (
        <Typography className={classes.answer} variant="body1">
          {speakerName}"{splitAnswer[0]}
          <Tooltip
            onMouseEnter={this.handleOnMouseEnterKeyInsight}
            onMouseOut={() => this.handleOnMouseLeaveKeyInsight()}
            title="Insight: Direct Answer"
          >
            <span className={classes.specificAnswer}>{splitAnswer[1]}</span>
          </Tooltip>
          {splitAnswer[2]}"
        </Typography>
      );
    }
    return (
      <Typography className={classes.answer} variant="body1">
        {speakerName}"{question.answer}"
      </Typography>
    );
  };

  getEditSentence = (result) => {
    const { classes, className, ...rest } = this.props;

    return (
      <div>
        <TextareaAutosize
          className={classes.editSentence}
          defaultValue={this.props.question.phrase}
          onChange={(event) => {
            this.handleSentenceOnChange(event, result);
          }}
        />
        <TextareaAutosize
          className={classes.editSentence}
          defaultValue={this.props.question.answer}
          onChange={(event) => {
            this.handleAnswerOnChange(event, result);
          }}
        />
        <div className={classes.changeIcons}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => this.handleSentenceEditComplete()}
          >
            <CheckCircleIcon color="primary" />
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => this.handleSentenceEditCancel()}
          >
            <CancelIcon color="primary" />
          </IconButton>
        </div>
      </div>
    );
  };

  handleSentenceEdit = (sentence) => {
    this.setState({
      editSentence: sentence,
    });
  };

  handleSentenceMouseEnter = (sentence) => {
    this.setState({
      hoveredSentence: sentence,
    });
  };

  handleSentenceMouseLeave = () => {
    this.setState({
      hoveredSentence: null,
    });
  };

  handleSentenceEditCancel = () => {
    this.setState({
      editSentence: null,
      modifiedSentence: null,
    });
  };

  handleSentenceEditComplete = () => {
    if (this.state.modifiedSentence) {
      if (this.state.modifiedSentence.sentence === "") {
        let timeStamp = this.state.modifiedSentence.timestamp;
        this.props.questionAndAnswerDeletehandler(timeStamp);
      }
      let filteredResults = this.props.questionAndAnswerUpdateHandler(
        this.state.modifiedSentence
      );

      let valid = false;
      /* Check if filteredResults is empty */
      if (filteredResults) {
        let currTimeStamp = this.state.modifiedSentence.timestamp;

        let updatedObj = filteredResults.filter(function(obj) {
          if (obj.timestamp === currTimeStamp) {
            return obj;
          }
        });

        //See if object still exists
        if (updatedObj.length) {
          this.setState({
            editSentence: null,
            questionSegment: this.state.modifiedSentence.sentence
              ? updatedObj[0].phrase
              : this.props.question.phrase,
            answerSegment: this.state.modifiedSentence.answer
              ? updatedObj[0]
              : this.props.question.answer,
            modifiedSentence: null,
          });
          valid = true;
        }
      }

      if (!valid) {
        this.setState({
          editSentence: null,
          questionSegment: this.state.modifiedSentence.sentence
            ? this.state.modifiedSentence.sentence
            : this.props.question.phrase,
          answerSegment: this.state.modifiedSentence
            ? this.state.modifiedSentence.answer
            : this.props.question.answer,
          modifiedSentence: null,
        });
      }
    } else {
      this.handleSentenceEditCancel();
    }
  };

  handleSentenceOnChange = (event, modifiedSentence) => {
    this.setState({
      modifiedSentence: {
        ...this.state.modifiedSentence,
        speaker_tag: modifiedSentence.phrase_speaker_tag,
        timestamp: modifiedSentence.timestamp,
        sentence: event.target.value,
      },
    });
  };

  handleAnswerOnChange = (event, modifiedSentence) => {
    this.setState({
      modifiedSentence: {
        ...this.state.modifiedSentence,
        speaker_tag: modifiedSentence.phrase_speaker_tag,
        timestamp: modifiedSentence.timestamp,
        answer: event.target.value,
      },
    });
  };

  handleDeleteQuestionandAnswer = () => {
    let timeStamp = this.props.question.timestamp;
    let filteredResults = this.props.questionAndAnswerDeletehandler(timeStamp);
    this.props.updateAllQuestionAndAnswer(filteredResults[0].questions);
  };

  render() {
    const { classes, className, ...rest } = this.props;

    if (this.state.editSentence) {
      return (
        <div>
          <Typography className={classes.edit} variant="body1">
            <li className={classes.list}>
              {getSpeakerString(
                this.props.question.phrase_speaker_tag,
                this.props.speakers,
                true
              )}
            </li>
          </Typography>
          {this.getEditSentence(this.props.question)}
        </div>
      );
    } else {
      return (
        <div
          className={classes.transcriptSpeakerSegmentContainer}
          onMouseEnter={() =>
            this.handleSentenceMouseEnter(this.state.questionSegment)
          }
          onMouseLeave={() => this.handleSentenceMouseLeave()}
          style={this.props.style}
        >
          <Typography className={classes.phrase} variant="body1">
            {this.getSentenceWithTimestamp(
              this.props.question.phrase,
              this.props.question.timestamp
            )}
          </Typography>
          {this.addAnswer(this.props.question)}
        </div>
      );
    }
  }
}

TopQuestionsSegment.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopQuestionsSegment);
