import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";

import GroupIcon from "@material-ui/icons/Group";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { Dashboard as DashboardLayout } from "layouts";

import {
  MeetingInfo,
  ViewVideo,
  Agenda,
  ShareDialog,
  Overview,
  Analytics,
  DeleteMeetingDialog,
  RenameMeetingDialog,
  MeetingScore,
  MoveMeetingDialog,
  ViewClipModal,
  GetFeedbackDialog,
  FTUX,
} from "./components";

import { Portlet, PortletContent, CustomPictureInPicture } from "components";

import {
  getMeeting,
  updateMeetingFeedbackStatus,
  getMeetingFeedbackFromParticipants,
  deleteAgendaItem,
  createAgendaItem,
  updateAgendaItem,
  reorderAgendaItem,
  addActionItem,
  updateActionItem,
  deleteActionItem,
  completeActionItem,
  assignActionItemOwner,
  assignActionItemTarget,
  notifyActionItemOwners,
  updateDecisionNote,
  deleteDecisionNote,
  assignDecisionNoteOwner,
  getMeetings,
  createMeetingClip,
  deleteMeetingClip,
  deleteAllMeetingClips,
  updateMeetingClip,
  isSoneroUser,
  addTakeaway,
  deleteTakeaway,
  addMeetingClipComment,
  updateMeetingClipComment,
  deleteMeetingClipComment,
  readMeetingClipComment,
} from "services/speech";

import {
  speechStatus,
  meetingStatuses,
  meetingTypes,
  conversationHighlightTypes,
} from "helpers";
import { isUserOrgOwner } from "helpers/user";
import { reorder } from "helpers/draggableList";
import { showMeetingScore } from "helpers/meetingScore";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

import ReactGA from "react-ga";
import { getEmotionPrediction } from "helpers/emotions";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  },
  snackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
    maxWidth: "600px",
  },
  whiteSnackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
    backgroundColor: "white",
    color: "black",
  },
  deleteMeetingOption: {
    color: theme.palette.danger.main,
  },
  deleteMeetingButton: {
    color: theme.palette.danger.main,
  },
  deleteMeetingCaption: {
    color: theme.palette.text.primary,
  },
  renameMeetingTextField: {
    width: "350px",
  },
  speechStatusChip: {
    marginLeft: "16px",
    padding: "5px",
    "@media (max-width: 750px)": {
      display: "none",
    },
  },
  viewCounter: {
    display: "flex",
    gap: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
    alignItems: "center",
    "@media (max-width: 825px)": {
      display: "none",
    },
  },
  tooltipSection: {
    margin: "7px",
  },
  analyticsGridContainer: {
    marginTop: theme.spacing.unit * 2,
  },
  attendeesContainer: {
    position: "relative",
    display: "inline-block",
    marginLeft: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 0.5,
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "@media (max-width: 400px)": {
      display: "block",
    },
  },
  attendeesButton: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
    color: theme.palette.text.secondary,
    fontSize: "14px",
    cursor: "pointer",
    "& > span": {
      position: "relative",
      top: "4px",
    },
  },
  hiddenMeetingInfo: {
    width: "min(max(35vw, 350px), 500px)",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      "@media (max-width: 400px)": {
        display: "block",
        width: "25vw",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
});

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "0px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const getStatus = (meetingStatus) => {
  let status = meetingStatuses.filter(
    (element) => element.value === meetingStatus
  );
  if (status.length > 0) {
    return status[0].label;
  }
  return "";
};

class ViewMeeting extends Component {
  speechId = null;
  initWithTranscriptSearchValue = "";
  isMeetingOwner = true;
  tabPanelsRef = React.createRef();

  state = {
    results: {},
    constants: {},
    viewCount: 0,
    videoBlob: null,
    isLoading: false,
    error: null,
    speechStatus: null,
    tab: 0,
    meetingTitle: "",
    snackbarOpen: false,
    snackbarMessage: "",
    autoHideDuration: "6000",
    bookmarks: [],
    optionsMenuAnchorEl: null,
    openRenameMeetingDialog: false,
    isRenameMeetingLoading: false,
    newMeetingTitle: "",
    openDeleteMeetingDialog: false,
    isDeleteMeetingLoading: false,
    notes: [],
    isCustomPiPOpen: false,
    videoPlayer: null,
    whiteSnackbar: false,
    attendeesAnchor: null,
    openShareDialog: false,
    isOrgOwner: isUserOrgOwner(),
    hideOptions: false,
    meetingFolders: [],
    meetingFoldersLoading: false,
    openViewClipModal: false,
    viewClipModalIndex: -1,
    viewClipModalClips: [],
    openFeedbackDialog: false,
    scrollToQuestion: null,
    scrollToTracker: null,
    scrollToDecision: null,
    scrollToNote: null,
    scrollToActionItem: null,
    scrollToClip: null,
    scrollToWidget: null,
    trackerNotesRect: null,
  };

  tabIndex = {
    insights: 0,
    analytics: 1,
  };
  timeSpentOnTab = new Date();

  componentDidMount() {
    if (this.props.location.state) {
      this.keyTopic = this.props.location.state.keyTopic;
      this.speechId = this.props.location.state.id;
      this.initWithTranscriptSearchValue = this.props.location.state.searchValue;
      this.isMeetingOwner = this.props.location.state.isMeetingOwner;
    } else {
      this.speechId = this.props.match.params.uuid;
    }

    const query = new URLSearchParams(this.props.location.search);
    const fromWeeklyReport = query.get("weekly-report");
    const fromMeetingCompletedEmail = query.get("source");
    const tab = query.get("tab");
    const openShare = query.get("open-share");
    const scrollToQuestion = query.get("question");
    const scrollToTracker = query.get("tracker");
    const scrollToDecision = query.get("decision");
    const scrollToNote = query.get("note");
    const scrollToActionItem = query.get("action-item");
    const scrollToClip = query.get("clip");
    const scrollToWidget = query.get("widget");

    if (tab != null) {
      this.setState({ tab: parseInt(tab) });
    }
    if (openShare != null) {
      this.setState({ openShareDialog: true });
    }
    if (scrollToQuestion != null) {
      this.setState({ scrollToQuestion: scrollToQuestion });
    }
    if (scrollToTracker != null) {
      this.setState({ scrollToTracker: scrollToTracker });
    }
    if (scrollToDecision != null) {
      this.setState({ scrollToDecision: scrollToDecision });
    }
    if (scrollToNote != null) {
      this.setState({ scrollToNote: scrollToNote });
    }
    if (scrollToActionItem) {
      this.setState({ scrollToActionItem: scrollToActionItem });
    }
    if (scrollToClip) {
      this.setState({ scrollToClip: scrollToClip });
    }
    if (scrollToWidget) {
      this.setState({ scrollToWidget: scrollToWidget });
    }

    this.getSpeechFromRemote(fromWeeklyReport, fromMeetingCompletedEmail, tab);
    this.getMeetingFolders();
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.initWithTranscriptSearchValue = "";
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleSetScrollToClip = (clipUuid) => {
    const clips = this.state.results.clips;
    for (let i = 0; i < clips.length; i++) {
      if (clips[i].uuid === clipUuid) {
        this.handleOpenViewClipModal(i, clips);
        return;
      }
    }
  };

  setTrackerNotesRect = (rect) => {
    this.setState({
      trackerNotesRect: rect,
    });
  };

  getSpeechFromRemote = (fromWeeklyReport, fromMeetingCompletedEmail) => {
    this.setState({ isLoading: true });
    getMeeting(this.speechId, fromWeeklyReport, fromMeetingCompletedEmail).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return;
          }
          this.setError(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Get meeting API for view-meeting failed",
          });
          return;
        }
        var status = response.data.status;
        var data = response.data.data;

        if (data && data.transcript_results) {
          var transcriptionResultsMap = {};
          for (let i = 0; i < data.transcript_results.length; i++) {
            let transcriptResult = data.transcript_results[i];
            transcriptionResultsMap[transcriptResult.timestamp.toString()] = i;
          }
          data.transcriptionResultsMap = transcriptionResultsMap;
        }

        if (status === speechStatus.processing) {
          this.setState({
            isLoading: false,
            speechStatus: status,
            meetingTitle: response.data.data.title,
            results: data,
            viewCount: response.data.view_count,
          });
          return;
        }

        if (status === speechStatus.rejected) {
          this.setState({
            speechStatus: speechStatus.rejected,
            hideOptions: true,
            error:
              "This meeting was not processed because you exceeded the quota limits for your pricing tier. Please contact us if you'd like to upgrade or increase your quota limits.",
          });
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Get meeting API returned quota exceeded",
          });
          return;
        }

        if (status === speechStatus.completed) {
          this.speechId = data.meeting_id;
          this.isMeetingOwner =
            data.owner_email === localStorage.getItem("email");
          this.setState({
            speechStatus: speechStatus.completed,
            results: data,
            constants: response.data.constants,
            meetingTitle: data.title,
            bookmarks: data.bookmarks,
            notes: data.notes,
            viewCount: response.data.view_count,
          });
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Get meeting API success",
          });
        } else if (status === speechStatus.failed) {
          this.setState({
            speechStatus: speechStatus.failed,
            hideOptions: true,
            error:
              "Sorry, but processing failed because of an unknown reason. We apologize for the inconvenience.",
          });
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Get meeting API returned processing failed",
          });
        } else if (
          status === speechStatus.uninitialized ||
          status === speechStatus.started ||
          status === speechStatus.completed_agenda_only ||
          status === speechStatus.waiting
        ) {
          this.speechId = data.meeting_id;
          this.isMeetingOwner =
            data.owner_email === localStorage.getItem("email");
          this.setState({
            speechStatus: status,
            results: data,
            constants: response.data.constants,
            meetingTitle: data.title,
            notes: data.notes,
            viewCount: response.data.view_count,
          });
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Get scheduled meeting API success",
          });
        } else {
          this.setError(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Get meeting API returned unknown error",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  getMeetingFolders = () => {
    this.setState({ meetingFoldersLoading: true });
    getMeetings().then(
      function(response) {
        if (response.status === 200) {
          var meetings = response.data.data;
          let meetingFolders = meetings.filter(
            (meeting) => meeting.type === meetingTypes.folder
          );

          this.setState({
            meetingFolders: meetingFolders,
          });

          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Get my meetings API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            error:
              "Sorry, couldn't load your meetings because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get my meetings API failed",
          });
        }
        this.setState({ meetingFoldersLoading: false });
      }.bind(this)
    );
  };

  setMediaPlayerCurrentTimeAndPlay = (timestamp) => {
    if (this.viewVideo) {
      this.viewVideo.setMediaPlayerCurrentTimeAndPlay(timestamp);
    }
  };

  requestCustomPictureInPicture = () => {
    const videoPlayer = document.querySelector("#videoPlayer");
    this.setState({
      videoPlayer: videoPlayer,
      isCustomPiPOpen: true,
    });
  };

  exitCustomPictureInPicture = () => {
    this.setState({
      videoPlayer: null,
      isCustomPiPOpen: false,
    });
  };

  updateFeedbackStatus = (isEnabled) => {
    return updateMeetingFeedbackStatus(this.speechId, isEnabled).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Update meeting feedback status API failed",
          });
          return response;
        }
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update meeting feedback status API success",
        });
        return response;
      }.bind(this)
    );
  };

  getFeedbackFromParticipants = () => {
    this.openSnackbar("Sending emails requesting feedback from attendees...");
    return getMeetingFeedbackFromParticipants(this.speechId).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          } else if (response.status == 404) {
            this.openSnackbar(
              "An error occurred because attendee details are missing",
              false
            );
            return response;
          }
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Request meeting feedback from attendees API failed",
          });
          return response;
        }

        this.openSnackbar(
          "Emails sent requesting feedback from attendees. You can also enable the option to automatically request feedback for your meetings in the Settings page.",
          false
        );
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Request meeting feedback from attendees API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleBookmarkDelete = (bookmark) => {
    this.setState((prevState) => {
      var bookmarks = [...prevState.bookmarks];
      bookmarks.splice(bookmarks.indexOf(bookmark), 1);
      return { bookmarks: bookmarks };
    });
  };

  setError = (error) => {
    this.setState({ error: error });
  };

  handleNewBookmarkResponseData = (bookmark) => {
    this.setState((prevState) => {
      var bookmarks = prevState.bookmarks;
      const existingIndex = bookmarks.findIndex(
        (e) => e.uuid === bookmark.uuid
      );
      if (existingIndex >= 0) {
        bookmarks[existingIndex] = bookmark;
      } else {
        bookmarks.push(bookmark);
      }
      return { bookmarks: bookmarks };
    });
  };

  handleNewNoteResponseData = (note) => {
    var notes = this.state.notes;
    notes.push(note);
    this.setState({ notes: notes });
  };

  handleDeleteNoteResponseData = (uuid) => {
    var notes = this.state.notes;
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].uuid === uuid) {
        notes.splice(i, 1);
        this.setState({ notes: notes });
      }
    }
  };

  handleAddParticipantResponseData = (participant) => {
    var results = this.state.results;
    results.participants_details.push(participant);
    this.setState({ results: results });
    this.openSnackbar("Meeting attendee added");
  };

  handleDeleteParticipantResponseData = (participantId) => {
    var results = this.state.results;
    for (let i = 0; i < results.participants_details.length; i++) {
      if (results.participants_details[i].id === participantId) {
        results.participants_details.splice(i, 1);
      }
    }
    this.setState({ results: results });
    this.openSnackbar("Meeting attendee deleted");
  };

  handleUpdateParticipantResponseData = (participant) => {
    const results = this.state.results;
    for (let i = 0; i < results.participants_details.length; i++) {
      if (results.participants_details[i].id === participant.id) {
        results.participants_details[i].name = participant.name;
        results.participants_details[i].user_email = participant.user_email;
      }
    }
    this.setState({ results: results });
    this.openSnackbar("Meeting attendee updated");
  };

  handleMapParticipantResponseData = (participantId, tag) => {
    let results = this.state.results;
    let merge = false;
    let mergeSpeakerTag = -1;
    let mergeToSpeakerTag = -1;

    // Find assigned participant to determine if the speakers need to be merge or simply (un)assigning a participant to a speaker
    for (let i = 0; i < results.participants_details.length; i++) {
      if (results.participants_details[i].id === participantId) {
        let hasSpeakerTag =
          results.participants_details[i].speaker_tag !== null &&
          results.participants_details[i].speaker_tag !== -1;
        if ((!hasSpeakerTag && tag !== -1) || (hasSpeakerTag && tag === -1)) {
          results.participants_details[i].speaker_tag = tag;
        } else {
          merge = true;
          mergeSpeakerTag = tag;
          mergeToSpeakerTag = results.participants_details[i].speaker_tag;
        }
        break;
      }
    }

    // Operations for merge and assigning individual speaker
    if (merge === true) {
      for (let i = 0; i < results.transcript_results.length; i++) {
        if (results.transcript_results[i].speaker_tag === mergeSpeakerTag) {
          results.transcript_results[i].speaker_tag = mergeToSpeakerTag;
        }
      }
    }
    this.openSnackbar("Speaker updated");

    // Update transcript final results
    this.setState({ results: results });
  };

  handleTaskUpdateResponseData = (tasks) => {
    var results = this.state.results;
    results.tasks = tasks;
    this.setState({ results: results });
    this.openSnackbar("Next steps updated");
  };

  handleSummaryUpdateResponseData = (summary, isAgenda) => {
    var results = this.state.results;
    if (isAgenda) {
      results.agenda_summary = summary;
    } else {
      results.general_summary = summary;
    }
    this.setState({ results: results });
    this.openSnackbar("Recap updated");
  };

  handleTranscriptUpdateResponseData = (transcript_results) => {
    var results = this.state.results;
    results.transcript_results = transcript_results;
    this.setState({ results: results });
    this.openSnackbar("Transcript updated");
  };

  handleOpenCreateBookmarkFromTranscript = (startDuration, endDuration) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Create Bookmark Icon",
      label: "Transcript",
    });
    this.viewVideo.handleOpenCreateBookmarkFromTranscript(
      startDuration,
      endDuration
    );
  };

  handleQuestionUpdateResponseData = (questions) => {
    var results = this.state.results;
    results.questions = questions;
    this.setState({ results: results });
  };

  handleTranscriptUpdateFailure = () => {
    this.openSnackbar("An error occurred while updating the transcript");
  };

  handleQuestionandAnswerUpdateFailure = () => {
    this.openSnackbar(
      "An error occured while updating the questions and answers"
    );
  };

  openSnackbar = (message, autoHide = true, isWhite = false) => {
    if (autoHide) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: message,
        autoHideDuration: "6000",
        whiteSnackbar: isWhite,
      });
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: message,
        autoHideDuration: null,
        whiteSnackbar: isWhite,
      });
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  handleScroll = () => {
    if (this.viewVideo != null) {
      if (
        document.body.scrollTop > 350 ||
        document.documentElement.scrollTop > 350
      ) {
        this.viewVideo.enablePictureInPicture(true);
      } else {
        this.viewVideo.enablePictureInPicture(false);
      }
    }
  };

  handleOpenShareDialog = () => {
    this.setState({ openShareDialog: true });
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User opened quick share dialog",
    });
  };

  handleCloseShareDialog = () => {
    this.setState({ openShareDialog: false });
  };

  handlePrevTabLogTime = () => {
    let prevTabIndex = this.state.tab;
    let timeSpentOnPrevTab = new Date() - this.timeSpentOnTab; //Convert from milliseconds to seconds
    this.timeSpentOnTab = new Date();

    let prevTabName = Object.keys(this.tabIndex).find(
      (key) => this.tabIndex[key] === prevTabIndex
    );

    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "Time Spent On " + prevTabName + " tab",
      value: timeSpentOnPrevTab,
    });
  };

  handleTabChange = (newTabIndex, isGoToTabNavigationClicked = false) => {
    this.handlePrevTabLogTime();

    this.setState({ tab: newTabIndex });

    var eventAction = "User clicked AI Insights tab";

    if (newTabIndex === this.tabIndex.analytics) {
      eventAction = "User clicked Analytics tab";
    }

    if (!isGoToTabNavigationClicked) {
      ReactGA.event({
        category: gaCategoryViewMeeting(),
        action: eventAction,
      });
    }
  };

  handleCloseMoveMeetingDialog = () => {
    this.setState({ openMoveMeetingDialog: false });
  };

  handleOpenMoveMeetingDialog = () => {
    this.setState({ openMoveMeetingDialog: true });
    this.closeMenu();
  };

  closeMenu = () => {
    this.setState({ optionsMenuAnchorEl: null });
  };

  renderMoveMeetingDialog = () => {
    const { classes, className, ...rest } = this.props;
    if (!this.state.hideOptions && this.state.openMoveMeetingDialog) {
      return (
        <MoveMeetingDialog
          classes={classes}
          title={this.state.meetingTitle}
          open={this.state.openMoveMeetingDialog}
          meetingId={this.speechId}
          onClose={this.handleCloseMoveMeetingDialog}
          openSnackbar={this.openSnackbar}
          setLoading={this.setLoading}
          meetingFolders={this.state.meetingFolders}
        />
      );
    }
  };

  handleOpenRenameMeetingDialog = () => {
    this.setState({ openRenameMeetingDialog: true });
    this.closeMenu();
  };

  handleCloseRenameMeetingDialog = () => {
    this.setState({ openRenameMeetingDialog: false });
  };

  renderRenameMeetingDialog = () => {
    const { classes, className, ...rest } = this.props;
    if (!this.state.hideOptions) {
      return (
        <RenameMeetingDialog
          classes={classes}
          open={this.state.openRenameMeetingDialog}
          id={this.speechId}
          onClose={this.handleCloseRenameMeetingDialog}
          openSnackbar={this.openSnackbar}
          setLoading={this.setLoading}
          handleRenameMeeting={this.renameMeeting}
          meetingTitle={this.state.meetingTitle}
        />
      );
    }
  };

  renameMeeting = (title) => {
    this.setState({ meetingTitle: title });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  navigateToTab = (tabIndex) => {
    let eventAction = "";
    if (tabIndex === this.tabIndex.analytics) {
      eventAction = "User Clicked Go To Analytics";
    }

    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: eventAction,
      label: "Insights",
    });

    let isGoToTabNavigationClicked = true;
    this.handleTabChange(tabIndex, isGoToTabNavigationClicked);

    if (this.tabPanelsRef.current) {
      this.tabPanelsRef.current.scrollIntoView();
    }
  };

  // Agenda

  deleteAgendaItemFunction = (agendaItem) => {
    return deleteAgendaItem(agendaItem.uuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Delete agenda item API failed",
          });
          return response;
        }
        this.setState((prevState) => {
          var agenda_items = [];
          if (prevState.results.agenda_items !== undefined) {
            agenda_items = [...prevState.results.agenda_items];
          }
          agenda_items.splice(agenda_items.indexOf(agendaItem), 1);
          var results = prevState.results;
          results.agenda_items = agenda_items;
          return { results: results };
        });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Delete agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  updateAgendaItemFunction = (agendaItem) => {
    return updateAgendaItem(agendaItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Update agenda item API failed",
          });
          return response;
        }
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  createAgendaItemFunction = () => {
    return createAgendaItem(this.state.results.uuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Create agenda item API failed",
          });
          return response;
        }
        this.setState((prevState) => {
          var agenda_items = [];
          if (prevState.results.agenda_items) {
            agenda_items = prevState.results.agenda_items.map((item) => {
              item.shouldFocus = false;
              return item;
            });
          }
          var newItem = response.data.data;
          newItem.shouldFocus = true;
          agenda_items.push(newItem);
          var results = prevState.results;
          results.agenda_items = agenda_items;
          return { results: results };
        });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Create agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  reorderAgendaItemFunction = (result) => {
    if (!result.destination) {
      return;
    }

    const agendaItems = reorder(
      this.state.results.agenda_items,
      result.source.index,
      result.destination.index
    );
    this.setState({
      results: {
        ...this.state.results,
        agenda_items: agendaItems,
      },
    });

    return reorderAgendaItem(
      this.state.results.uuid,
      agendaItems.map((item) => item.uuid)
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Re-order agenda item API failed",
          });
          return response;
        }
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Re-order agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  addActionItem = (task, owner_participant_id, type) => {
    return addActionItem(
      this.state.results,
      task,
      owner_participant_id,
      type
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Add action item API failed",
          });
          return response;
        }
        this.openSnackbar("Added action item");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Add action item API success",
        });
        let results = this.state.results;
        results.action_items.push(response.data.data);
        this.setState({ results: results });
        return response;
      }.bind(this)
    );
  };

  updateActionItem = (actionItem, task) => {
    return updateActionItem(actionItem, task).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Update action item API failed",
          });
          return response;
        }
        this.openSnackbar("Updated action item");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update action item API success",
        });
        return response;
      }.bind(this)
    );
  };

  deleteActionItem = (actionItem) => {
    return deleteActionItem(actionItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Delete action item API failed",
          });
          return response;
        }
        this.openSnackbar("Deleted action item");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Delete action item API success",
        });
        let results = this.state.results;
        results.action_items = results.action_items.filter(
          (item) => item.uuid !== actionItem.uuid
        );
        this.setState({ results: results });
        return response;
      }.bind(this)
    );
  };

  completeActionItem = (actionItem) => {
    return completeActionItem(actionItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Mark action item as complete API failed",
          });
          return response;
        }
        if (actionItem.is_completed) {
          this.openSnackbar("Marked as Open");
        } else {
          this.openSnackbar("Marked as Complete");
        }
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Mark action item as complete API success",
        });
        const newActionItem = response.data.data;
        const results = this.state.results;
        results.action_items = results.action_items.map((ai) => {
          if (ai.uuid === newActionItem.uuid) {
            return newActionItem;
          }
          return ai;
        });
        this.setState({ results: results });
        return response;
      }.bind(this)
    );
  };

  assignActionItemOwner = (actionItem) => {
    return assignActionItemOwner(actionItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Assign action item owner API failed",
          });
          return response;
        }
        this.openSnackbar("Assigned owner to the action item");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Assign action item owner API success",
        });
        return response;
      }.bind(this)
    );
  };

  assignActionItemTarget = (actionItem) => {
    return assignActionItemTarget(actionItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Assign action item recipient API failed",
          });
          return response;
        }
        this.openSnackbar("Assigned recipient to the action item");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Assign action item recipient API success",
        });
        return response;
      }.bind(this)
    );
  };

  notifyActionItemOwners = (user, isCompleted) => {
    const userId = user ? user.id : -1;
    return notifyActionItemOwners(
      this.state.results.uuid,
      userId,
      isCompleted
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          } else if (response.status === 400) {
            this.openSnackbar(
              "Please wait at least an hour before sending the email notification again."
            );
          } else if (response.status === 404) {
            this.openSnackbar(
              "There are no action items, or no owners of action items that need to be notified."
            );
          } else {
            this.openSnackbar(
              "Sorry, an unknown error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Notify action item owner API failed",
          });
          return response;
        }
        this.openSnackbar(
          user === null
            ? "Notified all the owners of the action items"
            : "Notified " + user.name + " of the action items"
        );
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Notified action item owner API success",
        });
        return response;
      }.bind(this)
    );
  };

  updateDecisionNote = (decisionNote, description) => {
    return updateDecisionNote(decisionNote, description).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: `Update ${decisionNote.category} API failed`,
          });
          return response;
        }
        this.openSnackbar(`Updated ${decisionNote.category}`);
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: `Update ${decisionNote.category} API success`,
        });
        return response;
      }.bind(this)
    );
  };

  deleteDecisionNote = (decisionNote) => {
    return deleteDecisionNote(decisionNote).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: `Delete ${decisionNote.category} API failed`,
          });
          return response;
        }
        this.openSnackbar(`Deleted ${decisionNote.category}`);
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: `Delete ${decisionNote.category} API success`,
        });
        let results = this.state.results;
        results.decisions = results.decisions.filter(
          (item) => item.uuid !== decisionNote.uuid
        );
        results.notes = results.notes.filter(
          (item) => item.uuid !== decisionNote.uuid
        );
        this.setState({ results: results });
        return response;
      }.bind(this)
    );
  };

  assignDecisionNoteOwner = (decisionNote) => {
    return assignDecisionNoteOwner(decisionNote).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: `Assign ${decisionNote.category} owner API failed`,
          });
          return response;
        }
        this.openSnackbar(`Assigned owner to the ${decisionNote.category}`);
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: `Assign ${decisionNote.category} owner API success`,
        });
        return response;
      }.bind(this)
    );
  };

  addTakeaway = (transcriptResults, type, endTime, sentence) => {
    if (transcriptResults.length === 0) return;
    const lastTranscriptResult =
      transcriptResults[transcriptResults.length - 1];
    const emotion = getEmotionPrediction(lastTranscriptResult.emotions);
    const takeaway = {
      emotion: emotion,
      end_time: endTime,
      polarity: lastTranscriptResult.polarity,
      timestamps: transcriptResults.map((r) => r.timestamp),
      sentence: sentence,
    };
    return addTakeaway(this.state.results, takeaway, type).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Add takeaway API failed",
          });
          return response;
        }
        this.openSnackbar("Added Takeaway");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Add takeaway API success",
        });
        let results = this.state.results;
        if (type === 1) {
          results.pain_points.push(takeaway);
        } else if (type === 2) {
          results.opportunities.push(takeaway);
        } else {
          results.wins.push(takeaway);
        }
        this.setState({ results: results });
        return response;
      }.bind(this)
    );
  };

  deleteTakeaway = (takeaway) => {
    return deleteTakeaway(this.state.results, takeaway).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Delete takeaway API failed",
          });
          return response;
        }
        this.openSnackbar("Deleted Takeaway");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Delete takeaway API success",
        });
        let results = this.state.results;
        if (takeaway.subtype === conversationHighlightTypes.takeaways) {
          results.takeaways = results.takeaways.filter(
            (t) => t.timestamps[0] !== takeaway.timestamp
          );
        } else if (takeaway.subtype === conversationHighlightTypes.wins) {
          results.wins = results.wins.filter(
            (t) => t.timestamps[0] !== takeaway.timestamp
          );
        } else if (
          takeaway.subtype === conversationHighlightTypes.opportunities
        ) {
          results.opportunities = results.opportunities.filter(
            (t) => t.timestamps[0] !== takeaway.timestamp
          );
        } else if (takeaway.subtype === conversationHighlightTypes.painPoints) {
          results.pain_points = results.pain_points.filter(
            (t) => t.timestamps[0] !== takeaway.timestamp
          );
        }
        this.setState({ results: results });
        return response;
      }.bind(this)
    );
  };

  handleCreateClip = (startTime, endTime, name) => {
    return createMeetingClip(
      this.state.results.uuid,
      startTime,
      endTime,
      name
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          } else {
            this.openSnackbar(
              "Sorry, an unknown error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Create meeting clip API failed",
          });
          return response;
        }
        const results = this.state.results;
        results.clips.push(response.data.data);
        this.setState({ results: results });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Create meeting clip API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleRenameClip = (clipUuid, title) => {
    return updateMeetingClip(clipUuid, title).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          } else {
            this.openSnackbar(
              "Sorry, an unknown error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Update meeting clip API failed",
          });
          return response;
        }
        const results = this.state.results;
        const clipIndex = results.clips.findIndex(
          (clip) => clip.uuid === clipUuid
        );
        results.clips[clipIndex] = response.data.data;
        this.setState({ results: results });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update meeting clip API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleDeleteClip = (clipUuid) => {
    return deleteMeetingClip(clipUuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          } else {
            this.openSnackbar(
              "Sorry, an unknown error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Delete meeting clip API failed",
          });
          return response;
        }
        const results = this.state.results;
        results.clips = results.clips.filter((clip) => clip.uuid !== clipUuid);
        this.setState({ results: results });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Delete meeting clip API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleDeleteAllClips = () => {
    return deleteAllMeetingClips(this.state.results.uuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          } else {
            this.openSnackbar(
              "Sorry, an unknown error occurred. Please try again."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Delete all meeting clips API failed",
          });
          return response;
        }
        const results = this.state.results;
        results.clips = [];
        this.setState({ results: results });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Delete all meeting clips API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleOpenViewClipModal = (clipIdx, clips) => {
    this.setState({
      openViewClipModal: true,
      viewClipModalIndex: clipIdx,
      viewClipModalClips: clips,
    });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked to view a clip",
    });
  };

  handleCloseViewClipModal = () => {
    this.setState({
      openViewClipModal: false,
      viewClipModalIndex: -1,
      viewClipModalClips: [],
    });
  };

  handlePrevClip = () => {
    const currIdx = this.state.viewClipModalIndex;
    if (currIdx > 0) {
      this.setState({ viewClipModalIndex: currIdx - 1 });
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: "User navigated to the previous clip from within the modal",
      });
    }
  };

  handleNextClip = () => {
    const currIdx = this.state.viewClipModalIndex;
    if (currIdx + 1 < this.state.viewClipModalClips.length) {
      this.setState({ viewClipModalIndex: currIdx + 1 });
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: "User navigated to the next clip from within the modal",
      });
    }
  };

  handleAddComment = (clipUuid, context) => {
    return addMeetingClipComment(clipUuid, context).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Add meeting clip comment API failed",
          });
          return response;
        }
        let { results } = this.state;
        results.clips.forEach((clip) => {
          if (clip.uuid === clipUuid) {
            clip.clip_comments.push(response.data.data);
            return;
          }
        });
        this.setState({
          results: results,
        });
        this.openSnackbar("Your comment has been added.");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Add meeting clip comment API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleUpdateComment = (clipUuid, commentUuid, context) => {
    return updateMeetingClipComment(commentUuid, context).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Update meeting clip comment API failed",
          });
          return response;
        }
        let { results } = this.state;
        results.clips.forEach((clip) => {
          if (clip.uuid === clipUuid) {
            clip.clip_comments.forEach((comment) => {
              if (comment.uuid === commentUuid) {
                comment.context = context.trim();
                comment.edited = true;
                return;
              }
            });
            return;
          }
        });
        this.setState({
          results: results,
        });
        this.openSnackbar("Your comment has been updated.");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update meeting clip comment API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleDeleteComment = (clipUuid, commentUuid) => {
    return deleteMeetingClipComment(commentUuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Remove meeting clip comment API failed",
          });
          return response;
        }
        let { results } = this.state;
        results.clips.forEach((clip) => {
          if (clip.uuid === clipUuid) {
            clip.clip_comments = clip.clip_comments.filter(
              (comment) => comment.uuid !== commentUuid
            );
            return;
          }
        });
        this.setState({
          results: results,
        });
        this.openSnackbar("Your comment has been removed.");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Remove meeting clip comment API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleUpdateReadComment = (clipUuid) => {
    return readMeetingClipComment(clipUuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          ReactGA.event({
            category: "Failure",
            action: "Update meeting clip comment read list API failed",
          });
          return response;
        }
        let { results } = this.state;
        results.clips.forEach((clip) => {
          if (clip.uuid === clipUuid) {
            clip.clip_comments.forEach((comment) => {
              if (!comment.seen_by.includes(response.data.data)) {
                comment.seen_by.push(response.data.data);
                comment.has_seen = true;
              }
            });
            return;
          }
        });
        this.setState({
          results: results,
        });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update meeting clip comment read list API success",
        });
        return response;
      }.bind(this)
    );
  };

  renderViewClipModal = () => {
    const {
      results,
      openViewClipModal,
      viewClipModalIndex,
      viewClipModalClips,
    } = this.state;
    return (
      <ViewClipModal
        modalOpen={openViewClipModal}
        clips={viewClipModalClips}
        modalClipIndex={viewClipModalIndex}
        data={results}
        handleCloseModal={this.handleCloseViewClipModal}
        handlePrevClip={this.handlePrevClip}
        handleNextClip={this.handleNextClip}
        handleAddComment={this.handleAddComment}
        handleUpdateComment={this.handleUpdateComment}
        handleDeleteComment={this.handleDeleteComment}
        handleUpdateReadComment={this.handleUpdateReadComment}
        isSharedMeetingView={false}
      />
    );
  };

  handleOpenDeleteMeetingDialog = () => {
    this.setState({ openDeleteMeetingDialog: true });
    this.closeMenu();
  };

  handleCloseDeleteMeetingDialog = () => {
    this.setState({ openDeleteMeetingDialog: false });
  };

  renderDeleteMeetingDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <DeleteMeetingDialog
        classes={classes}
        open={this.state.openDeleteMeetingDialog}
        id={this.speechId}
        onClose={this.handleCloseDeleteMeetingDialog}
        openSnackbar={this.openSnackbar}
        setLoading={this.setLoading}
        handleDeleteMeeting={this.props.history.goBack}
      />
    );
  };

  renderShareDialog = () => {
    return (
      <ShareDialog
        meetingUuid={this.state.results.uuid}
        meetingStatus={this.state.speechStatus}
        isShareDialogOpen={this.state.openShareDialog}
        handleClose={this.handleCloseShareDialog}
        openSnackbar={this.openSnackbar}
        isQuickShare={false}
      />
    );
  };

  renderGetFeedbackDialog = () => {
    return (
      <GetFeedbackDialog
        openFeedbackDialog={this.state.openFeedbackDialog}
        handleCloseFeedbackDialog={this.handleCloseFeedbackDialog}
        updateFeedbackStatus={this.updateFeedbackStatus}
        getFeedbackFromParticipants={this.getFeedbackFromParticipants}
        data={this.state.results}
      />
    );
  };

  // MEETING OPTIONS

  handleCloseOptionsMenu = () => {
    this.setState({ optionsMenuAnchorEl: null });
  };

  handleOptionsButtonClick = (event) => {
    this.setState({ optionsMenuAnchorEl: event.currentTarget });
  };

  handleOpenFeedbackDialog = () => {
    this.setState({ openFeedbackDialog: true });
    this.handleCloseOptionsMenu();
  };

  handleCloseFeedbackDialog = () => {
    this.setState({ openFeedbackDialog: false });
  };

  renderOptionsMenu = () => {
    const { classes } = this.props;
    return (
      <Menu
        anchorEl={this.state.optionsMenuAnchorEl}
        keepMounted
        open={Boolean(this.state.optionsMenuAnchorEl)}
        onClose={this.handleCloseOptionsMenu}
      >
        {!this.state.hideOptions && (
          <>
            <MenuItem onClick={this.handleOpenRenameMeetingDialog}>
              Rename Meeting
            </MenuItem>
            {!this.state.meetingFoldersLoading && (
              <MenuItem onClick={this.handleOpenMoveMeetingDialog}>
                Move Meeting
              </MenuItem>
            )}
            <MenuItem onClick={this.handleOpenFeedbackDialog}>
              Get Feedback
            </MenuItem>
          </>
        )}
        <MenuItem
          className={classes.deleteMeetingOption}
          onClick={this.handleOpenDeleteMeetingDialog}
        >
          Delete Meeting
        </MenuItem>
      </Menu>
    );
  };

  handleOpenAttendees = (event) => {
    this.setState({ attendeesAnchor: event.currentTarget });
  };

  handleCloseAttendees = () => {
    this.setState({ attendeesAnchor: null });
  };

  renderTitleComponent = () => {
    const { classes } = this.props;
    const isRecordingEnabled = this.state.results.is_recording_enabled;
    const completedStatus = this.state.speechStatus === speechStatus.completed;

    let tooltipTitle;
    if (this.state.speechStatus === speechStatus.uninitialized) {
      tooltipTitle = (
        <div>
          <p className={classes.tooltipSection}>
            Your meeting has been scheduled.
          </p>
          <p className={classes.tooltipSection}>
            In the meantime, you can add agenda items and attendee information.
          </p>
        </div>
      );
    } else if (this.state.speechStatus === speechStatus.started) {
      tooltipTitle = (
        <div>
          <p className={classes.tooltipSection}>Your meeting has started.</p>
          <p className={classes.tooltipSection}>
            In the meantime, you can add agenda items and attendee information.
          </p>
        </div>
      );
    } else if (this.state.speechStatus === speechStatus.waiting) {
      tooltipTitle = (
        <div>
          <p className={classes.tooltipSection}>
            Your meeting has ended and we are waiting for the recording to
            become available.
          </p>
          <p className={classes.tooltipSection}>
            This may take some time depending on the length of the recording.
            Once it becomes available, we will begin processing.
          </p>
          <p className={classes.tooltipSection}>
            In the meantime, you can add agenda items and attendee information.
          </p>
        </div>
      );
    } else if (this.state.speechStatus === speechStatus.processing) {
      tooltipTitle = (
        <div>
          <p className={classes.tooltipSection}>
            Your recording is currently being processed and analyzed. This may
            take some time depending on the length of the recording.
          </p>
          <p className={classes.tooltipSection}>
            We will send you an email once processing is complete.
          </p>
        </div>
      );
    } else if (this.state.speechStatus === speechStatus.completed) {
      tooltipTitle = (
        <div>
          <p className={classes.tooltipSection}>
            Your meeting has been processed.
          </p>
        </div>
      );
    }

    let viewCountTitle;
    if (this.state.viewCount === 0) {
      viewCountTitle = (
        <div>
          <p className={classes.tooltipSection}>
            Your meeting has not been viewed yet.
          </p>
        </div>
      );
    } else {
      viewCountTitle = (
        <div>
          <p className={classes.tooltipSection}>
            Your meeting has been viewed {this.state.viewCount} time
            {this.state.viewCount === 1 ? "" : "s"}.
          </p>
        </div>
      );
    }

    return (
      <div className={classes.titleContainer}>
        <span>{this.state.meetingTitle}</span>
        {tooltipTitle ? (
          <StyledTooltip title={tooltipTitle} arrow placement="bottom">
            <Chip
              size="small"
              color="primary"
              label={getStatus(this.state.speechStatus)}
              variant="outlined"
              className={classes.speechStatusChip}
            />
          </StyledTooltip>
        ) : (
          <Chip
            size="small"
            color="primary"
            label={getStatus(this.state.speechStatus)}
            variant="outlined"
            className={classes.speechStatusChip}
          />
        )}
        <StyledTooltip title={viewCountTitle} arrow placement="bottom">
          <div className={classes.viewCounter}>
            <VisibilityIcon />
            <Typography variant="h6">{this.state.viewCount}</Typography>
          </div>
        </StyledTooltip>
        {completedStatus && !isRecordingEnabled && (
          <div className={classes.attendeesContainer}>
            <div
              className={classes.attendeesButton}
              onClick={this.handleOpenAttendees}
            >
              <span>
                <GroupIcon className={classes.attendeesIcon} />
              </span>
              Attendees
            </div>
            <Menu
              anchorEl={this.state.attendeesAnchor}
              open={Boolean(this.state.attendeesAnchor)}
              onClose={this.handleCloseAttendees}
              MenuListProps={{
                disablePadding: true,
              }}
            >
              <div className={classes.hiddenMeetingInfo}>
                <MeetingInfo
                  className={classes.item}
                  data={this.state.results}
                  meetingStatus={this.state.speechStatus}
                  handleOpenShare={this.handleOpenShareDialog}
                  setMediaPlayerCurrentTimeAndPlay={
                    this.setMediaPlayerCurrentTimeAndPlay
                  }
                  addParticipantHandler={this.handleAddParticipantResponseData}
                  deleteParticipantHandler={
                    this.handleDeleteParticipantResponseData
                  }
                  updateParticipantHandler={
                    this.handleUpdateParticipantResponseData
                  }
                  openSnackbar={this.openSnackbar}
                  isOrgOwner={this.state.isOrgOwner}
                  hideShareButton={true}
                  handleSetScrollToClip={this.handleSetScrollToClip}
                />
              </div>
            </Menu>
          </div>
        )}
      </div>
    );
  };

  renderError = () => {
    const { classes } = this.props;
    const showOptions =
      this.state.speechStatus === speechStatus.failed ||
      this.state.speechStatus === speechStatus.rejected;
    const showActionButton = showOptions
      ? this.isMeetingOwner || this.state.isOrgOwner
      : null;
    const actionButtonHandler = showOptions
      ? this.handleOptionsButtonClick
      : null;
    const isOwner = showOptions
      ? this.state.results.owner_email === localStorage.getItem("email") ||
        this.state.isOrgOwner
      : null;
    return (
      <DashboardLayout
        title={this.state.meetingTitle}
        showActionButton={showActionButton}
        actionButtonHandler={actionButtonHandler}
        showMeetingAccount={isSoneroUser()}
        meetingAccount={this.state.results.account}
        meetingUuid={this.state.results.uuid}
      >
        <div className={classes.root}>
          <div className={classes.content}>
            <Portlet>
              <PortletContent className={classes.content}>
                <Typography className={classes.error} variant="body1">
                  {this.state.error}
                </Typography>
              </PortletContent>
            </Portlet>
          </div>
        </div>
        {showOptions && this.renderOptionsMenu()}
        {showOptions && this.renderDeleteMeetingDialog()}
        {showOptions && this.renderGetFeedbackDialog()}
      </DashboardLayout>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title={this.state.meetingTitle}>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderProcessing = () => {
    const { classes } = this.props;
    const { results, isOrgOwner } = this.state;
    if (results.recording_url && results.transcript_results.length > 0) {
      return this.renderSpeechResults();
    }
    return (
      <DashboardLayout
        title={this.renderTitleComponent()}
        showActionButton={this.isMeetingOwner || isOrgOwner}
        actionButtonHandler={this.handleOptionsButtonClick}
        showShareButton={true}
        shareButtonHandler={this.handleOpenShareDialog}
        showMeetingAccount={isSoneroUser()}
        meetingAccount={this.state.results.account}
        meetingUuid={this.state.results.uuid}
      >
        <div className={classes.root}>
          <div className={classes.content}>
            <Portlet>
              <PortletContent className={classes.item}>
                <div className={classes.progressWrapper}>
                  <CircularProgress />
                </div>
                <Typography
                  className={classes.caption}
                  variant="h4"
                  align="center"
                >
                  Your recording is currently being processed and analyzed. This
                  may take some time depending on the length of the recording.{" "}
                  <br />
                  <br />
                  We will send you an email once processing is complete.
                </Typography>
              </PortletContent>
            </Portlet>
            {this.renderOptionsMenu()}
            {this.renderRenameMeetingDialog()}
            {this.renderDeleteMeetingDialog()}
            {this.renderShareDialog()}
            {this.renderGetFeedbackDialog()}
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderWaiting = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout
        title={this.renderTitleComponent()}
        showActionButton={this.isMeetingOwner || this.state.isOrgOwner}
        actionButtonHandler={this.handleOptionsButtonClick}
        showShareButton={false}
        shareButtonHandler={this.handleOpenShareDialog}
        showMeetingAccount={isSoneroUser()}
        meetingAccount={this.state.results.account}
        meetingUuid={this.state.results.uuid}
      >
        <div className={classes.root}>{this.renderAgendaAndAttendees()}</div>
      </DashboardLayout>
    );
  };

  renderSpeechResults = () => {
    const { classes } = this.props;
    const isRecordingEnabled = this.state.results.is_recording_enabled;
    const shouldShowMeetingScore =
      showMeetingScore(this.state.results, this.state.speechStatus, false) &&
      !isRecordingEnabled;
    const trackerNotesRect = this.state.trackerNotesRect;

    return (
      <DashboardLayout
        title={this.renderTitleComponent()}
        showActionButton={this.isMeetingOwner || this.state.isOrgOwner}
        actionButtonHandler={this.handleOptionsButtonClick}
        showShareButton={true}
        shareButtonHandler={this.handleOpenShareDialog}
        showMeetingAccount={isSoneroUser()}
        meetingAccount={this.state.results.account}
        meetingUuid={this.state.results.uuid}
      >
        <div className={classes.root}>
          <Grid container spacing={4}>
            {(isRecordingEnabled || shouldShowMeetingScore) && (
              <>
                <Grid item lg={7} md={7} xl={9} xs={12}>
                  {isRecordingEnabled && (
                    <ViewVideo
                      ref={(instance) => {
                        this.viewVideo = instance;
                      }}
                      className={classes.item}
                      videoBlob={this.state.videoBlob}
                      data={this.state.results}
                      isMeeting={true}
                      bookmarks={this.state.bookmarks}
                      handleBookmarkDelete={this.handleBookmarkDelete}
                      bookmarkResponseHandler={
                        this.handleNewBookmarkResponseData
                      }
                      requestCustomPictureInPicture={
                        this.requestCustomPictureInPicture
                      }
                    />
                  )}
                  {shouldShowMeetingScore && (
                    <MeetingScore data={this.state.results} wrapInPortlet />
                  )}
                </Grid>
                <Grid item lg={5} md={5} xl={3} xs={12}>
                  <MeetingInfo
                    className={classes.item}
                    data={this.state.results}
                    meetingStatus={this.state.speechStatus}
                    handleOpenShare={this.handleOpenShareDialog}
                    setMediaPlayerCurrentTimeAndPlay={
                      this.setMediaPlayerCurrentTimeAndPlay
                    }
                    addParticipantHandler={
                      this.handleAddParticipantResponseData
                    }
                    deleteParticipantHandler={
                      this.handleDeleteParticipantResponseData
                    }
                    updateParticipantHandler={
                      this.handleUpdateParticipantResponseData
                    }
                    openSnackbar={this.openSnackbar}
                    isOrgOwner={this.state.isOrgOwner}
                    hideShareButton={false}
                    handleSetScrollToClip={this.handleSetScrollToClip}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={12} md={12} xl={12} xs={12} ref={this.tabPanelsRef}>
              <AppBar position="static" color="default" style={{ flexGrow: 1 }}>
                <Tabs
                  value={this.state.tab}
                  onChange={(_event, newValue) =>
                    this.handleTabChange(newValue)
                  }
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Insights" />
                  <Tab label="Analytics" />
                </Tabs>
              </AppBar>
              <TabPanel value={this.state.tab} index={this.tabIndex.insights}>
                <Overview
                  className={classes.item}
                  data={this.state.results}
                  meetingStatus={this.state.speechStatus}
                  navigateToAnalytics={() =>
                    this.navigateToTab(this.tabIndex.analytics)
                  }
                  setMediaPlayerCurrentTimeAndPlay={
                    this.setMediaPlayerCurrentTimeAndPlay
                  }
                  addActionItem={this.addActionItem}
                  updateActionItem={this.updateActionItem}
                  deleteActionItem={this.deleteActionItem}
                  completeActionItem={this.completeActionItem}
                  assignActionItemOwner={this.assignActionItemOwner}
                  assignActionItemTarget={this.assignActionItemTarget}
                  notifyActionItemOwners={this.notifyActionItemOwners}
                  updateDecisionNote={this.updateDecisionNote}
                  deleteDecisionNote={this.deleteDecisionNote}
                  assignDecisionNoteOwner={this.assignDecisionNoteOwner}
                  openSnackbar={this.openSnackbar}
                  isSharedMeetingView={false}
                  initWithTranscriptSearchValue={
                    this.initWithTranscriptSearchValue
                  }
                  meetingTitle={this.state.meetingTitle}
                  mapParticipantResponseHandler={
                    this.handleMapParticipantResponseData
                  }
                  transcriptUpdateResponseHandler={
                    this.handleTranscriptUpdateResponseData
                  }
                  transcriptUpdateFailureHandler={
                    this.handleTranscriptUpdateFailure
                  }
                  questionUpdateResponseHandler={
                    this.handleQuestionUpdateResponseData
                  }
                  openCreateBookmark={(startDuration, endDuration) => {
                    this.handleOpenCreateBookmarkFromTranscript(
                      startDuration,
                      endDuration
                    );
                  }}
                  createClip={this.handleCreateClip}
                  renameClip={this.handleRenameClip}
                  deleteClip={this.handleDeleteClip}
                  deleteAllClips={this.handleDeleteAllClips}
                  openClip={this.handleOpenViewClipModal}
                  preSelectedKeyTopic={this.keyTopic}
                  addTakeaway={this.addTakeaway}
                  deleteTakeaway={this.deleteTakeaway}
                  scrollToQuestion={this.state.scrollToQuestion}
                  scrollToTracker={this.state.scrollToTracker}
                  scrollToDecision={this.state.scrollToDecision}
                  scrollToNote={this.state.scrollToNote}
                  scrollToActionItem={this.state.scrollToActionItem}
                  scrollToClip={this.state.scrollToClip}
                  scrollToWidget={this.state.scrollToWidget}
                  setTrackerNotesRect={this.setTrackerNotesRect}
                />
                {(this.initWithTranscriptSearchValue = "")}
              </TabPanel>
              <TabPanel value={this.state.tab} index={this.tabIndex.analytics}>
                <Analytics
                  data={this.state.results}
                  constants={this.state.constants}
                  meetingStatus={this.state.speechStatus}
                  setMediaPlayerCurrentTimeAndPlay={
                    this.setMediaPlayerCurrentTimeAndPlay
                  }
                />
              </TabPanel>
            </Grid>
          </Grid>
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration={this.state.autoHideDuration}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: this.state.whiteSnackbar
                ? classes.whiteSnackbar
                : classes.snackbar,
            }}
            message={this.state.snackbarMessage}
            action={
              <Button
                style={{ color: this.state.whiteSnackbar ? "black" : "white" }}
                size="small"
                onClick={this.handleSnackbarClose}
              >
                CLOSE
              </Button>
            }
          />
          {this.renderOptionsMenu()}
          {this.renderRenameMeetingDialog()}
          {this.renderDeleteMeetingDialog()}
          {this.renderShareDialog()}
          {this.renderMoveMeetingDialog()}
          {this.renderViewClipModal()}
          {this.renderGetFeedbackDialog()}
        </div>
        {this.state.isCustomPiPOpen && (
          <CustomPictureInPicture
            video={this.state.videoPlayer}
            exitCustomPictureInPicture={this.exitCustomPictureInPicture}
          />
        )}
        <FTUX trackerNotesRect={trackerNotesRect} />
      </DashboardLayout>
    );
  };

  renderAgendaAndAttendees = () => {
    const { classes } = this.props;

    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={7} md={7} xl={9} xs={12}>
            <Agenda
              className={classes.item}
              agenda={this.state.results.agenda_items}
              deleteAgendaItem={this.deleteAgendaItemFunction}
              createAgendaItem={this.createAgendaItemFunction}
              updateAgendaItem={this.updateAgendaItemFunction}
              reorderAgendaItem={this.reorderAgendaItemFunction}
              openSnackbar={this.openSnackbar}
            />
          </Grid>
          <Grid item lg={5} md={5} xl={3} xs={12}>
            <MeetingInfo
              className={classes.item}
              data={this.state.results}
              meetingStatus={this.state.speechStatus}
              handleOpenShare={this.handleOpenShareDialog}
              updateSharingStatus={this.updateSharingStatus}
              shareMeetingWithParticipants={this.shareMeetingWithParticipants}
              setMediaPlayerCurrentTimeAndPlay={
                this.setMediaPlayerCurrentTimeAndPlay
              }
              addParticipantHandler={this.handleAddParticipantResponseData}
              deleteParticipantHandler={
                this.handleDeleteParticipantResponseData
              }
              updateParticipantHandler={
                this.handleUpdateParticipantResponseData
              }
              isGetFeedbackEnabled={
                this.state.speechStatus === speechStatus.uninitialized
                  ? false
                  : true
              }
              openSnackbar={this.openSnackbar}
              isOrgOwner={this.state.isOrgOwner}
              hideShareButton={false}
              handleSetScrollToClip={this.handleSetScrollToClip}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={this.state.autoHideDuration}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar,
          }}
          message={this.state.snackbarMessage}
          action={
            <Button
              style={{ color: "white" }}
              size="small"
              onClick={this.handleSnackbarClose}
            >
              CLOSE
            </Button>
          }
        />
        {this.renderOptionsMenu()}
        {this.renderRenameMeetingDialog()}
        {this.renderDeleteMeetingDialog()}
        {this.renderShareDialog()}
        {this.renderGetFeedbackDialog()}
      </>
    );
  };

  renderPrepareMode = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout
        title={this.renderTitleComponent()}
        showActionButton={this.isMeetingOwner || this.state.isOrgOwner}
        actionButtonHandler={this.handleOptionsButtonClick}
        showShareButton={true}
        shareButtonHandler={this.handleOpenShareDialog}
        showMeetingAccount={isSoneroUser()}
        meetingAccount={this.state.results.account}
        meetingUuid={this.state.results.uuid}
      >
        <div className={classes.root}>{this.renderAgendaAndAttendees()}</div>
      </DashboardLayout>
    );
  };

  render() {
    const isLoading = this.state.isLoading;

    if (this.state.error != null) {
      return this.renderError();
    }

    if (isLoading) {
      return this.renderLoading();
    }

    if (this.state.speechStatus === speechStatus.processing) {
      return this.renderProcessing();
    } else if (this.state.speechStatus === speechStatus.waiting) {
      return this.renderWaiting();
    } else if (this.state.speechStatus === speechStatus.failed) {
      return this.renderError();
    } else if (
      this.state.speechStatus === speechStatus.uninitialized ||
      this.state.speechStatus === speechStatus.started ||
      this.state.speechStatus === speechStatus.completed_agenda_only
    ) {
      return this.renderPrepareMode();
    }

    return this.renderSpeechResults();
  }
}

ViewMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewMeeting);
