import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  withStyles,
  TextField,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import { moveMeetingToFolder } from "services/speech";

import ReactGA from "react-ga";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import { shareAccess } from "helpers/shareAccess";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  moveMeetingOption: {
    color: theme.palette.danger.main,
    marginTop: theme.spacing.unit,
  },
  moveMeetingButton: {
    color: theme.palette.danger.main,
  },
  moveMeetingCaption: {
    color: theme.palette.text.primary,
  },
  dialogContent: {
    height: "400px",
  },
});

class MoveMeetingDialog extends Component {
  state = {
    isLoading: false,
    meetingFolders: [],
    openConfirmDialog: false,
    selectedFolder: null,
    listOpen: false,
  };

  componentDidMount = () => {
    this.setState({ meetingFolders: this.props.meetingFolders });
  };

  handleInputChange = (event, value) => {
    if (value) {
      const folder = this.state.meetingFolders[
        event.target.getAttribute("data-option-index")
      ];
      if (folder) {
        this.setState({
          openConfirmDialog: folder.general_share_access === shareAccess.anyone,
          selectedFolder: folder,
        });
      }
    } else {
      this.setState({ openConfirmDialog: false, selectedFolder: null });
    }
  };

  handleMoveMeeting = (folderUuid) => {
    this.setLoading(true);
    moveMeetingToFolder(this.props.meetingId, folderUuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.props.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          this.setLoading(false);
          ReactGA.event({
            category: "Failure",
            action: "Move meeting API failed",
          });
          return response;
        }

        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Move meeting API success",
        });
        this.setLoading(false);
        this.props.onClose();
        return response;
      }.bind(this)
    );
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
    this.props.setLoading(isLoading);
  };

  render = () => {
    const { classes, className, title, ...rest } = this.props;
    const { meetingFolders } = this.state;
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.onClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Move Meeting</DialogTitle>
        <DialogContent className={this.state.listOpen && classes.dialogContent}>
          <Typography
            className={classes.moveMeetingCaption}
            variant="h5"
            align="center"
          >
            {this.state.openConfirmDialog
              ? `"${this.state.selectedFolder.title}" is a shared folder. "${title}"
            will be shared as well once it is moved to the folder. Do you still
            want to move it?`
              : `Select a folder to move "${title}" to`}
          </Typography>
          <Autocomplete
            disablePortal
            options={meetingFolders}
            renderInput={(params) => <TextField {...params} label="Folder" />}
            getOptionLabel={(folder) => folder.title}
            onInputChange={this.handleInputChange}
            onOpen={() => this.setState({ listOpen: true })}
            onClose={() => this.setState({ listOpen: false })}
          />
        </DialogContent>
        <DialogActions>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <>
              {this.state.selectedFolder && (
                <Button
                  onClick={() =>
                    this.handleMoveMeeting(this.state.selectedFolder.id)
                  }
                  color="primary"
                >
                  CONFIRM
                </Button>
              )}
              <Button onClick={() => this.props.onClose()} color="primary">
                CANCEL
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

MoveMeetingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  meetingId: PropTypes.number.isRequired,
};

export default withStyles(styles)(MoveMeetingDialog);
