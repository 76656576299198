import React, { useState, useEffect } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material icons
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from "components";

import { updateProfile } from "services/speech";

// Component styles
import styles from "./styles";

const BasicInformation = (props) => {
  const [firstName, setFirstName] = useState(props.userInfo.first_name);
  const [lastName, setLastName] = useState(props.userInfo.last_name);

  // track if each field is currently being edited or not
  const [editingFirstName, setEditingFirstName] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false);

  // store the currently edited field values
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");

  useEffect(() => {
    setFirstName(props.userInfo.first_name);
    setLastName(props.userInfo.last_name);
  }, [props.userInfo]);

  const renderBasicInfoFields = () => (
    <div className={classes.basicInfoFieldsContainer}>
      <div className={classes.fieldContainer}>
        <div className={classes.fieldLabel}>First Name</div>
        <div className={classes.fieldContent}>
          {editingFirstName ? (
            <>
              <input
                type="text"
                className={classes.editingInput}
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
              />
              <div>
                <DoneIcon
                  className={classes.fieldIcon}
                  onClick={() => {
                    updateProfile({ firstName: newFirstName }).then(() => {
                      setFirstName(newFirstName ? newFirstName : firstName);
                      setEditingFirstName(false);
                    });
                  }}
                />
                <ClearIcon
                  className={classes.fieldIcon}
                  onClick={() => setEditingFirstName(false)}
                />
              </div>
            </>
          ) : (
            <>
              {firstName}
              <EditIcon
                className={classes.fieldIcon}
                onClick={() => {
                  setEditingFirstName(true);
                  setNewFirstName(firstName);
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className={classes.fieldContainer}>
        <div className={classes.fieldLabel}>Last Name</div>
        <div className={classes.fieldContent}>
          {editingLastName ? (
            <>
              <input
                type="text"
                className={classes.editingInput}
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
              />
              <div>
                <DoneIcon
                  className={classes.fieldIcon}
                  onClick={() => {
                    updateProfile({ lastName: newLastName }).then(() => {
                      setLastName(newLastName ? newLastName : lastName);
                      setEditingLastName(false);
                    });
                  }}
                />
                <ClearIcon
                  className={classes.fieldIcon}
                  onClick={() => setEditingLastName(false)}
                />
              </div>
            </>
          ) : (
            <>
              {lastName}
              <EditIcon
                className={classes.fieldIcon}
                onClick={() => {
                  setEditingLastName(true);
                  setNewLastName(lastName);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );

  const { classes, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletHeader>
        <PortletLabel title="Basic Information" />
      </PortletHeader>
      <PortletContent>{renderBasicInfoFields()}</PortletContent>
    </Portlet>
  );
};

BasicInformation.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

export default withStyles(styles)(BasicInformation);
