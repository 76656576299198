import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Checkbox,
  Typography,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
} from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";

// Component styles
import styles from "./styles";

import { sharingLevels } from "helpers";

class GcalPrefs extends Component {
  state = {
    isAutomaticGCalShareableLinkEnabled: true,
    automaticSharingLevel: sharingLevels.all,
    isOrgSettingsEnabled: false,
  };

  componentDidMount() {
    const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
    this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
    if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
      this.setPrefs();
    } else {
      this.setOrgPrefs(isOrgSettingsEnabled);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.onOrgTab !== this.props.onOrgTab ||
      prevProps.orgSettings !== this.props.orgSettings
    ) {
      const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
      this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
      if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
        this.setPrefs();
      } else {
        this.setOrgPrefs(isOrgSettingsEnabled);
      }
    }
  }

  setPrefs = () => {
    var isAutomaticGCalShareableLinkEnabled = JSON.parse(
      localStorage.getItem("isAutomaticGCalShareableLinkEnabled")
    );
    var automaticSharingLevel = JSON.parse(
      localStorage.getItem("automaticSharingLevel")
    );
    this.setState({
      isAutomaticGCalShareableLinkEnabled: isAutomaticGCalShareableLinkEnabled,
      automaticSharingLevel: automaticSharingLevel,
    });
  };

  setOrgPrefs = (isOrgSettingsEnabled) => {
    if (isOrgSettingsEnabled) {
      const { orgSettings } = this.props;

      this.setState({
        isAutomaticGCalShareableLinkEnabled:
          orgSettings.is_automatic_gcal_shareable_link_enabled,
        automaticSharingLevel: orgSettings.automatic_sharing_level,
      });
    } else {
      this.setState({
        isAutomaticGCalShareableLinkEnabled: false,
        automaticSharingLevel: false,
      });
    }
  };

  isOrgSettingsEnabled = () => {
    const { orgSettings } = this.props;
    return (
      orgSettings.is_automatic_gcal_shareable_link_enabled != null &&
      orgSettings.automatic_sharing_level != null
    );
  };

  handleSave = () => {
    var prefs = {
      isAutomaticGCalShareableLinkEnabled: this.state
        .isAutomaticGCalShareableLinkEnabled,
      automaticSharingLevel: this.state.automaticSharingLevel,
    };
    this.props.saveHandler(prefs);
  };

  handleOrgSettingsToggle = (event) => {
    if (event.target.checked) {
      this.setState({
        isOrgSettingsEnabled: true,
      });
    } else {
      const prefs = {
        isAutomaticGCalShareableLinkEnabled: null,
        automaticSharingLevel: null,
      };
      this.props.saveHandler(prefs).then(() => {
        this.setState({ isOrgSettingsEnabled: false });
        this.props.openSnackbar("Settings updated");
      });
    }
  };

  handleShareableLinkChange = (event) => {
    this.setState({
      isAutomaticGCalShareableLinkEnabled: event.target.checked,
    });
  };

  handleShareLevelChange = (event) => {
    this.setState({
      automaticSharingLevel: event.target.value,
    });
  };

  render() {
    const { classes, className, onOrgTab, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Calendar Preferences" />
          {onOrgTab && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Off</Typography>
              <Switch
                color="primary"
                checked={this.state.isOrgSettingsEnabled}
                onChange={this.handleOrgSettingsToggle}
              />
              <Typography>On</Typography>
            </div>
          )}
        </PortletHeader>
        {!onOrgTab && this.state.isOrgSettingsEnabled && (
          <PortletContent className={classes.info}>
            <Typography variant="h6">
              Your settings and preferences are managed by your organization
              owner. <br />
              Please contact them for any changes.
            </Typography>
          </PortletContent>
        )}
        <PortletContent noPadding>
          <form className={classes.form}>
            <div className={classes.group}>
              <div className={classes.firstField}>
                <Checkbox
                  color="primary"
                  onChange={this.handleShareableLinkChange}
                  checked={this.state.isAutomaticGCalShareableLinkEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Add a shareable link to the meeting in my calendar invites
                    automatically (Recommended)
                  </Typography>
                  <Typography variant="caption">
                    We'll add a link to your meeting in your calendar invite so
                    that other meeting attendees can add agenda topics and/or
                    view the meeting insights
                  </Typography>
                </div>
              </div>
              <div className={classes.radioButtonContainer}>
                {this.state.isAutomaticGCalShareableLinkEnabled && (
                  <RadioGroup
                    value={this.state.automaticSharingLevel}
                    onChange={this.handleShareLevelChange}
                  >
                    <div className={classes.radioOption}>
                      <FormControlLabel
                        value={sharingLevels.all}
                        control={<Radio color="primary" />}
                        label="Share Agenda and Insights (Recommended)"
                        disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                      />
                      <Typography
                        variant="caption"
                        className={classes.radioButtonDescription}
                      >
                        Meeting attendees can add agenda topics before the
                        meeting starts and view the meeting insights after it
                        ends through the shareable link
                      </Typography>
                    </div>
                    <div className={classes.radioOption}>
                      <FormControlLabel
                        value={sharingLevels.agendaOnly}
                        control={<Radio color="primary" />}
                        label="Share Agenda Only"
                        disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                      />
                      <Typography
                        variant="caption"
                        className={classes.radioButtonDescription}
                        gutterBottom
                      >
                        Meeting attendees can only add agenda topics before the
                        meeting starts through the shareable link
                      </Typography>
                    </div>
                  </RadioGroup>
                )}
              </div>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            onClick={this.handleSave}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

GcalPrefs.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GcalPrefs);
