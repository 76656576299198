export const toneResults = {
  no_emotion: {
    result: "UNVARIED",
    class: "badTone",
    caption: (speaker) =>
      `${speaker} had very little emotion in their delivery which made it sound monotonous. ${speaker} should emphasize some phrases and action words to give some ebb and flow which will greatly improve their delivery!`,
  },
  conversational: {
    result: "CONVERSATIONAL",
    class: "goodTone",
    caption: (speaker) =>
      `${speaker}'s delivery sounded conversational. This is good for informing the audience! But to also entertain the audience, ${speaker} should sound a bit more passionate!`,
  },
  passionate: {
    result: "PASSIONATE",
    class: "goodTone",
    caption: (speaker) =>
      `${speaker} sounded very passionate in their delivery! Passion is critical in entertaining the audience! Keep it up!`,
  },
};

export const toneToValue = (tone) => {
  if (tone === "no_emotion") {
    return -1; 
  } else if (tone === "conversational") {
    return 0;
  } else if (tone === "passionate") {
    return 1;
  }
};