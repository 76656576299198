import React, { useState } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import SearchBar from "material-ui-search-bar";
import { Typography, Menu, TextField, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  searchAccounts,
  associateAccount,
  createAndAssociateAccount,
} from "services/speech";

function MeetingAccount(props) {
  const { classes, meetingAccount, meetingUuid } = props;
  const [account, setAccount] = useState(meetingAccount);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchedAccounts, setSearchedAccounts] = useState(null);
  const [error, setError] = useState(null);
  const [newAccountName, setNewAccountName] = useState("");

  const openAccountMenu = (event) => {
    setAccountMenuAnchor(event.currentTarget);
  };

  const closeAccountMenu = () => {
    setAccountMenuAnchor(null);
    setSearchValue("");
    setSearchedAccounts(null);
    setNewAccountName("");
    setError(null);
  };

  const handleSearch = () => {
    searchAccounts(searchValue).then((response) => {
      if (response.status !== 200) {
        setError("Something went wrong with searching");
        return;
      }
      setError(null);
      setSearchedAccounts(response.data);
    });
  };

  const handleCancelSearch = () => {
    setSearchValue("");
  };

  const handleSelect = (selectedAccount) => {
    associateAccount(selectedAccount.uuid, [meetingUuid]).then((response) => {
      if (response.status !== 200) {
        setError("Something went wrong with associating the account");
        return;
      }
      setAccount(response.data);
      closeAccountMenu();
    });
  };

  const handleNewAccount = () => {
    createAndAssociateAccount(newAccountName, meetingUuid).then((response) => {
      if (response.status !== 200) {
        setError(
          "Something went wrong with creating and associating the account"
        );
        return;
      }
      setAccount(response.data);
      closeAccountMenu();
    });
  };

  const renderError = () => {
    return (
      <Typography variant="body1" className={classes.copy} type="error">
        {error}
      </Typography>
    );
  };

  const renderEmpty = () => {
    return (
      <Typography variant="body1" className={classes.copy}>
        Search for client accounts in your organization
      </Typography>
    );
  };

  const renderNoAccounts = () => {
    return (
      <>
        <Typography variant="body1" className={classes.copy}>
          No client accounts found...
        </Typography>
        <div className={classes.newAccountContainer}>
          <Typography variant="body1" className={classes.newAccountCopy}>
            Create an account and associate it with this meeting
          </Typography>
          <TextField
            label="Account Name"
            value={newAccountName}
            onChange={(e) => setNewAccountName(e.target.value)}
            variant="filled"
            className={classes.newAccountInput}
            fullWidth
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewAccount}
            className={classes.newAccountButton}
          >
            Create Account
          </Button>
        </div>
      </>
    );
  };

  const renderAccounts = () => {
    return (
      <>
        <div className={classes.accountRow} type="header">
          <Typography className={classes.accountEntry} type="header">
            Account Name
          </Typography>
          <Typography className={classes.accountEntry} type="header">
            Account Lead
          </Typography>
        </div>
        <div className={classes.accountTable}>
          {searchedAccounts.map((acc) => (
            <div
              className={classes.accountRow}
              onClick={() => handleSelect(acc)}
            >
              <Typography className={classes.accountEntry}>
                {!acc.status && "(INACTIVE) "}
                {acc.name}
              </Typography>
              <Typography className={classes.accountEntry}>
                {acc.account_lead.name}
              </Typography>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={accountMenuAnchor}
        open={Boolean(accountMenuAnchor)}
        onClose={closeAccountMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ transform: "translateY(35px)" }}
      >
        <div className={classes.accountMenu}>
          <SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            onRequestSearch={handleSearch}
            onCancelSearch={handleCancelSearch}
            placeholder="Press Enter to Search"
            className={classes.searchBar}
          />
          <div className={classes.searchedContent}>
            {error
              ? renderError()
              : searchedAccounts == null
              ? renderEmpty()
              : searchedAccounts.length === 0
              ? renderNoAccounts()
              : renderAccounts()}
          </div>
        </div>
      </Menu>
    );
  };

  return (
    <div>
      <Typography className={classes.account} onClick={openAccountMenu}>
        {account ? account.name : "Account Name"}
        <ArrowDropDownIcon />
      </Typography>
      {renderMenu()}
    </div>
  );
}

export default withStyles(styles)(MeetingAccount);
