import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Replay5Icon from '@material-ui/icons/Replay5';
import Forward5Icon from '@material-ui/icons/Forward5';

import styles from './styles';

function CustomPictureInPicture(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  
  useEffect(() => {
    const playVideo = () => {
      setIsPlaying(true);
    };

    const pauseVideo = () => {
      setIsPlaying(false);
    };

    if (props.video) {
      props.video.addEventListener("play", playVideo);
      props.video.addEventListener("pause", pauseVideo);
      props.video.addEventListener("ended", pauseVideo);
    }

    return (() => {
      if (props.video) {
        props.video.removeEventListener("play", playVideo);
        props.video.removeEventListener("pause", pauseVideo);
        props.video.removeEventListener("ended", pauseVideo);
      }
    });
  }, [props.video]);

  useEffect(() => {
    if (props.video) {
      if (isPlaying) {
        props.video.play();
      } else {
        props.video.pause();
      }
    }
  }, [isPlaying, props.video]);

  const seek = (secs) => {
    if (props.video) {
      props.video.currentTime = props.video.currentTime + secs;
    }
  };
  
  const classes = props.classes;
  return (
    <div className={classes.pipContainer}>
      <div className={classes.buttonsContainer}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={props.exitCustomPictureInPicture}
        />
        <div className={classes.controlsContainer}>
          <Replay5Icon
            className={classes.controlButtonIcon}
            onClick={() => seek(-5)}
          />
          {isPlaying ? 
            <PauseIcon
              className={classes.controlButtonIcon}
              onClick={() => setIsPlaying(false)}
            /> : 
            <PlayArrowIcon
              className={classes.controlButtonIcon}
              onClick={() => setIsPlaying(true)}
            />
          }
          <Forward5Icon
            className={classes.controlButtonIcon}
            onClick={() => seek(5)}
          />
        </div>
      </div>
    </div>
  );
}

CustomPictureInPicture.propTypes = {
  classes: PropTypes.object.isRequired,
  video: PropTypes.instanceOf(Element).isRequired,
  exitCustomPictureInPicture: PropTypes.func,
}

export default withStyles(styles)(CustomPictureInPicture);