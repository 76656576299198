import { fade } from "@material-ui/core";
const keyTopicsColourDefault = "#2096f3";
const keyTopicsColourSelected = "#cedef5";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  overviewSection: {
    display: "flex",
    flexDirection: "column",
  },
  transcriptSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "100px",
  },
  header: {
    padding: theme.spacing.unit * 2,
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.background.grey,
  },
  title: {
    color: theme.palette.text.grey,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  content: {
    width: "100%",
    padding: theme.spacing.unit * 2,
  },
  footer: {
    padding: theme.spacing.unit * 0.5,
    marginTop: "auto",
    borderTop: `1px solid ${theme.palette.border}`,
    textAlign: "right",
  },
  keyTopicsContent: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  topicContainer: {
    margin: "16px 42px 16px 42px",
  },
  chip: {
    fontFamily: "Roboto",
    fontSize: "11px",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    direction: "rtl",
    textAlign: "center",
    color: "white",
    border: `1px solid ${keyTopicsColourDefault}`,
    borderRadius: "4px",
    boxShadow: "1px 1px 3px rgba(0,0,0,.5)",
    backgroundColor: keyTopicsColourDefault,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    width: "100%",
    textTransform: "none",
    "&.selected": {
      color: keyTopicsColourDefault,
      backgroundColor: fade(keyTopicsColourSelected, 0.3),
      "&:hover": {
        backgroundColor: fade(keyTopicsColourSelected, 0.3),
      },
    },
    "&:hover": {
      backgroundColor: keyTopicsColourDefault,
    },
  },
  actionItemTip: {
    background: theme.palette.background.lightGrey,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.border}`,
    padding: theme.spacing.unit * 2,
  },
  tipIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.primary.yellow,
  },
  tipText: {
    width: "90%",
    fontWeight: 350,
    fontFamily: "Roboto",
  },
  parent: {
    display: "flex",
    gap: theme.spacing.unit * 2,
  },
  contentColumn: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit * 2,
    flex: "1 1 0px",
  },
  transcriptBox: {
    position: "sticky",
    top: "79px", // This is because the page header is 63px + 16px for standard padding.
    height: "calc(100vh - 95px)", // The 79px above plus 16px for bottom padding.
    flex: "1 1 0px",
  },
});
