import { white, black } from '../common/colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  primary: {
    contrastText: white,
    main: '#0767DB',
    light: '#F6F9FD',
    dark: '#0B48A0',
    purple: '#9d74ea',
    darkPurple: "#4c3ca6",
    cyan: '#10baca',
    pink: '#ca1080',
    brown: '#cc9966',
    lightBlue: '#ADD8E6',
    yellow: '#ffdf69',
    orange: "#f0914d",
    red: '#ff0000',
    green: '#008000',
    lightGreen: '#44b77f',
    grey: '#a5a5ae',
  },
  secondary: {
    contrastText: white,
    main: '#7d58ff',
    light: '',
    dark: '#37248F'
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08',
    mellow: '#FF6961',
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#A6B1BB',
    success: '#00783E',
    danger: '#BF0E08',
    main: '#0767DB',
    lightGrey: "#6b6d70",
    grey: '#56626e',
    darkGrey: "#4b4b5b",
    dark: '#0B0B45',
    light: "#9f9fc2",
  },
  background: {
    default: '#f8fafc',
    dark: '#172B4D',
    paper: white,
    grey: '#eeeeee',
    lightGrey: '#fafafa',
    darkerLightGrey: '#f7f9fd',
    purple: '#e5e5ff',
    blue: "#e7f0f9",
    green: "#dff7ea",
    red: "#ffe9e5",
  },
  elements: {
    takeaways: {
      normal: "#ffdfb8",
      dark: "#edcfab",
      saturated: "#fab86e",
    },
    wins: {
      normal: "#ffe6e7",
      dark: "#ccb4b5",
    },
    opportunities: {
      normal: "#d9f6ee",
      dark: "#a7c3bc",
    },
    questions: { 
      normal: "#dee6fd",
      dark: "#acb4ca",
      saturated: "#5e81f5",
    },
    answers: { 
      normal: "#fffcd0",
      dark: "#ccc99f",
      saturated: "#cc9966",
    },
    topics: {
      normal: "#cedef5",
      dark: "#9dacc2",
    },
    actionItem: {
      normal: "#ffddc1",
      dark: "#cbab91",
    },
    currentInsight: {
      normal: "#acbcff",
      dark: "#7b8ccc",
      lightGrey: "#f3f3ff",
      blue: "#3a7be0",
      darkGrey: "#9199a1",
    },
  },
  border: '#DFE3E8',
  divider: '#DFE3E8',
  highlight: "#e5effc",
  menuSelect: "#f5f5f5",
  //  Colour scheme matching the preset nivo dark2 scheme: https://nivo.rocks/guides/colors/
  nivoDark2: [
    "#1b9e77",
    "#d95f02",
    "#7570b3",
    "#e7298a",
    "#66a61e",
    "#e6ab02",
    "#a6761d",
    "#666666",
  ],
};
