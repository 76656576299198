import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

import { checkStatuses } from "helpers/discount";
import { checkDiscount } from "services/speech";

// Material helpers
import { withStyles } from "@material-ui/core";

import { SeatNumberWidget, CreditCardWidget } from "..";

import styles from "./styles";

import AwesomeDebouncePromise from "awesome-debounce-promise";

class CheckoutForm extends Component {
  state = {
    error: null,
    discountCode: "",
    discount: {},
    discountCheckStatus: checkStatuses.none,
  };
  checkDiscountDebounced = AwesomeDebouncePromise(checkDiscount, 1000);

  handleDiscountCodeChange = (event) => {
    const discountCode = event.target.value;
    this.setState({
      discountCode: discountCode,
      error: null,
      discountCheckStatus: checkStatuses.checking,
    });
    if (discountCode === "") {
      this.setState({ discount: {}, discountCheckStatus: "" });
    } else {
      this.checkDiscountDebounced(discountCode).then((response) => {
        if (response.status === 200) {
          this.setState({
            discount: response.data,
            discountCheckStatus: checkStatuses.valid,
          });
        } else {
          this.setState({
            discount: {},
            discountCheckStatus: checkStatuses.invalid,
          });
        }
      });
    }
  };

  handleErrorChange = (error) => {
    this.setState({ error: error });
  };

  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        {this.props.renderSeatWidget && (
          <SeatNumberWidget
            numOfUsers={this.props.numOfUsers}
            discountCode={this.state.discountCode}
            discount={this.state.discount}
            discountCheckStatus={this.state.discountCheckStatus}
            price={this.props.price}
            amountOff={this.props.amountOff}
            percentOff={this.props.percentOff}
            pricingTier={this.props.pricingTier}
            handleNumOfUsersChange={this.props.handleNumOfUserChange}
            handleDiscountCodeChange={this.handleDiscountCodeChange}
            renderSave={!this.props.isCheckout}
            updateSubscriptionDetails={this.props.updateSubscriptionDetails}
            applyDiscountToSubscription={this.props.applyDiscountToSubscription}
            handleChangePricingTier={this.props.handleChangePricingTier}
            billedAnnually={this.props.billedAnnually}
            handleToggleAnnualBilling={this.props.handleToggleAnnualBilling}
            switchToPricingTier={this.props.switchToPricingTier}
          />
        )}
        {this.props.renderCardWidget && (
          <CreditCardWidget
            error={this.state.error}
            numOfUsers={this.props.numOfUsers}
            discountCode={this.state.discountCode}
            subscriptionStatus={this.props.subscriptionStatus}
            stripe={this.props.stripe}
            elements={this.props.elements}
            numOfMinLicenses={this.props.numOfMinLicenses}
            createNewSubscription={this.props.createNewSubscription}
            updateSubscriptionWithNewPaymentMethod={
              this.props.updateSubscriptionWithNewPaymentMethod
            }
            handleErrorChange={this.handleErrorChange}
          />
        )}
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckoutForm);
