import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: { position: "relative", height: "100%" },
  rootContent: {},
  container: {
    height: "100%",
  },
  refreshButton: {
    margin: -theme.spacing.unit * 2,
  },
  chartWrapper: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing.unit,
  },
  deviceIcon: {
    color: theme.palette.common.neutral,
  },
  content: {
    width: "100%",
    padding: theme.spacing.unit * 2,
  },
  header: {
    padding: "5px",
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.elements.currentInsight.lightGrey,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.text.grey,
    fontWeight: 700,
    margin: "10px",
    lineHeight: "22px",
  },
  value: {
    marginTop: theme.spacing.unit,
    color: theme.palette.success.dark,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  footer: {
    alignItems: "center",
  },
  difference: {
    alignItems: "center",
    color: theme.palette.danger.dark,
    display: "inline-flex",
    fontWeight: 700,
  },
  treeRoot: {
    marginTop: theme.spacing.unit * 4,
  },
  actionItem: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit / 2,
  },
  actionItemText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  phraseText: {
    color: theme.palette.text.secondary,
  },
  phrase: {
    color: theme.palette.text.secondary,
    margin: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
  },
  phraseDeleteButton: {
    marginLeft: "auto",
  },
  taskTitle: {
    display: "flex",
    alignItems: "center",
  },
  taskWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  actionItemContainer: {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
  },
  actionItemTreeItem: {
    "&:hover .iconOption": {
      visibility: "visible !important",
    },
  },
  actionItemOwnerSelect: {
    color: theme.palette.text.main,
    width: 150,
    height: 30,
    marginLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  divider: {
    marginBottom: theme.spacing.unit * 2,
  },
  progress: {
    height: 30,
    marginBottom: theme.spacing.unit,
  },
  actionItemEditingTask: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    width: "90%",
    height: "auto",
    resize: "none",
    padding: theme.spacing.unit,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
  notifyActionItemContainer: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  notifyActionItemHelpIcon: {
    color: fade(theme.palette.text.secondary, 0.5),
  },
  notifyActionItemButton: {
    fontSize: "14px",
    padding: "3px 9px",
  },
  showActionItemContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  actionItemTip: {
    background: theme.palette.background.lightGrey,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.border}`,
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  tipIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.primary.yellow,
  },
  tipText: {
    width: "85%",
    fontWeight: 350,
  },
  closeIcon: {
    marginLeft: "auto",
    width: "16px",
    height: "16px",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.danger.main,
    },
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  scrollForMore: {
    position: "absolute",
    bottom: "0px",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: "center",
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  scrollForMoreContent: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  tab: {
    color: theme.palette.elements.currentInsight.darkGrey,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  aiLightbulbIcon: {
    color: fade(theme.palette.text.secondary, 0.5),
    marginLeft: theme.spacing.unit,
  },
  headerWithIcon: {
    display: "inline-flex",
    alignItems: "center",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
  },
  addIcon: {
    color: theme.palette.elements.currentInsight.blue,
  },
  shareIcon: {
    color: theme.palette.elements.currentInsight.blue,
  },
  actionItemOwner: {
    display: "flex",
    justifyContent: "space-between",
  },
});
