export default (theme) => ({
  caption: {
    whiteSpace: "initial",
    overflow: "auto",
  },
  field: {
    paddingTop: theme.spacing.unit * 2,
  },
  fieldError: {
    color: "red",
    marginTop: theme.spacing.unit * 0.5,
  },
  textField: {
    color: "black",
    width: "100%",
  },
  labelField: {
    width: "100%",
    color: theme.palette.text.lightGrey,
  },
  snackbar: {
    fontSize: 16,
    lineHeight: 1.6,
  },
  joinMeetingButton: {
    marginLeft: theme.spacing.unit * 2,
  },
});
