import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { Tooltip, withStyles } from "@material-ui/core";

// Material components
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Snackbar,
  AppBar,
  Tab,
  Tabs,
  Box,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Shared services
import {
  getAccounts,
  getContacts,
  getProfile,
  getOrg,
  deleteContact,
} from "services/speech";

// Shared components
import { Portlet, PortletContent, PortletLabel } from "components";

import { isOnboardingComplete } from "helpers/user";

// Component styles
import styles from "./styles";

import { pricingTiers, meetingLimits } from "helpers";

import ReactGA from "react-ga";

import queryString from "query-string";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Delete,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  ViewColumn,
} from "@material-ui/icons";
import { ClientAccountDialog } from "./components";
import ContactDialog from "./components/ContactDialog";
import DeleteDialog from "components/DeleteDialog";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      class={other.class}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px" }} width={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class ClientAccounts extends Component {
  state = {
    isLoading: false,
    accounts: [],
    contacts: [],
    orgMembers: [],
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    tab: 0,
    openEditDialog: false,
    newAccountTitle: "",
    openDeleteDialog: false,
    account: null,
    contact: null,
  };

  tabIndex = {
    accounts: 0,
    contacts: 1,
  };

  componentWillMount() {
    this.getMyProfile();
  }

  componentDidMount() {
    const { tab } = queryString.parse(this.props.location.search);
    if (tab === "contacts") {
      this.handleTabChange(this.tabIndex.contacts);
    }
  }

  getMyProfile = () => {
    this.setState({
      isLoading: true,
    });
    getProfile().then(
      function(response) {
        this.setState({
          isLoading: false,
        });
        this.refresh(this.state.tab);
      }.bind(this)
    );
  };

  goToSetup = () => {
    this.props.history.push("/setup");
  };

  // handleSelect = (meetingId, status, isMeetingOwner = true) => {
  //   let eventAction =
  //     this.state.tab === this.tabIndex.scheduled
  //       ? "User Clicked on Meeting (Scheduled)"
  //       : "User Clicked on Meeting (Completed)";
  //   ReactGA.event({
  //     category: "My Meetings",
  //     action: eventAction,
  //     value: meetingId,
  //   });
  //   this.props.history.push({
  //     pathname: "/accounts/",
  //     state: { id: meetingId, isMeetingOwner: isMeetingOwner },
  //   });
  // };

  refresh = (currentTabIndex) => {
    this.getAccounts();
    this.getOrgMembers();
    this.getContacts();
  };

  handleTabChange = (newTabIndex) => {
    this.setState({ tab: newTabIndex });

    var eventAction = "User clicked Accounts tab";
    if (newTabIndex === this.tabIndex.contacts) {
      eventAction = "User clicked Contacts tab";
    }
    this.refresh(newTabIndex);
    ReactGA.event({
      category: "Client Accounts",
      action: eventAction,
    });
  };

  renderAlerts = () => {
    const pricingTier = localStorage.getItem("pricingTier");
    const { classes } = this.props;

    if (!isOnboardingComplete()) {
      return (
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Portlet>
            <PortletContent className={classes.alertContent}>
              <Typography className={classes.alertCaption} variant="h6">
                Make the most of Sonero by completing your account setup!
              </Typography>
              <Button
                className={classes.alertButton}
                color="primary"
                size="small"
                variant="contained"
                onClick={this.goToSetup}
              >
                SETUP
              </Button>
            </PortletContent>
          </Portlet>
        </Grid>
      );
    }

    if (pricingTier !== pricingTiers.free) {
      return;
    }

    if (this.state.limitReached) {
      return (
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Portlet>
            <PortletContent className={classes.alertContent}>
              <Typography className={classes.alertCaption} variant="h6">
                You’ve reached the free tier limit.
              </Typography>
            </PortletContent>
          </Portlet>
        </Grid>
      );
    }
    return (
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="h6">
              {`You are using Sonero Free tier. You get free analysis for 
                ${meetingLimits.freeTier.numMeetings} meetings per month with a maximum 
                duration of ${meetingLimits.freeTier.duration} minutes each. 
                You may upgrade to Sonero Pro tier for unlimited usage in 
                the Billing page.`}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
    );
  };

  renderAccountsTable() {
    const { classes } = this.props;
    const { isLoading, accounts } = this.state;

    if (isLoading) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </PortletContent>
        </Portlet>
      );
    }

    const actions = [
      {
        icon: () => <Edit className={classes.actionIcon} />,
        tooltip: "Edit Account",
        onClick: (event, rowData) =>
          this.setState({
            openCreateAccountDialog: true,
            account: rowData,
          }),
      },
    ];

    if (accounts.length === 0) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="body1">
              You don't have any accounts, add one to get started!
            </Typography>
            <Button
              onClick={() =>
                this.setState({
                  account: null,
                  openCreateAccountDialog: true,
                })
              }
              color="primary"
              variant="contained"
              size="small"
            >
              New Account
            </Button>
          </PortletContent>
        </Portlet>
      );
    }

    const myTableRef = React.createRef();

    return (
      <Portlet>
        <MaterialTable
          tableRef={myTableRef}
          icons={tableIcons}
          title={<PortletLabel title="" />}
          columns={[
            {
              title: "Account",
              field: "title",
              render: (rowData) => this.getTitle(rowData.name, classes),
            },
            {
              title: "Account Lead",
              field: "owner",
              render: (rowData) =>
                this.renderProjectLeadColumn(
                  rowData.account_lead.name,
                  classes
                ),
            },
            {
              title: "Contacts",
              field: "contacts",
              render: (rowData) =>
                this.getContactsInfo(rowData.contacts, classes),
            },
            {
              title: "Last Communication",
              field: "last_communication",
              render: (rowData) =>
                this.getLastCommunication(rowData.last_communication, classes),
            },
            {
              title: "Status",
              field: "status",
              render: (rowData) =>
                this.renderStatusColumn(rowData.status, classes),
            },
          ]}
          data={accounts}
          options={{
            actionsColumnIndex: -1,
            rowStyle: {
              fontFamily: "Roboto",
              fontSize: "14px",
            },
            pageSize: 20,
            emptyRowsWhenPaging: false,
            toolbarButtonAlignment: "left",
          }}
          localization={{
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage: "",
            },
            toolbar: {
              searchPlaceholder: "Search",
            },
          }}
          style={{
            boxShadow: "none",
          }}
          actions={actions}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ marginLeft: "24px" }}
                  onClick={() =>
                    this.setState({
                      account: null,
                      openCreateAccountDialog: true,
                    })
                  }
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  New Account
                </Button>
                <MTableToolbar
                  {...props}
                  classes={{ root: classes.tableToolbar }}
                />
              </div>
            ),
          }}
        />
      </Portlet>
    );
  }

  getTitle = (name, classes) => {
    return (
      <div>
        <p>{name}</p>
      </div>
    );
  };

  getContactsInfo = (contacts, classes) => {
    var contactsElements = contacts.map(function(contact) {
      return (
        <li>
          <Tooltip title={contact.email}>
            <span className={classes.contactName}>{contact.name}</span>
          </Tooltip>
        </li>
      );
    });
    return <div className={classes.contactsCell}>{contactsElements}</div>;
  };

  getLastCommunication = (lastCommunication, classes) => {
    if (lastCommunication != null) {
      var localDate = new Date(lastCommunication);
      return localDate.toLocaleString();
    }
    return "";
  };

  renderProjectLeadColumn = (owner, classes) => {
    return (
      <div>
        <p>{owner}</p>
      </div>
    );
  };

  renderStatusColumn = (status, classes) => {
    return (
      <div>
        <p>{status ? "Active" : "Inactive"}</p>
      </div>
    );
  };

  renderContactsTable() {
    const { classes } = this.props;
    const { isLoading, contacts } = this.state;

    if (isLoading) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </PortletContent>
        </Portlet>
      );
    }

    if (contacts.length === 0) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="body1">
              You don't have any contacts, add one to get started!
            </Typography>
            <Button
              onClick={() =>
                this.setState({
                  openCreateContactDialog: true,
                })
              }
              color="primary"
              variant="contained"
              size="small"
            >
              New Contact
            </Button>
          </PortletContent>
        </Portlet>
      );
    }

    const actions = [
      {
        icon: () => <Delete className={classes.destructiveActionIcon} />,
        tooltip: "Delete Contact",
        onClick: (event, rowData) =>
          this.setState({
            openDeleteDialog: true,
            contact: rowData,
          }),
      },
    ];

    const myTableRef = React.createRef();

    return (
      <Portlet>
        <MaterialTable
          tableRef={myTableRef}
          icons={tableIcons}
          title={<PortletLabel title="" />}
          columns={[
            {
              title: "Name",
              field: "name",
              render: (rowData) => this.getContactName(rowData, classes),
            },
            {
              title: "Email",
              field: "email",
              render: (rowData) => this.getContactEmail(rowData, classes),
            },
          ]}
          data={contacts}
          options={{
            actionsColumnIndex: -1,
            rowStyle: {
              fontFamily: "Roboto",
              fontSize: "14px",
            },
            pageSize: 20,
            emptyRowsWhenPaging: false,
            toolbarButtonAlignment: "left",
          }}
          localization={{
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage: "",
            },
            toolbar: {
              searchPlaceholder: "Search",
            },
          }}
          style={{
            boxShadow: "none",
          }}
          actions={actions}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ marginLeft: "24px" }}
                  onClick={() =>
                    this.setState({
                      openCreateContactDialog: true,
                    })
                  }
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  New Contact
                </Button>
                <MTableToolbar
                  {...props}
                  classes={{ root: classes.tableToolbar }}
                />
              </div>
            ),
          }}
        />
      </Portlet>
    );
  }

  getContactName = (data, classes) => {
    return (
      <div>
        <p>{data.name}</p>
      </div>
    );
  };

  getContactEmail = (data, classes) => {
    return (
      <div>
        <p>{data.email}</p>
      </div>
    );
  };

  renderTabs() {
    const { error } = this.state;

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    return (
      <Grid>
        <AppBar position="static" color="default" style={{ flexGrow: 1 }}>
          <Tabs
            value={this.state.tab}
            onChange={(_event, newValue) => this.handleTabChange(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Accounts" />
            <Tab label="Contacts" />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.tab} index={this.tabIndex.accounts}>
          {this.renderAccountsTable()}
        </TabPanel>
        <TabPanel value={this.state.tab} index={this.tabIndex.contacts}>
          {this.renderContactsTable()}
        </TabPanel>
      </Grid>
    );
  }

  handleOpenDeleteDialog = () => {
    this.setState({ openDeleteDialog: true, menuAnchorEl: null });
  };

  handleDelete = () => {
    if (this.state.openDeleteDialog && this.state.contact) {
      this.deleteContact(this.state.contact);
    }
  };

  renderDeleteDialog = () => {
    const { classes } = this.props;
    var type = "";
    var objectTitle = "";
    if (this.state.account) {
      type = "Account";
      objectTitle = this.state.account.name;
    } else if (this.state.contact) {
      type = "Contact";
      objectTitle = this.state.contact.name;
    }

    return (
      <DeleteDialog
        classes={classes}
        open={this.state.openDeleteDialog}
        type={type}
        objectTitle={objectTitle}
        onClose={this.handleCloseDeleteDialog}
        openSnackbar={this.openSnackbar}
        handleDelete={this.handleDelete}
      />
    );
  };

  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleOpenEditAccountDialog = (account) => {
    this.setState({ openCreateAccountDialog: true, account: account });
  };

  handleCloseAccountDialog = () => {
    this.setState({ openCreateAccountDialog: false, account: null });
  };

  handleCloseContactDialog = () => {
    this.setState({ openCreateContactDialog: false });
  };

  handleCreateAccount = (account) => {
    var accounts = this.state.accounts;
    accounts.unshift(account);
    this.setState({ accounts: accounts });
  };

  handleCreateContact = (contact) => {
    var contacts = this.state.contacts;
    contacts.unshift(contact);
    this.setState({ contacts: contacts });
  };

  handleUpdateAccount = (account) => {
    var accounts = this.state.accounts.map((object) => {
      if (object.uuid === account.uuid) return account;
      return object;
    });
    this.setState({ accounts: accounts });
  };

  renderAccountDialog = () => {
    const { classes } = this.props;
    var accountUuid = null;
    var accountName = null;
    var projectLead = null;
    var status = true;
    var contacts = [];
    if (this.state.account) {
      accountUuid = this.state.account.uuid;
      accountName = this.state.account.name;
      projectLead = {
        name: this.state.account.account_lead.name,
        email: this.state.account.account_lead.email,
        user_id: this.state.account.account_lead.id,
      };
      status = this.state.account.status;
      contacts = this.state.account.contacts;
    }
    return (
      <ClientAccountDialog
        classes={classes}
        open={this.state.openCreateAccountDialog}
        accountUuid={accountUuid}
        accountName={accountName}
        projectLead={projectLead}
        status={status}
        contacts={contacts}
        projectLeadOptions={this.state.orgMembers}
        contactsOptions={this.state.contacts}
        onClose={this.handleCloseAccountDialog}
        openSnackbar={this.openSnackbar}
        setLoading={this.setLoading}
        handleUpdateAccount={this.handleUpdateAccount}
        handleCreateAccount={this.handleCreateAccount}
      />
    );
  };

  renderContactDialog = () => {
    const { classes } = this.props;
    return (
      <ContactDialog
        classes={classes}
        open={this.state.openCreateContactDialog}
        onClose={this.handleCloseContactDialog}
        openSnackbar={this.openSnackbar}
        setLoading={this.setLoading}
        handleCreateContact={this.handleCreateContact}
      />
    );
  };

  setLoading = (loading) => {
    this.setState({ isLoading: loading });
  };

  renameAccount = (title, uuid) => {
    var { accounts } = this.state;
    accounts.forEach((account) => {
      if (account.uuid === uuid) {
        account.title = title;
      }
    });
    this.setState({ accounts: accounts });
  };

  addSnackbar = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Snackbar
        open={this.state.snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackbarClose}
        ContentProps={{
          className: classes.snackbar,
        }}
        message={this.state.snackbarMessage}
        action={
          <Button
            style={{ color: "white" }}
            size="small"
            onClick={this.handleSnackbarClose}
          >
            CLOSE
          </Button>
        }
      />
    );
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  deleteContact = (contact) => {
    this.setState({ isLoading: true, openDeleteDialog: false });
    deleteContact(contact.uuid).then(
      function(response) {
        if (response.status === 200) {
          var contacts = this.state.contacts.filter(
            (obj) => obj.uuid !== contact.uuid
          );

          this.setState({
            isLoading: false,
            contacts,
          });

          ReactGA.event({
            category: "Contacts",
            action: "Delete contact API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't delete the contact because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Delete contact API failed",
          });
        }
      }.bind(this)
    );
  };

  getAccounts = () => {
    this.setState({ isLoading: true });
    getAccounts().then(
      function(response) {
        if (response.status === 200) {
          var accounts = response.data;

          this.setState({
            isLoading: false,
            accounts,
          });

          ReactGA.event({
            category: "Client Accounts",
            action: "Get accounts API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your accounts because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get accounts API failed",
          });
        }
      }.bind(this)
    );
  };

  getOrgMembers = () => {
    this.setState({ isLoading: true });
    getOrg().then(
      function(response) {
        if (response.status === 200) {
          var orgMembers = response.data.members;

          this.setState({
            isLoading: false,
            orgMembers,
          });

          ReactGA.event({
            category: "Client Accounts",
            action: "Get org members API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your accounts because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get org members API failed",
          });
        }
      }.bind(this)
    );
  };

  getContacts = () => {
    this.setState({ isLoading: true });
    getContacts().then(
      function(response) {
        if (response.status === 200) {
          var contacts = response.data;

          this.setState({
            isLoading: false,
            contacts,
          });

          ReactGA.event({
            category: "Contacts",
            action: "Get contacts API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your contacts because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get contacts API failed",
          });
        }
      }.bind(this)
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Accounts">
        <div className={classes.root}>
          <div className={classes.content}>
            <Grid container spacing={4}>
              {this.renderAlerts()}
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {this.renderTabs()}
              </Grid>
            </Grid>
          </div>
        </div>
        {this.renderAccountDialog()}
        {this.renderContactDialog()}
        {this.renderDeleteDialog()}
        {this.addSnackbar()}
      </DashboardLayout>
    );
  }
}

ClientAccounts.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientAccounts);
