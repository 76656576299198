export default (theme) => ({
  root: {},
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  videoWrapper: {
    height: "300px",
    position: "relative",
    maintainAspectRatio: true,
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  videoRecorder: {
    width: "100%",
    height: "100%",
    responsive: true,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  audioPiPButton: {
    position: "absolute",
    bottom: "100px",
    left: 0,
    cursor: "pointer",
    padding: "5px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    width: "30px",
    height: "30px",
    overflow: "hidden",
    transform: "scale(1.2, 1.2)",
    transition: "width 0.25s",
    "& > div": {
      height: "30px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      width: "150px",
    },
  },
  hiddenVideo: {
    display: "none",
    visibility: "hidden",
    position: "absolute",
  },
  recordButton: {
    "& > span": {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  },
});
