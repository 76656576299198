import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import theme from "theme";

import { Paper } from "components";
import {
  Analytics,
  FAQ,
  Meetings,
  MeetingScore,
  Trackers,
} from "../../../Dashboard/components";
import DatePicker from "../DatePicker";
import {
  Box,
  Button,
  Snackbar,
  CircularProgress,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EventNoteIcon from "@material-ui/icons/EventNote";

import { getDashboardData, getDashboardFAQ } from "services/speech";
import { getDateRangeString } from "helpers/time";
import { pricingTiers, verifyUserPricingTier } from "helpers/pricingTiers";

const dateFormat = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};

class MemberDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pastMeetings: [],
      upcomingMeetings: [],
      meetingScore: {},
      trackers: [],
      constants: {},
      snackbarMessage: "",
      autoHideDuration: "5000",
      isLoading: true,
      faq: null,
      datePickerAnchor: null,
    };
  }
  meetingsBoxRef = React.createRef();
  faqBoxRef = React.createRef();
  scoreBoxRef = React.createRef();

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.updateDashboardData(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    // Update the height of the meetings widget based on the widget next to it
    if (this.meetingsBoxRef.current && this.faqBoxRef.current) {
      const height = this.faqBoxRef.current.offsetHeight;
      this.meetingsBoxRef.current.style.height = `${height}px`;
    }

    // Re-retrieve data when user changes or when date range changes
    if (
      prevProps.id !== this.props.id ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      const { startDate, endDate } = this.props;
      this.updateDashboardData(startDate, endDate);
    }
  }

  updateDashboardData = (startDate, endDate) => {
    this.setState({ isLoading: true, faq: null });
    if (startDate) startDate = startDate.toISOString();
    if (endDate) endDate = endDate.toISOString();
    getDashboardData(startDate, endDate, this.props.id).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          this.props.history.push("/sign-in");
          return;
        }
        this.handleSnackbarOpen(
          "Sorry, but an unknown error occurred. Please try again."
        );
        return;
      }
      const data = response.data.data;
      const constants = response.data.constants;
      const pastMeetings = data.past_meetings;
      const upcomingMeetings = data.upcoming_meetings;
      const meetingScore = data.meeting_score;
      const trackers = data.trackers;
      if (pastMeetings) {
        for (var index in pastMeetings) {
          if (pastMeetings[index].meeting_type !== "demo") {
            this.onlyDemoOrEmpty = false;
            break;
          }
        }
      }

      this.setState({
        pastMeetings,
        upcomingMeetings,
        meetingScore,
        trackers,
        constants,
        isLoading: false,
      });
    });

    getDashboardFAQ(startDate, endDate, this.props.id).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          this.props.history.push("/sign-in");
        }
        this.setState({ faq: { asked_by_me: [], asked_by_attendee: [] } });
        return;
      }
      this.setState({ faq: response.data.data });
    });
  };

  handleNavigateToMeeting = (meetingUuid) => {
    this.props.history.push(`/view-meeting/${meetingUuid}`);
  };

  handleCloseDatePicker = () => {
    this.setState({ datePickerAnchor: null });
  };

  handleSnackbarOpen = (message) => {
    this.setState({ snackbarMessage: message });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarMessage: "" });
  };

  render() {
    const {
      classes,
      id,
      members,
      handleBack,
      handleChangeMember,
      handleDateChange,
      startDate,
      endDate,
    } = this.props;
    const {
      pastMeetings,
      upcomingMeetings,
      meetingScore,
      trackers,
      faq,
      constants,
      isLoading,
      datePickerAnchor,
    } = this.state;
    const memberInfo = members[id] ?? "Member";

    return (
      <div className={classes.dashboardContainer}>
        <header className={classes.header}>
          <div className={classes.headerLeft}>
            <IconButton onClick={handleBack}>
              <ArrowBackIcon color="primary" />
            </IconButton>
            <Typography>
              {memberInfo.name}'s calls{" "}
              {getDateRangeString(startDate, endDate, dateFormat)}
            </Typography>
            <IconButton
              onClick={(event) =>
                this.setState({ datePickerAnchor: event.currentTarget })
              }
            >
              <EventNoteIcon color="primary" />
            </IconButton>
          </div>
          <div className={classes.headerRight}>
            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="member-select-label">Member</InputLabel>
              <Select
                labelId="member-select-label"
                id="member-select"
                value={id}
                label="Member"
                onChange={(e) => handleChangeMember(e.target.value)}
                classes={{ root: classes.selectRoot }}
              >
                {Object.keys(members).map((id) => (
                  <MenuItem value={id}>{members[id].name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </header>
        <DatePicker
          anchor={datePickerAnchor}
          startDate={startDate}
          endDate={endDate}
          handleCloseDatePicker={this.handleCloseDatePicker}
          handleDateChange={handleDateChange}
        />
        {!isLoading ? (
          <Box
            ref={this.dashboardRef}
            className={classes.dashboardGrid}
            display="grid"
            gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            gridAutoRows="auto"
            gridGap={theme.spacing.unit * 4}
          >
            <Box ref={this.meetingsBoxRef}>
              <Paper className={classes.dashboardSection}>
                <Meetings
                  pastMeetings={pastMeetings}
                  upcomingMeetings={upcomingMeetings}
                  history={this.props.history}
                  handleNavigateToMeeting={this.handleNavigateToMeeting}
                  handleSnackbarOpen={this.handleSnackbarOpen}
                  startDate={startDate}
                  endDate={endDate}
                  memberView={true}
                />
              </Paper>
            </Box>
            <Box ref={this.faqBoxRef}>
              <Paper className={classes.dashboardSection}>
                <FAQ faq={faq} history={this.props.history} />
              </Paper>
            </Box>
            <Box ref={this.scoreBoxRef}>
              <Paper className={classes.dashboardSection}>
                <MeetingScore
                  pastMeetings={pastMeetings}
                  meetingScore={meetingScore}
                  handleNavigateToMeeting={this.handleNavigateToMeeting}
                  startDate={startDate}
                  endDate={endDate}
                  history={this.props.history}
                  memberName={memberInfo.name}
                />
              </Paper>
            </Box>
            {!verifyUserPricingTier(pricingTiers.free) && (
              <Box>
                <Paper
                  className={classes.dashboardSection}
                  style={{ overflow: "visible" }}
                >
                  <Trackers
                    pastMeetings={pastMeetings}
                    trackers={trackers}
                    history={this.props.history}
                  />
                </Paper>
              </Box>
            )}
            <Box>
              <Paper className={classes.dashboardSection}>
                <Analytics
                  meetings={pastMeetings}
                  handleNavigateToMeeting={this.handleNavigateToMeeting}
                  constants={constants}
                  startDate={startDate}
                  endDate={endDate}
                  memberEmail={memberInfo.email}
                />
              </Paper>
            </Box>
          </Box>
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        <Snackbar
          open={this.state.snackbarMessage !== ""}
          autoHideDuration={this.state.autoHideDuration}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar,
          }}
          message={this.state.snackbarMessage}
          action={
            <Button
              className={classes.snackbarActionButton}
              size="small"
              onClick={this.handleSnackbarClose}
            >
              CLOSE
            </Button>
          }
        />
      </div>
    );
  }
}

MemberDashboard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MemberDashboard);
