import { fade } from "@material-ui/core";

const highlightVarianceColor = "rgba(84, 99, 255, .15)";
const highlightPositiveColor = "rgba(101, 255, 93, .15)";
const highlightNegativeColor = "rgba(255, 90, 88, .15)";
const hoveredHighlightVarianceColor = "rgba(0, 0, 255, .4)";
const hoveredHighlightPositiveColor = "rgba(0, 255, 0, .4)";
const hoveredHighlightNegativeColor = "rgba(255, 0, 0, .4)";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  firstContent: {
    alignItems: "center",
    display: "flex",
  },
  content: {
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "Roboto",
    height: 450,
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  widgetGrid: {
    display: "grid",
    gridTemplateColumns: "33% 66%",
    padding: theme.spacing.unit * 2,
  },
  gridToggle: {
    gridColumn: 1,
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridAutoFlow: "row",
    gap: theme.spacing.unit * 0.5,
    margin: "auto",
    "@media (max-width: 500px)": {
      gridTemplateColumns: "auto",
    },
  },
  gridPie: {
    gridColumn: 1,
  },
  gridTimeline: {
    gridColumn: 2,
    gridRow: "1 / span 2",
  },
  "@media (max-width: 750px)": {
    gridToggle: {
      gridColumn: 1,
      gridRow: 1,
    },
    gridPie: {
      gridColumn: 2,
      gridRow: 1,
    },
    gridTimeline: {
      gridColumn: "1 / span 2",
      gridRow: 2,
    },
  },
  speakerToggle: {
    fontFamily: "Roboto",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
    padding: theme.spacing.unit,
    borderRadius: "5px",
    cursor: "pointer",
    "& span": {
      display: "inline-block",
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      backgroundColor: "lightblue",
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
    "&.active": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
    },
  },
  tooltipOverlay: {
    gridColumn: 1,
    gridRow: 1,
    fontFamily: "Roboto",
    width: "100%",
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing.unit * 2,
    borderRadius: "7px",
    width: "100%",
    height: "100%",
  },
  tooltipHeader: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
  },
  sentimentLabel: {
    padding: theme.spacing.unit,
    borderRadius: "5px",
    color: theme.palette.common.white,
    "&.Positive": {
      backgroundColor: theme.palette.success.main,
    },
    "&.Negative": {
      backgroundColor: theme.palette.danger.main,
    },
    "&.Neutral": {
      backgroundColor: theme.palette.text.grey,
    },
  },
  momentLabel: {
    padding: theme.spacing.unit,
    borderRadius: "5px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.darkPurple,
  },
  tooltipText: {
    fontSize: "16px",
    "& > span": {
      fontWeight: "600",
      color: theme.palette.primary.main,
    },
  },
  snippetSpeaker: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit * 3,
  },
  snippetBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    marginLeft: theme.spacing.unit * 3,
    "& > p": {
      marginBottom: theme.spacing.unit,
      lineHeight: "1.5",
    },
  },
  highlightOverlay: {
    position: "absolute",
    left: "60px",
    top: "20px",
    width: "calc(100% - 80px)",
    height: "calc(100% - 80px)",
  },
  highlightPositive: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: highlightPositiveColor,
  },
  highlightNegative: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: highlightNegativeColor,
  },
  highlightVariance: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: highlightVarianceColor,
  },
  hoveredHighlightPositive: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: hoveredHighlightPositiveColor,
  },
  hoveredHighlightNegative: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: hoveredHighlightNegativeColor,
  },
  hoveredHighlightVariance: {
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: hoveredHighlightVarianceColor,
  },
  timelineTooltip: {
    position: "absolute",
    top: "340px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    borderRadius: "5px",
    padding: theme.spacing(1),
    fontSize: "16px",
    fontWeight: 450,
    width: "210px",
    textAlign: "center",
    boxShadow: `0 3px 5px ${fade(theme.palette.common.black, 0.5)}`,
  },
});
