export default (theme) => ({
  root: {},
  dashboardContainer: {
    padding: theme.spacing.unit * 4,
  },
  dashboardSection: {
    fontFamily: "Roboto",
    height: "100%",
    borderRadius: "15px",
    overflow: "hidden",
    zIndex: 2,
  },
  snackbar: {
    height: "auto",
    fontSize: "16px",
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  snackbarActionButton: {
    color: "white",
  },
  emptyView: {
    margin: theme.spacing.unit * 16,
    marginLeft: theme.spacing.unit * 12,
    marginRight: theme.spacing.unit * 12,
  },
  emptyText: {
    color: theme.palette.text.secondary,
    fontSize: "24px",
    marginBottom: theme.spacing.unit * 6,
  },
  loadingContainer: {
    width: "100%",
    height: "50vh",
    display: "grid",
    placeItems: "center",
  },
  "@media (max-width: 900px)": {
    dashboardGrid: {
      gridTemplateColumns: "minmax(0, 1fr)",
    },
  },
});
