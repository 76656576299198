export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  firstContent: {
    alignItems: "center",
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  comment: {
    position: "relative",
    background: "none",
    margin: "0.5em 0em 0em",
    padding: "0.5em 0em 0em",
    border: "none",
    borderTop: "none",
    lineHeight: "1.2",
  },
  commentContent: {
    display: "block",
    fontFamily: "Roboto",
    color: theme.palette.text.secondary,
  },
  commentMetadata: {
    display: "inline-block",
    marginLeft: "0.5em",
    color: "rgba(0, 0, 0, 0.4)",
    fontSize: "14px",
    margin: "0em 0.5em 0em 0em",
  },
  commentText: {
    margin: "0.25em 0em 0.5em",
    fontSize: "14px",
    wordWrap: "break-word",
    lineHeight: "1.3",
    whiteSpace: "pre-wrap",
  },
  commentAuthor: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  commentAction: {
    fontSize: "11px",
    cursor: "pointer",
    display: "inline-block",
    margin: "0em 0.75em 0em 0em",
    color: theme.palette.danger.main,
  },
  commentTextField: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  commentButton: {
    marginTop: theme.spacing.unit * 2,
  },
  deleteNoteButton: {
    color: theme.palette.danger.main,
  },
  deleteNoteCaption: {
    color: theme.palette.text.primary,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
});
