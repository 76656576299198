import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Checkbox, Typography, Button, Switch } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";

// Component styles
import styles from "./styles";

class Notifications extends Component {
  state = {
    isAutomaticFeedbackEnabled: false,
    isOrgSettingsEnabled: false,
  };

  componentDidMount() {
    const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
    this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
    if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
      this.setNotificationStates();
    } else {
      this.setOrgPrefs(isOrgSettingsEnabled);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.onOrgTab !== this.props.onOrgTab ||
      prevProps.orgSettings !== this.props.orgSettings
    ) {
      const isOrgSettingsEnabled = this.isOrgSettingsEnabled();
      this.setState({ isOrgSettingsEnabled: isOrgSettingsEnabled });
      if (!this.props.onOrgTab && !isOrgSettingsEnabled) {
        this.setNotificationStates();
      } else {
        this.setOrgPrefs(isOrgSettingsEnabled);
      }
    }
  }

  setNotificationStates = () => {
    var isAutomaticFeedbackEnabled = JSON.parse(
      localStorage.getItem("isAutomaticFeedbackEnabled")
    );
    this.setState({
      isAutomaticFeedbackEnabled: isAutomaticFeedbackEnabled,
    });
  };

  setOrgPrefs = (isOrgSettingsEnabled) => {
    if (isOrgSettingsEnabled) {
      const { orgSettings } = this.props;

      this.setState({
        isAutomaticFeedbackEnabled: orgSettings.is_automatic_feedback_enabled,
      });
    } else {
      this.setState({
        isAutomaticFeedbackEnabled: false,
      });
    }
  };

  isOrgSettingsEnabled = () => {
    const { orgSettings } = this.props;
    return orgSettings.is_automatic_feedback_enabled != null;
  };

  handleSave = () => {
    var notificationSettings = {
      isAutomaticFeedbackEnabled: this.state.isAutomaticFeedbackEnabled,
    };
    this.props.saveHandler(notificationSettings);
  };

  handleOrgSettingsToggle = (event) => {
    if (event.target.checked) {
      this.setState({
        isOrgSettingsEnabled: true,
      });
    } else {
      const prefs = {
        isAutomaticFeedbackEnabled: null,
      };
      this.props.saveHandler(prefs).then(() => {
        this.setState({ isOrgSettingsEnabled: false });
        this.props.openSnackbar("Settings updated");
      });
    }
  };

  handleFeedbackchange = (event) => {
    this.setState({
      isAutomaticFeedbackEnabled: event.target.checked,
    });
  };

  render() {
    const { classes, className, onOrgTab, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Meeting Feedback Preferences" />
          {onOrgTab && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Off</Typography>
              <Switch
                color="primary"
                checked={this.state.isOrgSettingsEnabled}
                onChange={this.handleOrgSettingsToggle}
              />
              <Typography>On</Typography>
            </div>
          )}
        </PortletHeader>
        {!onOrgTab && this.state.isOrgSettingsEnabled && (
          <PortletContent className={classes.info}>
            <Typography variant="h6">
              Your settings and preferences are managed by your organization
              owner. <br />
              Please contact them for any changes.
            </Typography>
          </PortletContent>
        )}
        <PortletContent noPadding>
          <form className={classes.form}>
            <div className={classes.group}>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  onChange={this.handleFeedbackchange}
                  checked={this.state.isAutomaticFeedbackEnabled}
                  disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
                />
                <div>
                  <Typography variant="body1">
                    Request meeting feedback automatically
                  </Typography>
                  <Typography variant="caption">
                    We can automatically request feedback from attendees for
                    meetings that are processed by Sonero by email (if their
                    emails are available)
                  </Typography>
                </div>
              </div>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            onClick={this.handleSave}
            disabled={this.state.isOrgSettingsEnabled !== onOrgTab}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

Notifications.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);
