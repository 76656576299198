import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components
import { Zoom, Zapier, GoogleCalendar, Teams, Hubspot } from "./components";

// Shared services
import {
  getZoomClientId,
  connectToZoom,
  disconnectFromZoom,
  getIntegrationsStatus,
  getAPIHost,
  createZapierAPIKey,
  revokeZapierAPIKey,
  connectToGoogleCalendar,
  disconnectFromGoogleCalendar,
  connectToMicrosoft,
  disconnectFromMicrosoft,
  connectToHubspot,
  disconnectFromHubspot,
} from "services/speech";

import ReactGA from "react-ga";

import queryString from "query-string";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  upgradeIcon: {
    display: "block",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    color: theme.palette.success.main,
    border: `4px solid ${theme.palette.success.main}`,
    margin: `${theme.spacing(5)}px auto ${theme.spacing(2)}px`,
  },
  upgradeTitle: {
    width: "fit-content",
    margin: `${theme.spacing(1)}px auto`,
    fontSize: "18px",
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  upgradeBody: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontWeight: 400,
  },
  upgradeButton: {
    display: "block",
    width: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: `${theme.spacing(1)}px auto ${theme.spacing(5)}px`,
  },
});

class Integrations extends Component {
  zapierAPIKey = "";

  state = {
    results: {},
    isLoading: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    isZoomConnected: false,
    isZapierAPIKeyExists: false,
    isGoogleCalendarConnected: false,
    isMicrosoftConnected: false,
    isHubspotConnected: false,
    openZapierAPIKeyDialog: false,
    openUpgradeDialog: false,
    redirectToSetup: null,
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let code = params.code;
    let zoomState = params.state;
    if (zoomState) {
      const setupState = zoomState.split("-");
      this.setState({ code: code, redirectToSetup: setupState[1] });
      return;
    }
    if (code != null) {
      this.connect(code);
    } else {
      this.getAllIntegrationsStatus();
    }
  }

  getRedirectURL = () => {
    return `${getAPIHost()}/integrations`;
  };

  authorize = () => {
    this.setState({ isLoading: true });
    getZoomClientId().then(
      function(response) {
        if (response.status === 200) {
          var clientId = response.data.client_id;
          if (clientId != null) {
            var zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${this.getRedirectURL()}`;
            window.open(zoomUrl, "_self");
          }
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  authorizeGcal = () => {
    this.setState({ isLoading: true });
    connectToGoogleCalendar().then(
      function(response) {
        if (response.status === 200) {
          ReactGA.event({
            category: "GCal",
            action: "GCal integration successful",
          });
          var authorizeUrl = response.data.message;
          if (authorizeUrl != null) {
            window.open(authorizeUrl, "_self");
          }
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status === 403 &&
          response.data.error_type === "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  disconnectGcal = () => {
    this.setState({ isLoading: true });
    disconnectFromGoogleCalendar().then(
      function(response) {
        if (response.status === 200) {
          this.setState({ isGoogleCalendarConnected: false });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  connect = (code) => {
    this.setState({ isLoading: true });
    connectToZoom(code).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            isLoading: false,
            isZoomConnected: true,
          });
          ReactGA.event({
            category: "Zoom",
            action: "Zoom integration successful",
          });
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.setState({
            isLoading: false,
            isZoomConnected: false,
          });
          this.openSnackbar("Please verify your email address first!");
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            isZoomConnected: false,
          });
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            action: "Zoom integration failed",
          });
        }
      }.bind(this)
    );
  };

  disconnectZoom = () => {
    this.setState({ isLoading: true });
    disconnectFromZoom().then(
      function(response) {
        if (response.status === 200) {
          this.setState({ isZoomConnected: false });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  authorizeMicrosoft = () => {
    this.setState({ isLoading: true });
    connectToMicrosoft().then(
      function(response) {
        if (response.status === 200) {
          var authorizeUrl = response.data.message;
          if (authorizeUrl != null) {
            window.open(authorizeUrl, "_self");
          }
          ReactGA.event({
            category: "Teams",
            action: "Teams integration successful",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status === 403 &&
          response.data.error_type === "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  disconnectMicrosoft = () => {
    this.setState({ isLoading: true });
    disconnectFromMicrosoft().then(
      function(response) {
        if (response.status === 200) {
          this.setState({ isMicrosoftConnected: false });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  authorizeHubspot = () => {
    this.setState({ isLoading: true });
    connectToHubspot().then(
      function(response) {
        if (response.status === 200) {
          ReactGA.event({
            category: "Hubspot",
            action: "Hubspot integration successful",
          });
          var authorizeUrl = response.data.message;
          if (authorizeUrl != null) {
            window.open(authorizeUrl, "_self");
          }
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status === 403 &&
          response.data.error_type === "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  disconnectHubspot = () => {
    this.setState({ isLoading: true });
    disconnectFromHubspot().then(
      function(response) {
        if (response.status === 200) {
          this.setState({ isHubspotConnected: false });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  getAllIntegrationsStatus = () => {
    this.setState({ isLoading: true });
    getIntegrationsStatus().then(
      function(response) {
        if (response.status === 200) {
          var isZoomConnected = response.data.is_zoom_connected;
          var isZapierAPIKeyExists = response.data.is_zapier_api_key_exists;
          var isGoogleCalendarConnected =
            response.data.is_google_calendar_connected;
          var isMicrosoftConnected = response.data.is_microsoft_connected;
          var isOrgHubspotConnected = response.data.is_org_hubspot_connected;
          this.setState({
            isZoomConnected: isZoomConnected,
            isZapierAPIKeyExists: isZapierAPIKeyExists,
            isGoogleCalendarConnected: isGoogleCalendarConnected,
            isMicrosoftConnected: isMicrosoftConnected,
            isHubspotConnected: isOrgHubspotConnected,
            isLoading: false,
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        }
      }.bind(this)
    );
  };

  createZapierAPIKey = () => {
    this.setState({ isLoading: true });
    createZapierAPIKey().then(
      function(response) {
        if (response.status === 200) {
          this.zapierAPIKey = response.data.key;
          this.setState({
            isZapierAPIKeyExists: true,
            isLoading: false,
            openZapierAPIKeyDialog: true,
          });
          ReactGA.event({
            category: "Zapier",
            action: "Zapier Create API Key API success",
          });
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.setState({
            isLoading: false,
          });
          this.openSnackbar("Please verify your email address first!");
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
          });
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            action: "Zapier create API Key failed",
          });
        }
      }.bind(this)
    );
  };

  revokeZapierAPIKey = () => {
    this.setState({ isLoading: true });
    revokeZapierAPIKey().then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            isZapierAPIKeyExists: false,
            isLoading: false,
          });
          this.openSnackbar("Zapier API Key successfully revoked");
          ReactGA.event({
            category: "Zapier",
            action: "Zapier Revoke API Key API success",
          });
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.setState({
            isLoading: false,
          });
          this.openSnackbar("Please verify your email address first!");
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
          });
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            action: "Zapier revoke API Key failed",
          });
        }
      }.bind(this)
    );
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Integrations">
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  handleCloseZapierDialog = () => {
    this.zapierAPIKey = "";
    this.setState({
      openZapierAPIKeyDialog: false,
    });
  };

  renderZapierAPIKeyDialog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openZapierAPIKeyDialog}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">ZAPIER API KEY</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This is your API Key to connect your Sonero account in Zapier. Do
            not share this key with anyone. <br /> <br /> Once you dismiss this
            dialog, you will not be able to retrieve this key anymore. <br />{" "}
            <br />
            <b>{this.zapierAPIKey}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleCloseZapierDialog()}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenUpgradeDialog = () => {
    this.setState({ openUpgradeDialog: true });
  };

  handleCloseUpgradeDialog = () => {
    this.setState({ openUpgradeDialog: false });
  };

  renderUpgradeDialog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openUpgradeDialog}
        onClose={this.handleCloseUpgradeDialog}
      >
        <ArrowUpwardIcon className={classes.upgradeIcon} />
        <Typography className={classes.upgradeTitle}>
          Upgrade your account to integrate
        </Typography>
        <DialogContent>
          <Typography variant="body1" className={classes.upgradeBody}>
            You are on our Free tier.
          </Typography>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.redirectToSetup !== null) {
      const redirectURL = `/setup?code=${this.state.code}&conferenceType=${this.state.redirectToSetup}`;
      return <Redirect to={redirectURL} />;
    }

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <DashboardLayout title="Integrations">
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <GoogleCalendar
                className={classes.item}
                authorize={this.authorizeGcal}
                isConnected={this.state.isGoogleCalendarConnected}
                disconnect={this.disconnectGcal}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Teams
                className={classes.item}
                authorize={this.authorizeMicrosoft}
                isConnected={this.state.isMicrosoftConnected}
                disconnect={this.disconnectMicrosoft}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Zoom
                className={classes.item}
                authorize={this.authorize}
                isConnected={this.state.isZoomConnected}
                disconnect={this.disconnectZoom}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Hubspot
                className={classes.item}
                authorize={this.authorizeHubspot}
                isConnected={this.state.isHubspotConnected}
                disconnect={this.disconnectHubspot}
                handleOpenUpgradeDialog={this.handleOpenUpgradeDialog}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Zapier
                className={classes.item}
                isAPIKeyExists={this.state.isZapierAPIKeyExists}
                createAPIKey={this.createZapierAPIKey}
                revokeAPIKey={this.revokeZapierAPIKey}
                handleOpenUpgradeDialog={this.handleOpenUpgradeDialog}
              />
            </Grid>
          </Grid>
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
          {this.renderZapierAPIKeyDialog()}
          {this.renderUpgradeDialog()}
        </div>
      </DashboardLayout>
    );
  }
}

Integrations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Integrations);
