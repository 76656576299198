export function getPrice(tier, isAnnual) {
    switch(tier) {
        case pricingTiers.pro:
            return 35;
        case pricingTiers.professional:
            return isAnnual ? 34 : 39;
        case pricingTiers.business:
            return isAnnual ? 49 : 59;
        case pricingTiers.essential:
            return isAnnual ? 14 : 19;
        default:
            return 0;
    }
}

export const pricingTiers = {
    free: 1,
    proTrial: 2,
    pro: 3,
    professional: 4,
    business: 5,
    timeboxTrial: 6,
    essential: 7,
}

export function verifyUserPricingTier(tier) {
    const userPricingTier = localStorage.getItem("pricingTier");
    return userPricingTier === String(tier);
};

export function getUserPricingTier() {
    const userPricingTier = localStorage.getItem("pricingTier");
    switch (userPricingTier) {
        case "1":
            return "Free";
        case "2":
            return "Pro Trial";
        case "3":
            return "Pro";
        case "4":
            return "Professional";
        case "5":
            return "Business";
        case "6":
            return "Timebox Trial";
        case "7":
            return "Essential";
        default:
            return "";
    }
}

export function getNextUpgradeOnUserPricingTier() {
    const userPricingTier = localStorage.getItem("pricingTier");
    switch (userPricingTier) {
        case "1":
            return "Essential";
        case "2":
            return "Essential";
        case "3":
            return "Essential";
        case "4":
            return "Business";
        case "5":
            return "Business";
        case "6":
            return "Essential";
        case "7":
            return "Professional";
        default:
            return "";
    }
}

export default pricingTiers;