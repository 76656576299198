import { Typography, withStyles } from "@material-ui/core";
import { Paper } from "components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactWordcloud from "react-wordcloud";
import { select } from "d3-selection";
import {
  InsertEmoticon as InsertEmoticonIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
} from "@material-ui/icons";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import styles from "./styles";
import { getSpeakerString } from "helpers/meetingParticipants";

const minKeyTopics = 1;
const wordCloudMinSize = 12;
const wordCloudMaxSize = 60;

class WordCloud extends Component {
  state = {
    topicCloud: [],
    hoveredTopic: null,
  };
  containerRef = React.createRef();
  timeSpentHover = new Date();

  constructor(props) {
    super(props);
    this.state = { topicCloud: [] };
  }

  handleonMouseEnter = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeave = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Hovered Key Topics",
      value: timeSpent,
      label: "Insights",
    });
  };

  handleOnMouseOverWordCloud = (word, event) => {
    const text = select(event.target);
    text.transition().attr("text-decoration", "underline");
    this.setState({
      hoveredTopic: {
        topic: word.text,
        sentiment: word.sentiment,
        breakdown: word.breakdown,
        anchor: event.target,
      },
    });
  };

  handleOnMouseOutWordCloud = (word, event) => {
    const text = select(event.target);
    text.transition().attr("text-decoration", "none");
    this.setState({ hoveredTopic: null });
  };

  renderBreakdown = () => {
    const { hoveredTopic } = this.state;
    if (!hoveredTopic) {
      return <></>;
    }

    const { classes, data } = this.props;
    const participantsDetails = data.participants_details;

    let top = 0;
    let left = 0;
    if (this.containerRef.current && hoveredTopic.anchor) {
      const containerRect = this.containerRef.current.getBoundingClientRect();
      const wordRect = hoveredTopic.anchor.getBoundingClientRect();
      top = wordRect.top - containerRect.top;
      left = wordRect.left - containerRect.left;
    }

    return (
      <div className={classes.hoveredContent} style={{ top, left }}>
        <Typography className={classes.hoveredHeader}>
          <div className={classes.hoveredHeaderText}>
            Topic: <span>{hoveredTopic.topic}</span>
          </div>
          {hoveredTopic.sentiment > 0 && (
            <div className={classes.sentimentPill + " positive"}>
              <InsertEmoticonIcon className={classes.sentimentIcon} /> Positive
            </div>
          )}
          {hoveredTopic.sentiment < 0 && (
            <div className={classes.sentimentPill + " negative"}>
              <SentimentDissatisfiedIcon className={classes.sentimentIcon} />{" "}
              Negative
            </div>
          )}
        </Typography>
        {hoveredTopic.sentiment > 0 && (
          <Typography className={classes.sentimentTip}>
            Attendees were generally positive on this topic
          </Typography>
        )}
        {hoveredTopic.sentiment < 0 && (
          <Typography className={classes.sentimentTip}>
            Attendees were generally negative on this topic
          </Typography>
        )}
        <div className={classes.hoveredBreakdown}>
          {Object.keys(hoveredTopic.breakdown).map((speakerTag) => {
            if (speakerTag === "total") return <></>;
            const speaker = getSpeakerString(
              parseInt(speakerTag),
              participantsDetails
            );
            const percent = Math.round(
              hoveredTopic.breakdown[speakerTag] * 100
            );
            return (
              <div className={classes.breakdownRow}>
                <Typography
                  variant="body1"
                  className={classes.breakdownRowLabel}
                >
                  <div>{speaker}</div>
                  <div>{percent}%</div>
                </Typography>
                <div
                  className={classes.breakdownBar}
                  style={{
                    width: `${percent * 3}px`,
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { classes, topics } = this.props;

    if (topics.length < minKeyTopics) {
      return <></>;
    }

    const callbacks = {
      onWordClick: (e) => this.props.handleSelectedTopic(e.text),
      onWordMouseOver: this.handleOnMouseOverWordCloud,
      onWordMouseOut: this.handleOnMouseOutWordCloud,
    };
    const options = {
      colors: ["#2096f3"],
      rotations: 0,
      enableTooltip: false,
      deterministic: true,
      rotationAngles: [0],
      fontFamily: "Roboto",
      fontSizes: [wordCloudMinSize, wordCloudMaxSize],
      enableOptimizations: true,
      transitionDuration: 0,
    };

    return (
      <Paper className={classes.overviewSection}>
        <div ref={this.containerRef} className={classes.container}>
          <div className={classes.header}>
            <Typography className={classes.title} variant="h5">
              KEY TOPICS
            </Typography>
          </div>
          <div className={`${classes.content} ${classes.wordCloudContent}`}>
            <div
              className={classes.wordCloud}
              onMouseEnter={this.handleonMouseEnter}
              onMouseLeave={this.handleOnMouseLeave}
            >
              <ReactWordcloud
                words={this.props.topics}
                callbacks={callbacks}
                options={options}
              />
            </div>
          </div>
          {this.renderBreakdown()}
        </div>
      </Paper>
    );
  }
}

WordCloud.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const useCache = (prevProps, nextProps) => {
  if (prevProps.data === nextProps.data) {
    return true;
  }
  return false;
};

// React.memo() prevents this component from re-rendering everytime the parent component updates
// Since this component is quite heavy, only re-render when the data prop changes
export default withStyles(styles)(React.memo(WordCloud, useCache));
