import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconButton, withStyles } from "@material-ui/core";
import styles, { tickHeight } from "./styles";
import { takeawaysColour, questionsColour } from "../../styles";

import { Typography, Tooltip, fade } from "@material-ui/core";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import DeleteIcon from "@material-ui/icons/Delete";

import { getSpeakerString } from "helpers/meetingParticipants";
import { timeFormatter } from "helpers/time";
import { conversationHighlightTypes } from "helpers";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

class Timeline extends Component {
  state = {
    hoveringTick: null,
    timestampPosition: -1,
  };
  timelineRef = React.createRef();
  keepSnippet = false;

  getMeetingDuration = () => {
    let duration = this.props.data.duration;
    const transcriptResults = this.props.data.transcript_results;
    // For local testing, when the transcript length doesn't match the duration of the meeting
    if (transcriptResults[transcriptResults.length - 1].timestamp > duration) {
      duration = transcriptResults[transcriptResults.length - 1].timestamp + 10;
    }
    return duration;
  };

  handleShowSnippet = (tick) => {
    this.keepSnippet = true;
    this.setState({ hoveringTick: tick });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User hovered over an event in Conversation Highlights Timeline",
    });
  };

  handleHideSnippet = () => {
    this.keepSnippet = false;
    setTimeout(() => {
      if (!this.keepSnippet) {
        this.setState({ hoveringTick: null });
      }
    }, 250);
  };

  handleMouseMoveOnTimeline = (e) => {
    if (this.timelineRef.current) {
      const rect = this.timelineRef.current.getBoundingClientRect();
      this.setState({ timestampPosition: e.clientX - rect.left });
    }
  };

  handleMouseLeaveTimeline = () => {
    this.setState({ timestampPosition: -1 });
  };

  handleClickTimeline = () => {
    const { timestampPosition } = this.state;
    if (timestampPosition < 0 || !this.timelineRef.current) {
      return;
    }
    const timelineWidth = this.timelineRef.current.offsetWidth;
    const duration = this.getMeetingDuration();
    const timestamp = (timestampPosition / timelineWidth) * duration;
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
  };

  handleClickOnTick = (event, tick, type) => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action:
        "User clicked on a " +
        type +
        " event in Conversation Highlights Timeline",
    });
    event.stopPropagation();
    this.props.handleInsightOnClick(tick, type);
  };

  handleDeleteTakeaway = (event, takeaway) => {
    event.stopPropagation();
    this.props.handleDeleteTakeaway(takeaway);
  };

  renderTimelineTick = (tick, type) => {
    const { classes } = this.props;
    const duration = this.getMeetingDuration();

    let selected = this.props.tab === type;
    let notFaded = this.props.tab === "" || selected;

    let colour;
    let Icon;
    switch (type) {
      case conversationHighlightTypes.questions:
        colour = questionsColour;
        Icon = QuestionAnswerIcon;
        break;
      case conversationHighlightTypes.takeaways:
      default:
        colour = takeawaysColour;
        Icon = PriorityHighIcon;
    }

    return (
      <div
        key={tick.timestamp}
        className={classes.timelineTick + (selected ? " selected" : "")}
        style={{
          left: `${(tick.timestamp / duration) * 100}%`,
          background: notFaded ? colour : fade(colour, 0.25),
          zIndex: notFaded ? 1 : 0,
        }}
        onMouseEnter={() => {
          if (notFaded) this.handleShowSnippet(tick);
        }}
        onMouseLeave={this.handleHideSnippet}
        onClick={(e) => this.handleClickOnTick(e, tick, type)}
      >
        <Icon className={classes.tickIcon} style={{ color: colour }} />
      </div>
    );
  };

  renderHoverSnippet = () => {
    const { classes } = this.props;
    const { hoveringTick } = this.state;
    const participantsDetails = this.props.data.participants_details;

    if (!hoveringTick) {
      return <></>;
    }

    const duration = this.getMeetingDuration();
    const lPos = (hoveringTick.timestamp / duration) * 100;
    const rPos = (1 - hoveringTick.timestamp / duration) * 100;
    let style;
    if (lPos < rPos) {
      style = { left: `max(${lPos}% - 200px, 0px)` };
    } else {
      style = { right: `max(${rPos}% - 200px, 0px)` };
    }
    const timelineRect = this.timelineRef.current.getBoundingClientRect();
    const spaceAbove = timelineRect.top;
    const spaceBelow = window.innerHeight - timelineRect.bottom;
    if (spaceAbove >= spaceBelow) {
      style.bottom = `${tickHeight + 8}px`;
    } else {
      style.top = `${tickHeight + 8}px`;
    }

    let headerLabel = "";
    switch (hoveringTick.type) {
      case conversationHighlightTypes.questions:
        headerLabel = "Question";
        break;
      case conversationHighlightTypes.takeaways:
      default:
        headerLabel = "Takeaway";
    }

    return (
      <div
        className={classes.hoverSnippet}
        style={style}
        onMouseEnter={() => this.handleShowSnippet(hoveringTick)}
        onMouseLeave={this.handleHideSnippet}
        onClick={(e) =>
          this.handleClickOnTick(e, hoveringTick, hoveringTick.type)
        }
      >
        <header className={classes.snippetHeader}>
          <div>
            <span
              className={classes.snippetHeaderLabel + " " + hoveringTick.type}
            >
              {headerLabel}
            </span>{" "}
            at{" "}
            <span className={classes.snippetHeaderTimestamp}>
              {timeFormatter(hoveringTick.timestamp)}
            </span>
          </div>
          {hoveringTick.type === conversationHighlightTypes.takeaways &&
            !this.props.isSharedMeetingView && (
              <IconButton
                size="small"
                className={classes.deleteIcon}
                onClick={(e) => this.handleDeleteTakeaway(e, hoveringTick)}
              >
                <DeleteIcon />
              </IconButton>
            )}
        </header>
        <div className={classes.headerChipsContainer}>
          {hoveringTick.subtype === conversationHighlightTypes.wins && (
            <div className={classes.headerChip + " win"}>
              <div className={classes.chipContent}>
                <EmojiEventsIcon className="icon" /> Win
              </div>
            </div>
          )}
          {hoveringTick.subtype ===
            conversationHighlightTypes.opportunities && (
            <div className={classes.headerChip + " opportunity"}>
              <div className={classes.chipContent}>
                <EmojiObjectsIcon className="icon" /> Opportunity & Next Step
              </div>
            </div>
          )}
          {hoveringTick.subtype === conversationHighlightTypes.painPoints && (
            <div className={classes.headerChip + " painPoint"}>
              <div className={classes.chipContent}>
                <FitnessCenterIcon className="icon" /> Pain Point
              </div>
            </div>
          )}
          {hoveringTick.facialEmotion === "positive" && (
            <div className={classes.headerChip + " thrilled expandable"}>
              <div className={classes.chipContent}>
                <InsertEmoticonIcon className="icon" /> People were thrilled
              </div>
              <div className={classes.hoverTooltip}>
                Smiles detected via facial analysis
              </div>
            </div>
          )}
          {hoveringTick.facialEmotion === "negative" && (
            <div className={classes.headerChip + " down expandable"}>
              <div className={classes.chipContent}>
                <SentimentDissatisfiedIcon className="icon" /> People were down
              </div>
              <div className={classes.hoverTooltip}>
                Gloomy looks detected via facial analysis
              </div>
            </div>
          )}
        </div>
        <div className={classes.snippetContent}>
          {hoveringTick.transcriptSegments.map((segment, idx) => (
            <div key={idx} className={classes.snippetSegment}>
              <Typography variant="h6" className={classes.snippetSpeaker}>
                {getSpeakerString(segment.speaker, participantsDetails)}
              </Typography>
              <Typography variant="body1" className={classes.snippetBody}>
                {segment.transcript.map((transcriptSentence, i) => (
                  <p key={i}>{transcriptSentence.sentence}</p>
                ))}
              </Typography>
            </div>
          ))}
          {hoveringTick.answerTimestamp &&
            hoveringTick.answerTimestamp !== -1 &&
            this.renderAnswer(hoveringTick)}
        </div>
      </div>
    );
  };

  renderAnswer = (tick) => {
    const { classes } = this.props;
    const participantsDetails = this.props.data.participants_details;

    return (
      <>
        <header className={classes.snippetHeader}>
          <div>
            <span className={classes.snippetHeaderLabel + " answer"}>
              Answer
            </span>{" "}
            at{" "}
            <span className={classes.snippetHeaderTimestamp}>
              {timeFormatter(tick.answerTimestamp)}
            </span>
          </div>
        </header>
        {tick.answerSegments.map((segment, idx) => (
          <div key={idx}>
            <Typography variant="h6" className={classes.snippetSpeaker}>
              {getSpeakerString(segment.speaker, participantsDetails)}
            </Typography>
            <Typography variant="body1" className={classes.snippetBody}>
              {this.renderSpeakerSegmentAnswer(segment, tick)}
            </Typography>
          </div>
        ))}
      </>
    );
  };

  renderSpeakerSegmentAnswer(segment, tick) {
    const { classes } = this.props;

    var transcript = [];
    var currentParagraphLength = 0;
    const paragraphThreshold = 240;
    var paragraph = [];

    segment.transcript.forEach((element, idx) => {
      if (element.sentence.trim() === ".") {
        return;
      }
      if (
        currentParagraphLength + element.sentence.length <=
        paragraphThreshold
      ) {
        currentParagraphLength += element.sentence.length;
      } else {
        if (currentParagraphLength > 0) {
          transcript.push(
            <p className={classes.speakerParagraph}>{paragraph}</p>
          );
        }
        currentParagraphLength = element.sentence.length;
        paragraph = [];
      }
      if (paragraph.length > 0) {
        paragraph.push(" ");
      }
      if (tick.directAnswer) {
        const sentence = element.sentence;
        const directAnswer = tick.directAnswer;

        let splitAnswer = [];
        let indexStart = sentence.indexOf(directAnswer);
        if (indexStart >= 0) {
          splitAnswer = [
            sentence.slice(0, indexStart),
            sentence.slice(indexStart, indexStart + directAnswer.length),
            sentence.slice(indexStart + directAnswer.length),
          ];
        }

        if (splitAnswer.length === 3) {
          paragraph.push(
            <>
              {splitAnswer[0]}
              <Tooltip title="Insight: Direct Answer">
                <span className={classes.directAnswer}>{splitAnswer[1]}</span>
              </Tooltip>
              {splitAnswer[2]}
            </>
          );
        } else {
          paragraph.push(element.sentence);
        }
      } else {
        paragraph.push(element.sentence);
      }

      if (idx === segment.transcript.length - 1) {
        transcript.push(
          <p className={classes.speakerParagraph}>{paragraph}</p>
        );
      }
    });

    return <>{transcript}</>;
  }

  renderTimestamp = () => {
    const { classes } = this.props;
    const { timestampPosition } = this.state;
    if (
      timestampPosition < 0 ||
      !this.timelineRef.current ||
      this.keepSnippet
    ) {
      return <></>;
    }
    const timelineWidth = this.timelineRef.current.offsetWidth;
    const duration = this.getMeetingDuration();
    const timestamp = (timestampPosition / timelineWidth) * duration;
    if (timestamp > duration) {
      return <></>;
    }

    return (
      <Typography
        variant="body1"
        className={classes.timestamp}
        style={{ left: `${timestampPosition}px` }}
      >
        {timeFormatter(timestamp)}
      </Typography>
    );
  };

  render() {
    const { classes, questions, takeaways } = this.props;

    return (
      <div className={classes.timelineContainer}>
        <div
          className={classes.timeline}
          ref={this.timelineRef}
          onMouseMove={this.handleMouseMoveOnTimeline}
          onMouseLeave={this.handleMouseLeaveTimeline}
          onClick={this.handleClickTimeline}
        >
          <div className={classes.timelinePadding}></div>
          {questions.map((tick) =>
            this.renderTimelineTick(tick, conversationHighlightTypes.questions)
          )}
          {takeaways.map((tick) =>
            this.renderTimelineTick(tick, conversationHighlightTypes.takeaways)
          )}
          {this.renderHoverSnippet()}
          {this.renderTimestamp()}
        </div>
      </div>
    );
  }
}

Timeline.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Timeline);
