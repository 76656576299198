export default (theme) => ({
  account: {
    fontSize: "20px",
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.dark,
  },
  accountMenu: {
    padding: theme.spacing(2),
    width: "max(min(35vw, 600px), 350px)",
  },
  searchBar: {
    height: "40px",
    backgroundColor: theme.palette.primary.light,
  },
  searchedContent: {
    marginTop: theme.spacing(2),
  },
  copy: {
    fontSize: "16px",
    fontWeight: 400,
    margin: "auto",
    textAlign: "center",
    "&[type='error']": {
      color: theme.palette.danger.main,
    },
  },
  accountTable: {
    maxHeight: "250px",
    overflowY: "auto",
  },
  accountRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.border}`,
    cursor: "pointer",
    wordBreak: "break-word",
    "&[type='header']": {
      cursor: "auto",
    },
    "&:not([type='header']):hover": {
      backgroundColor: theme.palette.background.lightGrey,
    },
  },
  accountEntry: {
    width: "50%",
    "&[type='header']": {
      fontWeight: 600,
    },
  },
  newAccountContainer: {
    width: "90%",
    margin: "auto",
    marginTop: theme.spacing(3),
  },
  newAccountCopy: {
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  newAccountInput: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  newAccountButton: {
    display: "block",
    marginLeft: "auto",
  },
});
