import React, { useState, useRef } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AddIcon from "@material-ui/icons/Add";
import ReactGA from "react-ga";
import { gaCategoryDashboard } from "helpers/gaUtil";
import { updateTracker, createTracker } from "services/speech";

const tabs = {
  askedByMe: "asked_by_me",
  askedByAttendee: "asked_by_attendee",
};

function FAQ(props) {
  const { classes, faq, history, openSnackbar } = props;
  const [tab, setTab] = useState(tabs.askedByAttendee);
  const [expanded, setExpanded] = useState([]);
  const hoverStart = useRef(new Date());

  const handleTabChange = (_event, newValue) => {
    setTab(newValue);

    let action = "User switched to Asked By Me tab in FAQ";
    if (newValue === tabs.askedByAttendee) {
      action = "User switched to Asked By Attendee tab in FAQ";
    }
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: action,
    });
  };

  const handleExpandAndCollapse = (idx) => {
    if (expanded.includes(idx)) {
      setExpanded((prevExpanded) => prevExpanded.filter((i) => i !== idx));
    } else {
      setExpanded((prevExpanded) => [...prevExpanded, idx]);
    }
  };

  const handleClickQuestion = (question) => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User clicked on a question in FAQ",
    });
    history.push(
      `/view-meeting/${question.meeting_uuid}?question=${question.timestamp}`
    );
  };

  const handleOnMouseEnter = () => {
    hoverStart.current = new Date();
  };

  const handleOnMouseLeave = () => {
    const timeSpent = new Date() - hoverStart.current;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Hovered FAQ",
      value: timeSpent,
    });
  };

  const addToTracker = (trackerName, status) => {
    return createTracker(trackerName, status).then((response) => {
      if (response.status !== 200) {
        if (response.status === 401) {
          history.push("/sign-in");
          return response;
        } else if (response.status === 400) {
          openSnackbar(
            "This tracker already exists!"
          );
        } else {
          openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
        }
        ReactGA.event({
          category: "Failure",
          action: "Create tracker API failed",
        });
        return response;
      }
      openSnackbar("Tracker successfully created");
      ReactGA.event({
        category: gaCategoryDashboard(),
        action: "Create tracker API success",
      });
      return response;
    });
  };

  const getQuestionPhrase = (question, noun) => {
    let nounChunk;
    if (question.noun_chunks) {
      for (const chunk of question.noun_chunks) {
        if (chunk.includes(noun)) {
          nounChunk = chunk;
          break;
        }
      }
    }

    let phrase = [];
    if (nounChunk) {
      const matches = [
        ...question.phrase.matchAll(new RegExp(nounChunk, "gi")),
      ];
      let nextIdx = 0;
      matches.forEach((match) => {
        phrase.push(<>{question.phrase.slice(nextIdx, match.index)}</>);
        nextIdx = match.index + nounChunk.length;
        phrase.push(<b>{question.phrase.slice(match.index, nextIdx)}</b>);
      });
      phrase.push(question.phrase.slice(nextIdx));
    }
    if (phrase.length > 1) return phrase;

    phrase = [];
    question.phrase.split(" ").forEach((word) => {
      if (word.toLowerCase().includes(noun)) {
        const lastChar = word.slice(-1);
        if (lastChar.match(new RegExp("^[a-zA-Z]+$"))) {
          phrase.push(<b>{word} </b>);
        } else {
          phrase.push(
            <>
              <b>{word.slice(0, -1)}</b>
              {lastChar}{" "}
            </>
          );
        }
      } else {
        phrase.push(word + " ");
      }
    });
    return phrase;
  };

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <header className={classes.sectionHeader}>
        FREQUENTLY ASKED QUESTIONS
      </header>

      <ToggleButtonGroup
        className={classes.headerTabs}
        value={tab}
        onChange={handleTabChange}
        exclusive
      >
        <ToggleButton
          value={tabs.askedByAttendee}
          className={classes.tabButton}
          classes={{ selected: classes.selected }}
          disabled={tab === tabs.askedByAttendee}
        >
          <Typography className={classes.tabText} variant="h5">
            ASKED BY ATTENDEE
          </Typography>
        </ToggleButton>
        <ToggleButton
          value={tabs.askedByMe}
          className={classes.tabButton}
          classes={{ selected: classes.selected }}
          disabled={tab === tabs.askedByMe}
        >
          <Typography className={classes.tabText} variant="h5">
            ASKED BY ME
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>

      <div className={classes.faqContent}>
        {faq != null ? (
          <>
            {faq[tab].length > 0 && (
              <Typography className={classes.faqHeader}>
                There were <b>{faq[tab].length}</b> types of frequently asked
                questions during this period
              </Typography>
            )}
            {faq[tab].map((q, idx) => {
              const isExpanded = expanded.includes(idx);
              const numQuestions = q.questions.length;
              const questions = isExpanded
                ? q.questions
                : q.questions.slice(0, 3);
              const ctaText = isExpanded
                ? "HIDE QUESTION"
                : `SEE ${numQuestions - 3} MORE QUESTION` +
                  (numQuestions > 4 ? "S" : "");
              return (
                <div key={idx} className={classes.faqItem}>
                  <div className={classes.faqRow}>
                    <div className={classes.faqRowTitle}>
                      <Typography variant="h5">({numQuestions})</Typography>
                      <Typography variant="h5" className={classes.faqRowHeader}>
                        Questions about <span>{q.noun}</span>:
                      </Typography>
                    </div>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => addToTracker(q.noun, true)}
                    >
                      TRACKER
                    </Button>
                  </div>
                  <div className={classes.questions}>
                    {questions.map((qa, idx) => (
                      <div
                        key={idx}
                        className={classes.question}
                        onClick={() => handleClickQuestion(qa)}
                      >
                        <Typography>{idx + 1}.</Typography>
                        <Typography className={classes.questionPhrase}>
                          {getQuestionPhrase(qa, q.noun).map((p) => p)}
                          <span className={classes.meetingTitle}>
                            {" "}({qa.meeting_title})
                          </span>
                        </Typography>
                      </div>
                    ))}
                  </div>
                  {numQuestions > 3 && (
                    <Button
                      onClick={() => handleExpandAndCollapse(idx)}
                      className={classes.moreButton}
                      color="primary"
                    >
                      {ctaText}
                    </Button>
                  )}
                </div>
              );
            })}
            {faq[tab].length === 0 && (
              <Typography className={classes.emptyText}>
                <QuestionAnswerIcon className={classes.emptyIcon} />
                No frequently asked questions found
              </Typography>
            )}
          </>
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(FAQ);
