export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing.unit * 2,
        background: "#ffffff",
        '&:hover': {
            background: "#f1f1f1",
        },
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '3px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '0'
    },
    topic: {
        fontSize: 16,
        fontWeight: 500,
        marginLeft: theme.spacing.unit,
        width: '100%',
        color: theme.palette.text.primary
    },
    description: {
        fontWeight: 500,
        marginLeft: theme.spacing.unit,
        width: '100%',
        color: theme.palette.text.secondary
    },
    author: {
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit
    },
    dragging: {
        background: theme.palette.primary.light,
        border: `1px solid ${theme.palette.border}`
    },
});