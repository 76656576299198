const hostEnv = {
    prod: "production",
    dev: "development",
    local: "local"
}

export const currentEnv = () => {
    if (process.env.REACT_APP_API_URL === hostEnv.prod) {
      return hostEnv.prod;
    } else if (process.env.REACT_APP_API_URL === hostEnv.dev) {
      return hostEnv.dev;
    }
    return hostEnv.local;
};

export const stripePublishableKey = () => {
    if (currentEnv() === hostEnv.prod) {
      return "pk_live_u8Q8jcoTqEFepTV9OD0hOZye00enfcMt4m"
    } 
    return "pk_test_Q2niPSanS2PBF6K5pO9YO6DE00LIkvSuEd";
};

export const stripeProTierPriceId = () => {
    if (currentEnv() === hostEnv.prod) {
      return "price_1KStliC3Zklyo7C6WUIoZ6uC"
    } 
    return "price_1KQf58C3Zklyo7C6sdeZaBQv";
};

export const stripeProfessionalTierPriceId = (annual) => {
  if (currentEnv() === hostEnv.prod) {
    return annual ? "price_1LNS5TC3Zklyo7C6ZSJvbcII" : "price_1LNRtUC3Zklyo7C68gwhNLSz";
  } 
  return annual ? "price_1LLws1C3Zklyo7C6TTIYDLMz" : "price_1LLwrHC3Zklyo7C6MmTCflSP";
};

export const stripeBusinessTierPriceId = (annual) => {
  if (currentEnv() === hostEnv.prod) {
    return annual ? "price_1LNS6bC3Zklyo7C6S7k4ALaX" : "price_1LNS6EC3Zklyo7C6oFUEE6i5";
  } 
  return annual ? "price_1LLwtiC3Zklyo7C6CcRGGL2g" : "price_1LLwt3C3Zklyo7C60EfH7caC";
};

export const stripeEssentialTierPriceId = (annual) => {
  if (currentEnv() === hostEnv.prod) {
    return annual ? "price_1MCpaDC3Zklyo7C6xN7JpqLV" : "price_1MCpZhC3Zklyo7C6peTF1SLG";
  } 
  return annual ? "price_1M9ZacC3Zklyo7C69JRCYS0d" : "price_1M9Zc6C3Zklyo7C6n2hOnQWm";
}

export const gaTrackingId = () => {
    if (currentEnv() === hostEnv.prod) {
        return "UA-145147541-2"
    }
    return "UA-145147541-1"
}

// When changing the feedback scale,
// remember to also update the FEEDBACK_SCALE value in settings.ini 
export const feedbackScale = 5;