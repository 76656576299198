import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import { updateTracker, createTracker } from "services/speech";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  field: {
    minWidth: "300px",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
  },
  link: {
    color: theme.palette.primary.main,
  },
});

class TrackerDialog extends Component {
  state = {
    trackerName: this.props.trackerName,
    status: this.props.status,
    trackerId: this.props.trackerId,
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.trackerName !== this.props.trackerName ||
      prevProps.status !== this.props.status
    ) {
      this.setState({
        trackerName: this.props.trackerName,
        status: this.props.status,
      });
    }
  }

  handleSaveTracker = () => {
    const { classes } = this.props;
    var title = this.state.trackerName;
    if (!title.trim()) {
      return;
    }
    this.setLoading(true);
    if (this.props.trackerId !== null) {
      return updateTracker(
        this.state.trackerName,
        this.state.status,
        this.props.trackerId
      ).then(
        function(response) {
          if (response.status !== 200) {
            if (response.status === 401) {
              this.props.history.push("/sign-in");
              return response;
            } else if (response.status === 400) {
              this.props.openSnackbar(
                "This tracker already exists! Please choose a unique tracker name!"
              );
            } else {
              this.props.openSnackbar(
                "Sorry, but an unknown error occurred. Please try again."
              );
            }
            this.setLoading(false);
            ReactGA.event({
              category: "Failure",
              action: "Update tracker API failed",
            });
            return response;
          }
          var newTracker = response.data;
          this.props.handleUpdateTracker(newTracker);
          this.setLoading(false);
          this.props.openSnackbar("Tracker successfully updated");
          ReactGA.event({
            category: "Trackers",
            action: "Update tracker API success",
          });
          this.props.onClose();
          return response;
        }.bind(this)
      );
    } else {
      return createTracker(this.state.trackerName, this.state.status).then(
        function(response) {
          if (response.status !== 200) {
            if (response.status === 401) {
              this.props.history.push("/sign-in");
              return response;
            } else if (response.status === 400) {
              this.props.openSnackbar(
                "This tracker already exists! Please choose a unique tracker name!"
              );
            } else {
              this.props.openSnackbar(
                "Sorry, but an unknown error occurred. Please try again."
              );
            }
            this.setLoading(false);
            ReactGA.event({
              category: "Failure",
              action: "Create tracker API failed",
            });
            return response;
          }
          var newTracker = response.data;
          this.props.handleCreateTracker(newTracker);
          this.setLoading(false);
          this.props.openSnackbar("Tracker successfully created");
          ReactGA.event({
            category: "Trackers",
            action: "Create tracker API success",
          });
          this.props.onClose();
          return response;
        }.bind(this)
      );
    }
  };

  handleTrackerNameChange = (value) => {
    this.setState({ trackerName: value });
  };

  handleStatusChange = (event) => {
    this.setState({ status: event.target.checked });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render = () => {
    const { classes, className, ...rest } = this.props;
    const dialogTitle = this.props.trackerId
      ? "Edit Tracker"
      : "Create New Tracker";
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.field}
            label="Tracker"
            onChange={(event) =>
              this.handleTrackerNameChange(event.target.value)
            }
            required
            value={this.state.trackerName}
            inputProps={{ maxLength: 128 }}
          />
          <FormControl className={classes.field}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.status}
                  onChange={this.handleStatusChange}
                  name="status"
                  color="primary"
                />
              }
              label="Active"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={() => this.handleSaveTracker()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

TrackerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(TrackerDialog);
