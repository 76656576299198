import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, CircularProgress, Typography, Snackbar } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import {
  RecordVideo,
  SpeechDetails,
  Pacing,
  Energy,
  Delivery,
  Fillers,
  Emotions,
  QuickLook,
  ViewVideo, 
  ContentSentiment, 
  ContentPositiveWords, 
  ContentNegativeWords
} from './components';

// Shared components
import {
  Portlet,
  PortletContent,
} from 'components';

// Shared services
import { newSpeech, getSpeech, getProfile } from 'services/speech';

// Shared helpers
import { speechStatus } from 'helpers';
import { pricingTiers } from 'helpers';

import ReactGA from 'react-ga';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  item: {
    height: '100%'
  },
  progressWrapper: {
    paddingTop: '100px',
    paddingBottom: '50px',
    display: 'flex',
    justifyContent: 'center'
  },
  error: {
    color: theme.palette.text.danger
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: '100px',
  },
  freeTierCaption: {
    color: theme.palette.text.secondary
  },
  snackbar: {
    height: "60px",
    fontSize: 16
  }
});

class NewSpeech extends Component {

  timeout = null
  speechId = null
  pollingAttempts = 0

  state = {
    results: {},
    videoBlob: null,
    title: "",
    type: "",
    speechStatus: null,
    showTitleError: false,
    showTypeError: false,
    isLoading: false,
    error: null,
    snackbarOpen: false
  };

  componentWillMount() {
    this.setState({
      isLoading: true
    })
    getProfile().then(function(response) {
      if (response.status === 200) {
        if (response.data.pricing_tier == pricingTiers.free && response.data.num_of_speeches >= 10) {
          this.setError("You've reached the free tier limit of 10 recordings. Please upgrade to Sonero Pro to get feedback on new recordings.")
        }
        ReactGA.event({
          category: 'User',
          action: 'Get user profile success'
        });
      } else if (response.status == 401) {
        this.props.history.push('/sign-in');
        ReactGA.event({
          category: 'Failure',
          action: 'Refresh token expired'
        });
      } else {
        // TODO: Handle error
        ReactGA.event({
          category: 'Failure',
          action: 'Get user profile failed'
        });
      }
      this.setState({isLoading: false})
    }.bind(this));
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  uploadSpeech = (blob) => {
    if (this.state.title.length === 0 || !this.state.title.trim()) {
      this.setState({
        showTitleError: true
      })
      return
    }
    if (this.state.type.length === 0 || !this.state.type.trim()) {
      this.setState({
        showTypeError: true
      })
      return
    }
    this.setState({isLoading: true, videoBlob: blob})
    newSpeech(blob, this.state.title, this.state.type).then(function(response) {
      if (response.status === 202) {
        var data = response.data.id
        this.setState({speechStatus: speechStatus.processing})
        this.speechId = data
        const pricingTier = localStorage.getItem('pricingTier')
        if (pricingTier == pricingTiers.free) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 10000)
        }
        ReactGA.event({
          category: 'Speech',
          action: 'Submitted for processing'
        });
      } else if (response.status == 401) {
        this.props.history.push('/sign-in');
        ReactGA.event({
          category: 'Failure',
          action: 'Refresh token expired'
        });
      } else {
        this.openErrorSnackbar()
        ReactGA.event({
          category: 'Failure',
          action: 'Could not submit for processing'
        });
      }
      this.setState({isLoading: false})
    }.bind(this));
  }

  updateTitle = (title) => {
    this.setState({
      title: title,
      showTitleError: false
    })
  }

  updateType = (type) => {
    this.setState({
      type: type,
      showTypeError: false
    })
  }

  getSpeechFromRemote = () => {
    this.pollingAttempts += 1
    getSpeech(this.speechId).then(function(response) {
      if (response.status !== 200) {
        if (response.status == 401) {
          this.props.history.push('/sign-in');
          ReactGA.event({
            category: 'Failure',
            action: 'Refresh token expired'
          });
          return
        }
        this.setError("Sorry, but an unknown error occurred. Please try again.")
        ReactGA.event({
          category: 'Failure',
          action: 'Could not get speech results in new-speech'
        });
        return
      }

      var status = response.data.status

      if (status === speechStatus.processing) {
        if (this.pollingAttempts < 2) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 10000)
        } else if (this.pollingAttempts >= 2 && this.pollingAttempts < 4) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 15000)
        } else if (this.pollingAttempts >= 4 && this.pollingAttempts < 6) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 20000)
        } else if (this.pollingAttempts >= 6 && this.pollingAttempts < 10) {
          this.timeout = setTimeout(this.getSpeechFromRemote, 30000)
        } else {
          this.setError("Sorry, but analysis is taking longer than expected. Please check back at a later time. We apologize for the inconvenience.")
          ReactGA.event({
            category: 'Failure',
            action: 'Speech processing taking a long time'
          });
        }
        return
      }

      this.pollingAttempts = 0

      if (status === speechStatus.completed) {
        var data = response.data.data
        this.setState({
            speechStatus: speechStatus.completed,
            results: data
        })
        ReactGA.event({
          category: 'Speech',
          action: 'Get speech success'
        });
      } else if (status === speechStatus.failed) {
        this.setState({
          speechStatus: speechStatus.failed,
          error: "Sorry, but processing failed because of an unknown reason. We apologize for the inconvenience."
        })
        ReactGA.event({
          category: 'Failure',
          action: 'Speech processing failed'
        });
      } else {
        this.setError("Sorry, but an unknown error occurred. Please try again.")
        ReactGA.event({
          category: 'Failure',
          action: 'Unknown error in get speech'
        });
      }
    }.bind(this));
  }

  setError = (error) => {
    this.setState({error: error})
  }

  openErrorSnackbar = () => {
    this.setState({
      snackbarOpen: true,
    })
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    })
  }

  renderError = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="New Practice Recording">
          <div className={classes.root}>
              <div className={classes.content}>
                <Portlet
                >
                  <PortletContent className={classes.content}>
                    <Typography
                      className={classes.error}
                      variant="body1"
                    >
                      {this.state.error}
                    </Typography>
                  </PortletContent>
                </Portlet>
              </div>
          </div>
      </DashboardLayout>
    )
  }

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="New Practice Recording">
          <div className={classes.root}>
              <div className={classes.content}>
                  <div className={classes.progressWrapper}>
                      <CircularProgress />
                  </div>
              </div>
          </div>
      </DashboardLayout>
    )
  }

  renderProcessing = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Portlet
        >
          <PortletContent className={classes.item}>
            <div className={classes.progressWrapper}>
                <CircularProgress />
            </div>
            <Typography
              className={classes.caption}
              variant="h4"
              align="center"
            >
              Your recording is currently being processed and analyzed. This may take some time depending on the length of the recording. <br/><br/>

              The page will automatically update with the results once analysis is complete. Thank you!
            </Typography>
          </PortletContent>
        </Portlet>
      </div>
    )
  }

  renderProcessingProTier = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Portlet
        >
          <PortletContent className={classes.content}>
            <Typography
              className={classes.freeTierCaption}
              variant="h5"
              align="left"
            >
              Your recording is currently being processed. This may take some time depending on the length of the recording. A 5 minute recording may take up to 3 minutes to process. <br/><br/>

              We will send you an email once processing is complete. You can also check the status of the recording by clicking on "Coaching Assistant" on the side menu. Thank you for your patience.
            </Typography>
          </PortletContent>
        </Portlet>
      </div>
    )
  }

  renderContentSentiment = () => {
    const { classes } = this.props;

    if (this.state.results.sentiment_polarity != null) {
      return (
        <Grid
        item
        lg={4}
        sm={6}
        xl={3}
        xs={12}
      >
            <ContentSentiment className={classes.item} data={this.state.results}/>
        </Grid>
      )
    }
  }

  renderContentPositiveWords = () => {
    const { classes } = this.props;
    const tokens = this.state.results.sentiment_positive_tokens

    if (tokens != null) {
      if (tokens.length > 0) {
        return (
          <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
              <ContentPositiveWords className={classes.item} data={this.state.results}/>
          </Grid>
        )
      }
    }
  }

  renderContentNegativeWords = () => {
    const { classes } = this.props;
    const tokens = this.state.results.sentiment_negative_tokens
    
    if (tokens != null) {
      if (tokens.length > 0) {
        return (
          <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
              <ContentNegativeWords className={classes.item} data={this.state.results}/>
          </Grid>
        )
      }
    }
  }

  renderSpeechResults = () => {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={7}
          md={7}
          xl={9}
          xs={12}
        >
          <ViewVideo className={classes.item} videoBlob={this.state.videoBlob} data={this.state.results}/>
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          xl={3}
          xs={12}
        >
          <QuickLook className={classes.item} data={this.state.results}/>
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
          <Pacing className={classes.item} data = {this.state.results}/>
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
          <Energy className={classes.item} data = {this.state.results}/>
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xl={3}
          xs={12}
        >
          <Delivery className={classes.item} data={this.state.results}/>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xl={3}
          xs={12}
        >
          <Fillers className={classes.item} data={this.state.results}/>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xl={3}
          xs={12}
        >
          <Emotions className={classes.item} data={this.state.results}/>
        </Grid>
        {this.renderContentSentiment()}
        {this.renderContentPositiveWords()}
        {this.renderContentNegativeWords()}
      </Grid>
    );
  }

  renderFreeTier = () => {
    const pricingTier = localStorage.getItem('pricingTier')
    if (pricingTier != pricingTiers.free) {
      return
    }
    const { classes } = this.props;
    return (
      <Grid
          item
          lg={7}
          md={7}
          xl={9}
          xs={12}
        >
          <Portlet
          >
            <PortletContent className={classes.content}>
              <Typography
                className={classes.freeTierCaption}
                variant="body1"
              >
                You are using Sonero free tier. Recording will automatically stop after 1 minute. Please upgrade to Sonero Pro to remove this restriction.
              </Typography>
            </PortletContent>
          </Portlet>
      </Grid>
    )
  }

  renderNewSpeech = () => {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={7}
          md={7}
          xl={9}
          xs={12}
        >
          <RecordVideo 
          className={classes.item} 
          uploadSpeech={this.uploadSpeech}
          clientError={this.setError}
          videoBlob={this.state.videoBlob}
          />
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          xl={3}
          xs={12}
        >
          <SpeechDetails 
          className={classes.item} 
          showTitleError={this.state.showTitleError}
          showTypeError={this.state.showTypeError}
          updateTitle={this.updateTitle} 
          updateType={this.updateType}
          title={this.state.title}
          type={this.state.type}
          />
        </Grid>
        {this.renderFreeTier()}
      </Grid>
    );
  }

  renderSpeech = (status) => {
    if (status === speechStatus.processing) {
      const pricingTier = localStorage.getItem('pricingTier')
      if (pricingTier == pricingTiers.free) {
        return this.renderProcessing()
      } else {
        return this.renderProcessingProTier()
      }
    } else if (status === speechStatus.failed) {
      return this.renderError()
    } else if (status === speechStatus.completed) {
      return this.renderSpeechResults()
    }
    return this.renderNewSpeech()
  }

  render() {
    const { classes } = this.props;

    if (this.state.error != null) {
      return this.renderError()
    }

    if (this.state.isLoading) {
      return this.renderLoading()
    }

    return (
      <DashboardLayout title="New Practice Recording">
        <div className={classes.root}>
          {this.renderSpeech(this.state.speechStatus)}
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar
            }}
            message="Sorry, but an unknown error occurred. Please try again."
          />
        </div>
      </DashboardLayout>
      
    )
  }
}

NewSpeech.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewSpeech);
