import ReactGA from 'react-ga';

const VIEW_MEETING = "View Meeting"
const VIEW_SHARED_MEETING = "View Shared Meeting"
const DASHBOARD = "Dashboard";
const TEAM_DASHBOARD = "Team Dasboard";

export const gaCategoryViewMeeting = () => {
  return window.location.href.indexOf("view-meeting/share/") > -1 ? VIEW_SHARED_MEETING : VIEW_MEETING;
}

export const gaCategoryDashboard = () => {
  return window.location.href.indexOf("team-dashboard") > -1 ? TEAM_DASHBOARD : DASHBOARD;
};

export const logGAEvent = (event) => {
  ReactGA.event(event);
  
  const dateJoined = localStorage.getItem("dateJoined");
  const twoWeeksAgo = new Date(Date.now() - 12096e5);
  if (dateJoined && new Date(dateJoined) >= twoWeeksAgo) {
    event.category = "New User - " + event.category;
    ReactGA.event(event);
  }
}
