export default (theme) => ({
  root: {},
  reference: {
    color: theme.palette.primary.brown,
    fontWeight: 500,
  },
  actionType: {
    color: theme.palette.text.darkGrey,
    fontWeight: 700,
  },
  actionItemOwner: {
    color: theme.palette.text.main,
    fontWeight: 700,
    "&:hover": {
      background: "#e1e1e1",
    },
    padding: "3px",
    borderRadius: "3px",
  },
  actionItemOwnerDisabled: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    "&:hover": {
      background: "#e1e1e1",
    },
    padding: "3px",
    borderRadius: "3px",
  },
  actionItemText: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  typeIcon: {
    paddingLeft: theme.spacing.unit * 2,
    color: theme.palette.text.darkGrey,
  },
  actionItemEditingTask: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    width: "100%",
    height: "auto",
    resize: "none",
    padding: theme.spacing.unit,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
  textInset: {
    paddingLeft: theme.spacing.unit * 2,
  },
  textAreaInset: {
    paddingLeft: theme.spacing.unit * 2,
    width: "calc(100% - 60px)",
  },
  phraseTime: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  deleteIcon: {
    color: theme.palette.danger.mellow,
  },
  overflowMenu: {
    display: "flex",
  },
});
