import React, { Component, Fragment } from "react";

import LoadingScreen from "react-loading-screen";

// Externals
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";

// Material helpers
import { withStyles, withWidth } from "@material-ui/core";

// Material components
import { Drawer } from "@material-ui/core";

// Custom components
import { Sidebar, Topbar, Footer } from "./components";

// Component styles
import styles from "./styles";

import { getProfile } from "services/speech";

import ReactGA from "react-ga";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const isMobile = ["xs", "sm", "md"].includes(props.width);

    const fullName = localStorage.getItem("fullName");
    const email = localStorage.getItem("email");

    this.state = {
      isOpen: !isMobile,
      fullName: fullName,
      email: email,
      isLoading: false,
    };
  }

  componentWillMount() {
    if (this.state.email === null) {
      this.setState({
        isLoading: true,
      });
      getProfile().then(
        function(response) {
          if (response.status === 200) {
            const fullName = localStorage.getItem("fullName");
            const email = localStorage.getItem("email");
            this.setState({
              fullName: fullName,
              email: email,
              isLoading: false,
            });
            ReactGA.event({
              category: "Dashboard Layout",
              action: "Get user profile API success",
            });
          } else if (response.status === 401) {
            this.setState({
              fullName: "",
              email: "",
              isLoading: false,
            });
          } else {
            // TODO: Handle error
            ReactGA.event({
              category: "Failure",
              action: "Get user profile API failed",
            });
          }
        }.bind(this)
      );
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleToggleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { classes, width, title, children } = this.props;
    const { isOpen } = this.state;

    const isMobile = ["xs", "sm", "md"].includes(width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;

    if (this.state.isLoading) {
      return (
        <LoadingScreen
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          text="Loading App..."
        ></LoadingScreen>
      );
    }

    return (
      <Fragment>
        <Topbar
          className={classNames(classes.topbar)}
          isSidebarOpen={isOpen}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
          showActionButton={this.props.showActionButton}
          actionButtonHandler={this.props.actionButtonHandler}
          showShareButton={this.props.showShareButton}
          shareButtonHandler={this.props.shareButtonHandler}
          isSharedView={true}
          showMeetingAccount={this.props.showMeetingAccount}
          meetingAccount={this.props.meetingAccount}
          meetingUuid={this.props.meetingUuid}
        />
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen}
          variant={isMobile ? "temporary" : "persistent"}
        >
          <Sidebar
            className={classes.sidebar}
            fullName={this.state.fullName}
            email={this.state.email}
          />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent,
          })}
        >
          {children}
        </main>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withWidth())(Dashboard);
