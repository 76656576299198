import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress, Typography, Button } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Shared services
import { getSpeeches } from 'services/speech';

// Custom components
import { SpeechesTable } from './components';

// Shared components
import {
  Portlet,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';

import ReactGA from 'react-ga';

class MySpeeches extends Component {
  signal = true;

  state = {
    isLoading: false,
    speeches: [],
    error: null,
    selectedSpeech: null
  };

  componentDidMount() {
    this.signal = true;
    this.getMySpeeches();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  getMySpeeches = () => {
    this.setState({ isLoading: true });

    getSpeeches().then(function(response) {
        if (response.status === 200) {
            var speeches = response.data.data
            this.setState({
                isLoading: false,
                speeches
            });
            ReactGA.event({
              category: 'Speech',
              action: 'Get my speeches success'
            });
        } else if (response.status == 401) {
          this.props.history.push('/sign-in');
          ReactGA.event({
            category: 'Failure',
            action: 'Refresh token expired'
          });
        } else {
            this.setState({
                isLoading: false,
                error: "Sorry, couldn't load your recordings because an error occurred"
            });
            ReactGA.event({
              category: 'Failure',
              action: 'Get my speeches failed'
            });
        }
    }.bind(this));
  }

  handleSelect = (speechId) => {
    this.props.history.push({pathname: "/view-recording", state: {id: speechId} })
  };

  handleButtonAction = () => {
    this.props.history.push({pathname: "/new-recording"})
  };

  renderSpeeches() {
    const { classes } = this.props;
    const { isLoading, speeches, error } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (speeches.length === 0) {
      return (
        <Portlet
        >
          <PortletContent className={classes.content}>
            <Typography
              className={classes.caption}
              variant="body1"
            >
              Start refining your communication skills to help you run effective meetings. It's simple! Create a new practice recording and get feedback from the Sonero AI Coaching Assistant!
            </Typography>
          </PortletContent>
        </Portlet>
      )
    }

    return (
      <SpeechesTable
        onSelect={this.handleSelect}
        speeches={speeches}
      />
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Coaching Assistant">
        <div className={classes.root}>
          <div className={classes.actionButton}>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleButtonAction}
            >
              New Practice Recording
            </Button>
          </div>
          <div className={classes.content}>{this.renderSpeeches()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

MySpeeches.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
//   history: PropTypes.object.isRequired
};

export default withStyles(styles)(MySpeeches);
