import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

class Teams extends Component {
  state = {
    connectedMsg: "",
    isConnected: false,
  };

  componentDidMount() {
    if (this.props.isConnected === true) {
      this.setState({ connectedMsg: "Status: Connected", isConnected: true });
    } else {
      this.setState({
        connectedMsg: "Status: Not Connected",
        isConnected: false,
      });
    }
  }

  handleSubmit = () => {
    if (this.state.isConnected === false) {
      ReactGA.event({
        category: "Teams",
        action: "User clicked Connect button",
      });
      this.props.authorize();
    }
  };

  handleDisconnect = () => {
    if (this.state.isConnected) {
      ReactGA.event({
        category: "Teams",
        action: "User clicked Disconnect button",
      });
      this.props.disconnect();
    }
  };

  renderFooter = () => {
    const { classes, className, ...rest } = this.props;
    const { isConnected } = this.state;
    return (
      <PortletFooter className={classes.portletFooter}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="small"
          variant="text"
          onClick={isConnected ? this.handleDisconnect : this.handleSubmit}
        >
          {isConnected ? "Disconnect" : "Connect"}
        </Button>
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Microsoft Outlook & Teams" />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <div className={classes.instructions}>
            <Typography className={classes.caption} variant="body1">
              Connect your Microsoft account with Sonero to have your recorded
              meetings get automatically processed by Sonero.
            </Typography>
          </div>
          <div className={classes.instructions}>
            <Typography className={classes.status} variant="h5">
              {this.state.connectedMsg}
            </Typography>
          </div>
        </PortletContent>
        {this.renderFooter()}
      </Portlet>
    );
  }
}

Teams.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Teams);
