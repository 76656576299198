import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { MetricBar } from "components";
import { Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import {
  getScoreColor,
  getAvgActionItemsCopy,
  getAvgInactiveAttendeesCopy,
  getAvgAgendaTopicsCopy,
  getLabelDescriptor,
} from "helpers/meetingScore";

function MeetingEfficiency(props) {
  const { classes, avgEfficiencyScore, avgEfficiency, handleCollapse } = props;

  return (
    <div className={classes.detailedView}>
      <div className={classes.detailedViewContent}>
        <div className={classes.backButton} onClick={handleCollapse}>
          <ChevronLeft className={classes.largerIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.detailedScore}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Meeting Efficiency
              <span className={classes.score}>
                {Math.round(avgEfficiencyScore)}
                <span className={classes.outOfTen}>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={avgEfficiencyScore * 10}
              barColor={getScoreColor(avgEfficiencyScore)}
              height="10px"
              fillAnimation
            />

            <div className={classes.details}>
              {avgEfficiency.topics_discussed != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Agenda Topics
                    {getLabelDescriptor(avgEfficiency.topics_discussed)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgAgendaTopicsCopy(avgEfficiency.topics_discussed)}
                  </Typography>
                </div>
              )}

              {avgEfficiency.action_items != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Action Items
                    {getLabelDescriptor(avgEfficiency.action_items)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgActionItemsCopy(avgEfficiency.action_items)}
                  </Typography>
                </div>
              )}

              {avgEfficiency.inactive_participants != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Attendee participation
                    {getLabelDescriptor(avgEfficiency.inactive_participants)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgInactiveAttendeesCopy(
                      avgEfficiency.inactive_participants
                    )}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default withStyles(styles)(MeetingEfficiency);
