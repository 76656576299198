import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  item: {
    height: "100%",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  headerTabs: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 2,
  },
  tab: {
    display: "flex",
    alignItems: "center",
  },
  tabButton: {
    padding: "7px !important",
  },
  selected: {
    "&&": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
});
