import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

// Externals
import { Chart } from "react-chartjs-2";

// Material helpers
import { ThemeProvider } from "@material-ui/styles";

// ChartJS helpers
import { chartjs } from "./helpers";

import { gaTrackingId } from "./helpers/appEnv";

import { isSoneroUser } from "./helpers/email";

// Theme
import theme from "./theme";

// Styles
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";

// Routes
import Routes from "./Routes";

import ReactGA from "react-ga";

// Browser history
const browserHistory = createBrowserHistory();

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

var trackingID = gaTrackingId();
if (!isSoneroUser(localStorage.getItem("email"))) {
  ReactGA.initialize(trackingID);
}
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
  console.log(location.pathname);
});

export default class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_API_URL === "production") {
      this.setupCrispChat();
    }
  }

  setupCrispChat() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "fff22116-2933-4d66-9ac0-21840946619b";
    (function() {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);

      let email = localStorage.getItem("email");
      if (email) {
        window.$crisp.push(["set", "user:email", email]);
      }

      let fullName = localStorage.getItem("fullName");
      if (fullName) {
        window.$crisp.push(["set", "user:nickname", [fullName]]);
      }
    })();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
