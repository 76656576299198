export default (theme) => ({
  root: {},
  container: {
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    textRendering: "optimizeLegibility",
    height: "100%",
  },
  payButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  payButton: {
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    width: "100%",
    height: "40px",
  },
  textFieldsContainer: {
    margin: theme.spacing.unit * 4,
    paddingLeft: "20px",
    paddingRight: "20px",
    borderStyle: "none",
    boxShadow:
      "0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "4px",
  },
  textField: {
    fontSize: "16px",
    width: "100%",
    lineHeight: "40px",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    backgroundColor: "transparent",
    animation: "1ms void-animation-out",
    "-webkit-text-fill-color": "#0767DB",
    transition: "background-color 100000000s",
  },
  textFieldLabel: {
    width: "40%",
    minWidth: "140px",
    color: "#66788A",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
  },
  amountTextFieldLabel: {
    width: "40%",
    minWidth: "140px",
    color: "#66788A",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
    fontWeight: 700,
  },
  amountText: {
    position: "relative",
    width: "100%",
  },
  amountTextField: {
    fontWeight: 700,
    fontSize: "16px",
    width: "100%",
    lineHeight: "40px",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    backgroundColor: "transparent",
    animation: "1ms void-animation-out",
    "-webkit-text-fill-color": "#66788A",
    transition: "background-color 100000000s",
  },
  annualCalc: {
    position: "absolute",
    top: 0,
    left: 0,
    fontSize: "9px",
    color: theme.palette.text.secondary,
  },
  nameField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "none",
    lineHeight: "50px",
  },
  emailField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(50, 50, 93, 0.08)",
    lineHeight: "50px",
  },
  discountField: {
    fontSize: "16px",
    width: "100%",
    lineHeight: "40px",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    backgroundColor: "transparent",
    animation: "1ms void-animation-out",
    color: theme.palette.primary.main,
    transition: "background-color 100000000s",
    "&::placeholder": {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
  discountValid: {
    color: theme.palette.success.main,
  },
  discountInvalid: {
    color: theme.palette.danger.main,
  },
  numOfUsersContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  subscriptionField: {
    fontSize: "16px",
    width: "100%",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  subscriptionDropdown: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.25),
  },
  annualToggle: {
    display: "flex",
    alignItems: "center",
  },
  annuallyText: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    "&.on": {
      color: theme.palette.text.primary,
    },
  },
});
