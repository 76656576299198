import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import HelpIcon from "@material-ui/icons/Help";

import {
  getAPIHost,
  getMeetingShareAccess,
  updateMeetingShareAccess,
  sendMeetingShareAccessEmail,
  getFolderShareAccess,
  updateFolderShareAccess,
  sendFolderShareAccessEmail,
} from "services/speech";

import { validateEmail } from "helpers/email";
import { shareAccess, shareAccessCopies } from "helpers/shareAccess";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import ReactGA from "react-ga";

function ShareDialog(props) {
  const { classes } = props;
  const history = useHistory();
  const emailInputRef = useRef(null);
  const email = localStorage.getItem("email");
  const fullName = localStorage.getItem("fullName");

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [uuid, setUuid] = useState("");
  const [title, setTitle] = useState("");
  const [isFolder, setIsFolder] = useState(false);
  const [peopleWithAccess, setPeopleWithAccess] = useState([]);
  const [generalAccess, setGeneralAccess] = useState(shareAccess.restricted);
  const [shareGranularity, setShareGranularity] = useState({
    share_recording: true,
    share_insights: true,
    share_questions: true,
    share_transcript: true,
    share_analytics: true,
    share_notes: true,
    share_attendees: true,
    share_score: true,
  });
  const [isOwner, setIsOwner] = useState("");
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [emailRecipientInput, setEmailRecipientInput] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [generalAccessMenu, setGeneralAccessMenu] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);

  // Fetches share access data when user opens the share dialog of a meeting
  useEffect(() => {
    if (!props.meetingUuid || !props.isShareDialogOpen) return;
    setIsLoading(true);
    handleReset();
    getMeetingShareAccess(props.meetingUuid).then((res) => {
      if (res.status === 200) {
        const {
          meeting_uuid,
          meeting_title,
          general_access,
          people_with_access,
          share_granularity,
          is_owner,
        } = res.data;
        setUuid(meeting_uuid);
        setTitle(meeting_title);
        setIsFolder(false);
        setPeopleWithAccess(people_with_access);
        setGeneralAccess(general_access);
        setShareGranularity(share_granularity);
        setIsOwner(is_owner);
        setHasUnsavedChanges(false);
        setIsLoading(false);
        ReactGA.event({
          category: typeOfMeetingShared(),
          action: "Get meeting share access API success",
        });
      } else if (res.status === 401) {
        history.push("/sign-in");
      } else {
        props.openSnackbar(
          "Sorry, but an unknown error occurred. Please try again."
        );
        ReactGA.event({
          category: "Failure",
          action: "Get meeting share access API failed",
        });
      }
    });
  }, [props.meetingUuid, props.isShareDialogOpen]);

  // Fetches share access data when user opens the share dialog of a folder
  useEffect(() => {
    if (!props.folderUuid || !props.isShareDialogOpen) return;
    setIsLoading(true);
    handleReset();
    getFolderShareAccess(props.folderUuid).then((res) => {
      if (res.status === 200) {
        const {
          folder_uuid,
          folder_name,
          general_access,
          people_with_access,
          share_granularity,
          is_owner,
        } = res.data;
        setUuid(folder_uuid);
        setTitle(folder_name);
        setIsFolder(true);
        setPeopleWithAccess(people_with_access);
        setGeneralAccess(general_access);
        setShareGranularity(share_granularity);
        setIsOwner(is_owner);
        setHasUnsavedChanges(false);
        setIsLoading(false);
        ReactGA.event({
          category: typeOfMeetingShared(),
          action: "Get folder share access API success",
        });
      } else if (res.status === 401) {
        history.push("/sign-in");
      } else {
        props.openSnackbar(
          "Sorry, but an unknown error occurred. Please try again."
        );
        ReactGA.event({
          category: "Failure",
          action: "Get folder share access API failed",
        });
      }
    });
  }, [props.folderUuid, props.isShareDialogOpen]);

  const typeOfMeetingShared = () => {
    if (props.isQuickShare) {
      if (props.isScheduled) {
        return "Quick Share Scheduled";
      }
      return "Quick Share Completed";
    }
    return gaCategoryViewMeeting();
  };

  const handleRecipientInputChange = (event) => {
    if (event.target.value.endsWith(" ") || event.target.value.endsWith(",")) {
      const newEmailRecipient = event.target.value.slice(0, -1);
      if (newEmailRecipient) {
        const newEmailRecipients = [...emailRecipients, newEmailRecipient];
        setEmailRecipients(newEmailRecipients);
      }
      setEmailRecipientInput("");
    } else {
      setEmailRecipientInput(event.target.value);
    }
  };

  const handleRecipientInputKeyDown = (event) => {
    if (event.key === "Enter") {
      if (emailRecipientInput) {
        const newEmailRecipients = [...emailRecipients, emailRecipientInput];
        setEmailRecipients(newEmailRecipients);
      }
      setEmailRecipientInput("");
    } else if (event.key === "Backspace" && emailRecipientInput === "") {
      setEmailRecipients(emailRecipients.slice(0, -1));
    }
  };

  const handleRecipientInputBlur = () => {
    if (emailRecipientInput) {
      const newEmailRecipients = [...emailRecipients, emailRecipientInput];
      setEmailRecipients(newEmailRecipients);
    }
    setEmailRecipientInput("");
  };

  const handleDeleteRecipient = (event, email) => {
    event.stopPropagation();
    const newEmailRecipients = emailRecipients.filter((e) => email !== e);
    setEmailRecipients(newEmailRecipients);
  };

  const handleFocusEmailInput = () => {
    if (!emailInputRef.current) return;
    emailInputRef.current.focus();
  };

  const handleEmailMessageChange = (event) => {
    setEmailMessage(event.target.value);
    setHasUnsavedChanges(true);
  };

  const handleRemoveAccess = (person) => {
    const newPeopleWithAccess = peopleWithAccess.filter(
      (personWithAccess) => personWithAccess.email !== person.email
    );
    setPeopleWithAccess(newPeopleWithAccess);
    setHasUnsavedChanges(true);
  };

  const handleOpenGeneralAccessMenu = (event) => {
    setGeneralAccessMenu(event.currentTarget);
  };

  const handleCloseGeneralAccessMenu = (access) => {
    setGeneralAccessMenu(null);
    if (access) {
      setGeneralAccess(access);
      setHasUnsavedChanges(true);
    }
  };

  const handleShareGranularityChange = (newShareGranularity) => {
    setShareGranularity((currShareGranularity) => ({
      ...currShareGranularity,
      ...newShareGranularity,
    }));
    setHasUnsavedChanges(true);
  };

  const handleCopyLink = () => {
    const shareLink = `${getAPIHost()}/view-${
      isFolder ? "folder" : "meeting"
    }/share/${uuid}`;
    // adding an empty .catch() in case of an error occurring with copying to clipboard
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        props.openSnackbar("Link copied");
        ReactGA.event({
          category: typeOfMeetingShared(),
          action: "User clicked Copy Link",
        });
      })
      .catch(() => {});
  };

  const handleDone = () => {
    setIsUpdating(true);

    // Decide if we're updating the share access of a meeting or a folder
    let updateShareAccess;
    if (props.meetingUuid) {
      updateShareAccess = updateMeetingShareAccess;
    } else if (props.folderUuid) {
      updateShareAccess = updateFolderShareAccess;
    }
    if (!updateShareAccess) return;

    updateShareAccess(
      uuid,
      generalAccess,
      peopleWithAccess,
      shareGranularity,
      props.isQuickShare
    ).then((res) => {
      setIsUpdating(false);
      if (res.status === 200) {
        props.openSnackbar("Share access updated");
        props.handleClose();
        ReactGA.event({
          category: typeOfMeetingShared(),
          action: "Update meeting share access API success",
        });
      } else if (res.status === 401) {
        history.push("/sign-in");
      } else {
        props.openSnackbar(
          "Sorry, but an unknown error occurred. Please try again."
        );
        ReactGA.event({
          category: "Failure",
          action: "Update meeting share access API failed",
        });
      }
    });
  };

  const handleSend = () => {
    // Check for any invalid email addresses
    const invalidEmailIndex = emailRecipients.findIndex(
      (email) => !validateEmail(email)
    );
    if (invalidEmailIndex !== -1) {
      props.openSnackbar("One or more of the recipient emails are invalid");
      return;
    }

    setIsUpdating(true);

    // Decide if we're updating the share access of a meeting or a folder
    let updateShareAccess;
    let sendShareAccessEmail;
    if (props.meetingUuid) {
      updateShareAccess = updateMeetingShareAccess;
      sendShareAccessEmail = sendMeetingShareAccessEmail;
    } else if (props.folderUuid) {
      updateShareAccess = updateFolderShareAccess;
      sendShareAccessEmail = sendFolderShareAccessEmail;
    }
    if (!updateShareAccess) return;

    const newPeopleWithAccess = [
      ...peopleWithAccess,
      ...emailRecipients.map((email) => ({
        name: email,
        email: email,
      })),
    ];

    updateShareAccess(
      uuid,
      generalAccess,
      newPeopleWithAccess,
      shareGranularity,
      props.isQuickShare
    )
      .then((res) => {
        if (res.status === 200) {
          ReactGA.event({
            category: typeOfMeetingShared(),
            action: "Update meeting share access API success",
          });
          return sendShareAccessEmail(uuid, emailRecipients, emailMessage);
        } else if (res && res.status === 401) {
          history.push("/sign-in");
        } else {
          ReactGA.event({
            category: "Failure",
            action: "Update meeting share access API failed",
          });
        }
      })
      .then((res) => {
        setIsUpdating(false);
        if (res && res.status === 200) {
          props.handleClose();
          ReactGA.event({
            category: typeOfMeetingShared(),
            action: "Send meeting share access email API success",
          });
        } else if (res && res.status === 401) {
          history.push("/sign-in");
        } else {
          props.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Send meeting share access email API failed",
          });
        }
      });
  };

  const handleReset = () => {
    setEmailRecipientInput("");
    setEmailRecipients([]);
    setEmailMessage("");
  };

  const handleExit = () => {
    if (!hasUnsavedChanges) {
      props.handleClose();
      return;
    }
    setIsExitDialogOpen(true);
  };

  const handleDiscard = () => {
    setIsExitDialogOpen(false);
    props.handleClose();
  };

  const renderLoading = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap="1rem"
        margin="1rem"
      >
        <CircularProgress size={20} />
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  };

  const renderExitDialog = () => {
    return (
      <Dialog
        open={isExitDialogOpen}
        onClose={() => setIsExitDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant="h5">Discard unsaved changes?</Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsExitDialogOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={handleDiscard}>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderShareDialogContent = () => {
    const allPeopleWithAccess = [
      { name: fullName + " (you)", email: email },
      ...peopleWithAccess,
    ];
    const isSendView = emailRecipients.length > 0;

    return (
      <>
        <div className={classes.header}>
          <Typography className={classes.title}>Share "{title}"</Typography>
          <div
            className={classes.emailInputContainer}
            onClick={handleFocusEmailInput}
          >
            {emailRecipients.map((email) => (
              <Chip
                label={email}
                color={validateEmail(email) ? "primary" : "error"}
                onDelete={(e) => handleDeleteRecipient(e, email)}
              />
            ))}
            <input
              ref={emailInputRef}
              className={classes.emailInput}
              placeholder={
                emailRecipients.length === 0 ? "Enter email addresses" : ""
              }
              value={emailRecipientInput}
              onChange={handleRecipientInputChange}
              onKeyDown={handleRecipientInputKeyDown}
              onBlur={handleRecipientInputBlur}
            />
          </div>
        </div>
        {!isSendView ? (
          <div className={classes.accessContainer} key="access">
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>
                People with access
              </Typography>
              <div>
                {allPeopleWithAccess.map((person) => (
                  <div className={classes.personRow}>
                    <Typography>
                      <span className={classes.name}>{person.name}</span>
                      <span className={classes.email}>{person.email}</span>
                    </Typography>
                    <Typography>
                      {person.email === email ? (
                        <span>Owner</span>
                      ) : (
                        <ClearIcon
                          className={classes.clearIcon}
                          onClick={() => handleRemoveAccess(person)}
                        />
                      )}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>
                General access
                <Tooltip title="Select a group of people for sharing. The meeting will be shared with them and anyone listed in People with Access.">
                  <HelpIcon className={classes.tooltipIcon} />
                </Tooltip>
              </Typography>
              <div className={classes.indentedContent}>
                <Typography
                  className={classes.generalAccessLabel}
                  onClick={handleOpenGeneralAccessMenu}
                >
                  {shareAccessCopies[generalAccess].label}
                  {Boolean(generalAccessMenu) ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </Typography>
                <Typography className={classes.generalAccessDescription}>
                  {shareAccessCopies[generalAccess].description}
                </Typography>
                <Menu
                  anchorEl={generalAccessMenu}
                  getContentAnchorEl={null}
                  open={Boolean(generalAccessMenu)}
                  onClose={() => handleCloseGeneralAccessMenu()}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  {isOwner && (
                    <MenuItem
                      selected={generalAccess === shareAccess.restricted}
                      onClick={() =>
                        handleCloseGeneralAccessMenu(shareAccess.restricted)
                      }
                    >
                      Restricted
                    </MenuItem>
                  )}
                  {isOwner && !isFolder && (
                    <MenuItem
                      selected={generalAccess === shareAccess.attendees}
                      onClick={() =>
                        handleCloseGeneralAccessMenu(shareAccess.attendees)
                      }
                    >
                      Attendees
                    </MenuItem>
                  )}
                  {isOwner && (
                    <MenuItem
                      selected={generalAccess === shareAccess.anyone}
                      onClick={() =>
                        handleCloseGeneralAccessMenu(shareAccess.anyone)
                      }
                    >
                      Anyone with link
                    </MenuItem>
                  )}
                  <MenuItem
                    selected={generalAccess === shareAccess.team}
                    onClick={() =>
                      handleCloseGeneralAccessMenu(shareAccess.team)
                    }
                  >
                    Team
                  </MenuItem>
                  {!isFolder && (
                    <MenuItem
                      selected={generalAccess === shareAccess.attendeesAndTeam}
                      onClick={() =>
                        handleCloseGeneralAccessMenu(
                          shareAccess.attendeesAndTeam
                        )
                      }
                    >
                      Team and Attendees
                    </MenuItem>
                  )}
                  <MenuItem
                    selected={generalAccess === shareAccess.anyoneAndTeam}
                    onClick={() =>
                      handleCloseGeneralAccessMenu(shareAccess.anyoneAndTeam)
                    }
                  >
                    Team and Anyone with link
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>
                Shared Content
              </Typography>
              <div className={classes.indentedContent}>
                <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
                  <div className={classes.shareContentCheckbox}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={shareGranularity.share_recording}
                      onChange={(e) =>
                        handleShareGranularityChange({
                          share_recording: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1">Recording</Typography>
                  </div>
                  <div className={classes.shareContentCheckbox}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={shareGranularity.share_insights}
                      onChange={(e) =>
                        handleShareGranularityChange({
                          share_insights: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1">Insights</Typography>
                  </div>
                  <div className={classes.shareContentCheckbox}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={shareGranularity.share_transcript}
                      onChange={(e) =>
                        handleShareGranularityChange({
                          share_transcript: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1">Transcript</Typography>
                  </div>
                  <div className={classes.shareContentCheckbox}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={shareGranularity.share_analytics}
                      onChange={(e) =>
                        handleShareGranularityChange({
                          share_analytics: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1">Analytics</Typography>
                  </div>
                  <div className={classes.shareContentCheckbox}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={shareGranularity.share_attendees}
                      onChange={(e) =>
                        handleShareGranularityChange({
                          share_attendees: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1">Attendees</Typography>
                  </div>
                  <div className={classes.shareContentCheckbox}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={shareGranularity.share_score}
                      onChange={(e) =>
                        handleShareGranularityChange({
                          share_score: e.target.checked,
                        })
                      }
                    />
                    <Typography variant="body1">Efficiency Score</Typography>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.messageInputContainer} key="message">
            <TextField
              label="Message"
              multiline
              fullWidth
              rows={8}
              value={emailMessage}
              onChange={handleEmailMessageChange}
              variant="filled"
            />
          </div>
        )}
        <div className={classes.footer}>
          <Button
            className={classes.footerButton}
            color="primary"
            variant="outlined"
            onClick={handleCopyLink}
          >
            Copy link
          </Button>

          {isUpdating ? (
            <CircularProgress />
          ) : isSendView ? (
            <Box display="flex" alignItems="center" gridGap="1rem">
              <Button className={classes.footerButton} onClick={handleReset}>
                Cancel
              </Button>
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={handleSend}
              >
                Send
              </Button>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" gridGap="1rem">
              <Button className={classes.footerButton} onClick={handleDiscard}>
                Cancel
              </Button>
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={handleDone}
              >
                Done
              </Button>
            </Box>
          )}
        </div>
      </>
    );
  };

  return (
    <Dialog
      className={classes.shareDialogContainer}
      open={props.isShareDialogOpen}
      onClose={handleExit}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        {isLoading ? renderLoading() : renderShareDialogContent()}
      </DialogContent>
      {renderExitDialog()}
    </Dialog>
  );
}

ShareDialog.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  meetingUuid: PropTypes.string,
  folderUuid: PropTypes.string,
  meetingStatus: PropTypes.string,
  isShareDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  isQuickShare: PropTypes.bool,
  isScheduled: PropTypes.bool,
};

export default withStyles(styles)(ShareDialog);
