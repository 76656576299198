import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

function EmailPreview(props) {
  const { classes, content } = props;
  const contentLines = content.split("\n");
  const ctaRegex = /\[(.+)\](http.+)/;
  const cardRegex = /(\|(.+?): (.+?)\|)+/g;
  return (
    <div className={classes.backdrop}>
      <div className={classes.container}>
        <a href="https://sonero.ai" className={classes.logoLink}>
          sonero
        </a>
        <div className={classes.content}>
          {contentLines.map((line) => {
            if (line === "") {
              return <br></br>;
            }

            const ctaMatch = line.match(ctaRegex);
            if (ctaMatch) {
              return (
                <a href={ctaMatch[2]} className={classes.ctaButton}>
                  {ctaMatch[1]}
                </a>
              );
            }

            const cardsMatch = [...line.matchAll(cardRegex)];
            if (cardsMatch.length > 0) {
              return (
                <div className={classes.cardsContainer}>
                  {cardsMatch.map((cardMatch) => (
                    <div className={classes.card}>
                      <div className={classes.cardContent}>
                        <h4 className={classes.cardHeader}>{cardMatch[2]}</h4>
                        <div className={classes.cardValue}>{cardMatch[3]}</div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            }

            return <div>{line}</div>;
          })}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(EmailPreview);
