export const getNumberOfHighlights = (highlights, clips, isSharedView = false) => {
  let numHighlights = 0;
  if (isSharedView) {
    return numHighlights;
  }
  if (showFillersHighlight(highlights)) numHighlights++;
  if (showPacingHighlight(highlights)) numHighlights++;
  if (showPositiveTopic(highlights)) numHighlights++;
  if (showNegativeTopic(highlights)) numHighlights++;
  const [hasUnread, _] = hasUnreadClipComments(clips);
  if (hasUnread) numHighlights++;
  return numHighlights;
};

export const showQuestionHighlight = (highlights) => {
  return (
    highlights != null &&
    highlights.num_questions_deviation != null &&
    highlights.num_questions_deviation >= 0.5
  );
};

export const showWinHighlight = (highlights) => {
  return (
    highlights != null &&
    highlights.num_wins_deviation != null &&
    highlights.num_wins_deviation >= 0.5
  );
};

export const showOpportunityHighlight = (highlights) => {
  return (
    highlights != null &&
    highlights.num_opportunities_deviation != null &&
    highlights.num_opportunities_deviation >= 0.5
  );
};

export const showFillersHighlight = (highlights) => {
  return (
    highlights != null &&
    highlights.fillers_deviation != null &&
    Math.abs(highlights.fillers_deviation) >= 0.2
  );
};

export const showPacingHighlight = (highlights) => {
  return (
    highlights != null &&
    highlights.pacing_deviation != null &&
    Math.abs(highlights.pacing_deviation) >= 0.2
  );
};

export const showHighPointsHighlight = (highlights) => {
  return highlights != null && highlights.had_high_points;
};

export const showLowPointsHighlight = (highlights) => {
  return highlights != null && highlights.had_low_points;
};

export const showPositiveTopic = (highlights) => {
  return (
    highlights != null &&
    highlights.positive_topic != null
  )
};

export const showNegativeTopic = (highlights) => {
  return (
    highlights != null &&
    highlights.negative_topic != null
  )
};

export const hasUnreadClipComments = (clips) => {
  if (clips) {    
      for (let a = 0; a < clips.length; a++) {
          for (let b = 0; b < clips[a].clip_comments.length; b++) {
              if (!clips[a].clip_comments[b].has_seen) {
                  return [true, clips[a].uuid];
              }
          }
      }
  }
  return [false, ];
}