export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  textFieldsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    width: "25%",
  },
  headerLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textFieldDescription: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  table: {
    width: "100%",
    height: "100%",
  },
  dialogCaption: {
    marginBottom: theme.spacing.unit * 3,
    color: theme.palette.text.secondary,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  searchBar: {
    margin: theme.spacing.unit * 2,
    backgroundColor: theme.palette.common.neutral,
  },
  roleContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.25,
    "&:hover *": {
      visibility: "visible",
    },
  },
  roleIcon: {
    visibility: "hidden",
    cursor: "pointer",
  },
  roleSelect: {
    height: "30px",
    width: "max-content",
  },
});
