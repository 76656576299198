import React, { Component } from "react";
import styles from "./styles";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { CircularProgress, withStyles } from "@material-ui/core";

// Material components
import { Divider, Typography, Button } from "@material-ui/core";

// Shared services
import { requestShare, getProfile } from "services/speech";

import ReactGA from "react-ga";

class RequestShare extends Component {
  state = {
    isLoading: false,
    meetingTitle: "",
    ownerName: "",
    isRequestSent: false,
    error: "",
    message: "",
    isAuthenticated: false,
  };

  componentDidMount() {
    // Hit the server on mount to determine if user is authenticated or not
    getProfile().then((res) => {
      if (res.status !== 401) {
        this.setState({ isAuthenticated: true });
      }
    });
  }

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleRequestShare = () => {
    const meetingUuid = this.props.match.params.uuid;
    const queryParams = new URLSearchParams(this.props.location.search);
    const requesterEmail = queryParams.get("to");
    const requesterName = queryParams.get("to_name");
    const message = this.state.message;

    requestShare(meetingUuid, requesterEmail, requesterName, message).then(
      (response) => {
        this.setState({ isLoading: true });
        if (response.status === 200) {
          const meetingTitle = response.data.meeting_title;
          const ownerName = response.data.owner_name;
          this.setState({
            meetingTitle: meetingTitle,
            ownerName: ownerName,
          });
        } else if (response.status === 403) {
          this.setState({
            error: "Sorry, you cannot request this meeting to be shared.",
          });
        } else {
          this.setState({
            error: "Sorry, an unknown error occurred. Please try again.",
          });
        }
        this.setState({ isLoading: false, isRequestSent: true });
      }
    );
  };

  handleClickSignUp = () => {
    ReactGA.event({
      category: "Request Share",
      action: "Sign up button clicked",
    });
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Typography variant="h4" color="error">
          {this.state.error}
        </Typography>
      </div>
    );
  };

  renderRequestSuccess = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Typography variant="h4">
          We've notified {this.state.ownerName} to share the meeting output for{" "}
          "{this.state.meetingTitle}" with you.
        </Typography>
        {this.state.isAuthenticated ? (
          <a href="/" className={classes.ctaButton}>
            <Button color="primary" variant="contained">
              Back to App
            </Button>
          </a>
        ) : (
          <a
            href="/sign-up?ref=request-share-view"
            className={classes.ctaButton}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleClickSignUp}
            >
              Get your own AI Assistant
            </Button>
          </a>
        )}
      </div>
    );
  };

  renderRequestShare = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.requestAccessText}>
          <Typography className={classes.header}>Request Access</Typography>
          <Typography className={classes.body}>
            Request access, or log into an account with access.
          </Typography>
        </div>
        <textarea
          className={classes.messageTextbox}
          rows={8}
          placeholder="Message (Optional)"
          value={this.state.message}
          onChange={this.handleMessageChange}
        />
        <div className={classes.ctaContainer}>
          {!this.state.isLoading ? (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRequestShare}
            >
              Request Access
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.logoWrapper}>
          <a
            href="https://sonero.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Sonero logo"
              height="100px"
              className={classes.logoImage}
              src="/sonero-static/images/logo_with_name.png"
            />
          </a>
        </div>
        <Divider className={classes.logoDivider} />
        {!this.state.isRequestSent && this.renderRequestShare()}
        {this.state.isRequestSent &&
          !this.state.error &&
          this.renderRequestSuccess()}
        {this.state.isRequestSent && this.state.error && this.renderError()}
      </div>
    );
  }
}

RequestShare.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RequestShare);
