import { colors } from "@material-ui/core";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
    "& .MuiRadio-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.border}`,
      fontSize: 14,
      padding: `${theme.spacing.unit * 0.75}px ${theme.spacing.unit * 1.25}px`,
    },
  },
  instructions: {
    padding: "24px 24px 8px 24px",
  },
  portletFooter: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  header: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 5,
  },
  headerPrimary: {
    color: theme.palette.primary.main,
  },
  caption: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 4,
  },
  captionBottom: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
  captionBottomStepOne: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 4,
  },
  calendarSelectCaption: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 4,
  },
  captionContainer: {
    marginLeft: theme.spacing.unit * 2,
  },
  status: {
    color: theme.palette.text.primary,
  },
  field: {
    marginTop: theme.spacing.unit * 3,
    display: "flex",
    alignItems: "center",
  },
  textField: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  url: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  errorPortlet: {
    marginBottom: "32px",
  },
  teamsLogo: {
    height: "84px",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    backgroundSize: "contain",
  },
  zoomLogo: {
    height: "100px",
    backgroundSize: "contain",
  },
  meetLogo: {
    height: "100px",
    backgroundSize: "contain",
  },
  calendarGoogleLogo: {
    marginTop: theme.spacing.unit * 4,
    height: "100px",
    backgroundSize: "contain",
  },
  conferenceSolutions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: theme.spacing.unit * 2,
  },
  captionBody: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing.unit * 3,
  },
  captionStepTwoFirstParagraph: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 4,
  },
  radioButtonContainer: {
    marginLeft: "50px",
  },
  radioOption: {
    marginTop: "10px",
  },
  radioButtonDescription: {
    display: "block",
    marginLeft: "30px",
  },
  portletHeader: {
    height: "auto",
  },
  connected: {
    color: "green",
  },
  submitButton: {
    marginRight: theme.spacing.unit * 2,
  },
  screenshot: {
    height: "120px",
    backgroundSize: "cover",
  },
  screenshotGrid: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  settingSection: {
    marginBottom: theme.spacing.unit * 3,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  calendarButton: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  methodList: {},
  sectionDivider: {
    marginBottom: theme.spacing.unit * 3,
  },
  footerButtonContainer: {
    width: "max-content",
    marginLeft: "auto",
  },
  lastStepHeader: {
    color: theme.palette.primary.main,
    fontSize: "24px",
    fontWeight: 600,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
  },
  lastStepSubHeader: {
    fontSize: "20px",
    fontWeight: 500,
    margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 3}px`,
  },
  lastStepContent: {
    display: "flex",
    gap: theme.spacing.unit * 6,
    justifyContent: "center",
  },
  lastStepSection: {
    padding: theme.spacing.unit * 2,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "6px",
    boxShadow: `0 3px 6px 0 ${theme.palette.background.grey}`,
    width: "max(30%, 250px)",
  },
  lastStepSettingsContainer: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
    "&[disabled]": {
      opacity: 0.75,
    },
  },
  infoBox: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
    borderRadius: "0.5em",
    padding: theme.spacing(1),
  },
  "@media (max-width: 700px)": {
    lastStepContent: {
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    lastStepSection: {
      width: "max(80%, 150px)",
    },
  },
});
