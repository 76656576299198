export default (theme) => ({
  root: {},
  portletFooter: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  caption: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 2,
  },
  enabledStatus: {
    color: theme.palette.text.success,
  },
  disabledStatus: {
    color: theme.palette.text.danger,
  },
  field: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  url: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
});
