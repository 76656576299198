import React, { Component } from "react";
import PropTypes from "prop-types";
import VideoPlayer from "react-video-markers";
import { withStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Tabs,
  Tab,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./styles";
import theme from "theme";
import {
  convertStringToDuration,
  convertDurationToString,
  formatTimeString,
} from "../../../../helpers/time";
import ViewBookmarks from "../ViewBookmarks";

const tabs = {
  create: 0,
  view: 1,
};

class CreateBookmark extends Component {
  state = {
    totalDuration: 0,
    startDuration: 0,
    endDuration: 0,
    startTimeString: "",
    endTimeString: "",
    description: "",
    showError: false,
    errorMsg: "This field is required",
    isLoading: false,
    editBookmark: null,
    editingStartTime: false,
    editingEndTime: false,
    isPlaying: false,
    volume: 0.5,
    tab: tabs.create,
  };
  // class variables for dragging video marker
  videoProgressBar;
  startTimeMarker;
  endTimeMarker;
  draggingStart = false;
  draggingEnd = false;
  offsetUnit = 0;

  componentWillMount() {
    this.setState({ totalDuration: this.props.totalDuration });
    // With the width of VideoPlayer set to 480px, the progress bar mousemove event.offsetX can have values 0-329
    this.offsetUnit = this.props.totalDuration / 330;
  }

  componentDidUpdate(prevProps) {
    // When opening this create bookmark modal
    if (this.props.open === true && prevProps.open === false) {
      // set start time, end time, description, and editBookmark
      var startDuration = this.props.editBookmark
        ? this.props.editBookmark.start_time
        : this.props.startDuration;
      var endDuration = this.props.editBookmark
        ? this.props.editBookmark.end_time
        : this.props.endDuration;
      if (this.props.editBookmark) {
        this.setState({
          editBookmark: this.props.editBookmark,
          description: this.props.editBookmark.description,
        });
      } else {
        this.setState({ editBookmark: null, description: "" });
      }
      this.updateDurations(startDuration, endDuration);
      this.updateTimeStrings(startDuration, endDuration);

      // Hook up listeners for dragging video markers
      document.addEventListener("mousedown", this.mouseDownListener);
      document.addEventListener("mouseup", this.mouseUpListener);
    }

    // add/remove listeners for editing start time
    if (this.state.editingStartTime) {
      document.addEventListener("keydown", this.editingStartTimeListener);
      document.addEventListener("mouseup", this.exitEditingListener);
    } else {
      document.removeEventListener("keydown", this.editingStartTimeListener);
    }

    // add/remove listeners for editing end time
    if (this.state.editingEndTime) {
      document.addEventListener("keydown", this.editingEndTimeListener);
      document.addEventListener("mouseup", this.exitEditingListener);
    } else {
      document.removeEventListener("keydown", this.editingEndTimeListener);
    }
  }

  componentWillUnmount() {
    this.removeEditingListeners();
    this.removeDragListeners();
  }

  // Event listeners for editing the start and end time
  editingStartTimeListener = (event) => {
    if (event.code.startsWith("Digit")) {
      let startTime = this.state.startTimeString;
      if (startTime.length < 6) {
        startTime += event.code.slice(-1);
        this.setState({
          startTimeString: startTime,
          startDuration: convertStringToDuration(startTime),
        });
      }
    } else if (event.code === "Backspace") {
      let startTime = this.state.startTimeString;
      startTime = startTime.slice(0, -1);
      this.setState({
        startTimeString: startTime,
        startDuration: convertStringToDuration(startTime),
      });
    }
  };

  editingEndTimeListener = (event) => {
    if (event.code.startsWith("Digit")) {
      let endTime = this.state.endTimeString;
      if (endTime.length < 6) {
        endTime += event.code.slice(-1);
        this.setState({
          endTimeString: endTime,
          endDuration: convertStringToDuration(endTime),
        });
      }
    } else if (event.code === "Backspace") {
      let endTime = this.state.endTimeString;
      endTime = endTime.slice(0, -1);
      this.setState({
        endTimeString: endTime,
        endDuration: convertStringToDuration(endTime),
      });
    }
  };

  exitEditingListener = () => {
    this.setState({
      editingStartTime: false,
      editingEndTime: false,
    });
    this.removeEditingListeners();
  };

  removeEditingListeners() {
    document.removeEventListener("keydown", this.editingStartTimeListener);
    document.removeEventListener("keydown", this.editingEndTimeListener);
    document.removeEventListener("mouseup", this.exitEditingListener);
  }

  // Event listeners for dragging video marker to set start and end time
  mouseDownListener = (event) => {
    this.startTimeMarker = document.querySelector("#start-time-marker");
    this.endTimeMarker = document.querySelector("#end-time-marker");
    this.videoProgressBar = document.querySelector("progress");

    if (event.target === this.startTimeMarker) {
      this.draggingStart = true;
      this.styleDraggingBar(true, true);
      if (this.videoProgressBar) {
        this.videoProgressBar.addEventListener(
          "mousemove",
          this.mouseMoveListener
        );
      }
    } else if (event.target === this.endTimeMarker) {
      this.draggingEnd = true;
      this.styleDraggingBar(true, false);
      if (this.videoProgressBar) {
        this.videoProgressBar.addEventListener(
          "mousemove",
          this.mouseMoveListener
        );
      }
    }
  };

  mouseMoveListener = (event) => {
    if (this.draggingStart) {
      const newStartDuration = ~~(event.offsetX * this.offsetUnit);

      let newEndDuration = null;
      if (this.state.endDuration) {
        const startToEndDistance =
          this.state.endDuration - this.state.startDuration;
        newEndDuration = newStartDuration + startToEndDistance;
      }

      if (
        newStartDuration >= 0 &&
        newStartDuration <= this.state.totalDuration
      ) {
        this.setState({
          startDuration: newStartDuration,
          startTimeString: convertDurationToString(newStartDuration),
        });
      }
      if (
        newEndDuration != null &&
        newEndDuration <= this.state.totalDuration
      ) {
        this.setState({
          endDuration: newEndDuration,
          endTimeString: convertDurationToString(newEndDuration),
        });
      }
    } else if (this.draggingEnd) {
      const newEndDuration = ~~(event.offsetX * this.offsetUnit);
      if (
        newEndDuration >= this.state.startDuration &&
        newEndDuration <= this.state.totalDuration
      ) {
        this.setState({
          endDuration: newEndDuration,
          endTimeString: convertDurationToString(newEndDuration),
        });
      }
    }
  };

  mouseUpListener = (event) => {
    this.draggingStart = false;
    this.draggingEnd = false;
    this.dragOffset = 0;
    this.styleDraggingBar(false);

    if (this.videoProgressBar) {
      this.videoProgressBar.removeEventListener(
        "mousemove",
        this.mouseMoveListener
      );
    }
  };

  removeDragListeners() {
    document.removeEventListener("mousedown", this.mouseDownListener);
    document.removeEventListener("mouseup", this.mouseUpListener);
    if (this.videoProgressBar) {
      this.videoProgressBar.removeEventListener(
        "mousemove",
        this.mouseMoveListener
      );
    }
  }

  // extra stlying for when dragging video markers
  styleDraggingBar(isDragging, isStart) {
    if (isDragging) {
      if (isStart && this.startTimeMarker) {
        this.startTimeMarker.style.top = 0;
        this.startTimeMarker.style.height = "18px";
      }
      if (!isStart && this.endTimeMarker) {
        this.endTimeMarker.style.top = 0;
        this.endTimeMarker.style.height = "18px";
      }
      if (this.videoProgressBar) {
        this.videoProgressBar.style.height = "16px";
      }
    } else {
      if (this.startTimeMarker) {
        this.startTimeMarker.style.top = "9px";
        this.startTimeMarker.style.height = "8px";
      }
      if (this.endTimeMarker) {
        this.endTimeMarker.style.top = "9px";
        this.endTimeMarker.style.height = "8px";
      }
      if (this.videoProgressBar) {
        this.videoProgressBar.style.height = "8px";
      }
    }
  }

  // Update and handle methods
  updateDurations = (startDuration, endDuration) => {
    this.setState({
      tab: tabs.create,
      startDuration: +startDuration,
      endDuration: endDuration == null ? null : +endDuration,
    });
  };

  updateTimeStrings = (startDuration, endDuration) => {
    this.setState({
      startTimeString: convertDurationToString(startDuration),
      endTimeString: convertDurationToString(endDuration),
    });
  };

  setError = (message) => {
    this.setState({ showError: true, errorMsg: message });
  };

  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  handleBookmarkEdit = (bookmark) => {
    this.updateDurations(bookmark.start_time, bookmark.end_time);
    this.updateTimeStrings(bookmark.start_time, bookmark.end_time);
    this.setState({
      editBookmark: bookmark,
      description: bookmark.description,
    });
  };

  handleClose = () => {
    this.setState({
      description: "",
      showError: false,
      editingStartTime: false,
      editingEndTime: false,
    });
    this.removeEditingListeners();
    this.removeDragListeners();
    this.props.closeHandler();
  };

  handleTabChange = (_event, tab) => {
    const newState = { tab: tab };
    if (tab === tabs.view) {
      newState.editBookmark = null;
    }
    this.setState(newState);
  };

  handleSave = () => {
    if (
      this.state.endDuration &&
      this.state.endDuration <= this.state.startDuration
    ) {
      this.setError("End time has to be greater than start time");
      return;
    }

    if (
      this.state.startDuration > this.state.totalDuration ||
      (this.state.endDuration &&
        this.state.endDuration > this.state.totalDuration)
    ) {
      this.setError(
        "Start/end times cannot be greater than the duration of the video"
      );
      return;
    }

    if (!this.state.description) {
      this.setError("Please provide a description");
      return;
    }

    this.setState({ isLoading: true, showError: false });
    var bookmark = {
      description: this.state.description,
      startTime: Math.round(this.state.startDuration),
    };
    if (this.state.endDuration) {
      bookmark["endTime"] = Math.round(this.state.endDuration);
    }

    var action = this.props.saveBookmark;

    if (this.state.editBookmark) {
      bookmark["uuid"] = this.state.editBookmark.uuid;
      action = this.props.updateBookmarkFunction;
    }

    action(bookmark).then(
      function(response) {
        if (response.status !== 200) {
          this.setState({ isLoading: false });
          this.setError("Sorry, an error occurred");
        } else {
          this.setState({ isLoading: false });
          this.handleClose();
        }
      }.bind(this)
    );
  };

  // Render methods
  renderBookmarkContent = () => {
    const { classes, className, ...rest } = this.props;
    const {
      startDuration,
      endDuration,
      totalDuration,
      startTimeString,
      endTimeString,
      editingStartTime,
      editingEndTime,
    } = this.state;
    const newEndDuration =
      totalDuration >= startDuration + 60 ? startDuration + 60 : totalDuration;

    return (
      <div className={classes.timeContainer}>
        <div>
          <Typography variant="h6" className={classes.headerLabel}>
            Start Time
          </Typography>
          <Typography
            variant="h1"
            className={`${classes.timeLabel} ${editingStartTime &&
              classes.editingTimeLabel}`}
            onClick={() =>
              this.setState({
                editingStartTime: true,
                editingEndTime: false,
              })
            }
          >
            <div>{formatTimeString(startTimeString)}</div>
          </Typography>
        </div>
        <div>
          <Typography variant="h6" className={classes.headerLabel}>
            <div>End Time</div>
            {endDuration != null ? (
              <IconButton
                size="small"
                onClick={() => this.setState({ endDuration: null })}
              >
                <CloseIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={() =>
                  this.setState({
                    endDuration: newEndDuration,
                    endTimeString: convertDurationToString(newEndDuration),
                  })
                }
              >
                <AddIcon color="primary" />
              </IconButton>
            )}
          </Typography>
          {endDuration != null && (
            <Typography
              variant="h1"
              className={`${classes.timeLabel} ${editingEndTime &&
                classes.editingTimeLabel}`}
              onClick={() =>
                this.setState({
                  editingStartTime: false,
                  editingEndTime: true,
                })
              }
            >
              <div>{formatTimeString(endTimeString)}</div>
            </Typography>
          )}
        </div>
      </div>
    );
  };

  renderCreateBookmark = () => {
    const { classes, className, ...rest } = this.props;
    const markers = [
      {
        id: "start-time-marker",
        time: this.state.startDuration,
        title: "Start Time",
        color: theme.palette.success.main,
      },
    ];
    if (this.state.endDuration) {
      markers.push({
        id: "end-time-marker",
        time: this.state.endDuration,
        title: "End Time",
        color: theme.palette.danger.mellow,
      });
    }

    return (
      <>
        <DialogContent>
          <Typography variant="body1" className={classes.transcriptBody}>
            Drag the markers on the video to adjust the timing of the bookmark,
            or enter the times manually below.
          </Typography>
          <div className={classes.videoPlayerContainer}>
            <VideoPlayer
              url={this.props.videoUrl}
              width="480px"
              height="270px"
              controls={["play", "time", "progress", "volume"]}
              timeStart={this.state.startDuration}
              isPlaying={this.state.isPlaying}
              volume={this.state.volume}
              onPlay={() => this.setState({ isPlaying: true })}
              onPause={() => this.setState({ isPlaying: false })}
              onVolume={(value) => this.setState({ volume: value })}
              markers={markers}
            />
          </div>
          {this.renderBookmarkContent()}
          <TextField
            className={classes.textFieldDescription}
            label="A short description of the bookmark"
            onChange={(event) =>
              this.handleDescriptionChange(event.target.value)
            }
            required
            value={this.state.description}
          />
          {this.state.showError && (
            <Typography className={classes.fieldError} variant="body2">
              {this.state.errorMsg}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          {this.state.isLoading ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <Button onClick={this.handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </>
    );
  };

  renderViewBookmark = () => {
    const {
      classes,
      bookmarks,
      handleBookmarkSelect,
      handleBookmarkDelete,
      handleBookmarkError,
    } = this.props;
    return (
      <div className={classes.viewBookmarkContainer}>
        <ViewBookmarks
          open={true}
          bookmarks={bookmarks}
          isSharedMeetingView={false}
          handleBookmarkSelect={handleBookmarkSelect}
          handleBookmarkDelete={handleBookmarkDelete}
          handleBookmarkError={handleBookmarkError}
          handleBookmarkEdit={this.handleBookmarkEdit}
          closeHandler={() => {}}
          isModal={false}
          hideHint={false}
        />
      </div>
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        value={this.state.tab}
        onChange={this.handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Create Bookmark" value={tabs.create} />
        <Tab label="View Bookmark" value={tabs.view} />
      </Tabs>
    );
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        {this.renderTabs()}
        {this.state.tab === tabs.create && this.renderCreateBookmark()}
        {this.state.tab === tabs.view && this.renderViewBookmark()}
      </Dialog>
    );
  }
}

CreateBookmark.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateBookmark);
