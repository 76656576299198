import React, { Component } from "react";

import PropTypes from "prop-types";

import Paper from "components/Paper";
import {
  SpeakerBreakdown,
  SentimentBreakdown,
  FacialEmotionBreakdown,
  EnergyGraph,
  DeliveryTone,
  FillerBreakdown,
  PacingBreakdown,
} from "../";

import { Grid, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import BarChartIcon from "@material-ui/icons/BarChart";
import SpeedIcon from "@material-ui/icons/Speed";

import { withStyles } from "@material-ui/core";
import styles from "./styles";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { speechStatus } from "helpers";
import { SentimentVerySatisfied } from "@material-ui/icons";

const tabs = {
  speaker: "speaker",
  sentiment: "sentiment",
  emotion: "emotion",
};

class Analytics extends Component {
  state = {
    tab: tabs.speaker,
  };
  timeSpentOnElem = new Date();

  handleTabChange = (tab) => {
    this.setState({ tab: tab });
  };

  handleOnClickTimeLine = (time, elem) => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked " + elem,
      label: "Analytics",
    });
    this.props.setMediaPlayerCurrentTimeAndPlay(time);
  };

  handleOnMouseEnter = () => {
    this.timeSpentOnElem = new Date();
  };

  handleOnMouseLeave = (elem) => {
    let currentMoment = new Date();
    let timeSpent = currentMoment - this.timeSpentOnElem;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Hovered " + elem,
      label: "Analytics",
      value: timeSpent,
    });
  };

  renderSpeakerAndSentimentWidget = () => {
    const { classes } = this.props;
    const { tab } = this.state;
    const hasSpeakers = this.props.data.transcript_results.length > 1;
    const hasSentiment =
      this.props.data.sentiment && this.props.data.sentiment.polarity;
    const hasEmotions =
      this.props.data.analytics &&
      this.props.data.analytics.video_emotions &&
      Object.keys(this.props.data.analytics.video_emotions).length > 0;
    if (!hasSpeakers) {
      return <></>;
    }
    return (
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Paper>
          <ToggleButtonGroup
            className={classes.headerTabs}
            value={this.state.tab}
            onChange={(_event, newValue) => this.handleTabChange(newValue)}
            exclusive
          >
            <ToggleButton
              value={tabs.speaker}
              className={classes.tabButton}
              classes={{ selected: classes.selected }}
              disabled={this.state.tab === tabs.speaker}
            >
              <div className={classes.tab}>
                <div className={classes.iconWrapper}>
                  <BarChartIcon className={classes.icon} />
                </div>
                <div>
                  <Typography className={classes.title} variant="h5">
                    SPEAKER BREAKDOWN
                  </Typography>
                </div>
              </div>
            </ToggleButton>
            {hasSentiment && (
              <ToggleButton
                value={tabs.sentiment}
                className={classes.tabButton}
                classes={{ selected: classes.selected }}
                disabled={this.state.tab === tabs.sentiment}
              >
                <div className={classes.tab}>
                  <div className={classes.iconWrapper}>
                    <SpeedIcon className={classes.icon} />
                  </div>
                  <div>
                    <Typography className={classes.title} variant="h5">
                      SENTIMENT BREAKDOWN
                    </Typography>
                  </div>
                </div>
              </ToggleButton>
            )}
            {hasEmotions && (
              <ToggleButton
                value={tabs.emotion}
                className={classes.tabButton}
                classes={{ selected: classes.selected }}
                disabled={this.state.tab === tabs.emotion}
              >
                <div className={classes.tab}>
                  <div className={classes.iconWrapper}>
                    <SentimentVerySatisfied className={classes.icon} />
                  </div>
                  <div>
                    <Typography className={classes.title} variant="h5">
                      EMOTION BREAKDOWN
                    </Typography>
                  </div>
                </div>
              </ToggleButton>
            )}
          </ToggleButtonGroup>

          {tab === tabs.speaker && (
            <SpeakerBreakdown
              className={classes.item}
              data={this.props.data}
              setMediaPlayerCurrentTimeAndPlay={this.handleOnClickTimeLine}
              style={{ border: "none" }}
              handleOnMouseEnter={this.handleOnMouseEnter}
              handleOnMouseLeave={this.handleOnMouseLeave}
            />
          )}
          {tab === tabs.sentiment && hasSentiment && (
            <SentimentBreakdown
              className={classes.item}
              data={this.props.data}
              setMediaPlayerCurrentTimeAndPlay={this.handleOnClickTimeLine}
              style={{ border: "none" }}
              handleOnMouseEnter={this.handleOnMouseEnter}
              handleOnMouseLeave={this.handleOnMouseLeave}
            />
          )}
          {tab === tabs.emotion && hasEmotions && (
            <FacialEmotionBreakdown
              className={classes.item}
              data={this.props.data}
              setMediaPlayerCurrentTimeAndPlay={this.handleOnClickTimeLine}
              style={{ border: "none" }}
              handleOnMouseEnter={this.handleOnMouseEnter}
              handleOnMouseLeave={this.handleOnMouseLeave}
            />
          )}
        </Paper>
      </Grid>
    );
  };

  renderEnergyWidget = () => {
    const { classes, meetingStatus } = this.props;
    if (
      meetingStatus === speechStatus.completed &&
      (!this.props.data.analytics || !this.props.data.analytics.intensity)
    ) {
      return <></>;
    }
    return (
      <Grid item lg={6} md={12} xl={6} xs={12}>
        <EnergyGraph
          className={classes.item}
          data={this.props.data}
          meetingStatus={meetingStatus}
          handleOnMouseEnter={this.handleOnMouseEnter}
          handleOnMouseLeave={this.handleOnMouseLeave}
        />
      </Grid>
    );
  };

  renderToneWidget = () => {
    const { classes, meetingStatus } = this.props;
    if (
      meetingStatus === speechStatus.completed &&
      (!this.props.data.analytics || !this.props.data.analytics.tone)
    ) {
      return <></>;
    }
    return (
      <Grid item lg={6} md={12} xl={6} xs={12}>
        <DeliveryTone
          className={classes.item}
          data={this.props.data}
          meetingStatus={meetingStatus}
        />
      </Grid>
    );
  };

  renderFillerWidget = () => {
    const { classes, meetingStatus } = this.props;
    if (
      meetingStatus === speechStatus.completed &&
      (!this.props.data.analytics || !this.props.data.analytics.fillers)
    ) {
      return <></>;
    }
    return (
      <Grid item lg={6} md={12} xl={6} xs={12}>
        <FillerBreakdown
          handleOnMouseEnter={this.handleOnMouseEnter}
          handleOnMouseLeave={this.handleOnMouseLeave}
          className={classes.item}
          data={this.props.data}
          constants={this.props.constants}
          meetingStatus={meetingStatus}
        />
      </Grid>
    );
  };

  renderPacingWidget = () => {
    const { classes, meetingStatus } = this.props;
    return (
      <Grid item lg={6} md={12} xl={6} xs={12}>
        <PacingBreakdown
          className={classes.item}
          data={this.props.data}
          meetingStatus={meetingStatus}
        />
      </Grid>
    );
  };

  render() {
    return (
      <Grid container spacing={4}>
        {this.renderSpeakerAndSentimentWidget()}
        {this.renderEnergyWidget()}
        {this.renderToneWidget()}
        {this.renderFillerWidget()}
        {this.renderPacingWidget()}
      </Grid>
    );
  }
}

Analytics.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Analytics);
