export default (theme) => ({
  container: {
    width: "80%",
    margin: "auto",
    padding: theme.spacing(4),
  },
  copy: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    width: "fit-content",
    margin: `0 auto ${theme.spacing(1.5)}px`,
  },
  barColour: {
    backgroundColor: theme.palette.primary.light,
  },
});
