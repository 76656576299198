export default (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    height: "64px",
    zIndex: theme.zIndex.appBar,
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    flexShrink: 0,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: "pointer",
  },
  logoName: {
    color: theme.palette.text.main,
    marginBottom: theme.spacing.unit * 0.5,
  },
  logoDivider: {
    marginBottom: theme.spacing.unit * 2,
  },
  toolbar: {
    minHeight: "auto",
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  menuButton: {
    marginLeft: "-4px",
  },
  notificationsButton: {
    marginLeft: "auto",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 2,
    width: "max-content",
    marginLeft: "auto",
    marginRight: theme.spacing.unit * 1.25,
  },
  dropDownButton: {
    padding: "1.5px",
    minWidth: 0,
  },
});
