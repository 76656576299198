export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  deleteButton: {
    color: theme.palette.danger.main,
  },
  deleteCaption: {
    color: theme.palette.text.primary,
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
});
