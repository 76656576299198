import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { MetricBar } from "components";
import { Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import {
  getScoreColor,
  getAvgToneCopy,
  getAvgEnergyCopy,
  getAvgFillerCopy,
  getLabelDescriptor,
} from "helpers/meetingScore";

function MyPerformance(props) {
  const {
    classes,
    avgPerformanceScore,
    avgPerformance,
    handleCollapse,
    fillerWpm,
    memberName,
  } = props;

  let prefix = "My";
  if (memberName) {
    prefix = memberName.split(" ")[0];
    if (prefix.endsWith("s")) {
      prefix += "'";
    } else {
      prefix += "'s";
    }
  }

  return (
    <div className={classes.detailedView}>
      <div className={classes.detailedViewContent}>
        <div className={classes.backButton} onClick={handleCollapse}>
          <ChevronLeft className={classes.largerIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.detailedScore}>
          <Typography variant="h6" className={classes.scoreLabel}>
            {prefix} Performance
            <span className={classes.score}>
              {Math.round(avgPerformanceScore)}
              <span className={classes.outOfTen}>/10</span>
            </span>
          </Typography>
          <MetricBar
            barPercentage={avgPerformanceScore * 10}
            barColor={getScoreColor(avgPerformanceScore)}
            height="10px"
            fillAnimation
          />

          <div className={classes.details}>
            {avgPerformance.intensity != null && (
              <div className={classes.subScoreContainer}>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " label"}
                >
                  Energy
                  {getLabelDescriptor(avgPerformance.intensity)}
                </Typography>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " detail"}
                >
                  {getAvgEnergyCopy(avgPerformance.intensity, false)}
                </Typography>
              </div>
            )}

            {avgPerformance.tone != null && (
              <div className={classes.subScoreContainer}>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " label"}
                >
                  Delivery Tone
                  {getLabelDescriptor(avgPerformance.tone)}
                </Typography>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " detail"}
                >
                  {getAvgToneCopy(avgPerformance.tone, false)}
                </Typography>
              </div>
            )}

            {avgPerformance.filler_per_min != null && (
              <div className={classes.subScoreContainer}>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " label"}
                >
                  Fillers {getLabelDescriptor(avgPerformance.filler_per_min)}
                </Typography>
                <Typography
                  variant="body"
                  className={classes.subScoreText + " detail"}
                >
                  {getAvgFillerCopy(avgPerformance.filler_per_min, fillerWpm)}
                </Typography>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default withStyles(styles)(MyPerformance);
