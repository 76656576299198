import { fade } from "@material-ui/core";
import {
  takeawaysColour,
  winsColour,
  opportunitiesColour,
  painPointsColour,
  questionsColour,
} from "../../styles";

const timelineHeight = 6;
const timelinePadding = 15;
export const tickHeight = 28;

export default (theme) => ({
  timelineContainer: {
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px`,
  },
  timeline: {
    width: "calc(100% - 20px)",
    height: `${timelineHeight}px`,
    background: theme.palette.background.grey,
    position: "relative",
    left: "10px",
    cursor: "pointer",
    "&::before": {
      content: `''`,
      width: "10px",
      height: `${timelineHeight}px`,
      background: theme.palette.background.grey,
      position: "absolute",
      left: "-10px",
      top: 0,
    },
    "&::after": {
      content: `''`,
      width: "10px",
      height: `${timelineHeight}px`,
      background: theme.palette.background.grey,
      position: "absolute",
      right: "-10px",
      top: 0,
    },
  },
  timelineTick: {
    position: "absolute",
    top: `${timelineHeight / 2 - tickHeight / 2}px`,
    width: "4px",
    height: `${tickHeight}px`,
    cursor: "pointer",
    transition: "transform 0.1s, height 0.1s, top 0.1s",
    "&.selected": {
      height: `${tickHeight + 12}px`,
      top: `${timelineHeight / 2 - tickHeight / 2 - 6}px`,
      "& > *": {
        top: `${tickHeight + 14}px`,
      },
    },
    "&:hover": {
      transform: "scale(1.25, 1.25)",
      "& > *": {
        display: "block",
      },
    },
  },
  tickIcon: {
    display: "none",
    width: "12px",
    height: "12px",
    position: "absolute",
    top: `${tickHeight + 2}px`,
    left: "50%",
    transform: "translateX(-50%)",
  },
  timelineOptions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing.unit * 4,
    justifyItems: "center",
  },
  option: {
    width: "100%",
    maxWidth: "250px",
    height: "40px",
    boxSizing: "border-box",
    color: theme.palette.background.lightGrey,
    cursor: "pointer",
    textTransform: "none",
    fontSize: "11px",
    "&.selected": {
      border: `1px solid`,
      fontWeight: "500",
    },
    "&.takeaways": {
      background: takeawaysColour,
    },
    "&.takeaways.selected": {
      borderColor: takeawaysColour,
      color: takeawaysColour,
      background: fade(takeawaysColour, 0.2),
    },
    "&.questions": {
      background: questionsColour,
    },
    "&.questions.selected": {
      borderColor: questionsColour,
      color: questionsColour,
      background: fade(questionsColour, 0.2),
    },
  },
  optionIcon: {
    width: "16px",
    height: "16px",
    "@media (min-width: 1280px) and (max-width: 1350px)": {
      display: "none",
    },
  },
  hoverSnippet: {
    position: "absolute",
    background: theme.palette.common.white,
    padding: theme.spacing.unit * 2,
    paddingTop: 0,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.border}`,
    boxShadow: `0 5px 7px ${fade(theme.palette.common.black, 0.5)}`,
    width: "max-content",
    zIndex: 1,
    cursor: "pointer",
    maxHeight: "max(60vh, 300px)",
    overflowY: "scroll",
  },
  snippetContent: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  snippetSegment: {
    marginBottom: theme.spacing.unit,
  },
  snippetSpeaker: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    whiteSpace: "pre-wrap",
  },
  snippetBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    marginLeft: theme.spacing.unit * 3,
    width: "max-content",
    maxWidth: "400px",
    "& > p": {
      marginBottom: theme.spacing.unit * 0.5,
      padding: 0,
      lineHeight: "1.5",
    },
  },
  snippetHeader: {
    fontFamily: "Roboto",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing.unit * 2,
  },
  snippetHeaderLabel: {
    padding: theme.spacing.unit,
    borderRadius: "3px",
    color: theme.palette.background.lightGrey,
    "&.takeaways": {
      background: takeawaysColour,
    },
    "&.questions": {
      background: questionsColour,
    },
    "&.answer": {
      background: theme.palette.primary.brown,
    },
  },
  snippetHeaderTimestamp: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  headerChipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
  },
  headerChip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    padding: `0 ${theme.spacing.unit}px`,
    height: "25px",
    borderRadius: "50px",
    fontFamily: "Roboto",
    transition: "height 0.2s, border-radius 0.1s",
    "&.win": {
      backgroundColor: winsColour,
    },
    "&.opportunity": {
      backgroundColor: opportunitiesColour,
    },
    "&.painPoint": {
      backgroundColor: painPointsColour,
    },
    "&.thrilled": {
      backgroundColor: theme.palette.success.main,
    },
    "&.down": {
      backgroundColor: theme.palette.danger.mellow,
    },
    "&.expandable:hover": {
      flexDirection: "column",
      height: "50px",
      borderRadius: "5px",
    },
    "&.expandable:hover $hoverTooltip": {
      display: "block",
    },
  },
  chipContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
    fontSize: "12px",
    "& > .icon": {
      width: "15px",
      height: "15px",
    },
  },
  directAnswer: {
    color: theme.palette.primary.brown,
    fontWeight: 700,
    cursor: "pointer",
  },
  hoverTooltip: {
    display: "none",
    color: theme.palette.background.lightGrey,
    fontSize: "9px",
    maxWidth: "100px",
    marginTop: theme.spacing.unit * 0.5,
  },
  timestamp: {
    position: "absolute",
    bottom: `${tickHeight - 3}px`,
    left: 0,
    padding: theme.spacing.unit * 0.5,
    paddingTop: theme.spacing.unit * 0.15,
    paddingBottom: theme.spacing.unit * 0.15,
    background: fade(theme.palette.primary.light, 0.75),
    color: theme.palette.text.secondary,
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 600,
    transform: "translateX(-50%)",
  },
  timelinePadding: {
    position: "absolute",
    left: 0,
    top: `-${timelinePadding}px`,
    bottom: 0,
    width: "100%",
    height: `${timelinePadding}px`,
    "&::after": {
      content: `''`,
      position: "absolute",
      left: 0,
      bottom: `-${timelineHeight + timelinePadding}px`,
      width: "100%",
      height: `${timelinePadding}px`,
    },
  },
  deleteIcon: {
    color: theme.palette.danger.mellow,
  },
});
