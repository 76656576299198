export const emotions = {
  joy: 'joy',
  sadness: 'sadness',
  fear: 'fear',
  anger: 'anger',
  neutral: 'neutral'
};

export const getEmotionPrediction = (emotionsProbability, confidenceThreshold=0) => {
  const maxProb = Math.max(...Object.values(emotionsProbability));
  if (maxProb < confidenceThreshold) {
    return emotions.neutral;
  }
  for (let emotion of Object.keys(emotions)) {
    if (emotionsProbability[emotions[emotion]] === maxProb) {
      return emotion;
    }
  }
};
