import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { TextField, MenuItem, Button } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import palette from "theme/palette";

class SubscriptionStatusForm extends Component {
  state = {
    subscriptionStatus: "",
  };

  componentWillMount() {
    this.setState({
      subscriptionStatus: this.props.subscriptionStatus,
    });
  }

  handlePaymentChangeClick = () => {
    this.props.handleShowSeatWidget(false);
    this.props.handleShowCardWidget(true);
  };

  handleSeatChangeClick = () => {
    this.props.handleShowCardWidget(false);
    this.props.handleShowSeatWidget(true);
  };

  handleCancelSubscription = () => {
    this.props.handleOpenCancelSubscriptionDialog();
  };

  getSubscriptionStatusCopy = () => {
    switch (this.state.subscriptionStatus) {
      case "active":
        return (
          <p>
            Your current subscription status: <b>ACTIVE</b>
          </p>
        );
      case "past_due":
        return (
          <p>
            Your current subscription status: <b>PAST DUE</b> <br /> <br />
            Please update your payment method to pay your invoice or contact us
            to resolve any billing issues
          </p>
        );
      case "incomplete":
        return (
          <p>
            Your current subscription status: <b>INCOMPLETE</b> <br /> <br />
            Please update your payment method to pay your invoice or contact us
            to resolve any billing issues
          </p>
        );
      case "canceled":
      case "unpaid":
        return (
          <p>
            Your current subscription status: <b>NOT ACTIVE</b> <br /> <br />
            You have to re-subscribe or contact us to resolve any billing issues
          </p>
        );
      default:
        return (
          <p>
            Your current subscription status: <b>ACTIVE</b>
          </p>
        );
    }
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader className={classes.header}>
          <PortletLabel title="Subscription Details" />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <Typography className={classes.contentText} variant="body1">
            {this.getSubscriptionStatusCopy()}
          </Typography>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            className={classes.actionButton}
            color="primary"
            size="small"
            variant="outlined"
            onClick={this.handlePaymentChangeClick}
          >
            CHANGE PAYMENT METHOD
          </Button>
          <Button
            className={classes.actionButton}
            color="primary"
            size="small"
            variant="outlined"
            onClick={this.handleSeatChangeClick}
          >
            UPDATE SUBSCRIPTION
          </Button>
          <Button
            className={classes.cancelButton}
            size="small"
            variant="outlined"
            onClick={this.handleCancelSubscription}
          >
            CANCEL SUBSCRIPTION
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

SubscriptionStatusForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubscriptionStatusForm);
