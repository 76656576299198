import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  CheckCircle,
  Cancel,
  ArrowDropDown,
  ArrowDropUp,
} from "@material-ui/icons";

import { checkStatuses, getDiscountTooltip } from "helpers/discount";
import pricingTiers from "helpers/pricingTiers";

// Shared components
import { Portlet } from "components";

import styles from "./styles";

const billingCycles = {
  monthly: "monthly",
  annually: "annually",
};

class SeatNumberWidget extends Component {
  state = {
    processing: false,
    prevPricingTier: pricingTiers.professional,
    prevBilledAnnually: false,
    prevNumOfUsers: 0,
    prevDiscountCode: "",
    subscriptionMenu: null,
    cycleMenu: null,
  };

  componentDidMount() {
    const {
      pricingTier,
      billedAnnually,
      numOfUsers,
      discountCode,
      switchToPricingTier,
      handleChangePricingTier,
    } = this.props;
    this.setState({
      prevPricingTier: pricingTier,
      prevBilledAnnually: billedAnnually,
      prevNumOfUsers: numOfUsers,
      prevDiscountCode: discountCode,
    });
    if (switchToPricingTier) {
      handleChangePricingTier(switchToPricingTier);
    }
  }

  getTotalAmount = () => {
    let amount = this.props.price;
    if (this.props.numOfUsers > 1) {
      amount = this.props.numOfUsers * amount;
    }

    const discount = this.props.discount;
    let discountString = "";
    // Apply any discounts from the discount code entered
    if (discount && Object.keys(discount).length > 0) {
      if (discount.percent_off > 0) {
        amount -= amount * (discount.percent_off / 100);
        discountString = `(incl -${discount.percent_off.toFixed(0)}%)`;
      } else if (discount.amount_off > 0) {
        amount -= discount.amount_off;
        discountString = `(incl -$${discount.amount_off.toFixed(2)})`;
      }
    }
    // No discount found in the discount code field. Apply any discount the user currently has
    else if (this.props.percentOff > 0) {
      amount -= amount * (this.props.percentOff / 100);
      discountString = `(incl -${this.props.percentOff.toFixed(0)}%)`;
    } else if (this.props.amountOff > 0) {
      amount -= this.props.amountOff;
      discountString = `(incl -$${this.props.amountOff.toFixed(2)})`;
    }

    if (this.props.billedAnnually) {
      return `$${(amount * 12).toFixed(2)} / year ${discountString}`;
    }

    return `$${amount.toFixed(2)} / month ${discountString}`;
  };

  handleOpenSubscriptionMenu = (event) => {
    this.setState({ subscriptionMenu: event.currentTarget });
  };

  handleCloseSubscriptionMenu = () => {
    this.setState({ subscriptionMenu: null });
  };

  handleSubscriptionMenuSelect = (tier) => {
    this.props.handleChangePricingTier(tier);
    this.handleCloseSubscriptionMenu();
  };

  handleOpenCycleMenu = (event) => {
    this.setState({ cycleMenu: event.currentTarget });
  };

  handleCloseCycleMenu = () => {
    this.setState({ cycleMenu: null });
  };

  handleCycleMenuSelect = (cycle) => {
    const { billedAnnually, handleToggleAnnualBilling } = this.props;
    if (billedAnnually && cycle === billingCycles.monthly) {
      handleToggleAnnualBilling();
    } else if (!billedAnnually && cycle === billingCycles.annually) {
      handleToggleAnnualBilling();
    }
    this.handleCloseCycleMenu();
  };

  handleSave = () => {
    const {
      pricingTier,
      billedAnnually,
      numOfUsers,
      discountCode,
      updateSubscriptionDetails,
      applyDiscountToSubscription,
    } = this.props;
    const {
      prevPricingTier,
      prevBilledAnnually,
      prevNumOfUsers,
      prevDiscountCode,
    } = this.state;
    const apiCalls = [];

    this.setState({ processing: true });

    if (
      pricingTier !== prevPricingTier ||
      billedAnnually !== prevBilledAnnually ||
      numOfUsers !== prevNumOfUsers
    ) {
      apiCalls.push(
        updateSubscriptionDetails(pricingTier, billedAnnually, numOfUsers)
      );
    }
    if (discountCode !== prevDiscountCode) {
      apiCalls.push(applyDiscountToSubscription(discountCode));
    }
    Promise.all(apiCalls).finally(() => {
      this.setState({
        processing: false,
        prevNumOfUsers: numOfUsers,
        prevDiscountCode: discountCode,
      });
    });
  };

  renderSubscriptionField = () => {
    const { classes, pricingTier } = this.props;
    const { subscriptionMenu } = this.state;
    const subscription =
      pricingTier === pricingTiers.business
        ? "Business"
        : pricingTier === pricingTiers.professional
        ? "Professional"
        : pricingTier === pricingTiers.pro
        ? "Pro"
        : pricingTier === pricingTiers.essential
        ? "Essential"
        : "";

    return (
      <div className={classes.nameField}>
        <label htmlFor="users" className={classes.textFieldLabel}>
          Subscription
        </label>
        <div className={classes.subscriptionField}>
          <div
            className={classes.subscriptionDropdown}
            onClick={this.handleOpenSubscriptionMenu}
          >
            {subscription}
            {Boolean(subscriptionMenu) ? <ArrowDropUp /> : <ArrowDropDown />}
          </div>
        </div>
        <Menu
          anchorEl={subscriptionMenu}
          open={Boolean(subscriptionMenu)}
          onClose={this.handleCloseSubscriptionMenu}
          style={{ transform: "translateY(35px)" }}
        >
          <MenuItem
            onClick={() => {
              this.handleSubscriptionMenuSelect(pricingTiers.essential);
            }}
          >
            Essential
          </MenuItem>
          <MenuItem
            onClick={() =>
              this.handleSubscriptionMenuSelect(pricingTiers.professional)
            }
          >
            Professional
          </MenuItem>
          <MenuItem
            onClick={() =>
              this.handleSubscriptionMenuSelect(pricingTiers.business)
            }
          >
            Business
          </MenuItem>
        </Menu>
      </div>
    );
  };

  renderBillingCycleField = () => {
    const { classes, billedAnnually } = this.props;
    const { cycleMenu } = this.state;

    return (
      <div className={classes.nameField}>
        <label htmlFor="users" className={classes.textFieldLabel}>
          Billing Cycle
        </label>
        <div className={classes.subscriptionField}>
          <div
            className={classes.subscriptionDropdown}
            onClick={this.handleOpenCycleMenu}
          >
            {billedAnnually ? "Annually" : "Monthly"}
            {Boolean(cycleMenu) ? <ArrowDropUp /> : <ArrowDropDown />}
          </div>
        </div>
        <Menu
          anchorEl={cycleMenu}
          open={Boolean(cycleMenu)}
          onClose={this.handleCloseCycleMenu}
          style={{ transform: "translateY(35px)" }}
        >
          <MenuItem
            onClick={() => this.handleCycleMenuSelect(billingCycles.monthly)}
          >
            Monthly
          </MenuItem>
          <MenuItem
            onClick={() => this.handleCycleMenuSelect(billingCycles.annually)}
          >
            Annually
          </MenuItem>
        </Menu>
      </div>
    );
  };

  renderNumUsersField = () => {
    const { classes, numOfUsers, handleNumOfUsersChange } = this.props;

    return (
      <div className={classes.nameField}>
        <label htmlFor="users" className={classes.textFieldLabel}>
          Number of Users
        </label>
        <input
          className={classes.textField}
          id="users"
          type="number"
          placeholder=""
          min="1"
          required
          autoComplete="users"
          value={numOfUsers}
          onChange={handleNumOfUsersChange}
        />
      </div>
    );
  };

  renderTotalAmountField = () => {
    const { classes, price, numOfUsers, billedAnnually } = this.props;
    const annualCalc = `$${price.toFixed(2)} * ${numOfUsers} seats * 12 months`;

    return (
      <div className={classes.emailField}>
        <label htmlFor="users" className={classes.amountTextFieldLabel}>
          Total Amount
        </label>
        <div className={classes.amountText}>
          <input
            className={classes.amountTextField}
            id="amount"
            type="text"
            placeholder=""
            required
            disabled
            autoComplete="amount"
            value={this.getTotalAmount()}
          />
          {billedAnnually && (
            <Typography className={classes.annualCalc}>{annualCalc}</Typography>
          )}
        </div>
      </div>
    );
  };

  renderDiscountField = () => {
    const {
      classes,
      discountCode,
      handleDiscountCodeChange,
      discount,
      discountCheckStatus,
    } = this.props;
    const tooltip = getDiscountTooltip(discount);

    return (
      <div className={classes.nameField}>
        <label htmlFor="users" className={classes.textFieldLabel}>
          Discount Code
        </label>
        <input
          className={classes.discountField}
          id="discount"
          type="text"
          placeholder="CODE"
          value={discountCode}
          onChange={handleDiscountCodeChange}
        />
        {discountCheckStatus === checkStatuses.checking && (
          <CircularProgress style={{ width: "30px", height: "20px" }} />
        )}
        {discountCheckStatus === checkStatuses.valid && (
          <Tooltip open={true} title={tooltip} arrow>
            <CheckCircle className={classes.discountValid} />
          </Tooltip>
        )}
        {discountCheckStatus === checkStatuses.invalid && (
          <Tooltip open={true} title={tooltip} arrow>
            <Cancel className={classes.discountInvalid} />
          </Tooltip>
        )}
      </div>
    );
  };

  render() {
    const { classes, renderSave } = this.props;

    return (
      <Portlet className={classes.numOfUsersContainer}>
        <div className={classes.container}>
          <div className={classes.textFieldsContainer}>
            {this.renderSubscriptionField()}
            {this.renderBillingCycleField()}
            {this.renderNumUsersField()}
            {this.renderTotalAmountField()}
          </div>
          <div className={classes.textFieldsContainer}>
            {this.renderDiscountField()}
          </div>
          {renderSave && (
            <div className={classes.payButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                className={classes.payButton}
                onClick={this.handleSave}
                disabled={this.state.processing}
              >
                {this.state.processing ? "Processing..." : "SAVE"}
              </Button>
            </div>
          )}
        </div>
      </Portlet>
    );
  }
}

SeatNumberWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SeatNumberWidget);
