import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
} from "@material-ui/core";

import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components
import {
  BasicInformation,
  NewPassword,
  CreateOrg,
  OrgMembersTable,
  SpeakerEnrollment,
  LanguageSettings,
} from "./components";

// Shared services
import {
  getProfile,
  changePassword,
  createOrg,
  getOrg,
  addOrgMember,
  removeOrgMember,
  updateOrgRole,
  inviteNewUserToOrg,
  getInvitedUsers,
  deleteInvitedUser,
  postLanguageCode,
} from "services/speech";

import { ROLES } from "helpers/user";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  gridContainer: {
    marginTop: theme.spacing.unit * 2,
  },
  toggleButtonsContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

class Account extends Component {
  state = {
    results: {},
    isLoading: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    isUserInOrg: false,
    org: {},
    invitedUsers: [],
    userInfo: {},
    toggledPage: "account",
  };

  componentWillMount() {
    this.setState({ isLoading: true });

    let requests = [];
    requests.push(this.getMyOrg());
    requests.push(this.getInvitedUsersForMyOrg());
    requests.push(this.getMyProfile());

    Promise.all(requests).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  getMyOrg = () => {
    return getOrg().then(
      function(response) {
        if (response.status === 200 && response.data) {
          response.data.members = response.data.members.map((member) => {
            member.name = member.first_name + " " + member.last_name;
            member.role = ROLES[member.role];
            return member;
          });
          this.setState({ isUserInOrg: true, org: response.data });
        }
      }.bind(this)
    );
  };

  getInvitedUsersForMyOrg = () => {
    return getInvitedUsers().then(
      function(response) {
        if (response.status === 200 && response.data) {
          const invitedUsers = response.data.invited_users.map(
            (invitedUser) => ({
              name: "N/A",
              email: invitedUser.email_address,
              role: ROLES[4],
            })
          );
          this.setState({ isUserInOrg: true, invitedUsers: invitedUsers });
        }
      }.bind(this)
    );
  };

  getMyProfile = () => {
    return getProfile().then(
      function(response) {
        if (response.status === 200 && response.data) {
          this.setState({ userInfo: response.data });
        }
      }.bind(this)
    );
  };

  changePassword = (newPassword1, newPassword2) => {
    this.setState({ isLoading: true });
    changePassword(newPassword1, newPassword2).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Your password has been changed",
            isLoading: false,
          });
          ReactGA.event({
            category: "User",
            action: "Change password API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          const passwordErrors = response.data.new_password2;
          if (passwordErrors != null) {
            if (
              passwordErrors.includes(
                "This password is too short. It must contain at least 8 characters."
              )
            ) {
              this.setState({
                snackbarOpen: true,
                snackbarMessage:
                  "Sorry, the password must contain at least 8 characters.",
                isLoading: false,
              });
              ReactGA.event({
                category: "Failure",
                action: "Change password API returned password too short",
              });
              return;
            }
          }

          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
            isLoading: false,
          });
          ReactGA.event({
            category: "Failure",
            action: "Changed password API failed",
          });
        }
        this.setState({});
      }.bind(this)
    );
  };

  createOrg = (orgName) => {
    this.setState({ isLoading: true });
    createOrg(orgName).then(
      function(response) {
        if (response.status === 201 && response.data) {
          response.data.members = response.data.members.map((member) => {
            member.name = member.first_name + " " + member.last_name;
            member.role = ROLES[member.role];
            return member;
          });
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Organization created",
            isLoading: false,
            isUserInOrg: true,
            org: response.data,
          });
          ReactGA.event({
            category: "Org",
            action: "Create org API success",
          });
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Please verify your email address first!",
            isLoading: false,
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
            isLoading: false,
          });
          ReactGA.event({
            category: "Failure",
            action: "Create org API failed",
          });
        }
      }.bind(this)
    );
  };

  addOrgMember = (email) => {
    return addOrgMember(email).then(
      function(response) {
        if (response.status === 201 && response.data) {
          response.data.members = response.data.members.map((member) => {
            member.name = member.first_name + " " + member.last_name;
            member.role = ROLES[member.role];
            return member;
          });
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Team member added to the organization",
            isUserInOrg: true,
            org: response.data,
          });
          ReactGA.event({
            category: "Org",
            action: "Add org member API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          if (response.data.error_code) {
            var errorCode = response.data.error_code;
            if (errorCode == "license_purchase_required") {
              this.setState({
                snackbarOpen: true,
                snackbarMessage:
                  "Please purchase additional licenses to add new team members",
              });
              ReactGA.event({
                category: "Failure",
                action:
                  "Add org member API failed because additional license required",
              });
              return response;
            } else if (errorCode == "member_does_not_exist") {
              ReactGA.event({
                category: "Failure",
                action:
                  "Add org member API failed because user does not have Sonero account",
              });
              return response;
            } else if (errorCode == "domain_mismatch") {
              this.setState({
                snackbarOpen: true,
                snackbarMessage:
                  "User could not be added because the email address does not belong to same domain as the organization",
              });
              ReactGA.event({
                category: "Failure",
                action: "Add org member API failed because of domain mismatch",
              });
              return response;
            }
          }
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
          });
          ReactGA.event({
            category: "Failure",
            action: "Add org member API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  removeOrgMember = (email) => {
    return removeOrgMember(email)
      .then((response) => {
        if (response.status === 200 && response.data) {
          response.data.members = response.data.members.map((member) => {
            member.name = member.first_name + " " + member.last_name;
            member.role = ROLES[member.role];
            return member;
          });
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Team member removed from the organization",
            isUserInOrg: true,
            org: response.data,
          });
          ReactGA.event({
            category: "Org",
            action: "Delete org member API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
          });
          ReactGA.event({
            category: "Failure",
            action: "Delete org member API failed",
          });
        }
        return response;
      })
      .catch(() => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Sorry, an error occurred. Please try again.",
        });
        ReactGA.event({
          category: "Failure",
          action: "Delete org member API failed",
        });
      });
  };

  updateOrgMemberRole = (email, role) => {
    return updateOrgRole(email, role)
      .then((response) => {
        if (response.status === 200 && response.data) {
          response.data.members = response.data.members.map((member) => {
            member.name = member.first_name + " " + member.last_name;
            member.role = ROLES[member.role];
            return member;
          });
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Team member role updated",
            isUserInOrg: true,
            org: response.data,
          });
          ReactGA.event({
            category: "Org",
            action: "Update org member role API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
          });
          ReactGA.event({
            category: "Failure",
            action: "Update org member role API failed",
          });
        }
      })
      .catch(() => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Sorry, an error occurred, Please try again.",
        });
        ReactGA.event({
          category: "Failure",
          action: "Update org member role API failed",
        });
      });
  };

  enrollSpeaker = () => {
    this.props.history.push("/enroll-speaker");
  };

  saveLanguageSettings = (languageCode) => {
    return postLanguageCode(languageCode).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Transcription language saved",
          });
          ReactGA.event({
            category: "Settings",
            action: "User changed language code API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
          });
          ReactGA.event({
            category: "Failure",
            action: "User changed language code API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  inviteNewUserToOrg = (email) => {
    return inviteNewUserToOrg(email).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "User has been invited to join Sonero",
          });
          ReactGA.event({
            category: "Org",
            action: "Invite new user to org API success",
          });
          this.getInvitedUsersForMyOrg();
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
          });
          ReactGA.event({
            category: "Failure",
            action: "Invite new user to org API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  deleteInvitedUserFromOrg = (email) => {
    return deleteInvitedUser(email).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage:
              "Invited user has been removed from your Organization",
          });
          ReactGA.event({
            category: "Org",
            action: "Delete invited user from org API success",
          });
          this.getInvitedUsersForMyOrg();
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status === 400 &&
          response.data.error_code === "account_created"
        ) {
          return response;
        } else {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Sorry, an error occurred. Please try again.",
          });
          ReactGA.event({
            category: "Failure",
            action: "Delete invited user from org API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="My Account">
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderToggledPageContent = () => {
    const { classes } = this.props;
    switch (this.state.toggledPage) {
      case "account":
        return (
          <>
            <Grid
              className={classes.gridContainer}
              container
              wrap="wrap"
              justify="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={6} md={6} xl={3} xs={12}>
                <SpeakerEnrollment
                  className={classes.item}
                  enrollSpeaker={this.enrollSpeaker}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridContainer}
              container
              wrap="wrap"
              justify="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={6} md={6} xl={3} xs={12}>
                {this.state.isUserInOrg === true ? (
                  <OrgMembersTable
                    className={classes.item}
                    org={this.state.org}
                    invitedUsers={this.state.invitedUsers}
                    addOrgMember={this.addOrgMember}
                    removeOrgMember={this.removeOrgMember}
                    inviteNewUserToOrg={this.inviteNewUserToOrg}
                    deleteInvitedUserFromOrg={this.deleteInvitedUserFromOrg}
                    refreshOrgTable={() => {
                      return this.getMyOrg().then(() => {
                        return this.getInvitedUsersForMyOrg();
                      });
                    }}
                    updateOrgMemberRole={this.updateOrgMemberRole}
                  />
                ) : (
                  <CreateOrg
                    className={classes.item}
                    createOrg={this.createOrg}
                  />
                )}
              </Grid>
            </Grid>
          </>
        );
      case "profile":
        return (
          <>
            <Grid
              className={classes.gridContainer}
              container
              wrap="wrap"
              justify="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={6} md={6} xl={3} xs={12}>
                <BasicInformation
                  className={classes.item}
                  userInfo={this.state.userInfo}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridContainer}
              container
              wrap="wrap"
              justify="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={6} md={6} xl={3} xs={12}>
                <LanguageSettings
                  className={classes.item}
                  saveLanguageSettings={this.saveLanguageSettings}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridContainer}
              container
              wrap="wrap"
              justify="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={6} md={6} xl={3} xs={12}>
                <NewPassword
                  className={classes.item}
                  changePassword={this.changePassword}
                />
              </Grid>
            </Grid>
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <DashboardLayout title="My Account">
        <div className={classes.root}>
          <div className={classes.toggleButtonsContainer}>
            <ToggleButtonGroup
              value={this.state.toggledPage}
              onChange={(_event, newValue) =>
                this.setState({ toggledPage: newValue })
              }
              exclusive
            >
              <ToggleButton value="account">Account Settings</ToggleButton>
              <ToggleButton value="profile">My Profile</ToggleButton>
            </ToggleButtonGroup>
          </div>
          {this.renderToggledPageContent()}
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="10000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
        </div>
      </DashboardLayout>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account);
