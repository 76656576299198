export default (theme) => ({
  root: {},
  portletFooter: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  caption: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 2,
  },
  field: {
    marginTop: theme.spacing.unit * 2,
  },
});
