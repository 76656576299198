export default (theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  banner: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.purple,
    borderRadius: "10px",
    position: "relative",
  },
  bannerHeavyText: {
    fontSize: "32px",
    marginBottom: theme.spacing.unit * 3,
    lineHeight: 1,
    position: "relative",
    zIndex: 1,
    "& > span": {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
  },
  bannerLightText: {
    fontWeight: 400,
    fontSize: "18px",
    width: "fit-content",
    position: "relative",
    zIndex: 1,
  },
  bannerImage: {
    position: "absolute",
    bottom: 0,
    right: theme.spacing.unit * 4,
    height: "200px",
  },
  table: {
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    overflow: "hidden",
    boxShadow: `0px 3px 5px ${theme.palette.background.grey}`,
  },
  dateFilter: {
    fontSize: "18px",
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  statContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
    gridAutoRows: "auto",
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  stat: {
    textAlign: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    height: "150px",
    border: `1px solid &{theme.palette.border}`,
    borderRadius: "10px",
    boxShadow: `0px 3px 5px ${theme.palette.background.grey}`,
    overflow: "hidden",
  },
  statCell: {
    fontSize: "24px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statCellHeader: {
    fontSize: "16px",
    color: theme.palette.text.grey,
    fontWeight: 450,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  arrowPositive: {
    color: theme.palette.primary.green,
    width: "40px",
    height: "40px",
  },
  arrowNegative: {
    color: theme.palette.primary.red,
    width: "40px",
    height: "40px",
  },
  dashNeutral: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "40px",
  },
  content: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  row: {
    display: "flex",
    gap: theme.spacing(1),
    borderRadius: "5px",
    padding: theme.spacing(1),
    transition: "background-color 0.2s",
    "&.selectable": {
      cursor: "pointer",
    },
    "&.selectable:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  cell: {
    width: "15%",
    fontSize: "16px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),
    "&.header": {
      fontSize: "18px",
      fontWeight: 800,
    },
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  loadingContainer: {
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(4),
  },
});
