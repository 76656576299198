export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  container: {
    width: "max(60%, 300px)",
    margin: `${theme.spacing(9)}px auto`,
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    flexShrink: 0,
    marginBottom: theme.spacing.unit * 3,
  },
  logoImage: {
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "max(60%, 400px)",
    margin: `${theme.spacing(1.5)}px auto`,
    padding: theme.spacing(3),
    textAlign: "center",
  },
  header: {
    fontSize: "20px",
    fontWeight: 600,
  },
  body: {
    fontSize: "16px",
    fontWeight: 400,
  },
  messageTextbox: {
    padding: theme.spacing(2),
    fontFamily: "Roboto",
    fontSize: "16px",
    backgroundColor: theme.palette.background.grey,
    borderRadius: "5px",
    resize: "none",
    border: "none",
    outline: "none",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  ctaContainer: {
    width: "fit-content",
    marginLeft: "auto",
  },
  ctaButton: {
    margin: "auto",
  },
  requestAccessText: {
    textAlign: "left",
  },
});
