import theme from "theme";

const placeholderImage = "/sonero-static/images/logo_with_name_square.png";

export const playAudioPiP = async (audioPlayer, failureCallback) => {
  try {
    // set up PiP with placeholder canvas and video elements
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 512;
    
    const video = document.createElement('video');
    video.srcObject = canvas.captureStream();
    video.muted = true;
    
    const image = new Image();
    image.src = placeholderImage;
    await image.decode();
    
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = theme.palette.background.grey;
    ctx.fillRect(0, 0, 512, 512);
    ctx.drawImage(image, 0, 0);
    
    // set up play/pause button in PiP
    navigator.mediaSession.metadata = new window.MediaMetadata({});

    navigator.mediaSession.setActionHandler('play', function() {
      audioPlayer.play();
      if (document.pictureInPictureElement) {
        document.pictureInPictureElement.play();
      }
    });

    navigator.mediaSession.setActionHandler('pause', function() {
      audioPlayer.pause();
      if (document.pictureInPictureElement) {
        document.pictureInPictureElement.pause();
      }
    });

    // handle cases when user interacts with the audio player directly
    audioPlayer.addEventListener('ended', function() {
      video.pause();
    });

    audioPlayer.addEventListener('pause', function() {
      video.pause();
    });

    audioPlayer.addEventListener('play', function() {
      video.play();
    });

    await audioPlayer.play();
    await video.play();
    await video.requestPictureInPicture();
  }
  catch (err) {
    console.log(err);
    failureCallback();
  }
};

export const isVideo = (url) => {
  return new Promise(function(resolve, reject){
    let video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function() {
      resolve(!!(video.videoHeight && video.videoWidth));
      video.src = null;      
    };
    video.src = url;
  });
};