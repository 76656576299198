import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Remove from '@material-ui/icons/Remove';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import FilterList from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ViewColumn from '@material-ui/icons/ViewColumn';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';

// Shared helpers
import { meetingStatuses } from 'helpers';

// Shared components
import { Portlet, PortletContent, PortletLabel } from 'components';

// Component styles
import styles from './styles';

const tableIcons = {
    Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

class SearchResultsTable extends Component {

  handleSelect = (id) => {
    const { onSelect } = this.props;
    onSelect(id);
  };

  getStatus = (meetingStatus) => {
    let status = meetingStatuses.filter((element) => element.value === meetingStatus)
    if (status.length > 0) {
        return status[0].label
    }
    return ""
  }

  getDate = (date) => {
    if (date != null) {
      var localDate = new Date(date)
      return localDate.toLocaleString()
    }
    return ""
  }

  handleRowClick = (event, rowData) => {
    var meetings = this.props.meetings
    var index = this.props.meetings.indexOf(rowData)
    this.handleSelect(meetings[index].id)
  }

  render() {
    const { classes, className, meetings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <MaterialTable
          icons={tableIcons}
          title={
            <PortletLabel title="Search Results (Top 25)" />
          }
          columns={
            [
              { title: 'Title', field: 'title' },
              { title: 'Start Time', field: 'start_time', render: rowData => this.getDate(rowData.start_time) },
            ]
          }
          data={meetings}
          options={{
              rowStyle: {
                  fontFamily: 'Roboto',
                  fontSize: '14px',
              },
              actionsColumnIndex: -1,
              pageSize: 25,
              emptyRowsWhenPaging: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: '',
            },
            toolbar: {
              searchPlaceholder: 'Filter by Title'
            }
          }}
          style={{
            boxShadow: 'none'
          }}
          onRowClick={this.handleRowClick}
        />
      </Portlet>
    );
  }
}

SearchResultsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  meetings: PropTypes.array.isRequired
};

SearchResultsTable.defaultProps = {
  meetings: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

export default withStyles(styles)(SearchResultsTable);
