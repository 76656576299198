import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: {
    position: "relative",
  },
  transcriptBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit,
    textAlign: "left",
  },
  content: {
    alignItems: "center",
    display: "flex",
    marginTop: theme.spacing.unit * 5,
  },
  header: {
    padding: "5px",
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.elements.currentInsight.lightGrey,
    display: "flex",
    justifyContent: "space-between",
  },
  transcriptDownloadIcon: {
    color: theme.palette.elements.currentInsight.blue,
  },
  paperBody: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
  },
  searchBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  searchCounter: {
    padding: "10px",
  },
  searchButtonWrapper: {
    display: "flex",
    postion: "relative",
  },
  vertLine: {
    position: "absolute",
    top: "5%",
    bottom: "5%",
    borderLeft: `1px solid ${theme.palette.border}`,
  },
  participantMap: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.grey,
    fontWeight: 700,
    margin: "10px",
    lineHeight: "22px",
  },
  value: {
    marginTop: theme.spacing.unit,
    color: theme.palette.success.dark,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  phrase: {
    color: theme.palette.text.secondary,
  },
  transcriptInfoIcon: {
    color: fade(theme.palette.text.secondary, 0.5),
    marginLeft: theme.spacing.unit,
  },
  newSpeakerButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  headerWithIcon: {
    display: "inline-flex",
    alignItems: "center",
  },
  timelineRoot: {
    padding: 0,
  },
  speakerSegment: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  searchResult: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
});
