import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Chip,
} from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import FolderIcon from "@material-ui/icons/Folder";
import SearchIcon from "@material-ui/icons/Search";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Shared services
import { searchMeetings } from "services/speech";

// Custom components
import { SearchResultsTable } from "./components";

import SearchBar from "material-ui-search-bar";

// Shared components
import { Portlet, PortletContent } from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

class MyMeetings extends Component {
  signal = true;

  state = {
    isLoading: false,
    meetings: [],
    error: null,
    selectedSpeech: null,
    searched: false,
    searchValue: "",
    searchFilters: {
      participant: "",
      folder: "",
    },
    showParticipantFilter: false,
    showFolderFilter: false,
  };

  componentWillMount() {
    var searchValue = localStorage.getItem("searchValue");
    var searchFilters = localStorage.getItem("searchFilters");
    var searchResults = localStorage.getItem("searchResults");

    if (
      searchValue !== null &&
      searchResults !== null &&
      searchFilters !== null
    ) {
      searchResults = JSON.parse(searchResults);
      searchFilters = JSON.parse(searchFilters);
      const showParticipantFilter =
        searchFilters &&
        searchFilters.participant &&
        searchFilters.participant !== "";
      const showFolderFilter =
        searchFilters && searchFilters.folder && searchFilters.folder !== "";
      if (searchValue !== "" && searchResults.length > 0) {
        this.setState({
          searchValue: searchValue,
          searchFilters: searchFilters,
          showParticipantFilter: showParticipantFilter,
          showFolderFilter: showFolderFilter,
          meetings: searchResults,
        });
      }
    }
  }

  searchMyMeetings = (searchValue, searchFilters) => {
    this.setState({ isLoading: true });
    searchMeetings(searchValue, searchFilters).then(
      function(response) {
        if (response.status === 200) {
          var meetings = response.data.data;
          this.setState({
            isLoading: false,
            meetings,
            searched: true,
          });
          this.saveSearchResultsInLocalStorage(
            searchValue,
            searchFilters,
            meetings
          );
          ReactGA.event({
            category: "Search",
            action: "Search meetings API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error: "Sorry, search failed because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Search meetings API failed",
          });
        }
      }.bind(this)
    );
  };

  saveSearchResultsInLocalStorage = (searchValue, searchFilters, data) => {
    localStorage.setItem("searchValue", searchValue);
    localStorage.setItem("searchFilters", JSON.stringify(searchFilters));
    localStorage.setItem("searchResults", JSON.stringify(data));
  };

  handleSelect = (meetingId) => {
    this.props.history.push({
      pathname: "/view-meeting",
      state: { id: meetingId, searchValue: this.state.searchValue },
    });
  };

  resetSearchResults = () => {
    this.setState({
      searchValue: "",
      searchFilters: { participant: "", folder: "" },
      isLoading: false,
      meetings: [],
      searched: false,
    });
    localStorage.removeItem("searchValue");
    localStorage.removeItem("searchResults");
    localStorage.removeItem("searchFilters");
  };

  openFilterOptions = (event) => {
    this.setState({ showParticipantFilter: true, showFolderFilter: true });
  };

  handleSearchRequest = (query, filters) => {
    if (
      query.trim() === "" &&
      filters.participant.trim() === "" &&
      filters.folder.trim() === ""
    ) {
      this.resetSearchResults();
      return;
    } else {
      this.searchMyMeetings(query, filters);
    }

    ReactGA.event({
      category: "Search",
      action: "User executed search request",
    });
  };

  applyParticipantFilter = () => {
    const { searchValue, searchFilters } = this.state;
    searchFilters.participant =
      searchFilters.participant.trim() === "" ? "" : searchFilters.participant;
    this.setState({
      searchFilters: searchFilters,
      showParticipantFilter: true,
    });
    this.handleSearchRequest(searchValue, searchFilters);
  };

  applyFolderFilter = () => {
    const { searchValue, searchFilters } = this.state;
    searchFilters.folder =
      searchFilters.folder.trim() === "" ? "" : searchFilters.folder;
    this.setState({ searchFilters: searchFilters, showFolderFilter: true });
    this.handleSearchRequest(searchValue, searchFilters);
  };

  closeParticipantFilter = () => {
    const { searchValue, searchFilters } = this.state;
    searchFilters.participant = "";
    this.setState({
      searchFilters: searchFilters,
      showParticipantFilter: false,
    });
    this.handleSearchRequest(searchValue, searchFilters);
  };

  closeFolderFilter = () => {
    const { searchValue, searchFilters } = this.state;
    searchFilters.folder = "";
    this.setState({ searchFilters: searchFilters, showFolderFilter: false });
    this.handleSearchRequest(searchValue, searchFilters);
  };

  participantOnChange = (value) => {
    let searchFilters = { ...this.state.searchFilters };
    searchFilters.participant = value;
    this.setState({ searchFilters: searchFilters });
  };

  folderOnChange = (value) => {
    this.setState((prevState) => {
      prevState.searchFilters.folder = value;
      return {
        searchFilters: prevState.searchFilters,
      };
    });
  };

  renderChipLabel(value, onChange, placeholder, onApply) {
    const { classes } = this.props;
    const inputRef = React.createRef();
    return (
      <div className={classes.chipContent}>
        <input
          className={classes.chipInput}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          ref={inputRef}
          onKeyDown={(e) => {
            if (e.key === "Enter" && inputRef.current) {
              inputRef.current.blur();
            }
          }}
          onBlur={() => onApply()}
        />
        <SearchIcon className={classes.chipSearchIcon} onClick={onApply} />
      </div>
    );
  }

  renderMeetings() {
    const { classes } = this.props;
    const { isLoading, meetings, error, searched } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (meetings.length > 0) {
      return (
        <SearchResultsTable
          className={classes.resultsTable}
          onSelect={this.handleSelect}
          meetings={meetings}
        />
      );
    } else {
      return (
        <div className={classes.emptyHeader}>
          {searched
            ? "There are no meetings that match your search"
            : "Search for topics to get relevant meetings"}
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const {
      searchValue,
      searchFilters,
      showParticipantFilter,
      showFolderFilter,
    } = this.state;

    return (
      <DashboardLayout title="Search">
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.flexboxContainer}>
              <SearchBar
                className={classes.searchBar}
                value={searchValue}
                onChange={(newValue) =>
                  this.setState({ searchValue: newValue })
                }
                onRequestSearch={() =>
                  this.handleSearchRequest(searchValue, searchFilters)
                }
                onCancelSearch={() => this.resetSearchResults()}
                placeholder="Press Enter to Search"
              />
              <Button
                color="primary"
                onClick={(event) => this.openFilterOptions(event)}
              >
                FILTERS
              </Button>
            </div>
            {showParticipantFilter && (
              <Chip
                icon={<PersonIcon />}
                label={this.renderChipLabel(
                  searchFilters.participant,
                  (event) => this.participantOnChange(event.target.value),
                  "Filter by Participant",
                  this.applyParticipantFilter
                )}
                onDelete={this.closeParticipantFilter}
                className={classes.filterChip}
                color="primary"
              />
            )}
            {showFolderFilter && (
              <Chip
                icon={<FolderIcon />}
                label={this.renderChipLabel(
                  searchFilters.folder,
                  (event) => this.folderOnChange(event.target.value),
                  "Filter by Folder",
                  this.applyFolderFilter
                )}
                onDelete={this.closeFolderFilter}
                className={classes.filterChip}
                color="secondary"
              />
            )}
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {this.renderMeetings()}
              </Grid>
            </Grid>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

MyMeetings.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
};

export default withStyles(styles)(MyMeetings);
