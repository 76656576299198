import { fade } from "@material-ui/core";

export default (theme) => ({
  sectionHeader: {
    padding: theme.spacing.unit * 2,
    fontWeight: 700,
    background: theme.palette.background.blue,
    color: theme.palette.text.dark,
  },
  headerTabs: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  tabText: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    fontSize: "14px",
  },
  selected: {
    "&&": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
  faqContent: {
    height: "350px",
    overflowY: "scroll",
  },
  faqHeader: {
    textAlign: "center",
    backgroundColor: theme.palette.background.grey,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  faqItem: {
    paddingBottom: theme.spacing(2),
  },
  faqRow: {
    display: "flex",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqRowTitle: {
    display: "flex",
    gap: theme.spacing(1),
  },
  faqRowHeader: {
    fontSize: "16px",
    "& > span": {
      color: theme.palette.primary.dark,
      fontWeight: "700",
      textTransform: "uppercase",
    },
  },
  questions: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  question: {
    marginLeft: theme.spacing(6),
    display: "flex",
    gap: theme.spacing(1),
    cursor: "pointer",
    transition: "margin-left 0.25s",
    "&:hover": {
      marginLeft: theme.spacing(6.25),
    },
    "&:hover *": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  questionPhrase: {
    width: "90%",
  },
  moreButton: {
    display: "block",
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  emptyText: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: "20px",
  },
  emptyIcon: {
    width: "80px",
    height: "80px",
  },
  loadingContainer: {
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
  meetingTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
});
