export default (theme) => ({
  root: {},
  container: {
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    textRendering: "optimizeLegibility",
    height: "100%",
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0px",
    paddingBottom: "30px",
    paddingLeft: "30px",
    paddingRight: "10px",
    fontSize: "13px",
    width: "100%",
    fontFamily: "Roboto",
  },
  cardElement: {
    margin: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    paddingLeft: "20px",
    paddingRight: "20px",
    borderStyle: "none",
    boxShadow:
      "0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "4px",
    height: "50px",
  },
  payButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  payButton: {
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    width: "100%",
    height: "40px",
  },
  textFieldsContainer: {
    margin: theme.spacing.unit * 4,
    paddingLeft: "20px",
    paddingRight: "20px",
    borderStyle: "none",
    boxShadow:
      "0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.10)",
    borderRadius: "4px",
  },
  textFieldLabel: {
    width: "15%",
    minWidth: "70px",
    color: "#66788A",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
  },
  textField: {
    fontSize: "16px",
    width: "100%",
    lineHeight: "40px",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    backgroundColor: "transparent",
    animation: "1ms void-animation-out",
    "-webkit-text-fill-color": "#0767DB",
    transition: "background-color 100000000s",
  },
  nameField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "none",
    lineHeight: "50px",
  },
  emailField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(50, 50, 93, 0.08)",
    lineHeight: "50px",
  },
  policy: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 4,
  },
  policyCheckbox: {
    marginLeft: "-10px",
  },
  policyText: {
    display: "inline",
    color: theme.palette.text.secondary,
  },
  policyUrl: {
    color: theme.palette.text.primary,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
});
