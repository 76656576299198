export default (theme) => ({
  root: {},
  meetingScoreContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing.unit * 2,
    height: "350px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  transitionContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  overallView: {
    position: "static",
    animation: "$slideInFromLeft 0.5s",
    "&.no-animation": {
      animation: "",
    },
  },
  detailedView: {
    position: "static",
    animation: "$slideInFromRight 0.5s",
  },
  overallScore: {
    width: "100%",
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
  },
  overallScoreLabel: {
    fontFamily: "Roboto",
    color: theme.palette.text.secondary,
    fontSize: "20px",
    width: "100%",
    marginBottom: theme.spacing.unit,
    "& > span": {
      float: "right",
      fontWeight: "700",
      fontSize: "40px",
    },
    "& > span > span": {
      color: theme.palette.text.secondary,
      fontSize: "20px",
    },
  },
  individualScore: {
    width: "100%",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: 0,
    marginBottom: theme.spacing.unit,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit,
  },
  score: {
    width: "95%",
    flexShrink: 3,
  },
  scoreCreateAccountButton: {
    flexGrow: 2,
  },
  scoreLabel: {
    fontFamily: "Roboto",
    color: theme.palette.text.secondary,
    fontSize: "16px",
    width: "99%",
    marginTop: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit,
    "& > span": {
      float: "right",
      fontWeight: "700",
      fontSize: "32px",
    },
    "& > span > span": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
    },
  },
  performanceSignUp: {
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
    fontSize: "16px",
    float: "right",
    display: "inline",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  performanceDetailsSignUp: {
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontSize: "16px",
  },
  expandIcon: {
    transform: "scale(1.75)",
  },
  details: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit,
  },
  subScoreContainer: {
    width: "100%",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  subScoreText: {
    display: "block",
    fontFamily: "Roboto",
    fontSize: "16px",
    "&.label": {
      color: theme.palette.text.primary,
    },
    "&.detail": {
      color: theme.palette.text.secondary,
    },
  },
  backButton: {
    width: "max-content",
    marginRight: "auto",
    cursor: "pointer",
    borderRadius: "8px",
    padding: theme.spacing.unit,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit,
    "& > div": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      color: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  "@keyframes slideInFromLeft": {
    "0%": {
      width: "100%",
      position: "absolute",
      left: "-100%",
    },
    "100%": {
      width: "100%",
      position: "absolute",
      left: 0,
    },
  },
  "@keyframes slideInFromRight": {
    "0%": {
      width: "100%",
      position: "absolute",
      left: "100%",
    },
    "100%": {
      width: "100%",
      position: "absolute",
      left: 0,
    },
  },
});
