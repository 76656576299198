export default theme => ({
    root: {},
    portletFooter: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    chartWrapper: {
        height: '300px',
        position: 'relative',
        maintainAspectRatio: true
    },
    caption: {
        color: theme.palette.text.secondary
    },
    field: {
        marginTop: theme.spacing.unit * 2
    },
    textField: {
        width: '100%'
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit
    },
  });