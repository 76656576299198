export const meetingSentiment = {
    neutral: "Neutral",
    positive: "Positive",
    negative: "Negative"
}

export const getMeetingSentimentFromPolarity = (polarity) => {
    if (polarity >= -0.25 && polarity <= 0.25) {
        return meetingSentiment.neutral;
    } else if (polarity > 0.25) {
        return meetingSentiment.positive;
    } else if (polarity < -0.25) {
        return meetingSentiment.negative;
    } else {
        return null;
    }
}

export const POSITIVE_TRANSCRIPT_THRESHOLD = 0.5;

export const NEGATIVE_TRANSCRIPT_THRESHOLD = -0.5;