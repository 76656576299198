import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import {
  MeetingInfo,
  ViewVideo,
  Agenda,
  Overview,
  Analytics,
  MeetingScore,
  ViewClipModal,
} from "views/ViewMeeting/components";

import { Dashboard as DashboardLayout } from "layouts";
import { Bookmarks } from "./components";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  TranscriptDialog,
  PortletFooter,
} from "components";

// Shared services
import {
  getMeetingShare,
  deleteSharedAgendaItem,
  createSharedAgendaItem,
  updateSharedAgendaItem,
  reorderSharedAgendaItem,
} from "services/speech";

// Shared helpers
import { speechStatus } from "helpers";
import { showMeetingScore } from "helpers/meetingScore";
import { reorder } from "helpers/draggableList";

import ReactGA from "react-ga";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

// Component styles
const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 12,
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  textField: {
    width: "100%",
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "63px",
    flexShrink: 0,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 3,
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: "pointer",
  },
  logoName: {
    color: theme.palette.text.main,
  },
  logoDivider: {
    marginBottom: theme.spacing.unit * 2,
  },
  toolbar: {
    minHeight: "auto",
    width: "100%",
  },
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    height: "64px",
    zIndex: theme.zIndex.appBar,
    justifyContent: "center",
  },
  signUpButton: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
  },
  signUpIconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
    marginRight: theme.spacing.unit,
  },
  signUpIcon: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "auto",
  },
  analyticsGridContainer: {
    marginTop: theme.spacing.unit * 2,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "0px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tabIndex = {
  insights: "insights",
  analytics: "analytics",
};

class ViewMeetingShare extends Component {
  speechId = null;
  tabPanelsRef = React.createRef();

  state = {
    results: {},
    constants: {},
    videoBlob: null,
    isLoading: false,
    error: null,
    speechStatus: null,
    openTranscriptDialog: false,
    tab: "",
    meetingTitle: "",
    bookmarks: [],
    password: "",
    isPasswordRequired: false,
    snackbarOpen: false,
    snackbarMessage: "",
    autoHideDuration: "6000",
    openViewClipModal: false,
    viewClipModalIndex: -1,
    viewClipModalClips: [],
  };

  tabIndex = {
    insights: 0,
    analytics: 1,
  };
  timeSpentOnTab = new Date();

  componentWillMount() {
    this.speechId = this.props.location.pathname.split("/").pop();
    const queryParams = new URLSearchParams(this.props.location.search);
    const password = queryParams.get("password");
    if (password) {
      this.setState(
        { password: password, isLoading: true },
        this.getSpeechFromRemote
      );
    } else {
      this.getSpeechFromRemote();
    }
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  openSnackbar = (message, autoHide = true) => {
    if (autoHide) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: message,
        autoHideDuration: "6000",
      });
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: message,
        autoHideDuration: null,
      });
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  getSpeechFromRemote = () => {
    this.setState({ isLoading: true });
    getMeetingShare(this.speechId, this.state.password).then(
      function(response) {
        if (response.status !== 200) {
          const data = response.data.data ?? {};
          const {
            uuid,
            is_signed_in,
            requester_name,
            requester_email,
            title,
            owner,
          } = data;
          if (
            response.status === 401 ||
            (response.status === 403 && !is_signed_in)
          ) {
            this.redirectToSignUp(uuid, title, owner);
            return;
          } else if (response.status === 403 && is_signed_in) {
            this.redirectToRequestShare(uuid, requester_name, requester_email);
            return;
          }
          this.setError("Sorry, an unknown error occurred. Please try again.");
          ReactGA.event({
            category: "Failure",
            action: "Get meeting API for view-shared-meeting failed",
          });
          return;
        }

        var status = response.data.status;
        var data = response.data.data;

        if (data && data.transcript_results) {
          var transcriptionResultsMap = {};
          for (let i = 0; i < data.transcript_results.length; i++) {
            let transcriptResult = data.transcript_results[i];
            transcriptionResultsMap[transcriptResult.timestamp.toString()] = i;
          }
          data.transcriptionResultsMap = transcriptionResultsMap;
        }

        var constants = response.data.constants;

        let firstTab = "";
        const shareGranularity = data.share_granularity;
        if (
          shareGranularity.share_insights ||
          shareGranularity.share_transcript
        ) {
          firstTab = tabIndex.insights;
        } else if (shareGranularity.share_analytics) {
          firstTab = tabIndex.analytics;
        }
        this.setState({ tab: firstTab });

        if (status === speechStatus.processing) {
          this.setState({
            isLoading: false,
            speechStatus: speechStatus.processing,
            results: data,
            constants: constants,
            meetingTitle: data.title,
          });
          return;
        }

        if (status === speechStatus.completed) {
          this.setState({
            speechStatus: speechStatus.completed,
            results: data,
            constants: constants,
            meetingTitle: data.title,
            bookmarks: data.bookmarks,
            isPasswordRequired: false,
          });
          ReactGA.event({
            category: "View Shared Meeting",
            action: "Get meeting API success",
          });
        } else if (
          status === speechStatus.uninitialized ||
          status === speechStatus.started ||
          status === speechStatus.completed_agenda_only ||
          status === speechStatus.waiting
        ) {
          this.setState({
            speechStatus: speechStatus.uninitialized,
            results: data,
            constants: response.data.constants,
            meetingTitle: data.title,
            bookmarks: data.bookmarks,
            isPasswordRequired: false,
          });
          ReactGA.event({
            category: "View Shared Meeting",
            action: "Get meeting API success",
          });
        } else if (status === speechStatus.failed) {
          this.setState({
            speechStatus: speechStatus.failed,
            error:
              "Sorry, but processing failed because of an unknown reason. We apologize for the inconvenience.",
          });
        } else {
          this.setError(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Get meeting API for view-shared-meeting failed",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  redirectToSignUp = (uuid, title, owner) => {
    const redirect = `/sign-up?ref=share-view&sharedUuid=${uuid}&sharedTitle=${title}&sharedOwner=${owner}`;
    this.props.history.push(redirect);
  };

  redirectToRequestShare = (uuid, name, email) => {
    const toName = encodeURIComponent(name);
    const toEmail = encodeURIComponent(email);
    const redirect = `/request-share/${uuid}?to_name=${toName}&to=${toEmail}`;
    this.props.history.push(redirect);
  };

  // Agenda

  deleteAgendaItemFunction = (agendaItem) => {
    return deleteSharedAgendaItem(agendaItem.uuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Delete agenda item API failed",
          });
          return response;
        }
        this.setState((prevState) => {
          var agenda_items = [];
          if (prevState.results.agenda_items !== undefined) {
            agenda_items = [...prevState.results.agenda_items];
          }
          agenda_items.splice(agenda_items.indexOf(agendaItem), 1);
          var results = prevState.results;
          results.agenda_items = agenda_items;
          return { results: results };
        });
        ReactGA.event({
          category: "View Shared Meeting",
          action: "Delete agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  updateAgendaItemFunction = (agendaItem) => {
    return updateSharedAgendaItem(agendaItem).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Update agenda item API failed",
          });
          return response;
        }
        ReactGA.event({
          category: "View Shared Meeting",
          action: "Update agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  createAgendaItemFunction = () => {
    const firstName = localStorage.getItem("firstName");
    const email = localStorage.getItem("email");

    return createSharedAgendaItem(this.speechId, firstName, email).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Create agenda item API failed",
          });
          return response;
        }
        this.setState((prevState) => {
          var agenda_items = [];
          if (prevState.results.agenda_items) {
            agenda_items = prevState.results.agenda_items.map((item) => {
              item.shouldFocus = false;
              return item;
            });
          }
          var newItem = response.data.data;
          newItem.shouldFocus = true;
          agenda_items.push(newItem);
          var results = prevState.results;
          results.agenda_items = agenda_items;
          return { results: results };
        });
        ReactGA.event({
          category: "View Shared Meeting",
          action: "Create agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  reorderAgendaItemFunction = (result) => {
    if (!result.destination) {
      return;
    }

    const agendaItems = reorder(
      this.state.results.agenda_items,
      result.source.index,
      result.destination.index
    );
    this.setState({
      results: {
        ...this.state.results,
        agenda_items: agendaItems,
      },
    });

    return reorderSharedAgendaItem(
      this.state.results.uuid,
      agendaItems.map((item) => item.uuid)
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Re-order agenda item API failed",
          });
          return response;
        }
        ReactGA.event({
          category: "View Shared Meeting",
          action: "Re-order agenda item API success",
        });
        return response;
      }.bind(this)
    );
  };

  handlePasswordSubmit = () => {
    if (this.state.password.trim()) {
      this.getSpeechFromRemote();
    }
  };

  setError = (error) => {
    this.setState({ error: error });
  };

  handlePasswordChange = (value) => {
    this.setState({ password: value });
  };

  handleOpenTranscript = () => {
    this.setState({ openTranscriptDialog: true });
  };

  handleCloseTranscript = () => {
    this.setState({ openTranscriptDialog: false });
  };

  handleScroll = () => {
    if (this.viewVideo != null) {
      if (
        document.body.scrollTop > 350 ||
        document.documentElement.scrollTop > 350
      ) {
        this.viewVideo.enablePictureInPicture(true);
      } else {
        this.viewVideo.enablePictureInPicture(false);
      }
    }
  };

  handlePrevTabLogTime = () => {
    let prevTabIndex = this.state.tab;
    let timeSpentOnPrevTab = (new Date() - this.timeSpentOnTab) / 1000;
    this.timeSpentOnTab = new Date();

    let prevTabName = Object.keys(this.tabIndex).find(
      (key) => this.tabIndex[key] === prevTabIndex
    );

    ReactGA.event({
      category: "View Shared Meeting",
      action: "Time Spent On " + prevTabName + " tab",
      value: timeSpentOnPrevTab,
    });
  };

  handleTabChange = (newTabIndex) => {
    this.handlePrevTabLogTime();

    this.setState({ tab: newTabIndex });

    var eventAction = "User clicked AI Insights tab";

    if (newTabIndex === tabIndex.analytics) {
      eventAction = "User clicked Analytics tab";
    }
    ReactGA.event({
      category: "View Shared Meeting",
      action: eventAction,
    });
  };

  setMediaPlayerCurrentTimeAndPlay = (timestamp) => {
    if (this.viewVideo) {
      this.viewVideo.setMediaPlayerCurrentTimeAndPlay(timestamp);
    }
  };

  handleOpenViewClipModal = (clipIdx, clips) => {
    this.setState({
      openViewClipModal: true,
      viewClipModalIndex: clipIdx,
      viewClipModalClips: clips,
    });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked to view a clip",
    });
  };

  handleCloseViewClipModal = () => {
    this.setState({
      openViewClipModal: false,
      viewClipModalIndex: -1,
      viewClipModalClips: [],
    });
  };

  handlePrevClip = () => {
    const currIdx = this.state.viewClipModalIndex;
    if (currIdx > 0) {
      this.setState({ viewClipModalIndex: currIdx - 1 });
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: "User navigated to the previous clip from within the modal",
      });
    }
  };

  handleNextClip = () => {
    const currIdx = this.state.viewClipModalIndex;
    if (currIdx + 1 < this.state.viewClipModalClips.length) {
      this.setState({ viewClipModalIndex: currIdx + 1 });
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: "User navigated to the next clip from within the modal",
      });
    }
  };

  renderViewClipModal = () => {
    const {
      results,
      openViewClipModal,
      viewClipModalIndex,
      viewClipModalClips,
    } = this.state;
    return (
      <ViewClipModal
        modalOpen={openViewClipModal}
        clips={viewClipModalClips}
        modalClipIndex={viewClipModalIndex}
        data={results}
        handleCloseModal={this.handleCloseViewClipModal}
        handlePrevClip={this.handlePrevClip}
        handleNextClip={this.handleNextClip}
        isSharedMeetingView={true}
      />
    );
  };

  renderTranscriptDialog = () => {
    return (
      <TranscriptDialog
        data={this.state.results}
        open={this.state.openTranscriptDialog}
        closeHandler={this.handleCloseTranscript}
        setMediaPlayerCurrentTimeAndPlay={this.setMediaPlayerCurrentTimeAndPlay}
      />
    );
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Portlet>
            <PortletContent className={classes.content}>
              <Typography className={classes.error} variant="body1">
                {this.state.error}
              </Typography>
            </PortletContent>
          </Portlet>
        </div>
      </div>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  };

  renderProcessing = () => {
    const { classes } = this.props;
    const { results } = this.state;
    if (results.recording_url && results.transcript_results.length > 0) {
      return this.renderSpeechResults();
    }
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Portlet>
            <PortletContent className={classes.item}>
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
              <Typography
                className={classes.caption}
                variant="h4"
                align="center"
              >
                The recording is currently being processed and analyzed. This
                may take some time depending on the length of the recording.{" "}
                <br />
                <br />
              </Typography>
            </PortletContent>
          </Portlet>
        </div>
      </div>
    );
  };

  renderPasswordPrompt = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center" alignContent="center" spacing={4}>
          <Grid item lg={3} md={3} xl={3} xs={12}>
            <div className={classes.content}>
              <Portlet>
                <PortletHeader>
                  <PortletLabel title="Meeting Password" />
                </PortletHeader>
                <PortletContent className={classes.item}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    required
                    className={classes.textField}
                    value={this.state.password}
                    onChange={(event) =>
                      this.handlePasswordChange(event.target.value)
                    }
                  />
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                  <Button
                    className={classes.feedbackButton}
                    color="primary"
                    size="small"
                    onClick={this.handlePasswordSubmit}
                  >
                    SUBMIT
                  </Button>
                </PortletFooter>
              </Portlet>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderSpeechResults = () => {
    const { classes } = this.props;
    const shareGranularity = this.state.results.share_granularity;
    const isRecordingEnabled = this.state.results.is_recording_enabled;
    const allTabsHidden =
      !shareGranularity.share_insights &&
      !shareGranularity.share_transcript &&
      !shareGranularity.share_analytics;

    const shouldShowMeetingScore =
      showMeetingScore(
        this.state.results,
        this.state.speechStatus,
        this.props.showScoreToGuests
      ) &&
      !isRecordingEnabled &&
      shareGranularity.share_score;
    const shouldShowVideo =
      isRecordingEnabled && shareGranularity.share_recording;

    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={4}>
            {(shouldShowVideo || shouldShowMeetingScore) && (
              <>
                <Grid item lg={7} md={7} xl={9} xs={12}>
                  {shouldShowVideo && (
                    <ViewVideo
                      ref={(instance) => {
                        this.viewVideo = instance;
                      }}
                      className={classes.item}
                      videoBlob={this.state.videoBlob}
                      data={this.state.results}
                      isMeeting={true}
                      bookmarks={this.state.bookmarks}
                      isSharedMeetingView={true}
                    />
                  )}
                  {shouldShowMeetingScore && (
                    <MeetingScore
                      data={this.state.results}
                      sharedView={true}
                      wrapInPortlet
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} xl={3} xs={12}>
                  {shareGranularity.share_attendees ? (
                    <MeetingInfo
                      className={classes.item}
                      data={this.state.results}
                      setMediaPlayerCurrentTimeAndPlay={
                        this.setMediaPlayerCurrentTimeAndPlay
                      }
                      openSnackbar={this.openSnackbar}
                      hideShareButton={true}
                      meetingStatus={this.state.speechStatus}
                      showScoreToGuests={true}
                      isSharedView={true}
                    />
                  ) : (
                    <Bookmarks
                      bookmarks={this.state.bookmarks}
                      openSnackbar={this.openSnackbar}
                    />
                  )}
                </Grid>
              </>
            )}
            {!allTabsHidden && (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
                ref={this.tabPanelsRef}
              >
                <AppBar
                  position="static"
                  color="default"
                  style={{ flexGrow: 1 }}
                >
                  <Tabs
                    value={this.state.tab}
                    onChange={(_event, newValue) =>
                      this.handleTabChange(newValue)
                    }
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    {(shareGranularity.share_insights ||
                      shareGranularity.share_transcript) && (
                      <Tab label="Insights" value={tabIndex.insights} />
                    )}
                    {shareGranularity.share_analytics && (
                      <Tab label="Analytics" value={tabIndex.analytics} />
                    )}
                  </Tabs>
                </AppBar>
                {(shareGranularity.share_insights ||
                  shareGranularity.share_transcript) && (
                  <TabPanel value={this.state.tab} index={tabIndex.insights}>
                    <Overview
                      className={classes.item}
                      data={this.state.results}
                      meetingStatus={this.state.speechStatus}
                      navigateToAnalytics={() => {
                        this.handleTabChange(tabIndex.analytics);
                        if (this.tabPanelsRef.current) {
                          this.tabPanelsRef.current.scrollIntoView();
                        }
                      }}
                      setMediaPlayerCurrentTimeAndPlay={(timestamp) =>
                        this.viewVideo.setMediaPlayerCurrentTimeAndPlay(
                          timestamp
                        )
                      }
                      isSharedMeetingView={true}
                      openClip={this.handleOpenViewClipModal}
                    />
                  </TabPanel>
                )}
                {shareGranularity.share_analytics && (
                  <TabPanel value={this.state.tab} index={tabIndex.analytics}>
                    <Analytics
                      data={this.state.results}
                      constants={this.state.constants}
                      meetingStatus={this.state.speechStatus}
                      setMediaPlayerCurrentTimeAndPlay={
                        this.setMediaPlayerCurrentTimeAndPlay
                      }
                    />
                  </TabPanel>
                )}
              </Grid>
            )}
          </Grid>
        </div>
        {this.renderViewClipModal()}
      </div>
    );
  };

  renderPrepareMode = () => {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={7} md={7} xl={9} xs={12}>
              <Agenda
                className={classes.item}
                agenda={this.state.results.agenda_items}
                deleteAgendaItem={this.deleteAgendaItemFunction}
                createAgendaItem={this.createAgendaItemFunction}
                updateAgendaItem={this.updateAgendaItemFunction}
                reorderAgendaItem={this.reorderAgendaItemFunction}
                openSnackbar={this.openSnackbar}
              />
            </Grid>
            <Grid item lg={5} md={5} xl={3} xs={12}>
              <MeetingInfo
                className={classes.item}
                data={this.state.results}
                setMediaPlayerCurrentTimeAndPlay={
                  this.setMediaPlayerCurrentTimeAndPlay
                }
                openSnackbar={this.openSnackbar}
                hideShareButton={true}
                isSharedView={true}
              />
            </Grid>
          </Grid>
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration={this.state.autoHideDuration}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
            action={
              <Button
                style={{ color: "white" }}
                size="small"
                onClick={this.handleSnackbarClose}
              >
                CLOSE
              </Button>
            }
          />
        </div>
      </div>
    );
  };

  render() {
    let content;

    if (this.state.error != null) {
      content = this.renderError();
    } else if (this.state.isLoading) {
      content = this.renderLoading();
    } else if (this.state.speechStatus === speechStatus.processing) {
      content = this.renderProcessing();
    } else if (this.state.speechStatus === speechStatus.failed) {
      content = this.renderError();
    } else if (
      this.state.speechStatus === speechStatus.uninitialized ||
      this.state.speechStatus === speechStatus.started ||
      this.state.speechStatus === speechStatus.waiting ||
      this.state.speechStatus === speechStatus.completed_agenda_only
    ) {
      content = this.renderPrepareMode();
    } else if (this.state.isPasswordRequired) {
      content = this.renderPasswordPrompt();
    } else {
      content = this.renderSpeechResults();
    }

    return (
      <DashboardLayout title={this.state.meetingTitle}>
        {content}
      </DashboardLayout>
    );
  }
}

ViewMeetingShare.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewMeetingShare);
