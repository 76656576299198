export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  textFieldsContainer: {
    display: "flex",
  },
  textField: {
    width: "200px",
    marginRight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
  },
  textFieldDescription: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
});
