import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Snackbar,
  AppBar,
  Tab,
  Tabs,
  Box,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

// Material icons
import {
  CloudUpload as CloudUploadIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Shared services
import {
  getMeetings,
  getProfile,
  getMeetingsSharedWithMe,
  addLinkToGoogleCalendar,
  optOutOfMeetingByHost,
  optInMeetingByHost,
} from "services/speech";

// Custom components
import { MeetingsTable, TeamMeetingsTable } from "./components";

// Shared components
import { Portlet, PortletContent } from "components";
import {
  ShareDialog,
  RenameMeetingDialog,
  DeleteMeetingDialog,
} from "views/ViewMeeting/components";

import { isOnboardingComplete, isGcalConnected } from "helpers/user";

// Component styles
import styles from "./styles";

import {
  meetingTypes,
  pricingTiers,
  speechStatus,
  meetingLimits,
} from "helpers";

import ReactGA from "react-ga";

import queryString from "query-string";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      class={other.class}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px" }} width={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class MyMeetings extends Component {
  state = {
    isLoading: false,
    meetings: [],
    error: null,
    selectedSpeech: null,
    orgMeetings: [],
    toggleButtonValue: 0,
    snackbarOpen: false,
    snackbarMessage: "",
    tab: 0,
    limitReached: false,
    isShareDialogOpen: false,
    shareDialogMeeting: "",
    isMenuOpen: false,
    menuAnchorEl: null,
    menuMeetingId: "",
    displaySoneroWillJoin: true,
    openRenameDialog: false,
    newMeetingTitle: "",
    openDeleteDialog: false,
    isGcalConnected: false,
    shareFolderUuid: "",
  };

  tabIndex = {
    completed: 0,
    scheduled: 1,
  };

  countCompletedMeetings = (prev, curr) => {
    if (
      curr.task_status === speechStatus.completed &&
      curr.meeting_type !== "demo"
    ) {
      return prev + 1;
    }
    return prev;
  };

  componentWillMount() {
    this.getMyProfile();
  }

  componentDidMount() {
    const { tab } = queryString.parse(this.props.location.search);
    if (tab === "scheduled") {
      this.handleTabChange(this.tabIndex.scheduled);
    }
  }

  checkLimitReached = () => {
    if (this.state.tab === this.tabIndex.completed) {
      let meetingDurationLeft = localStorage.getItem("meetingDurationLeft");
      if (meetingDurationLeft != null) {
        this.setState({
          limitReached: +meetingDurationLeft === 0,
        });
      }
    }
  };

  getMyProfile = () => {
    this.setState({
      isLoading: true,
    });
    getProfile().then(
      function(response) {
        this.setState({
          isLoading: false,
          isGcalConnected: isGcalConnected(),
        });
        this.refreshMeetings(this.state.toggleButtonValue, this.state.tab);
      }.bind(this)
    );
  };

  goToSetup = () => {
    this.props.history.push("/setup");
  };

  goToUploadMeeting = () => {
    this.props.history.push("/upload-meeting");
  };

  goToSearch = () => {
    this.props.history.push("/search");
  };

  getMyMeetings = (status, fromDate) => {
    this.setState({ isLoading: true });
    getMeetings(status, fromDate).then(
      function(response) {
        if (response.status === 200) {
          var meetings = response.data.data;

          this.setState({
            isLoading: false,
            meetings,
          });

          this.checkLimitReached();

          ReactGA.event({
            category: "My Meetings",
            action: "Get my meetings API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your meetings because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get my meetings API failed",
          });
        }
      }.bind(this)
    );
  };

  getMeetingsSharedWithMe = (status, fromDate) => {
    this.setState({ isLoading: true });
    getMeetingsSharedWithMe(status, fromDate).then(
      function(response) {
        if (response.status === 200) {
          var meetings = response.data.data;
          this.setState({
            isLoading: false,
            meetings,
          });
          ReactGA.event({
            category: "Shared-with-me Meetings",
            action: "Get shared-with-me meetings API success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your team's meetings because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get shared-with-me meetings API failed",
          });
        }
      }.bind(this)
    );
  };

  handleSelect = (
    meetingId,
    meetingUuid,
    hasInternalAccess,
    isMeetingOwner = true
  ) => {
    let eventAction =
      this.state.tab === this.tabIndex.scheduled
        ? "User Clicked on Meeting (Scheduled)"
        : "User Clicked on Meeting (Completed)";
    ReactGA.event({
      category: "My Meetings",
      action: eventAction,
      value: meetingId,
    });

    const meetingUrl = hasInternalAccess
      ? `/view-meeting/${meetingUuid}`
      : `/view-meeting/share/${meetingUuid}`;

    this.props.history.push({
      pathname: meetingUrl,
      state: { id: meetingId, isMeetingOwner: isMeetingOwner },
    });
  };

  handleToggleButtonChange = (event, value) => {
    if (value !== null) {
      this.setState({ toggleButtonValue: value });
      this.refreshMeetings(value, this.state.tab);
    }
  };

  handleOpenShareDialog = (id, isFolder = false) => {
    if (isFolder) {
      this.setState({ shareFolderUuid: id });
    } else {
      this.setState({ shareDialogMeeting: id });
    }
    this.setState({
      isShareDialogOpen: true,
    });
    ReactGA.event({
      category: "My Meetings",
      action: "User opened quick share dialog",
    });
  };

  handleCloseShareDialog = () => {
    this.setState({
      isShareDialogOpen: false,
      shareDialogMeeting: "",
      shareFolderUuid: "",
    });
  };

  handleOpenMenu = (anchorEl, meetingId, meetingTitle) => {
    this.setState({
      menuMeetingId: meetingId,
      menuAnchorEl: anchorEl,
      menuMeetingTitle: meetingTitle,
    });
  };

  refreshMeetings = (toggleButtonValue, currentTabIndex) => {
    var status = null;
    var fromDate = null;
    if (currentTabIndex === this.tabIndex.scheduled) {
      status = [
        speechStatus.uninitialized,
        speechStatus.started,
        speechStatus.waiting,
      ].join();
      fromDate = new Date();
      fromDate.setUTCHours(0, 0, 0, 0);
    }
    if (toggleButtonValue === 1) {
      this.getMeetingsSharedWithMe(status, fromDate);
    } else {
      this.getMyMeetings(status, fromDate);
    }
  };

  handleMeetingsUpdated = (snackbarMessage) => {
    this.refreshMeetings(this.state.toggleButtonValue, this.state.tab);

    if (snackbarMessage) {
      this.openSnackbar(snackbarMessage);
    }
  };

  handleTabChange = (newTabIndex) => {
    this.setState({ tab: newTabIndex });

    var eventAction = "User clicked Completed tab";
    if (newTabIndex === this.tabIndex.scheduled) {
      eventAction = "User clicked Scheduled tab";
    }
    this.refreshMeetings(this.state.toggleButtonValue, newTabIndex);
    ReactGA.event({
      category: "My Meetings",
      action: eventAction,
    });
  };

  renderToggleButton = () => {
    const { classes } = this.props;
    return (
      <div className={classes.toggleButtonContainer}>
        <ToggleButtonGroup
          value={this.state.toggleButtonValue}
          exclusive
          onChange={this.handleToggleButtonChange}
        >
          <ToggleButton value={0}>My Meetings</ToggleButton>
          <ToggleButton value={1}>Shared With Me</ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  renderUploadMeetingButton = () => {
    const { classes } = this.props;
    return (
      <div className={classes.uploadButtonContainer}>
        <Button
          className={classes.alertButton}
          color="primary"
          size="small"
          variant="contained"
          endIcon={<CloudUploadIcon />}
          onClick={this.goToUploadMeeting}
        >
          UPLOAD MEETING
        </Button>
      </div>
    );
  };

  renderSearchButton = () => {
    const { classes } = this.props;
    return (
      <div className={classes.searchButtonContainer}>
        <Button
          className={classes.alertButton}
          color="primary"
          size="small"
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={this.goToSearch}
        >
          SEARCH
        </Button>
      </div>
    );
  };

  renderAlerts = () => {
    const pricingTier = localStorage.getItem("pricingTier");
    const { classes } = this.props;

    if (!isOnboardingComplete()) {
      return (
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Portlet>
            <PortletContent className={classes.alertContent}>
              <Typography className={classes.alertCaption} variant="h6">
                Make the most of Sonero by completing your account setup!
              </Typography>
              <Button
                className={classes.alertButton}
                color="primary"
                size="small"
                variant="contained"
                onClick={this.goToSetup}
              >
                SETUP
              </Button>
            </PortletContent>
          </Portlet>
        </Grid>
      );
    }

    if (pricingTier != pricingTiers.free) {
      return;
    }

    const { meetings } = this.state;

    if (this.state.limitReached) {
      return (
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Portlet>
            <PortletContent className={classes.alertContent}>
              <Typography className={classes.alertCaption} variant="h6">
                You’ve reached the free tier limit.
              </Typography>
            </PortletContent>
          </Portlet>
        </Grid>
      );
    }
    return (
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="h6">
              {`You are using Sonero Free tier. You get free analysis for 
                ${meetingLimits.freeTier.numMeetings} meetings per month with a maximum 
                duration of ${meetingLimits.freeTier.duration} minutes each. 
                You may upgrade to Sonero Pro tier for unlimited usage in 
                the Billing page.`}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
    );
  };

  renderMeetingsTable() {
    const { classes } = this.props;
    const { isLoading, meetings, tab, displaySoneroWillJoin } = this.state;

    const displayFolders = this.state.tab === this.tabIndex.completed;
    const meetingsWithNoFolders = meetings.filter(
      (meeting) => meeting.type !== meetingTypes.folder
    );
    const isEmpty =
      meetings.length === 0 ||
      (!displayFolders && meetingsWithNoFolders.length === 0);

    if (!isLoading && isEmpty) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="body1">
              {this.state.tab === this.tabIndex.completed
                ? "Sonero hasn't processed any of your meetings yet. If you haven't done so already, please complete the setup process to view your meetings insights."
                : "Please enable one of our calendar integrations to view your scheduled meetings and add agenda topics"}
            </Typography>
          </PortletContent>
        </Portlet>
      );
    }
    return (
      <MeetingsTable
        onSelect={this.handleSelect}
        meetings={meetings}
        onMeetingsUpdated={this.handleMeetingsUpdated}
        displayFolders={displayFolders}
        handleOpenShareDialog={this.handleOpenShareDialog}
        handleOpenMenu={this.handleOpenMenu}
        handleDisplaySwitchScheduledMeetings={
          this.handleDisplaySwitchScheduledMeetings
        }
        isShared={false}
        handleOpenFolderShareDialog={this.handleOpenFolderShareDialog}
        optOutOfMeetingByHost={this.optOutOfMeetingByHost}
        selectedTab={tab}
        displaySoneroWillJoin={displaySoneroWillJoin}
        isLoading={isLoading}
      />
    );
  }

  handleOpenFolderShareDialog = (folderUuid) => {
    this.setState({
      isShareDialogOpen: true,
      shareFolderUuid: folderUuid,
    });
  };

  handleDisplaySwitchScheduledMeetings = (checked) => {
    if (checked) {
      this.setState({
        displaySoneroWillJoin: true,
      });
    } else {
      this.setState({
        displaySoneroWillJoin: false,
      });
    }
  };

  renderTeamMeetingsTable() {
    const { classes } = this.props;
    const { isLoading, meetings } = this.state;

    if (isLoading) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </PortletContent>
        </Portlet>
      );
    }

    if (meetings.length === 0) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="body1">
              No meetings have been shared with you
            </Typography>
          </PortletContent>
        </Portlet>
      );
    }
    return (
      <TeamMeetingsTable
        onSelect={this.handleSelect}
        meetings={meetings}
        handleOpenShareDialog={this.handleOpenShareDialog}
      />
    );
  }

  renderMeetings() {
    const { error } = this.state;

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (this.state.toggleButtonValue === 1) {
      return (
        <Grid>
          <AppBar position="static" color="default" style={{ flexGrow: 1 }}>
            <Tabs
              value={this.state.tab}
              onChange={(_event, newValue) => this.handleTabChange(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Completed" />
              <Tab label="Scheduled" />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.tab} index={this.tabIndex.completed}>
            {this.renderTeamMeetingsTable()}
          </TabPanel>
          <TabPanel value={this.state.tab} index={this.tabIndex.scheduled}>
            {this.renderTeamMeetingsTable()}
          </TabPanel>
        </Grid>
      );
    }

    return (
      <Grid>
        <AppBar position="static" color="default" style={{ flexGrow: 1 }}>
          <Tabs
            value={this.state.tab}
            onChange={(_event, newValue) => this.handleTabChange(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Completed" />
            <Tab label="Scheduled" />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.tab} index={this.tabIndex.completed}>
          {this.renderMeetingsTable()}
        </TabPanel>
        <TabPanel value={this.state.tab} index={this.tabIndex.scheduled}>
          {this.renderMeetingsTable()}
        </TabPanel>
      </Grid>
    );
  }

  renderQuickMenu = () => {
    return (
      <Menu
        anchorEl={this.state.menuAnchorEl}
        open={Boolean(this.state.menuAnchorEl)}
        onClose={this.handleCloseQuickMenu}
      >
        {this.state.isGcalConnected && (
          <MenuItem onClick={this.addGcalLink}>
            Add link to Google calendar
          </MenuItem>
        )}
        <MenuItem onClick={this.handleOpenRenameMeetingDialog}>
          Rename meeting
        </MenuItem>
        <MenuItem onClick={this.handleOpenDeleteMeetingDialog}>
          Delete meeting
        </MenuItem>
      </Menu>
    );
  };

  addGcalLink = () => {
    addLinkToGoogleCalendar(this.state.menuMeetingId).then(
      function(response) {
        if (response.status === 200) {
          this.openSnackbar("Link added to Google calendar");
          ReactGA.event({
            category: "My Meetings",
            action: "Link added to Google calendar",
          });
        } else if (response.status === 400) {
          this.openSnackbar(response.data.message);
        } else {
          this.openSnackbar("An unknown error has occurred");
        }
        this.handleCloseQuickMenu();
      }.bind(this)
    );
  };

  handleOpenDeleteMeetingDialog = () => {
    this.setState({ openDeleteDialog: true, menuAnchorEl: null });
  };

  renderDeleteDialog = () => {
    const { classes } = this.props;
    return (
      <DeleteMeetingDialog
        classes={classes}
        open={this.state.openDeleteDialog}
        id={this.state.menuMeetingId}
        onClose={this.handleCloseDeleteDialog}
        openSnackbar={this.openSnackbar}
        setLoading={this.setLoading}
        handleDeleteMeeting={this.deleteMeeting}
      />
    );
  };

  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  deleteMeeting = (id) => {
    var { meetings } = this.state;
    meetings = meetings.filter((meeting) => meeting.id !== id);
    this.setState({ meetings: meetings });
  };

  handleCloseQuickMenu = () => {
    this.setState({ menuAnchorEl: null, menuMeetingId: "" });
  };

  handleOpenRenameMeetingDialog = () => {
    this.setState({ openRenameDialog: true, menuAnchorEl: null });
  };

  renderRenameDialog = () => {
    const { classes } = this.props;
    return (
      <RenameMeetingDialog
        classes={classes}
        open={this.state.openRenameDialog}
        id={this.state.menuMeetingId}
        onClose={this.handleCloseRenameDialog}
        openSnackbar={this.openSnackbar}
        setLoading={this.setLoading}
        handleRenameMeeting={this.renameMeeting}
        meetingTitle={this.state.menuMeetingTitle}
      />
    );
  };

  handleCloseRenameDialog = () => {
    this.setState({ openRenameDialog: false });
  };

  setLoading = (loading) => {
    this.setState({ isLoading: loading });
  };

  renameMeeting = (title, id) => {
    var { meetings } = this.state;
    meetings.forEach((meeting) => {
      if (meeting.id === id) {
        meeting.title = title;
      }
    });
    this.setState({ meetings: meetings });
  };

  addSnackbar = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Snackbar
        open={this.state.snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackbarClose}
        ContentProps={{
          className: classes.snackbar,
        }}
        message={this.state.snackbarMessage}
        action={
          <Button
            style={{ color: "white" }}
            size="small"
            onClick={this.handleSnackbarClose}
          >
            CLOSE
          </Button>
        }
      />
    );
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  optOutOfMeetingByHost = (meetingUuid, optOut) => {
    if (optOut == true) {
      return optOutOfMeetingByHost(meetingUuid).then(
        function(response) {
          if (response.status === 200) {
            var newMeetings = this.state.meetings.map((value) => {
              if (value.uuid === meetingUuid) {
                return {
                  ...value,
                  user_opted_out_of_assistant:
                    response.data.user_opted_out_of_assistant,
                  will_bot_join: response.data.will_bot_join,
                };
              }
              return value;
            });
            this.openSnackbar(
              'AI assistant has been removed from the meeting "' +
                response.data.meeting_title +
                '"'
            );
            ReactGA.event({
              category: "My Meetings",
              action: "Opt out of meeting by host API success",
            });
            this.setState({
              meetings: newMeetings,
            });
            return response;
          } else if (response.status === 404) {
            this.openSnackbar("Sorry, the requested meeting was not found.");
          } else {
            this.openSnackbar("Sorry, an error occurred. Please try again.");
          }
          ReactGA.event({
            category: "Failure",
            action: "Opt out of meeting by host API failed",
          });
          return response;
        }.bind(this)
      );
    } else {
      return optInMeetingByHost(meetingUuid).then(
        function(response) {
          if (response.status === 200) {
            var newMeetings = this.state.meetings.map((value) => {
              if (value.uuid === meetingUuid) {
                return {
                  ...value,
                  user_opted_out_of_assistant:
                    response.data.user_opted_out_of_assistant,
                  will_bot_join: response.data.will_bot_join,
                };
              }
              return value;
            });
            this.openSnackbar(
              'AI assistant has been added to the meeting "' +
                response.data.meeting_title +
                '"'
            );
            ReactGA.event({
              category: "My Meetings",
              action: "Opt in meeting by host API success",
            });
            this.setState({
              meetings: newMeetings,
            });
            return response;
          } else if (response.status === 404) {
            this.openSnackbar("Sorry, the requested meeting was not found.");
          } else {
            this.openSnackbar("Sorry, an error occurred. Please try again.");
          }
          ReactGA.event({
            category: "Failure",
            action: "Opt in meeting by host API failed",
          });
          return response;
        }.bind(this)
      );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="My Meetings">
        <div className={classes.root}>
          <div className={classes.content}>
            <Grid container spacing={4}>
              {this.renderAlerts()}
            </Grid>
            <Grid
              container
              justify-content="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item lg={2} md={2} xl={2} xs={2}>
                {this.renderSearchButton()}
              </Grid>
              <Grid item lg={8} md={8} xl={8} xs={8}>
                {this.renderToggleButton()}
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                {this.renderUploadMeetingButton()}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {this.renderMeetings()}
              </Grid>
            </Grid>
            <ShareDialog
              meetingUuid={this.state.shareDialogMeeting}
              folderUuid={this.state.shareFolderUuid}
              isShareDialogOpen={this.state.isShareDialogOpen}
              handleClose={this.handleCloseShareDialog}
              openSnackbar={this.openSnackbar}
              isQuickShare={true}
              isScheduled={this.state.tab === this.tabIndex.scheduled}
            />
          </div>
        </div>
        {this.renderQuickMenu()}
        {this.renderRenameDialog()}
        {this.renderDeleteDialog()}
        {this.addSnackbar()}
      </DashboardLayout>
    );
  }
}

MyMeetings.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
};

export default withStyles(styles)(MyMeetings);
