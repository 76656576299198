import { Link, Tooltip, Typography, withStyles } from "@material-ui/core";
import { Forum as LiveHelpIcon } from "@material-ui/icons";
// Externals
import classNames from "classnames";
import { Paper } from "components";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { updateMeetingQuestionandAnswer } from "services/speech";
import { timeFormatter } from "../../../../helpers/time";
import TopQuestionsSegment from "../TopQuestionsSegment";
import styles from "./styles";

class TopQuestions extends Component {
  state = {
    data: {},
    caption: "",
    questions: [],
  };

  componentWillMount() {
    var questions = this.props.data.questions;
    this.setState({
      questions: questions,
    });
  }

  componentDidMount() {
    this.setState({
      questions: this.props.data.questions,
    });
  }

  addTopQuestions = () => {
    if (
      this.state.questions === null ||
      this.state.questions.length === null ||
      this.state.questions.length === 0 ||
      this.state.questions[0].questions === "" || // Handles edge case where last item is deleted
      this.state.questions[0].questions.length === 0 // Handles edge case where last item is deleted
    ) {
      return this.addEmptyState();
    }
    return (
      <div>
        <ol>
          {this.state.questions.map((e) => {
            return this.addQuestions(e.questions);
          })}
        </ol>
      </div>
    );
  };

  addQuestions = (questions) => {
    const { classes, className, ...rest } = this.props;
    questions.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    return (
      <div>
        <div>
          <ul>
            {questions.map((e) => {
              return this.addQuestionItem(e);
            })}
          </ul>
        </div>
      </div>
    );
  };

  addQuestionItem = (question) => {
    const { classes, className, ...rest } = this.props;
    let speakerName = "";
    if (question.phrase_speaker_tag) {
      speakerName = this.getSpeakerName(question.phrase_speaker_tag) + ": ";
    }
    if (
      question.phrase !== null ||
      question.phrase !== undefined ||
      question.phrase !== "" //Handles edge case where last item is deleted
    ) {
      return (
        <div>
          <TopQuestionsSegment
            question={question}
            speakers={this.props.data.participants_details}
            data={this.props.data}
            questionAndAnswerUpdateHandler={this.handleQuestionandAnswerUpdate}
            questionAndAnswerDeletehandler={this.handleQuestionandAnswerDelete}
            handleDeleteIcon={this.handleDeleteIcon}
            updateAllQuestionAndAnswer={this.updateQuestionandAnswer}
            setMediaPlayerCurrentTimeAndPlay={
              this.props.setMediaPlayerCurrentTimeAndPlay
            }
            setTimestampLink={this.setTimestampLink}
          />
        </div>
      );
    }
  };

  addAnswer = (question) => {
    if (question.answer == null) {
      return;
    }
    var splitAnswer = null;
    if (question.specific_answer) {
      let indexStart = question.answer.indexOf(question.specific_answer);
      if (indexStart >= 0) {
        splitAnswer = [
          question.answer.slice(0, indexStart),
          question.answer.slice(
            indexStart,
            indexStart + question.specific_answer.length
          ),
          question.answer.slice(indexStart + question.specific_answer.length),
        ];
      }
    }
    const { classes, className, ...rest } = this.props;
    var speakerName = "";
    if (question.answer_speaker_tag) {
      speakerName = this.getSpeakerName(question.answer_speaker_tag) + ": ";
    }
    if (splitAnswer) {
      return (
        <Typography className={classes.answer} variant="body1">
          {speakerName}"{splitAnswer[0]}
          <Tooltip title="Insight: Direct Answer">
            <span className={classes.specificAnswer}>{splitAnswer[1]}</span>
          </Tooltip>
          {splitAnswer[2]}"
        </Typography>
      );
    }
    return (
      <Typography className={classes.answer} variant="body1">
        {speakerName}"{question.answer}"
      </Typography>
    );
  };

  getSpeakerName = (speakerTag) => {
    var speaker = "SPEAKER " + speakerTag;
    this.props.data.participants_details.forEach((participant, _index) => {
      if (participant.speaker_tag === speakerTag) {
        speaker = participant.name;
      }
    });
    return speaker;
  };

  handleDeleteIcon = (menuAnchorEl, timestamp, speakerTag) => {
    this.setState({
      menuAnchorEl: menuAnchorEl,
      timestamp: timestamp,
      currentSpeakerTag: speakerTag,
    });
  };

  handleTimestampClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Q & A",
    });
  };

  handleQuestionandAnswerDelete = (timeStamp) => {
    let array = [...this.state.questions]; // make a separate copy of the array
    let index = array[0].questions.findIndex(
      (obj) => obj.timestamp === timeStamp
    );
    if (index !== -1) {
      array[0].questions.splice(index, 1);
      this.setState({ questions: array });
    }
    return array;
  };

  handleQuestionandAnswerUpdate = (updatedSentence) => {
    let updatedQuestionandAnswers = this.state.questions[0].questions.map(
      (currentQuestion) => {
        if (
          updatedSentence.speaker_tag === currentQuestion.phrase_speaker_tag &&
          updatedSentence.timestamp === currentQuestion.timestamp
        ) {
          currentQuestion.phrase =
            updatedSentence.sentence != undefined
              ? updatedSentence.sentence
              : currentQuestion.phrase;
          currentQuestion.answer =
            updatedSentence.answer != undefined
              ? updatedSentence.answer
              : currentQuestion.answer;
        }
        return currentQuestion;
      }
    );
    let filteredQuestionsandAnswers = updatedQuestionandAnswers.filter(
      (question) => {
        if (question.phrase) {
          return true;
        } else {
          return false;
        }
      }
    );

    this.updateQuestionandAnswer(filteredQuestionsandAnswers, [
      updatedSentence.timestamp,
    ]);
    return filteredQuestionsandAnswers;
  };

  updateQuestionandAnswer = (newQuestionsandAnswers) => {
    updateMeetingQuestionandAnswer(
      this.props.data.meeting_id,
      newQuestionsandAnswers
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
          }
          this.props.questionUpdateFailureHandler();
          ReactGA.event({
            category: "Failure",
            action: "Update meeting question and answer API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Update meeting question and answer API success",
          });
          this.props.questionUpdateResponseHandler(response.data.answer);
        }
      }.bind(this)
    );
  };

  setTimestampLink = (timestamp) => {
    if (
      !this.props.data.is_recording_enabled ||
      (this.props.isSharedMeetingView &&
        !this.props.data.share_granularity.share_recording)
    ) {
      return;
    }
    return (
      <Link
        component="button"
        onClick={() => this.handleTimestampClicked(timestamp)}
      >
        ({timeFormatter(timestamp)})
      </Link>
    );
  };

  addEmptyState = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <div>
        <Typography className={classes.phrase} variant="body1">
          It didn't seem like there were any conversation highlights from the
          meeting!
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.content}>
          <div className={classes.iconWrapper}>
            <LiveHelpIcon className={classes.icon} />
          </div>
          <div className={classes.details}>
            <Typography className={classes.title} variant="h5">
              TOP QUESTIONS & ANSWERS
            </Typography>
          </div>
        </div>
        <div className={classes.footer}>{this.addTopQuestions()}</div>
      </Paper>
    );
  }
}

TopQuestions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopQuestions);
