export default (theme) => ({
  root: {},
  tableRow: {
    height: "64px",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  tableCellInner: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px",
  },
  nameText: {
    cursor: "pointer",
  },
  actionIcon: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    "&.disabled": {
      color: theme.palette.text.secondary,
    },
  },
  disabled: {
    opacity: "0.5",
  },
  willNotJoinIcon: {
    color: theme.palette.danger.main,
  },
});
