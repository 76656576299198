export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isSoneroUser = (email) => {
  if (!email) return false;
  const soneroEmail = '@sonero.ai';
  const kennelEmail = '@kennelstudios.ca';
  const kickoffEmail = '@kickoffsoftware.com';
  return email.endsWith(soneroEmail) || email.endsWith(kennelEmail) || email.endsWith(kickoffEmail);
}
