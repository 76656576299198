const placeholderImage = "/sonero-static/images/logo_with_name_square.png";

export default theme => ({
  root: {},
  pipContainer: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "250px",
    height: "250px",
    borderRadius: "10px",
    border: `2px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.background.grey,
    background: `url(${placeholderImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    boxShadow: "4px 2px 5px grey",
    '&:hover': {
      border: "none",
      boxShadow: "none",
    },
    "&:hover > div": {
      display: "block",
    }
  },
  buttonsContainer: {
    display: "none",
    height: "100%",
    width: "100%",
    borderRadius: "10px",
    background: "rgba(0, 0, 0, 0.7)",
  },
  closeIcon: {
    position: "absolute",
    right: "5px",
    top: "5px",
    cursor: "pointer",
    color: "white",
  },
  controlsContainer: {
    width: "80%",
    position: "absolute",
    top: "60%",
    left: "10%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  controlButtonIcon: {
    cursor: "pointer",
    color: "white",
    transform: "scale(1.4)",
  },
});