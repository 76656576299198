export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progressWrapper: {
    paddingTop: "48px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.primary,
  },
  freeTierCaption: {
    color: theme.palette.text.secondary,
  },
  resultsTable: {
    marginTop: theme.spacing.unit * 4,
  },
  flexboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  searchBar: {
    flexGrow: 1,
  },
  filterChip: {
    margin: theme.spacing.unit,
    height: "36px",
    borderRadius: "72px",
    animation: "$expand 500ms",
  },
  flexboxColumnContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
  },
  chipContent: {
    display: "flex",
    alignItems: "center",
  },
  chipInput: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    border: "none",
    color: "rgb(255, 255, 255)",
    width: "125px",
    backgroundImage: "linear-gradient(white, white)",
    backgroundSize: "0% 1px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 calc(100% - 0px)",
    transition: "background-size 0.5s",
    "&:focus": {
      outline: "none",
      backgroundSize: "100% 1px",
    },
    "&::placeholder": {
      color: "rgb(211, 211, 211)",
    },
  },
  chipSearchIcon: {
    cursor: "pointer",
    color: "rgb(211, 211, 211)",
    "&:hover": {
      color: "rgb(255, 255, 255)",
    },
  },
  emptyHeader: {
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "26px",
    fontWeight: "500",
    textAlign: "center",
    width: "75%",
    minWidth: "200px",
    margin: "64px auto",
    opacity: "0.4",
    "@media (max-width: 300px)": {
      fontSize: "16px",
    },
  },
  "@keyframes expand": {
    "0%": { transform: "scale(1, 1)" },
    "50%": { transform: "scale(1.05, 1.1)" },
    "100%": { transform: "scale(1, 1)" },
  },
});
