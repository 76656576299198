import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Typography, IconButton, ListItem } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { MetricBar } from "components";
import { getScoreColor, subScoreLabels } from "helpers/meetingScore";

function OverallScore(props) {
  const {
    classes,
    avgPerformanceScore,
    avgEfficiencyScore,
    avgEngagementScore,
    avgFeedbackScore,
    handleExpand,
    memberName,
  } = props;

  let prefix = "My";
  if (memberName) {
    prefix = memberName.split(" ")[0];
    if (prefix.endsWith("s")) {
      prefix += "'";
    } else {
      prefix += "'s";
    }
  }

  return (
    <div className={classes.scoresContainer}>
      <ListItem
        button
        className={classes.scoreContainer}
        onClick={() => handleExpand(subScoreLabels.performance)}
        disabled={avgPerformanceScore < 0}
      >
        <div>
          <Typography variant="h6" className={classes.scoreLabel}>
            {prefix} Performance
            <span className={classes.score}>
              {avgPerformanceScore >= 0 ? Math.round(avgPerformanceScore) : 0}
              <span className={classes.outOfTen}>/10</span>
            </span>
          </Typography>
          <MetricBar
            barPercentage={avgPerformanceScore * 10}
            barColor={getScoreColor(avgPerformanceScore)}
            height="12px"
            fillAnimation
          />
        </div>
        <IconButton style={{ backgroundColor: "transparent" }}>
          <ChevronRight color="primary" className={classes.largerIcon} />
        </IconButton>
      </ListItem>

      <ListItem
        button
        className={classes.scoreContainer}
        onClick={() => handleExpand(subScoreLabels.efficiency)}
        disabled={avgEfficiencyScore < 0}
      >
        <div>
          <Typography variant="h6" className={classes.scoreLabel}>
            Meeting Efficiency
            <span className={classes.score}>
              {avgEfficiencyScore >= 0 ? Math.round(avgEfficiencyScore) : 0}
              <span className={classes.outOfTen}>/10</span>
            </span>
          </Typography>
          <MetricBar
            barPercentage={avgEfficiencyScore * 10}
            barColor={getScoreColor(avgEfficiencyScore)}
            height="12px"
            fillAnimation
          />
        </div>
        <IconButton style={{ backgroundColor: "transparent" }}>
          <ChevronRight color="primary" className={classes.largerIcon} />
        </IconButton>
      </ListItem>

      <ListItem
        button
        className={classes.scoreContainer}
        onClick={() => handleExpand(subScoreLabels.engagement)}
        disabled={avgEngagementScore < 0}
      >
        <div>
          <Typography variant="h6" className={classes.scoreLabel}>
            Audience Engagement
            <span className={classes.score}>
              {avgEngagementScore >= 0 ? Math.round(avgEngagementScore) : 0}
              <span className={classes.outOfTen}>/10</span>
            </span>
          </Typography>
          <MetricBar
            barPercentage={avgEngagementScore * 10}
            barColor={getScoreColor(avgEngagementScore)}
            height="12px"
            fillAnimation
          />
        </div>
        <IconButton style={{ backgroundColor: "transparent" }}>
          <ChevronRight color="primary" className={classes.largerIcon} />
        </IconButton>
      </ListItem>

      <ListItem
        button
        className={classes.scoreContainer}
        onClick={() => handleExpand(subScoreLabels.feedback)}
        disabled={avgFeedbackScore < 0}
      >
        <div>
          <Typography variant="h6" className={classes.scoreLabel}>
            Attendee Feedback
            <span className={classes.score}>
              {avgFeedbackScore >= 0 ? Math.round(avgFeedbackScore) : 0}
              <span className={classes.outOfTen}>/10</span>
            </span>
          </Typography>
          <MetricBar
            barPercentage={avgFeedbackScore * 10}
            barColor={getScoreColor(avgFeedbackScore)}
            height="12px"
            fillAnimation
          />
        </div>
        <IconButton style={{ backgroundColor: "transparent" }}>
          <ChevronRight color="primary" className={classes.largerIcon} />
        </IconButton>
      </ListItem>
    </div>
  );
}

export default withStyles(styles)(OverallScore);
