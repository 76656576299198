import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Step,
  StepLabel,
  Stepper,
  withStyles,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Shared services
import {
  getZoomClientId,
  connectToZoom,
  getIntegrationsStatus,
  getAPIHost,
  connectToGoogleCalendar,
  connectToMicrosoft,
  getOrgSettings,
} from "services/speech";

import ReactGA from "react-ga";

import queryString from "query-string";

// Component styles
import styles from "./styles";

import { enrollUser, postNotificationSettings } from "services/speech";

import { sharingLevels } from "helpers";

import EnrollVideo from "views/EnrollSpeaker/components/EnrollVideo";

import { meetingParticipantTypes } from "helpers";
import { isUserInOrg } from "helpers/user";

class Setup extends Component {
  zapierAPIKey = "";

  state = {
    results: {},
    isLoading: false,
    isZoomLoading: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    isZoomConnected: false,
    hasZoomCloudRecording: false,
    isZapierAPIKeyExists: false,
    isGoogleCalendarConnected: false,
    isMicrosoftConnected: false,
    showGoogleCalendar: false,
    openZapierAPIKeyDialog: false,
    step: 1,
    videoBlob: null,
    isEnrolled: false,
    didUpdateSettingsToDefault: false,
    conferenceType: null,
    zoomMethodValue: "bot",
    joinMethodValue: "join-all",
    copyMeeting: "",
    diableNonHostDialog: false,
    orgPrefs: {},
  };

  defaultPrefs = {
    isAutomaticCallInEnabled: true,
    automaticSharingLevel: sharingLevels.all,
    joinNonHostMeetings: true,
    processMeetingParticipantLevel: meetingParticipantTypes.all,
    isAutomaticSharingEnabled: true,
    isAutomaticInternalSharingEnabled: true,
    isMeetingAssistantNotificationEnabled: true,
    meetingAssistantNotificationValue: 60,
    isAutomaticFeedbackEnabled: true,
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let code = params.code;
    let conferenceType = params.conferenceType;
    let calendarType = params.calendarType;
    let step = params.step;
    let error = params.error;
    let copyMeeting = params.copy;
    if (copyMeeting != null) {
      this.setState({ copyMeeting });
    }
    if (step != null) {
      this.setState({ step: parseInt(step) });
    }
    if (conferenceType != null) {
      if (conferenceType === "zoom") {
        this.setState({ zoomMethodValue: "cloud", step: 2 });
      }
      this.setState({ conferenceType: conferenceType });
    }

    this.setPrefs();

    let isEnrolled = localStorage.getItem("isEnrolled") === "true";
    if (isEnrolled) {
      this.setState({ isEnrolled: isEnrolled });
    }

    if (error) {
      this.setState({ error: error });
    }

    this.getAllIntegrationsStatus();
    if (code != null) {
      this.connect(code);
    }

    this.getOrgPrefsIfAvailable();
  }

  getRedirectURL = () => {
    return `${getAPIHost()}/integrations`;
  };

  getOrgPrefsIfAvailable = () => {
    getOrgSettings().then((res) => {
      if (res.status !== 200) {
        return;
      }
      const data = res.data;
      const orgPrefs = {};
      if (data.is_automatic_call_in_enabled != null) {
        orgPrefs.isAutomaticCallInEnabled = data.is_automatic_call_in_enabled;
      }
      if (data.automatic_sharing_level != null) {
        orgPrefs.automaticSharingLevel = data.automatic_sharing_level;
      }
      if (data.join_non_host_meetings != null) {
        orgPrefs.joinNonHostMeetings = data.join_non_host_meetings;
      }
      if (data.process_meeting_participant_level != null) {
        orgPrefs.processMeetingParticipantLevel =
          data.process_meeting_participant_level;
      }
      if (data.is_automatic_sharing_enabled != null) {
        orgPrefs.isAutomaticSharingEnabled = data.is_automatic_sharing_enabled;
      }
      if (data.is_automatic_internal_sharing_enabled != null) {
        orgPrefs.isAutomaticInternalSharingEnabled =
          data.is_automatic_internal_sharing_enabled;
      }
      if (data.is_meeting_assistant_notification_enabled != null) {
        orgPrefs.isMeetingAssistantNotificationEnabled =
          data.is_meeting_assistant_notification_enabled;
      }
      if (data.meeting_assistant_notification_value != null) {
        orgPrefs.meetingAssistantNotificationValue =
          data.meeting_assistant_notification_value;
      }
      if (data.is_automatic_feedback_enabled != null) {
        orgPrefs.isAutomaticFeedbackEnabled =
          data.is_automatic_feedback_enabled;
      }
      this.setState({ orgPrefs });
    });
  };

  authorizeGcal = () => {
    this.setState({ isLoading: true });
    var oauthState = null;
    if (this.state.step === 1) {
      oauthState = "onboarding-" + this.state.conferenceType;
    } else {
      oauthState = "onboarding-calendar";
    }
    connectToGoogleCalendar(oauthState).then(
      function(response) {
        if (response.status === 200) {
          var authorizeUrl = response.data.message;
          if (authorizeUrl != null) {
            window.open(authorizeUrl, "_self");
          }
          ReactGA.event({
            category: "Onboarding",
            event: "Google Calendar authorized",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status === 403 &&
          response.data.error_type === "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
          ReactGA.event({
            category: "Failure",
            event:
              "Google Calendar authorization failure due to unverified email",
          });
        } else {
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            event: "Google Calendar authorization failure",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  authorizeMicrosoft = () => {
    this.setState({ isLoading: true });
    let oauthState = "onboarding-" + this.state.conferenceType;
    connectToMicrosoft(oauthState).then(
      function(response) {
        if (response.status === 200) {
          var authorizeUrl = response.data.message;
          if (authorizeUrl != null) {
            window.open(authorizeUrl, "_self");
          }
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (
          response.status === 403 &&
          response.data.error_type === "unverified_email"
        ) {
          this.openSnackbar("Please verify your email address first!");
          ReactGA.event({
            category: "Failure",
            event: "Microsoft authorization failure due to unverified email",
          });
        } else {
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            event: "Microsoft authorization failure",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  connect = (code) => {
    this.setState({ isZoomLoading: true });
    connectToZoom(code).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            isZoomLoading: false,
            isZoomConnected: true,
            zoomMethodValue: "cloud",
          });
          ReactGA.event({
            category: "Zoom",
            action: "Zoom integration successful",
          });
        } else if (
          response.status == 403 &&
          response.data.error_type == "unverified_email"
        ) {
          this.setState({
            isZoomLoading: false,
            isZoomConnected: false,
          });
          this.openSnackbar("Please verify your email address first!");
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isZoomLoading: false,
            isZoomConnected: false,
          });
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            action: "Zoom integration failed",
          });
        }
      }.bind(this)
    );
  };

  getAllIntegrationsStatus = () => {
    this.setState({ isLoading: true });
    getIntegrationsStatus().then(
      function(response) {
        if (response.status === 200) {
          var isZoomConnected = response.data.is_zoom_connected;
          var hasZoomCloudRecording = response.data.has_zoom_cloud_recording;
          var isZapierAPIKeyExists = response.data.is_zapier_api_key_exists;
          var isGoogleCalendarConnected =
            response.data.is_google_calendar_connected;
          var isMicrosoftConnected = response.data.is_microsoft_connected;
          var showGoogleCalendar = response.data.show_google_calendar;
          this.setState({
            isZoomConnected: isZoomConnected,
            hasZoomCloudRecording: hasZoomCloudRecording,
            isZapierAPIKeyExists: isZapierAPIKeyExists,
            isGoogleCalendarConnected: isGoogleCalendarConnected,
            isMicrosoftConnected: isMicrosoftConnected,
            showGoogleCalendar: showGoogleCalendar,
            isLoading: false,
            zoomMethodValue: isZoomConnected ? "cloud" : "bot",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
        }
      }.bind(this)
    );
  };

  enrollBegin = () => {
    this.setState({ isEnrollingSpeakerProfile: true });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  renderLoading = () => {
    const { classes } = this.props;
    const firstName = localStorage.getItem("firstName");

    return (
      <DashboardLayout title={"Hello " + (firstName ? firstName : "") + " 👋"}>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.isLoading || this.state.isZoomLoading) {
      return this.renderLoading();
    }

    const firstName = localStorage.getItem("firstName");

    return (
      <DashboardLayout title={"Hello " + (firstName ? firstName : "") + " 👋"}>
        <div className={classes.root}>
          {this.state.error && this.renderError()}
          {this.renderWidget()}
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
        </div>
      </DashboardLayout>
    );
  }

  handleNext = () => {
    this.logNextClick(this.state.step);
    this.setState((prevState) => {
      return { step: prevState.step + 1 };
    });
  };

  handlePrev = () => {
    this.logPrevClick(this.state.step);
    this.setState((prevState) => {
      return { step: prevState.step - 1 };
    });
  };

  getTitle() {
    switch (this.state.step) {
      case 1:
        return "Welcome to Sonero!";
      case 2:
        return "Enroll your speaker profile";
      case 3:
        return "Customize your experience";
      default:
        return "Welcome to Sonero!";
    }
  }

  getSteps() {
    return [
      "Connect your calendar",
      "Enroll speaker profile",
      "Customize your experience",
    ];
  }

  renderWidget() {
    const { classes, className, ...rest } = this.props;

    var steps = this.getSteps();

    return (
      <Portlet {...rest} className={classes.mainPortlet}>
        <PortletHeader className={classes.portletHeader}>
          <PortletLabel title={this.getTitle()} />
          <Stepper activeStep={this.state.step - 1}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </PortletHeader>
        {this.renderContent()}
      </Portlet>
    );
  }

  renderContent() {
    if (this.state.step === 1) {
      return this.renderStep1Content();
    } else if (this.state.step === 2) {
      return this.renderStep2Content();
    } else if (this.state.step === 3) {
      return this.renderStep3Content();
    }
  }

  renderStep1Content() {
    const { classes, className, ...rest } = this.props;
    return (
      <>
        <PortletContent className={classes.contentStep1}>
          <div className={classes.instructions}>
            <Typography className={classes.header} variant="h4">
              Let's get you set up for incredibly efficient meetings in{" "}
              <span className={classes.headerPrimary}>3 short steps.</span>
            </Typography>
            <div className={classes.captionContainer}>
              <Typography className={classes.caption} variant="subtitle1">
                <b>Integrate with your calendar</b> (the most critical step).{" "}
                <br></br>
                {this.state.copyMeeting !== "" ? (
                  <span>
                    For your own copy of <b>{this.state.copyMeeting}</b>,
                    integrate your calendar. We'll provide notes, insights,
                    analytics, and more!
                  </span>
                ) : (
                  <span>
                    This lets Sonero join your meetings automatically to provide
                    notes, analytics, recommendations and more!
                  </span>
                )}
              </Typography>

              <Typography
                className={classes.calendarSelectCaption}
                variant="subtitle1"
              >
                <b>Select your calendar tool:</b>
              </Typography>
            </div>
            <Grid container className={classes.conferenceSolutions} spacing={6}>
              {this.renderGoogleCalendarConnection()}
              {this.renderOutlookConnection()}
            </Grid>
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="small"
            variant="contained"
            disabled={
              !this.state.isGoogleCalendarConnected &&
              !this.state.isMicrosoftConnected
            }
            onClick={this.handleNext}
          >
            NEXT
          </Button>
        </PortletFooter>
      </>
    );
  }

  renderGoogleCalendarConnection() {
    const { classes, className, ...rest } = this.props;
    if (this.state.isGoogleCalendarConnected) {
      return (
        <>
          <Grid item xs={6} sm={5} md={5} lg={5}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.calendarGoogleLogo}
                  image="/sonero-static/images/google_calendar_logo.svg"
                  title="Google Calendar"
                />
                <CardContent>
                  <Typography
                    className={classes.captionBottom}
                    variant="h5"
                    align="center"
                  >
                    Google Calendar is{" "}
                    <span className={classes.connected}>connected</span>{" "}
                    <span role="img" aria-label="connected">
                      🔌
                    </span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={6} sm={5} md={5} lg={5}>
            <Card>
              <CardActionArea onClick={this.authorizeGcal}>
                <CardMedia
                  className={classes.calendarGoogleLogo}
                  image="/sonero-static/images/google_calendar_logo.svg"
                  title="Google Calendar"
                />
                <CardContent>
                  <Typography
                    className={classes.captionBottom}
                    variant="h5"
                    align="center"
                  >
                    Google Calendar
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </>
      );
    }
  }

  renderStep2Content() {
    const { classes, className, ...rest } = this.props;
    return (
      <>
        <PortletContent className={classes.content}>
          <div className={classes.instructions}>
            {this.renderEnrollSpeaker()}
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="small"
            variant="text"
            onClick={this.handlePrev}
          >
            PREVIOUS
          </Button>
          <Button
            className={classes.submitButton}
            color="primary"
            size="small"
            variant="contained"
            disabled={!this.state.isEnrolled}
            onClick={this.handleNext}
          >
            NEXT
          </Button>
        </PortletFooter>
      </>
    );
  }

  renderEnrollSpeaker = () => {
    const { classes } = this.props;
    if (this.state.isEnrolled) {
      return (
        <>
          <Typography className={classes.caption} variant="subtitle1">
            Your speaker profile is enrolled!{" "}
            <span role="img" aria-label="celebration enrolled">
              🎉
            </span>
          </Typography>
          <Typography className={classes.caption} variant="subtitle1">
            We're almost done, hit <b>next</b> to continue.
          </Typography>
        </>
      );
    } else {
      return (
        <Grid container spacing={4}>
          <Grid item lg={5} md={5} xl={3} xs={12}>
            <Typography className={classes.header} variant="h4">
              1 down, only{" "}
              <span className={classes.headerPrimary}>2 more to go!</span>
            </Typography>
            <div className={classes.captionContainer}>
              <Typography
                className={classes.captionStepTwoFirstParagraph}
                variant="subtitle1"
              >
                <b>Record your Speaker Profile</b> <br></br>
                This lets Sonero properly identify you and attribute your speech
                in our meeting insights
              </Typography>
              <Typography className={classes.caption} variant="h5">
                Click Record and read me:
              </Typography>
              <Typography className={classes.caption} variant="subtitle1">
                "Hello Sonero. My name is {this.getName()} and I'm excited to
                meet you. I'm recording this so that you can learn to recognize
                my amazing voice and we can become good friends. I'm speaking
                clearly and there's no background noise at all. I'm thrilled to
                see how you're going to make my meetings awesome. Well, it's
                time to say goodbye but I'm sure we'll meet again soon!"
              </Typography>
            </div>
          </Grid>
          <Grid item lg={7} md={7} xl={9} xs={12}>
            <EnrollVideo
              className={classes.item}
              uploadEnrollVideo={this.uploadEnrollVideo}
              clientError={this.setError}
              videoBlob={this.state.videoBlob}
            />
          </Grid>
        </Grid>
      );
    }
  };

  uploadEnrollVideo = (blob) => {
    this.setState({ isLoading: true, videoBlob: blob });
    enrollUser(blob).then(
      function(response) {
        if (response.status === 200) {
          this.setState({ isEnrolled: true, enrollDialogOpen: true, step: 3 });
          ReactGA.event({
            category: "User",
            action: "User speaker profile enrolled",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
          ReactGA.event({
            category: "Failure",
            action: "Refresh token expired",
          });
        } else {
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Could not submit enrollment video",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  getName = () => {
    const fullName = localStorage.getItem("fullName");
    return fullName;
  };

  handleZoomMethodChange = (value) => {
    this.setState({
      zoomMethodValue: value,
    });
  };

  handleJoinMethodChange = (value) => {
    this.setState({
      joinMethodValue: value,
    });
  };

  renderOutlookConnection() {
    const { classes, className, ...rest } = this.props;
    if (this.state.isMicrosoftConnected) {
      return (
        <>
          <Grid item xs={6} sm={5} md={5} lg={5}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.calendarGoogleLogo}
                  image="/sonero-static/images/microsoft_outlook_logo.svg"
                  title="Outlook Calendar"
                />
                <CardContent>
                  <Typography
                    className={classes.captionBottom}
                    variant="h5"
                    align="center"
                  >
                    Outlook Calendar is{" "}
                    <span className={classes.connected}>connected</span>{" "}
                    <span role="img" aria-label="connected">
                      🔌
                    </span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={6} sm={5} md={5} lg={5}>
            <Card>
              <CardActionArea onClick={this.authorizeMicrosoft}>
                <CardMedia
                  className={classes.calendarGoogleLogo}
                  image="/sonero-static/images/microsoft_outlook_logo.svg"
                  title="Outlook Calendar"
                />
                <CardContent>
                  <Typography
                    className={classes.captionBottom}
                    variant="h5"
                    align="center"
                  >
                    Outlook Calendar
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </>
      );
    }
  }

  renderError = () => {
    const { classes } = this.props;
    return (
      <div className={classes.errorPortlet}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.error} variant="body1">
              {this.state.error}
            </Typography>
          </PortletContent>
        </Portlet>
      </div>
    );
  };

  renderStep3Content = () => {
    const { classes, className, ...rest } = this.props;
    const orgPrefs = this.state.orgPrefs;

    if (!this.state.didUpdateSettingsToDefault) {
      this.postNotificationSettingsToRemote(this.defaultPrefs, false).then(
        function(response) {
          this.setState({ didUpdateSettingsToDefault: true });
          this.setPrefs();
        }.bind(this)
      );
    }

    const alertsDisabled =
      orgPrefs.isMeetingAssistantNotificationEnabled != null &&
      orgPrefs.meetingAssistantNotificationValue != null;
    const {
      isMeetingAssistantNotificationEnabled,
      meetingAssistantNotificationValue,
    } = alertsDisabled ? orgPrefs : this.state;

    // Org settings can be enabled with processMeetingParticipantLevel == null
    // This can happen when the org setting has auto-join disabled
    const joinDisabled = orgPrefs.joinNonHostMeetings != null;
    const { joinNonHostMeetings, processMeetingParticipantLevel } = joinDisabled
      ? orgPrefs
      : this.state;

    const shareDisabled =
      orgPrefs.isAutomaticSharingEnabled != null &&
      orgPrefs.isAutomaticInternalSharingEnabled != null;
    const {
      isAutomaticSharingEnabled,
      isAutomaticInternalSharingEnabled,
    } = shareDisabled ? orgPrefs : this.state;

    const feedbackDisabled = orgPrefs.isAutomaticFeedbackEnabled != null;
    const { isAutomaticFeedbackEnabled } = feedbackDisabled
      ? orgPrefs
      : this.state;

    return (
      <>
        <PortletContent className={classes.content}>
          <Typography variant="h3" className={classes.lastStepHeader}>
            You're on the last step!
          </Typography>
          <Typography variant="h4" className={classes.lastStepSubHeader}>
            Pick your meeting settings
          </Typography>
          <div className={classes.lastStepContent}>
            <div className={classes.lastStepSection}>
              <Typography variant="h5">Before meetings</Typography>
              <div
                className={classes.lastStepSettingsContainer}
                disabled={alertsDisabled}
              >
                <Typography variant="h6">Meeting alerts</Typography>
                {alertsDisabled && (
                  <Typography className={classes.infoBox}>
                    This setting has been set at the org. level by your org.
                    owner.
                  </Typography>
                )}
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={isMeetingAssistantNotificationEnabled}
                        onChange={(e) =>
                          this.setState({
                            isMeetingAssistantNotificationEnabled:
                              e.target.checked,
                          })
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "8px",
                          marginRight: "8px",
                        }}
                        disabled={alertsDisabled}
                      />
                    }
                    label="Notify meeting attendees that Sonero will join the call"
                  />
                  <Select
                    variant="outlined"
                    fullWidth
                    value={meetingAssistantNotificationValue}
                    onChange={(e) =>
                      this.setState({
                        meetingAssistantNotificationValue: e.target.value,
                      })
                    }
                    disabled={alertsDisabled}
                  >
                    <MenuItem value={60}>60 minutes before</MenuItem>
                    <MenuItem value={30}>30 minutes before</MenuItem>
                    <MenuItem value={15}>15 minutes before</MenuItem>
                    <MenuItem value={5}>5 minutes before</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className={classes.lastStepSection}>
              <Typography variant="h5">During meetings</Typography>
              <div
                className={classes.lastStepSettingsContainer}
                disabled={joinDisabled}
              >
                <Typography variant="h6">
                  Sonero will join automatically
                </Typography>
                {joinDisabled && (
                  <Typography className={classes.infoBox}>
                    This setting has been set at the org. level by your org.
                    owner.
                  </Typography>
                )}
                <div>
                  <Typography variant="body1">Automatically join</Typography>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={joinNonHostMeetings}
                    onChange={(e) => {
                      if (this.state.copyMeeting === "" || e.target.value) {
                        this.setState({ joinNonHostMeetings: e.target.value });
                      } else {
                        this.setState({ disableNonHostDialog: true });
                      }
                    }}
                    disabled={joinDisabled}
                  >
                    <MenuItem value={true}>All my meetings</MenuItem>
                    <MenuItem value={false}>Only meetings that I host</MenuItem>
                  </Select>
                </div>
                <div>
                  <Typography variant="body1">
                    Join these meeting types
                  </Typography>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={processMeetingParticipantLevel}
                    onChange={(e) =>
                      this.setState({
                        processMeetingParticipantLevel: e.target.value,
                      })
                    }
                    disabled={joinDisabled}
                  >
                    <MenuItem value={meetingParticipantTypes.all}>
                      Internal and external meetings
                    </MenuItem>
                    <MenuItem value={meetingParticipantTypes.internal}>
                      Internal meetings only
                    </MenuItem>
                    <MenuItem value={meetingParticipantTypes.external}>
                      External meetings only
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className={classes.lastStepSection}>
              <Typography variant="h5">After meetings</Typography>
              <div
                className={classes.lastStepSettingsContainer}
                disabled={shareDisabled}
              >
                <Typography variant="h6">Share meeting insights</Typography>
                {shareDisabled && (
                  <Typography className={classes.infoBox}>
                    This setting has been set at the org. level by your org.
                    owner.
                  </Typography>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isAutomaticSharingEnabled}
                      onChange={(e) =>
                        this.setState({
                          isAutomaticSharingEnabled: e.target.checked,
                        })
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                      disabled={shareDisabled}
                    />
                  }
                  label="Share with all attendees"
                />
                {isUserInOrg() && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={isAutomaticInternalSharingEnabled}
                        onChange={(e) =>
                          this.setState({
                            isAutomaticInternalSharingEnabled: e.target.checked,
                          })
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "8px",
                          marginRight: "8px",
                        }}
                        disabled={shareDisabled}
                      />
                    }
                    label="Share with my teammates"
                  />
                )}
              </div>
              <div
                className={classes.lastStepSettingsContainer}
                disabled={feedbackDisabled}
              >
                <Typography variant="h6">Request meeting feedback</Typography>
                {feedbackDisabled && (
                  <Typography className={classes.infoBox}>
                    This setting has been set at the org. level by your org.
                    owner.
                  </Typography>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isAutomaticFeedbackEnabled}
                      onChange={(e) =>
                        this.setState({
                          isAutomaticFeedbackEnabled: e.target.checked,
                        })
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                      disabled={feedbackDisabled}
                    />
                  }
                  label="Request feedback from attendees"
                />
              </div>
            </div>
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <div className={classes.footerButtonContainer}>
            <Button
              className={classes.submitButton}
              color="primary"
              size="small"
              variant="text"
              onClick={this.handlePrev}
            >
              PREVIOUS
            </Button>
            <Button
              className={classes.submitButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={this.handleSaveSettings}
            >
              FINISH
            </Button>
          </div>
        </PortletFooter>
        {this.renderDisableNonHostDialog()}
      </>
    );
  };

  renderDisableNonHostDialog = () => {
    return (
      <Dialog
        open={this.state.disableNonHostDialog}
        onClose={() => this.setState({ disableNonHostDialog: false })}
      >
        <DialogContent>
          <Typography variant="body1">
            By choosing "Only meetings I host", you will not be able to get a
            copy of <b>{this.state.copyMeeting}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="small"
            onClick={() =>
              this.setState({
                joinNonHostMeetings: true,
                disableNonHostDialog: false,
              })
            }
          >
            Yes, meeting notes!
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() =>
              this.setState({
                joinNonHostMeetings: false,
                disableNonHostDialog: false,
              })
            }
          >
            No notes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  finish = () => {
    this.props.history.push("/dashboard?finished-onboarding");
  };

  setPrefs = () => {
    const joinNonHostMeetings = JSON.parse(
      localStorage.getItem("joinNonHostMeetings")
    );
    const processMeetingParticipantLevel = JSON.parse(
      localStorage.getItem("processMeetingParticipantLevel")
    );
    const isAutomaticSharingEnabled = JSON.parse(
      localStorage.getItem("isAutomaticSharingEnabled")
    );
    const isAutomaticInternalSharingEnabled = JSON.parse(
      localStorage.getItem("isAutomaticInternalSharingEnabled")
    );
    const isAutomaticFeedbackEnabled = JSON.parse(
      localStorage.getItem("isAutomaticFeedbackEnabled")
    );
    const isMeetingAssistantNotificationEnabled = JSON.parse(
      localStorage.getItem("isMeetingAssistantNotificationEnabled")
    );
    const meetingAssistantNotificationValue = JSON.parse(
      localStorage.getItem("meetingAssistantNotificationValue")
    );
    this.setState({
      joinNonHostMeetings: joinNonHostMeetings,
      processMeetingParticipantLevel: processMeetingParticipantLevel,
      isAutomaticSharingEnabled: isAutomaticSharingEnabled,
      isAutomaticInternalSharingEnabled: isAutomaticInternalSharingEnabled,
      isMeetingAssistantNotificationEnabled: isMeetingAssistantNotificationEnabled,
      meetingAssistantNotificationValue: meetingAssistantNotificationValue,
      isAutomaticFeedbackEnabled: isAutomaticFeedbackEnabled,
    });
  };

  handleSaveSettings = () => {
    const {
      joinNonHostMeetings,
      processMeetingParticipantLevel,
      isAutomaticSharingEnabled,
      isAutomaticInternalSharingEnabled,
      isMeetingAssistantNotificationEnabled,
      meetingAssistantNotificationValue,
      isAutomaticFeedbackEnabled,
    } = this.state;
    var prefs = {
      isAutomaticCallInEnabled: true,
      automaticSharingLevel: sharingLevels.all,
      joinNonHostMeetings: joinNonHostMeetings,
      processMeetingParticipantLevel: processMeetingParticipantLevel,
      isAutomaticSharingEnabled: isAutomaticSharingEnabled,
      isAutomaticInternalSharingEnabled: isAutomaticInternalSharingEnabled,
      isMeetingAssistantNotificationEnabled: isMeetingAssistantNotificationEnabled,
      meetingAssistantNotificationValue: meetingAssistantNotificationValue,
      isAutomaticFeedbackEnabled: isAutomaticFeedbackEnabled,
    };
    this.logNextClick(this.state.step);
    this.postNotificationSettingsToRemote(prefs, true).then(
      function(response) {
        this.setPrefs();
      }.bind(this)
    );
  };

  postNotificationSettingsToRemote = (notificationSettings, finish) => {
    if (finish) {
      this.setState({ isLoading: true });
    }
    return postNotificationSettings(notificationSettings).then(
      function(response) {
        if (response.status === 200) {
          ReactGA.event({
            category: "Onboarding",
            action: "Update meeting settings API success",
          });
          if (finish) {
            this.finish();
          }
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          if (finish) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Sorry, an error occurred. Please try again.",
              isLoading: false,
            });
          }
          ReactGA.event({
            category: "Failure",
            action: "Update meeting settings API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  logNextClick = (step) => {
    var action = `Next clicked during step ${step}`;
    if (step === 3) {
      action = "Onboarding complete";
    }
    ReactGA.event({
      category: "Onboarding",
      action: action,
    });
  };

  logPrevClick = (step) => {
    ReactGA.event({
      category: "Onboarding",
      action: `Prev clicked during step ${step}`,
    });
  };
}

Setup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Setup);
