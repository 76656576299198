import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  meetingScoreContainer: {
    position: "relative",
  },
  sectionHeader: {
    padding: theme.spacing.unit * 2,
    fontWeight: 700,
    background: theme.palette.background.blue,
    color: theme.palette.text.dark,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
  },
  meetingScoresContent: {
    padding: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    height: "60%",
    minHeight: "300px",
    margin: "auto",
    position: "relative",
    "&.isEmpty": {
      height: "auto",
    },
  },
  overallScoreContainer: {
    width: "40%",
    textAlign: "center",
  },
  score: {
    fontWeight: "700",
    fontSize: "28px",
    color: theme.palette.text.darkGrey,
    "&.overall": {
      fontSize: "42px",
    },
  },
  outOfTen: {
    fontSize: "14px",
    color: theme.palette.text.light,
  },
  emptyTextContainer: {
    width: "100%",
    height: "300px",
    position: "absolute",
    left: 0,
    bottom: "10px",
    zIndex: 10,
    background: fade(theme.palette.common.white, 0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyText: {
    width: "fit-content",
    maxWidth: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
  tabButtonsContainer: {
    margin: `${theme.spacing.unit * 3}px auto auto`,
    width: "fit-content",
  },
  tabButton: {
    color: theme.palette.primary.main,
    padding: "8px",
    fontSize: "12px",
  },
  selected: {
    "&&": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
  tabContent: {
    height: "315px",
  },
  meetingNameText: {
    color: "#4b4b5b",
    fontWeight: 600,
    padding: "0px 8px 0px 8px",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box !important",
    lineClamp: 3,
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
    cursor: "pointer",
  },
  "@media (max-width: 500px)": {
    meetingScoresContent: {
      flexDirection: "column",
      gap: theme.spacing.unit * 3,
      paddingTop: theme.spacing.unit * 3,
      width: "100%",
    },
    overallScoreContainer: {
      width: "100%",
    },
    scoresContainer: {
      width: "100%",
    },
  },
});
