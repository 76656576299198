import React, { Component } from "react";
import { withStyles, Menu, MenuItem, Button } from "@material-ui/core";
import { getSpeakerString } from "helpers/meetingParticipants";

const styles = (theme) => ({
  newSpeakerButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

class SpeakerMenu extends Component {
  render() {
    const {
      classes,
      menuAnchorEl,
      data,
      speakerTags,
      handleSpeakerUpdate,
      closeSpeakerMenu,
      currentSpeakerTag,
    } = this.props;
    const menuOpen = Boolean(menuAnchorEl);
    return (
      <Menu
        id="speaker-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={closeSpeakerMenu}
      >
        {speakerTags.map((speakerTag) => {
          if (speakerTag !== currentSpeakerTag) {
            return (
              <MenuItem onClick={() => handleSpeakerUpdate(speakerTag)}>
                {getSpeakerString(speakerTag, data.participants_details)}
              </MenuItem>
            );
          }
          return <></>;
        }, this)}
        <Button
          className={classes.newSpeakerButton}
          color="primary"
          variant="text"
          onClick={() => handleSpeakerUpdate(-1)}
        >
          Add New Speaker
        </Button>
      </Menu>
    );
  }
}

export default withStyles(styles)(SpeakerMenu);
