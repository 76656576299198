import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

function CircularBar(props) {
  const {
    classes,
    children,
    barPercentage,
    barFillColor,
    barEmptyColor,
    bgColor,
    smallerSizing,
  } = props;

  const isSmallBar = smallerSizing;

  return (
    <div className={classes.circularBarContainer}>
      <div
        style={
          isSmallBar
            ? {
                background: `conic-gradient(${barFillColor} ${barPercentage}%, ${barEmptyColor} 0)`,
                width: "100%",
                paddingBottom: "100%",
                borderRadius: "50%",
                position: "relative",
              }
            : {
                background: `conic-gradient(${barFillColor} ${barPercentage}%, ${barEmptyColor} 0)`,
                width: "max(12vw, 150px)",
                height: "max(12vw, 150px)",
                borderRadius: "50%",
                position: "relative",
              }
        }
      >
        <div
          className={classes.circularBarLabel}
          style={{ backgroundColor: bgColor }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CircularBar);
