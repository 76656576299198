export default [
    {
      value: 'uninitialized',
      label: 'Scheduled'
    },
    {
      value: 'started',
      label: 'In progress'
    },
    {
      value: 'waiting',
      label: 'Processing'
    },
    {
      value: 'processing',
      label: 'Processing'
    }
    ,
    {
      value: 'completed',
      label: 'Completed'
    },
    {
      value: 'completed_agenda_only',
      label: 'Agenda'
    },
    {
      value: 'failed',
      label: 'Failed'
    },
    {
      value: 'rejected',
      label: 'Quota Exceeded'
    }
  ];