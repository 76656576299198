// Material helpers
import {
  Button,
  Menu,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { ExternalSignUpLink } from "components";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
// Externals
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { getAPIHost } from "services/speech";
import { MeetingInfo } from "views/ViewMeeting/components";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  textField: {
    width: "100%",
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "63px",
    flexShrink: 0,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 3,
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: "pointer",
  },
  logoName: {
    color: theme.palette.text.main,
  },
  logoDivider: {
    marginBottom: theme.spacing.unit * 2,
  },
  toolbar: {
    minHeight: "auto",
    width: "100%",
  },
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    height: "64px",
    zIndex: theme.zIndex.appBar,
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "auto",
  },
  attendeesContainer: {
    position: "relative",
    display: "inline-block",
    marginLeft: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 0.5,
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  attendeesIcon: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
    color: theme.palette.text.secondary,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    cursor: "pointer",
  },
  hiddenMeetingInfo: {
    width: "min(max(35vw, 350px), 500px)",
  },
  feedbackButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 1.25,
  },
  url: {
    color: "#0767DB",
  },
  "@media (max-width: 900px)": {
    signUpText: {
      display: "none",
    },
  },
  "@media (max-width: 600px)": {
    titleContainer: {
      flexDirection: "column",
    },
  },
});

class ShareToolbar extends Component {
  state = {
    attendeesAnchor: null,
  };

  handleOpenAttendees = (event) => {
    this.setState({ attendeesAnchor: event.target });
  };

  handleCloseAttendees = () => {
    this.setState({ attendeesAnchor: null });
  };

  handleSignUpButtonClick = () => {
    ReactGA.event({
      category: "View Shared Meeting",
      action: "Sign up button clicked",
    });
  };

  render() {
    const { classes } = this.props;
    const showAttendeesButton =
      (!this.props.isRecordingEnabled ||
        !this.props.shareGranularity.share_recording) &&
      this.props.shareGranularity.share_attendees;

    return (
      <div className={classes.toolbarContainer}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoWrapper}>
            <a href="https://sonero.ai/">
              <Typography
                className={classes.logoName}
                variant="h1"
                color="primary"
              >
                sonero
              </Typography>
            </a>
          </div>
          <div className={classes.titleContainer}>
            <Typography variant="h4">{this.props.title}</Typography>
            {this.props.meetingData && showAttendeesButton && (
              <div className={classes.attendeesContainer}>
                <div
                  className={classes.attendeesIcon}
                  onClick={this.handleOpenAttendees}
                >
                  <GroupIcon />
                  <span>Attendees</span>
                </div>
                <Menu
                  anchorEl={this.state.attendeesAnchor}
                  open={Boolean(this.state.attendeesAnchor)}
                  onClose={this.handleCloseAttendees}
                  MenuListProps={{
                    disablePadding: true,
                  }}
                >
                  <div className={classes.hiddenMeetingInfo}>
                    <MeetingInfo
                      data={this.props.meetingData}
                      setMediaPlayerCurrentTimeAndPlay={(timestamp) => {
                        if (this.props.viewVideo) {
                          this.props.viewVideo.setMediaPlayerCurrentTimeAndPlay(
                            timestamp
                          );
                        }
                      }}
                      openSnackbar={this.props.openSnackbar}
                      hideShareButton={true}
                      showScoreToGuests={false}
                    />
                  </div>
                </Menu>
              </div>
            )}
          </div>
        </Toolbar>
      </div>
    );
  }
}

ShareToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShareToolbar);
