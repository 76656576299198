export default (theme) => ({
  root: {},
  clipContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing.unit,
    position: "relative",
  },
  clipLengthContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    height: "20px",
    marginLeft: theme.spacing.unit * 3,
  },
  videoClip: {
    width: "100%",
    height: "fit-content",
    maxHeight: `calc(90% - 20px - ${theme.spacing.unit * 2}px)`,
  },
  controls: {
    width: "100%",
    height: "10%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  controlButtonIcon: {
    cursor: "pointer",
    transform: "scale(1.25)",
    color: theme.palette.common.black,
    aspectRatio: "1 / 1",
  },
  loadingSpinner: {
    position: "absolute",
    left: "calc(50% - 20px)",
    right: "calc(50% - 20px)",
  },
});
