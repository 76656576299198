import axios from "axios";

export const getAPIHost = () => {
  if (process.env.REACT_APP_API_URL === "production") {
    return "https://app.sonero.ai";
  } else if (process.env.REACT_APP_API_URL === "development") {
    return "https://dev.sonero.ai";
  }
  return "http://localhost:8000";
};

export const uploadMeeting = (video, title, agenda, numOfParticipants, onUploadProgress) => {
  var url = `${getAPIHost()}/api/meeting/`;
  const formData = new FormData();

  formData.append("title", title);
  formData.append("video", video);
  formData.append("num_of_participants", numOfParticipants);
  formData.append("agenda", JSON.stringify(agenda));
  
  return newAuthenicatedRequestWithProgress("post", url, formData, onUploadProgress)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateMeeting = (id, agenda) => {
  var url = `${getAPIHost()}/api/meeting/update/${id}`;
  const formData = new FormData();
  formData.append("agenda", JSON.stringify(agenda));

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const renameMeeting = (id, title) => {
  var url = `${getAPIHost()}/api/meeting/update/rename/${id}`;
  const formData = new FormData();
  formData.append("title", title);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteMeeting = (id) => {
  var url = `${getAPIHost()}/api/meeting/delete/${id}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeeting = (id, fromWeeklyReport, fromMeetingCompletedEmail=false) => {
  let url = `${getAPIHost()}/api/meeting/${id}`;
  if (fromWeeklyReport) {
    url += "?weekly-report=True"
  }
  if (fromMeetingCompletedEmail) {
    url += "?email=True"
  }

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeetingShare = (id, password) => {
  var params = null;
  if (password) {
    params = { password: password };
  }

  var headers = {}

  if (isUserLoggedIn()) {
    return verifyToken().then(function(response) {
      if (response.status === 200) {
        let token = localStorage.getItem("token")
        headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        }
      }
      return getMeetingShareInternal(id, params, headers);
    }).catch(function(error) {
      return error.response;
    });
  }
  return getMeetingShareInternal(id, params, headers);
};

export const getMeetingShareInternal = (id, params, headers) => {
  const url = `${getAPIHost()}/api/meeting/share/view/${id}`;

  return axios({
    method: "get",
    url: url,
    params: params,
    headers: headers,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const updateMeetingShareStatus = (id, isEnabled, isPasswordRequired, fromQuickShare) => {
  const url = `${getAPIHost()}/api/meeting/share/status/${id}`;

  const formData = new FormData();
  formData.append("is_sharing_enabled", isEnabled);
  formData.append("is_password_required", isPasswordRequired);
  if (fromQuickShare != null) {
    formData.append("from_quick_share", fromQuickShare);
  }

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const shareMeetingWithParticipants = (id) => {
  const url = `${getAPIHost()}/api/meeting/share/participants/${id}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const shareMeetingViaEmail = (id, emailRecipients) => {
  const url = `${getAPIHost()}/api/meeting/share/emails/${id}`;
  const jsonData = {
    "emails_list": emailRecipients,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getShareMeetingEmailPreview = (id) => {
  const url = `${getAPIHost()}/api/meeting/share/emails/${id}`;
  
  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const shareMeetingInternally = (id, enable) => {
  const url = `${getAPIHost()}/api/meeting/share/internal/${id}`;
  const data = { enable: enable };

  return newAuthenticatedRequest("post", url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateMeetingShareGranularity = (id, shareGranularity) => {
  const url = `${getAPIHost()}/api/meeting/share/granularity/${id}`;
  return newAuthenticatedJSONRequest("post", url, shareGranularity)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateMeetingFeedbackStatus = (id, isEnabled) => {
  const url = `${getAPIHost()}/api/meeting/feedback/status/${id}`;

  const formData = new FormData();
  formData.append("is_feedback_enabled", isEnabled);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeetingFeedbackFromParticipants = (id) => {
  const url = `${getAPIHost()}/api/meeting/feedback/participants/${id}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeetingForRating = (uuid) => {
  const url = `${getAPIHost()}/api/meeting/feedback/${uuid}`;

  return axios({
    method: "get",
    url: url,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const submitNewMeetingRating = (uuid, data) => {
  const url = `${getAPIHost()}/api/meeting/feedback/${uuid}`;

  const formData = new FormData();
  formData.append("meeting_need", data.meeting_need);
  formData.append("attendee_importance", data.attendee_importance);
  formData.append("clear_decisions", data.clear_decisions);
  formData.append("meeting_productivity", data.meeting_productivity);

  return axios({
    method: "post",
    url: url,
    data: formData,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const saveNewBookmark = (id, bookmark) => {
  const url = `${getAPIHost()}/api/meeting/bookmark/create/${id}`;

  const formData = new FormData();
  formData.append("description", bookmark.description);
  formData.append("start_time", bookmark.startTime);
  if (bookmark.endTime) {
    formData.append("end_time", bookmark.endTime);
  }

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateBookmark = (bookmark) => {
  const url = `${getAPIHost()}/api/meeting/bookmark/update/${bookmark.uuid}`;

  const jsonData = {
    "description": bookmark.description,
    "start_time": bookmark.startTime,
    "end_time": bookmark.endTime,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteBookmark = (uuid) => {
  const url = `${getAPIHost()}/api/meeting/bookmark/delete/${uuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

// Agenda

export const deleteAgendaItem = (uuid) => {
  const url = `${getAPIHost()}/api/meeting/agenda-item/delete/${uuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createAgendaItem = (uuid) => {
  const url = `${getAPIHost()}/api/meeting/agenda-item/add/${uuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateAgendaItem = (agendaItem) => {
  const url = `${getAPIHost()}/api/meeting/agenda-item/update/${agendaItem.uuid}`;

  const jsonData = {
    "uuid": agendaItem.uuid,
    "topic": agendaItem.topic,
    "notes": agendaItem.notes,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const reorderAgendaItem = (uuid, reorderedAgendaItems) => {
  const url = `${getAPIHost()}/api/meeting/agenda-item/reorder/${uuid}`;
  const jsonData = { "agenda_items": reorderedAgendaItems };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteSharedAgendaItem = (uuid) => {
  const url = `${getAPIHost()}/api/meeting/share/agenda-item/delete/${uuid}`;

  return axios({
    method: "post", 
    url: url
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createSharedAgendaItem = (id, fullName, email) => {
  const url = `${getAPIHost()}/api/meeting/share/agenda-item/add/${id}`;

  const jsonData = {
    "author_name": fullName,
    "author_email": email,
  }

  return axios({
    method: "post", 
    url: url,
    data: jsonData,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateSharedAgendaItem = (agendaItem) => {
  const url = `${getAPIHost()}/api/meeting/share/agenda-item/update/${agendaItem.uuid}`;

  const jsonData = {
    "uuid": agendaItem.uuid,
    "topic": agendaItem.topic,
    "notes": agendaItem.notes,
  }
  
  return axios({
    method: 'post',
    url: url,
    data: jsonData,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const reorderSharedAgendaItem = (uuid, reorderedAgendaItems) => {
  const url = `${getAPIHost()}/api/meeting/share/agenda-item/reorder/${uuid}`;
  
  const jsonData = { "agenda_items": reorderedAgendaItems };

  return axios({
    method: "post",
    url: url,
    data: jsonData,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

// Action items

export const addActionItem = (meeting, task, owner_participant_id, type) => {
  const url = `${getAPIHost()}/api/meeting/action-item/add/${meeting.uuid}`;
  const jsonData = {
    task: task,
    owner_participant_id: owner_participant_id,
    type: type
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateActionItem = (actionItem, task) => {
  const url = `${getAPIHost()}/api/meeting/action-item/update/${actionItem.uuid}`;
  const jsonData = { task: task }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteActionItem = (actionItem) => {
  const url = `${getAPIHost()}/api/meeting/action-item/delete/${actionItem.uuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const completeActionItem = (actionItem) => {
  const url = `${getAPIHost()}/api/meeting/action-item/complete/${actionItem.uuid}`;
  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const assignActionItemOwner = (actionItem) => {
  const url = `${getAPIHost()}/api/meeting/action-item/owner/update/${actionItem.uuid}`;

  const jsonData = {
    "owner_participant_id": actionItem.owner_participant_id
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const assignActionItemTarget = (actionItem) => {
  const url = `${getAPIHost()}/api/meeting/action-item/target/update/${actionItem.uuid}`;

  const jsonData = {
    "target_participant_id": actionItem.target_participant_id
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const notifyActionItemOwners = (meetingUuid, userId, isCompleted) => {
  const url = `${getAPIHost()}/api/meeting/action-item/owner/notify/${meetingUuid}`;
  const jsonData = {
    "user_id": userId,
    "is_completed": isCompleted,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateDecisionNote = (decisionNote, description) => {
  const url = `${getAPIHost()}/api/meeting/decision-note/update/${decisionNote.uuid}`;
  const jsonData = { description: description }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteDecisionNote = (decisionNote) => {
  const url = `${getAPIHost()}/api/meeting/decision-note/delete/${decisionNote.uuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const assignDecisionNoteOwner = (decisionNote) => {
  const url = `${getAPIHost()}/api/meeting/decision-note/owner/update/${decisionNote.uuid}`;

  const jsonData = {
    "owner_participant_id": decisionNote.owner_participant_id
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const saveNewNote = (id, note) => {
  const url = `${getAPIHost()}/api/meeting/note/add/${id}`;

  const formData = new FormData();
  formData.append("text", note);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteNote = (uuid) => {
  const url = `${getAPIHost()}/api/meeting/note/delete/${uuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const addParticipant = (id, name, email) => {
  const url = `${getAPIHost()}/api/meeting/participant/add/${id}`;

  const formData = new FormData();
  formData.append("participant_name", name);
  formData.append("participant_email", email);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteParticipant = (id, participantId) => {
  const url = `${getAPIHost()}/api/meeting/participant/delete/${id}`;

  const formData = new FormData();
  formData.append("participant_id", participantId);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateParticipant = (participantId, name, email) => {
  const url = `${getAPIHost()}/api/meeting/participant/update/${participantId}`;

  const jsonData = {
    "participant_name": name,
    "participant_email": email
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeetings = (status, fromDate) => {
  const url = `${getAPIHost()}/api/meetings/`;
  
  var params = { };
  if (status) {
    params.status = status;
    params.sort_reverse = false;
  } else {
    params.sort_reverse = true;
  }
  if (fromDate) {
    params.from_date = fromDate;
  }

  params.sort_by = "start_time";

  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const searchMeetings = (searchValue, searchFilters) => {
  const url = `${getAPIHost()}/api/meetings/search/`;

  const requestBody = {
    "search_text": searchValue,
    "search_filters": searchFilters
  }

  return newAuthenticatedJSONRequest("post", url, requestBody)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getDashboardData = (startDate, endDate, userId) => {
  const url = `${getAPIHost()}/api/meetings/dashboard/`;
  const params = {};
  if (startDate && endDate) {
    params.start_date = startDate;
    params.end_date = endDate;
  }
  if (userId) {
    params.user_id = userId;
  }

  return newAuthenticatedRequest("get", url, null , params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getTeamDashboardData = (startDate, endDate) => {
  const url = `${getAPIHost()}/api/meetings/dashboard/team/`;
  const params = {
    start_date: startDate,
    end_date: endDate,
  };

  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getDashboardFAQ = (startDate, endDate, userId) => {
  const url = `${getAPIHost()}/api/meetings/dashboard/faq/`;
  const params = {};
  if (startDate && endDate) {
    params.start_date = startDate;
    params.end_date = endDate;
  }
  if (userId) {
    params.user_id = userId;
  }

  return newAuthenticatedRequest("get", url, null , params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const createOrg = (name) => {
  const url = `${getAPIHost()}/api/org/`;

  const formData = new FormData();
  formData.append("name", name);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getOrg = () => {
  const url = `${getAPIHost()}/api/org/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const addOrgMember = (email) => {
  const url = `${getAPIHost()}/api/org/member/`;

  const formData = new FormData();
  formData.append("member_email", email);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const removeOrgMember = (email) => {
  const url = `${getAPIHost()}/api/org/member/delete/${email}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateOrgRole = (email, role) => {
  const url = `${getAPIHost()}/api/org/role/update/`;

  const formData = new FormData();
  formData.append("member_email", email);
  formData.append("role", role);

  return newAuthenticatedJSONRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getOrgMeetings = (status, fromDate) => {
  var params = { };
  if (status) {
    params.status = status;
    params.sort_reverse = false;
  } else {
    params.sort_reverse = true;
  }
  if (fromDate) {
    params.from_date = fromDate;
  }

  params.sort_by = "start_time";
  
  const url = `${getAPIHost()}/api/org/meetings/`;

  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const inviteNewUserToOrg = (email) => {
  const url = `${getAPIHost()}/api/org/invite-new-user/`;
  
  const jsonData = {
    "email": email
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getInvitedUsers = () => {
  const url = `${getAPIHost()}/api/org/invite-new-user/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteInvitedUser = (email) => {
  const url = `${getAPIHost()}/api/org/invite-new-user/delete/${email}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getOrgSettings = () => {
  const url = `${getAPIHost()}/api/org/settings/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateOrgSettings = (settings) => {
  const url = `${getAPIHost()}/api/org/settings/`;
  const jsonData = {};

  if ("isAutomaticFeedbackEnabled" in settings) {
    jsonData.is_automatic_feedback_enabled = settings.isAutomaticFeedbackEnabled;
  }

  if ("automaticSharingLevel" in settings) {
    jsonData.automatic_sharing_level = settings.automaticSharingLevel;
  }

  if ("isAutomaticSharingEnabled" in settings) {
    jsonData.is_automatic_sharing_enabled = settings.isAutomaticSharingEnabled;
  }

  if ("isAutomaticInternalSharingEnabled" in settings) {
    jsonData.is_automatic_internal_sharing_enabled = settings.isAutomaticInternalSharingEnabled;
  }

  if ("isAutomaticSharePasswordEnabled" in settings) {
    jsonData.is_automatic_share_password_enabled = settings.isAutomaticSharePasswordEnabled;
  }

  if ("isAutomaticGCalShareableLinkEnabled" in settings) {
    jsonData.is_automatic_gcal_shareable_link_enabled = settings.isAutomaticGCalShareableLinkEnabled;
  }

  if ("isAutomaticCallInEnabled" in settings) {
    jsonData.is_automatic_call_in_enabled = settings.isAutomaticCallInEnabled;
  }

  if ("isBotChimeEnabled" in settings) {
    jsonData.is_bot_chime_enabled = settings.isBotChimeEnabled;
  }

  if ("isRecordingEnabled" in settings) {
    jsonData.is_recording_enabled = settings.isRecordingEnabled;
  }

  if ("processMeetingParticipantLevel" in settings) {
    jsonData.process_meeting_participant_level = settings.processMeetingParticipantLevel;
  }

  if ("joinNonHostMeetings" in settings) {
    jsonData.join_non_host_meetings = settings.joinNonHostMeetings;
  }

  if ("isMeetingAssistantNotificationEnabled" in settings) {
    jsonData.is_meeting_assistant_notification_enabled = settings.isMeetingAssistantNotificationEnabled;
  }

  if ("meetingAssistantNotificationValue" in settings) {
    jsonData.meeting_assistant_notification_value = settings.meetingAssistantNotificationValue;
  }

  if ("isGuestRequestOutputEnabled" in settings) {
    jsonData.is_guest_request_output_enabled = settings.isGuestRequestOutputEnabled;
  }

  if ("isAttendeeAllowedToOptOut" in settings) {
    jsonData.is_attendee_allowed_to_opt_out = settings.isAttendeeAllowedToOptOut;
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getOrgSharePrefs = () => {
  const url = `${getAPIHost()}/api/org/share/prefs/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateOrgSharePrefs = (sharePrefs) => {
  const url = `${getAPIHost()}/api/org/share/prefs/`;
  const jsonData = sharePrefs;

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getProfile = () => {
  const url = `${getAPIHost()}/api/profile/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let firstName = data.first_name;
        let lastName = data.last_name;
        let email = data.email;
        let pricingTier = data.pricing_tier;
        let numOfSpeeches = data.num_of_speeches;
        let numOfMeetings = data.num_of_meetings;
        let isEnrolled = data.is_enrolled;
        let automaticSharingLevel = data.automatic_sharing_level;
        let isAutomaticSharingEnabled = data.is_automatic_sharing_enabled;
        let isAutomaticInternalSharingEnabled =
          data.is_automatic_internal_sharing_enabled;
        let isAutomaticSharePasswordEnabled =
          data.is_automatic_share_password_enabled;
        let isAutomaticFeedbackEnabled = data.is_automatic_feedback_enabled;
        let isAutomaticGCalShareableLinkEnabled = data.is_automatic_gcal_shareable_link_enabled;
        let isAutomaticCallInEnabled = data.is_automatic_call_in_enabled;
        let isBotChimeEnabled = data.is_bot_chime_enabled;
        let isRecordingEnabled = data.is_recording_enabled;
        let stripeSubStatus = data.stripe_sub_status;
        let stripeSubQuantity = data.stripe_sub_quantity;
        let orgMemberRole = data.org_member_role;
        let orgMembersCount = data.org_members_count;
        let isZoomConnected = data.is_zoom_connected;
        let isGcalConnected = data.is_gcal_connected;
        let isMicrosoftConnected = data.is_microsoft_connected;
        let isHubspotConnected = data.is_hubspot_connected;
        let userShareGranularity = data.user_share_granularity;
        let languageCode = data.language_code;
        let processMeetingParticipantLevel = data.process_meeting_participant_level;
        let joinNonHostMeetings = data.join_non_host_meetings;
        let isMeetingAssistantNotificationEnabled = data.is_meeting_assistant_notification_enabled;
        let meetingAssistantNotificationValue = data.meeting_assistant_notification_value;
        let isGuestRequestOutputEnabled = data.is_guest_request_output_enabled;
        let isAttendeeAllowedToOptOut = data.is_attendee_allowed_to_opt_out;
        let dateJoined = data.date_joined;
        let meetingDurationLeft = data.meeting_duration_left;

        let fullName = firstName + " " + lastName

        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);
        localStorage.setItem("fullName", fullName);
        localStorage.setItem("email", email);
        localStorage.setItem("pricingTier", pricingTier);
        localStorage.setItem("numOfSpeeches", numOfSpeeches);
        localStorage.setItem("numOfMeetings", numOfMeetings);
        localStorage.setItem("isEnrolled", isEnrolled);
        localStorage.setItem("isZoomConnected", isZoomConnected);
        localStorage.setItem("isGoogleCalendarConnected", isGcalConnected);
        localStorage.setItem("isMicrosoftConnected", isMicrosoftConnected);
        localStorage.setItem("isHubspotConnected", isHubspotConnected);
        localStorage.setItem("automaticSharingLevel", JSON.stringify(automaticSharingLevel));
        localStorage.setItem(
          "isAutomaticSharingEnabled",
          JSON.stringify(isAutomaticSharingEnabled)
        );
        localStorage.setItem(
          "isAutomaticInternalSharingEnabled",
          JSON.stringify(isAutomaticInternalSharingEnabled)
        );
        localStorage.setItem(
          "isAutomaticSharePasswordEnabled",
          JSON.stringify(isAutomaticSharePasswordEnabled)
        );
        localStorage.setItem(
          "isAutomaticFeedbackEnabled",
          JSON.stringify(isAutomaticFeedbackEnabled)
        );
        localStorage.setItem(
          "isAutomaticGCalShareableLinkEnabled",
          JSON.stringify(isAutomaticGCalShareableLinkEnabled)
        );
        localStorage.setItem(
          "isAutomaticCallInEnabled",
          JSON.stringify(isAutomaticCallInEnabled)
        );
        localStorage.setItem(
          "isBotChimeEnabled",
          JSON.stringify(isBotChimeEnabled)
        );
        localStorage.setItem(
          "isRecordingEnabled",
          JSON.stringify(isRecordingEnabled)
        );
        localStorage.setItem("processMeetingParticipantLevel", JSON.stringify(processMeetingParticipantLevel));
        localStorage.setItem(
          "userShareGranularity",
          JSON.stringify(userShareGranularity)
        );
        localStorage.setItem("stripeSubStatus", stripeSubStatus);
        localStorage.setItem("stripeSubQuantity", stripeSubQuantity);
        localStorage.setItem("languageCode", languageCode);
        localStorage.setItem("joinNonHostMeetings", JSON.stringify(joinNonHostMeetings));
        localStorage.setItem("isMeetingAssistantNotificationEnabled", JSON.stringify(isMeetingAssistantNotificationEnabled));
        localStorage.setItem("meetingAssistantNotificationValue", JSON.stringify(meetingAssistantNotificationValue));
        localStorage.setItem("isGuestRequestOutputEnabled", isGuestRequestOutputEnabled);
        localStorage.setItem("isAttendeeAllowedToOptOut", isAttendeeAllowedToOptOut);
        localStorage.setItem("dateJoined", dateJoined);
        localStorage.setItem("meetingDurationLeft", meetingDurationLeft);

        if (orgMemberRole) {
          localStorage.setItem("orgMemberRole", orgMemberRole);
          if (orgMembersCount) {
            localStorage.setItem("orgMembersCount", orgMembersCount);
          }
        }

        if (window.$crisp) {
          window.$crisp.push(["set", "user:email", email]);
          window.$crisp.push(["set", "user:nickname", [fullName]]);
        }
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateProfile = (newProfileInfo) => {
  const url = `${getAPIHost()}/api/profile/update/`;

  const formData = new FormData();
  if (newProfileInfo.firstName) {
    formData.append("first_name", newProfileInfo.firstName);
  }
  if (newProfileInfo.lastName) {
    formData.append("last_name", newProfileInfo.lastName);
  }

  return newAuthenticatedRequest("post", url, formData)
    .then((response) => {
      const newFirstName = response.data.first_name;
      const newLastName = response.data.last_name;

      localStorage.setItem("fullName", newFirstName + " " + newLastName);
      localStorage.setItem("firstName", newFirstName);
      localStorage.setItem("lastName", newLastName);

      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const postNotificationSettings = (settings) => {
  const url = `${getAPIHost()}/api/profile/`;

  const formData = new FormData();

  if ("isAutomaticFeedbackEnabled" in settings) {
    formData.append(
      "is_automatic_feedback_enabled",
      settings.isAutomaticFeedbackEnabled
    );
  }

  if ("automaticSharingLevel" in settings) {
    formData.append(
      "automatic_sharing_level",
      settings.automaticSharingLevel
    );
  }

  if ("isAutomaticSharingEnabled" in settings) {
    formData.append(
      "is_automatic_sharing_enabled",
      settings.isAutomaticSharingEnabled
    );
  }

  if ("isAutomaticInternalSharingEnabled" in settings) {
    formData.append(
      "is_automatic_internal_sharing_enabled",
      settings.isAutomaticInternalSharingEnabled
    );
  }

  if ("isAutomaticSharePasswordEnabled" in settings) {
    formData.append(
      "is_automatic_share_password_enabled",
      settings.isAutomaticSharePasswordEnabled
    );
  }

  if ("isAutomaticGCalShareableLinkEnabled" in settings) {
    formData.append(
      "is_automatic_gcal_shareable_link_enabled",
      settings.isAutomaticGCalShareableLinkEnabled
    );
  }

  if ("isAutomaticCallInEnabled" in settings) {
    formData.append(
      "is_automatic_call_in_enabled",
      settings.isAutomaticCallInEnabled
    );
  }

  if ("isBotChimeEnabled" in settings) {
    formData.append(
      "is_bot_chime_enabled",
      settings.isBotChimeEnabled
    );
  }

  if ("isRecordingEnabled" in settings) {
    formData.append(
      "is_recording_enabled",
      settings.isRecordingEnabled
    );
  }

  if ("processMeetingParticipantLevel" in settings) {
    formData.append(
      "process_meeting_participant_level", 
      settings.processMeetingParticipantLevel
    );
  }

  if ("joinNonHostMeetings" in settings) {
    formData.append(
      "join_non_host_meetings",
      settings.joinNonHostMeetings
    );
  }

  if ("isMeetingAssistantNotificationEnabled" in settings) {
    formData.append("is_meeting_assistant_notification_enabled", settings.isMeetingAssistantNotificationEnabled);
  }

  if ("meetingAssistantNotificationValue" in settings) {
    formData.append("meeting_assistant_notification_value", settings.meetingAssistantNotificationValue);
  }

  if ("isGuestRequestOutputEnabled" in settings) {
    formData.append("is_guest_request_output_enabled", settings.isGuestRequestOutputEnabled);
  }

  if ("isAttendeeAllowedToOptOut" in settings) {
    formData.append("is_attendee_allowed_to_opt_out", settings.isAttendeeAllowedToOptOut);
  }

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let automaticSharingLevel = data.automatic_sharing_level;
        let isAutomaticSharingEnabled = data.is_automatic_sharing_enabled;
        let isAutomaticInternalSharingEnabled =
          data.is_automatic_internal_sharing_enabled;
        let isAutomaticSharePasswordEnabled =
          data.is_automatic_share_password_enabled;
        let isAutomaticFeedbackEnabled = data.is_automatic_feedback_enabled;
        let isAutomaticGCalShareableLinkEnabled = data.is_automatic_gcal_shareable_link_enabled;
        let isAutomaticCallInEnabled = data.is_automatic_call_in_enabled;
        let isBotChimeEnabled = data.is_bot_chime_enabled;
        let isRecordingEnabled = data.is_recording_enabled;
        let processMeetingParticipantLevel = data.process_meeting_participant_level;
        let joinNonHostMeetings = data.join_non_host_meetings;
        let isMeetingAssistantNotificationEnabled = data.is_meeting_assistant_notification_enabled;
        let meetingAssistantNotificationValue = data.meeting_assistant_notification_value;
        let isGuestRequestOutputEnabled = data.is_guest_request_output_enabled;
        let isAttendeeAllowedToOptOut = data.is_attendee_allowed_to_opt_out;

        localStorage.setItem(
          "automaticSharingLevel",
          JSON.stringify(automaticSharingLevel)
        );
        localStorage.setItem(
          "isAutomaticSharingEnabled",
          JSON.stringify(isAutomaticSharingEnabled)
        );
        localStorage.setItem(
          "isAutomaticInternalSharingEnabled",
          JSON.stringify(isAutomaticInternalSharingEnabled)
        );
        localStorage.setItem(
          "isAutomaticSharePasswordEnabled",
          JSON.stringify(isAutomaticSharePasswordEnabled)
        );
        localStorage.setItem(
          "isAutomaticFeedbackEnabled",
          JSON.stringify(isAutomaticFeedbackEnabled)
        );
        localStorage.setItem(
          "isAutomaticGCalShareableLinkEnabled",
          JSON.stringify(isAutomaticGCalShareableLinkEnabled)
        );
        localStorage.setItem(
          "isAutomaticCallInEnabled",
          JSON.stringify(isAutomaticCallInEnabled)
        );
        localStorage.setItem(
          "isBotChimeEnabled",
          JSON.stringify(isBotChimeEnabled)
        );
        localStorage.setItem(
          "isRecordingEnabled",
          JSON.stringify(isRecordingEnabled)
        );
        localStorage.setItem(
          "processMeetingParticipantLevel",
          JSON.stringify(processMeetingParticipantLevel)
        );
        localStorage.setItem(
          "joinNonHostMeetings",
          JSON.stringify(joinNonHostMeetings)
        );
        localStorage.setItem(
          "isMeetingAssistantNotificationEnabled", 
          JSON.stringify(isMeetingAssistantNotificationEnabled)
        );
        localStorage.setItem(
          "meetingAssistantNotificationValue", 
          JSON.stringify(meetingAssistantNotificationValue)
        );
        localStorage.setItem(
          "isGuestRequestOutputEnabled",
          JSON.stringify(isGuestRequestOutputEnabled)
        );
        localStorage.setItem(
          "isAttendeeAllowedToOptOut",
          JSON.stringify(isAttendeeAllowedToOptOut)
        );
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const postLanguageCode = (languageCode) => {
  const url = `${getAPIHost()}/api/profile/`;

  const formData = new FormData();
  formData.append(
    "language_code",
    languageCode
  );

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let languageCode = data.language_code;
        localStorage.setItem("languageCode", languageCode);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateUserShareGranularity = (userShareGranularity) => {
  const url = `${getAPIHost()}/api/profile/share/granularity/`;
  return newAuthenticatedJSONRequest("post", url, userShareGranularity)
    .then(function(response) {
      const data = response.data;
      if (response.status === 200 && data != null) {
        localStorage.setItem(
          "userShareGranularity",
          JSON.stringify(data)
        );
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getSubscriptionDetails = () => {
  const url = `${getAPIHost()}/api/stripe/subscription/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateIndicatorProTipFeedback = (feedback, subject) => {
  const url = `${getAPIHost()}/api/feedback/`;

  const formData = new FormData();
  formData.append("feedback", feedback);
  formData.append("subject", subject);
  
  return newAuthenticatedRequest("post", url, formData)
  .then(function(response) {
    if (response.status === 200) {
      localStorage.setItem("hasClickedIndicatorProTip", true);
    }
    return response;
  })
  .catch(function(error) {
    return error.response;
  });
}

export const createSubscription = (
  billingDetails,
  paymentMethodId,
  priceId,
  numOfUsers,
  discountCode,
) => {
  const url = `${getAPIHost()}/api/stripe/subscription/create/`;

  const formData = new FormData();
  formData.append("billing_name", billingDetails.name);
  formData.append("billing_email", billingDetails.email);
  formData.append("payment_method_id", paymentMethodId);
  formData.append("price_id", priceId);
  formData.append("num_of_users", numOfUsers);
  formData.append("discount_code", discountCode);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let pricingTier = data.pricing_tier;
        let stripeSubStatus = data.stripe_sub_status;
        localStorage.setItem("pricingTier", pricingTier);
        localStorage.setItem("stripeSubStatus", stripeSubStatus);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const changePaymentMethod = (paymentMethodId) => {
  const url = `${getAPIHost()}/api/stripe/subscription/change/payment/`;

  const formData = new FormData();
  formData.append("payment_method_id", paymentMethodId);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let pricingTier = data.pricing_tier;
        let stripeSubStatus = data.stripe_sub_status;
        localStorage.setItem("pricingTier", pricingTier);
        localStorage.setItem("stripeSubStatus", stripeSubStatus);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateSubscription = (type, billedAnnually, seats) => {
  const url = `${getAPIHost()}/api/stripe/subscription/change/`;
  const jsonData = {
    "type": type,
    "billing_cycle": billedAnnually ? "year" : "month",
    "num_of_users": seats
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const applyDiscount = (discountCode) => {
  const url = `${getAPIHost()}/api/stripe/subscription/change/discount/`;
  const jsonData = { "discount_code": discountCode };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const checkDiscount = (discountCode) => {
  const url = `${getAPIHost()}/api/stripe/discount/validate/`;
  const jsonData = { "discount_code": discountCode };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const cancelSubscription = () => {
  const url = `${getAPIHost()}/api/stripe/subscription/cancel/`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let pricingTier = data.pricing_tier;
        let stripeSubStatus = data.stripe_sub_status;
        localStorage.setItem("pricingTier", pricingTier);
        localStorage.setItem("stripeSubStatus", stripeSubStatus);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const changePassword = (newPassword1, newPassword2) => {
  const url = `${getAPIHost()}/api/password/change/`;
  const formData = new FormData();
  formData.append("new_password1", newPassword1);
  formData.append("new_password2", newPassword2);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const isUserLoggedIn = () => {
  var isAuth = localStorage.getItem("token");
  if (isAuth === null) {
    return false;
  } else {
    return true;
  }
};

export const isSoneroUser = () => {
  if (isUserLoggedIn()) {
    let userEmail = localStorage.getItem("email");
    if (typeof userEmail === 'string' || userEmail instanceof String) {
      return userEmail.endsWith("@sonero.ai");
    }
  }
  return false;
};

export const verifyToken = () => {
  var url = `${getAPIHost()}/api/token/verify/`;
  var formData = new FormData();
  formData.append("token", localStorage.getItem("token"));
  var headers = {};

  return axios({method: "post", url: url, data: formData, headers: headers})
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const login = (email, password) => {
  const url = `${getAPIHost()}/api/login/`;
  const formData = new FormData();
  formData.append("username", email);
  formData.append("password", password);

  if (isUserLoggedIn()) {
    clearLocalStorage();
  }

  return axios({
    method: "post",
    url: url,
    data: formData,
  })
    .then(function(response) {
      const token = response.data.access;
      const refreshToken = response.data.refresh;
      if (response.status === 200 && token != null) {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const signup = (email, password, firstName, lastName, signUpSource, participantId, meetingUuid) => {
  const url = `${getAPIHost()}/api/register/`;
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password1", password);
  formData.append("password2", password);
  formData.append("first_name", firstName);
  formData.append("last_name", lastName);
  formData.append("sign_up_source", signUpSource);
  formData.append("sign_up_participant_id", participantId ?? -1);
  if (meetingUuid) {
    formData.append("sign_up_meeting_uuid", meetingUuid);
  }

  return axios({
    method: "post",
    url: url,
    data: formData,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const logout = () => {
  clearLocalStorage();
  if (window.$crisp) {
    window.$crisp.push(["do", "session:reset"])
  }
};

export const resendConfirmEmail = (email) => {
  const url = `${getAPIHost()}/api/resend-confirm-email/`;
  const formData = new FormData();
  formData.append("email", email);

  return axios({
    method: "post",
    url: url,
    data: formData,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const resetPassword = (email) => {
  const url = `${getAPIHost()}/api/password/reset/`;
  const formData = new FormData();
  formData.append("email", email);

  return axios({
    method: "post",
    url: url,
    data: formData,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

const newAuthenticatedRequest = (method, url, data, params) => {
  var token = localStorage.getItem("token");

  return newRequest(token, method, url, data, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      if (error.response.status === 401) {
        return refreshToken().then(function(response) {
          if (response.status === 200) {
            token = localStorage.getItem("token");
            return newRequest(token, method, url, data, params);
          } else {
            return response;
          }
        });
      } else {
        return error.response;
      }
    });
};

var newRequest = (token, method, url, data, params) => {
  return axios({
    method: method,
    url: url,
    data: data,
    params: params,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const newAuthenticatedJSONRequest = (method, url, data) => {
  var token = localStorage.getItem("token");

  return newJSONRequest(token, method, url, data)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      if (error.response.status == 401) {
        return refreshToken().then(function(response) {
          if (response.status == 200) {
            token = localStorage.getItem("token");
            return newRequest(token, method, url, data);
          } else {
            return response;
          }
        });
      } else {
        return error.response;
      }
    });
};

var newJSONRequest = (token, method, url, data) => {
  return axios({
    method: method,
    url: url,
    data: data,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const newAuthenicatedRequestWithProgress = (method, url, data, onUploadProgress) => {
  var token = localStorage.getItem("token");

  return newRequestWithProgress(token, method, url, data, onUploadProgress)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      if (error.response.status == 401) {
        return refreshToken().then(function(response) {
          if (response.status == 200) {
            token = localStorage.getItem("token");
            return newRequestWithProgress(token, method, url, data, onUploadProgress);
          } else {
            return response;
          }
        });
      } else {
        return error.response;
      }
    });
};

var newRequestWithProgress = (token, method, url, data, onUploadProgress) => {
  return axios({
    method: method,
    url: url,
    data: data,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
  });
};

const refreshToken = () => {
  const url = `${getAPIHost()}/api/token/refresh/`;
  const formData = new FormData();
  const refreshToken = localStorage.getItem("refreshToken");
  formData.append("refresh", refreshToken);

  return axios({
    method: "post",
    url: url,
    data: formData,
  })
    .then(function(response) {
      var token = response.data.access;
      localStorage.setItem("token", token);
      return response;
    })
    .catch(function(error) {
      clearLocalStorage();
      return error.response;
    });
};

const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("fullName");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("email");
  localStorage.removeItem("pricingTier");
  localStorage.removeItem("numOfSpeeches");
  localStorage.removeItem("numOfMeetings");
  localStorage.removeItem("isEnrolled");
  localStorage.removeItem("searchValue");
  localStorage.removeItem("searchFilters");
  localStorage.removeItem("searchResults");
  localStorage.removeItem("automaticSharingLevel");
  localStorage.removeItem("isAutomaticSharingEnabled");
  localStorage.removeItem("isAutomaticInternalSharingEnabled");
  localStorage.removeItem("isAutomaticSharePasswordEnabled");
  localStorage.removeItem("isAutomaticFeedbackEnabled");
  localStorage.removeItem("isAutomaticGCalShareableLinkEnabled");
  localStorage.removeItem("isAutomaticCallInEnabled");
  localStorage.removeItem("stripeSubStatus");
  localStorage.removeItem("stripeSubQuantity");
  localStorage.removeItem("orgMemberRole");
  localStorage.removeItem("orgMembersCount");
  localStorage.removeItem("isZoomConnected");
  localStorage.removeItem("isGoogleCalendarConnected");
  localStorage.removeItem("isMicrosoftConnected");
  localStorage.removeItem("isHubspotConnected");
  localStorage.removeItem("redirectedToOnboarding");
  localStorage.removeItem("userShareGranularity");
  localStorage.removeItem("languageCode");
  localStorage.removeItem("processMeetingParticipantLevel");
  localStorage.removeItem("isMeetingAssistantNotificationEnabled");
  localStorage.removeItem("meetingAssistantNotificationValue");
  localStorage.removeItem("isGuestRequestOutputEnabled");
  localStorage.removeItem("isAttendeeAllowedToOptOut");
  localStorage.removeItem("dateJoined");
  localStorage.removeItem("meetingDurationLeft");
};

export const getZoomClientId = () => {
  const url = `${getAPIHost()}/api/zoom/client-id/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const connectToZoom = (authCode) => {
  const url = `${getAPIHost()}/api/zoom/connect/`;
  const formData = new FormData();
  formData.append("code", authCode);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const disconnectFromZoom = () => {
  const url = `${getAPIHost()}/api/zoom/disconnect/`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const connectToGoogleCalendar = (state) => {
  const url = `${getAPIHost()}/api/gcal/authorize/`;

  const jsonData = {
    "state": state,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const disconnectFromGoogleCalendar = () => {
  const url = `${getAPIHost()}/api/gcal/disconnect`;
  
  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const addLinkToGoogleCalendar = (meetingId) => {
  const url = `${getAPIHost()}/api/gcal/update/${meetingId}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const connectToMicrosoft = (state) => {
  const url = `${getAPIHost()}/api/microsoft/authorize/`;

  const jsonData = {
    "state": state,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const disconnectFromMicrosoft = () => {
  const url = `${getAPIHost()}/api/microsoft/disconnect/`;
  
  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const connectToHubspot = (state) => {
  const url = `${getAPIHost()}/api/hubspot/authorize/`;
  
  const jsonData = {
    "state": state
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const disconnectFromHubspot = () => {
  const url = `${getAPIHost()}/api/hubspot/disconnect/`;
  
  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getIntegrationsStatus = () => {
  const url = `${getAPIHost()}/api/integrations/status/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      var data = response.data;
      if (response.status === 200 && data != null) {
        let isZoomConnected = data.is_zoom_connected;
        var isGoogleCalendarConnected = data.is_google_calendar_connected;
        let isMicrosoftConnected = data.is_microsoft_connected;
        let isHubspotConnected = data.is_hubspot_connected;
        localStorage.setItem("isZoomConnected", isZoomConnected);
        localStorage.setItem("isGoogleCalendarConnected", isGoogleCalendarConnected);
        localStorage.setItem("isMicrosoftConnected", isMicrosoftConnected);
        localStorage.setItem("isHubspotConnected", isHubspotConnected);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createZapierAPIKey = () => {
  const url = `${getAPIHost()}/api/zapier/api-key/new/`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const revokeZapierAPIKey = () => {
  const url = `${getAPIHost()}/api/zapier/api-key/revoke/`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const mapParticipant = (id, tag, participantId) => {
  const url = `${getAPIHost()}/api/meeting/participant/speakermap/${id}`;

  const formData = new FormData();
  formData.append("speaker_tag", tag);
  formData.append("participant_id", participantId);

  return newAuthenticatedRequest("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateMeetingTasks = (id, tasks) => {
  const url = `${getAPIHost()}/api/meeting/tasks/update/${id}`;

  const formData = new FormData();
  formData.append("tasks", JSON.stringify(tasks));

  return newAuthenticatedRequest("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateMeetingSummary = (id, summary, isAgenda = false) => {
  const url = `${getAPIHost()}/api/meeting/summary/update/${id}`;

  const formData = new FormData();
  formData.append("summary", JSON.stringify(summary));
  formData.append("is_agenda", isAgenda);

  return newAuthenticatedRequest("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateMeetingTranscript = (id, transcript, timestamps) => {
  const url = `${getAPIHost()}/api/meeting/transcript/update/${id}`;

  const jsonData = {
    "transcript": transcript,
    "timestamps": timestamps
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const joinAdHocMeeting = (link, title, numOfParticipants) => {
  const url = `${getAPIHost()}/api/meeting/adhoc/`;
  const formData = new FormData();
  formData.append("meeting_url", link);
  formData.append("meeting_title", title);
  formData.append("num_of_participants", numOfParticipants);

  return newAuthenticatedRequest("post", url, formData)
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
};

export const updateMeetingQuestionandAnswer = (id, newQuestionsandAnswers) => {
  const url = `${getAPIHost()}/api/meeting/questionandanswer/update/${id}`;
  const jsonData = {
    "answer": newQuestionsandAnswers,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });

} 


export const createMeetingFolder = (name) => {
  const url = `${getAPIHost()}/api/meeting/folder/create`;

  const formData = new FormData();
  formData.append("name", name);

  return newAuthenticatedRequest("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteMeetingFolder = (folderUuid) => {
  const url = `${getAPIHost()}/api/meeting/folder/delete/${folderUuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const moveMeetingToFolder = (id, folderUuid) => {
  const url = `${getAPIHost()}/api/meeting/folder/move/${id}`;

  const formData = new FormData();
  formData.append("folder_uuid", folderUuid);

  return newAuthenticatedRequest("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateMeetingFolderShareStatus = (folderUuid, isEnabled, isPasswordRequired) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/status/${folderUuid}`;

  const formData = new FormData();
  formData.append("is_sharing_enabled", isEnabled);
  formData.append("is_password_required", isPasswordRequired);

  return newAuthenticatedRequest("post", url, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });  
};

export const getMeetingFolderInternal = (uuid, params, headers) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/view/${uuid}`;

  return axios({
    method: "get",
    url: url,
    params: params,
    headers: headers,
  })
  .then(function(response) {
    return response;
  })
  .catch(function(error) {
    return error.response;
  });
}

export const getMeetingFolder = (folderUuid, password) => {
  var params = null;
  if (password) {
    params = { password: password };
  }

  var headers = {}

  if (isUserLoggedIn()) {
    return verifyToken().then(function(response) {
      if (response.status === 200) {
        let token = localStorage.getItem("token")
        headers = {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        }
      }
      return getMeetingFolderInternal(folderUuid, params, headers);
    }).catch(function(error) {
      return error.response;
    });
  } else {
    return getMeetingFolderInternal(folderUuid, params, headers);
  }
};

export const getMeetingFolderShareStatus = (folderUuid) => {
  const url = `${getAPIHost()}/api/meeting/folder/${folderUuid}`;

  return newAuthenticatedRequest("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    }); 
};

export const getShareMeetingFolderEmailPreview = (folderUuid) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/emails/${folderUuid}`;

  return newAuthenticatedRequest("get", url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    }); 
};

export const shareMeetingFolderViaEmail = (folderUuid, emailRecipients) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/emails/${folderUuid}`;

  const jsonData = {
    "emails_list": emailRecipients,
  }

  return newAuthenticatedRequest("post", url, jsonData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    }); 

}

export const updateMeetingFolderShareGranularity = (folderUuid, shareGranularity) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/granularity/${folderUuid}`;
  return newAuthenticatedJSONRequest("post", url, shareGranularity)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const logViewMeetingDashboardMetric = (meetingUuid) => {
  const url = `${getAPIHost()}/api/metric/meeting/view/${meetingUuid}`;
  return newAuthenticatedRequest("post", url)
    .then(function (response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

// OLD SONERO SPEECH COACH ENDPOINTS

export const newSpeech = (video, title, type) => {
  const url = `${getAPIHost()}/api/speech/`;
  const formData = new FormData();
  formData.append("title", title);
  formData.append("type", type);
  formData.append("video", video);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getSpeech = (id) => {
  const url = `${getAPIHost()}/api/speech/${id}`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getSpeeches = () => {
  const url = `${getAPIHost()}/api/speeches/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const uploadRecording = (video, title) => {
  const url = `${getAPIHost()}/api/speech/`;
  const formData = new FormData();
  formData.append("title", title);
  formData.append("type", "practice");
  formData.append("video", video);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const enrollUser = (video) => {
  const url = `${getAPIHost()}/api/enroll-speaker/`;
  const formData = new FormData();
  formData.append("video", video);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      if (response.status === 200) {
        localStorage.setItem("isEnrolled", true);
      }
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getFeedbackMessages = () => {
  const url = `${getAPIHost()}/api/feedback-messages/`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getFeedbackMessage = (id) => {
  const url = `${getAPIHost()}/api/feedback-message/${id}`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const requestFeedbackMessage = (speechId, email) => {
  const url = `${getAPIHost()}/api/feedback-message/`;
  const formData = new FormData();
  formData.append("speech_id", speechId);
  formData.append("email", email);
  formData.append("is_sender", true);

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const respondFeedbackMessage = (results, messageId) => {
  const url = `${getAPIHost()}/api/feedback-message/`;
  const formData = new FormData();
  formData.append("fillers", results.fillers);
  formData.append("words_per_min", results.pacing);
  formData.append("delivery_tone", results.delivery);
  formData.append("intensity", results.energy);
  formData.append("emotions", results.expressions);
  formData.append("overall", results.overall);
  formData.append("message_id", messageId);

  if (results.comments !== null) {
    formData.append("comments", results.comments);
  }

  return newAuthenticatedRequest("post", url, formData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createMeetingClip = (meetingUuid, startTime, endTime, name) => {
  const url = `${getAPIHost()}/api/meeting/clip/add/${meetingUuid}`;
  const jsonData = {
    "start_time": startTime,
    "end_time": endTime,
    "name": name,
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateMeetingClip = (clipUuid, name) => {
  const url = `${getAPIHost()}/api/meeting/clip/update/${clipUuid}`;
  const jsonData = { name: name }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteMeetingClip = (clipUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/delete/${clipUuid}`;
  
  return newAuthenticatedJSONRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteAllMeetingClips = (meetingUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/delete/all/${meetingUuid}`;

  return newAuthenticatedJSONRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateMeetingClipShareStatus = (clipUuid, shareStatus) => {
  const url = `${getAPIHost()}/api/meeting/clip/share/status/${clipUuid}`;
  const jsonData = {
    "share_status": shareStatus,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const shareMeetingClipWithParticipants = (clipUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/share/participants/${clipUuid}`;
  
  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeetingClipShareEmail = (clipUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/share/email/${clipUuid}`;

  return newAuthenticatedRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const sendMeetingClipShareEmail = (clipUuid, emails) => {
  const url = `${getAPIHost()}/api/meeting/clip/share/email/${clipUuid}`;
  const jsonData = {
    "emails_list": emails
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getSharedMeetingClip = (clipUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/share/view/${clipUuid}`;

  return axios({ method: "get", url: url })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const addMeetingClipComment = (clipUuid, context) => {
  const url = `${getAPIHost()}/api/meeting/clip/comment/add/${clipUuid}`;
  const jsonData = {
    "context": context,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const updateMeetingClipComment = (commentUuid, context) => {
  const url = `${getAPIHost()}/api/meeting/clip/comment/update/${commentUuid}`;
  const jsonData = {
    "context": context
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const deleteMeetingClipComment = (commentUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/comment/delete/${commentUuid}`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const readMeetingClipComment = (commentUuid) => {
  const url = `${getAPIHost()}/api/meeting/clip/comment/update/${commentUuid}/read`;

  return newAuthenticatedRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const requestShare = (meetingUuid, requesterEmail, requesterName, message) => {
  const url = `${getAPIHost()}/api/request-share/${meetingUuid}`;
  let params = { to: requesterEmail, to_name: requesterName };
  if (message) {
    params["message"] = message;
  }

  return axios({
    method: "get",
    url: url,
    params: params,
    headers: {},
  })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const confirmShared = (meetingUuid, requesterEmail, requesterName) => {
  const url = `${getAPIHost()}/api/confirm-shared/${meetingUuid}`
  return newAuthenticatedJSONRequest("post", url, { to: requesterEmail, to_name: requesterName })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getAccounts = () => {
  const url = `${getAPIHost()}/api/accounts/list/`;
  
  var params = { };
  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateAccount = (accountUuid, name, projectLead, status, contacts) => {
  const url = `${getAPIHost()}/api/accounts/update/${accountUuid}`
  const jsonData = {
    "name": name,
    "owner": projectLead.user_id,
    "status": status,
    "contacts": contacts
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createAccount = (name, projectLead, status, contacts) => {
  const url = `${getAPIHost()}/api/accounts/add/`
  const jsonData = {
    "name": name,
    "owner": projectLead.user_id,
    "status": status,
    "contacts": contacts
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const searchAccounts = (name) => {
  const url = `${getAPIHost()}/api/accounts/search/`;
  const jsonData = { name: name };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const associateAccount = (accountUuid, meetingUuids) => {
  const url = `${getAPIHost()}/api/accounts/associate/${accountUuid}`;
  const jsonData = { meeting_uuids: meetingUuids };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createAndAssociateAccount = (accountName, meetingUuid) => {
  const url = `${getAPIHost()}/api/accounts/add-associate/${meetingUuid}`;
  const jsonData = { name: accountName };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getContacts = () => {
  const url = `${getAPIHost()}/api/contacts/list/`;
  
  var params = { };
  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const createContact = (name, email) => {
  const url = `${getAPIHost()}/api/contacts/add/`
  const jsonData = {
    "name": name,
    "email": email,
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const deleteContact = (contactUuid) => {
  const url = `${getAPIHost()}/api/contacts/delete/${contactUuid}`
  const jsonData = {};

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const addTakeaway = (meeting, takeaway, type) => {
  const url = `${getAPIHost()}/api/meeting/takeaway/add/${meeting.uuid}`;
  const jsonData = {
    takeaway: takeaway,
    type: type,
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const deleteTakeaway = (meeting, takeaway) => {
  const url = `${getAPIHost()}/api/meeting/takeaway/delete/${meeting.uuid}`;
  const jsonData = { timestamp: takeaway.timestamp };
  return newAuthenticatedRequest("post", url, jsonData)
      .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getTrackers = () => {
  const url = `${getAPIHost()}/api/trackers/list/`;
  
  var params = { };
  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const createTracker = (name, status) => {
  const url = `${getAPIHost()}/api/trackers/add/`
  const jsonData = {
    "name": name,
    "status": status,
  };

  return newAuthenticatedRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const updateTracker = (name, status, trackerId) => {
  const url = `${getAPIHost()}/api/trackers/update/${trackerId}`
  const jsonData = {
    "name": name,
    "status": status,
  }

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const deleteTracker = (trackerId) => {
  const url = `${getAPIHost()}/api/trackers/delete/${trackerId}`
  const jsonData = {};

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const optOutOfMeetingByParticipant = (meetingUuid, participantId) => {
  const url = `${getAPIHost()}/api/opt-out/meeting/${meetingUuid}/${participantId}`;

  return axios({ method: "post", url: url })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    })
};

export const optOutOfMeetingByHost = (meetingUuid) => {
  const url = `${getAPIHost()}/api/opt-out/meeting/${meetingUuid}/host`;

  return newAuthenticatedJSONRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    })
};

export const optInMeetingByHost = (meetingUuid) => {
  const url = `${getAPIHost()}/api/opt-in/meeting/${meetingUuid}/host`;

  return newAuthenticatedJSONRequest("post", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    })
};

export const getMeetingShareAccess = (meetingUuid) => {
  const url = `${getAPIHost()}/api/meeting/share/access/${meetingUuid}`;

  return newAuthenticatedJSONRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateMeetingShareAccess = (meetingUuid, generalAccess, peopleWithAccess, shareGranularity, fromQuickShare) => {
  const url = `${getAPIHost()}/api/meeting/share/access/${meetingUuid}`;
  const jsonData = {
    general_access: generalAccess,
    people_with_access: peopleWithAccess,
    share_granularity: shareGranularity,
    from_quick_share: fromQuickShare,
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const sendMeetingShareAccessEmail = (meetingUuid, emailsList, customMessage) => {
  const url = `${getAPIHost()}/api/meeting/share/access/email/${meetingUuid}`;
  const jsonData = {
    emails_list: emailsList,
    custom_message: customMessage
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getFolderShareAccess = (folderUuid) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/access/${folderUuid}`;

  return newAuthenticatedJSONRequest("get", url)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const updateFolderShareAccess = (folderUuid, generalAccess, peopleWithAccess, shareGranularity, fromQuickShare) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/access/${folderUuid}`;
  const jsonData = {
    general_access: generalAccess,
    people_with_access: peopleWithAccess,
    share_granularity: shareGranularity,
    from_quick_share: fromQuickShare,
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const sendFolderShareAccessEmail = (folderUuid, emailsList, customMessage) => {
  const url = `${getAPIHost()}/api/meeting/folder/share/access/email/${folderUuid}`;
  const jsonData = {
    emails_list: emailsList,
    custom_message: customMessage
  };

  return newAuthenticatedJSONRequest("post", url, jsonData)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
}

export const unsubscribeFromEmails = (unsubUuid) => {
  const url = `${getAPIHost()}/api/unsubscribe/email/${unsubUuid}`;

  return axios({ method: "post", url: url })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const resubscribeToEmails = (unsubUuid) => {
  const url = `${getAPIHost()}/api/resubscribe/email/${unsubUuid}`;

  return axios({ method: "post", url: url })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};

export const getMeetingsSharedWithMe = (status, fromDate) => {
  const url = `${getAPIHost()}/api/meetings/shared-with-me/`;
  let params = {};
  if (status) params.status = status;
  if (fromDate) params.from_date = fromDate;

  return newAuthenticatedRequest("get", url, null, params)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error.response;
    });
};