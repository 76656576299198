import React, { Component } from "react";
import ReactGA from "react-ga";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Clear from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import EjectIcon from "@material-ui/icons/Eject";
import Remove from "@material-ui/icons/Remove";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import FolderIcon from "@material-ui/icons/Folder";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ShareIcon from "@material-ui/icons/Share";
import MoreVert from "@material-ui/icons/MoreVert";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

// Material helpers
import { withStyles, Tooltip, Typography, Switch, CircularProgress } from "@material-ui/core";

// Shared helpers
import { meetingStatuses, meetingTypes } from "helpers";
import { timeFormatter } from "helpers/time";
import { deleteMeetingFolder, moveMeetingToFolder } from "services/speech";

// Shared components
import { Portlet, PortletContent, PortletLabel } from "components";
import {
  AddMeetingFolder,
  MoveMeetingDialog,
} from "views/MyMeetings/components";

import { shareAccess, shareAccessTooltip } from "helpers/shareAccess";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import { optOutTypes } from "helpers/meetingOptOut";

// Component styles
import styles from "./styles";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

class MeetingsTable extends Component {
  state = {
    isAddToFolder: false,
    addToFolderUuid: null,
    openAddMeetingFolderDialog: false,
    isFolderShared: false,
    selectedMeetings: [],
    folderTitle: "",
  };
  tabIndex = {
    completed: 0,
    scheduled: 1,
  };

  handleSelect = (id, uuid, hasInternalAccess, isMeetingOwner) => {
    const { onSelect } = this.props;
    onSelect(id, uuid, hasInternalAccess, isMeetingOwner);
  };

  handleMeetingsUpdated = (snackbarMessage) => {
    const { onMeetingsUpdated } = this.props;
    onMeetingsUpdated(snackbarMessage);
  };

  getStatus = (meetingStatus) => {
    let status = meetingStatuses.filter(
      (element) => element.value === meetingStatus
    );
    if (status.length > 0) {
      return status[0].label;
    }
    return "";
  };

  getDate = (date) => {
    if (date != null) {
      var localDate = new Date(date);
      return localDate.toLocaleString();
    }
    return "";
  };

  getDuration = (duration) => {
    if (duration != null) {
      return timeFormatter(parseInt(duration));
    }
    return "";
  };

  getTitle = (rowData, classes) => {
    const showSharingIcon = !this.props.isShared;
    if (rowData.type == meetingTypes.folder) {
      return (
        <div className={classes.tableCellInner}>
          {showSharingIcon && (
            <Tooltip
              title={`Folder is ${
                shareAccessTooltip[rowData.general_share_access]
              }`}
            >
              <PeopleIcon className={classes.sharedIcon} />
            </Tooltip>
          )}
          <FolderIcon className={classes.folderIcon} />
          <p className={classes.folderTitle}>{rowData.title}</p>
        </div>
      );
    }
    if (rowData.parent_folder) {
      return <p className={classes.childMeetingTitle}>{rowData.title}</p>;
    }
    return (
      <div className={classes.tableCellInner}>
        {showSharingIcon && (
          <Tooltip
            title={`Meeting is ${
              shareAccessTooltip[rowData.general_share_access]
            }`}
          >
            <PeopleIcon className={classes.sharedIcon} />
          </Tooltip>
        )}
        <p>{rowData.title}</p>
      </div>
    );
  };

  getTitleRow = (rowData) => {
    const { classes, isShared } = this.props;
    const meetingUrl = `/view-meeting/${
      isShared ? `share/${rowData.uuid}` : rowData.uuid
    }`;

    if (!rowData.uuid) {
      return this.getTitle(rowData, classes);
    }

    if (
      rowData.task_status === "uninitialized" &&
      rowData.user_opted_out_of_assistant !== optOutTypes.none
    ) {
      let optOutTooltip = "Assistant will not join";
      if (rowData.user_opted_out_of_assistant === optOutTypes.byParticipant) {
        optOutTooltip += " - ATTENDEE SELECTION";
      } else if (rowData.user_opted_out_of_assistant === optOutTypes.byOwner) {
        optOutTooltip += " - HOST SELECTION";
      }
      return (
        <Tooltip title={optOutTooltip}>
          <Link
            to={meetingUrl}
            onClick={(e) => e.stopPropagation()}
            className={classes.link + " disabled"}
          >
            <Clear className={classes.willNotJoinIcon} />
            <span className={classes.disabled}>
              {this.getTitle(rowData, classes)}
            </span>
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link
        to={meetingUrl}
        onClick={(e) => e.stopPropagation()}
        className={classes.link}
      >
        {this.getTitle(rowData, classes)}
      </Link>
    );
  };

  handleRowClick = (event, rowData, tableRef) => {
    var meetings = this.props.meetings;
    var index = this.props.meetings.indexOf(rowData);
    if (meetings[index].type == meetingTypes.meeting) {
      this.handleSelect(meetings[index].id, meetings[index].uuid, true, true);
    } else if (meetings[index].type == meetingTypes.folder) {
      tableRef.current.onTreeExpandChanged(rowData.tableData.path, rowData);
    }
  };

  handleClearSelections = () => {
    this.props.meetings.forEach((meeting) => {
      if (meeting.tableData) meeting.tableData.checked = false;
    });
    this.setState({
      isAddToFolder: false,
      addToFolderUuid: null,
      selectedMeetings: [],
      folderTitle: "",
    });
  };

  handleCloseAddMeetingFolder = () => {
    this.setState({ openAddMeetingFolderDialog: false });
  };

  handleAddMeetingFolderSuccess = () => {
    this.setState({ openAddMeetingFolderDialog: false });
    this.handleMeetingsUpdated("Meeting folder added");
  };

  handleDeleteMeetingFolder = (folderUuid) => {
    deleteMeetingFolder(folderUuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          ReactGA.event({
            category: "Failure",
            action: "Delete meeting folder API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Delete meeting folder API success",
          });
          this.handleMeetingsUpdated("Meeting folder removed");
        }
      }.bind(this)
    );
  };

  handleMoveMeetings = () => {
    var meetingUpdate = this.state.selectedMeetings.map((meeting) =>
      moveMeetingToFolder(meeting.id, this.state.addToFolderUuid)
    );
    Promise.all(meetingUpdate).then(
      function(responses) {
        if (
          responses.filter((response) => {
            return response.status !== 200;
          }).length !== 0
        ) {
          ReactGA.event({
            category: "Failure",
            action: "Move meeting to folder API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Move meeting to folder API success",
          });
        }
        if (meetingUpdate.length > 1) {
          this.handleMeetingsUpdated("Meetings moved");
        } else {
          this.handleMeetingsUpdated("Meeting moved");
        }
      }.bind(this)
    );
  };

  handleCloseMoveMeetingDialog = () => {
    this.setState({ openMoveMeetingDialog: false });
    this.handleClearSelections();
  };

  handleRemoveMeetingFromFolder = (meetingId) => {
    moveMeetingToFolder(meetingId, "").then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          ReactGA.event({
            category: "Failure",
            action: "Remove meeting from folder API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Remove meeting from folder API success",
          });
          this.handleMeetingsUpdated("Meeting removed from folder");
        }
      }.bind(this)
    );
  };

  handleMoveMeetingSelect = (event, rowData) => {
    this.setState(
      {
        selectedMeetings: this.props.meetings.filter(
          (meeting) => meeting.tableData && meeting.tableData.checked === true
        ),
      },
      this.handleSelectMeetingFinish
    );
  };

  handleSelectMeetingFinish = () => {
    if (this.state.isFolderShared) {
      this.setState({ openMoveMeetingDialog: true });
    } else {
      this.handleMoveMeetings();
    }
  };

  handleScheduledMeetingBotJoiningdIcon = (rowData) => {
    const { classes } = this.props;
    if (rowData.user_opted_out_of_assistant === optOutTypes.none) {
      return <PersonAddDisabledIcon className={classes.removeAssistantIcon} />;
    } else if (rowData.user_opted_out_of_assistant === optOutTypes.byOwner) {
      return <PersonAddIcon className={classes.addAssistantIcon} />;
    } else {
      return <PersonAddIcon color="disabled" />;
    }
  };

  handleScheduledMeetingBotJoiningAction = (event, rowData) => {
    event.preventDefault();
    if (rowData.user_opted_out_of_assistant === optOutTypes.none) {
      this.props.optOutOfMeetingByHost(rowData.uuid, true);
      ReactGA.event({
        category: "My Meetings",
        action: "Opt out of meeting clicked",
      });
    } else if (rowData.user_opted_out_of_assistant === optOutTypes.byOwner) {
      this.props.optOutOfMeetingByHost(rowData.uuid, false);
      ReactGA.event({
        category: "My Meetings",
        action: "Opt in meeting clicked",
      });
    }
  };

  renderAddMeetingFolderDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <AddMeetingFolder
        open={this.state.openAddMeetingFolderDialog}
        closeHandler={this.handleCloseAddMeetingFolder}
        successHandler={this.handleAddMeetingFolderSuccess}
      />
    );
  };

  renderMoveMeetingDialog = () => {
    return (
      <MoveMeetingDialog
        open={this.state.openMoveMeetingDialog}
        closeHandler={this.handleCloseMoveMeetingDialog}
        handleMoveMeeting={this.handleMoveMeetings}
        meetings={this.state.selectedMeetings}
        folderTitle={this.state.folderTitle}
      />
    );
  };

  render() {
    const { classes, className, selectedTab, displaySoneroWillJoin, handleDisplaySwitchScheduledMeetings, isLoading } = this.props;

    const rootClassName = classNames(classes.root, className);
    const myTableRef = React.createRef();

    const actions = [
      {
        icon: () => <CreateNewFolderIcon className={classes.addMeetingIcon} />,
        tooltip: "Add New Folder",
        isFreeAction: true,
        hidden: this.state.isAddToFolder,
        onClick: (event, rowData) =>
          this.setState({
            openAddMeetingFolderDialog: true,
            folderTitle: rowData.title,
          }),
      },
      {
        icon: CancelIcon,
        tooltip: "Cancel",
        isFreeAction: true,
        onClick: () => this.handleClearSelections(),
        hidden: !this.state.isAddToFolder,
      },
      {
        icon: CancelIcon,
        tooltip: "Cancel",
        onClick: () => this.handleClearSelections(),
        hidden: !this.state.isAddToFolder,
      },
      {
        icon: CheckCircleIcon,
        tooltip: "Done",
        onClick: this.handleMoveMeetingSelect,
        hidden: !this.state.isAddToFolder,
      },
      (rowData) => ({
        icon: () => this.handleScheduledMeetingBotJoiningdIcon(rowData),
        tooltip:
          rowData.user_opted_out_of_assistant === optOutTypes.none
            ? "Remove Assistant from this meeting"
            : rowData.user_opted_out_of_assistant === optOutTypes.byOwner
            ? "Allow Assistant to join this meeting"
            : null,
        onClick: (event, rowData) =>
          this.handleScheduledMeetingBotJoiningAction(event, rowData),
        hidden:
          rowData.type !== meetingTypes.meeting ||
          rowData.task_status !== "uninitialized",
        disabled:
          rowData.user_opted_out_of_assistant === optOutTypes.byParticipant,
      }),
      (rowData) => ({
        icon: () => <ShareIcon className={classes.actionIcon} />,
        tooltip: "Share this meeting",
        onClick: (event, rowData) => {
          this.props.handleOpenShareDialog(rowData.uuid, false);
        },
        hidden: rowData.type !== meetingTypes.meeting,
      }),
      (rowData) => ({
        icon: () => <MoreVert className={classes.actionIcon} />,
        tooltip: "More options",
        onClick: (event, rowData) => {
          this.props.handleOpenMenu(
            event.currentTarget,
            rowData.id,
            rowData.title
          );
        },
        hidden:
          rowData.type === meetingTypes.folder || this.state.isAddToFolder,
      }),
      (rowData) => ({
        icon: () => <ShareIcon className={classes.actionIcon} />,
        tooltip: "Share this folder",
        onClick: (event, rowData) => {
          this.props.handleOpenFolderShareDialog(rowData.id, true);
        },
        hidden: rowData.type !== meetingTypes.folder,
      }),
      (rowData) => ({
        icon: () => (
          <AddCircleOutlineIcon className={classes.folderActionIcon} />
        ),
        tooltip: "Add Meeting To Folder",
        onClick: (event, rowData) =>
          this.setState({
            isAddToFolder: true,
            addToFolderUuid: rowData.id,
            isFolderShared: rowData.general_share_access === shareAccess.anyone,
            folderTitle: rowData.title,
          }),
        hidden: rowData.type != meetingTypes.folder,
      }),
      (rowData) => ({
        icon: () => <EjectIcon className={classes.folderActionIcon} />,
        tooltip: "Move Meeting Out of Folder",
        onClick: (event, rowData) =>
          this.handleRemoveMeetingFromFolder(rowData.id),
        hidden:
          rowData.type != meetingTypes.meeting || rowData.parent_folder == null,
      }),
      (rowData) => ({
        icon: () => <EjectIcon className={classes.folderActionIcon} />,
        tooltip: "Move All Meetings Out of Folder",
        onClick: (event, rowData) => this.handleDeleteMeetingFolder(rowData.id),
        hidden: rowData.type != meetingTypes.folder,
      }),
    ];

    let meetingsToDisplay = this.props.meetings;
    if (!this.props.displayFolders) {
      meetingsToDisplay = meetingsToDisplay.filter(
        (meeting) => meeting.type !== meetingTypes.folder
      );
    }
    if (selectedTab === this.tabIndex.scheduled && displaySoneroWillJoin) {
      meetingsToDisplay = meetingsToDisplay.filter((meeting) => 
        meeting.will_bot_join === true
      );
    }

    return (
      <Portlet className={rootClassName}>
        {(!this.state.isAddToFolder && selectedTab === this.tabIndex.scheduled) && (
          <div className={classes.scheduledMeetingsActions}> 
            <div className={classes.scheduledMeetingsDisplayToggle}>
              <Tooltip title="Display Setting">
                <Switch
                  checked={displaySoneroWillJoin}
                  onChange={(event) => handleDisplaySwitchScheduledMeetings(event.target.checked)}
                  color="primary"
                  size="small"
                />
              </Tooltip>
              <Typography variant="h6">Show only meetings Sonero will join</Typography>
            </div>
          </div>
        )}
        {isLoading ? (
          <PortletContent className={classes.progressWrapperContainer}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </PortletContent>
        ) : (
          <>
            <MaterialTable
              tableRef={myTableRef}
              icons={tableIcons}
              title={<PortletLabel title="" />}
              columns={[
                {
                  title: "Title",
                  field: "title",
                  render: (rowData) => this.getTitleRow(rowData),
                },
                {
                  title: "Start Time",
                  field: "start_time",
                  render: (rowData) => this.getDate(rowData.start_time),
                },
                {
                  title: "Duration",
                  field: "duration",
                  render: (rowData) => this.getDuration(rowData.duration),
                },
                {
                  title: "Status",
                  field: "task_status",
                  render: (rowData) => this.getStatus(rowData.task_status),
                },
              ]}
              data={meetingsToDisplay}
              parentChildData={(row, rows) =>
                rows.find((a) => a.id === row.parent_folder)
              }
              options={{
                actionsColumnIndex: -1,
                rowStyle: {
                  fontFamily: "Roboto",
                  fontSize: "14px",
                },
                pageSize: 20,
                emptyRowsWhenPaging: false,
                toolbarButtonAlignment: "left",
                selection: this.state.isAddToFolder,
                selectionProps: (rowData) => ({
                  disabled:
                    rowData.type === meetingTypes.folder ||
                    rowData.parent_folder === this.state.addToFolderUuid,
                }),
              }}
              localization={{
                header: {
                  actions: "",
                },
                body: {
                  emptyDataSourceMessage: "",
                },
                toolbar: {
                  searchPlaceholder: "Filter by Title",
                },
              }}
              style={{
                boxShadow: "none",
              }}
              onRowClick={(e, rowData) =>
                this.handleRowClick(e, rowData, myTableRef)
              }
              actions={this.props.isShared ? [] : actions}
              components={{
                Toolbar: (props) => (
                  <MTableToolbar
                    {...props}
                    classes={{ root: classes.tableToolbar }}
                  />
                ),
              }}
            />
            {this.renderAddMeetingFolderDialog()}
            {this.renderMoveMeetingDialog()}
          </>
        )}
      </Portlet>
    );
  }
}

MeetingsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  meetings: PropTypes.array.isRequired,
};

MeetingsTable.defaultProps = {
  meetings: [],
  onSelect: () => {},
  onShowDetails: () => {},
  onMeetingsUpdated: () => {},
};

export default withStyles(styles)(MeetingsTable);
