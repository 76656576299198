export default (theme) => ({
  content: {
    height: "100%",
    overflowY: "scroll",
  },
  criteriaContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.border}`,
    "&.positive": {
      backgroundColor: theme.palette.success.light,
    },
    "&.negative": {
      backgroundColor: theme.palette.danger.light,
    },
    "&.unread": {
      backgroundColor: theme.palette.highlight,
      cursor: "pointer",
    },
  },
  positiveCriteria: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.success.dark,
    fontSize: "18px",
    marginBottom: theme.spacing(0.75),
  },
  negativeCriteria: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.danger.mellow,
    fontSize: "20px",
    marginBottom: theme.spacing(0.5),
  },
  unreadCriteria: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: "18px",
    marginBottom: theme.spacing(0.75),
  },
  criteriaDetails: {
    marginLeft: theme.spacing(4),
    fontSize: "15px",
    "& > p": {
      marginBottom: theme.spacing(0.5),
    },
  },
  clickable: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  "@keyframes borderBlink": {
    "0%": {
      borderColor: "transparent",
    },
    "50%": {
      borderColor: theme.palette.success.light,
    },
    "100%": {
      borderColor: "transparent",
    },
  },
});
