import React, { Component } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CommentIcon from "@material-ui/icons/Comment";
import {
  showFillersHighlight,
  showPacingHighlight,
  showPositiveTopic,
  showNegativeTopic,
  hasUnreadClipComments,
} from "helpers/meetingHighlights";
import { wpmCutoffs } from "helpers/meetingPacing";

class Attention extends Component {
  unreadClipUuid = null;

  getUserPacing = () => {
    if (
      !this.props.data.analytics ||
      !this.props.data.analytics.words_per_min
    ) {
      return -1;
    }
    const pacingData = this.props.data.analytics.words_per_min;

    const userEmail = localStorage.getItem("email");
    const userDetails = this.props.data.participants_details.find(
      (detail) => detail.user_email === userEmail
    );
    if (!userDetails) {
      return -1;
    }
    const speakerTag = userDetails.speaker_tag;

    if (!pacingData[speakerTag]) {
      return -1;
    }
    if (pacingData[speakerTag].duration === 0) {
      return 0;
    }
    return (
      pacingData[speakerTag].word_count / (pacingData[speakerTag].duration / 60)
    );
  };

  hasUnreadComments = (clips) => {
    const [unreadMeetingClipComments, clipUuid] = hasUnreadClipComments(clips);
    this.unreadClipUuid = clipUuid;
    return unreadMeetingClipComments;
  };

  renderFillersHighlight = () => {
    const { classes, data } = this.props;
    const deviationPercent = Math.round(
      Math.abs(data.highlights.fillers_deviation) * 100
    );
    const increased = data.highlights.fillers_deviation > 0;
    return (
      <div
        className={
          classes.criteriaContainer + (increased ? " negative" : " positive")
        }
      >
        <Typography
          variant="h4"
          className={
            increased ? classes.negativeCriteria : classes.positiveCriteria
          }
        >
          {increased ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          Filler words
        </Typography>
        <Typography variant="body1" className={classes.criteriaDetails}>
          <p>
            You had {deviationPercent}% {increased ? "more" : "less"} filler
            words than usual.
          </p>
          <p>
            {increased
              ? "This may come across poorly. Try pausing instead."
              : "You sound great, keep it up!"}
          </p>
        </Typography>
      </div>
    );
  };

  renderPacingHighlight = () => {
    const wpm = Math.round(this.getUserPacing());
    if (wpm === -1) return <></>;

    const { classes, data } = this.props;
    const deviationPercent = Math.round(
      Math.abs(data.highlights.pacing_deviation) * 100
    );
    const increased = data.highlights.pacing_deviation > 0;
    const isOptimal = wpm >= wpmCutoffs.optimal && wpm <= wpmCutoffs.fast;
    return (
      <div
        className={
          classes.criteriaContainer + (isOptimal ? " positive" : " negative")
        }
      >
        <Typography
          variant="h4"
          className={
            isOptimal ? classes.positiveCriteria : classes.negativeCriteria
          }
        >
          {increased ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          Pacing
        </Typography>
        <Typography variant="body1" className={classes.criteriaDetails}>
          <p>
            Your pacing {increased ? "increased" : "decreased"} by{" "}
            {deviationPercent}% to {wpm} words/min.
          </p>
          <p>
            {isOptimal
              ? "This is optimal, keep it up!"
              : wpm > wpmCutoffs.fast
              ? "This is fast. Try to slow things back down or people may miss what you're saying."
              : "This is slow. Try to pick things up or you may lose your audience."}
          </p>
        </Typography>
      </div>
    );
  };

  renderUnreadCommentHighlight = () => {
    const { classes, handleSetScrollToClip } = this.props;
    return (
      <div
        className={classes.criteriaContainer + " unread"}
        onClick={() => handleSetScrollToClip(this.unreadClipUuid)}
      >
        <Typography variant="h4" className={classes.unreadCriteria}>
          <CommentIcon fontSize="small" color="primary" /> Clips:{" "}
          <b className={classes.capitalize}>unread comments</b>
        </Typography>
        <Typography variant="body1" className={classes.criteriaDetails}>
          <p>
            You have <b className={classes.capitalize}>unread comments</b> on your meeting clips!
          </p>
          <p>
            View clips in the Clips and see unread comments in
            the comment section.
          </p>
        </Typography>
      </div>
    );
  };

  renderPositiveTopicHighlight = () => {
    const { classes, data } = this.props;
    const topic = data.highlights.positive_topic;
    return (
      <div className={classes.criteriaContainer + " positive"}>
        <Typography variant="h4" className={classes.positiveCriteria}>
          <ArrowUpwardIcon /> Key Topic:{" "}
          <b className={classes.capitalize}>{topic}</b>
        </Typography>
        <Typography variant="body1" className={classes.criteriaDetails}>
          <p>
            <b className={classes.capitalize}>{topic}</b> was well received!
          </p>
          <p>
            View mentions in the Power Transcript or see a speaker breakdown in
            Key Topics.
          </p>
        </Typography>
      </div>
    );
  };

  renderNegativeTopicHighlight = () => {
    const { classes, data } = this.props;
    const topic = data.highlights.negative_topic;
    return (
      <div className={classes.criteriaContainer + " negative"}>
        <Typography variant="h4" className={classes.negativeCriteria}>
          <ArrowDownwardIcon /> Key Topic:{" "}
          <b className={classes.capitalize}>{topic}</b>
        </Typography>
        <Typography variant="body1" className={classes.criteriaDetails}>
          <p>
            <b className={classes.capitalize}>{topic}</b> was poorly received!
          </p>
          <p>
            View mentions in the Power Transcript or see a speaker breakdown in
            Key Topics.
          </p>
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, data } = this.props;
    return (
      <section className={classes.content}>
        {showFillersHighlight(data.highlights) && this.renderFillersHighlight()}
        {showPacingHighlight(data.highlights) && this.renderPacingHighlight()}
        {showPositiveTopic(data.highlights) &&
          this.renderPositiveTopicHighlight()}
        {showNegativeTopic(data.highlights) &&
          this.renderNegativeTopicHighlight()}
        {this.hasUnreadComments(data.clips) &&
          this.renderUnreadCommentHighlight()}
      </section>
    );
  }
}

export default withStyles(styles)(Attention);
