import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

class Features extends Component {
  getName = () => {
    const fullName = localStorage.getItem("fullName");
    return fullName;
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Speaker Identification & Enrollment" />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <div className={classes.instructions}>
            <Typography className={classes.captionTitle} variant="subtitle1">
              We can automatically identify you from other participants in your
              meetings to provide better insights. To enable this, please enroll
              your speaker profile by submitting a small recording of yourself
              speaking the following blurb. Please make sure that there is
              minimal background noise while recording for better accuracy.
            </Typography>
            <Typography className={classes.caption} variant="h5">
              "Hello Sonero. My name is {this.getName()} and I'm excited to meet
              you. I'm recording this so that you can learn to recognize my
              amazing voice and we can become good friends. I'm speaking clearly
              and there's no background noise at all. I'm thrilled to see how
              you're going to make my meetings awesome. Well, it's time to say
              goodbye but I'm sure we'll meet again soon!"
            </Typography>
          </div>
        </PortletContent>
      </Portlet>
    );
  }
}

Features.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Features);
