import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { MetricBar } from "components";
import { Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import {
  getScoreColor,
  getAvgMeetingNecessityCopy,
  getAvgInviteeAttendanceCopy,
  getAvgDecisionDrivenCopy,
  getAvgProductiveMeetingCopy,
  getLabelDescriptor,
} from "helpers/meetingScore";

function AttendeeFeedback(props) {
  const { classes, avgFeedbackScore, avgFeedback, handleCollapse } = props;

  return (
    <div className={classes.detailedView}>
      <div className={classes.detailedViewContent}>
        <div className={classes.backButton} onClick={handleCollapse}>
          <ChevronLeft className={classes.largerIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.detailedScore}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Attendee Feedback
              <span className={classes.score}>
                {Math.round(avgFeedbackScore)}
                <span className={classes.outOfTen}>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={avgFeedbackScore * 10}
              barColor={getScoreColor(avgFeedbackScore)}
              height="10px"
              fillAnimation
            />

            <div className={classes.details}>
              {avgFeedback.meeting_need != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Meeting Necessity
                    {getLabelDescriptor(avgFeedback.meeting_need)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgMeetingNecessityCopy(avgFeedback.meeting_need)}
                  </Typography>
                </div>
              )}

              {avgFeedback.attendee_importance && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Invitee Attendance
                    {getLabelDescriptor(avgFeedback.attendee_importance)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgInviteeAttendanceCopy(
                      avgFeedback.attendee_importance
                    )}
                  </Typography>
                </div>
              )}

              {avgFeedback.clear_decisions && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Decision Driven
                    {getLabelDescriptor(avgFeedback.clear_decisions)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgDecisionDrivenCopy(avgFeedback.clear_decisions)}
                  </Typography>
                </div>
              )}

              {avgFeedback.meeting_productivity && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Productive Meeting
                    {getLabelDescriptor(avgFeedback.meeting_productivity)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgProductiveMeetingCopy(
                      avgFeedback.meeting_productivity
                    )}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default withStyles(styles)(AttendeeFeedback);
