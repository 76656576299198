import React, { Component } from "react";

import ReactGA from "react-ga";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  withStyles,
  Typography,
  TextField,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import NotesIcon from "@material-ui/icons/Notes";
import { Paper } from "components";
import { Comment } from "semantic-ui-react";
import styles from "./styles";
import { saveNewNote, deleteNote } from "services/speech";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class Notes extends Component {
  state = {
    comment: "",
    comments: [],
    deleteNoteUuid: "",
    isDeleteNoteLoading: false,
    meetingId: 0,
    openDeleteNoteDialog: false,
    showSnackbar: false,
    snackbarOpen: false,
    snackbarMessage: "",
    isOwner: true,
  };

  componentWillMount() {
    if (this.props.notes !== null && this.props.notes.length > 0) {
      const notes = this.props.notes.map((note) => {
        const date = new Date(note.created);
        return {
          uuid: note.uuid,
          text: note.text,
          metadata: date,
          author: note.author_name,
        };
      });
      this.setState({ comments: notes });
    }
    this.setState({
      meetingId: this.props.meetingId,
      isOwner: this.props.isMeetingOwner || this.props.isOrgOwner,
    });
  }

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleCommentChange = (value) => {
    this.setState({ comment: value });
  };

  handleDelete = (uuid) => {
    this.setState({ isDeleteNoteLoading: true });
    var array = [...this.state.comments];
    return deleteNote(uuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Delete note API failed",
          });
          this.setState({
            isDeleteNoteLoading: false,
            openDeleteNoteDialog: false,
            deleteNoteUuid: "",
          });
          return response;
        }

        for (let i = 0; i < array.length; i++) {
          if (array[i].uuid === uuid) {
            array.splice(i, 1);
            this.setState({ comments: array });
          }
        }
        this.props.deleteNoteResponseHandler(uuid);
        this.setState({
          isDeleteNoteLoading: false,
          openDeleteNoteDialog: false,
          deleteNoteUuid: "",
        });
        this.openSnackbar("Note deleted successfully");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Delete note API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleOpenDeleteNoteDialog = (uuid) => {
    this.setState({
      optionsMenuAnchorEl: null,
      openDeleteNoteDialog: true,
      deleteNoteUuid: uuid,
    });
  };

  handleCloseDeleteNoteDialog = () => {
    this.setState({ openDeleteNoteDialog: false, deleteNoteUuid: "" });
  };

  renderComment = (comment) => {
    const { classes, className, ...rest } = this.props;
    return (
      <Comment
        key={comment.author + "-" + comment.metadata}
        className={classes.comment}
      >
        <Comment.Content className={classes.commentContent}>
          <Comment.Author as="a" className={classes.commentAuthor}>
            {comment.author}
          </Comment.Author>
          <Comment.Metadata className={classes.commentMetadata}>
            <div>{comment.metadata.toLocaleString()}</div>
          </Comment.Metadata>
          <Comment.Text className={classes.commentText}>
            {comment.text}
          </Comment.Text>
          {this.state.isOwner && (
            <Comment.Actions>
              <Comment.Action
                className={classes.commentAction}
                onClick={() => {
                  this.handleOpenDeleteNoteDialog(comment.uuid);
                }}
              >
                Delete
              </Comment.Action>
            </Comment.Actions>
          )}
        </Comment.Content>
      </Comment>
    );
  };

  renderComments = () => {
    return this.state.comments.map((e) => {
      return this.renderComment(e);
    });
  };

  saveNote = (note) => {
    return saveNewNote(this.state.meetingId, note).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Create note API failed",
          });
          return response;
        }
        const responseNote = {
          uuid: response.data.data.uuid,
          author: response.data.data.author_name,
          metadata: new Date(response.data.data.created).toLocaleString(),
          text: response.data.data.text,
        };

        this.setState({
          comments: [...this.state.comments, responseNote],
          comment: "",
        });
        this.props.newNoteResponseHandler(response.data.data);
        this.openSnackbar("Note created successfully");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Create note API success",
        });
        return response;
      }.bind(this)
    );
  };

  renderDeleteNoteDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.openDeleteNoteDialog}
        onClose={() => this.handleCloseDeleteNoteDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Note</DialogTitle>
        <DialogContent>
          <Typography
            className={classes.deleteNoteCaption}
            variant="h5"
            align="center"
          >
            Are you sure you want to delete this note?
          </Typography>
        </DialogContent>
        <DialogActions>
          {this.state.isDeleteNoteLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button
              onClick={() => this.handleDelete(this.state.deleteNoteUuid)}
              color="primary"
              className={classes.deleteNoteButton}
            >
              YES, I'M SURE
            </Button>
          )}
          <Button
            onClick={() => this.handleCloseDeleteNoteDialog()}
            color="primary"
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <div>
        <Paper {...rest} className={rootClassName}>
          <div className={classes.firstContent}>
            <div className={classes.iconWrapper}>
              <NotesIcon className={classes.icon} />
            </div>
            <div className={classes.details}>
              <Typography className={classes.title} variant="h5">
                MEETING NOTES / COMMENTS
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <Comment.Group>
              {this.renderComments()}
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  if (this.state.comment.length > 0) {
                    this.saveNote(this.state.comment);
                  }
                }}
              >
                <TextField
                  className={classes.commentTextField}
                  label="Add a note"
                  multiline="true"
                  rows="4"
                  variant="outlined"
                  onChange={(event) =>
                    this.handleCommentChange(event.target.value)
                  }
                  value={this.state.comment}
                />
                <Button
                  className={classes.commentButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  ADD NOTE
                </Button>
              </form>
            </Comment.Group>
          </div>
        </Paper>
        <Snackbar
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar,
          }}
          message={this.state.snackbarMessage}
          autoHideDuration={2000}
          action={
            <Button
              style={{ color: "white" }}
              size="small"
              onClick={this.handleSnackbarClose}
            >
              CLOSE
            </Button>
          }
        />
        {this.renderDeleteNoteDialog()}
      </div>
    );
  }
}

Notes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notes);
