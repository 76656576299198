import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Card,
  Box,
  Chip,
} from "@material-ui/core";
import { EmailPreview } from "components";

import theme from "theme";
import { validateEmail } from "helpers/email";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import {
  getAPIHost,
  updateMeetingClipShareStatus,
  shareMeetingClipWithParticipants,
  getMeetingClipShareEmail,
  sendMeetingClipShareEmail,
} from "services/speech";

import ReactGA from "react-ga";

function ShareClipModal(props) {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [dialogTab, setDialogTab] = useState(0);
  const [isSharingEnabled, setIsSharingEnabled] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [emailRecipientInput, setEmailRecipientInput] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  useEffect(() => {
    if (props.clipUuid) {
      updateMeetingClipShareStatus(props.clipUuid, true)
        .then(() => {
          const url = `${getAPIHost()}/view-clip/share/${props.clipUuid}`;
          setIsSharingEnabled(true);
          setShareUrl(url);
          handleCopyShare(url);
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Shared a meeting clip",
          });
          return getMeetingClipShareEmail(props.clipUuid);
        })
        .then(({ data }) => {
          setEmailSubject(data.email_subject);
          setEmailBody(data.email_message);
          setIsLoading(false);
        });
    }
  }, [props.clipUuid]);

  const handleEnableSharing = () => {
    updateMeetingClipShareStatus(props.clipUuid, true).then((response) => {
      if (response.status === 200) {
        setIsSharingEnabled(true);
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Shared a meeting clip",
        });
      } else {
        props.openSnackbar("Sorry, an error occurred. Please try again.");
      }
    });
  };

  const handleDisableSharing = () => {
    updateMeetingClipShareStatus(props.clipUuid, false).then((response) => {
      if (response.status === 200) {
        setIsSharingEnabled(false);
      } else {
        props.openSnackbar("Sorry, an error occurred. Please try again.");
      }
    });
  };

  const handleCopyShare = (url) => {
    if (!url) url = shareUrl;
    navigator.clipboard
      .writeText(url)
      .then(() => props.openSnackbar("Link copied to clipboard"))
      .catch(() => {});
  };

  const handleShareWithParticipants = () => {
    shareMeetingClipWithParticipants(props.clipUuid).then((response) => {
      if (response.status === 200) {
        props.handleCloseModal();
        props.openSnackbar("Clip shared with attendees.");
      } else {
        props.openSnackbar("Sorry, an error occurred. Please try again.");
      }
    });
  };

  const handleDeleteRecipient = (email) => {
    const newEmailRecipients = emailRecipients.filter((e) => email !== e);
    setEmailRecipients(newEmailRecipients);
  };

  const handleRecipientInputChange = (event) => {
    if (event.target.value.endsWith(" ") || event.target.value.endsWith(",")) {
      const newEmailRecipient = event.target.value.slice(0, -1);
      if (newEmailRecipient) {
        const newEmailRecipients = [...emailRecipients, newEmailRecipient];
        setEmailRecipients(newEmailRecipients);
      }
      setEmailRecipientInput("");
    } else {
      setEmailRecipientInput(event.target.value);
    }
  };

  const handleRecipientInputKeyDown = (event) => {
    if (event.key === "Enter") {
      if (emailRecipientInput) {
        const newEmailRecipients = [...emailRecipients, emailRecipientInput];
        setEmailRecipients(newEmailRecipients);
      }
      setEmailRecipientInput("");
    }
  };

  const handleRecipientInputBlur = () => {
    if (emailRecipientInput) {
      const newEmailRecipients = [...emailRecipients, emailRecipientInput];
      setEmailRecipients(newEmailRecipients);
    }
    setEmailRecipientInput("");
  };

  const handleShareViaEmail = () => {
    sendMeetingClipShareEmail(props.clipUuid, emailRecipients).then(
      (response) => {
        if (response.status === 200) {
          props.handleCloseModal();
          props.openSnackbar("Clip shared succesfully.");
        } else {
          props.openSnackbar("Sorry, an error occurred. Please try again.");
        }
      }
    );
  };

  const renderShareDialogContent = () => {
    if (isLoading) {
      return (
        <Typography variant="body1" className={classes.loadingText}>
          Loading...
        </Typography>
      );
    } else {
      if (isSharingEnabled) {
        return (
          <>
            {dialogTab === 0 && (
              <DialogContent>{renderShareWithPeopleContent()}</DialogContent>
            )}
            {dialogTab === 1 && (
              <DialogContent>{renderShareViaEmailContent()}</DialogContent>
            )}
          </>
        );
      } else {
        return (
          <Typography variant="body1">
            Please enable sharing to view the different sharing options for the
            clip
          </Typography>
        );
      }
    }
  };

  const renderShareWithPeopleContent = () => {
    return (
      <Card elevation={0}>
        <Box flexGrow={1}>
          <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
            Shareable Link
          </Typography>
          <Typography variant="body1">{shareUrl}</Typography>
          <div className={classes.shareOptionButtonContainer}>
            <Button
              onClick={() => handleCopyShare()}
              color="primary"
              variant="outlined"
              size="small"
            >
              Copy Link
            </Button>
          </div>

          <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
            Share with Meeting Attendees
          </Typography>
          <Typography variant="body1">
            We'll share the clip for you by email with the attendees (if their
            emails are available)
          </Typography>
          <div className={classes.shareOptionButtonContainer}>
            <Button
              onClick={handleShareWithParticipants}
              color="primary"
              variant="outlined"
              size="small"
            >
              Share with Attendees
            </Button>
          </div>
        </Box>
      </Card>
    );
  };

  const renderShareViaEmailContent = () => {
    return (
      <>
        <Typography variant="h5" style={{ marginTop: theme.spacing.unit }}>
          Add email addresses to share this clip
        </Typography>
        <div className={classes.emailContainer}>
          <div className={classes.recipientsContainer}>
            {emailRecipients.map((email) => (
              <Chip
                label={email}
                variant="outlined"
                color={validateEmail(email) ? "primary" : "error"}
                className={classes.emailChip}
                onDelete={() => handleDeleteRecipient(email)}
              />
            ))}
            <input
              className={classes.emailRecipientsInput}
              placeholder="Add recipient"
              value={emailRecipientInput}
              onChange={handleRecipientInputChange}
              onKeyDown={handleRecipientInputKeyDown}
              onBlur={handleRecipientInputBlur}
              autoFocus={true}
            />
          </div>

          <Typography variant="h5" style={{ marginBottom: theme.spacing.unit }}>
            Here's a preview of the email content
          </Typography>
          <div className={classes.emailSection}>
            <Typography variant="h6">Subject</Typography>
            <div className={classes.emailSubject}>{emailSubject}</div>
          </div>

          <div className={classes.emailSection}>
            <Typography variant="h6">Message</Typography>
            <div className={classes.emailBody}>
              <EmailPreview content={emailBody} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderShareStatusButton = () => {
    if (isSharingEnabled) {
      return (
        <Button onClick={handleDisableSharing} color="primary">
          Disable Sharing
        </Button>
      );
    } else {
      return (
        <Button onClick={handleEnableSharing} color="primary">
          Enable Sharing
        </Button>
      );
    }
  };

  return (
    <Dialog
      className={classes.shareDialogContainer}
      open={props.modalOpen}
      onClose={props.handleCloseModal}
      aria-labelledby="form-dialog-title"
    >
      {isSharingEnabled && (
        <AppBar position="static" color="default" style={{ flexGrow: 1 }}>
          <Tabs
            value={dialogTab}
            onChange={(_event, newValue) => setDialogTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="SHARE WITH PEOPLE" />
            <Tab label="SHARE VIA EMAIL" />
          </Tabs>
        </AppBar>
      )}
      <DialogContent>{renderShareDialogContent()}</DialogContent>
      <DialogActions>
        {dialogTab === 1 && (
          <Button
            onClick={handleShareViaEmail}
            color="primary"
            variant="outlined"
            size="small"
          >
            Send Email
          </Button>
        )}
        {isLoading ? (
          <CircularProgress size="1rem" className={classes.progress} />
        ) : (
          renderShareStatusButton()
        )}
        <Button onClick={props.handleCloseModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ShareClipModal);
