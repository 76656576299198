export default theme => ({
    root: {},
    portletFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: 'none'
    },
    chartWrapper: {
        height: '300px',
        position: 'relative',
        maintainAspectRatio: true
    },
    caption: {
        color: theme.palette.text.secondary
    },
    field: {
        marginTop: theme.spacing.unit * 2
    },
    fieldFirst: {
        marginTop: theme.spacing.unit
    },
    textField: {
        width: '100%'
    },
    fieldError: {
        color: theme.palette.danger.main,
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit
    },
    formControl: {
        marginTop: theme.spacing.unit
    },
    group: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit * 2
    },
    radio: {
        '&$checked': {
            color: theme.palette.text.secondary,
        },
    },
    input: {
        marginBottom: theme.spacing.unit,
        width: '100%'
    },
    fieldFormats: {
        marginBottom: theme.spacing.unit * 2,
        color: theme.palette.text.secondary
    }
  });