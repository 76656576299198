import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  container: {
    position: "relative",
    overflow: "visible",
  },
  header: {
    padding: theme.spacing.unit * 2,
    borderBottom: `1px solid ${theme.palette.border}`,
    background: theme.palette.elements.currentInsight.lightGrey,
  },
  title: {
    color: theme.palette.text.grey,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  content: {
    width: "100%",
    padding: theme.spacing.unit * 2,
  },
  footer: {
    padding: theme.spacing.unit * 0.5,
    marginTop: "auto",
    borderTop: `1px solid ${theme.palette.border}`,
    textAlign: "right",
  },
  wordCloudContent: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  wordCloud: {
    height: "150px",
    width: "90%",
    margin: "auto",
    "& *": {
      outline: "none",
    },
  },
  hoveredContent: {
    position: "absolute",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.primary.light,
    transform: "translateY(-100%)",
    zIndex: 5,
  },
  hoveredHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(0.75),
    minWidth: "200px",
  },
  hoveredHeaderText: {
    fontSize: "14px",
    fontWeight: 500,
    "& > span": {
      fontSize: "18px",
      textTransform: "uppercase",
      color: theme.palette.primary.main,
    },
  },
  hoveredBreakdown: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  breakdownRowLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  breakdownBar: {
    height: "4px",
    background: fade(theme.palette.primary.lightBlue, 0.75),
    borderRadius: "4px",
  },
  sentimentPill: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    borderRadius: "5px",
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    fontSize: "12px",
    color: theme.palette.common.white,
    "&.positive": {
      backgroundColor: theme.palette.success.main,
    },
    "&.negative": {
      backgroundColor: theme.palette.danger.mellow,
    },
  },
  sentimentIcon: {
    width: "12px",
    height: "12px",
  },
  sentimentTip: {
    fontSize: "12px",
    fontWeight: 400,
    fontStyle: "italic",
    marginBottom: theme.spacing(1.5),
  },
});
