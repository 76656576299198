import React, { Component } from "react";
import ReactGA from "react-ga";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  Person as ParticipantIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  NotificationsActive as NotificationsActiveIcon,
} from "@material-ui/icons";
import {
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
} from "@material-ui/core";
import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletFooter,
  TranscriptDialog,
  ShareButton,
} from "components";
import {
  AddParticipant,
  DeleteParticipant,
  MeetingScore,
  Attention,
} from "views/ViewMeeting/components";
import styles from "./styles";
import { getAPIHost, updateParticipant } from "services/speech";
import validate from "validate.js";
import { showMeetingScore } from "helpers/meetingScore";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import { getNumberOfHighlights } from "helpers/meetingHighlights";

class MeetingInfo extends Component {
  state = {
    duration: "00:00",
    durationCaption: "Duration",
    title: "",
    openTranscriptDialog: false,
    openAddParticipantDialog: false,
    openDeleteParticipantDialog: false,
    participants: [],
    isParticipantEditingEnabled: false,
    deleteParticipantEmail: "",
    deleteParticipantId: "",
    openShareDropDown: false,
    editingParticipant: "",
    editingName: "",
    editingEmail: "",
    showScoreToGuests: false,
    shouldShowMeetingScore: false,
    numHighlights: 0,
    tab: 0,
  };
  tabs = {
    highlights: 0,
    score: 1,
    attendees: 2,
  };
  dropDownAnchor = React.createRef();

  componentWillMount() {
    const title = this.props.data.title;
    const participants = this.props.data.participants_details;
    const isParticipantEditingEnabled = this.props.data
      .is_participant_editing_enabled;
    const showScoreToGuests =
      this.props.showScoreToGuests !== undefined
        ? this.props.showScoreToGuests
        : false;
    const shouldShowMeetingScore =
      showMeetingScore(
        this.props.data,
        this.props.meetingStatus,
        showScoreToGuests
      ) &&
      this.props.data.is_recording_enabled &&
      (!this.props.isSharedView ||
        this.props.data.share_granularity.share_score);
    const numHighlights = getNumberOfHighlights(this.props.data.highlights, this.props.data.clips, this.props.isSharedView);

    this.updateTabs(shouldShowMeetingScore, numHighlights);
    this.setState({
      title: title,
      participants: participants,
      isParticipantEditingEnabled: isParticipantEditingEnabled,
      showScoreToGuests: showScoreToGuests,
      shouldShowMeetingScore: shouldShowMeetingScore,
      numHighlights: numHighlights,
    });
  }

  updateTabs = (shouldShowMeetingScore, numHighlights) => {
    if (!shouldShowMeetingScore && numHighlights === 0) {
      this.tabs = {
        attendees: 0,
      };
    } else if (!shouldShowMeetingScore) {
      this.tabs = {
        highlights: 0,
        attendees: 1,
      };
    } else if (numHighlights === 0) {
      this.tabs = {
        score: 0,
        attendees: 1,
      };
    } else {
      this.tabs = {
        highlights: 0,
        score: 1,
        attendees: 2,
      };
    }
  };

  handleUpdateParticipant = (participantId) => {
    const { editingName, editingEmail } = this.state;

    const constraints = {
      editingEmail: {
        email: editingEmail !== "",
      },
    };
    const errors = validate(this.state, constraints);
    if (errors) {
      return;
    }

    updateParticipant(participantId, editingName, editingEmail).then(
      (response) => {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
          }
          ReactGA.event({
            category: "Failure",
            action: "Update participant API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Update participant API success",
          });
          this.props.updateParticipantHandler(response.data.data);
        }
      }
    );
  };

  handleOpenTranscript = () => {
    this.setState({ openTranscriptDialog: true });
  };

  handleCloseTranscript = () => {
    this.setState({ openTranscriptDialog: false });
  };

  renderTranscriptButton = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Button
        className={classes.recordButton}
        color="primary"
        size="small"
        variant="text"
        onClick={() => this.handleOpenTranscript()}
      >
        TRANSCRIPT
      </Button>
    );
  };

  getParticipantsList = () => {
    const { classes, className, ...rest } = this.props;
    if (this.state.participants.length == 0) {
      return (
        <div>
          {
            <ListItem className={classes.listItem}>
              <Typography className={classes.value} variant="h5">
                No meeting attendee details.
              </Typography>
            </ListItem>
          }
        </div>
      );
    } else {
      const reorderedParticipants = [
        ...this.state.participants.filter(
          (participant) => participant.user_email != null
        ),
        ...this.state.participants.filter(
          (participant) => participant.user_email == null
        ),
      ];

      return (
        <div>
          {reorderedParticipants.map((element) => {
            return this.getParticipant(element);
          })}
        </div>
      );
    }
  };

  handleonClickEditAttendees = (participantId, element) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Edit Attendee",
      label: "Meeting Info",
    });
    this.setState({
      editingParticipant: participantId,
      editingName: element.name,
      editingEmail: element.user_email,
    });
  };

  getParticipant = (element) => {
    const { classes, className, ...rest } = this.props;

    var name = element.name;
    if (name == "") {
      name = "Guest";
    }

    var email = element.user_email;

    var participantId = element.id;
    const isEditing = this.state.editingParticipant === participantId;

    return (
      <div>
        <ListItem className={classes.listItem}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#e8e8e8" }}>
              <ParticipantIcon className={classes.avatar} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <React.Fragment>
                {isEditing ? (
                  <input
                    className={classes.participantNameInput}
                    placeholder="Name"
                    value={this.state.editingName}
                    onChange={(e) =>
                      this.setState({ editingName: e.target.value })
                    }
                  />
                ) : (
                  <Typography className={classes.value} variant="h5">
                    {name}
                  </Typography>
                )}
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                {isEditing ? (
                  <input
                    className={classes.participantEmailInput}
                    placeholder="Email"
                    value={this.state.editingEmail}
                    onChange={(e) =>
                      this.setState({ editingEmail: e.target.value })
                    }
                  />
                ) : (
                  <Typography
                    className={classes.valueSecondary}
                    variant="body2"
                  >
                    {email}
                  </Typography>
                )}
              </React.Fragment>
            }
          />
          {this.state.isParticipantEditingEnabled && (
            <ListItemSecondaryAction>
              {isEditing ? (
                <>
                  <IconButton
                    aria-label="confirm"
                    onClick={() => {
                      this.handleUpdateParticipant(participantId);
                      this.setState({ editingParticipant: "" });
                    }}
                  >
                    <CheckIcon color="primary" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="cancel"
                    onClick={() => this.setState({ editingParticipant: "" })}
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    aria-label="edit"
                    onClick={() =>
                      this.handleonClickEditAttendees(participantId, element)
                    }
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      this.handleOpenDeleteParticipant(email, participantId)
                    }
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                </>
              )}
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </div>
    );
  };

  // Render share dialog stuff

  renderShareButton = () => {
    const isOwner =
      this.props.data.owner_email === localStorage.getItem("email") ||
      this.props.isOrgOwner;
    const { classes } = this.props;
    return (
      <div className={classes.shareButton}>
        <ShareButton shareButtonHandler={this.props.handleOpenShare} />
      </div>
    );
  };

  renderTranscriptDialog = () => {
    return (
      <TranscriptDialog
        data={this.props.data}
        open={this.state.openTranscriptDialog}
        closeHandler={this.handleCloseTranscript}
        setMediaPlayerCurrentTimeAndPlay={
          this.props.setMediaPlayerCurrentTimeAndPlay
        }
      />
    );
  };

  renderAddParticipantDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <AddParticipant
        open={this.state.openAddParticipantDialog}
        closeHandler={this.handleCloseAddParticipant}
        meetingId={this.props.data.meeting_id}
        successHandler={this.handleAddParticipantSuccess}
      />
    );
  };

  renderDeleteParticipantDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <DeleteParticipant
        open={this.state.openDeleteParticipantDialog}
        closeHandler={this.handleCloseDeleteParticipant}
        meetingId={this.props.data.meeting_id}
        email={this.state.deleteParticipantEmail}
        participantId={this.state.deleteParticipantId}
        successHandler={this.handleDeleteParticipantSuccess}
      />
    );
  };

  handleCloseAddParticipant = () => {
    this.setState({ openAddParticipantDialog: false });
  };

  handleCloseDeleteParticipant = () => {
    this.setState({ openDeleteParticipantDialog: false });
  };

  handleOpenAddParticipant = () => {
    this.setState({ openAddParticipantDialog: true });
  };

  handleAddParticipantSuccess = (participant) => {
    this.props.addParticipantHandler(participant);
  };

  handleDeleteParticipantSuccess = () => {
    this.props.deleteParticipantHandler(this.state.deleteParticipantId);
    this.setState({ deleteParticipantEmail: "", deleteParticipantId: "" });
  };

  handleOpenDeleteParticipant = (email, participantId) => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Delete Attendee",
      label: "Meeting Info",
    });
    this.setState({
      openDeleteParticipantDialog: true,
      deleteParticipantEmail: email,
      deleteParticipantId: participantId,
    });
  };

  handleOnClickHighlights = () => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Highlights Tab",
      label: "Meeting Info",
    });
    this.setState({ tab: this.tabs.highlights });
  };

  handleOnClickEfficiencyScore = () => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Efficiency Score Tab",
      label: "Meeting Info",
    });
    this.setState({ tab: this.tabs.score });
  };

  handleOnClickAttendees = () => {
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Attendees Tab",
      label: "Meeting Info",
    });
    this.setState({ tab: this.tabs.attendees });
  };

  renderHeader = () => {
    const { classes, data } = this.props;
    const {
      tab,
      isParticipantEditingEnabled,
      shouldShowMeetingScore,
      numHighlights,
    } = this.state;
    const showAddAttendeeButton =
      isParticipantEditingEnabled &&
      (!shouldShowMeetingScore ||
        (shouldShowMeetingScore && tab === this.tabs.attendees));
    const tabWidth = Math.round(100 / Object.keys(this.tabs).length);
    return (
      <PortletHeader noPadding>
        <div className={classes.tabContainer}>
          {numHighlights > 0 && (
            <div
              className={
                classes.tab + (tab === this.tabs.highlights ? " active" : "")
              }
              onClick={this.handleOnClickHighlights}
            >
              {!data.viewed && (
                <NotificationsActiveIcon
                  className={classes.highlightsNotification}
                />
              )}
              Highlights
            </div>
          )}
          {shouldShowMeetingScore && (
            <div
              className={
                classes.tab + (tab === this.tabs.score ? " active" : "")
              }
              onClick={this.handleOnClickEfficiencyScore}
            >
              Efficiency Score
            </div>
          )}
          <div
            className={
              classes.tab + (tab === this.tabs.attendees ? " active" : "")
            }
            onClick={this.handleOnClickAttendees}
          >
            Attendees
            {showAddAttendeeButton && (
              <IconButton
                edge="end"
                aria-label="add"
                size="small"
                onClick={() => this.handleOpenAddParticipant()}
              >
                <AddIcon color="primary" />
              </IconButton>
            )}
          </div>
          <div
            className={classes.activeUnderline}
            style={(() => {
              const underlineStyle = { width: `${tabWidth}%`, left: 0 };
              if (tab === 1) {
                underlineStyle.left = `${tabWidth}%`;
              } else if (tab === 2) {
                underlineStyle.left = `${tabWidth * 2}%`;
              }
              return underlineStyle;
            })()}
          />
        </div>
      </PortletHeader>
    );
  };

  render() {
    const {
      classes,
      className,
      data,
      meetingStatus,
      showScoreToGuests,
      handleSetScrollToClip,
      ...rest
    } = this.props;
    const { tab, shouldShowMeetingScore } = this.state;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        {this.renderHeader(shouldShowMeetingScore)}
        {tab === this.tabs.highlights && (
          <div style={{ maxHeight: "380px" }}>
            <Attention data={data} handleSetScrollToClip={handleSetScrollToClip}/>
          </div>
        )}
        {shouldShowMeetingScore && tab === this.tabs.score && (
          <MeetingScore
            data={this.props.data}
            sharedView={this.state.showScoreToGuests}
          />
        )}
        {tab === this.tabs.attendees && (
          <PortletContent className={classes.content}>
            <div>
              <List className={classes.list}>{this.getParticipantsList()}</List>
            </div>
          </PortletContent>
        )}
        {this.renderTranscriptDialog()}
        {this.renderAddParticipantDialog()}
        {this.renderDeleteParticipantDialog()}
      </Portlet>
    );
  }
}

MeetingInfo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MeetingInfo);
