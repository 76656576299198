import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components
import { EnrollVideo, EnrollDescription } from "./components";

// Shared components
import { Portlet, PortletContent } from "components";

// Shared services
import { enrollUser } from "services/speech";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  freeTierCaption: {
    color: theme.palette.text.secondary,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
});

class EnrollSpeaker extends Component {
  timeout = null;
  pageTitle = "Enroll Speaker Profile";

  state = {
    results: {},
    videoBlob: null,
    isLoading: false,
    error: null,
    snackbarOpen: false,
    isEnrolled: false,
    snackbarMessage: "",
    enrollDialogOpen: false,
  };

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  uploadEnrollVideo = (blob) => {
    this.setState({ isLoading: true, videoBlob: blob });
    enrollUser(blob).then(
      function(response) {
        if (response.status === 200) {
          this.setState({ isEnrolled: true, enrollDialogOpen: true });
          ReactGA.event({
            category: "User",
            action: "User speaker profile enrolled",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
          ReactGA.event({
            category: "Failure",
            action: "Refresh token expired",
          });
        } else {
          this.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Could not submit enrollment video",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  setError = (error) => {
    this.setState({ error: error });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      enrollDialogOpen: false,
    });
    this.props.history.push("/account");
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title={this.pageTitle}>
        <div className={classes.root}>
          <div className={classes.content}>
            <Portlet>
              <PortletContent className={classes.content}>
                <Typography className={classes.error} variant="body1">
                  {this.state.error}
                </Typography>
              </PortletContent>
            </Portlet>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title={this.pageTitle}>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderProcessing = () => {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography
              className={classes.freeTierCaption}
              variant="h5"
              align="left"
            >
              Your recording is currently being processed and analyzed. This may
              take some time depending on the length of the recording.
              <br />
              <br />
              We will send you an email once processing is complete. Thank you.
            </Typography>
          </PortletContent>
        </Portlet>
      </div>
    );
  };

  renderEnrollCompleteDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.enrollDialogOpen}
        onClose={() => this.handleCloseDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Speaker Enrollment</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            We've successfully enrolled your speaker profile!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleCloseDialog()} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderEnrollSpeaker = () => {
    const { classes } = this.props;
    return (
      <Grid container spacing={4}>
        <Grid item lg={7} md={7} xl={9} xs={12}>
          <EnrollVideo
            className={classes.item}
            uploadEnrollVideo={this.uploadEnrollVideo}
            clientError={this.setError}
            videoBlob={this.state.videoBlob}
            showPortletHeader={true}
          />
        </Grid>
        <Grid item lg={5} md={5} xl={3} xs={12}>
          <EnrollDescription className={classes.item} />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.error != null) {
      return this.renderError();
    }

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <DashboardLayout title={this.pageTitle}>
        <div className={classes.root}>
          {this.renderEnrollSpeaker()}
          {this.renderEnrollCompleteDialog()}
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
        </div>
      </DashboardLayout>
    );
  }
}

EnrollSpeaker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnrollSpeaker);
