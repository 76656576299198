import React, { useState, useEffect } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Typography, Popper, Card, Button } from "@material-ui/core";

const FTUX = (props) => {
  const { classes } = props;
  const [ftuxStep, setFtuxStep] = useState(0);
  const [timeInterval, setTimeInterval] = useState(0);
  const [transcriptTooltipAnchor, setTranscriptTooltipAnchor] = useState(null);
  const [shareTooltipAnchor, setShareTooltipAnchor] = useState(null);
  const [convoHighlightTooltipAnchor, setConvoHighlightTooltipAnchor] =
    useState(null);
  const [clipTooltipAnchor, setClipTooltipAnchor] = useState(null);
  const [trackerTooltipAnchor, setTrackerTooltipAnchor] = useState(null);
  const transcriptTooltipRef = (ref) => {
    const hasSeenTranscriptTooltip = localStorage.getItem(
      "hasSeenTranscriptTooltip"
    );
    if (!hasSeenTranscriptTooltip) {
      setTranscriptTooltipAnchor(ref);
    }
  };
  const shareTooltipRef = (ref) => {
    const hasSeenShareTooltip = localStorage.getItem("hasSeenShareTooltip");
    if (!hasSeenShareTooltip) {
      setShareTooltipAnchor(ref);
    }
  };
  const convoHighlightTooltipRef = (ref) => {
    const hasSeenConvoHighlightTooltip = localStorage.getItem(
      "hasSeenConvoHighlightTooltip"
    );
    if (!hasSeenConvoHighlightTooltip) {
      setConvoHighlightTooltipAnchor(ref);
    }
  };
  const clipTooltipRef = (ref) => {
    const hasSeenClipTooltip = localStorage.getItem("hasSeenClipTooltip");
    if (!hasSeenClipTooltip) {
      setClipTooltipAnchor(ref);
    }
  };
  const trackerTooltipRef = (ref) => {
    const hasSeenTrackerTooltip = localStorage.getItem("hasSeenTrackerTooltip");
    if (!hasSeenTrackerTooltip) {
      setTrackerTooltipAnchor(ref);
    }
  };

  useEffect(() => {
    const pageLastPopup = localStorage.getItem("viewMeetingLastPopup");
    const hasSeenTranscriptTooltip = localStorage.getItem(
      "hasSeenTranscriptTooltip"
    );
    const hasSeenShareTooltip = localStorage.getItem("hasSeenShareTooltip");
    const hasSeenConvoHighlightTooltip = localStorage.getItem(
      "hasSeenConvoHighlightTooltip"
    );
    const hasSeenClipTooltip = localStorage.getItem("hasSeenClipTooltip");
    const hasSeenTrackerTooltip = localStorage.getItem("hasSeenTrackerTooltip");
    if (!pageLastPopup) {
      setTimeInterval(18);
    } else {
      setTimeInterval(
        Math.abs(
          new Date() - Date.parse(localStorage.getItem("viewMeetingLastPopup"))
        ) /
          (1000 * 60 * 60)
      );
    }
    if (!hasSeenTranscriptTooltip) {
      setFtuxStep(1);
    } else if (!hasSeenShareTooltip) {
      setFtuxStep(2);
    } else if (!hasSeenConvoHighlightTooltip) {
      setFtuxStep(3);
    } else if (!hasSeenClipTooltip) {
      setFtuxStep(4);
    } else if (!hasSeenTrackerTooltip) {
      setFtuxStep(5);
    }
  }, []);

  const handlePageLastPopupUpdate = () => {
    localStorage.setItem("viewMeetingLastPopup", new Date().toString());
  };

  const handleCloseTranscriptTooltip = () => {
    localStorage.setItem("hasSeenTranscriptTooltip", true);
    setTranscriptTooltipAnchor(null);
  };

  const handleCloseShareTooltip = () => {
    localStorage.setItem("hasSeenShareTooltip", true);
    setShareTooltipAnchor(null);
  };

  const handleCloseConvoHighlightTooltip = () => {
    localStorage.setItem("hasSeenConvoHighlightTooltip", true);
    setConvoHighlightTooltipAnchor(null);
  };

  const handleCloseClipTooltip = () => {
    localStorage.setItem("hasSeenClipTooltip", true);
    setClipTooltipAnchor(null);
  };

  const handleCloseTrackerTooltip = () => {
    localStorage.setItem("hasSeenTrackerTooltip", true);
    setTrackerTooltipAnchor(null);
  };

  const handleNextStep = () => {
    if (ftuxStep === 1) {
      handleCloseTranscriptTooltip();
    } else if (ftuxStep === 2) {
      handleCloseShareTooltip();
    } else if (ftuxStep === 3) {
      handleCloseConvoHighlightTooltip();
    } else if (ftuxStep === 4) {
      handleCloseClipTooltip();
    } else if (ftuxStep === 5) {
      handleCloseTrackerTooltip();
    }
    setFtuxStep(ftuxStep + 1);
  };

  const handleFinish = () => {
    handleCloseTranscriptTooltip();
    handleCloseShareTooltip();
    handleCloseConvoHighlightTooltip();
    handleCloseClipTooltip();
    handleCloseTrackerTooltip();
    handlePageLastPopupUpdate();
    setFtuxStep(0);
    setTimeInterval(0);
  };

  const handleFinishButton = () => {
    if (ftuxStep === 1) {
      handleCloseTranscriptTooltip();
    } else if (ftuxStep === 2) {
      handleCloseShareTooltip();
    } else if (ftuxStep === 3) {
      handleCloseConvoHighlightTooltip();
    } else if (ftuxStep === 4) {
      handleCloseClipTooltip();
    } else if (ftuxStep === 5) {
      handleCloseTrackerTooltip();
    }
    handlePageLastPopupUpdate();
    setFtuxStep(0);
    setTimeInterval(0);
  };

  const renderStep1Ftux = () => {
    return (
      <>
        <div className={classes.dimOverlay} onClick={handleFinish}></div>
        <div
          ref={transcriptTooltipRef}
          style={{
            position: "absolute",
            right: "900px",
            top: "700px",
          }}
        ></div>
        <Popper
          open={Boolean(transcriptTooltipAnchor)}
          anchorEl={transcriptTooltipAnchor}
          placement="right"
          style={{ zIndex: 10 }}
        >
          <Card className={classes.transcriptTooltip}>
            <Typography variant="h4">Transcript Navigation</Typography>
            <Typography variant="body1">
              Navigate the Transcript using any of the Insights on the left hand
              side.
            </Typography>
            <Button
              className={classes.tooltipButton}
              color="primary"
              onClick={handleNextStep}
            >
              Got it
            </Button>
          </Card>
        </Popper>
      </>
    );
  };

  const renderStep2Ftux = () => {
    return (
      <>
        <div className={classes.dimOverlay} onClick={handleFinish}></div>
        <div
          ref={shareTooltipRef}
          style={{
            position: "fixed",
            right: "10px",
            top: "85px",
          }}
        ></div>
        <Popper
          open={Boolean(shareTooltipAnchor)}
          anchorEl={shareTooltipAnchor}
          placement="bottom-end"
          style={{ zIndex: 10 }}
        >
          <Card className={classes.shareTooltip}>
            <Typography variant="h4">Sharing meetings</Typography>
            <Typography variant="body1">
              Share this meeting with a link or by email from here!
            </Typography>
            <Button
              color="primary"
              className={classes.tooltipButton}
              onClick={handleFinishButton}
            >
              Got it
            </Button>
          </Card>
        </Popper>
      </>
    );
  };

  const renderStep3Ftux = () => (
    <>
      <div className={classes.dimOverlay} onClick={handleFinish}></div>
      <div
        ref={convoHighlightTooltipRef}
        style={{
          position: "absolute",
          left: "45%",
          top: "670px",
        }}
      ></div>
      <Popper
        open={Boolean(convoHighlightTooltipAnchor)}
        anchorEl={convoHighlightTooltipAnchor}
        placement="right"
        style={{ zIndex: 10 }}
      >
        <Card className={classes.convoTooltip}>
          <Typography variant="h4">New Conversation Highlighs!</Typography>
          <Typography variant="body1">
            Check out our new look and feel for conversation highlights! View
            these insights in a list view and download for offline access.
          </Typography>
          <Typography>
            Our timeline is still available too. Switch between the two using
            the toggle!
          </Typography>
          <Button
            className={classes.tooltipButton}
            color="primary"
            onClick={handleFinishButton}
          >
            Got it
          </Button>
        </Card>
      </Popper>
    </>
  );

  const renderStep4Ftux = () => {
    return (
      <>
        <div className={classes.dimOverlay} onClick={handleFinish}></div>
        <div
          ref={clipTooltipRef}
          style={{
            position: "absolute",
            right: "325px",
            top: "475px",
          }}
        ></div>
        <Popper
          open={Boolean(clipTooltipAnchor)}
          anchorEl={clipTooltipAnchor}
          placement="bottom"
          style={{ zIndex: 10 }}
        >
          <Card className={classes.clipTooltip}>
            <Typography variant="h4">Create clips!</Typography>
            <Typography variant="body1">
              Drag and select parts of the transcript to create a clip. Your
              clips will be saved in the Clips widget below.
            </Typography>
            <Button
              className={classes.tooltipButton}
              color="primary"
              onClick={handleFinishButton}
            >
              Got it
            </Button>
          </Card>
        </Popper>
      </>
    );
  };

  const renderStep5Ftux = () => {
    let trackerNotesYPosition = "";
    if (props.trackerNotesRect) {
      trackerNotesYPosition = `${props.trackerNotesRect.y + 30}px`;
    }
    return (
      <>
        <div className={classes.dimOverlay} onClick={handleFinish}></div>
        <div
          ref={trackerTooltipRef}
          style={{
            position: "absolute",
            top: trackerNotesYPosition,
            left: "50%",
          }}
        ></div>
        <Popper
          open={Boolean(trackerTooltipAnchor)}
          anchorEl={trackerTooltipAnchor}
          placement="right"
          style={{ zIndex: 10 }}
        >
          <Card className={classes.trackerTooltip}>
            <Typography variant="h4">Trackers and Notes!</Typography>
            <Typography variant="body1">
              Track custom key words or ask the AI Assistant to record notes and
              decisions! See what we catch here.
            </Typography>
            <Button
              className={classes.tooltipButton}
              color="primary"
              onClick={handleFinishButton}
            >
              Okay
            </Button>
          </Card>
        </Popper>
      </>
    );
  };

  if (timeInterval >= 18) {
    if (ftuxStep === 1) {
      return renderStep1Ftux();
    }
    if (ftuxStep === 2) {
      return renderStep2Ftux();
    }
    if (ftuxStep === 3) {
      return renderStep3Ftux();
    }
    if (ftuxStep === 4) {
      return renderStep4Ftux();
    }
    if (ftuxStep === 5) {
      return renderStep5Ftux();
    }
  }
  return <></>;
};

export default withStyles(styles)(FTUX);
