import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Externals
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from "validate.js";
import _ from "underscore";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";

// Component styles
import styles from "./styles";

// Form validation schema
import schema from "./schema";

// Shared services
import { resetPassword } from "services/speech";

import ReactGA from "react-ga";

class ResetPassword extends Component {
  state = {
    values: {
      email: "",
    },
    touched: {
      email: false,
    },
    errors: {
      email: null,
    },
    isValid: false,
    isLoading: false,
    submitError: null,
    resetPasswordSuccess: false,
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleResetPassword = () => {
    const { history } = this.props;
    const { values } = this.state;

    this.setState({ isLoading: true });

    resetPassword(values.email).then(
      function (response) {
        console.log(response);
        if (response.status === 200) {
          this.setState({
            isLoading: false,
            resetPasswordSuccess: true,
            submitError: null,
          });
        } else {
          const data = response.data;
          if (data != null) {
            const errors = data.email;
            if (
              errors != null &&
              errors.includes("Enter a valid email address.")
            ) {
              this.setState({
                isLoading: false,
                submitError: "Please verify your email address and try again. ",
              });
              ReactGA.event({
                category: "User",
                action: "Reset password API failed because email not valid",
              });
              return;
            }
          }
          this.setState({
            isLoading: false,
            submitError: "Sorry, unable to reset your password. Try again. ",
          });
          ReactGA.event({
            category: "Failure",
            action: "Reset password API failed",
          });
        }
      }.bind(this)
    );
  };

  render() {
    const { classes } = this.props;
    const {
      values,
      isValid,
      submitError,
      isLoading,
      resetPasswordSuccess,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form}>
              <div className={classes.logoWrapper}>
                <Link className={classes.logoLink} to="/">
                  <img
                    alt="Sonero logo"
                    height="100px"
                    className={classes.logoImage}
                    src="/sonero-static/images/logo_with_name.png"
                  />
                </Link>
              </div>
              <Divider className={classes.logoDivider} />
              <Typography className={classes.title} variant="h2">
                Reset Password
              </Typography>
              {resetPasswordSuccess && (
                <Typography className={classes.title} variant="h4">
                  A password reset email has been sent to your email address.
                </Typography>
              )}
              {!resetPasswordSuccess && (
                <div>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label="Email address"
                      name="email"
                      onChange={(event) =>
                        this.handleFieldChange("email", event.target.value)
                      }
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                  </div>
                  {submitError && (
                    <Typography className={classes.submitError} variant="body2">
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.resetPasswordButton}
                      color="primary"
                      disabled={!isValid}
                      onClick={this.handleResetPassword}
                      size="large"
                      variant="contained"
                    >
                      Next
                    </Button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(ResetPassword);
