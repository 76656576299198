import React, { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import Highlighter from "react-highlight-words";
import { Box, IconButton, withStyles } from "@material-ui/core";
import {
  Info as InfoIcon,
  GetApp,
  ArrowDownward,
  ArrowUpward,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import {
  Typography,
  Link,
  Chip,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { Paper } from "components";
import palette from "theme/palette";
import ReactGA from "react-ga";
import styles from "./styles";
import { timeFormatter } from "../../../../helpers/time";
import { getSpeakerString } from "helpers/meetingParticipants";
import { updateMeetingTranscript } from "services/speech";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { Timeline } from "@material-ui/lab";
import { conversationHighlightTypes } from "helpers";
import {
  TranscriptSpeakerSegment,
  SpeakerMenu,
  HighlightMenu,
  ClipCreateForm,
} from "./components/";
import theme from "theme";

class Transcript extends Component {
  transcript = "";
  searchResults = [];
  searchExactResults = [];
  searchExactResultsSentenceCount = 0;
  searchExactResultsIndexElementToSentence = {};
  searchExactResultsIndexElementToElementInSentence = {};
  transcriptResults = [];
  transcriptSpeakerTags = new Set();
  initWithTranscriptSearchValue = "";
  segmentRefs = {};
  scrollableParentContainer = React.createRef();
  transcriptBodyRef = React.createRef();
  stickyObserver = null;
  isObserving = false;
  timeSpentHover = new Date();

  state = {
    searchValue: "",
    showSearchCount: false,
    searchExactResultsCount: 0,
    searchExactResultsSelected: 0,
    showFullTranscript: true,
    talkingPoints: [],
    isSharedMeetingView: false,
    transcriptSpeakerSegments: [],
    navigableInsights: [],
    currentInsight: -1,
    speakerMenuAnchorEl: null,
    currentSpeakerTag: -1,
    currentTimestamps: [],
    showClipForm: false,
    currentStartTime: -1,
    currentEndTime: -1,
  };

  componentDidMount() {
    this.getTranscriptSpeakerSegments(this.props.resultsWithMetadata);

    var talkingPoints = this.props.data.freq_noun_phrases;
    if (this.props.initWithTranscriptSearchValue) {
      this.setState({
        talkingPoints: talkingPoints,
        searchValue: this.props.initWithTranscriptSearchValue,
        showFullTranscript: false,
      });
      this.performSearch(this.props.initWithTranscriptSearchValue);
    } else {
      this.setState({
        talkingPoints: talkingPoints,
      });
    }

    if (this.props.isSharedMeetingView) {
      this.setState({ isSharedMeetingView: this.props.isSharedMeetingView });
    }

    // This prevents the actions menu from appearing when highlighting
    if (this.transcriptBodyRef.current) {
      this.transcriptBodyRef.current.addEventListener(
        "mousedown",
        this.disableActionsMenu
      );
      this.transcriptBodyRef.current.addEventListener(
        "mouseup",
        this.enableActionsMenu
      );
    }
  }

  componentDidUpdate() {
    this.updateNavigableInsights();
  }

  componentWillUnmount() {
    if (this.transcriptBodyRef.current) {
      this.transcriptBodyRef.current.removeEventListener(
        "mousedown",
        this.disableActionsMenu
      );
      this.transcriptBodyRef.current.removeEventListener(
        "mouseup",
        this.enableActionsMenu
      );
    }
  }

  updateNavigableInsights = () => {
    var navigableInsights = [];
    if (
      this.props.selectedHighlights === conversationHighlightTypes.questions
    ) {
      navigableInsights = this.props.resultsWithMetadata.filter(
        (sentence) => sentence.isQuestion
      );
    } else if (
      this.props.selectedHighlights === conversationHighlightTypes.takeaways
    ) {
      navigableInsights = this.props.resultsWithMetadata.filter(
        (sentence) => sentence.isTakeaway
      );
    } else if (this.props.selectedTopic) {
      navigableInsights = this.props.resultsWithMetadata.filter(
        (sentence) => sentence.containsTopics[this.props.selectedTopic] ?? false
      );
    }
    var isEqual = true;
    this.setState(
      (prevState) => {
        if (
          prevState.navigableInsights.length === 0 &&
          navigableInsights.length === 0
        )
          return;
        if (prevState.navigableInsights.length !== navigableInsights.length) {
          isEqual = false;
          return { navigableInsights: navigableInsights, currentInsight: -1 };
        }
        prevState.navigableInsights.forEach((element, index) => {
          if (!this.sentenceEquals(element, navigableInsights[index]))
            isEqual = false;
        });
        if (!isEqual) {
          return { navigableInsights: navigableInsights, currentInsight: -1 };
        }
      },
      () => {
        if (!isEqual) {
          this.goToNextInsight();
        }
      }
    );
  };

  sentenceEquals(a, b) {
    return a.timestamp === b.timestamp;
  }

  getTranscriptSpeakerSegments = (transcriptResults) => {
    if (transcriptResults != null && transcriptResults.length > 0) {
      var currentSpeakerTag = transcriptResults[0].speaker_tag;
      var transcriptSpeakerTags = new Set();
      var transcriptSpeakerSegments = [];
      var segmentByTimestamp = {};
      transcriptSpeakerTags.add(currentSpeakerTag);
      var currentTranscript = [];
      var currentSentiment = 0;
      transcriptResults.forEach((result, index) => {
        if (currentSpeakerTag === result.speaker_tag) {
          currentTranscript.push(result);
          currentSentiment += result.polarity ?? 0;
        } else {
          transcriptSpeakerSegments.push({
            speaker: currentSpeakerTag,
            transcript: currentTranscript,
            sentiment:
              currentTranscript.length > 0
                ? currentSentiment / currentTranscript.length
                : 0,
          });
          currentSpeakerTag = result.speaker_tag;
          currentTranscript = [];
          currentSentiment = 0;
          currentTranscript.push(result);
          currentSentiment += result.polarity ?? 0;
          transcriptSpeakerTags.add(currentSpeakerTag);
        }

        if (index === transcriptResults.length - 1) {
          transcriptSpeakerSegments.push({
            speaker: currentSpeakerTag,
            transcript: currentTranscript,
            sentiment:
              currentTranscript.length > 0
                ? currentSentiment / currentTranscript.length
                : 0,
          });
          currentTranscript.forEach((element) => {
            segmentByTimestamp[element.timestamp] =
              currentTranscript[0].timestamp;
          });
        }
      });
      this.transcriptResults = transcriptResults;
      this.setState({
        transcriptSpeakerSegments: transcriptSpeakerSegments,
        segmentByTimestamp: segmentByTimestamp,
      });
      this.transcriptSpeakerTags = transcriptSpeakerTags;
      this.transcript = this.props.data.transcript;
    }
  };

  searchTranscript = (q) => {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map((s) => s.trim())
      .filter((s) => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );

    var results = [];
    this.transcriptResults.forEach((element, index) => {
      var sentence = element.sentence;
      var timestamp = element.timestamp;
      if (searchRegex.test(sentence)) {
        results.push({
          sentence: sentence,
          timestamp: timestamp,
        });
      }
    });
    return results;
  };

  searchTranscriptSet = (q) => {
    function getMatchedIndicesOf(searchStr, str, caseSensitive) {
      const searchStrLen = searchStr.length;
      if (searchStrLen == 0) {
        return [];
      }
      let startIndex = 0,
        index,
        indices = [];
      if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
      }
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }
      return indices;
    }

    let resultSearchSet = [];
    let wordSearchCounter = 0;
    let elementToSentence = [];
    let elementToElementInSentence = [];
    this.state.transcriptSpeakerSegments.forEach((segment, segmentIndex) => {
      segment.transcript.forEach((element, index) => {
        const indices = getMatchedIndicesOf(q, element.sentence);
        if (indices.length !== 0) {
          wordSearchCounter += indices.length;
          elementToSentence = elementToSentence.concat(
            Array(indices.length).fill(resultSearchSet.length)
          );
          elementToElementInSentence = elementToElementInSentence.concat([
            ...Array(indices.length).keys(),
          ]);
          resultSearchSet.push({
            segmentIndex: segmentIndex,
            sentenceIndexInSegment: index,
            timestamp: element.timestamp,
            foundAtIndices: indices,
          });
        }
      });
    });
    elementToSentence = { ...elementToSentence };
    elementToElementInSentence = { ...elementToElementInSentence };
    return {
      wordSearchCounter,
      resultSearchSet,
      elementToSentence,
      elementToElementInSentence,
    };
  };

  resetTranscriptBody = () => {
    this.searchResults = [];
    this.searchExactResults = [];
    this.searchExactResultsSentenceCount = 0;
    this.searchExactResultsIndexElementToSentence = {};
    this.searchExactResultsIndexElementToElementInSentence = {};
    this.setState({
      showSearchCount: false,
      searchExactResultsCount: 0,
      searchExactResultsSelected: 0,
      searchValue: "",
      showFullTranscript: true,
    });
  };

  performSearch = (q) => {
    const results = this.searchTranscript(q);
    this.searchResults = results;
  };

  performSearchExact = (q) => {
    const {
      wordSearchCounter,
      resultSearchSet,
      elementToSentence,
      elementToElementInSentence,
    } = this.searchTranscriptSet(q);

    this.searchExactResultsSentenceCount = resultSearchSet.length;
    this.searchExactResults = resultSearchSet;
    this.searchExactResultsIndexElementToSentence = elementToSentence;
    this.searchExactResultsIndexElementToElementInSentence = elementToElementInSentence;
    if (wordSearchCounter <= 0) {
      this.setState({
        searchExactResultsCount: wordSearchCounter,
        searchExactResultsSelected: 0,
        showSearchCount: false,
      });
    } else {
      this.setState({
        searchExactResultsCount: wordSearchCounter,
        searchExactResultsSelected: 1,
        showSearchCount: true,
      });
      this.goToInsight(
        resultSearchSet[this.searchExactResultsIndexElementToSentence[0]]
          .timestamp
      );
    }
  };

  goToSearchResult = (next = true) => {
    const prevSelectedSearch = this.state.searchExactResultsSelected;
    const searchCount = this.state.searchExactResultsCount;
    let selected = 0;
    let searchAction = "";
    if (next) {
      selected =
        prevSelectedSearch !== searchCount ? prevSelectedSearch + 1 : 1;
      searchAction = "Go to next search result clicked";
    } else {
      selected =
        prevSelectedSearch !== 1 ? prevSelectedSearch - 1 : searchCount;
      searchAction = "Go to prev search result clicked";
    }
    this.setState({
      searchExactResultsSelected: selected,
    });
    this.goToInsight(
      this.searchExactResults[
        this.searchExactResultsIndexElementToSentence[selected - 1]
      ].timestamp
    );
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: searchAction,
    });
  };

  // Highly Coupled with onClick for TalkingPoint, default parameter used for ReactGA
  handleSearchRequest = (q, isTalkingPointClicked = false) => {
    if (q.trim() === "") {
      this.resetTranscriptBody();
      return;
    } else {
      this.performSearch(q);
      this.setState({ showFullTranscript: false });
    }
    let eventAction = isTalkingPointClicked
      ? "User Clicked Talking Point"
      : "User executed transcript search";
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: eventAction,
    });
  };

  addSearchResultWithTimestamp = (result, searchValues) => {
    const classes = this.props.classes;
    var sentence = result.sentence + " ";

    return (
      <div className={classes.searchResult}>
        <br />
        <Highlighter
          highlightStyle={{
            backgroundColor: "transparent",
            color: palette.danger.main,
            fontWeight: "bold",
          }}
          searchWords={searchValues}
          autoEscape={false}
          textToHighlight={sentence}
        />
        <Typography variant="body1" display="inline">
          ({this.setTimestampLink(result.timestamp)})
        </Typography>
      </div>
    );
  };

  handleTranscriptSearchResultTimestampClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Transcript Search Result",
    });
  };

  setTimestampLink = (timestamp) => {
    return (
      <Link
        component="button"
        onClick={() =>
          this.handleTranscriptSearchResultTimestampClicked(timestamp)
        }
      >
        {timeFormatter(timestamp)}
      </Link>
    );
  };

  addSpeakerSegment = (
    segment,
    segmentEndTime,
    prevSentiment,
    selectedSearchResult
  ) => {
    const classes = this.props.classes;
    const newRef = React.createRef();
    this.segmentRefs[segment.transcript[0].timestamp] = newRef;
    const currentInsight = this.state.navigableInsights[
      this.state.currentInsight
    ];
    const backgroundColor = theme.palette.common.white;

    return (
      <div ref={newRef} style={{ backgroundColor }}>
        <TranscriptSpeakerSegment
          className={classes.speakerSegment}
          speakerSegment={segment}
          segmentEndTime={segmentEndTime}
          data={this.props.data}
          setMediaPlayerCurrentTimeAndPlay={
            this.props.setMediaPlayerCurrentTimeAndPlay
          }
          sentenceUpdateHandler={this.handleSentenceUpdate}
          openCreateBookmark={this.props.openCreateBookmark}
          editSentence={this.state.editSentence}
          handleSentenceEditCancel={this.handleSentenceEditCancel}
          selectedTopic={this.props.selectedTopic}
          selectedHighlights={this.props.selectedHighlights}
          selectedActionItem={this.props.selectedActionItem}
          selectedHighlight={this.props.selectedHighlight}
          currentInsight={currentInsight}
          handleSentenceEdit={this.handleSentenceEdit}
          handleOpenBookmark={this.handleOpenBookmark}
          handleOnClickSpeakerAttribution={this.handleOnClickSpeakerAttribution}
          openClipCreateForm={this.openClipCreateForm}
          handleOpenActionItemForm={this.handleOpenActionItemForm}
          isSharedMeetingView={this.state.isSharedMeetingView}
          handleOpenTakeaway={this.handleOpenTakeaway}
          mapParticipantResponseHandler={this.mapParticipantResponseHandler}
          speakerTags={this.transcriptSpeakerTags}
          backgroundColor={backgroundColor}
          searchValue={this.state.searchValue}
          selectedSearchResult={selectedSearchResult}
          openSnackbar={this.props.openSnackbar}
        />
      </div>
    );
  };

  updateTranscript = (newTranscriptResults, timestamps) => {
    updateMeetingTranscript(
      this.props.data.meeting_id,
      newTranscriptResults,
      timestamps
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
          }
          this.props.transcriptUpdateFailureHandler();
          ReactGA.event({
            category: "Failure",
            action: "Update meeting transcript API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Update meeting transcript API success",
          });
          this.getTranscriptSpeakerSegments(response.data.transcript_results);
          this.props.transcriptUpdateResponseHandler(
            response.data.transcript_results
          );
          this.props.questionUpdateResponseHandler(response.data.questions);
        }
      }.bind(this)
    );
  };

  handleSpeakerUpdate = (speakerTag) => {
    const currentTimestamps = this.state.currentTimestamps;
    if (!this.transcriptSpeakerTags.has(speakerTag)) {
      speakerTag = Math.max(...this.transcriptSpeakerTags) + 1;
    }
    var updatedTranscriptResults = [...this.transcriptResults];
    for (let timestamp of currentTimestamps) {
      const idx = this.props.data.transcriptionResultsMap[timestamp.toString()];
      if (idx !== -1) {
        updatedTranscriptResults[idx].speaker_tag = speakerTag;
      }
    }
    this.updateTranscript(updatedTranscriptResults, currentTimestamps);
    this.closeSpeakerMenu();
  };

  handleSentenceUpdate = (updatedSentence) => {
    var updatedTranscriptResults = this.transcriptResults.map(
      (currentSentence) => {
        if (
          updatedSentence.speaker_tag === currentSentence.speaker_tag &&
          updatedSentence.timestamp === currentSentence.timestamp
        ) {
          currentSentence.sentence = updatedSentence.sentence;
        }
        return currentSentence;
      }
    );
    var filteredTranscriptResults = updatedTranscriptResults.filter(
      (sentence) => {
        if (sentence.sentence) {
          return true;
        } else {
          return false;
        }
      }
    );
    this.updateTranscript(filteredTranscriptResults, [
      updatedSentence.timestamp,
    ]);
  };

  handleSentenceEdit = (sentence) => {
    this.setState({
      editSentence: sentence,
    });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Edit Transcript",
      label: "Transcript",
    });
  };

  handleSentenceEditCancel = () => {
    this.setState({
      editSentence: null,
    });
  };

  handleOnClickSpeakerAttribution = (event, timestamp, speaker) => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Edit Speaker Attribution",
      label: "Transcript",
    });
    this.openSpeakerMenu(event.target, [timestamp], speaker);
  };

  handleOpenBookmark = (startTime, endTime) => {
    this.props.openCreateBookmark(startTime, endTime);
  };

  handleOpenActionItemForm = (sentence) => {
    this.props.openActionItemForm(sentence);
  };

  handleOpenTakeaway = (transcriptResult, sentence, endTime) => {
    this.props.openTakeaway(transcriptResult, sentence, endTime);
  };

  handleCreateClip = (clipName) => (event) => {
    event.preventDefault();
    const { currentStartTime, currentEndTime } = this.state;
    this.props
      .createClip(currentStartTime, currentEndTime, clipName)
      .then(() => this.closeClipCreateForm());
  };

  handleOnMouseEnter = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeave = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Hovered Transcript",
      value: timeSpent,
    });
  };

  handleOnClick = () => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked a Sentence in Transcript",
    });
  };

  openSpeakerMenu = (menuAnchorEl, timestamps, speakerTag) => {
    this.setState({
      speakerMenuAnchorEl: menuAnchorEl,
      currentSpeakerTag: speakerTag,
      currentTimestamps: timestamps,
    });
  };

  closeSpeakerMenu = () => {
    this.setState({
      speakerMenuAnchorEl: null,
      currentSpeakerTag: -1,
      currentTimestamps: [],
    });
  };

  renderSpeakerMenu = () => {
    const speakerTags = [...this.transcriptSpeakerTags];
    return (
      <SpeakerMenu
        menuAnchorEl={this.state.speakerMenuAnchorEl}
        data={this.props.data}
        speakerTags={speakerTags}
        handleSpeakerUpdate={this.handleSpeakerUpdate}
        closeSpeakerMenu={this.closeSpeakerMenu}
        currentSpeakerTag={this.state.currentSpeakerTag}
      />
    );
  };

  openClipCreateForm = (event, startTime, endTime) => {
    this.setState({
      showClipForm: true,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      currentStartTime: startTime,
      currentEndTime: endTime,
    });
  };

  closeClipCreateForm = () => {
    this.setState({
      showClipForm: false,
      mouseX: null,
      mouseY: null,
      currentStartTime: -1,
      currentEndTime: -1,
    });
  };

  renderClipCreateForm = () => {
    return (
      <ClipCreateForm
        open={this.state.showClipForm}
        mouseX={this.state.mouseX}
        mouseY={this.state.mouseY}
        onClose={this.closeClipCreateForm}
        onCreateClip={this.handleCreateClip}
      />
    );
  };

  renderHighlightMenu = () => {
    if (this.props.isSharedMeetingView) return <></>;
    return (
      <HighlightMenu
        transcriptBody={this.transcriptBodyRef.current}
        updateMousePosition={(mouseX, mouseY) => {
          this.setState({
            mouseX: mouseX,
            mouseY: mouseY,
          });
        }}
        mouseX={this.state.mouseX}
        mouseY={this.state.mouseY}
        data={this.props.data}
        updateTranscript={this.updateTranscript}
        openClipCreateForm={this.openClipCreateForm}
        openSpeakerMenu={this.openSpeakerMenu}
        openAddActionItem={this.handleOpenActionItemForm}
        openAddTakeaway={this.handleOpenTakeaway}
      />
    );
  };

  renderFullTranscript = () => {
    const { classes } = this.props;
    const selectedSearchResultIndex = this.state.searchExactResultsSelected;
    const selectedSearchElementIndexInSentence = this
      .searchExactResultsIndexElementToElementInSentence[
      selectedSearchResultIndex - 1
    ];
    const selectedSearchResult = this.searchExactResults[
      this.searchExactResultsIndexElementToSentence[
        selectedSearchResultIndex - 1
      ]
    ];
    this.segmentRefs = {};
    return (
      <Timeline className={classes.timelineRoot}>
        {this.state.transcriptSpeakerSegments.map((segment, idx) => {
          const nextSegment = this.state.transcriptSpeakerSegments[idx + 1];
          let segmentEndTime = 0;
          if (nextSegment && nextSegment.transcript.length > 0) {
            segmentEndTime = nextSegment.transcript[0].timestamp;
          } else if (segment.transcript.length > 0) {
            const lastIdx = segment.transcript.length - 1;
            segmentEndTime = segment.transcript[lastIdx].timestamp + 30;
          }
          let prevSentiment;
          if (idx > 0) {
            prevSentiment = this.state.transcriptSpeakerSegments[idx - 1]
              .sentiment;
          }
          let selectedSearchInfo = null;
          if (selectedSearchResult !== undefined) {
            if (idx === selectedSearchResult.segmentIndex) {
              selectedSearchInfo = {
                sentenceIndex: selectedSearchResult.sentenceIndexInSegment,
                elementIndex: selectedSearchElementIndexInSentence,
              };
            }
          }
          return this.addSpeakerSegment(
            segment,
            segmentEndTime,
            prevSentiment,
            selectedSearchInfo
          );
        })}
      </Timeline>
    );
  };

  renderTranscriptBody = (searchValue) => {
    var searchValues = searchValue.split(" ");
    if (
      this.searchResults.length === 0 &&
      this.state.showFullTranscript === true
    ) {
      if (this.state.transcriptSpeakerSegments.length === 0) {
        return this.transcript;
      }
      return this.renderFullTranscript();
    } else {
      return (
        <div>
          {this.searchResults.map((e) => {
            return this.addSearchResultWithTimestamp(e, searchValues);
          })}
        </div>
      );
    }
  };

  downloadFullTranscript = () => {
    var transcript = "";
    var filename = "transcript.txt";
    if (this.props.meetingTitle) {
      filename = this.props.meetingTitle + ".txt";
    }

    this.state.transcriptSpeakerSegments.map((e) => {
      var speaker_transcript =
        getSpeakerString(
          e.speaker,
          this.props.data.participants_details,
          true
        ) + ":\n";
      e.transcript.forEach((element) => {
        speaker_transcript += element.sentence + "\n";
      });
      transcript += speaker_transcript + "\n";
    });

    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(transcript)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }

    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User downloaded transcript",
      label: "Transcript",
    });
  };

  searchTalkingPoint = (word) => {
    this.setState({
      searchValue: word,
    });
    this.handleSearchRequest(word, true);
  };

  addTalkingPoints = () => {
    if (
      this.state.talkingPoints === null ||
      this.state.talkingPoints.length === null ||
      this.state.talkingPoints.length === 0
    ) {
      return this.addEmptyState();
    }
    return this.state.talkingPoints.map((word, index, { length }) => {
      return (
        <Chip
          label={word}
          color="primary"
          onClick={() => this.searchTalkingPoint(word)}
        />
      );
    });
  };

  addEmptyState = () => {
    const { classes } = this.props;
    return (
      <div>
        <Typography className={classes.phrase} variant="body1">
          It didn't seem like there were any major talking points from the
          meeting!
        </Typography>
      </div>
    );
  };

  scroll = (element, container, location = "top", offset = 0) => {
    if (location === "top") {
      container.scrollTop = element.offsetTop - container.offsetTop + offset;
    } else if (location === "bottom") {
      const offsetBottom = element.offsetTop + element.offsetHeight;
      container.scrollTop =
        offsetBottom - container.offsetHeight - container.offsetTop;
    }
  };

  goToPreviousInsight = () => {
    var prevInsightIndex = this.state.currentInsight - 1;
    if (this.state.navigableInsights.length === 0) {
      return;
    } else if (prevInsightIndex === -1) {
      prevInsightIndex = this.state.navigableInsights.length - 1;
    }
    const nextInsight = this.state.navigableInsights[prevInsightIndex];
    const [segment, index] = this.findSegmentForSentence(nextInsight);
    const nextRef = this.segmentRefs[segment.transcript[0].timestamp];
    const sentence = nextRef.current.querySelector(
      `[class*="timestamp-${segment.transcript[index].timestamp}"]`
    );
    this.scroll(
      nextRef.current,
      this.scrollableParentContainer.current,
      "top",
      sentence.offsetTop - 20
    );
    this.setState({ currentInsight: prevInsightIndex });
  };

  goToNextInsight = () => {
    var nextInsightIndex = this.state.currentInsight + 1;
    if (this.state.navigableInsights.length === 0) {
      return;
    } else if (this.state.navigableInsights.length <= nextInsightIndex) {
      nextInsightIndex = 0;
    }
    const nextInsight = this.state.navigableInsights[nextInsightIndex];
    const [segment, index] = this.findSegmentForSentence(nextInsight);
    const nextRef = this.segmentRefs[segment.transcript[0].timestamp];
    const sentence = nextRef.current.querySelector(
      `[class*="timestamp-${segment.transcript[index].timestamp}"]`
    );
    this.scroll(
      nextRef.current,
      this.scrollableParentContainer.current,
      "top",
      sentence.offsetTop - 20
    );
    this.setState({ currentInsight: nextInsightIndex });
  };

  goToActionItem = (actionItem) => {
    const [segment, _] = this.findSegmentForSentence(actionItem) ?? [];
    if (!segment) return;
    const nextRef = this.segmentRefs[segment.transcript[0].timestamp];
    this.scroll(
      nextRef.current,
      this.scrollableParentContainer.current,
      "bottom"
    );
  };

  goToInsight = (timestamp) => {
    const [segment, index] = this.findSegmentForTimestamp(timestamp);
    const nextRef = this.segmentRefs[segment.transcript[0].timestamp];
    const sentence = nextRef.current.querySelector(
      `[class*="timestamp-${segment.transcript[index].timestamp}"]`
    );
    this.scroll(
      nextRef.current,
      this.scrollableParentContainer.current,
      "top",
      sentence.offsetTop - 20
    );
  };

  findSegmentForSentence(sentence) {
    return this.findSegmentForTimestamp(sentence.timestamp);
  }

  findSegmentForTimestamp(timestamp) {
    if (timestamp == null) {
      return;
    }
    const fixedTimestamp = timestamp.toFixed(2);
    for (var i = 0; i < this.state.transcriptSpeakerSegments.length; i++) {
      const segment = this.state.transcriptSpeakerSegments[i];
      for (var j = 0; j < segment.transcript.length; j++) {
        if (segment.transcript[j].timestamp == null) {
          continue;
        }
        if (segment.transcript[j].timestamp.toFixed(2) === fixedTimestamp) {
          return [segment, j];
        }
      }
    }
  }

  enableActionsMenu = () => {
    if (this.transcriptBodyRef.current) {
      const transcriptSentenceEls = this.transcriptBodyRef.current.querySelectorAll(
        ".transcript-sentence"
      );
      for (let el of transcriptSentenceEls) {
        el.classList.add("actions-menu-enabled");
      }
    }
  };

  disableActionsMenu = () => {
    if (this.transcriptBodyRef.current) {
      const transcriptSentenceEls = this.transcriptBodyRef.current.querySelectorAll(
        ".transcript-sentence"
      );
      for (let el of transcriptSentenceEls) {
        el.classList.remove("actions-menu-enabled");
      }
    }
  };

  renderTranscript = () => {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    var searchValue = this.state.searchValue;
    var showSearchBar = true;
    var showSearchCount = this.state.showSearchCount;
    var searchExactResultsSelected = this.state.searchExactResultsSelected;
    var searchExactResultsCount = this.state.searchExactResultsCount;

    // If a search term was passed in the props then we want to do a search when the transcript dialog is opened instead of presenting the whole transcript
    if (
      searchValue == "" &&
      this.props.searchWord != null &&
      this.props.searchWord != ""
    ) {
      searchValue = this.props.searchWord;
      showSearchBar = false;
      this.performSearch(searchValue);
    }

    const showInsightNavigation =
      this.props.selectedHighlights || this.props.selectedTopic;

    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.header}>
          <div className={classes.headerWithIcon}>
            <Typography className={classes.title} variant="h5">
              POWER TRANSCRIPT
            </Typography>
            <Tooltip
              title={
                <span>
                  Please note that the accuracy of the transcription depends on
                  various factors. As a result, there may be some inaccuracies
                  in the transcript occasionally.{" "}
                  <b>
                    Hover over a sentence to edit, reassign, or bookmark parts
                    of the transcript.
                  </b>
                </span>
              }
            >
              <InfoIcon className={classes.transcriptInfoIcon} />
            </Tooltip>
          </div>
          <Box>
            {showInsightNavigation && (
              <Tooltip title="Previous Insight">
                <IconButton
                  className={classes.overflowButton}
                  onClick={this.goToPreviousInsight}
                >
                  <ArrowUpward />
                </IconButton>
              </Tooltip>
            )}
            {showInsightNavigation && (
              <Tooltip title="Next Insight">
                <IconButton
                  className={classes.overflowButton}
                  onClick={this.goToNextInsight}
                >
                  <ArrowDownward />
                </IconButton>
              </Tooltip>
            )}
            {this.props.data.is_participant_editing_enabled && (
              <Tooltip title="Download Transcript">
                <IconButton
                  className={classes.overflowButton}
                  onClick={this.downloadFullTranscript}
                >
                  <GetApp className={classes.transcriptDownloadIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </div>
        {showSearchBar && (
          <SearchBar
            className={classes.searchBar}
            value={searchValue}
            onChange={(newValue) => {
              this.performSearchExact(newValue);
              this.setState({ searchValue: newValue });
            }}
            onCancelSearch={() => this.resetTranscriptBody()}
            placeholder="Press Enter to Search"
            endAdornment={
              <InputAdornment position="end">
                {showSearchCount && (
                  <Typography
                    className={classes.searchCounter}
                    variant="caption"
                  >
                    {searchExactResultsSelected}/{searchExactResultsCount}
                  </Typography>
                )}
                <div class={classes.searchButtonWrapper}>
                  <div class={classes.vertLine}></div>
                  <IconButton
                    className={classes.searchUp}
                    onClick={() => this.goToSearchResult(false)}
                    disabled={searchExactResultsCount === 0}
                  >
                    <ExpandLess />
                  </IconButton>
                  <IconButton
                    className={classes.searchDown}
                    onClick={this.goToSearchResult}
                    disabled={searchExactResultsCount === 0}
                  >
                    <ExpandMore />
                  </IconButton>
                </div>
              </InputAdornment>
            }
          />
        )}
        <div
          className={classes.paperBody}
          ref={this.scrollableParentContainer}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
        >
          <Typography
            variant="body1"
            className={classes.transcriptBody}
            ref={this.transcriptBodyRef}
            onClick={this.handleOnClick}
          >
            {this.renderTranscriptBody(searchValue)}
          </Typography>
        </div>
      </Paper>
    );
  };

  mapParticipantResponseHandler = (id, speaker_tag) => {
    this.props.mapParticipantResponseHandler(id, speaker_tag);
    this.getTranscriptSpeakerSegments(this.props.data.transcript_results);
  };

  render() {
    return (
      <>
        {this.renderTranscript()}
        {this.renderSpeakerMenu()}
        {this.renderHighlightMenu()}
        {this.renderClipCreateForm()}
      </>
    );
  }
}

Transcript.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Transcript);
