import theme from "theme";

export const labelDescriptors = {
  needsWork: " - Needs Work",
  notBad: " - Not Bad",
  great: " - Great"
}

export const subScoreLabels = {
  performance: "My Performance",
  efficiency: "Meeting Efficiency",
  engagement: "Audience Engagement",
  feedback: "Attendee Feedback",
};

const toneLabels = {
  unvaried: "no_emotion",
  conversational: "conversational",
  passionate: "passionate"
};

const energyLabels = {
  very_low: "very low",
  low: "low",
  moderate: "moderate",
  high: "high",
  very_high: "very high"
};

const fillerLabels = {
  low: "filler_low",
  moderate: "filler_moderate",
  high: "filler_high"
};

const topicsDiscussedLabels = {
  none: "no_agenda_topics",
  some: "missed_some_topics",
  all: "discussed_all_topics"
};

const actionItemLabels = {
  has: "has_action_items",
  none: "no_action_items"
};

const inactiveParticipantLabels = {
  none: "no_inactive_participants",
  some: "some_inactive_participants",
  most: "most_inactive_participants"
};

const speakerDurationLabels = {
  good: "duration_good",
  ok: "duration_ok",
  poor: "duration_poor"
};

const sentimentLabels = {
  positive: "sentiment_positive",
  neutral: "sentiment_neutral",
  negative: "sentiment_negative"
};

const feedbackLabels = {
  good: "feedback_good",
  ok: "feedback_ok",
  poor: "feedback_poor"
}

const labelDescriptorsMap = {
  [energyLabels.very_low]: labelDescriptors.needsWork,
  [energyLabels.low]: labelDescriptors.needsWork,
  [energyLabels.moderate]: labelDescriptors.notBad,
  [energyLabels.high]: labelDescriptors.great,
  [energyLabels.very_high]: labelDescriptors.great,
  [toneLabels.unvaried]: labelDescriptors.needsWork,
  [toneLabels.conversational]: labelDescriptors.notBad,
  [toneLabels.passionate]: labelDescriptors.great,
  [fillerLabels.high]: labelDescriptors.needsWork,
  [fillerLabels.moderate]: labelDescriptors.notBad,
  [fillerLabels.low]: labelDescriptors.great,
  [topicsDiscussedLabels.none]: labelDescriptors.needsWork,
  [topicsDiscussedLabels.some]: labelDescriptors.notBad,
  [topicsDiscussedLabels.all]: labelDescriptors.great,
  [actionItemLabels.none]: labelDescriptors.needsWork,
  [actionItemLabels.has]: labelDescriptors.great,
  [inactiveParticipantLabels.none]: labelDescriptors.great,
  [inactiveParticipantLabels.some]: labelDescriptors.notBad,
  [inactiveParticipantLabels.most]: labelDescriptors.needsWork,
  [speakerDurationLabels.poor]: labelDescriptors.needsWork,
  [speakerDurationLabels.ok]: labelDescriptors.notBad,
  [speakerDurationLabels.good]: labelDescriptors.great,
  [sentimentLabels.negative]: labelDescriptors.needsWork,
  [sentimentLabels.neutral]: labelDescriptors.notBad,
  [sentimentLabels.positive]: labelDescriptors.great,
  [feedbackLabels.poor]: labelDescriptors.needsWork,
  [feedbackLabels.ok]: labelDescriptors.notBad,
  [feedbackLabels.good]: labelDescriptors.great,
}

export const getLabelDescriptor = (label) => {
  if (labelDescriptorsMap.hasOwnProperty(label)){
    return labelDescriptorsMap[label];
  }
  return "";
}

export const getEnergyCopy = (label, isAudience) => {
  // Labels "very low" and "very high" have been removed
  // For backwards compability reasons though, keep these labels around
  // and set them to the same copy as "low" and "high" respectively
  if (isAudience) {
    switch (label) {
      case energyLabels.very_low:
        return "Low. Speak louder to be understood.";
      case energyLabels.low:
        return "Low. Speak louder to be understood.";
      case energyLabels.moderate:
        return "Moderate. Speak just a bit louder.";
      case energyLabels.high:
        return "High. Keep it up!";
      case energyLabels.very_high:
        return "High. Keep it up!";
      default:
        return "";
    }
  } else {
    switch (label) {
      case energyLabels.very_low:
        return "Low. Speak louder to be understood.";
      case energyLabels.low:
        return "Low. Speak louder to be understood.";
      case energyLabels.moderate:
        return "Moderate. Speak just a bit louder.";
      case energyLabels.high:
        return "High. Keep it up!";
      case energyLabels.very_high:
        return "High. Keep it up!";
      default:
        return "";
    }
  }
};

export const getAvgEnergyCopy = (label, isAudience) => {
  // Labels "very low" and "very high" have been removed
  // For backwards compability reasons though, keep these labels around
  // and set them to the same copy as "low" and "high" respectively
  if (isAudience) {
    switch (label) {
      case energyLabels.very_low:
        return "Low. Speak louder to be understood.";
      case energyLabels.low:
        return "Low. Speak louder to be understood.";
      case energyLabels.moderate:
        return "Moderate. Speak just a bit louder.";
      case energyLabels.high:
        return "High. Keep it up!";
      case energyLabels.very_high:
        return "High. Keep it up!";
      default:
        return "";
    }
  } else {
    switch (label) {
      case energyLabels.very_low:
        return "Low. Speak louder in your meetings to be understood.";
      case energyLabels.low:
        return "Low. Speak louder in your meetings to be understood.";
      case energyLabels.moderate:
        return "Moderate. Speak just a bit louder in your meetings.";
      case energyLabels.high:
        return "High. Keep it up!";
      case energyLabels.very_high:
        return "High. Keep it up!";
      default:
        return "";
    }
  }
};

export const getToneCopy = (label, isAudience) => {
  if (isAudience) {
    switch (label) {
      case toneLabels.unvaried:
        return "Unvaried. Be more dynamic. Add emphasis for audience engagement.";
      case toneLabels.conversational:
        return "Conversational. Keep it up! Remember to add emphasis.";
      case toneLabels.passionate:
        return "Passionate. So much passion! Keep it up!";
      default:
        return "";
    }
  } else {
    switch (label) {
      case toneLabels.unvaried:
        return "Unvaried. Be more dynamic. Add emphasis in your delivery.";
      case toneLabels.conversational:
        return "Conversational. Keep it up! Add emphasis sometimes.";
      case toneLabels.passionate:
        return "Passionate. So much passion! Keep it up!";
      default:
        return "";
    }
  }
};

export const getAvgToneCopy = (label, isAudience) => {
  if (isAudience) {
    switch (label) {
      case toneLabels.unvaried:
        return "Unvaried. Be more dynamic. Add emphasis for audience engagement.";
      case toneLabels.conversational:
        return "Conversational. Keep it up! Remember to add emphasis.";
      case toneLabels.passionate:
        return "Passionate. So much passion! Keep it up!";
      default:
        return "";
    }
  } else {
    switch (label) {
      case toneLabels.unvaried:
        return "Unvaried. Be more dynamic. Add emphasis when you speak in meetings.";
      case toneLabels.conversational:
        return "Conversational. Keep it up! Add a bit more emphasis when you speak in meetings.";
      case toneLabels.passionate:
        return "Passionate. So much passion! Keep it up!";
      default:
        return "";
    }
  }
}

export const getFillerCopy = (label, wpm) => {
  switch (label) {
    case fillerLabels.low:
      return `${Math.round(wpm)} fillers/min. That's not many filler words. Keep it up!`;
    case fillerLabels.moderate:
      return `${Math.round(wpm)} fillers/min. That's a normal amount of filler words. Try pausing instead.`;
    case fillerLabels.high:
      return `${Math.round(wpm)} fillers/min. That's too many filler words. Try pausing instead.`;
    default:
      return "";
  }
};

export const getAvgFillerCopy = (label, wpm) => {
  switch (label) {
    case fillerLabels.low:
      return `${Math.round(wpm)} fillers/min. On average, that's not many filler words. Keep it up!`;
    case fillerLabels.moderate:
      return `${Math.round(wpm)} fillers/min. On average, that's a normal amount of filler words. Try pausing instead.`;
    case fillerLabels.high:
      return `${Math.round(wpm)} fillers/min. On average, that's too many filler words. Try pausing instead.`;
    default:
      return "";
  }
};

export const getAgendaTopicsCopy = (label) => {
  switch (label) {
    case topicsDiscussedLabels.none:
      return "Add an agenda next time to keep on topic.";
    case topicsDiscussedLabels.some:
      return "You missed some topics. Remember to stick to your agenda.";
    case topicsDiscussedLabels.all:
      return "You were on topic. Keep it up!";
    default:
      return "";
  }
};

export const getAvgAgendaTopicsCopy = (label) => {
  switch (label) {
    case topicsDiscussedLabels.none:
      return "Add agenda in your meetings to keep on topic.";
    case topicsDiscussedLabels.some:
      return "You missed some topics in your meetings. Remember to stick to your agenda.";
    case topicsDiscussedLabels.all:
      return "You were on topic in all your meetings. Keep it up!";
    default:
      return "";
  }
};

export const getActionItemsCopy = (label) => {
  switch (label) {
    case actionItemLabels.has:
      return "Meeting had action items. Keep it up!";
    case actionItemLabels.none:
      return "No action items. Have some to drive progress.";
    default:
      return "";
  }
};

export const getAvgActionItemsCopy = (label) => {
  switch (label) {
    case actionItemLabels.has:
      return "Your meetings had action items. Keep it up!";
    case actionItemLabels.none:
      return "There were no action items in your meetings. Have some to drive progress.";
    default:
      return "";
  }
};

export const getInactiveAttendeesCopy = (label) => {
  switch (label) {
    case inactiveParticipantLabels.none:
      return "Everyone was active. Keep it up!";
    case inactiveParticipantLabels.some:
      return "Some attendees were inactive. Did they need to be there?";
    case inactiveParticipantLabels.most:
      return "Most attendees were inactive. Did they need to be there?";
    default:
      return "";
  }
};

export const getAvgInactiveAttendeesCopy = (label) => {
  switch (label) {
    case inactiveParticipantLabels.none:
      return "Everyone in your meetings were active. Keep it up!";
    case inactiveParticipantLabels.some:
      return "Some attendees in your meetings were inactive. Did they need to be there?";
    case inactiveParticipantLabels.most:
      return "Most attendees in your meetings were inactive. Did they need to be there?";
    default:
      return "";
  }
};

export const getSpeakingDurationCopy = (label) => {
  switch (label) {
    case speakerDurationLabels.good:
      return "Everyone got airtime. Keep it up!";
    case speakerDurationLabels.ok:
      return "Most attendees were engaged. Keep it up!";
    case speakerDurationLabels.poor:
      return "A few people dominated the call. Encourage everyone to be heard.";
    default:
      return "";
  }
};

export const getAvgSpeakingDurationCopy = (label) => {
  switch (label) {
    case speakerDurationLabels.good:
      return "Everyone in your meetings got airtime. Keep it up!";
    case speakerDurationLabels.ok:
      return "Most attendees in your meetings were engaged. Keep it up!";
    case speakerDurationLabels.poor:
      return "A few people in your meetings dominated the call. Encourage everyone to be heard.";
    default:
      return "";
  }
};

export const getSentimentCopy = (label) => {
  switch (label) {
    case sentimentLabels.negative:
      return "Negative. Increase positivity for better outcomes.";
    case sentimentLabels.neutral:
      return "Neutral. Try increasing positivity next time.";
    case sentimentLabels.positive:
      return "Positive. Keep it up!";
    default:
      return "";
  }
};

export const getAvgSentimentCopy = (label) => {
  switch (label) {
    case sentimentLabels.negative:
      return "Negative. Increase positivity in your meetings for better outcomes.";
    case sentimentLabels.neutral:
      return "Neutral. Try increasing positivity in your meetings.";
    case sentimentLabels.positive:
      return "Positive. Keep it up!";
    default:
      return "";
  }
}

export const getMeetingNecessityCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "This meeting was unnecessary.";
    case feedbackLabels.ok:
      return "This meeting was necessary.";
    case feedbackLabels.good:
      return "This meeting was much needed!";
    default:
      return "";
  }
};

export const getAvgMeetingNecessityCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "These meetings were unnecessary.";
    case feedbackLabels.ok:
      return "These meetings were necessary.";
    case feedbackLabels.good:
      return "These meetings were much needed!";
    default:
      return "";
  }
};

export const getInviteeAttendanceCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "Attendance felt unnecessary.";
    case feedbackLabels.ok:
      return "Attendance felt somewhat needed.";
    case feedbackLabels.good:
      return "Attendance felt necessary and useful.";
    default:
      return "";
  }
};

export const getAvgInviteeAttendanceCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "Attendance in these meetings felt unnecessary.";
    case feedbackLabels.ok:
      return "Attendance in these meetings felt somewhat needed.";
    case feedbackLabels.good:
      return "Attendance in these meetings felt necessary and useful.";
    default:
      return "";
  }
};

export const getDecisionDrivenCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "Decisions were not made.";
    case feedbackLabels.ok:
      return "Some decisions were made.";
    case feedbackLabels.good:
      return "Decisions were made and helpful.";
    default:
      return "";
  }
};

export const getAvgDecisionDrivenCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "Few decisions were made in these meetings.";
    case feedbackLabels.ok:
      return "Some decisions were made in these meetings.";
    case feedbackLabels.good:
      return "Helpful decisions were made in these meetings.";
    default:
      return "";
  }
};

export const getProductiveMeetingCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "Meeting felt unproductive.";
    case feedbackLabels.ok:
      return "Meeting felt somewhat productive.";
    case feedbackLabels.good:
      return "Meeting felt highly productive!";
    default:
      return "";
  }
};

export const getAvgProductiveMeetingCopy = (label) => {
  switch (label) {
    case feedbackLabels.poor:
      return "These meetings felt unproductive.";
    case feedbackLabels.ok:
      return "These meetings felt somewhat productive.";
    case feedbackLabels.good:
      return "These meetings felt highly productive!";
    default:
      return "";
  }
};

export const getScoreColor = (score) => {
  if (score <= 3) {
    return theme.palette.danger.main;
  } else if (score <= 6) {
    return theme.palette.warning.main;
  } else {
    return theme.palette.success.main;
  }
};

export const hasMeetingScore = (meetingResult) => {
  return (
    meetingResult.analytics && 
    meetingResult.analytics.meeting_score &&
    meetingResult.analytics.meeting_score.overall_score !== -1
  );
};

export const showMeetingScore = (meetingResult, meetingStatus, showScoreToGuests) => {
  const userEmail = localStorage.getItem("email");
  let isGuest = userEmail === null || userEmail === undefined;
  return (
    meetingStatus === "completed" &&
    hasMeetingScore(meetingResult) &&
    (!isGuest || (showScoreToGuests && isGuest))
  );
};
