export default [
    {
      value: 'product',
      label: 'Product Demo'
    },
    {
      value: 'presentation',
      label: 'Presentation'
    },
    {
      value: 'sales',
      label: 'Sales'
    },
    {
      value: 'speech',
      label: 'Speech'
    },
    {
      value: 'generic',
      label: 'Generic'
    }
  ];