import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Typography, Tooltip } from "@material-ui/core";
import { Paper } from "components";
import { timeFormatterText, timeFormatter } from "../../../../helpers/time";
import { getSpeakerString } from "helpers/meetingParticipants";
import styles from "./styles";
import theme from "theme";
import { ResponsivePie } from "@nivo/pie";

class SpeakerBreakdown extends Component {
  speakers = [];
  totalDuration = 0;
  timeline = [];

  componentWillMount() {
    var participantsDetails = this.props.data.participants_details;
    var transcript = this.props.data.transcript_results;
    var currentSpeaker = 0;
    var duration = 0;
    var startTimestamp = 0;
    var endTimestamp = 0;

    var speakersMap = new Map();
    transcript.forEach((result, index) => {
      if (index === 0) {
        currentSpeaker = result.speaker_tag;
        startTimestamp = result.timestamp;
        this.totalDuration = result.timestamp;
      } else {
        this.totalDuration = result.timestamp;
        if (
          currentSpeaker !== result.speaker_tag ||
          index === transcript.length - 1
        ) {
          endTimestamp = result.timestamp;
          duration = endTimestamp - startTimestamp;

          var speakerKey = getSpeakerString(
            currentSpeaker,
            participantsDetails
          );
          if (speakersMap.has(speakerKey)) {
            var totalDuration = speakersMap.get(speakerKey).value + duration;
            speakersMap.set(speakerKey, {
              id: speakerKey,
              label: speakerKey,
              value: totalDuration,
            });
          } else {
            speakersMap.set(speakerKey, {
              id: speakerKey,
              label: speakerKey,
              value: duration,
            });
          }

          this.timeline.push({
            speaker: getSpeakerString(currentSpeaker, participantsDetails),
            timestamp: startTimestamp,
            duration: duration,
          });

          currentSpeaker = result.speaker_tag;
          startTimestamp = result.timestamp;
        }
      }
    });
    this.speakers = [...speakersMap.values()];
    this.speakers = this.speakers.sort(function(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }

  renderPieGraph = () => {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    const data = this.speakers;
    if (data.length === 0) {
      return <></>;
    }
    return (
      <Paper {...rest} className={rootClassName}>
        <div
          className={classes.content}
          style={{ height: 200 }}
          onMouseEnter={this.props.handleOnMouseEnter}
          onMouseLeave={() => this.props.handleOnMouseLeave("Speaker Pie")}
        >
          <ResponsivePie
            data={data}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.5}
            padAngle={1.5}
            cornerRadius={3}
            valueFormat={(val) => {
              return timeFormatterText(val);
            }}
            colors={{ scheme: "dark2" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            enableRadialLabels={false}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#FFFFFF"
            sliceLabel={(e) => {
              return ((e.value / this.totalDuration) * 100).toFixed(0) + "%";
            }}
            height={200}
          />
        </div>
      </Paper>
    );
  };

  renderTimeline = () => {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    const speakers = this.speakers.map((speaker) => speaker.id);
    const nivoDark2 = theme.palette.nivoDark2;
    return (
      <Paper
        {...rest}
        onMouseEnter={this.props.handleOnMouseEnter}
        onMouseLeave={() => this.props.handleOnMouseLeave("Speaker Timeline")}
        className={rootClassName}
      >
        <div className={classes.content + " " + classes.timelineContent}>
          {speakers.map((speaker, idx) => {
            const data = this.timeline.filter(
              (timelineObj) => timelineObj.speaker === speaker
            );
            const speakerColor = nivoDark2[idx % nivoDark2.length];
            return (
              <div className={classes.timelineContainer}>
                <Typography className="timelineSpeaker" variant="h5">
                  {speaker}
                </Typography>
                <div className={classes.timeline}>
                  {data.map((timelineObj) => {
                    const left =
                      (timelineObj.timestamp / this.totalDuration) * 100 + "%";
                    const width =
                      (timelineObj.duration / this.totalDuration) * 100 + "%";
                    return (
                      <Tooltip
                        title={timeFormatter(timelineObj.timestamp)}
                        placement="top"
                        arrow
                      >
                        <div
                          className={classes.speakerSegment}
                          style={{
                            left: left,
                            width: width,
                            backgroundColor: speakerColor,
                          }}
                          onClick={() =>
                            this.props.setMediaPlayerCurrentTimeAndPlay(
                              timelineObj.timestamp,
                              "Speaker Breakdown"
                            )
                          }
                        ></div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className={classes.timestampsContainer}>
            <div className={classes.startTimestamp}>{timeFormatter(0)}</div>
            <div className={classes.midTimestamp}>
              {timeFormatter(Math.floor(this.totalDuration / 2))}
            </div>
            <div className={classes.endTimestamp}>
              {timeFormatter(this.totalDuration)}
            </div>
          </div>
        </div>
      </Paper>
    );
  };

  renderToggle = () => {
    const { classes } = this.props;
    let speakers = this.speakers.map((speaker) => speaker.id);
    const nivoDark2 = theme.palette.nivoDark2;
    return (
      <>
        {speakers.map((speaker, idx) => {
          const speakerColor = nivoDark2[idx % nivoDark2.length];
          return (
            <div className={classes.speakerToggle}>
              <span style={{ backgroundColor: speakerColor }}></span> {speaker}
            </div>
          );
        })}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.widgetGrid}>
        <div className={classes.gridPie}>{this.renderPieGraph()}</div>
        <div className={classes.gridToggle}>{this.renderToggle()}</div>
        <div className={classes.gridTimeline}>{this.renderTimeline()}</div>
      </div>
    );
  }
}

SpeakerBreakdown.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpeakerBreakdown);
