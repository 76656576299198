import React, { Component } from "react";

import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Bookmarks as BookmarksIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  AssignmentTurnedIn,
  PriorityHigh,
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  InsertEmoticon as InsertEmoticonIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
  PriorityHigh as PriorityHighIcon,
  QuestionAnswer as QuestionAnswerIcon,
} from "@material-ui/icons";
import {
  withStyles,
  Typography,
  Link,
  IconButton,
  TextareaAutosize,
  Box,
  Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ReactGA from "react-ga";
import styles from "./styles";
import { getSpeakerString } from "helpers/meetingParticipants";
import { gaCategoryViewMeeting } from "helpers/gaUtil";
import {
  POSITIVE_TRANSCRIPT_THRESHOLD,
  NEGATIVE_TRANSCRIPT_THRESHOLD,
} from "helpers/meetingSentiment";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { timeFormatter } from "helpers/time";
import classNames from "classnames";
import { conversationHighlightTypes } from "helpers";
import { stringifyTimeRange } from "helpers/meetingTranscript";
import palette from "theme/palette";
import { mapParticipant } from "services/speech";

class TranscriptSpeakerSegment extends Component {
  state = {
    speakerSegment: null,
    modifiedSentence: null,
    hoveredSentence: null,
    editSentence: null,
    containsTakeaway: false,
    containsQuestion: false,
    containsAnswer: false,
    containsSelectedTopic: false,
    speakerMenuOpen: false,
  };
  speakerMenuAnchor = React.createRef(null);

  componentWillMount() {
    this.setState({ speakerSegment: this.props.speakerSegment });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.speakerSegment !== this.props.speakerSegment) {
      this.setState({ speakerSegment: this.props.speakerSegment });
    }
    if (prevProps.editSentence !== this.props.editSentence) {
      this.setState({ editSentence: this.props.editSentence });
    }
  }

  handleTranscriptSentenceClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Full Transcript",
    });
  };

  handleEditSentenceClicked = (event, result) => {
    event.stopPropagation();
    this.props.handleSentenceEdit(result);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User editing sentence via actions menu",
      label: "Transcript",
    });
  };

  handleCreateBookmarkClicked = (event, startTime, endTime) => {
    event.stopPropagation();
    this.props.handleOpenBookmark(startTime, endTime);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User creating bookmark via actions menu",
      label: "Transcript",
    });
  };

  handleAssignSpeakerClicked = (event, timestamp, speakerTag) => {
    event.stopPropagation();
    this.props.handleOnClickSpeakerAttribution(event, timestamp, speakerTag);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User assigning speaker via actions menu",
      label: "Transcript",
    });
  };

  handleClipClicked = (event, timestamp) => {
    event.stopPropagation();
    const transcriptResults = this.props.data.transcript_results;
    const idx = this.props.data.transcriptionResultsMap[timestamp.toString()];
    if (idx === -1) return;
    let endTime = this.props.data.duration;
    if (idx + 1 < transcriptResults.length) {
      endTime = transcriptResults[idx + 1].timestamp;
    }
    this.props.openClipCreateForm(event, timestamp, endTime);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User creating clip via actions menu",
      label: "Transcript",
    });
  };

  handleActionItemClicked = (event, sentence) => {
    event.stopPropagation();
    this.props.handleOpenActionItemForm(sentence);
  };

  handleTakeawayClicked = (event, timestamp, endTime) => {
    event.stopPropagation();
    const transcriptResults = this.props.data.transcript_results;
    const idx = this.props.data.transcriptionResultsMap[timestamp.toString()];
    const transcriptResult = transcriptResults[idx];
    this.props.handleOpenTakeaway(
      [transcriptResult],
      transcriptResults[idx].sentence,
      endTime
    );
  };

  shouldHighlightAsQuestion = (result) => {
    if (
      this.props.selectedHighlights === conversationHighlightTypes.questions
    ) {
      return true;
    } else if (
      this.props.selectedHighlight &&
      this.props.selectedHighlight.type === conversationHighlightTypes.questions
    ) {
      if (this.props.selectedHighlight.insight.timestamp === result.timestamp) {
        return true;
      }
    }
    return false;
  };

  shouldHighlightAsAnswer = (result) => {
    if (
      this.props.selectedHighlights === conversationHighlightTypes.questions
    ) {
      return true;
    } else if (
      this.props.selectedHighlight &&
      this.props.selectedHighlight.type === conversationHighlightTypes.questions
    ) {
      for (
        var i = 0;
        i < this.props.selectedHighlight.insight.answerTimestamps.length;
        i++
      ) {
        if (
          this.props.selectedHighlight.insight.answerTimestamps[i] ===
          result.timestamp
        ) {
          return true;
        }
      }
    }
    return false;
  };

  shouldHighlightAsTakeaway = (result) => {
    if (
      this.props.selectedHighlights === conversationHighlightTypes.takeaways
    ) {
      return true;
    } else if (
      this.props.selectedHighlight &&
      this.props.selectedHighlight.type === conversationHighlightTypes.takeaways
    ) {
      const segments = this.props.selectedHighlight.insight.transcriptSegments;
      for (var i = 0; i < segments.length; i++) {
        const segment = segments[i];
        for (var j = 0; j < segment.transcript.length; j++) {
          if (segment.transcript[j].timestamp === result.timestamp) {
            return true;
          }
        }
      }
    }
    return false;
  };

  getSentenceWithTimestamp = (
    result,
    startTime,
    endTime,
    searchSelectedElementIndex
  ) => {
    const { classes } = this.props;

    const containsSelectedTopic = this.props.selectedTopic
      ? result.containsTopics[this.props.selectedTopic]
      : false;
    const containsQuestion =
      result.isQuestion && this.shouldHighlightAsQuestion(result);
    const containsAnswer =
      result.isAnswer && this.shouldHighlightAsAnswer(result);
    const containsTakeaway =
      result.isTakeaway && this.shouldHighlightAsTakeaway(result);
    const containsActionItem =
      this.props.selectedActionItem &&
      this.props.selectedActionItem.timestamp != null &&
      result.timestamp != null
        ? this.props.selectedActionItem.timestamp.toFixed(2) ===
          result.timestamp.toFixed(2)
        : false;

    var styles = [classes.transcriptSentenceContainer];

    if (containsActionItem) {
      styles.push(classes.containsActionItem);
    } else if (containsTakeaway) {
      styles.push(classes.containsTakeaway);
    } else if (containsQuestion) {
      styles.push(classes.containsQuestion);
    } else if (containsAnswer) {
      styles.push(classes.containsAnswer);
    } else if (containsSelectedTopic) {
      styles.push(classes.containsTopic);
    }

    const containsCurrentInsight = this.props.currentInsight
      ? result.timestamp === this.props.currentInsight.timestamp
      : false;
    if (containsCurrentInsight) {
      styles.push(classes.containsCurrentInsight);
    }

    styles = classNames(styles);

    // This is a workaround for Safari, to force proper re-rendering of the element
    // when the page has first loaded, otherwise the element must be interacted with
    // in order to show the changing in classes.
    const isHighlighted =
      containsAnswer ||
      containsSelectedTopic ||
      containsQuestion ||
      containsTakeaway ||
      containsActionItem;

    return (
      <Box
        className={styles}
        onMouseEnter={() => this.handleSentenceMouseEnter(result)}
        onMouseLeave={() => this.handleSentenceMouseLeave()}
      >
        {isHighlighted && (
          <Box className={styles}>
            {this.renderSentence(
              result,
              startTime,
              endTime,
              isHighlighted,
              searchSelectedElementIndex
            )}
          </Box>
        )}
        {!isHighlighted &&
          this.renderSentence(
            result,
            startTime,
            endTime,
            isHighlighted,
            searchSelectedElementIndex
          )}
      </Box>
    );
  };

  renderSentence = (
    result,
    startTime,
    endTime,
    isHighlighted,
    searchSelectedElementIndex
  ) => {
    const { classes, isSharedMeetingView } = this.props;
    var sentence = result.sentence + " ";
    var timestamp = result.timestamp;
    const timestampClasses = stringifyTimeRange(startTime, endTime, "t");

    const sentenceSplits = [];
    const topic = this.props.selectedTopic;
    if (topic && isHighlighted) {
      const re = new RegExp(
        `[${topic[0]}|${topic[0].toUpperCase()}]${topic.slice(1)}`,
        "gi"
      );
      const indices = [...sentence.matchAll(re)].map((a) => a.index);
      let prevIndex = 0;
      for (let currIndex of indices) {
        const nextIndex = currIndex + topic.length;
        sentenceSplits.push(sentence.slice(prevIndex, currIndex));
        sentenceSplits.push(sentence.slice(currIndex, nextIndex));
        prevIndex = nextIndex;
      }
      sentenceSplits.push(sentence.slice(prevIndex, sentence.length));
    }
    const searchValue = this.props.searchValue;

    return (
      <Link
        className={
          `${classes.transcriptSentence} ` +
          `${timestampClasses} ` +
          "transcript-sentence " +
          `timestamp-${timestamp} ` +
          (!isSharedMeetingView ? "actions-menu-enabled" : "")
        }
        underline="none"
        onClick={() => this.handleTranscriptSentenceClicked(timestamp)}
      >
        {sentenceSplits.length === 0 ? (
          <Highlighter
            activeIndex={searchSelectedElementIndex}
            activeStyle={{
              backgroundColor: palette.primary.orange,
              color: palette.common.black,
              fontWeight: "bold",
            }}
            highlightStyle={{
              backgroundColor: "transparent",
              color: palette.danger.main,
              fontWeight: "bold",
            }}
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={sentence}
          />
        ) : (
          sentenceSplits.map((split) => {
            if (split.toLowerCase() === topic.toLowerCase()) {
              return (
                <Highlighter
                  activeIndex={searchSelectedElementIndex}
                  activeStyle={{
                    backgroundColor: palette.primary.orange,
                    color: palette.common.black,
                    fontWeight: "bold",
                  }}
                  highlightStyle={{
                    backgroundColor: "transparent",
                    color: palette.danger.main,
                    fontWeight: "bold",
                  }}
                  unhighlightStyle={{
                    fontWeight: "bold",
                  }}
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={split}
                />
              );
            }
            return (
              <Highlighter
                activeIndex={searchSelectedElementIndex}
                activeStyle={{
                  backgroundColor: palette.primary.orange,
                  color: palette.common.black,
                  fontWeight: "bold",
                }}
                highlightStyle={{
                  backgroundColor: "transparent",
                  color: palette.danger.main,
                  fontWeight: "bold",
                }}
                searchWords={[searchValue]}
                autoEscape={true}
                textToHighlight={split + " "}
              />
            );
          })
        )}
        {!isSharedMeetingView &&
          this.renderActionsMenu(result, startTime, endTime, sentence)}
      </Link>
    );
  };

  renderActionsMenu = (result, startTime, endTime, sentence) => {
    const { classes } = this.props;

    return (
      <div className={classes.overflowMenu}>
        <IconButton
          size="small"
          onClick={(e) => this.handleEditSentenceClicked(e, result)}
        >
          <Tooltip title="Edit" placement="top">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) =>
            this.handleAssignSpeakerClicked(
              e,
              result.timestamp,
              result.speaker_tag
            )
          }
        >
          <Tooltip title="Assign Speaker" placement="top">
            <PersonIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => this.handleClipClicked(e, result.timestamp)}
        >
          <Tooltip title="Clip" placement="top">
            <AssignmentIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) =>
            this.handleCreateBookmarkClicked(e, startTime, endTime)
          }
        >
          <Tooltip title="Bookmark" placement="top">
            <BookmarksIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => this.handleActionItemClicked(e, sentence)}
        >
          <Tooltip title="Add Action Item" placement="top">
            <AssignmentTurnedIn fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) =>
            this.handleTakeawayClicked(e, result.timestamp, endTime)
          }
        >
          <Tooltip title="Add Takeaway" placement="top">
            <PriorityHigh />
          </Tooltip>
        </IconButton>
      </div>
    );
  };

  setTimestampLink = (timestamp) => {
    const { classes } = this.props;
    if (
      !this.props.data.is_recording_enabled ||
      (this.props.isSharedMeetingView &&
        !this.props.data.share_granularity.share_recording)
    ) {
      return;
    }
    return (
      <Link
        className={classes.transcriptTime}
        component="button"
        onClick={() => this.handleTimestampClicked(timestamp)}
      >
        {timeFormatter(timestamp)}
      </Link>
    );
  };

  handleTimestampClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Transcript",
    });
  };

  getEditSentence = (result, startTime, endTime) => {
    const { classes, className, ...rest } = this.props;

    var sentence = result.sentence + " ";
    const timestampClasses = stringifyTimeRange(startTime, endTime, "t");

    return (
      <div className={classes.transcriptEditSentenceContainer}>
        <TextareaAutosize
          className={`${classes.transcriptEditSentence}${timestampClasses}`}
          defaultValue={sentence}
          onChange={(event) => {
            this.handleSentenceOnChange(event, result);
          }}
        />
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => this.handleSentenceEditComplete()}
          >
            <CheckCircleIcon color="primary" />
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => this.handleSentenceEditCancel()}
          >
            <CancelIcon color="primary" />
          </IconButton>
        </div>
      </div>
    );
  };

  handleSentenceEdit = (sentence) => {
    this.setState({
      editSentence: sentence,
    });
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Edit Transcript",
      label: "Transcript",
    });
  };

  handleSentenceMouseEnter = (sentence) => {
    this.setState({
      hoveredSentence: sentence,
    });
  };

  handleSentenceMouseLeave = () => {
    this.setState({
      hoveredSentence: null,
    });
  };

  handleSentenceEditCancel = () => {
    this.props.handleSentenceEditCancel();
    this.setState({
      modifiedSentence: null,
    });
  };

  handleSentenceEditComplete = () => {
    if (this.state.modifiedSentence) {
      var speakerSegment = this.state.speakerSegment;
      var modifiedTranscript = speakerSegment.transcript.map((sentence) => {
        var modifiedSentence = this.state.modifiedSentence;
        if (modifiedSentence.timestamp === sentence.timestamp) {
          return modifiedSentence;
        } else {
          return sentence;
        }
      });
      var filteredTranscript = modifiedTranscript.filter((sentence) => {
        if (sentence.sentence) {
          return true;
        } else {
          return false;
        }
      });
      this.props.sentenceUpdateHandler(this.state.modifiedSentence);
      this.setState({
        editSentence: null,
        modifiedSentence: null,
        speakerSegment: {
          speaker: speakerSegment.speaker,
          transcript: filteredTranscript,
        },
      });
    } else {
      this.handleSentenceEditCancel();
    }
  };

  handleSentenceOnChange = (event, modifiedSentence) => {
    this.setState({
      modifiedSentence: {
        speaker_tag: modifiedSentence.speaker_tag,
        timestamp: modifiedSentence.timestamp,
        sentence: event.target.value,
      },
    });
  };

  handleOpenSpeakerMenu = (event) => {
    this.setState({ speakerMenuOpen: true });
  };

  handleCloseSpeakerMenu = () => {
    this.setState({ speakerMenuOpen: false });
  };

  handleMapParticipant = (participantId) => {
    mapParticipant(
      this.props.data.meeting_id,
      this.state.speakerSegment.speaker,
      participantId
    ).then(() => {
      this.props.mapParticipantResponseHandler(
        participantId,
        this.state.speakerSegment.speaker
      );
      this.handleCloseSpeakerMenu();
    });
  };

  handleClearParticipantMapping = () => {
    const participant = this.getMappedParticipant();
    if (!participant) return;
    mapParticipant(this.props.data.meeting_id, -1, participant.id).then(() => {
      this.props.mapParticipantResponseHandler(participant.id, -1);
    });
  };

  getMappedParticipant = () => {
    return this.props.data.participants_details.find((p) => {
      return p.speaker_tag === this.state.speakerSegment.speaker;
    });
  };

  hasTimestampIntersection = (sentence, dictionary) => {
    return dictionary[sentence.timestamp] !== undefined;
  };

  render() {
    const { classes, className, ...rest } = this.props;

    var transcript = [];
    const segment = this.state.speakerSegment;
    var startTimestamp = 0;
    var currentParagraphLength = 0;
    const paragraphThreshold = 240;
    var paragraph = [];

    const showPositiveTip = segment.sentiment >= POSITIVE_TRANSCRIPT_THRESHOLD;
    const showNegativeTip = segment.sentiment <= NEGATIVE_TRANSCRIPT_THRESHOLD;
    let showTakeawayTip = false;
    let showQuestionTip = false;
    let showAnswerTip = false;

    segment.transcript.forEach((element, idx) => {
      if (element.sentence.trim() === ".") {
        return;
      }
      if (
        currentParagraphLength + element.sentence.length <=
        paragraphThreshold
      ) {
        currentParagraphLength += element.sentence.length;
      } else {
        if (currentParagraphLength > 0) {
          transcript.push(
            <p className={classes.speakerParagraph}>{paragraph}</p>
          );
        }
        currentParagraphLength = element.sentence.length;
        paragraph = [];
      }
      let startTime = Math.round(element.timestamp);
      let endTime = Math.round(rest.segmentEndTime);
      if (idx === 0) {
        startTimestamp = startTime;
      }
      if (idx < segment.transcript.length - 1) {
        endTime = Math.round(segment.transcript[idx + 1].timestamp);
      }
      if (this.state.editSentence === element) {
        paragraph.push(this.getEditSentence(element, startTime, endTime));
      } else {
        let searchSelectedElementIndex = -1;
        if (rest.selectedSearchResult !== null) {
          if (idx === rest.selectedSearchResult.sentenceIndex) {
            searchSelectedElementIndex = rest.selectedSearchResult.elementIndex;
          }
        }
        paragraph.push(
          this.getSentenceWithTimestamp(
            element,
            startTime,
            endTime,
            searchSelectedElementIndex
          )
        );
      }
      if (idx === segment.transcript.length - 1) {
        transcript.push(
          <p className={classes.speakerParagraph}>{paragraph}</p>
        );
      }
      showTakeawayTip =
        showTakeawayTip || this.shouldHighlightAsTakeaway(element);
      showQuestionTip =
        showQuestionTip || this.shouldHighlightAsQuestion(element);
      showAnswerTip = showAnswerTip || this.shouldHighlightAsAnswer(element);
    });

    return (
      <TimelineItem className={className}>
        <TimelineOppositeContent
          style={{ maxWidth: "40px", paddingLeft: "0px", paddingRight: "8px" }}
        >
          {this.setTimestampLink(startTimestamp)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent
          className={classes.transcriptSpeakerSegmentContainer}
          style={this.props.style}
        >
          <Typography
            variant="h6"
            className={classes.transcriptSpeakerTitle}
            ref={this.speakerMenuAnchor}
            style={{ backgroundColor: this.props.backgroundColor }}
          >
            {getSpeakerString(
              this.state.speakerSegment.speaker,
              this.props.data.participants_details,
              true
            )}
            {this.getMappedParticipant() ? (
              <ClearIcon
                className={classes.buttonIcon + " small"}
                onClick={this.handleClearParticipantMapping}
              />
            ) : (
              <ArrowDropDownIcon
                className={classes.buttonIcon}
                onClick={this.handleOpenSpeakerMenu}
              />
            )}
            <div className={classes.tipContainer}>
              {showTakeawayTip && (
                <Typography className={classes.tipPill + " takeaway"}>
                  <PriorityHighIcon className={classes.tipIcon} />
                  Takeaway
                </Typography>
              )}
              {showQuestionTip && (
                <Typography className={classes.tipPill + " question"}>
                  <QuestionAnswerIcon className={classes.tipIcon} />
                  Question
                </Typography>
              )}
              {showAnswerTip && (
                <Typography className={classes.tipPill + " answer"}>
                  <QuestionAnswerIcon className={classes.tipIcon} />
                  Answer
                </Typography>
              )}
              {showPositiveTip && (
                <Typography className={classes.tipPill + " positive"}>
                  <InsertEmoticonIcon className={classes.tipIcon} />
                  Positive
                </Typography>
              )}
              {showNegativeTip && (
                <Typography className={classes.tipPill + " negative"}>
                  <SentimentDissatisfiedIcon className={classes.tipIcon} />
                  Negative
                </Typography>
              )}
            </div>
          </Typography>
          <div variant="body1" className={classes.transcriptSpeakerSegmentBody}>
            {transcript}
          </div>
          <Menu
            open={this.state.speakerMenuOpen}
            anchorEl={this.speakerMenuAnchor.current}
            onClose={this.handleCloseSpeakerMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {this.props.data.participants_details.map((p) => (
              <>
                <MenuItem onClick={() => this.handleMapParticipant(p.id)}>
                  {p.name}
                </MenuItem>
              </>
            ))}
          </Menu>
        </TimelineContent>
      </TimelineItem>
    );
  }
}

TranscriptSpeakerSegment.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TranscriptSpeakerSegment);
