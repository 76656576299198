export default theme => ({
    root: {
        padding: theme.spacing.unit * 3
    },
    refreshButton: {
      margin: -theme.spacing.unit * 2
    },
    chartWrapper: {
      position: 'relative',
      height: '300px'
    },
    stats: {
      marginTop: theme.spacing.unit * 2,
      display: 'flex',
      justifyContent: 'center'
    },
    device: {
      textAlign: 'center',
      padding: theme.spacing.unit
    },
    deviceIcon: {
      color: theme.palette.common.neutral
    },
    content: {
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 700
    },
    value: {
      marginTop: theme.spacing.unit,
      color: theme.palette.success.dark
    },
    iconWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.warning.main,
      borderRadius: '50%',
      display: 'inline-flex',
      height: '4rem',
      justifyContent: 'center',
      marginLeft: 'auto',
      width: '4rem'
    },
    icon: {
      color: theme.palette.common.white,
      fontSize: '2rem',
      height: '2rem',
      width: '2rem'
    },
    footer: {
      marginTop: theme.spacing.unit * 2,
      display: 'flex',
      alignItems: 'center'
    },
    difference: {
      alignItems: 'center',
      color: theme.palette.danger.dark,
      display: 'inline-flex',
      fontWeight: 700
    },
    caption: {
        color: theme.palette.text.secondary
    }
  });
  