import { Grid, Typography, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CircularBar } from "components";
import React from "react";
import theme from "theme";
import styles from "./styles";

function Breakdown(props) {
  const {
    classes,
    meetings,
    totalHours,
    numMeetingsWithActionItems,
    numMeetingsWithGoodMeetingScore,
  } = props;
  let numMeetingsWithActionItemsPercent =
    meetings.length > 0
      ? Math.round((numMeetingsWithActionItems / meetings.length) * 100)
      : 0;
  let numMeetingsWithGoodMeetingScorePercent =
    meetings.length > 0
      ? Math.round((numMeetingsWithGoodMeetingScore / meetings.length) * 100)
      : 0;
  let isEmpty = !(meetings && meetings.length > 0);

  return (
    <>
      <div
        className={classes.meetingScoresContent + (isEmpty ? " isEmpty" : "")}
      >
        <div className={classes.breakdownContainer}>
          <div className={classes.breakdownSummary}>
            <Grid className={classes.scoreLabelContainer}>
              <Grid xs={12} sm={12} md={6}>
                <Typography variant="h6" className={classes.scoreLabel}>
                  Total Meetings
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={6} className={classes.highlightScore}>
                <span className={classes.score}>
                  {meetings ? meetings.length : 0}
                </span>
              </Grid>
            </Grid>
            <Grid className={classes.scoreLabelContainer}>
              <Grid xs={12} sm={12} md={6}>
                <Typography variant="h6" className={classes.scoreLabel}>
                  Total Hours
                </Typography>
              </Grid>
              <Grid xs={12} sm={12} md={6} className={classes.highlightScore}>
                <span className={classes.score}>{totalHours}</span>
              </Grid>
            </Grid>
          </div>
          <div className={classes.graphContainer}>
            <div className={classes.pieChart}>
              <CircularBar
                barPercentage={numMeetingsWithActionItemsPercent}
                barFillColor={"green"}
                barEmptyColor={theme.palette.background.grey}
                bgColor={theme.palette.common.white}
                smallerSizing={true}
              >
                <div className={classes.score + " overall"}>
                  {numMeetingsWithActionItemsPercent}%
                </div>
              </CircularBar>
            </div>
            <Typography variant="h6" className={classes.captionText}>
              with Action Items
            </Typography>
          </div>
          <div className={classes.graphContainer}>
            <div className={classes.pieChart}>
              <CircularBar
                barPercentage={numMeetingsWithGoodMeetingScorePercent}
                barFillColor={"green"}
                barEmptyColor={theme.palette.background.grey}
                bgColor={theme.palette.common.white}
                smallerSizing={true}
              >
                <div className={classes.score + " overall"}>
                  {numMeetingsWithGoodMeetingScorePercent}%
                </div>
              </CircularBar>
            </div>
            <Typography variant="h6" className={classes.captionText}>
              with positive Meeting Scores
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(Breakdown);
