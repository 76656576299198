import { fade } from "@material-ui/core";
import {
  questionsColour,
  answersColour,
  takeawaysColour,
  winsColour,
  opportunitiesColour,
  painPointsColour,
} from "../../styles";

export default (theme) => ({
  listContent: {
    maxHeight: "400px",
    overflow: "auto",
  },
  section: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${fade(theme.palette.border, 0.5)}`,
    cursor: "pointer",
    "&:nth-child(even)": {
      backgroundColor: theme.palette.background.lightGrey,
    },
    "&:hover,&:focus-within": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  itemHeader: {
    fontSize: "16px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  timestamp: {
    color: theme.palette.primary.main,
  },
  itemContent: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  segment: {
    display: "flex",
    flexDirection: "column",
    margin: `${theme.spacing(1)}px 0`,
  },
  segmentSpeaker: {
    color: theme.palette.text.secondary,
  },
  segmentContent: {
    color: theme.palette.text.primary,
    lineHeight: 1.6,
    "& > p:not(:last-child)": {
      marginBottom: theme.spacing(1.5),
    },
  },
  type: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    color: theme.palette.common.white,
    borderRadius: "5px",
    "&.question": {
      backgroundColor: questionsColour,
    },
    "&.answer": {
      backgroundColor: answersColour,
    },
    "&.takeaway": {
      backgroundColor: takeawaysColour,
    },
    "&.keywords": {
      backgroundColor: takeawaysColour,
    },
  },
  chipsContainer: {
    width: "fit-content",
    marginLeft: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  headerChip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    padding: `0 ${theme.spacing.unit}px`,
    minHeight: "25px",
    borderRadius: "50px",
    fontFamily: "Roboto",
    lineHeight: 1.25,
    transition: "min-height 0.2s, border-radius 0.1s",
    "&.win": {
      backgroundColor: winsColour,
    },
    "&.opportunity": {
      backgroundColor: opportunitiesColour,
    },
    "&.painPoint": {
      backgroundColor: painPointsColour,
    },
    "&.thrilled": {
      backgroundColor: theme.palette.success.main,
    },
    "&.down": {
      backgroundColor: theme.palette.danger.mellow,
    },
    "&.expandable:hover": {
      flexDirection: "column",
      minHeight: "55px",
      borderRadius: "5px",
    },
    "&.expandable:hover $hoverTooltip": {
      display: "block",
    },
  },
  hoverTooltip: {
    display: "none",
    color: theme.palette.background.lightGrey,
    fontSize: "9px",
    maxWidth: "100px",
    marginTop: theme.spacing(0.25),
  },
  chipContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.unit * 0.5,
    fontSize: "12px",
    fontFamily: "Roboto",
    "& > .icon": {
      width: "15px",
      height: "15px",
    },
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  filterSelectRoot: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.5),
    minWidth: "90px",
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
  },
  emptyText: {
    textAlign: "center",
    padding: theme.spacing(4),
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  highlight: {
    color: theme.palette.primary.brown,
    fontWeight: 600,
  },
  scrollTip: {
    width: "100%",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    textAlign: "center",
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    borderTop: `1px solid ${theme.palette.border}`,
  },
  deleteIcon: {
    color: theme.palette.danger.mellow,
    marginLeft: "auto",
  },
  keywordSection: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${fade(theme.palette.border, 0.5)}`,
    "&:nth-child(even)": {
      backgroundColor: theme.palette.background.lightGrey,
    },
  },
  keywordHeader: {
    marginBottom: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  keywordLabel: {
    fontSize: "20px",
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  keywordCount: {
    fontSize: "12px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    display: "grid",
    placeItems: "center",
  },
  keywordContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.5),
    position: "relative",
  },
  keywordInstance: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    cursor: "pointer",
  },
  keywordTimestamp: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    width: "35px",
  },
  keywordSentence: {
    width: "100%",
  },
});
