import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";

import { RenameClipModal, ShareClipModal } from "./components";
import { Paper } from "components";
import {
  Typography,
  MenuItem,
  Button,
  IconButton,
  Menu,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { Delete, Edit, MoreVert, Share, Comment } from "@material-ui/icons";
import { getTranscriptSegments } from "helpers/meetingTranscript";
import { getSpeakerString } from "helpers/meetingParticipants";

import { timeFormatter } from "helpers/time";

import theme from "theme";

const MAX_LINES = 5;
const MAX_CHARS_PER_LINE = 35;

class Clips extends Component {
  state = {
    clipSegments: {},
    shareModalOpen: false,
    modalClipUuid: "",
    renameClip: {},
    menuAnchor: null,
    deleteDialogOpen: false,
    deleteDialogText: "",
    deleteDialogHandler: () => {},
  };
  intersectTargetClips = React.createRef();
  lastClipRef = React.createRef();
  clipRefs = [];
  prevLastClip;

  componentDidMount() {
    const { data, openClip } = this.props;
    this.updateThumbnails();
    this.buildClipSegments();
    this.prevLastClip = this.lastClipRef.current;
    if (this.props.scrollToClip) {
      this.handleScrollClipIntoView().then((value) =>
        setTimeout(() => {
          data.clips.forEach((clip, idx) => {
            if (clip.uuid === value) {
              openClip(idx, data.clips);
              return;
            }
          });
        }, 2000)
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateThumbnails();
      this.buildClipSegments();
      if (
        this.lastClipRef.current &&
        this.lastClipRef.current !== this.prevLastClip
      ) {
        this.prevLastClip = this.lastClipRef.current;
        window.scrollTo({
          left: 0,
          top:
            this.lastClipRef.current.offsetTop -
            this.lastClipRef.current.offsetHeight,
          behavior: "smooth",
        });
      }
    }
  }

  async handleScrollClipIntoView() {
    const uuid = this.props.scrollToClip;
    if (this.clipRefs[uuid]) {
      this.clipRefs[uuid].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      this.clipRefs[uuid].style.backgroundColor = theme.palette.warning.light;
    }
    return uuid;
  }

  updateThumbnails = (clipsIdx = 0) => {
    if (clipsIdx >= this.props.data.clips.length) {
      return;
    }

    const clip = this.props.data.clips[clipsIdx];
    const canvas = document.querySelector(`#thumbnail${clip.uuid}`);
    const hiddenVideo = document.getElementById("hiddenVideo");

    const seekThumbnail = () => {
      const width = canvas.width;
      const height = hiddenVideo.videoHeight * (width / hiddenVideo.videoWidth);
      canvas.style.height = height / 2;
      canvas.getContext("2d").drawImage(hiddenVideo, 0, 0, width, height);
      hiddenVideo.removeEventListener("seeked", seekThumbnail);
      this.updateThumbnails(clipsIdx + 1);
    };

    if (hiddenVideo.readyState < 3) {
      hiddenVideo.addEventListener("loadeddata", function videoLoad() {
        hiddenVideo.addEventListener("seeked", seekThumbnail);
        hiddenVideo.currentTime = clip.start_time;
        hiddenVideo.removeEventListener("loadeddata", videoLoad);
      });
    } else {
      hiddenVideo.addEventListener("seeked", seekThumbnail);
      hiddenVideo.currentTime = clip.start_time;
    }
  };

  buildClipSegments = () => {
    const transcriptResults = this.props.data.transcript_results;
    const clipSegments = {};

    for (let clip of this.props.data.clips) {
      const startIdx = this.props.data.transcriptionResultsMap[
        clip.start_time.toString()
      ];
      const endIdx =
        this.props.data.duration === +clip.end_time
          ? transcriptResults.length
          : this.props.data.transcriptionResultsMap[clip.end_time.toString()];
      if (startIdx === -1 || endIdx === -1) {
        continue;
      }
      const slicedResults = transcriptResults.slice(startIdx, endIdx);
      clipSegments[clip.uuid] = getTranscriptSegments(slicedResults);
    }
    this.setState({ clipSegments });
  };

  handleOpenMenu = (event) => {
    this.setState({ menuAnchor: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ menuAnchor: null });
  };

  handleOpenDeleteDialog = (clipUuid) => {
    this.setState({
      deleteDialogOpen: true,
      deleteDialogText: "Are you sure you want to delete this clip?",
      deleteDialogHandler: () => {
        this.props.deleteClip(clipUuid);
        this.handleCloseDeleteDialog();
      },
    });
  };

  handleOpenDeleteAllDialog = () => {
    this.handleCloseMenu();
    this.setState({
      deleteDialogOpen: true,
      deleteDialogText: "Are you sure you want to delete all clips?",
      deleteDialogHandler: () => {
        this.props.deleteAllClips();
        this.handleCloseDeleteDialog();
      },
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: false,
      deleteDialogText: "",
      deleteDialogHandler: () => {},
    });
  };

  handleOpenShareModal = (clipUuid) => {
    this.setState({ shareModalOpen: true, modalClipUuid: clipUuid });
  };

  handleCloseShareModal = () => {
    this.setState({ shareModalOpen: false, modalClipUuid: "" });
  };

  renderHeader = () => {
    const { classes, isSharedMeetingView } = this.props;
    const { menuAnchor } = this.state;
    return (
      <header className={classes.clipsHeader}>
        <Typography variant="h6">CLIPS</Typography>
        {!isSharedMeetingView && (
          <>
            <IconButton onClick={this.handleOpenMenu}>
              <MoreVert className={classes.clipMoreIcon} />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              getContentAnchorEl={null}
              open={Boolean(menuAnchor)}
              onClose={this.handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem onClick={this.handleOpenDeleteAllDialog}>
                Delete all clips
              </MenuItem>
            </Menu>
          </>
        )}
      </header>
    );
  };

  renderClipHeader = (clips, clip, idx) => {
    const { classes, isSharedMeetingView, openClip } = this.props;
    let commentUnread = false;
    clip.clip_comments.forEach((comment) => {
      if (!comment.has_seen) {
        commentUnread = true;
        return;
      }
    });
    return (
      <div
        className={classes.clipHeader + (isSharedMeetingView ? " shared" : "")}
      >
        <Typography className={classes.clipTitle}>
          {clip.name ? clip.name : `Clip ${idx + 1}`}
        </Typography>
        {!isSharedMeetingView && (
          <div className={classes.actionMenu}>
            <Tooltip title="Rename">
              <IconButton
                size="small"
                onClick={() => this.handleOpenRenameModal(clip)}
              >
                <Edit fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`You have ${commentUnread ? "" : "no "}unread comments`}
            >
              <IconButton size="small" onClick={() => openClip(idx, clips)}>
                <Badge color={commentUnread ? "error" : ""} variant="dot">
                  <Comment fontSize="small" color="primary" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Share">
              <IconButton
                size="small"
                onClick={() => this.handleOpenShareModal(clip.uuid)}
              >
                <Share fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => this.handleOpenDeleteDialog(clip.uuid)}
              >
                <Delete fontSize="small" className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  renderClipPreview = (clip, segments) => {
    if (!segments || segments.length === 0) return <></>;
    const { classes, data } = this.props;
    const participantsDetails = data.participants_details;

    // Limit the number of lines and characters to show in the preview
    const segmentsToRender = [];
    let lines = 0;
    for (let i = 0; i < segments.length; i++) {
      const segment = { ...segments[i] };
      segmentsToRender.push(segment);
      lines += 1;
      for (let j = 0; j < segment.transcript.length; j++) {
        const sentence = segment.transcript[j].sentence;
        lines += Math.ceil(sentence.length / MAX_CHARS_PER_LINE);
        if (lines >= MAX_LINES) {
          segment.transcript = segment.transcript.slice(0, j + 1);
          break;
        }
      }
      if (lines >= MAX_LINES) break;
    }

    return (
      <div className={classes.clipSnippet}>
        {segmentsToRender.map((segment) => (
          <>
            <Typography variant="h6" className={classes.snippetSpeaker}>
              {getSpeakerString(segment.speaker, participantsDetails)}
            </Typography>
            <Typography variant="body1" className={classes.snippetBody}>
              {segment.transcript.map((transcriptSentence, i) => (
                <p key={i}>{transcriptSentence.sentence}</p>
              ))}
            </Typography>
          </>
        ))}
      </div>
    );
  };

  renderShareClipModal = () => {
    return (
      <ShareClipModal
        modalOpen={this.state.shareModalOpen}
        handleCloseModal={this.handleCloseShareModal}
        clipUuid={this.state.modalClipUuid}
        openSnackbar={this.props.openSnackbar}
      />
    );
  };

  renderDeleteDialog = () => {
    const {
      deleteDialogOpen,
      deleteDialogText,
      deleteDialogHandler,
    } = this.state;
    return (
      <Dialog open={deleteDialogOpen} onClose={this.handleCloseDeleteDialog}>
        <DialogContent>
          <Typography variant="h5">{deleteDialogText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteDialogHandler} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenRenameModal = (clip) => {
    this.setState({ openRenameModal: true, renameClip: clip });
  };

  handleCloseRenameModal = () => {
    this.setState({ openRenameModal: false, renameClip: {} });
  };

  renderRenameModal = () => {
    const { classes } = this.props;
    return (
      <RenameClipModal
        classes={classes}
        open={this.state.openRenameModal}
        id={this.state.renameClip.uuid}
        currentTitle={this.state.renameClip.name}
        onClose={this.handleCloseRenameModal}
        openSnackbar={this.props.openSnackbar}
        renameClip={this.props.renameClip}
      />
    );
  };

  render() {
    const { classes, data, openClip, ...rest } = this.props;
    const { clipSegments } = this.state;
    return (
      <div className={classes.container} ref={this.intersectTargetClips}>
        <Paper style={{ height: "100%" }} {...rest}>
          {this.renderHeader()}
          {data.clips.length > 0 && (
            <div className={classes.clipsList}>
              {data.clips.map((clip, idx) => (
                <div
                  key={idx}
                  ref={(instance) => {
                    this.clipRefs[clip.uuid] = instance;
                  }}
                  className={classes.clip}
                >
                  {this.renderClipHeader(data.clips, clip, idx)}
                  <MenuItem
                    className={classes.clipContent}
                    onClick={() => openClip(idx, data.clips)}
                    ref={
                      idx + 1 === data.clips.length ? this.lastClipRef : null
                    }
                  >
                    <div className={classes.clipContainer}>
                      <Typography variant="h6" className={classes.clipLength}>
                        Clip length (
                        {timeFormatter(clip.end_time - clip.start_time)})
                      </Typography>
                      <canvas
                        id={"thumbnail" + clip.uuid}
                        className={classes.thumbnailCanvas}
                      ></canvas>
                    </div>
                    {this.renderClipPreview(clip, clipSegments[clip.uuid])}
                  </MenuItem>
                </div>
              ))}
            </div>
          )}
          {data.clips.length === 0 && (
            <div className={classes.emptyView}>
              <Typography variant="h4" className={classes.emptyText}>
                No clips have been created.
              </Typography>
            </div>
          )}
        </Paper>
        {this.renderShareClipModal()}
        {this.renderDeleteDialog()}
        {this.renderRenameModal()}
      </div>
    );
  }
}

Clips.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Clips);
