import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import { Paper, ProcessingInProgress } from "components";
import { getSpeakerString } from "helpers/meetingParticipants";
import { toneResults } from "helpers/meetingTone";
import styles from "./styles";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { speechStatus } from "helpers";

class DeliveryTone extends Component {
  state = {
    data: [],
    currentSpeaker: "",
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const analytics = this.props.data.analytics;
    if (!analytics.tone && !analytics.tone_level) {
      return;
    }
    const tone = analytics.tone;
    const toneLevels = analytics.tone_level;

    const participantsDetails = this.props.data.participants_details;
    const data = [];
    for (let i = 0; i < tone.length; i++) {
      data.push({
        speaker: getSpeakerString(tone[i].speaker_tag, participantsDetails),
        tone: tone[i].tone,
        toneLevel: toneLevels[i].tone_level,
      });
    }
    this.setState({
      data: data,
      currentSpeaker: data.length > 0 ? data[0].speaker : "",
    });
  };

  handleOnClickSelectSpeaker = (tone) => {
    this.setState({ currentSpeaker: tone.speaker });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Change Speaker (Delivery Tone)",
      label: "Analytics",
    });
  };

  renderSpeakerDetail = (speaker) => {
    const { classes } = this.props;
    const speakerTone = this.state.data.find(
      (tone) => tone.speaker === speaker
    );
    const toneResult = toneResults[speakerTone.tone];

    return (
      <div className={classes.toneDetail}>
        <Typography className={classes.speaker} variant="h6">
          {speaker}
        </Typography>
        <Typography
          className={classes.tone + ` ${toneResult.class}`}
          variant="h5"
        >
          {toneResult.result}
        </Typography>
        <Typography className={classes.caption} variant="body1">
          {toneResult.caption(speaker)}
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, className, meetingStatus, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    if (
      meetingStatus === speechStatus.completed &&
      this.state.data.length === 0
    ) {
      return <></>;
    }

    return (
      <div>
        {this.props.data.analytics && (
          <Paper {...rest} className={rootClassName}>
            <div className={classes.firstContent}>
              <div className={classes.iconWrapper}>
                <RecordVoiceOverIcon className={classes.icon} />
              </div>
              <div>
                <Typography className={classes.title} variant="h5">
                  DELIVERY TONE
                </Typography>
              </div>
            </div>
            {meetingStatus === speechStatus.completed && (
              <div className={classes.content}>
                {this.state.data.map((tone) => {
                  const toneResult = toneResults[tone.tone];
                  return (
                    <div
                      className={
                        classes.speakerTone +
                        ` ${tone.speaker === this.state.currentSpeaker &&
                          "selected"}`
                      }
                      onClick={() => this.handleOnClickSelectSpeaker(tone)}
                    >
                      <Typography className={classes.speaker} variant="h6">
                        {tone.speaker}
                      </Typography>
                      <Typography
                        className={classes.tone + ` ${toneResult.class}`}
                        variant="h5"
                      >
                        {toneResult.result}
                      </Typography>
                    </div>
                  );
                })}
                <div
                  className={classes.details}
                  style={{
                    gridRow: `1 / ${this.state.data.length + 1}`,
                  }}
                >
                  {this.renderSpeakerDetail(this.state.currentSpeaker)}
                </div>
              </div>
            )}
            {meetingStatus === speechStatus.processing && (
              <ProcessingInProgress copy="Processing Analytics..." />
            )}
          </Paper>
        )}
      </div>
    );
  }
}

DeliveryTone.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryTone);
