import React from "react";

export const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle
      fill={color}
      r={size / 2}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      r={size / 5}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
);
