import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import ReactGA from "react-ga";

import { createContact } from "services/speech";

// Component styles
const styles = (theme) => ({
  field: {
    minWidth: "300px",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
  },
  projectLeadField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
});

class ContactDialog extends Component {
  state = {
    contactName: this.props.contactName,
    contactEmail: this.props.contactEmail,
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.contactName !== this.props.contactName ||
      prevProps.contactEmail !== this.props.contactEmail
    ) {
      this.setState({
        contactName: this.props.contactName,
        contactEmail: this.props.contactEmail,
      });
    }
  }

  handleSaveContact = () => {
    var contactName = this.state.contactName;
    var contactEmail = this.state.contactEmail;
    if (!contactName.trim() || !contactEmail.trim()) {
      return;
    }
    this.setLoading(true);
    return createContact(contactName, contactEmail).then(
      function(response) {
        if (response.status !== 201) {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.props.openSnackbar(
            "Sorry, but an unknown error occurred. Please try again."
          );
          this.setLoading(false);
          ReactGA.event({
            category: "Failure",
            action: "Create contact API failed",
          });
          return response;
        }
        var newContact = response.data;
        this.props.handleCreateContact(newContact);
        this.setLoading(false);
        this.setState({ contactName: null, contactEmail: null });
        this.props.openSnackbar("Contact successfully created");
        ReactGA.event({
          category: "Client Accounts",
          action: "Create contact API success",
        });
        this.props.onClose();
        return response;
      }.bind(this)
    );
  };

  handleContactNameChange = (value) => {
    this.setState({ contactName: value });
  };

  handleContactEmailChange = (value) => {
    this.setState({ contactEmail: value });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New Contact</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.field}
            label="Contact Name"
            onChange={(event) =>
              this.handleContactNameChange(event.target.value)
            }
            required
            value={this.state.contactName}
          />
          <TextField
            className={classes.field}
            label="Contact Email"
            onChange={(event) =>
              this.handleContactEmailChange(event.target.value)
            }
            required
            value={this.state.contactEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={() => this.handleSaveContact()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

ContactDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(ContactDialog);
