import React, { Component } from 'react';
import MaterialTable from 'material-table';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Remove from '@material-ui/icons/Remove';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';

// Shared components
import {
    Paper,
    PortletLabel,
    PortletFooter
  } from 'components';

// Component styles
import styles from './styles';

// Palette
import palette from 'theme/palette';

const tableIcons = {
    Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
}

class MeetingAgenda extends Component {

  state = {
    columns: [
        { title: 'Agenda Item', field: 'agendaItem', editComponent: props => (
            <TextField
                label='New Agenda Topic'
                value={props.value}
                fullWidth={true}
                onChange={e => props.onChange(e.target.value)}
                autoFocus
            />
        ) },
      ],
      data: [],
  }

  componentWillMount() {
    let initialData = this.props.agenda.map(e => {
        return {agendaItem: e}
    })
    this.setState({
        data: initialData
    })
  }

  handleSave = () => {
    this.props.updateMeeting()
  }

  handleAdd = () => {
    this.tableRef.setState({
      showAddRow: true
    })
  }
  
  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
      {...rest}
      className={rootClassName}
      >
        <MaterialTable
          tableRef={instance => {this.tableRef = instance;}}
          icons={tableIcons}
          title={
            <PortletLabel title="Meeting Agenda" />
          }
          columns={this.state.columns}
          data={this.state.data}
          options={{
              rowStyle: {
                  fontFamily: 'Roboto'
              },
              search: false,
              header: false,
              paging: false,
              actionsColumnIndex: -1,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: ''
            }
          }}
          style={{
            boxShadow: 'none'
          }}
          editable={{
              onRowAdd: (newData) =>
              new Promise((resolve) => {
                  resolve();
                  this.setState((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                  });
                  this.props.agenda.push(newData.agendaItem)
              }),
              onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                  resolve();
                  if (oldData) {
                      this.setState((prevState) => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                      });
                      this.props.agenda[this.props.agenda.indexOf(oldData.agendaItem)] = newData.agendaItem;
                  }
              }),
              onRowDelete: (oldData) =>
              new Promise((resolve) => {
                  resolve();
                  this.setState((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                  });
                  this.props.agenda.splice(this.props.agenda.indexOf(oldData), 1);
              }),
          }}
          />
          <PortletFooter className={classes.portletFooter}>
            <Button
                color="primary"
                size="small"
                variant="text"
                onClick={this.handleAdd}
            >
                Add Topic
            </Button>
            <Button
                color="primary"
                size="small"
                variant="text"
                onClick={this.handleSave}
            >
                Save
            </Button>
        </PortletFooter>
    </Paper>
    );
  }
}

MeetingAgenda.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MeetingAgenda);
