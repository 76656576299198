import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  },
  snackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  whiteSnackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
    backgroundColor: "white",
    color: "black",
  },
  renameClipTextField: {
    width: "350px",
  },
});

class RenameClipModal extends Component {
  state = {
    newClipTitle: "",
    anchorEl: null,
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentTitle !== prevProps.currentTitle) {
      this.setState({ newClipTitle: this.props.currentTitle });
    }
  }

  handleSaveRenameClip = () => {
    var title = this.state.newClipTitle;
    if (!title.trim()) {
      return;
    }
    this.setLoading(true);
    return this.props.renameClip(this.props.id, title).then(
      function(response) {
        if (response.status !== 200) {
          this.setLoading(false);
          return response;
        }
        this.setLoading(false);
        this.props.openSnackbar("Clip successfully renamed");
        this.props.onClose();
        return response;
      }.bind(this)
    );
  };

  handleCheckEnterSaveRenameClip = (event) => {
    if (event.key === "Enter") {
      this.handleSaveRenameClip();
    }
  }

  handleClipTitleChange = (value) => {
    this.setState({ newClipTitle: value });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rename Clip</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.renameClipTextField}
            label="New Clip Title"
            onChange={(event) => this.handleClipTitleChange(event.target.value)}
            onKeyPress={this.handleCheckEnterSaveRenameClip}
            required
            value={this.state.newClipTitle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={() => this.handleSaveRenameClip()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

RenameClipModal.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(RenameClipModal);
