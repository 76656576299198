import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { ButtonGroup, Button } from "@material-ui/core";

class ShareButton extends Component {
  render() {
    return (
      <div>
        <ButtonGroup>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={this.props.shareButtonHandler}
          >
            SHARE
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

ShareButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShareButton);
