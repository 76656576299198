import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  Face as FaceIcon
} from '@material-ui/icons';

// Chart configuration
import { options } from './chart';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

// Palette
import palette from 'theme/palette';

class Emotions extends Component {
  state = {
    data: {},
    caption: ""
  }

  componentWillMount() {
    const emotionData = this.props.data;

    var labels = emotionData.emotions.map(function(e) {
        return e.type;
    })

    var values = emotionData.emotions.map(function(e) {
        return e.percent;
    })

    const chartData = {
        datasets: [
          {
            data: values,
            backgroundColor: [
              palette.primary.main,
              palette.danger.main,
              palette.warning.main,
              palette.success.main,
              palette.primary.purple,
              palette.primary.cyan,
              palette.primary.pink
            ],
            borderWidth: 8,
            borderColor: palette.common.white,
            hoverBorderColor: palette.common.white
          }
        ],
        labels: labels
    }

    this.setState({
        data: chartData
    })

    if (emotionData.emotions.length === 0) {
        this.setState({
            caption: "Sorry, but we couldn't recognize your facial expressions because the video was unclear"
        })
    }
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h6"
            >
              FACIAL EXPRESSIONS
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <FaceIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.chartWrapper}>
            <Pie
            data={this.state.data}
            options={options}
            />
        </div>
        <div className={classes.footer}>
          <Typography
            className={classes.caption}
            variant="body1"
          >
            {this.state.caption}
          </Typography>
        </div>
      </Paper>
    );
  }
}

Emotions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Emotions);
