import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import styles from "./styles";

function MetricBar(props) {
  const { classes, barPercentage, barColor, height, fillAnimation } = props;
  return (
    <div className={classes.container} style={height && { height }}>
      <div className={classes.metrics + " one"}></div>
      <div className={classes.metrics + " two"}></div>
      <div className={classes.metrics + " three"}></div>
      <div className={classes.metrics + " four"}></div>
      <div className={classes.metrics + " five"}></div>
      <div className={classes.metrics + " six"}></div>
      <div className={classes.metrics + " seven"}></div>
      <div className={classes.metrics + " eight"}></div>
      <div className={classes.metrics + " nine"}></div>
      <div
        className={classes.bar + (fillAnimation ? " fill-animation" : "")}
        style={{
          width: barPercentage >= 0 ? barPercentage + "%" : 0,
          backgroundColor: barColor,
        }}
      ></div>
    </div>
  );
}

MetricBar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MetricBar);
