import React from "react";

const styleById = {
  moments: {
    strokeWidth: 0,
  },
  default: {
    strokeWidth: 2,
  },
};

export const CustomLinesLayer = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={styleById[id] || styleById.default}
    />
  ));
};
