import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  firstContent: {
    alignItems: "center",
    display: "flex",
  },
  content: {
    fontFamily: "Roboto",
    minHeight: "400px",
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gridAutoRows: "auto",
    alignItems: "center",
  },
  speakerPacing: {
    borderBottom: `2px solid ${theme.palette.border}`,
    borderRadius: "10px 0 0 0",
    padding: theme.spacing.unit,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
    "&.selected": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
  speaker: {
    justifySelf: "center",
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  pacing: {
    "&.badPacing": {
      color: theme.palette.danger.dark,
    },
    "&.goodPacing": {
      color: theme.palette.success.dark,
    },
  },
  details: {
    // gridRow is defined dynamically in index.jsx
    gridColumn: "2 / 3",
    alignSelf: "start",
    padding: theme.spacing.unit * 2,
    fontSize: "1.25rem",
    color: theme.palette.text.secondary,
  },
  emptyDetails: {
    padding: theme.spacing.unit * 2,
  },
  pacingDetail: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  caption: {
    marginTop: theme.spacing.unit * 2,
    fontSize: "1.275rem",
    color: theme.palette.text.secondary,
    lineHeight: 1.35,
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
});
