import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";

import {
  Button,
  Fade,
  Typography,
  Card,
  CardActions,
  CardContent,
  Popper,
  Menu,
} from "@material-ui/core";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { getDateRangeString } from "helpers/time";

const rangeFormat = { month: "2-digit", day: "2-digit" };
const rangeFormatWithYear = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};

class Header extends Component {
  state = {
    ftuxStep: 0,
    ftuxOpen: false,
  };
  headerRef = React.createRef();
  datePickerRef = React.createRef();

  componentDidMount() {
    const hasSeenDashboardPopup = localStorage.hasOwnProperty(
      "hasSeenDashboardPopup"
    );
    const hasSeenDateSelectorTooltip = localStorage.hasOwnProperty(
      "hasSeenDateSelectorTooltip"
    );
    const dashboardLastPopup =
      localStorage.hasOwnProperty("dashboardLastPopup");
    let step = 0;
    if (!hasSeenDashboardPopup) {
      step = 1;
    } else if (!hasSeenDateSelectorTooltip) {
      step = 2;
    }
    let timeInterval = 0;
    if (!dashboardLastPopup) {
      timeInterval = 18;
    } else {
      timeInterval =
        Math.abs(
          new Date() - Date.parse(localStorage.getItem("dashboardLastPopup"))
        ) /
        (1000 * 60 * 60);
    }
    const showHint =
      this.props.onlyDemoOrEmpty && step > 0 && timeInterval >= 18;
    this.setState({
      ftuxStep: step,
      ftuxOpen: showHint,
    });
  }

  handleFTUXFinishButton() {
    const { ftuxStep } = this.state;
    if (ftuxStep === 1) {
      localStorage.setItem("hasSeenDashboardPopup", true);
    } else if (ftuxStep === 2) {
      localStorage.setItem("hasSeenDateSelectorTooltip", true);
    } else {
      localStorage.setItem("hasSeenDashboardPopup", true);
      localStorage.setItem("hasSeenDateSelectorTooltip", true);
    }
    localStorage.setItem("dashboardLastPopup", new Date().toString());
    this.setState({
      ftuxStep: 0,
      ftuxOpen: false,
    });
  }

  handleFTUXFinish() {
    localStorage.setItem("hasSeenDashboardPopup", true);
    localStorage.setItem("hasSeenDateSelectorTooltip", true);
    localStorage.setItem("dashboardLastPopup", new Date().toString());
    this.setState({
      ftuxStep: 0,
      ftuxOpen: false,
    });
  }

  renderFTUX = () => {
    const { classes } = this.props;
    const { ftuxStep, ftuxOpen } = this.state;
    if (!ftuxOpen) {
      return <></>;
    }
    return (
      <>
        <div
          className={classes.overlay}
          onClick={() => this.handleFTUXFinish()}
        ></div>
        {ftuxStep === 1 && this.renderFTUXStep1()}
        {ftuxStep === 2 && this.renderFTUXStep2()}
      </>
    );
  };

  renderFTUXStep1 = () => {
    const { classes } = this.props;
    const { ftuxOpen } = this.state;
    return (
      <Popper
        className={classes.popper}
        open={ftuxOpen}
        anchorEl={this.headerRef.current}
        transition
        onRequestClose={this.handleFTUXFinish}
        modifiers={[
          {
            preventOverflow: {
              enabled: false,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            in={ftuxOpen}
            timeout={{
              enter: 350,
              exit: 100,
            }}
          >
            <Card sx={{ minWidth: 550 }} className={classes.step1Container}>
              <CardContent>
                <Typography
                  className={classes.cardHeader}
                  variant="h5"
                  component="div"
                  color="text.secondary"
                  gutterBottom
                >
                  Welcome to Sonero!
                </Typography>
                <Typography component="div" className={classes.cardText}>
                  This is your dashboard. We'll aggregate key takeaways from
                  your last 10 meetings here. To show you what it can do, we've
                  added a<b> demo meeting</b> for reference. It'll disappear
                  once you've had few meetings of your own!
                </Typography>
              </CardContent>
              <CardActions style={{ marginTop: "-20px" }}>
                <Button
                  color="success"
                  variant="contained"
                  className={classes.cardButton}
                  onClick={() => this.handleFTUXFinishButton()}
                >
                  Got it
                </Button>
              </CardActions>
            </Card>
          </Fade>
        )}
      </Popper>
    );
  };

  renderFTUXStep2 = () => {
    const { classes } = this.props;
    const { ftuxOpen } = this.state;
    return (
      <Popper
        className={classes.popper}
        open={ftuxOpen}
        anchorEl={this.datePickerRef.current}
        placement="left"
        onRequestClose={this.handleFTUXFinish}
        modifiers={[
          {
            preventOverflow: {
              enabled: false,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            in={ftuxOpen}
            timeout={{
              enter: 350,
              exit: 100,
            }}
          >
            <Card className={classes.step2Container}>
              <CardContent>
                <Typography
                  className={classes.cardHeader}
                  variant="h5"
                  component="div"
                  color="text.secondary"
                  gutterBottom
                >
                  Select a custom range
                </Typography>
                <Typography component="div" className={classes.cardText}>
                  By default, we show your last 10 meetings on the dashboard.
                  You can now adjust the time range for which meetings to show
                  on your dashboard.
                </Typography>
              </CardContent>
              <CardActions style={{ marginTop: "-20px" }}>
                <Button
                  color="success"
                  variant="contained"
                  className={classes.cardButton}
                  onClick={() => this.handleFTUXFinishButton()}
                >
                  Try it out
                </Button>
              </CardActions>
            </Card>
          </Fade>
        )}
      </Popper>
    );
  };

  renderDateRangeSelector = () => {
    const {
      classes,
      pastMeetings,
      startDate,
      endDate,
      selectingStartDate,
      selectingEndDate,
      datePickerAnchor,
      handleOpenDatePicker,
      handleCloseDatePicker,
      handleDateChange,
    } = this.props;
    const rangeSpecified = startDate && endDate;
    const sameYear = rangeSpecified
      ? startDate.getFullYear() === endDate.getFullYear()
      : false;
    return (
      <>
        <span className={classes.selectable} onClick={handleOpenDatePicker}>
          {rangeSpecified ? (
            <span>
              meetings{" "}
              {getDateRangeString(
                startDate,
                endDate,
                sameYear ? rangeFormat : rangeFormatWithYear
              )}
            </span>
          ) : (
            <span>last {pastMeetings.length} meetings</span>
          )}
        </span>
        <Menu
          anchorEl={datePickerAnchor}
          open={datePickerAnchor != null}
          onClose={handleCloseDatePicker}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ transform: "translateY(30px)" }}
        >
          <div className={classes.datepickerContainer}>
            <DateRangePicker
              onChange={handleDateChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={[
                {
                  startDate: selectingStartDate,
                  endDate: selectingEndDate,
                  key: "selection",
                },
              ]}
              direction="horizontal"
            />
          </div>
        </Menu>
      </>
    );
  };

  render() {
    const { classes, pastMeetings, isLoading, startDate, endDate } = this.props;
    const firstName = localStorage.getItem("firstName");
    const noMeetings =
      pastMeetings.length === 0 && (startDate == null || endDate == null);

    return (
      <>
        <header className={classes.header} ref={this.headerRef}>
          <Typography variant="h4" className={classes.welcomeBanner}>
            <div className={classes.bannerTextHeavy}>
              Welcome back{firstName && <span>, {firstName}</span>}!
            </div>
            <div className={classes.bannerTextLight}>
              It's been a busy week.
            </div>
            <div className={classes.bannerTextLight} ref={this.datePickerRef}>
              {isLoading ? (
                <span>
                  Please wait a moment while we retrieve your meetings...
                </span>
              ) : noMeetings ? (
                <span>Looks like you haven't had any meetings yet.</span>
              ) : (
                <span>
                  Here's an overview of your {this.renderDateRangeSelector()}.
                </span>
              )}
            </div>
            <img
              src="/sonero-static/images/meeting_illustration.png"
              alt=""
              className={classes.bannerImage}
            />
          </Typography>
        </header>
        {this.renderFTUX()}
      </>
    );
  }
}

export default withStyles(styles)(Header);
