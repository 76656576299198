import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { ViewBookmarks } from "views/ViewMeeting/components";
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from "components";

// Component styles
const styles = (theme) => ({
  root: {},
  bookmarksContainer: {
    height: "100%",
  },
  bookmarksContent: {
    maxHeight: "350px",
    overflow: "scroll",
  },
});

class Bookmarks extends Component {
  handleBookmarkSelect = (startTimestamp, endTimestamp) => {
    const videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.currentTime = startTimestamp;
    videoPlayer.addEventListener("timeupdate", () => {
      if (endTimestamp && videoPlayer.currentTime > endTimestamp) {
        videoPlayer.pause();
      }
    });
    videoPlayer.play();
  };

  handleBookmarkError = (error) => {
    this.props.openSnackbar(error);
  };

  render() {
    const { classes, bookmarks, openSnackbar, ...rest } = this.props;

    return (
      <Portlet {...rest} className={classes.bookmarksContainer}>
        <PortletHeader>
          <PortletLabel title="Bookmarks" />
        </PortletHeader>
        <PortletContent className={classes.bookmarksContent}>
          <ViewBookmarks
            open={true}
            bookmarks={bookmarks}
            isSharedMeetingView={true}
            handleBookmarkSelect={this.handleBookmarkSelect}
            handleBookmarkDelete={() => {}}
            handleBookmarkError={this.handleBookmarkError}
            handleBookmarkEdit={() => {}}
            closeHandler={() => {}}
            isModal={false}
            hideHint={true}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

Bookmarks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Bookmarks);
