import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Grid, CircularProgress, Snackbar } from "@material-ui/core";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components
import {
  Notifications,
  SharingPrefs,
  GCalPrefs,
  AssistantPrefs,
} from "./components";

import {
  getProfile,
  postNotificationSettings,
  updateUserShareGranularity,
  getOrgSettings,
  updateOrgSettings,
  getOrgSharePrefs,
  updateOrgSharePrefs,
} from "services/speech";

import { isUserOrgOwner } from "helpers/user";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  toggleButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing.unit * 4,
  },
});

class Settings extends Component {
  state = {
    isLoading: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    tab: "my-settings",
    orgSettings: {},
    orgSharePrefs: {},
  };

  componentWillMount() {
    this.setState({
      isLoading: true,
    });

    const requests = [];
    requests.push(getProfile());
    requests.push(this.getOrgSettingsAndPrefs());

    Promise.all(requests).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  getOrgSettingsAndPrefs = () => {
    return getOrgSettings()
      .then((response) => {
        if (response.status === 200) {
          this.setState({ orgSettings: response.data });
        }
        return getOrgSharePrefs();
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ orgSharePrefs: response.data });
        }
      });
  };

  postNotificationSettingsToRemote = (notificationSettings) => {
    this.setState({ isLoading: true });

    if (this.state.tab === "my-settings") {
      return postNotificationSettings(notificationSettings).then(
        function(response) {
          if (response.status === 200) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Settings updated",
              isLoading: false,
            });
            ReactGA.event({
              category: "Settings",
              action: "Update meeting settings API success",
            });
          } else if (response.status === 401) {
            this.props.history.push("/sign-in");
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Sorry, an error occurred. Please try again.",
              isLoading: false,
            });
            ReactGA.event({
              category: "Failure",
              action: "Update meeting settings API failed",
            });
          }
          return response;
        }.bind(this)
      );
    } else {
      return updateOrgSettings(notificationSettings).then(
        function(response) {
          if (response.status === 200) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Settings updated",
              isLoading: false,
              orgSettings: response.data,
            });
            ReactGA.event({
              category: "Settings",
              action: "Update org settings API success",
            });
          } else if (response.status === 401) {
            this.props.history.push("/sign-in");
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Sorry, an error occurred. Please try again.",
              isLoading: false,
            });
            ReactGA.event({
              category: "Failure",
              action: "Update org settings API failed",
            });
          }
          return response;
        }.bind(this)
      );
    }
  };

  postSharePrefsToRemote = (sharePrefs) => {
    if (this.state.tab === "my-settings") {
      return updateUserShareGranularity(sharePrefs);
    } else {
      return updateOrgSharePrefs(sharePrefs).then((response) => {
        if (response.status === 200) {
          this.setState({ orgSharePrefs: response.data });
        }
      });
    }
  };

  handleSnakbarOpen = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Settings">
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <DashboardLayout title="Settings">
        <div className={classes.root}>
          {isUserOrgOwner() && (
            <div className={classes.toggleButtonsContainer}>
              <ToggleButtonGroup
                value={this.state.tab}
                onChange={(_event, newValue) =>
                  this.setState({ tab: newValue })
                }
                exclusive
              >
                <ToggleButton value="my-settings">My Settings</ToggleButton>
                <ToggleButton value="org-settings">Org Settings</ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            justify="center"
            alignContent="center"
            spacing={4}
          >
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <AssistantPrefs
                className={classes.item}
                saveHandler={this.postNotificationSettingsToRemote}
                onOrgTab={this.state.tab === "org-settings"}
                orgSettings={this.state.orgSettings}
                openSnackbar={this.handleSnakbarOpen}
              />
            </Grid>
          </Grid>
          <Grid
            container
            wrap="wrap"
            justify="center"
            alignContent="center"
            spacing={4}
          >
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <SharingPrefs
                className={classes.item}
                saveHandler={this.postNotificationSettingsToRemote}
                postSharePrefsToRemote={this.postSharePrefsToRemote}
                onOrgTab={this.state.tab === "org-settings"}
                orgSettings={this.state.orgSettings}
                orgSharePrefs={this.state.orgSharePrefs}
                openSnackbar={this.handleSnakbarOpen}
              />
            </Grid>
          </Grid>
          <Grid
            container
            wrap="wrap"
            justify="center"
            alignContent="center"
            spacing={4}
          >
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <GCalPrefs
                className={classes.item}
                saveHandler={this.postNotificationSettingsToRemote}
                onOrgTab={this.state.tab === "org-settings"}
                orgSettings={this.state.orgSettings}
                openSnackbar={this.handleSnakbarOpen}
              />
            </Grid>
          </Grid>
          <Grid
            container
            wrap="wrap"
            justify="center"
            alignContent="center"
            spacing={4}
          >
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Notifications
                className={classes.item}
                saveHandler={this.postNotificationSettingsToRemote}
                onOrgTab={this.state.tab === "org-settings"}
                orgSettings={this.state.orgSettings}
                openSnackbar={this.handleSnakbarOpen}
              />
            </Grid>
          </Grid>
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
        </div>
      </DashboardLayout>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
