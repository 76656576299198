import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography, Button, Checkbox } from "@material-ui/core";

import { Portlet } from "components";

// Stripe
import { CardElement } from "@stripe/react-stripe-js";

import styles from "./styles";

const cardOptions = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#0767DB",
      color: "#0767DB",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#0767DB",
      },
      "::placeholder": {
        color: "#66788A",
      },
      lineHeight: "50px",
    },
    invalid: {
      iconColor: "#ED4740",
      color: "#ED4740",
    },
  },
};

class CreditCardWidget extends Component {
  state = {
    cardComplete: false,
    processing: false,
    paymentMethod: null,
    subscriptionStatus: "",
    billingDetails: {
      name: "",
      email: "",
    },
    termsChecked: false,
  };

  componentWillMount() {
    var fullName = localStorage.getItem("fullName");
    var email = localStorage.getItem("email");
    this.setState({
      billingDetails: {
        name: fullName,
        email: email,
      },
      subscriptionStatus: this.props.subscriptionStatus,
    });
  }

  handleTermsCheckbox = (event) => {
    this.setState({ termsChecked: event.target.checked });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const {
      stripe,
      elements,
      numOfMinLicenses,
      numOfUsers,
      error,
    } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (numOfUsers < numOfMinLicenses) {
      this.props.handleErrorChange({
        message:
          "At a minimum, you need to purchase a number of licenses for the total users you have in your organization.",
      });
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (this.state.cardComplete) {
      this.setState({ processing: true });
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: this.state.billingDetails,
    });

    if (payload.error) {
      this.props.handleErrorChange(payload.error);
    } else {
      var paymentMethodId = payload.paymentMethod.id;
      switch (this.state.subscriptionStatus) {
        case "active":
        case "past_due":
        case "incomplete":
          this.props.updateSubscriptionWithNewPaymentMethod(paymentMethodId);
          break;
        case "unpaid":
        case "canceled":
        default:
          this.props.createNewSubscription(
            this.state.billingDetails,
            paymentMethodId,
            this.props.numOfUsers,
            this.props.discountCode
          );
      }
    }

    this.setState({ processing: false });
  };

  renderErrorMessage = () => {
    const { classes, className } = this.props;
    return (
      <div className={classes.errorMessage} role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
          <path
            fill="#ED4740"
            d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
          />
          <path
            fill="#FFF"
            d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
          />
        </svg>
        &nbsp;&nbsp;{this.props.error.message}
      </div>
    );
  };

  renderBillingDetailsTextFields = () => {
    const { classes } = this.props;
    return (
      <fieldset className={classes.textFieldsContainer}>
        <div className={classes.nameField}>
          <label htmlFor="name" className={classes.textFieldLabel}>
            Name
          </label>
          <input
            className={classes.textField}
            id="name"
            type="text"
            placeholder=""
            required
            autoComplete="name"
            value={this.state.billingDetails.name}
            onChange={(e) => {
              this.setState({
                billingDetails: {
                  name: e.target.value,
                  email: this.state.billingDetails.email,
                },
              });
              this.props.handleErrorChange(null);
            }}
          />
        </div>
        <div className={classes.emailField}>
          <label htmlFor="email" className={classes.textFieldLabel}>
            Email
          </label>
          <input
            className={classes.textField}
            id="email"
            type="email"
            placeholder=""
            required
            autoComplete="email"
            value={this.state.billingDetails.email}
            onChange={(e) => {
              this.setState({
                billingDetails: {
                  email: e.target.value,
                  name: this.state.billingDetails.name,
                },
              });
              this.props.handleErrorChange(null);
            }}
          />
        </div>
      </fieldset>
    );
  };

  renderBillingDetailsTextFieldsIfNeeded = () => {
    switch (this.state.subscriptionStatus) {
      case "active":
      case "past_due":
      case "incomplete":
        return;
      case "canceled":
      default:
        return this.renderBillingDetailsTextFields();
    }
  };

  renderTermsCheckboxIfNeeded = () => {
    const { classes } = this.props;
    switch (this.state.subscriptionStatus) {
      case "active":
      case "past_due":
      case "incomplete":
        return;
      case "canceled":
      default:
        return (
          <div className={classes.policy}>
            <Checkbox
              checked={this.state.termsChecked}
              className={classes.policyCheckbox}
              color="primary"
              name="policy"
              required
              onChange={this.handleTermsCheckbox}
            />
            <Typography className={classes.policyText} variant="body1">
              I have read the&nbsp;
              <a
                className={classes.policyUrl}
                href="https://sonero.ai/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions&nbsp;
              </a>
            </Typography>
          </div>
        );
    }
  };

  render() {
    const { stripe, classes } = this.props;
    return (
      <Portlet>
        <div className={classes.container}>
          <form onSubmit={this.handleSubmit}>
            {this.renderBillingDetailsTextFieldsIfNeeded()}
            <div className={classes.cardElement}>
              <CardElement
                options={cardOptions}
                onChange={(e) => {
                  this.setState({
                    cardComplete: e.complete,
                  });
                  this.props.handleErrorChange(e.error);
                }}
              />
            </div>
            {this.renderTermsCheckboxIfNeeded()}
            {this.props.error && this.renderErrorMessage()}
            <div className={classes.payButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.payButton}
                disabled={this.state.processing || !stripe}
              >
                {this.state.processing ? "Processing..." : "SUBMIT"}
              </Button>
            </div>
          </form>
        </div>
      </Portlet>
    );
  }
}

CreditCardWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditCardWidget);
