import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Paper, ProcessingInProgress } from "components";
import { getSpeakerString } from "helpers/meetingParticipants";
import styles from "./styles";
import { ResponsiveBar } from "@nivo/bar";
import { speechStatus } from "helpers";

const yOffset = 55;

class EnergyGraph extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.getGraphData();
  }

  getGraphData = () => {
    const analytics = this.props.data.analytics;
    if (!analytics.intensity && !analytics.intensity_level) {
      return;
    }
    const energy = analytics.intensity;
    const energyLevels = analytics.intensity_level;

    const participantsDetails = this.props.data.participants_details;
    const data = [];
    for (let i = 0; i < energy.length; i++) {
      data.push({
        speaker: getSpeakerString(energy[i].speaker_tag, participantsDetails),
        energy: energy[i].intensity,
        energyLevel: Math.max(energyLevels[i].intensity_level - yOffset, 0),
      });
    }
    data.sort((first, second) => {
      if (first.speaker < second.speaker) {
        return -1;
      } else if (first.speaker > second.speaker) {
        return 1;
      }
      return 0;
    });
    this.setState({ data: data });
  };

  render() {
    const { classes, className, meetingStatus, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    if (
      meetingStatus === speechStatus.completed &&
      this.state.data.length === 0
    ) {
      return <></>;
    }

    return (
      <div className={className}>
        {this.props.data.analytics && (
          <Paper {...rest} className={rootClassName}>
            <div className={classes.firstContent}>
              <div className={classes.iconWrapper}>
                <AssessmentIcon className={classes.icon} />
              </div>
              <div className={classes.details}>
                <Typography className={classes.title} variant="h5">
                  ENERGY
                </Typography>
              </div>
            </div>
            {meetingStatus === speechStatus.completed && (
              <div
                className={classes.content}
                onMouseEnter={this.props.handleOnMouseEnter}
                onMouseLeave={() =>
                  this.props.handleOnMouseLeave("energyGraph")
                }
              >
                <ResponsiveBar
                  data={this.state.data}
                  keys={["energyLevel"]}
                  layout="vertical"
                  groupMode="grouped"
                  padding={0.5}
                  innerPadding={25}
                  margin={{ top: 30, right: 80, bottom: 60, left: 80 }}
                  colors={{ scheme: "dark2" }}
                  colorBy="indexValue"
                  indexBy="speaker"
                  axisBottom={{
                    tickSize: 2.5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Participants",
                    legendPosition: "middle",
                    legendOffset: 40,
                    format: (speaker) => {
                      if (speaker.length > 9) {
                        return speaker.slice(0, 9) + "...";
                      } else {
                        return speaker;
                      }
                    },
                  }}
                  axisLeft={{
                    tickValues: [
                      55 - yOffset,
                      60 - yOffset,
                      65 - yOffset,
                      70 - yOffset,
                      75 - yOffset,
                    ],
                    format: (value) => {
                      if (value === 55 - yOffset) {
                        return "Very Low";
                      } else if (value === 60 - yOffset) {
                        return "Low";
                      } else if (value === 65 - yOffset) {
                        return "Moderate";
                      } else if (value === 70 - yOffset) {
                        return "High";
                      } else if (value === 75 - yOffset) {
                        return "Very High";
                      } else {
                        return "";
                      }
                    },
                  }}
                  maxValue={75 - yOffset}
                  enableGridX={true}
                  enableGridY={true}
                  gridXValues={[0]}
                  gridYValues={[
                    55 - yOffset,
                    60 - yOffset,
                    65 - yOffset,
                    70 - yOffset,
                    75 - yOffset,
                  ]}
                  label={() => ""}
                  tooltip={(energy) => (
                    <div>
                      {energy.data.speaker} spoke with{" "}
                      <strong>{energy.data.energy} energy</strong>
                    </div>
                  )}
                />
              </div>
            )}
            {meetingStatus === speechStatus.processing && (
              <ProcessingInProgress copy="Processing Analytics..." />
            )}
          </Paper>
        )}
      </div>
    );
  }
}

EnergyGraph.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnergyGraph);
