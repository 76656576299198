import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";
import styles from "./styles";
import theme from "theme";

import { Tooltip } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab";
import { Help } from "@material-ui/icons";
import { dateFormatter, getDateRangeString } from "helpers/time";
import { getScoreColor, subScoreLabels } from "helpers/meetingScore";
import {
  OverallScore,
  MyPerformance,
  MeetingEfficiency,
  AudienceEngagement,
  AttendeeFeedback,
  OverTimeGraph,
} from "./components";
import { CircularBar } from "components";

import ReactGA from "react-ga";
import { gaCategoryDashboard } from "helpers/gaUtil";

const tabs = {
  overview: "Overview",
  timeline: "Timeline",
  bestMeeting: "Best Meeting",
  worstMeeting: "Worst Meeting",
};

const goodMeetingScoreLimit = 5.5; // A good meeting score is from 5.5 -> 10
const rangeFormat = { month: "2-digit", day: "2-digit" };

class MeetingScore extends Component {
  state = {
    avgOverallScore: 0,
    avgPerformanceScore: 0,
    avgEfficiencyScore: 0,
    avgEngagementScore: 0,
    avgFeedbackScore: 0,
    avgPerformance: {},
    avgEfficiency: {},
    avgEngagement: {},
    avgFeedback: {},
    overallScores: {},
    expanded: "",
    scoreTab: tabs.overview,
  };
  timeSpentHover = new Date();

  componentDidMount() {
    const meetingScore = this.props.meetingScore;
    const avgOverallScore = meetingScore.overall_score;
    const avgPerformanceScore = meetingScore.sub_scores.my_performance ?? -1;
    const avgEfficiencyScore = meetingScore.sub_scores.meeting_efficiency ?? -1;
    const avgEngagementScore =
      meetingScore.sub_scores.audience_engagement ?? -1;
    const avgFeedbackScore = meetingScore.sub_scores.attendee_feedback ?? -1;
    const avgPerformance = meetingScore.score_labels.my_performance ?? {};
    const avgEfficiency = meetingScore.score_labels.meeting_efficiency ?? {};
    const avgEngagement = meetingScore.score_labels.audience_engagement ?? {};
    const avgFeedback = meetingScore.score_labels.attendee_feedback ?? {};
    const overallScores = meetingScore.scores;
    this.setState({
      avgOverallScore,
      avgPerformanceScore,
      avgEfficiencyScore,
      avgEngagementScore,
      avgFeedbackScore,
      avgPerformance,
      avgEfficiency,
      avgEngagement,
      avgFeedback,
      overallScores,
    });
  }

  handleExpand = (subScore) => {
    this.setState({ expanded: subScore });
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User expanded ${subScore} score`,
    });
  };

  handleCollapse = () => {
    this.setState({ expanded: "" });
  };

  handleTabChange = (tab) => {
    this.setState({ scoreTab: tab });
  };

  handleOnClickOverview = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Overview Tab",
      label: "Meeting Efficiency",
    });
  };

  handleOnClickTimeline = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Timeline Tab",
      label: "Meeting Efficiency",
    });
  };

  handleOnClickBestMeeting = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Best Meeting Tab",
      label: "Meeting Efficiency",
    });
  };

  handleOnClickWorstMeeting = () => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User Clicked Worst Meeting Tab",
      label: "Meeting Efficiency",
    });
  };

  handleOnMouseEnter = () => {
    this.timeSpentHover = new Date();
  };

  handleOnMouseLeave = () => {
    let timeSpent = new Date() - this.timeSpentHover;
    const { scoreTab } = this.state;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User Hovered ${
        scoreTab === tabs.overview
          ? "Overview"
          : scoreTab === tabs.timeline
          ? "Timeline"
          : scoreTab === tabs.bestMeeting
          ? "Best Meeting"
          : "Worst Meeting"
      } tab in Meeting Efficiency Widget`,
      value: timeSpent,
    });
  };

  findMeetingById = (meetingId) => {
    return this.props.pastMeetings.find((meeting) => {
      return meeting.meeting_id === meetingId;
    });
  };

  findMeetingWithBestScore = () => {
    let meetingScore = this.props.meetingScore;
    if (Object.keys(meetingScore.scores).length === 0) return;
    let bestMeetingId = parseInt(
      Object.keys(meetingScore.scores).reduce((a, b) =>
        meetingScore.scores[a] > meetingScore.scores[b] ? a : b
      )
    );

    return this.findMeetingById(bestMeetingId);
  };

  findMeetingWithWorstScore = () => {
    let meetingScore = this.props.meetingScore;
    if (Object.keys(meetingScore.scores).length === 0) return;
    let worstMeetingId = parseInt(
      Object.keys(meetingScore.scores).reduce((a, b) =>
        meetingScore.scores[a] < meetingScore.scores[b] ? a : b
      )
    );

    return this.findMeetingById(worstMeetingId);
  };

  renderMyPerformanceView = () => {
    const { avgPerformanceScore, avgPerformance } = this.state;
    let fillerWpm = 0;
    const meetingScoreObj = this.props.meetingScore.score_obj;
    if (
      meetingScoreObj.my_performance &&
      meetingScoreObj.my_performance.filler_per_min != null
    ) {
      fillerWpm = meetingScoreObj.my_performance.filler_per_min;
    }
    return (
      <MyPerformance
        avgPerformanceScore={avgPerformanceScore}
        avgPerformance={avgPerformance}
        handleCollapse={this.handleCollapse}
        fillerWpm={fillerWpm}
        memberName={this.props.memberName}
      />
    );
  };

  renderMyPerformanceSingleMeeting = (bestMeetingScore) => {
    const avgPerformanceScore =
      bestMeetingScore.sub_scores.my_performance ?? -1;
    const avgPerformance = bestMeetingScore.score_labels.my_performance ?? {};
    let fillerWpm = 0;
    const meetingScoreObj = bestMeetingScore.score_obj;
    if (
      meetingScoreObj.my_performance &&
      meetingScoreObj.my_performance.filler_per_min != null
    ) {
      fillerWpm = meetingScoreObj.my_performance.filler_per_min;
    }
    return (
      <MyPerformance
        avgPerformanceScore={avgPerformanceScore}
        avgPerformance={avgPerformance}
        sharedView={false}
        handleCollapse={this.handleCollapse}
        fillerWpm={fillerWpm}
        memberName={this.props.memberName}
      />
    );
  };

  renderMeetingEfficiencyView = () => {
    const { avgEfficiencyScore, avgEfficiency } = this.state;
    return (
      <MeetingEfficiency
        avgEfficiencyScore={avgEfficiencyScore}
        avgEfficiency={avgEfficiency}
        handleCollapse={this.handleCollapse}
      />
    );
  };

  renderMeetingEfficiencySingleMeeting = (bestMeetingScore) => {
    const avgEfficiencyScore =
      bestMeetingScore.sub_scores.meeting_efficiency ?? -1;
    const avgEfficiency =
      bestMeetingScore.score_labels.meeting_efficiency ?? {};
    return (
      <MeetingEfficiency
        avgEfficiencyScore={avgEfficiencyScore}
        avgEfficiency={avgEfficiency}
        sharedView={false}
        handleCollapse={this.handleCollapse}
      />
    );
  };

  renderAudienceEngagementView = () => {
    const { avgEngagementScore, avgEngagement } = this.state;
    return (
      <AudienceEngagement
        avgEngagementScore={avgEngagementScore}
        avgEngagement={avgEngagement}
        handleCollapse={this.handleCollapse}
      />
    );
  };

  renderAudienceEngagementSingleMeeting = (bestMeetingScore) => {
    const avgEngagementScore =
      bestMeetingScore.sub_scores.audience_engagement ?? -1;
    const avgEngagement =
      bestMeetingScore.score_labels.audience_engagement ?? {};
    return (
      <AudienceEngagement
        avgEngagementScore={avgEngagementScore}
        avgEngagement={avgEngagement}
        handleCollapse={this.handleCollapse}
      />
    );
  };

  renderAttendeeFeedbackView = () => {
    const { avgFeedbackScore, avgFeedback } = this.state;
    return (
      <AttendeeFeedback
        avgFeedbackScore={avgFeedbackScore}
        avgFeedback={avgFeedback}
        handleCollapse={this.handleCollapse}
      />
    );
  };

  renderAttendeeFeedbackSingleMeeting = (bestMeetingScore) => {
    const avgFeedbackScore =
      bestMeetingScore.sub_scores.attendee_feedback ?? -1;
    const avgFeedback = bestMeetingScore.score_labels.attendee_feedback ?? {};
    return (
      <AttendeeFeedback
        avgFeedbackScore={avgFeedbackScore}
        avgFeedback={avgFeedback}
        handleCollapse={this.handleCollapse}
      />
    );
  };

  renderOverallView = (
    performanceScore,
    efficiencyScore,
    engagementScore,
    feedbackScore
  ) => {
    return (
      <OverallScore
        avgPerformanceScore={performanceScore}
        avgEfficiencyScore={efficiencyScore}
        avgEngagementScore={engagementScore}
        avgFeedbackScore={feedbackScore}
        handleExpand={this.handleExpand}
        memberName={this.props.memberName}
      />
    );
  };

  renderOverview = () => {
    const { classes } = this.props;
    const { avgOverallScore, expanded } = this.state;
    const isEmpty = avgOverallScore === -1;
    const {
      avgPerformanceScore,
      avgEfficiencyScore,
      avgEngagementScore,
      avgFeedbackScore,
    } = this.state;

    return (
      <div
        className={classes.meetingScoresContent + (isEmpty ? " isEmpty" : "")}
      >
        <div className={classes.overallScoreContainer}>
          <CircularBar
            barPercentage={avgOverallScore * 10}
            barFillColor={getScoreColor(avgOverallScore)}
            barEmptyColor={theme.palette.background.grey}
            bgColor={theme.palette.common.white}
          >
            <div className={classes.score + " overall"}>
              {avgOverallScore >= 0 ? Math.round(avgOverallScore) : 0}
              <span className={classes.outOfTen}>/10</span>
            </div>
          </CircularBar>
        </div>
        {expanded === subScoreLabels.performance &&
          this.renderMyPerformanceView()}
        {expanded === subScoreLabels.efficiency &&
          this.renderMeetingEfficiencyView()}
        {expanded === subScoreLabels.engagement &&
          this.renderAudienceEngagementView()}
        {expanded === subScoreLabels.feedback &&
          this.renderAttendeeFeedbackView()}
        {expanded === "" &&
          this.renderOverallView(
            avgPerformanceScore,
            avgEfficiencyScore,
            avgEngagementScore,
            avgFeedbackScore
          )}
      </div>
    );
  };

  renderTimeline = () => {
    const { pastMeetings } = this.props;
    const { overallScores } = this.state;
    const overallScoresKeys = Object.keys(overallScores);
    const data = [
      {
        id: "data",
        data: overallScoresKeys.map((meetingId, idx) => {
          const meeting = pastMeetings.find(
            (meeting) => meeting.meeting_id === +meetingId
          );
          return {
            uuid: meeting.uuid,
            x: `${meeting.title} - ${dateFormatter(meeting.start_time)}`,
            y: overallScores[meetingId],
            firstHalf: idx < overallScoresKeys.length / 2,
          };
        }),
      },
    ];

    return (
      <OverTimeGraph
        data={data}
        handleNavigateToMeeting={this.props.handleNavigateToMeeting}
      />
    );
  };

  renderMeetingGauge = (meeting, meetingScore) => {
    const { classes, history } = this.props;
    const overallScore = meetingScore ? meetingScore.overall_score : 0;

    return (
      <div className={classes.overallScoreContainer}>
        <CircularBar
          barPercentage={overallScore * 10}
          barFillColor={getScoreColor(overallScore)}
          barEmptyColor={theme.palette.background.grey}
          bgColor={theme.palette.common.white}
        >
          <div className={classes.score + " overall"}>
            {overallScore >= 0 ? Math.round(overallScore) : 0}
            <span className={classes.outOfTen}>/10</span>
          </div>
        </CircularBar>
        <Typography
          variant="h4"
          className={classes.meetingNameText}
          onClick={() => {
            history.push(`/view-meeting/${meeting.uuid}`);
          }}
        >
          {meeting ? meeting.title : ""}
        </Typography>
      </div>
    );
  };

  renderBestMeeting = () => {
    const { classes } = this.props;
    const {
      numMeetingsWithScore,
      numMeetingsWithMyPerformance,
      numMeetingsWithFeedback,
      expanded,
    } = this.state;

    const isEmpty =
      numMeetingsWithScore === 0 &&
      numMeetingsWithMyPerformance === 0 &&
      numMeetingsWithFeedback === 0;

    let bestMeeting = this.findMeetingWithBestScore();
    let bestMeetingScore = bestMeeting
      ? bestMeeting.analytics.meeting_score
      : {};
    let subScores = bestMeetingScore.sub_scores;

    return (
      <div
        className={classes.meetingScoresContent + (isEmpty ? " isEmpty" : "")}
      >
        {this.renderMeetingGauge(bestMeeting, bestMeetingScore)}
        {expanded === subScoreLabels.performance &&
          this.renderMyPerformanceSingleMeeting(bestMeetingScore)}
        {expanded === subScoreLabels.efficiency &&
          this.renderMeetingEfficiencySingleMeeting(bestMeetingScore)}
        {expanded === subScoreLabels.engagement &&
          this.renderAudienceEngagementSingleMeeting(bestMeetingScore)}
        {expanded === subScoreLabels.feedback &&
          this.renderAttendeeFeedbackSingleMeeting(bestMeetingScore)}
        {expanded === "" &&
          this.renderOverallView(
            subScores ? subScores.my_performance : 0,
            subScores ? subScores.meeting_efficiency : 0,
            subScores ? subScores.audience_engagement : 0,
            subScores ? subScores.attendee_feedback : 0
          )}
      </div>
    );
  };

  renderWorstMeeting = () => {
    const { classes } = this.props;
    const {
      numMeetingsWithScore,
      numMeetingsWithMyPerformance,
      numMeetingsWithFeedback,
      expanded,
    } = this.state;

    const isEmpty =
      numMeetingsWithScore === 0 &&
      numMeetingsWithMyPerformance === 0 &&
      numMeetingsWithFeedback === 0;

    let worstMeeting = this.findMeetingWithWorstScore();
    let worstMeetingScore = worstMeeting
      ? worstMeeting.analytics.meeting_score
      : {};
    let subScores = worstMeetingScore.sub_scores;

    return (
      <div
        className={classes.meetingScoresContent + (isEmpty ? " isEmpty" : "")}
      >
        {this.renderMeetingGauge(worstMeeting, worstMeetingScore)}
        {expanded === subScoreLabels.performance &&
          this.renderMyPerformanceSingleMeeting(worstMeetingScore)}
        {expanded === subScoreLabels.efficiency &&
          this.renderMeetingEfficiencySingleMeeting(worstMeetingScore)}
        {expanded === subScoreLabels.engagement &&
          this.renderAudienceEngagementSingleMeeting(worstMeetingScore)}
        {expanded === subScoreLabels.feedback &&
          this.renderAttendeeFeedbackSingleMeeting(worstMeetingScore)}
        {expanded === "" &&
          this.renderOverallView(
            subScores ? subScores.my_performance : 0,
            subScores ? subScores.meeting_efficiency : 0,
            subScores ? subScores.audience_engagement : 0,
            subScores ? subScores.attendee_feedback : 0
          )}
      </div>
    );
  };

  renderToggleGroup = () => {
    const { classes } = this.props;
    const { scoreTab } = this.state;
    return (
      <div className={classes.tabButtonsContainer}>
        <ToggleButtonGroup
          value={scoreTab}
          onChange={(_event, newValue) => this.handleTabChange(newValue)}
          exclusive
        >
          <ToggleButton
            value={tabs.overview}
            className={classes.tabButton}
            classes={{ selected: classes.selected }}
            disabled={scoreTab === tabs.overview}
            onClick={this.handleOnClickOverview}
          >
            OVERVIEW
          </ToggleButton>
          <ToggleButton
            value={tabs.timeline}
            className={classes.tabButton}
            classes={{ selected: classes.selected }}
            disabled={scoreTab === tabs.timeline}
            onClick={this.handleOnClickTimeline}
          >
            TIMELINE
          </ToggleButton>
          <ToggleButton
            value={tabs.bestMeeting}
            className={classes.tabButton}
            classes={{ selected: classes.selected }}
            disabled={scoreTab === tabs.bestMeeting}
            onClick={this.handleOnClickBestMeeting}
          >
            BEST MEETING
          </ToggleButton>
          <ToggleButton
            value={tabs.worstMeeting}
            className={classes.tabButton}
            classes={{ selected: classes.selected }}
            disabled={scoreTab === tabs.worstMeeting}
            onClick={this.handleOnClickWorstMeeting}
          >
            WORST MEETING
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  render() {
    const { classes, startDate, endDate } = this.props;
    const { avgOverallScore, overallScores, scoreTab } = this.state;
    const numMeetingsWithScore = Object.keys(overallScores).length;
    const isEmpty = avgOverallScore === -1;
    const rangeSpecified = startDate && endDate;

    return (
      <div
        className={classes.meetingScoreContainer}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
      >
        <header className={classes.sectionHeader}>
          MEETING EFFICIENCY
          <Tooltip
            title={`Your Meeting Efficiency score is calculated from your last ${numMeetingsWithScore} meetings`}
          >
            <Help />
          </Tooltip>
        </header>
        {isEmpty && (
          <div className={classes.emptyTextContainer}>
            <Alert severity="warning" className={classes.emptyText}>
              {rangeSpecified
                ? `There are no meetings ${getDateRangeString(
                    startDate,
                    endDate,
                    rangeFormat
                  )}.`
                : "Use Sonero in a meeting to see your meeting efficiency score."}
            </Alert>
          </div>
        )}
        {this.renderToggleGroup()}
        <div className={classes.tabContent}>
          {scoreTab === tabs.overview && this.renderOverview()}
          {scoreTab === tabs.timeline && this.renderTimeline()}
          {scoreTab === tabs.bestMeeting && this.renderBestMeeting()}
          {scoreTab === tabs.worstMeeting && this.renderWorstMeeting()}
        </div>
      </div>
    );
  }
}

MeetingScore.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MeetingScore);
