import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { updateMeetingTasks } from "services/speech";

import styles from "./styles";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class AddMeetingTask extends Component {
  state = {
    showError: false,
    isLoading: false,
    meetingTask: "",
  };

  componentWillMount() {}

  setError = (message) => {
    this.setState({ showError: true, errorMsg: message });
  };

  handleClose = () => {
    this.setState({
      showError: false,
      meetingTask: "",
    });
    this.props.closeHandler();
  };

  handleUpdateTaskSuccess = (response) => {
    this.props.successHandler(response.data.tasks);
    this.handleClose();
  };

  handleUpdateTask = () => {
    if (!this.state.meetingTask) {
      this.setError("Please provide a next step");
      return;
    }
    this.setState({ isLoading: true, showError: false });
    const tasks = [...this.props.tasks];

    if (this.props.taskPos >= 0) {
      const task = tasks[this.props.taskPos];
      if (task.relevant_phrases) {
        task.relevant_phrases.push({ phrase: this.state.meetingTask });
      } else {
        task.relevant_phrases = [{ phrase: this.state.meetingTask }];
      }
    } else {
      if (tasks) {
        tasks.push({ action_item: this.state.meetingTask });
      }
    }
    updateMeetingTasks(this.props.meetingId, tasks).then(
      function(response) {
        this.setState({ isLoading: false });
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          this.setError(response.data.message);
          ReactGA.event({
            category: "Failure",
            action: "Update meeting task API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Update meeting task API success",
          });
          this.handleUpdateTaskSuccess(response);
        }
      }.bind(this)
    );
  };

  handleMeetingTaskChange = (value) => {
    this.setState({ meetingTask: value });
  };

  renderDialog = () => {
    const { classes, className, ...rest } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Next Step</DialogTitle>
        <DialogContent>
          {this.state.showError && (
            <Typography className={classes.fieldError} variant="body2">
              {this.state.errorMsg}
            </Typography>
          )}
          <TextField
            className={classes.addMeetingTaskTextField}
            label="Next Step"
            onChange={(event) =>
              this.handleMeetingTaskChange(event.target.value)
            }
            required
            value={this.state.meetingTask}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={() => this.handleUpdateTask()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    return <div>{this.renderDialog()}</div>;
  }
}

AddMeetingTask.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddMeetingTask);
