import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  proTrialCTAText: {
    color: theme.palette.text.secondary,
  },
  proTrialCTAContainer: {
    marginBottom: theme.spacing.unit * 4,
  },
  content: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  annualToggle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  monthlyText: {
    display: "block",
    width: "fit-content",
    fontWeight: 400,
  },
  annuallyText: {
    display: "block",
    width: "fit-content",
    fontWeight: 400,
  },
  infoText: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: "10px",
    backgroundColor: theme.palette.background.blue,
    fontWeight: 500,
  },
  optionsContainer: {
    display: "flex",
    gap: theme.spacing(3),
    alignItems: "stretch",
  },
  option: {
    width: "50%",
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    minHeight: "340px",
    display: "flex",
    flexDirection: "column",
  },
  optionTitle: {
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  optionPrice: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: theme.spacing(3),
    "& > span": {
      fontSize: "24px",
      fontWeight: 600,
    },
  },
  optionBody: {
    fontSize: "16px",
    fontWeight: 300,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.75),
  },
  optionButton: {
    display: "block",
    margin: "auto",
    marginBottom: 0,
    width: "fit-content",
    fontSize: "12px",
  },
  popular: {
    position: "relative",
    "&::before": {
      content: `"Most Popular"`,
      position: "absolute",
      top: "5px",
      right: "-10px",
      padding: `${theme.spacing(1)}px ${theme.spacing(1.25)}px`,
      backgroundColor: fade(theme.palette.background.blue, 0.9),
      borderRadius: "5px 5px 0 5px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    "&::after": {
      content: `""`,
      position: "absolute",
      top: "37px",
      right: "-10px",
      borderLeft: `10px solid ${fade(theme.palette.primary.main, 0.25)}`,
      borderBottom: "10px solid transparent",
      borderTop: "none",
      borderRight: "none",
    },
  },
});
