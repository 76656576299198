import React, { Component } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { ExternalSignUpLink } from "components";
import { Typography, Toolbar, CircularProgress } from "@material-ui/core";

import { getSharedMeetingClip } from "services/speech";
import { getTranscriptSegments } from "helpers/meetingTranscript";
import { getSpeakerString } from "helpers/meetingParticipants";
import { convertMilisecsToHigherOrder } from "helpers/time";

class ViewSharedClip extends Component {
  state = {
    clip: {},
    clipSegments: [],
    title: "",
    participantsDetails: [],
    error: "",
    isLoading: true,
  };

  componentDidMount() {
    const clipUuid = this.props.location.pathname.split("/").pop();
    getSharedMeetingClip(clipUuid).then((response) => {
      if (response.status === 200) {
        const data = response.data.data;
        const title = data.meeting_title;
        const clip = data.clip;
        const clipSegments = getTranscriptSegments(data.transcript_results);
        const participantsDetails = data.participants_details;
        this.setState({ clip, clipSegments, title, participantsDetails });
      } else if (response.status === 403) {
        this.setState({ error: "This clip is no longer being shared..." });
      } else {
        this.setState({
          error: "Sorry, an unknown error occurred. Please try again.",
        });
      }
      this.setState({ isLoading: false });
    });
  }

  renderHeader = () => {
    const { classes } = this.props;
    return (
      <Toolbar className={classes.header}>
        <div className={classes.logoWrapper}>
          <a href="https://sonero.ai/">
            <Typography
              className={classes.logoName}
              variant="h1"
              color="primary"
            >
              sonero
            </Typography>
          </a>
        </div>
        <Typography variant="h4">{this.state.title}</Typography>
        <div className={classes.signUpLink}>
          <ExternalSignUpLink onSignUp={() => {}} />
        </div>
      </Toolbar>
    );
  };

  renderVideoPlayer = () => {
    const { classes } = this.props;
    return (
      <div className={classes.videoContainer}>
        <video
          src={this.state.clip.url}
          className={classes.videoPlayer}
          controls
          controlsList="nodownload"
          preload="metadata"
        ></video>
      </div>
    );
  };

  renderClipTranscript = () => {
    const { classes } = this.props;
    const { clip, clipSegments, participantsDetails } = this.state;
    return (
      <div className={classes.clipTranscriptContainer}>
        <Typography variant="h4" className={classes.clipTranscriptHeader}>
          {clip.name ? clip.name : "Clip"}
        </Typography>
        <div className={classes.clipTranscriptSnippet}>
          {clipSegments.map((segment) => (
            <>
              <Typography variant="h6" className={classes.snippetSpeaker}>
                {getSpeakerString(segment.speaker, participantsDetails)}
              </Typography>
              <Typography variant="body1" className={classes.snippetBody}>
                {segment.transcript.map((transcriptSentence, i) => (
                  <p key={i}>{transcriptSentence.sentence}</p>
                ))}
              </Typography>
            </>
          ))}
        </div>
      </div>
    );
  };

  renderCommentSection = () => {
    const { classes } = this.props;
    const { clip, clipSegments, participantsDetails } = this.state;
    return (
      <div className={classes.commentSectionContainer}>
        <Typography variant="h4" className={classes.commentSectionHeader}>
          Comment Section
        </Typography>
        <div className={classes.commentSectionSnippet}>
          {clip.clip_comments && [...clip.clip_comments].reverse().map((comment) => (
            <div className={classes.commentSectionCommentContainer}>
              <Typography variant="h6" className={classes.commentSectionCommentHeader}>
                <span className={classes.commentSectionCommentName}>
                  {comment.user.first_name + " " + comment.user.last_name + " "}
                </span>
                <span className={classes.commentSectionCommentTimestamp}>
                  {convertMilisecsToHigherOrder(Math.abs(new Date() - new Date(comment.created)))} ago
                </span>
                {comment.edited && (
                  <span className={classes.commentSectionCommentEditedStatus}>
                    (edited)
                  </span>
                )}
              </Typography>
              <Typography variant="body1" className={classes.commentSectionCommentContext}>
                {comment.context}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { isLoading, error } = this.state;
    return (
      <>
        {this.renderHeader()}
        <div className={classes.content}>
          {isLoading && <CircularProgress />}
          {!isLoading && error ? (
            <Typography variant="h3" className={classes.errorText}>
              {error}
            </Typography>
          ) : (
            <>
              <div className={classes.clipInfo}>
                {this.renderVideoPlayer()}
                {this.renderClipTranscript()}
              </div>
              <div className={classes.clipComment}>
                {this.renderCommentSection()}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ViewSharedClip);
