import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Grid, Typography, CircularProgress } from "@material-ui/core";

// Shared services
import { confirmShared } from "services/speech";

import { Portlet, PortletContent } from "components";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  content: {
    marginTop: "50px",
    textAlign: "center",
  },
  image: {
    display: "inline-block",
    marginTop: "50px",
    maxWidth: "100%",
    width: "554px",
  },
});

class ConfirmShared extends Component {
  state = {
    isLoading: false,
    meetingTitle: "",
    ownerName: "",
    requesterEmail: "",
    requesterName: "",
    isOutputSent: false,
  };

  componentDidMount() {
    const meetingUuid = this.props.match.params.uuid;
    const queryParams = new URLSearchParams(this.props.location.search);
    const requesterEmail = queryParams.get("to");
    const requesterName = queryParams.get("to_name");

    confirmShared(meetingUuid, requesterEmail, requesterName).then(
      (response) => {
        this.setState({ isLoading: true });
        if (response.status === 200) {
          const meetingTitle = response.data.meeting_title;
          const ownerName = response.data.owner_name;
          this.setState({
            meetingTitle: meetingTitle,
            ownerName: ownerName,
            requesterEmail: requesterEmail,
            requesterName: requesterName,
            isOutputSent: true,
          });
        } else if (response.status === 401) {
          let redirect = "?redirect=" + this.props.location.pathname;
          if (this.props.location.search) {
            redirect += encodeURIComponent(this.props.location.search);
          }
          this.props.history.push("/sign-in" + redirect);
        } else if (response.status === 403) {
          this.setState({
            error: "Sorry, this meeting cannot be shared.",
          });
        } else {
          this.setState({
            error: "Sorry, an unknown error occurred. Please try again.",
          });
        }
        this.setState({ isLoading: false });
      }
    );
  }

  renderError = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Portlet>
            <PortletContent className={classes.content}>
              <Typography className={classes.error} variant="body1">
                {this.state.error}
              </Typography>
            </PortletContent>
          </Portlet>
        </div>
      </div>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  };

  renderConfirmSuccess = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={4}>
          <Grid item lg={6} xs={12}>
            <div className={classes.content}>
              <Typography variant="h1">
                Yay! We’ve shared the meeting output for{" "}
                {this.state.meetingTitle} with{" "}
                {this.state.requesterName == null ||
                this.state.requesterName == "Guest"
                  ? this.state.requesterEmail
                  : this.state.requesterName}
                .
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const isLoading = this.state.isLoading;
    if (this.state.error != null) {
      return this.renderError();
    }

    if (isLoading || !this.state.isOutputSent) {
      return this.renderLoading();
    }

    if (this.state.isOutputSent) {
      return this.renderConfirmSuccess();
    }
  }
}

ConfirmShared.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmShared);
