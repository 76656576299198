import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Link, Chip, Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';

// Material icons
import {
    Bookmark as CommentIcon
} from '@material-ui/icons';

// Shared components
import { Paper, TranscriptDialog } from 'components';

// Component styles
import styles from './styles';

// Palette
import palette from 'theme/palette';

import Highlighter from "react-highlight-words";

class TalkingPoints extends Component {

  state = {
    data: {},
    caption: "",
    talkingPoints: [],
    openTranscriptDialog: false,
    searchWord: ""
  }

  componentWillMount() {
    var talkingPoints = this.props.data.freq_noun_phrases
    this.setState({
        talkingPoints: talkingPoints,
        data: this.props.data
    })
  }

  handleOpenTranscript = (word) => {
    this.setState({openTranscriptDialog: true, searchWord: word})
  }

  handleCloseTranscript = () => {
    this.setState({openTranscriptDialog: false})
  }

  renderTranscriptDialog = () => {
    return (
      <TranscriptDialog data={this.state.data} searchWord={this.state.searchWord} open={this.state.openTranscriptDialog} closeHandler={this.handleCloseTranscript} setMediaPlayerCurrentTimeAndPlay={this.props.setMediaPlayerCurrentTimeAndPlay} />
    )
  }

  addTalkingPoints = () => {
    if (this.state.talkingPoints == null || this.state.talkingPoints.length == null || this.state.talkingPoints.length == 0) {
      return this.addEmptyState()
    }
    return (
      this.state.talkingPoints.map((word, index, {length}) => {
        return <Chip label={word} variant="outlined" color="primary" onClick={() => this.handleOpenTranscript(word)}/>
      })
    )
}

  addEmptyState = () => {
    const { classes, className, ...rest } = this.props;
    return (
        <div>
            <Typography
                className={classes.phrase}
                variant="body1"
            >
                It didn't seem like there were any major talking points from the meeting!
            </Typography>
        </div>
      )
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.iconWrapper}>
            <CommentIcon className={classes.icon} />
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h5"
            >
                TALKING POINTS
            </Typography>
          </div>
        </div>
        <div className={classes.pills}>{this.addTalkingPoints()}</div>
        {this.renderTranscriptDialog()}
      </Paper>
    );
  }
}

TalkingPoints.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TalkingPoints);
