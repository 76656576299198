import React, { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import {
  withStyles,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
} from "@material-ui/core";
import { Paper } from "components";
import { getMeetingSentimentFromPolarity } from "../../../../helpers/meetingSentiment";
import { timeFormatter } from "../../../../helpers/time";
import { getSpeakerString } from "helpers/meetingParticipants";
import { getTranscriptSegments } from "helpers/meetingTranscript";
import styles from "./styles";
import theme from "theme";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { People } from "@material-ui/icons";
import { CustomLinesLayer } from "components/Nivo/CustomLinesLayer";
import { CustomPointsLayer } from "components/Nivo/CustomPointsLayer";

const tooltipCharsThreshold = 100;

class FacialEmotionBreakdown extends Component {
  state = {
    activeSpeaker: "",
    activeSpeakerColor: "",
    pieData: [],
    timelineData: [],
    optionsMenuAnchorEl: null,
    assigningToFace: null,
  };
  emotionSummary = new Map();
  emotionSummaryTotal = 0;
  sentimentMap = new Map();
  videoEmotionMap = new Map();

  componentWillMount() {
    var videoEmotionData = this.props.data.analytics.video_emotions;
    for (const [index, videoEmotions] of Object.entries(videoEmotionData)) {
      for (var idx in videoEmotions) {
        var emotion = videoEmotions[idx];

        if (emotion.emotion) {
          let emotionCount = 0;
          if (this.emotionSummary.has(emotion.emotion)) {
            emotionCount = this.emotionSummary.get(emotion.emotion);
          }
          this.emotionSummary.set(emotion.emotion, emotionCount + 1);
          this.emotionSummaryTotal++;
        }

        var userEmotions = [];
        if (this.videoEmotionMap.has(emotion.participant)) {
          userEmotions = this.videoEmotionMap.get(emotion.participant);
        }
        userEmotions.push({
          x: index,
          y: this.mapEmotionToValue(emotion.emotion),
        });
        this.videoEmotionMap.set(emotion.participant, userEmotions);
      }
    }
    if (this.props.data.analytics.moments) {
      var moments = [];
      for (var i in this.props.data.analytics.moments) {
        var moment = this.props.data.analytics.moments[i];
        moments.push({
          x: moment.timestamp,
          y: moment.score,
        });
      }
      this.videoEmotionMap.set("moments", moments);
    }
    this.getSummaryChartDataFromEmotionSummary();
    this.getChartDataFromSentimentMap();
  }

  mapEmotionToValue = (value) => {
    if (value === "neutral") return 0;
    if (value === "displeased") return -1;
    if (value === "happy") return 1;
    if (value === "sad") return -0.5;
    if (value === "surprised") return 0.5;
  };

  getSummaryChartDataFromEmotionSummary = () => {
    var output = [];
    for (let [key, value] of this.emotionSummary.entries()) {
      output.push({ id: key, label: key, value: value });
    }
    const sortedOutput = output.sort(function(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    this.setState({ pieData: sortedOutput });
  };

  getChartDataFromSentimentMap = () => {
    var participantsDetails = this.props.data.participants_details;
    var output = [];
    var bucketTimeSliceSecs = 10;
    for (let [key, value] of this.videoEmotionMap.entries()) {
      output.push({
        id: key,
        data: this.smoothenData(value, bucketTimeSliceSecs),
      });
    }
    const sortedOutput = output.sort(function(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    this.setState({
      timelineData: sortedOutput,
    });
  };

  smoothenData = (data, bucketTimeSliceSecs) => {
    var buckets = [];
    var currentTimeSliceSentiment = 0;
    var currentTimeSlice = 0;
    var currentTimeSliceCount = 0;
    var currentTimeSliceSpikeX = 0;
    var currentTimeSliceSpikeY = 0;
    var currentBucketTimeSlice = bucketTimeSliceSecs;
    for (var sentimentData of data) {
      if (currentTimeSlice >= currentBucketTimeSlice) {
        buckets.push({
          x: currentTimeSliceSpikeX,
          y: currentTimeSliceSentiment / currentTimeSliceCount,
        });
        currentTimeSliceSentiment = sentimentData.y;
        currentTimeSliceCount = 1;
        currentTimeSlice = sentimentData.x;
        currentTimeSliceSpikeX = sentimentData.x;
        currentTimeSliceSpikeY = Math.abs(sentimentData.y);
        while (currentTimeSlice >= currentBucketTimeSlice) {
          currentBucketTimeSlice += bucketTimeSliceSecs;
        }
      } else {
        currentTimeSliceSentiment += sentimentData.y;
        currentTimeSliceCount++;
        currentTimeSlice = sentimentData.x;
        if (Math.abs(sentimentData.y) > currentTimeSliceSpikeY) {
          currentTimeSliceSpikeX = sentimentData.x;
          currentTimeSliceSpikeY = Math.abs(sentimentData.y);
        }
      }
    }
    buckets.push({
      x: currentTimeSliceSpikeX,
      y: currentTimeSliceSentiment / currentTimeSliceCount,
    });
    return buckets;
  };

  handleToggleClick = (speaker, speakerColor) => {
    if (this.state.activeSpeaker === speaker) {
      this.setState({
        activeSpeaker: null,
        activeSpeakerColor: null,
      });
    } else {
      this.setState({
        activeSpeaker: speaker,
        activeSpeakerColor: speakerColor,
      });
    }
  };

  renderTranscriptSegments = (transcriptResults) => {
    if (transcriptResults.length === 0) {
      return <></>;
    }

    const { classes } = this.props;
    const transcriptSegments = getTranscriptSegments(transcriptResults);
    const participantsDetails = this.props.data.participants_details;
    return (
      <>
        {transcriptSegments.map((segment) => (
          <div>
            <Typography variant="h6" className={classes.snippetSpeaker}>
              {getSpeakerString(segment.speaker, participantsDetails)}
            </Typography>
            <Typography variant="body1" className={classes.snippetBody}>
              {segment.transcript.map((transcriptSentence) => (
                <p>{transcriptSentence.sentence}</p>
              ))}
            </Typography>
          </div>
        ))}
      </>
    );
  };

  renderTimelineTooltip = ({ point }) => {
    const { classes } = this.props;
    const sentimentLabel = getMeetingSentimentFromPolarity(point.data.y);
    const transcriptResults = this.props.data.transcript_results;
    const timestamp = point.data.x;

    const shareGranularity = this.props.data.share_granularity;
    if (!shareGranularity.share_transcript) {
      return (
        <div className={classes.tooltipContainer}>
          <div className={classes.tooltipHeader}>
            <div className={classes.sentimentLabel + " " + sentimentLabel}>
              {sentimentLabel}
            </div>
            <Typography className={classes.tooltipText} variant="body">
              at <span>{timeFormatter(point.data.x)}</span>
            </Typography>
          </div>
        </div>
      );
    }

    const index = this.props.data.transcriptionResultsMap[timestamp.toString()];
    const results = [];
    const speakers = new Set();
    let totalNumChars = 0;
    if (index >= 0) {
      for (let i = index; i > index - 3; i--) {
        if (i < 0) break;
        if (totalNumChars > tooltipCharsThreshold) break;
        results.unshift(transcriptResults[i]);
        speakers.add(transcriptResults[i].speaker_tag);
        totalNumChars += transcriptResults[i].sentence.length;
      }
    }

    // Don't show the tooltip if this speaker didn't talk at the hovered timestamp
    const speakerData = this.state.timelineData.find(
      (d) => d.id === point.serieId
    );
    if (!speakerData || !speakers.has(speakerData.speakerTag)) {
      return <></>;
    }

    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipHeader}>
          <div className={classes.sentimentLabel + " " + sentimentLabel}>
            {sentimentLabel}
          </div>
          <Typography className={classes.tooltipText} variant="body">
            at <span>{timeFormatter(point.data.x)}</span>
          </Typography>
        </div>
        {this.renderTranscriptSegments(results)}
      </div>
    );
  };

  renderPieGraph = () => {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    return (
      <Paper
        {...rest}
        className={rootClassName}
        onMouseEnter={this.props.handleOnMouseEnter}
        onMouseLeave={() => this.props.handleOnMouseLeave("Facial Emotion Pie")}
      >
        <div className={classes.content} style={{ height: 200 }}>
          <ResponsivePie
            data={this.state.pieData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            innerRadius={0}
            padAngle={0}
            cornerRadius={0}
            enableRadialLabels={true}
            valueFormat={(val) => {
              return ((val / this.emotionSummaryTotal) * 100).toFixed(2) + "%";
            }}
            colors={{ scheme: "set1" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: "color" }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
            sliceLabel={(e) => {
              return (
                ((e.value / this.emotionSummaryTotal) * 100).toFixed(2) + "%"
              );
            }}
            height={200}
          />
        </div>
      </Paper>
    );
  };

  renderTimeline = () => {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    let data = this.state.timelineData;
    if (this.state.activeSpeaker) {
      data = data.filter(
        (timelineObj) => timelineObj.id === this.state.activeSpeaker.tag
      );
    }
    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.content} style={{ height: 350 }}>
          <ResponsiveLine
            onMouseEnter={this.props.handleOnMouseEnter}
            onMouseLeave={() =>
              this.props.handleOnMouseLeave("Facial Emotion Timeline")
            }
            data={data}
            yScale={{
              type: "linear",
              min: -1,
              max: 1,
            }}
            curve="monotoneX"
            margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
            xScale={{ type: "linear" }}
            colors={
              this.state.activeSpeakerColor
                ? this.state.activeSpeakerColor
                : theme.palette.nivoDark2
            }
            pointSize={5}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            axisBottom={{
              format: (value) => timeFormatter(value),
              legend: "Duration",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickValues: [-1, 0, 1],
              format: (value) => {
                if (value > 0.75) {
                  return "Happy";
                } else if (value <= 0.75 && value > 0.25) {
                  return "Surprised";
                } else if (value <= 0.25 && value >= -0.25) {
                  return "Neutral";
                } else if (value < -0.25 && value >= -0.75) {
                  return "Sad";
                } else {
                  return "Displeased";
                }
              },
              legend: "",
              legendOffset: 0,
            }}
            layers={[
              "grid",
              "markers",
              "areas",
              CustomLinesLayer,
              "axes",
              CustomPointsLayer,
              "crosshair",
              "mesh",
              "legends",
            ]}
            enableSlices={false}
            useMesh={true}
            crosshairType="bottom"
            tooltip={this.renderTimelineTooltip}
            onClick={(point) => {
              this.props.setMediaPlayerCurrentTimeAndPlay(
                point.data.x,
                "Facial Emotion Timeline"
              );
            }}
          />
        </div>
      </Paper>
    );
  };

  handleParticipantIconClick = (event, face) => {
    event.stopPropagation();
    this.setState({
      optionsMenuAnchorEl: event.currentTarget,
      assigningToFace: face,
    });
  };

  renderToggle = () => {
    const { classes } = this.props;
    const nivoDark2 = theme.palette.nivoDark2;

    return (
      <>
        {this.props.data.faces.map((face, idx) => {
          const speakerColor = nivoDark2[idx % nivoDark2.length];
          const matchingParticipant = this.props.data.participants_details.find(
            (element) => element.face_id === face.id
          );
          const slotName = matchingParticipant
            ? matchingParticipant.name
            : "Guest " + (face.tag + 1);
          return (
            <>
              <div
                className={
                  classes.speakerToggle +
                  (this.state.activeSpeaker === face ? " active" : "")
                }
                onClick={() => this.handleToggleClick(face, speakerColor)}
              >
                <span style={{ backgroundColor: speakerColor }}></span>{" "}
                {slotName}
              </div>
              <Tooltip title="Assign Participant">
                <IconButton
                  size="small"
                  onClick={(event) => {
                    this.handleParticipantIconClick(event, face);
                  }}
                >
                  <People color="primary" />
                </IconButton>
              </Tooltip>
            </>
          );
        })}
      </>
    );
  };

  handleCloseOptionsMenu = () => {
    this.setState({ optionsMenuAnchorEl: null, assigningToFace: null });
  };

  renderParticipantMenu = () => {
    const { classes } = this.props;
    return (
      <Menu
        anchorEl={this.state.optionsMenuAnchorEl}
        open={Boolean(this.state.optionsMenuAnchorEl)}
        onClose={this.handleCloseOptionsMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography variant="h5" align="center">
          Who is this?
        </Typography>
        {this.state.assigningToFace && (
          <Avatar
            className={classes.avatar}
            src={this.state.assigningToFace.image_url}
          />
        )}
        {this.props.data.participants_details.map((participant) => {
          return (
            <MenuItem
              value={participant.id}
              onClick={() => {
                this.handleFaceParticipantChange(participant);
              }}
            >
              {participant.name}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  handleFaceParticipantChange = (participant) => {
    // TODO: Hook up to BE endpoint
    // this.props
    //   .assignParticipantToFace(this.state.assigningToFace, participant.id)
    //   .then(function(response) {
    //     if (response.status !== 200) {
    //       // Reset face to original
    //     }
    //   });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.data.analytics.video_emotions && (
          <div className={classes.widgetGrid}>
            <div className={classes.gridPie}>{this.renderPieGraph()}</div>
            <div className={classes.gridToggle}>{this.renderToggle()}</div>
            <div className={classes.gridTimeline}>{this.renderTimeline()}</div>
            {this.renderParticipantMenu()}
          </div>
        )}
      </div>
    );
  }
}

FacialEmotionBreakdown.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FacialEmotionBreakdown);
