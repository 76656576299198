import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  Spellcheck as SpellcheckIcon
} from '@material-ui/icons';

// Chart configuration
import { options } from './chart';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

// Palette
import palette from 'theme/palette';

class Fillers extends Component {
  state = {
    data: {},
    totalFillers: 0,
    result: "",
    caption: "",
    valueClassName: ""
  }

  componentWillMount() {
    const fillerData = this.props.data;
    const duration = this.props.data.duration
    const classes = this.props.classes

    var labels = fillerData.fillers.map(function(e) {
        return e.type;
    })

    var values = fillerData.fillers.map(function(e) {
        return e.count;
    })

    const chartData = {
        datasets: [
          {
            data: values,
            backgroundColor: [
                palette.primary.main,
                palette.danger.main,
                palette.warning.main,
                palette.success.main,
                palette.primary.purple,
                palette.primary.cyan,
                palette.primary.pink
            ],
            borderWidth: 8,
            borderColor: palette.common.white,
            hoverBorderColor: palette.common.white
          }
        ],
        labels: labels
    }

    var totalFillers = values.reduce((a, b) => a + b, 0)
    var fillersPerMin = totalFillers

    if (duration > 0) {
      var duration_min = duration/60
      fillersPerMin = Math.round((totalFillers/duration_min)*10)/10
    }

    if (fillersPerMin === 0) {
        this.setState({
            totalFillers: totalFillers,
            result: "GREAT",
            caption: `You had no filler words! Excellent work!`,
            valueClassName: classes.valueGood
        })
    } else if (fillersPerMin === 1) {
      this.setState({
        data: chartData,
        totalFillers: totalFillers,
        result: "GOOD",
        caption: `You had ${totalFillers} fillers with an average of ${fillersPerMin} per minute. Its not a lot of filler words but if possible, you can try eliminating them all together!`,
        valueClassName: classes.valueGood
      })
    } else if (fillersPerMin > 1) {
      this.setState({
        data: chartData,
        totalFillers: totalFillers,
        result: "TOO MANY",
        caption: `You had ${totalFillers} fillers with an average of ${fillersPerMin} per minute. That's too many filler words! Try to be a bit more conscious of them when speaking. Replacing them with simple pauses will make you sound much better!`,
        valueClassName: classes.valueBad
      })
    } else {
      this.setState({
        result: "UNKNOWN",
        caption: "Sorry, a problem occurred when analyzing your recording!",
        valueClassName: classes.valueBad
      })
    }
  }

  getChartDiv() {
    if (this.state.totalFillers > 0) {
        const { classes, className, ...rest } = this.props;

        return (
            <div className={classes.chartWrapper}>
                <Doughnut
                data={this.state.data}
                options={options}
                />
            </div>
        )
    }
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h6"
            >
              FILLERS
            </Typography>
            <Typography
              className={this.state.valueClassName}
              variant="h4"
            >
              {this.state.result}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <SpellcheckIcon className={classes.icon} />
          </div>
        </div>
        {this.getChartDiv()}
        <div className={classes.footer}>
          <Typography
            className={classes.caption}
            variant="body1"
          >
            {this.state.caption}
          </Typography>
        </div>
      </Paper>
    );
  }
}

Fillers.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Fillers);
