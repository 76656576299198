import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { Box, withStyles } from "@material-ui/core";
import { Chip, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { mapParticipant } from "services/speech";

import styles from "./styles";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class MapParticipant extends Component {
  state = {
    menuAnchorEl: null,
    selectedTag: null,
  };

  addTagChips = () => {
    if (
      this.props.speakerTags === null ||
      this.props.speakerTags.size === null ||
      this.props.speakerTags.size === 0
    ) {
      return <div />;
    }
    const rendered = [];
    this.props.speakerTags.forEach((_key, value) => {
      if (value == null) {
        return <></>;
      }
      var label = "Speaker " + value;
      var mapping = null;
      var disabled = true;
      this.props.data.participants_details.forEach((map, _index) => {
        if (map.speaker_tag === value) {
          mapping = map.name;
        }
        if (this.checkSpeakerTagUnassigned(map.speaker_tag)) {
          disabled = false;
        }
      });
      if (mapping) {
        label = mapping;
      }
      rendered.push(
        <Chip
          label={label}
          color="primary"
          variant="outlined"
          disabled={!mapping && disabled}
          deleteIcon={!mapping && <ArrowDropDownIcon color="primary" />}
          onClick={(event) => {
            !mapping && this.handleTagButtonClick(event, value);
          }}
          onDelete={(event) => {
            if (mapping) {
              this.handleTagButtonDelete(event, value);
            } else {
              this.handleTagButtonClick(event, value);
            }
          }}
        />
      );
    });
    return rendered;
  };

  renderTagMenu = () => {
    return (
      <Menu
        anchorEl={this.state.menuAnchorEl}
        open={Boolean(this.state.menuAnchorEl)}
        onClose={this.handleCloseTagMenu}
      >
        {this.props.data.participants_details.map((value) => {
          return (
            this.checkSpeakerTagUnassigned(value.speaker_tag) && (
              <MenuItem onClick={() => this.handleSpeakerTagChange(value.name)}>
                {value.name}
              </MenuItem>
            )
          );
        })}
      </Menu>
    );
  };

  checkSpeakerTagUnassigned = (tag) => {
    var tagUnassigned = tag === null || tag === -1;
    if (
      this.props.speakerTags !== null &&
      this.props.speakerTags.size !== null
    ) {
      tagUnassigned = tagUnassigned || !this.props.speakerTags.has(tag);
    }

    return tagUnassigned;
  };

  handleCloseTagMenu = () => {
    this.setState({ menuAnchorEl: null, selectedTag: null });
  };

  handleTagButtonClick = (event, value) => {
    this.setState({ menuAnchorEl: event.currentTarget, selectedTag: value });
  };

  handleTagButtonDelete = (_event, value) => {
    var participantMap = this.props.data.participants_details;
    participantMap.forEach((map, _index) => {
      if (map.speaker_tag === value) {
        map.speaker_tag = -1;
        this.handleMapParticipant(map);
      }
    });
    this.setState({
      menuAnchorEl: null,
      selectedTag: null,
      participants: participantMap,
    });
  };

  handleSpeakerTagChange = (speaker) => {
    var participants = this.props.data.participants_details;
    participants.forEach((participant, _index) => {
      if (participant.name === speaker) {
        participant.speaker_tag = this.state.selectedTag;
        this.handleMapParticipant(participant);
      }
    });
    this.setState({
      menuAnchorEl: null,
      selectedTag: null,
      participants: participants,
    });
  };

  handleMapParticipant = (participant) => {
    mapParticipant(
      this.props.data.meeting_id,
      participant.speaker_tag,
      participant.id
    ).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          ReactGA.event({
            category: "Failure",
            action: "Map participant API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Map participant API success",
          });
          this.props.mapParticipantResponseHandler(
            participant.id,
            participant.speaker_tag
          );
        }
      }.bind(this)
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    return (
      <div className={className}>
        <Box className={classes.pills}>{this.addTagChips()}</Box>
        {this.renderTagMenu()}
      </div>
    );
  }
}

MapParticipant.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapParticipant);
