import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, ListItem, List } from '@material-ui/core';
import { TextField, Button, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Shared components
import {
    Portlet,
    PortletLabel,
    PortletFooter,
    PortletHeader,
    PortletContent
  } from 'components';

import { AgendaItem } from './components';

// Component styles
import styles from './styles';

class Agenda extends Component {

  state = {
    columns: [
        { title: 'Agenda Item', field: 'agendaItem', editComponent: props => (
            <TextField
                label='New Agenda Topic'
                value={props.value}
                fullWidth={true}
                onChange={e => props.onChange(e.target.value)}
                autoFocus
            />
        ) },
      ],
  }

  handleSave = () => {
    this.props.updateMeeting()
  }

  handleAdd = () => {
    this.props.createAgendaItem()
  }

  renderAgendaContent() {
    const { classes } = this.props;
    const items = this.props.agenda ?? [];
    if (items.length == 0) {
      return (
        <div>
          {
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Typography className={classes.value} variant="h5">
                  Start by adding some agenda items!
                </Typography>
              </ListItem>
            </List>
          }
        </div>
      );
    }

    const agenda_items = items.map((item, idx) => {
      return (
        <Draggable key={item.uuid} draggableId={item.uuid} index={idx}>
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <AgendaItem 
                item={item} 
                author={item.author_name} 
                topic={item.topic} 
                description={item.notes} 
                shouldFocus={item.shouldFocus}
                deleteAgendaItem={this.props.deleteAgendaItem} 
                createAgendaItem={this.props.createAgendaItem}
                updateAgendaItem={this.props.updateAgendaItem}
                isDragging={snapshot.isDragging}
                openSnackbar={this.props.openSnackbar}
              />
            </div>
          )}
        </Draggable>
      );
    });

    return (
      <DragDropContext onDragEnd={this.props.reorderAgendaItem}>
        <Droppable droppableId="agenda-droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {agenda_items}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
      {...rest}
      className={rootClassName}
      >
          <PortletHeader>
          <PortletLabel title="Agenda" />
            <IconButton
              edge="end"
              aria-label="add"
              onClick={this.handleAdd}
            >
              <Add color="primary"/>
            </IconButton>
        </PortletHeader>
        <PortletContent className={classes.content}>
          {this.renderAgendaContent()}
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
              color="primary"
              size="small"
              variant="text"
              onClick={this.handleAdd}
          >
              Add Topic
          </Button>
        </PortletFooter>
    </Portlet>
    );
  }
}

Agenda.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Agenda);
