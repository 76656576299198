import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  meetingScoresContent: {
    padding: "16px 16px 16px 24px",
    display: "flex",
    alignItems: "center",
    height: "70%",
    minHeight: "300px",
    margin: "auto",
    position: "relative",
    "&.isEmpty": {
      height: "auto",
    },
  },
  score: {
    fontWeight: "700",
    fontSize: "28px",
    color: theme.palette.text.darkGrey,
    "&.overall": {
      fontSize: "42px",
    },
  },
  "@media (max-width: 600px)": {
    score: {
      "&.overall": {
        fontSize: "26px",
      },
    },
  },
  "@media (min-width: 600px) and (max-width: 900px)": {
    score: {
      "&.overall": {
        fontSize: "42px",
      },
    },
  },
  "@media (min-width: 900px) and (max-width: 1000px)": {
    score: {
      "&.overall": {
        fontSize: "22px",
      },
    },
  },
  "@media (min-width: 1000px) and (max-width: 1200px)": {
    score: {
      "&.overall": {
        fontSize: "28px",
      },
    },
  },
  highlightScore: {
    textAlign: "right",
    color: theme.palette.text.darkGrey,
    "& > span": {
      float: "right",
    },
    justifyContent: "flex-end",
    width: "100%",
  },
  selected: {
    "&&": {
      backgroundColor: fade(theme.palette.primary.main, 0.25),
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.25),
      },
    },
  },
  tabContent: {
    height: "315px",
  },
  "@media (max-width: 500px)": {
    meetingScoresContent: {
      flexDirection: "column",
      gap: theme.spacing.unit * 3,
      paddingTop: theme.spacing.unit * 3,
      width: "100%",
    },
    overallScoreContainer: {
      width: "100%",
    },
    scoresContainer: {
      width: "100%",
    },
  },
  breakdownContainer: {
    width: "100%",
    maxWidth: "600px",
    margin: "auto",
    display: "flex",
    justifyContent: "space-around",
  },
  breakdownSummary: {
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
    verticalAlign: "middle",
    margin: "auto",
    width: "max(10vw, 170px)",
    padding: theme.spacing.unit * 1.5,
  },
  scoreLabel: {
    fontSize: "18px",
    color: theme.palette.text.darkGrey,
    marginBottom: theme.spacing.unit * 0.5,
    "& > span": {
      float: "right",
    },
    justifyContent: "flex-end",
    width: "100%",
  },
  scoreLabelContainer: {
    display: "flex",
    width: "100%",
    padding: "8px 0px 8px 0px",
  },
  totalLabel: {
    fontSize: 24,
  },
  fieldIcon: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  graphContainer: {
    textAlign: "center",
    position: "relative",
    width: "33%",
  },
  pieChart: {
    paddingBottom: theme.spacing.unit * 2,
  },
  captionText: {
    color: theme.palette.text.darkGrey,
  },
  emptyTextContainer: {
    color: theme.palette.text.secondary,
    width: "100%",
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
  },
  emptyText: {
    width: "fit-content",
    maxWidth: "95%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  },
});
