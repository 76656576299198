export const isUserInOrg = () => {
    if (localStorage.getItem("orgMemberRole")) {
        return true
    }
    return false
};

export const isGcalConnected = () => {
    let isGoogleCalendarConnected = localStorage.getItem("isGoogleCalendarConnected") === "true"
    return isGoogleCalendarConnected
};

export const isOnboardingComplete = () => {
    let isGoogleCalendarConnected = isGcalConnected()
    let isMicrosoftConnected = localStorage.getItem("isMicrosoftConnected") === "true"
    let isEnrolled = localStorage.getItem("isEnrolled") === "true"
    if ((isGoogleCalendarConnected || isMicrosoftConnected) && isEnrolled) {
        return true
    }
    return false
};

export const isUserOrgOwner = () => {
    var orgMemberRole = localStorage.getItem("orgMemberRole")
    if (orgMemberRole) {
        if (orgMemberRole == 1) {
            return true
        }
        return false
    }
    return null
};

export const isUserOrgOwnerOrAdmin = () => {
    const orgMemberRole = localStorage.getItem("orgMemberRole")
    if (orgMemberRole) {
        if (orgMemberRole == 1 || orgMemberRole == 3) {
            return true
        }
        return false
    }
    return null
};

export const isParticipant = (meeting) => {
    const userEmail = localStorage.getItem("email");
    let isGuest = userEmail === null || userEmail === undefined;
    if (isGuest) {
        return null;
    }
    if (meeting.participants_details) {
      const participant = meeting.participants_details.find(
        (p) => p.user_email === userEmail
      );
      return participant;
    }
    return null;
};

export const ROLES = {
    1: "Owner",
    2: "Member",
    3: "Admin",
    4: "Invited",
};
