import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components

import { MeetingAgenda } from "./components";

// Shared components
import { Portlet, PortletContent } from "components";

// Shared services
import { updateMeeting, getMeeting } from "services/speech";

import { pricingTiers, meetingLimits } from "helpers";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  freeTierCaption: {
    color: theme.palette.text.secondary,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
});

class UpdateMeeting extends Component {
  speechId = null;
  agenda = [];

  state = {
    results: {},
    isLoading: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
  };

  componentDidMount() {
    this.speechId = this.props.location.state.id;
    this.getMeetingFromRemote();
  }

  getMeetingFromRemote = () => {
    this.setState({ isLoading: true });
    getMeeting(this.speechId).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            ReactGA.event({
              category: "Failure",
              action: "Refresh token expired",
            });
            return;
          }
          this.setError(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Could not get meeting results in view-meeting",
          });
          return;
        }

        this.agenda = response.data.data.agenda;
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  updateMeeting = () => {
    this.setState({ isLoading: true });
    updateMeeting(this.speechId, this.agenda).then(
      function(response) {
        if (response.status === 200) {
          this.openSnackbar("Meeting agenda updated");
          ReactGA.event({
            category: "Meeting",
            action: "Update meeting agenda success",
          });
        } else if (response.status == 401) {
          this.props.history.push("/sign-in");
          ReactGA.event({
            category: "Failure",
            action: "Refresh token expired",
          });
        } else {
          this.openSnackbar("Sorry, but an unknown error occurred");
          ReactGA.event({
            category: "Failure",
            action: "Update meeting agenda failed",
          });
        }
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  setError = (error) => {
    this.setState({ error: error });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      enrollDialogOpen: false,
    });
  };

  newAgendaItem = (agendaItem) => {
    this.agenda.push(agendaItem);
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Update Meeting">
        <div className={classes.root}>
          <div className={classes.content}>
            <Portlet>
              <PortletContent className={classes.content}>
                <Typography className={classes.error} variant="body1">
                  {this.state.error}
                </Typography>
              </PortletContent>
            </Portlet>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Update Meeting">
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderFreeTier = () => {
    const pricingTier = localStorage.getItem("pricingTier");
    if (pricingTier != pricingTiers.free) {
      return;
    }
    const { classes } = this.props;
    return (
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.freeTierCaption} variant="body1">
              {`You are using Sonero free tier. You are limited to 
                ${meetingLimits.freeTier.numMeetings} meetings per month that 
                have a maximum duration of ${meetingLimits.freeTier} 
                minutes each. Please upgrade to Sonero Pro to remove 
                this restriction.`}
            </Typography>
          </PortletContent>
        </Portlet>
      </Grid>
    );
  };

  renderUpload = () => {
    const { classes } = this.props;
    return (
      <Grid container spacing={4}>
        {this.renderFreeTier()}
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <MeetingAgenda
            className={classes.item}
            agenda={this.agenda}
            updateMeeting={this.updateMeeting}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;

    if (this.state.error != null) {
      return this.renderError();
    }

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <DashboardLayout title="Update Meeting">
        <div className={classes.root}>
          {this.renderUpload()}
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration="6000"
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
          />
        </div>
      </DashboardLayout>
    );
  }
}

UpdateMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpdateMeeting);
