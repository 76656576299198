import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ShareIcon from "@material-ui/icons/Share";

// Material helpers
import { withStyles, Tooltip } from "@material-ui/core";

// Shared helpers
import { meetingStatuses } from "helpers";
import { timeFormatter } from "helpers/time";
import { optOutTypes } from "helpers/meetingOptOut";

// Shared components
import { Portlet, PortletLabel } from "components";

// Component styles
import styles from "./styles";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

class TeamMeetingsTable extends Component {
  handleSelect = (id, uuid, hasInternalAccess, isMeetingOwner) => {
    const { onSelect } = this.props;
    onSelect(id, uuid, hasInternalAccess, isMeetingOwner);
  };

  getStatus = (meetingStatus) => {
    let status = meetingStatuses.filter(
      (element) => element.value === meetingStatus
    );
    if (status.length > 0) {
      return status[0].label;
    }
    return "";
  };

  getDate = (date) => {
    if (date != null) {
      var localDate = new Date(date);
      return localDate.toLocaleString();
    }
    return "";
  };

  getDuration = (duration) => {
    if (duration != null) {
      return timeFormatter(parseInt(duration));
    }
    return "";
  };

  getTitleRow = (rowData) => {
    const { classes } = this.props;

    if (!rowData.uuid) {
      return rowData.title;
    }

    const meetingUrl = rowData.has_internal_access
      ? `/view-meeting/${rowData.uuid}`
      : `/view-meeting/share/${rowData.uuid}`;

    if (
      rowData.task_status === "uninitialized" &&
      rowData.user_opted_out_of_assistant !== optOutTypes.none
    ) {
      let optOutTooltip = "Assistant will not join";
      if (rowData.user_opted_out_of_assistant === optOutTypes.byParticipant) {
        optOutTooltip += " - ATTENDEE SELECTION";
      } else if (rowData.user_opted_out_of_assistant === optOutTypes.byOwner) {
        optOutTooltip += " - HOST SELECTION";
      }
      return (
        <Tooltip title={optOutTooltip}>
          <Link
            to={meetingUrl}
            onClick={(e) => e.stopPropagation()}
            className={classes.link + " disabled"}
          >
            <Clear className={classes.willNotJoinIcon} />
            <span className={classes.disabled}>{rowData.title}</span>
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link
        to={meetingUrl}
        onClick={(e) => e.stopPropagation()}
        className={classes.link}
      >
        {rowData.title}
      </Link>
    );
  };

  handleRowClick = (event, rowData) => {
    var meetings = this.props.meetings;
    var index = this.props.meetings.indexOf(rowData);
    this.handleSelect(
      meetings[index].id,
      meetings[index].uuid,
      meetings[index].has_internal_access,
      false,
    );
  };

  render() {
    const { classes, className, meetings } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <MaterialTable
          icons={tableIcons}
          title={<PortletLabel title="" />}
          columns={[
            {
              title: "Title",
              field: "title",
              render: (rowData) => this.getTitleRow(rowData),
            },
            { title: "Owner", field: "meeting_owner" },
            {
              title: "Start Time",
              field: "start_time",
              render: (rowData) => this.getDate(rowData.start_time),
            },
            {
              title: "Duration",
              field: "duration",
              render: (rowData) => this.getDuration(rowData.duration),
            },
            {
              title: "Status",
              field: "task_status",
              render: (rowData) => this.getStatus(rowData.task_status),
            },
          ]}
          data={meetings}
          options={{
            rowStyle: {
              fontFamily: "Roboto",
              fontSize: "14px",
            },
            actionsColumnIndex: -1,
            pageSize: 25,
            emptyRowsWhenPaging: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "",
            },
            toolbar: {
              searchPlaceholder: "Filter by Title",
            },
            header: {
              actions: "",
            },
          }}
          style={{
            boxShadow: "none",
          }}
          onRowClick={this.handleRowClick}
          actions={[
            (rowData) => ({
              icon: () => {
                if (rowData.has_internal_access) {
                  return <ShareIcon className={classes.actionIcon} />;
                }
                return <></>;
              },
              tooltip: "Share this meeting",
              onClick: (event, rowData) => {
                this.props.handleOpenShareDialog(rowData.uuid);
              },
            }),
          ]}
        />
      </Portlet>
    );
  }
}

TeamMeetingsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  meetings: PropTypes.array.isRequired,
};

TeamMeetingsTable.defaultProps = {
  meetings: [],
  onSelect: () => {},
  onShowDetails: () => {},
};

export default withStyles(styles)(TeamMeetingsTable);
