import ReactGA from "react-ga";
import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material icons
import {
  SlowMotionVideo as SlowMotionVideoIcon,
  Bookmarks as BookmarksIcon,
} from "@material-ui/icons";
import PictureInPictureAltIcon from "@material-ui/icons/PictureInPictureAlt";

// Material components
import { Button, Snackbar, Menu, MenuItem } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";
import { CreateBookmark, ViewBookmarks } from "views/ViewMeeting/components";
import {
  saveNewBookmark,
  updateBookmark,
  deleteBookmark,
} from "services/speech";

// Component styles
import styles from "./styles";
import palette from "theme/palette";

import { playAudioPiP, isVideo } from "helpers/audioPiP";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

class ViewVideo extends Component {
  mediaURL = "";
  endTimestamp = null;

  state = {
    title: "Recording",
    speechId: null,
    openCreateBookmark: false,
    openViewBookmarks: false,
    isLoading: false,
    showErrorResponse: false,
    showSnackbar: false,
    startDuration: 0,
    endDuration: 0,
    snackbarOpen: false,
    snackbarMessage: "",
    isSharedMeetingView: false,
    enablePictureInPicture: false,
    audioOnly: false,
    metadataLoaded: false,
    playbackMenuAnchorEl: null,
    playbackSpeedButtonTitle: "PLAYBACK SPEED",
    isPlaying: false,
    fromVideoPlayer: false,
  };

  abortController = new AbortController();

  componentDidMount() {
    this.setState({
      speechId: this.props.data.meeting_id,
      isMeeting: true,
    });
    this.mediaURL = this.props.data.recording_url;

    if (this.props.videoBlob != null) {
      this.setupSpeechVideoWithBlob(this.props.videoBlob);
    } else {
      this.setupSpeechVideoWithURL(this.mediaURL);
    }
    this.setupTranscriptHighlighting();

    if (this.props.isSharedMeetingView) {
      this.setState({ isSharedMeetingView: this.props.isSharedMeetingView });
    }

    isVideo(this.mediaURL)
      .then((isVideo) => {
        this.setState({ audioOnly: !isVideo });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture().catch((error) => {
        console.log(error);
      });
    }
    this.abortController.abort();
  }

  customPictureInPicture = () => {
    const videoPlayer = document.querySelector("#videoPlayer");
    playAudioPiP(videoPlayer, () => {
      // if audio PiP is not supported, use the custom PiP
      this.props.requestCustomPictureInPicture();
    });
  };

  saveBookmark = (bookmark) => {
    return saveNewBookmark(this.state.speechId, bookmark).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Create bookmark API failed",
          });
          return response;
        }
        this.props.bookmarkResponseHandler(response.data.data);
        this.openSnackbar("Video bookmark created successfully");
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Create bookmark API success",
        });
        return response;
      }.bind(this)
    );
  };

  updateBookmarkFunction = (bookmark) => {
    return updateBookmark(bookmark).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
            return response;
          }
          this.openSnackbar(
            "Sorry, an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Update bookmark API failed",
          });
          return response;
        }
        this.props.bookmarkResponseHandler(response.data.data);
        this.openSnackbar("Video bookmark updated successfully");
        this.setState({ bookmarkToEdit: null });
        ReactGA.event({
          category: gaCategoryViewMeeting(),
          action: "Update bookmark API success",
        });
        return response;
      }.bind(this)
    );
  };

  handleBookmarkDelete = (bookmark) => {
    return new Promise((resolve, reject) => {
      deleteBookmark(bookmark.uuid).then(
        function(response) {
          if (response.status !== 200) {
            if (response.status == 401) {
              this.props.history.push("/sign-in");
              return response;
            }
            ReactGA.event({
              category: "Failure",
              action: "Delete bookmark API failed",
            });
            this.openSnackbar(
              "Sorry, an unknown error occurred. Please try again."
            );
            reject();
            return response;
          }

          this.props.handleBookmarkDelete(bookmark);

          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Delete bookmark API success",
          });
          resolve();
          return response;
        }.bind(this)
      );
    });
  };

  setupSpeechVideoWithBlob = (blob) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.srcObject = null;
    videoPlayer.src = URL.createObjectURL(blob);
    videoPlayer.onloadedmetadata = function() {
      this.setState({ metadataLoaded: true });
    }.bind(this);

    var hiddenVideo = document.getElementById("hiddenVideo");
    hiddenVideo.srcObject = null;
    hiddenVideo.src = URL.createObjectURL(blob);
  };

  setupSpeechVideoWithURL = (url) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.srcObject = null;
    videoPlayer.src = url;
    videoPlayer.addEventListener("play", this.videoDidPlay);
    videoPlayer.addEventListener("pause", this.videoDidPause);
    videoPlayer.onloadedmetadata = function() {
      this.setState({ metadataLoaded: true });
    }.bind(this);

    var hiddenVideo = document.getElementById("hiddenVideo");
    hiddenVideo.srcObject = null;
    hiddenVideo.src = url;
  };

  setupTranscriptHighlighting = () => {
    const videoPlayer = document.getElementById("videoPlayer");
    let newSentence;
    let currentSentence;
    let currentTime;

    videoPlayer.addEventListener(
      "timeupdate",
      () => {
        if (!this.state.isPlaying) return;
        currentTime = Math.round(videoPlayer.currentTime);
        newSentence = document.querySelector(`.t${currentTime}`);
        if (newSentence) {
          if (currentSentence) currentSentence.style.backgroundColor = "";
          currentSentence = newSentence;
          currentSentence.style.backgroundColor = palette.highlight;
        }
      },
      { signal: this.abortController.signal }
    );
  };

  setMediaPlayerCurrentTimeAndPlay = (timestamp) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.currentTime = timestamp;
    if (this.state.enablePictureInPicture) {
      if (
        videoPlayer != null &&
        !document.pictureInPictureElement &&
        this.state.metadataLoaded
      ) {
        if (this.state.audioOnly) {
          this.customPictureInPicture();
        } else {
          videoPlayer.requestPictureInPicture().catch((error) => {
            console.log(error);
          });
        }
      }
    } else {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture().catch((error) => {
          console.log(error);
        });
      }
    }
    videoPlayer.play();
  };

  playBookmarkHandler = (startTimestamp, endTimestamp) => {
    this.handleCloseViewBookmarks();
    this.handleCloseCreateBookmark();
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.currentTime = startTimestamp;
    this.endTimestamp = endTimestamp;
    videoPlayer.addEventListener("timeupdate", this.videoTimeUpdate);
    videoPlayer.play();
  };

  handleBookmarkEdit = (bookmark) => {
    this.setState({ openCreateBookmark: true, bookmarkToEdit: bookmark });
  };

  handleBookmarkError = (error) => {
    this.openSnackbar(error);
  };

  handleClickOnVideoPlayer = () => {
    this.setState({ fromVideoPlayer: true });
  };

  videoDidPlay = () => {
    var videoPlayer = document.getElementById("videoPlayer");
    this.setState({ isPlaying: true, startDuration: videoPlayer.currentTime });
    if (this.state.fromVideoPlayer) {
      logGAEvent({
        category: gaCategoryViewMeeting(),
        action: "User Clicked Play Recording",
      });
      this.setState({ fromVideoPlayer: false });
    }
  };

  videoDidPause = () => {
    var videoPlayer = document.getElementById("videoPlayer");
    this.endTimestamp = null;
    if (videoPlayer) {
      this.setState({ isPlaying: false, endDuration: videoPlayer.currentTime });
    }
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "Time Spent On Video",
      value: videoPlayer.currentTime - this.state.startDuration,
    });
  };

  videoTimeUpdate = () => {
    var videoPlayer = document.getElementById("videoPlayer");
    if (this.endTimestamp && videoPlayer.currentTime > this.endTimestamp) {
      videoPlayer.pause();
      this.endTimestamp = null;
    }
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleOpenCreateBookmarkFromTranscript = (startDuration, endDuration) => {
    const totalDuration = this.props.data.duration;
    this.setState({
      openCreateBookmark: true,
      startDuration: startDuration,
      endDuration:
        totalDuration >= endDuration + 60 ? endDuration + 60 : totalDuration,
    });
  };

  handleOpenCreateBookmark = () => {
    let startDuration = 0;
    let endDuration = 60;
    const videoPlayer = document.querySelector("#videoPlayer");
    if (videoPlayer) {
      videoPlayer.pause();
      startDuration = videoPlayer.currentTime;
      endDuration = startDuration + 60;
    }
    if (endDuration > this.props.data.duration) {
      endDuration = this.props.data.duration;
    }
    this.setState({
      openCreateBookmark: true,
      startDuration: startDuration,
      endDuration: endDuration,
    });
  };

  handleCloseCreateBookmark = () => {
    this.setState({ openCreateBookmark: false, bookmarkToEdit: null });
  };

  handleOpenViewBookmarks = () => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked View Bookmarks",
      label: "Bookmarks",
    });
    this.setState({ openViewBookmarks: true });
  };

  handleCloseViewBookmarks = () => {
    this.setState({ openViewBookmarks: false });
  };

  enablePictureInPicture = (enable) => {
    this.setState({ enablePictureInPicture: enable });
  };

  handleClosePlaybackMenu = () => {
    this.setState({ playbackMenuAnchorEl: null });
  };

  handlePlaybackSpeedButtonClick = (event) => {
    this.setState({ playbackMenuAnchorEl: event.currentTarget });
  };

  handlePlaybackSpeedChange = (speed) => {
    var videoPlayer = document.getElementById("videoPlayer");
    videoPlayer.playbackRate = speed;
    if (speed == 1) {
      this.setState({
        playbackSpeedButtonTitle: "PLAYBACK SPEED",
      });
    } else {
      this.setState({
        playbackSpeedButtonTitle: "PLAYBACK SPEED: " + speed + "x",
      });
    }
    this.handleClosePlaybackMenu();
  };

  renderPlaybackSpeedMenu = () => {
    const { classes } = this.props;
    return (
      <Menu
        anchorEl={this.state.playbackMenuAnchorEl}
        keepMounted
        open={Boolean(this.state.playbackMenuAnchorEl)}
        onClose={this.handleClosePlaybackMenu}
      >
        <MenuItem onClick={() => this.handlePlaybackSpeedChange(0.5)}>
          0.5x
        </MenuItem>
        <MenuItem onClick={() => this.handlePlaybackSpeedChange(1)}>
          Normal
        </MenuItem>
        <MenuItem onClick={() => this.handlePlaybackSpeedChange(1.25)}>
          1.25x
        </MenuItem>
        <MenuItem onClick={() => this.handlePlaybackSpeedChange(1.5)}>
          1.5x
        </MenuItem>
        <MenuItem onClick={() => this.handlePlaybackSpeedChange(1.75)}>
          1.75x
        </MenuItem>
        <MenuItem onClick={() => this.handlePlaybackSpeedChange(2)}>
          2x
        </MenuItem>
      </Menu>
    );
  };

  renderPortletFooter = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <PortletFooter className={classes.portletFooter}>
        <Button
          className={classes.recordButton}
          color="primary"
          size="small"
          variant="text"
          style={{ marginRight: "auto" }}
          onClick={this.handlePlaybackSpeedButtonClick}
        >
          <SlowMotionVideoIcon /> {this.state.playbackSpeedButtonTitle}
        </Button>
        <Button
          className={classes.recordButton}
          color="primary"
          size="small"
          variant="text"
          onClick={() => {
            this.state.isSharedMeetingView
              ? this.handleOpenViewBookmarks()
              : this.handleOpenCreateBookmark();
          }}
        >
          <BookmarksIcon />{" "}
          {this.state.isSharedMeetingView ? "BOOKMARKS" : "BOOKMARK"}
        </Button>
        {!this.state.isSharedMeetingView && (
          <CreateBookmark
            open={this.state.openCreateBookmark}
            editBookmark={this.state.bookmarkToEdit}
            startDuration={this.state.startDuration}
            endDuration={this.state.endDuration}
            totalDuration={this.props.data.duration}
            closeHandler={this.handleCloseCreateBookmark}
            saveBookmark={this.saveBookmark}
            updateBookmarkFunction={this.updateBookmarkFunction}
            videoUrl={this.props.data.recording_url}
            bookmarks={this.props.bookmarks}
            handleBookmarkSelect={this.playBookmarkHandler}
            handleBookmarkDelete={this.handleBookmarkDelete}
            handleBookmarkError={this.handleBookmarkError}
          />
        )}
        <ViewBookmarks
          open={this.state.openViewBookmarks}
          bookmarks={this.props.bookmarks}
          isSharedMeetingView={this.state.isSharedMeetingView}
          handleBookmarkSelect={this.playBookmarkHandler}
          handleBookmarkDelete={this.handleBookmarkDelete}
          handleBookmarkError={this.handleBookmarkError}
          handleBookmarkEdit={this.handleBookmarkEdit}
          closeHandler={this.handleCloseViewBookmarks}
          isModal={true}
          hideHint={false}
        />
        {this.renderPlaybackSpeedMenu()}
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title={this.state.title} />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <div className={classes.videoWrapper}>
            <video
              id="videoPlayer"
              className={classes.videoRecorder}
              controls
              controlsList="nodownload"
              preload="metadata"
              onClick={this.handleClickOnVideoPlayer}
            ></video>
            {this.state.audioOnly && (
              <div
                className={classes.audioPiPButton}
                onClick={() => this.customPictureInPicture()}
              >
                <PictureInPictureAltIcon fontSize="large" color="primary" />
                <div>Picture in Picture</div>
              </div>
            )}
          </div>
        </PortletContent>
        {this.renderPortletFooter()}
        <Snackbar
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            className: classes.snackbar,
          }}
          message={this.state.snackbarMessage}
          action={
            <Button
              style={{ color: "white" }}
              size="small"
              onClick={this.handleSnackbarClose}
            >
              CLOSE
            </Button>
          }
        />
        {/* Hidden video is used to extract thumbnails for clips */}
        <video id="hiddenVideo" className={classes.hiddenVideo}></video>
      </Portlet>
    );
  }
}

ViewVideo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewVideo);
