export default (theme) => ({
  root: {
    "& .MuiRadio-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  group: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  groupLabel: {
    paddingLeft: theme.spacing.unit * 2,
  },
  firstField: {
    display: "flex",
    alignItems: "center",
  },
  field: {
    marginTop: theme.spacing.unit * 3,
    display: "flex",
    alignItems: "center",
  },
  textField: {
    width: "320px",
    maxWidth: "100%",
    marginRight: theme.spacing.unit * 3,
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  checkboxLabelOption: {
    marginTop: theme.spacing.unit * 2,
  },
  radioButtonContainer: {
    marginLeft: "50px",
    marginBottom: theme.spacing.unit * 3,
  },
  radioButtonDescription: {
    display: "block",
    marginLeft: "30px",
    marginBottom: theme.spacing.unit,
  },
  indentedField: {
    marginTop: theme.spacing.unit,
    marginLeft: "40px",
  },
  radioSubfield: {
    "& > *": {
      marginTop: theme.spacing.unit * 0.5,
    },
  },
  info: {
    backgroundColor: theme.palette.background.grey,
  },
});
