import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  withStyles,
  Typography,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Paper, ProcessingInProgress } from "components";
import { getSpeakerString } from "helpers/meetingParticipants";
import styles from "./styles";
import { ResponsivePie } from "@nivo/pie";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Textsms as TextsmsIcon,
} from "@material-ui/icons/";
import { fillerFeedback } from "helpers/meetingFiller";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { speechStatus } from "helpers";

class FillerBreakdown extends Component {
  state = {
    data: [],
    currentSpeaker: "",
    speakerData: [],
  };

  componentDidMount() {
    this.getFillerData();
  }

  getDurationData = () => {
    var transcript = this.props.data.transcript_results;
    var currentSpeaker = 0;
    var duration = 0;
    var startTimestamp = 0;
    var endTimestamp = 0;

    var speakersMap = new Map();
    transcript.forEach((result, index) => {
      if (index === 0) {
        currentSpeaker = result.speaker_tag;
        startTimestamp = result.timestamp;
      } else if (
        currentSpeaker !== result.speaker_tag ||
        index === transcript.length - 1
      ) {
        endTimestamp = result.timestamp;
        duration = endTimestamp - startTimestamp;

        var speakerKey = currentSpeaker;
        if (speakersMap.has(speakerKey)) {
          duration += speakersMap.get(speakerKey).duration;
        }
        speakersMap.set(speakerKey, {
          id: speakerKey,
          duration: duration,
          fillerTotal: 0,
          fillerRate: null,
        });

        currentSpeaker = result.speaker_tag;
        startTimestamp = result.timestamp;
      }
    });

    var speakerData = [...speakersMap.values()];
    speakerData.forEach((speaker) => {
      const fillerData = this.state.data.find(
        (fillerData) => fillerData.speaker_tag === speaker.id
      );
      if (fillerData) {
        speaker.fillerTotal = fillerData.fillers.reduce(
          (total, curr) => total + curr.count,
          0
        );

        if (speaker.duration) {
          const duration = speaker.duration / 60;
          speaker.fillerRate = Math.round(speaker.fillerTotal / duration);
        } else {
          speaker.fillerRate = null;
        }
      }
    });
    this.setState({ speakerData: speakerData });
  };

  getFillerData = () => {
    var analytics = this.props.data.analytics;
    if (!analytics.fillers || analytics.fillers.length === 0) {
      return;
    }

    this.setState(
      {
        data: analytics.fillers,
        currentSpeaker: analytics.fillers[0].speaker_tag,
      },
      this.getDurationData
    );
  };

  closeMenu = () => {
    this.setState({ menuAnchor: null });
  };

  getSpeakerText = (tag, participantsDetails) => {
    const currentSpeaker = this.state.speakerData.find(
      (speaker) => speaker.id === tag
    );
    if (currentSpeaker) {
      const text = `${getSpeakerString(tag, participantsDetails)} (${
        currentSpeaker.fillerTotal
      } total`;
      if (currentSpeaker.fillerRate !== null) {
        return text.concat(" ", `/ ${currentSpeaker.fillerRate} per min)`);
      } else {
        return text.concat("", ")");
      }
    }
    return "";
  };

  getFeedback = (fillerRate) => {
    const fillerCutoff = this.props.constants.filler_cutoff;
    if (fillerRate === 0) {
      return fillerFeedback.great;
    } else if (fillerRate < fillerCutoff.moderate) {
      return fillerFeedback.good;
    } else if (fillerRate >= fillerCutoff.moderate) {
      return fillerFeedback.bad;
    }
    return null;
  };

  handleOnClickMenuItem = (entry) => {
    this.setState({ currentSpeaker: entry.speaker_tag });
    this.closeMenu();
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Change Speaker (Filler Words)",
      label: "Analytics",
    });
  };

  render() {
    const { classes, className, meetingStatus, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    const pieData = this.state.data.find(
      (filler) => filler.speaker_tag === this.state.currentSpeaker
    );
    const participantsDetails = this.props.data.participants_details;
    const currentSpeakerData = this.state.speakerData.find(
      (speakerData) => speakerData.id === this.state.currentSpeaker
    );

    let currentFeedback;
    if (meetingStatus === speechStatus.completed) {
      if (!pieData || !currentSpeakerData) {
        return <></>;
      }
      currentFeedback = this.getFeedback(currentSpeakerData.fillerRate);
      if (currentFeedback === null) {
        return <></>;
      }
    }

    return (
      <div className={className}>
        {this.props.data.analytics && (
          <Paper {...rest} className={rootClassName}>
            <div className={classes.firstContent}>
              <div className={classes.iconWrapper}>
                <TextsmsIcon className={classes.icon} />
              </div>
              <div className={classes.details}>
                <Typography className={classes.title} variant="h5">
                  FILLER WORDS BREAKDOWN
                </Typography>
              </div>
            </div>
            {meetingStatus === speechStatus.completed && (
              <div className={classes.content}>
                <div className={classes.selector}>
                  <Button
                    className={classes.speakerSelect}
                    color="primary"
                    variant="outlined"
                    onClick={(event) =>
                      this.setState({ menuAnchor: event.currentTarget })
                    }
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {this.getSpeakerText(
                      this.state.currentSpeaker,
                      participantsDetails
                    )}
                  </Button>
                  <span className={currentFeedback.className}>
                    {currentFeedback.title}
                  </span>
                  <Menu
                    anchorEl={this.state.menuAnchor}
                    open={Boolean(this.state.menuAnchor)}
                    onClose={this.closeMenu}
                  >
                    {this.state.data.map((entry) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            this.setState({
                              currentSpeaker: entry.speaker_tag,
                            });
                            this.closeMenu();
                          }}
                        >
                          {this.getSpeakerText(
                            entry.speaker_tag,
                            participantsDetails
                          )}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
                <Typography className={classes.feedback}>
                  {currentFeedback.getText(
                    getSpeakerString(
                      currentSpeakerData.id,
                      participantsDetails
                    ),
                    currentSpeakerData.fillerTotal,
                    currentSpeakerData.fillerRate
                  )}
                </Typography>
                <div
                  className={classes.pieContainer}
                  onMouseEnter={this.props.handleOnMouseEnter}
                  onMouseLeave={() =>
                    this.props.handleOnMouseLeave("fillerWordsPie")
                  }
                >
                  <ResponsivePie
                    data={pieData.fillers.map((filler) => ({
                      id: filler.type,
                      label: filler.type,
                      value: filler.count,
                    }))}
                    height={250}
                    margin={{ top: 20, right: 20, left: 20 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    enableRadialLabels={true}
                    colors={[
                      "#f45243",
                      "#d53e4f",
                      "#9e0142",
                      "#f46d43",
                      "#fdae61",
                      "#fee08b",
                      "#bf812d",
                    ]}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextColor="#333333"
                    radialLabelsLinkColor={{ from: "color" }}
                    sliceLabelsSkipAngle={10}
                    sliceLabelsTextColor="#FFFFFF"
                    sliceLabel={(e) => e.value}
                  />
                </div>
              </div>
            )}
            {meetingStatus === speechStatus.processing && (
              <ProcessingInProgress copy="Processing Analytics..." />
            )}
          </Paper>
        )}
      </div>
    );
  }
}

FillerBreakdown.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FillerBreakdown);
