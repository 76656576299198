import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  CircularProgress,
  Typography,
  Grid,
  Button,
  Snackbar,
  TextField,
} from "@material-ui/core";

// Shared services
import { getMeetingFolder } from "services/speech";

// Custom components
import { MeetingsTable } from "views/MyMeetings/components";

import { ShareToolbar } from "views/ViewMeetingShare/components";

// Shared components
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
  PortletFooter,
} from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

class ViewSharedFolder extends Component {
  state = {
    isLoading: false,
    meetings: [],
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    uuid: "",
    folderName: "Shared Folder",
    passwordRequired: false,
    password: "",
  };

  componentWillMount() {
    const uuid = this.props.location.pathname.split("/").pop();
    const queryParams = new URLSearchParams(this.props.location.search);
    const password = queryParams.get("password");
    if (password) {
      this.setState(
        { uuid: uuid, password: password, isLoading: true },
        this.getMeetings
      );
    } else {
      this.setState({ uuid: uuid, isLoading: true }, this.getMeetings);
    }
  }

  getMeetings = () => {
    this.setState({ isLoading: true });
    getMeetingFolder(this.state.uuid, this.state.password).then(
      function(response) {
        if (response.status === 200) {
          var meetings = response.data.data;

          this.setState({
            isLoading: false,
            folderName: response.data.folder_name,
            passwordRequired: false,
            meetings,
          });

          ReactGA.event({
            category: "Shared Meeting Folder",
            action: "Get meeting folder API success",
          });
        } else if (response.status === 401) {
          this.setState({
            isLoading: false,
            error: "Please sign in to view this folder",
          });
        } else if (response.status === 403) {
          this.setState({
            isLoading: false,
            error:
              "You do not have enough permissions to view this folder or the folder does not exist",
          });
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your meetings because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get meeting folder API failed",
          });
        }
      }.bind(this)
    );
  };

  handleSelect = (meetingId) => {
    const { meetings } = this.state;
    const meeting = meetings.find((meeting) => meeting.id === meetingId);
    if (meeting && meeting.uuid) {
      this.props.history.push({
        pathname: `/view-meeting/share/${meeting.uuid}?password=${this.state.password}`,
      });
    } else {
      this.openSnackbar("An unknown error has occurred");
    }
  };

  renderError() {
    return (
      <Portlet>
        <PortletContent>
          <Typography variant="body1">{this.state.error}</Typography>
        </PortletContent>
      </Portlet>
    );
  }

  renderMeetingsTable() {
    const { meetings } = this.state;

    return (
      <MeetingsTable
        onSelect={this.handleSelect}
        meetings={meetings}
        displayFolders={false}
        handleOpenShareDialog={this.handleOpenShareDialog}
        handleOpenMenu={this.handleOpenMenu}
        isShared={true}
      />
    );
  }

  renderMeetings() {
    if (this.state.error) {
      return this.renderError();
    }

    return <Grid>{this.renderMeetingsTable()}</Grid>;
  }

  addSnackbar = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Snackbar
        open={this.state.snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackbarClose}
        ContentProps={{
          className: classes.snackbar,
        }}
        message={this.state.snackbarMessage}
        action={
          <Button
            style={{ color: "white" }}
            size="small"
            onClick={this.handleSnackbarClose}
          >
            CLOSE
          </Button>
        }
      />
    );
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handlePasswordChange = (password) => {
    this.setState({ password: password });
  };

  handlePasswordSubmit = () => {
    if (this.state.password.trim()) {
      this.getMeetings();
    }
  };

  renderFolder = () => {
    const { classes } = this.props;

    return (
      <>
        <ShareToolbar
          title={this.state.folderName}
          isRecordingEnabled={false}
          shareGranularity={{}}
          openSnackbar={this.openSnackbar}
        />
        <div className={classes.root}>
          <div className={classes.content}>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {this.renderMeetings()}
              </Grid>
            </Grid>
          </div>
        </div>
        {this.addSnackbar()}
      </>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <Portlet>
        <PortletContent className={classes.content}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </PortletContent>
      </Portlet>
    );
  };

  renderPasswordPrompt = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center" alignContent="center" spacing={4}>
          <Grid item lg={3} md={3} xl={3} xs={12}>
            <div className={classes.content}>
              <Portlet>
                <PortletHeader>
                  <PortletLabel title="Meeting Password" />
                </PortletHeader>
                <PortletContent className={classes.item}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    required
                    className={classes.textField}
                    value={this.state.password}
                    onChange={(event) =>
                      this.handlePasswordChange(event.target.value)
                    }
                  />
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                  <Button
                    className={classes.feedbackButton}
                    color="primary"
                    size="small"
                    onClick={this.handlePasswordSubmit}
                  >
                    SUBMIT
                  </Button>
                </PortletFooter>
              </Portlet>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { isLoading, passwordRequired } = this.state;
    if (isLoading) {
      return this.renderLoading();
    }

    if (passwordRequired) {
      return this.renderPasswordPrompt();
    }

    return this.renderFolder();
  }
}

ViewSharedFolder.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewSharedFolder);
