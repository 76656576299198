import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { LinearProgress, Typography } from "@material-ui/core";

const SlowLinearProgress = withStyles({
  bar: {
    animationTimingFunction: "linear",
    animationDuration: "10s",
    animationDelay: 0,
  },
})(LinearProgress);

function ProcessingInProgress(props) {
  const { classes, copy } = props;
  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.copy}>
        {copy}
      </Typography>
      <SlowLinearProgress
        classes={{
          barColorPrimary: classes.barColour,
        }}
      />
    </div>
  );
}

export default withStyles(styles)(ProcessingInProgress);
