export default theme => ({
    root: {},
    portletFooter: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    videoWrapper: {
      height: '300px',
      position: 'relative',
      maintainAspectRatio: true
    },
    videoRecorder: {
      width:'100%',
      height: '100%',
      responsive: true
    },
    snackbar: {
      height: "60px",
      fontSize: 16
    }
  });
  