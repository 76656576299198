import React, { Component } from "react";
import Rating from "material-ui-rating";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  CircularProgress,
  Typography,
  Divider,
  Button,
  Toolbar,
} from "@material-ui/core";

// Shared components
import { Portlet, PortletContent } from "components";

// Shared services
import { getMeetingForRating, submitNewMeetingRating } from "services/speech";
import { feedbackScale } from "helpers/appEnv";

import ReactGA from "react-ga";
import { ExternalSignUpLink } from "components";

// Component styles
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
  },
  contentWrapper: {},
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing.unit * 5,
    paddingBototm: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  backButton: {},
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    flexShrink: 0,
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 5,
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: "pointer",
  },
  logoDivider: {
    marginBottom: theme.spacing.unit * 2,
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingLeft: "100px",
    paddingRight: "100px",
    paddingBottom: "125px",
    flexBasis: "700px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  title: {
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 5,
    textAlign: "center",
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 0.5,
  },
  fields: {
    marginTop: theme.spacing.unit * 5,
  },
  textField: {
    width: "100%",
    "& + & ": {
      marginTop: theme.spacing.unit * 2,
    },
  },
  progress: {
    display: "block",
    marginTop: theme.spacing.unit * 2,
    marginLeft: "auto",
    marginRight: "auto",
  },
  signUpButton: {
    marginTop: theme.spacing.unit * 5,
    width: "50%",
  },
  signIn: {
    marginTop: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  signInUrl: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: "center",
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  ratingQuestionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing.unit * 2,
  },
  ratingQuestion: {
    marginTop: theme.spacing.unit * 3,
  },
  actionButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.background.lightGrey,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
});

class RateMeeting extends Component {
  meetingUuid = null;

  state = {
    isLoading: false,
    isLoadingSubmission: false,
    error: null,
    meetingTitle: "",
    isRatingSubmitted: false,
    rating1: 3,
    rating2: 3,
    rating3: 3,
    rating4: 3,
  };

  componentWillMount() {
    this.meetingUuid = this.props.location.pathname.split("/").pop();
    this.getMeetingForRatingFromRemote();
  }

  getMeetingForRatingFromRemote = () => {
    this.setState({ isLoading: true });
    getMeetingForRating(this.meetingUuid).then(
      function(response) {
        if (response.status !== 200) {
          if (response.status == 403) {
            this.setError(
              "Sorry, but you do not have enough permissions to rate this meeting."
            );
            ReactGA.event({
              category: "Rate Meeting",
              action:
                "Get meeting API failed because user does not have permission",
            });
            return;
          } else {
            this.setError(
              "Sorry, but an unknown error occurred. Please try again."
            );
            ReactGA.event({
              category: "Failure",
              action: "Get meeting for rating API failed",
            });
            return;
          }
        }

        var data = response.data.data;
        this.setState({
          meetingTitle: data.title,
        });
        ReactGA.event({
          category: "Rate Meeting",
          action: "Request Feedback Submit",
        });
        this.setState({ isLoading: false });
      }.bind(this)
    );
  };

  submitNewRating = (data) => {
    this.setState({ isLoadingSubmission: true });
    submitNewMeetingRating(this.meetingUuid, data).then(
      function(response) {
        if (response.status !== 200) {
          this.setError(
            "Sorry, but an unknown error occurred. Please try again."
          );
          ReactGA.event({
            category: "Failure",
            action: "Submit rating for meeting API failed",
          });
          return;
        }

        ReactGA.event({
          category: "Rate Meeting",
          action: "Submit rating for meeting API success",
        });
        this.setState({ isLoadingSubmission: false, isRatingSubmitted: true });
      }.bind(this)
    );
  };

  setError = (error) => {
    this.setState({ error: error });
  };

  handleSubmit = () => {
    var data = {
      meeting_need: this.state.rating1,
      attendee_importance: this.state.rating2,
      clear_decisions: this.state.rating3,
      meeting_productivity: this.state.rating4,
    };
    this.submitNewRating(data);
  };

  handleRatingChange = (value, ratingControlNum) => {
    if (ratingControlNum == 1) {
      this.setState({ rating1: value });
    } else if (ratingControlNum == 2) {
      this.setState({ rating2: value });
    } else if (ratingControlNum == 3) {
      this.setState({ rating3: value });
    } else if (ratingControlNum == 4) {
      this.setState({ rating4: value });
    }
  };

  handleClickSignUp = () => {
    ReactGA.event({
      category: "Rate Meeting",
      action: "Sign up button clicked",
    });
  };

  renderError = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Portlet>
            <PortletContent className={classes.content}>
              <Typography className={classes.error} variant="body1">
                {this.state.error}
              </Typography>
            </PortletContent>
          </Portlet>
        </div>
      </div>
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  };

  renderMeetingFeedbackSuccess = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form}>
              <div className={classes.logoWrapper}>
                <a href="https://sonero.ai/">
                  <img
                    alt="Sonero logo"
                    height="100px"
                    className={classes.logoImage}
                    src="/sonero-static/images/logo_with_name.png"
                  />
                </a>
              </div>
              <Divider className={classes.logoDivider} />
              <Typography className={classes.title} variant="h3">
                Thank you for submitting your feedback!
              </Typography>
            </form>
          </div>
        </div>
      </div>
    );
  };

  renderMeetingFeedback = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <ExternalSignUpLink onSignUp={this.handleClickSignUp} />
        </Toolbar>
        <div className={classes.content}>
          <div className={classes.contentBody}>
            <form className={classes.form}>
              <div className={classes.logoWrapper}>
                <a href="https://sonero.ai/">
                  <img
                    alt="Sonero logo"
                    height="100px"
                    className={classes.logoImage}
                    src="/sonero-static/images/logo_with_name.png"
                  />
                </a>
              </div>
              <Divider className={classes.logoDivider} />
              <Typography className={classes.title} variant="h3">
                {this.state.meetingTitle}
              </Typography>
              <Portlet>
                <PortletContent className={classes.ratingQuestionContainer}>
                  <Typography className={classes.ratingQuestion} variant="h5">
                    Was this meeting necessary to hold?
                  </Typography>
                  <Rating
                    value={this.state.rating1}
                    max={feedbackScale}
                    onChange={(value) => this.handleRatingChange(value, 1)}
                  />
                  <Typography className={classes.ratingQuestion} variant="h5">
                    Was your attendance important for this meeting?
                  </Typography>
                  <Rating
                    value={this.state.rating2}
                    max={feedbackScale}
                    onChange={(value) => this.handleRatingChange(value, 2)}
                  />
                  <Typography className={classes.ratingQuestion} variant="h5">
                    Did action items or next steps come out of this meeting?
                  </Typography>
                  <Rating
                    value={this.state.rating3}
                    max={feedbackScale}
                    onChange={(value) => this.handleRatingChange(value, 3)}
                  />
                  <Typography className={classes.ratingQuestion} variant="h5">
                    Was this meeting productive?
                  </Typography>
                  <Rating
                    value={this.state.rating4}
                    max={feedbackScale}
                    onChange={(value) => this.handleRatingChange(value, 4)}
                  />
                </PortletContent>
              </Portlet>
              <div className={classes.actionButtonContainer}>
                {this.state.isLoadingSubmission ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  <Button
                    className={classes.signUpButton}
                    color="primary"
                    disabled={false}
                    onClick={this.handleSubmit}
                    size="large"
                    variant="contained"
                  >
                    Submit Feedback
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const isLoading = this.state.isLoading;

    if (this.state.error != null) {
      return this.renderError();
    }

    if (isLoading) {
      return this.renderLoading();
    }

    if (this.state.isRatingSubmitted) {
      return this.renderMeetingFeedbackSuccess();
    } else {
      return this.renderMeetingFeedback();
    }
  }
}

RateMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.object.isRequired,
};

export default withStyles(styles)(RateMeeting);
