import React, { Component } from "react";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

// Component styles
import styles from "./styles";

import { trackerNoteTypes } from "helpers";
import { timeFormatter } from "helpers/time";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import theme from "theme";
import Highlighter from "react-highlight-words";

class Trackers extends Component {
  state = {
    showScrollTip: true,
    autoScrolled: false,
  };
  containerRef = React.createRef();
  trackerRefs = {};
  hoverStart = new Date();

  componentDidMount() {
    this.setState({ showScrollTip: this.hasMoreContent() });
    this.handleAutoScroll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tab !== this.props.tab) {
      this.handleResetScroll();
      this.setState({ showScrollTip: this.hasMoreContent() });
      this.handleAutoScroll();
    }
  }

  handleResetScroll = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollTop = 0;
    }
  };

  handleAutoScroll = () => {
    if (!this.state.autoScrolled && this.props.scrollToTracker) {
      this.handleScrollTrackerIntoView();
    }
  };

  hasMoreContent = () => {
    if (this.containerRef.current) {
      return (
        this.containerRef.current.scrollHeight >
        this.containerRef.current.clientHeight
      );
    }
    return false;
  };

  atEndOfScroll = () => {
    const container = this.containerRef.current;
    if (container) {
      const { clientHeight, scrollTop, scrollHeight } = container;
      if (clientHeight + scrollTop + 1 >= scrollHeight) {
        this.setState({ showScrollTip: false });
      } else {
        this.setState({ showScrollTip: true });
      }
    }
  };

  handleOnMouseEnter = () => {
    this.hoverStart = new Date();
  };

  handleOnMouseLeave = (type) => {
    let timeSpent = new Date() - this.hoverStart;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User hovered ${type} event in Trackers and Notes`,
      value: timeSpent,
    });
  };

  handleScrollTrackerIntoView = () => {
    const trackerHashString = this.props.scrollToTracker;
    const [trackerWord, trackerTimestamp] = trackerHashString.split("@");
    if (this.trackerRefs[trackerHashString] && this.trackerRefs[trackerWord]) {
      this.trackerRefs[trackerWord].style.backgroundColor =
        theme.palette.warning.light;
      this.trackerRefs[trackerHashString].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      this.props.handleInsightOnClick(
        { timestamp: +trackerTimestamp },
        trackerNoteTypes.tracker
      );
      this.setState({ autoScrolled: true });
    }
  };

  handleClickKeywordTimestamp = (timestamp) => {
    this.props.handleInsightOnClick({ timestamp }, trackerNoteTypes.tracker);
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked on a tracker in Trackers and Notes",
    });
  };

  renderKeywords = () => {
    const { classes } = this.props;
    const keywords = this.props.keywords.filter((keyword) => keyword.count > 0);
    keywords.sort((k1, k2) => k2.count - k1.count);
    const transcriptResults = this.props.data.transcript_results;
    return (
      <>
        {keywords.length === 0 && (
          <Typography className={classes.emptyText}>
            No trackers detected
          </Typography>
        )}
        {keywords.map((keyword) => (
          <div
            className={classes.keywordSection}
            onMouseEnter={this.handleOnMouseEnter}
            onMouseLeave={() => this.handleOnMouseLeave("tracker")}
            ref={(instance) => {
              this.trackerRefs[keyword.keyword] = instance;
            }}
          >
            <Typography className={classes.keywordHeader}>
              Keyword:
              <span className={classes.keywordLabel}>{keyword.keyword}</span>
              <span className={classes.keywordCount}>{keyword.count}</span>
            </Typography>
            <div className={classes.keywordContent}>
              {keyword.timestamps.map((timestamp) => {
                const transcriptIdx = this.props.data.transcriptionResultsMap[
                  timestamp.toString()
                ];
                return (
                  <Typography
                    ref={(instance) => {
                      this.trackerRefs[
                        `${keyword.keyword}@${timestamp}`
                      ] = instance;
                    }}
                    className={classes.keywordInstance}
                    onClick={() => this.handleClickKeywordTimestamp(timestamp)}
                  >
                    <div className={classes.keywordTimestamp}>
                      {timeFormatter(timestamp)}
                    </div>
                    <div className={classes.keywordSentence}>
                      <Highlighter
                        highlightStyle={{
                          fontWeight: 600,
                          backgroundColor: "transparent",
                        }}
                        searchWords={[
                          `( |^)${keyword.keyword}.*?(,|.|!|\\?| |$)`,
                        ]}
                        caseSensitive={false}
                        autoEscape={false}
                        textToHighlight={
                          transcriptResults[transcriptIdx].sentence
                        }
                      />
                    </div>
                  </Typography>
                );
              })}
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          className={classes.trackerContent}
          ref={this.containerRef}
          onScroll={this.atEndOfScroll}
        >
          {this.renderKeywords()}
        </div>
        {this.state.showScrollTip && (
          <Typography className={classes.scrollTip}>Scroll for more</Typography>
        )}
      </>
    );
  }
}

Trackers.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Trackers);
