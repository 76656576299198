export default theme => ({
    root: {},
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        alignItems: 'center',
        position: 'relative',
        minHeight: '250px'
    },
    portletFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: 'none'
    },
    value: {
        marginTop: theme.spacing.unit,
        color: theme.palette.text.secondary,
        fontWeight: 700
    },
    list: {
        maxHeight: '100%', 
        overflow: 'auto', 
        position: 'absolute', 
        paddingTop: 0, 
        paddingBottom: 0, 
        right: 0, 
        left: 0
    },
    listItem: {
        marginLeft: theme.spacing.unit,
    },
  });