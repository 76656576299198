import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";
import { verifyUserPricingTier, pricingTiers } from "helpers/pricingTiers";

class Zapier extends Component {
  state = {
    buttonTitle: "CREATE API KEY",
    isAPIKeyExists: false,
    openConfirmDialog: false,
  };

  componentDidMount() {
    if (this.props.isAPIKeyExists == true) {
      this.setState({
        buttonTitle: "REVOKE API KEY",
        isAPIKeyExists: this.props.isAPIKeyExists,
      });
    } else {
      this.setState({
        buttonTitle: "CREATE API KEY",
        isAPIKeyExists: this.props.isAPIKeyExists,
      });
    }
  }

  handleSubmit = () => {
    const isFreeTier = verifyUserPricingTier(pricingTiers.free);
    if (isFreeTier) {
      this.props.handleOpenUpgradeDialog();
      return;
    }

    if (this.state.isAPIKeyExists == true) {
      this.setState({
        openConfirmDialog: true,
      });
    } else {
      ReactGA.event({
        category: "Zapier",
        action: "User clicked Create API key button",
      });
      this.props.createAPIKey();
    }
  };

  handleRevokeAPIKey = () => {
    ReactGA.event({
      category: "Zapier",
      action: "User clicked Revoke API key button",
    });
    this.props.revokeAPIKey();
  };

  handleCloseConfirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
    });
  };

  renderConfirmDialog = () => {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.openConfirmDialog}
        aria-labelledby="form-dialog-title"
        onClose={() => this.handleCloseConfirmDialog()}
      >
        <DialogTitle id="form-dialog-title">REVOKE API KEY</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to revoke your Zapier API key? This will
            disable your Zapier integration.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleRevokeAPIKey()} color="primary">
            Yes, I'm Sure
          </Button>
          <Button
            onClick={() => this.handleCloseConfirmDialog()}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderFooter = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <PortletFooter className={classes.portletFooter}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="small"
          variant="text"
          onClick={this.handleSubmit}
        >
          {this.state.buttonTitle}
        </Button>
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <div>
        <Portlet {...rest} className={rootClassName}>
          <PortletHeader>
            <PortletLabel title="Zapier" />
          </PortletHeader>
          <div style={{ position: "relative" }}>
            <PortletContent className={classes.content}>
              <div className={classes.instructions}>
                <Typography className={classes.caption} variant="body1">
                  Integrate with Zapier to get Sonero's insights into your CRM
                  or project management tools.
                  <br />
                  <br />
                  This integration is currently in BETA. Please contact us to
                  get early access.
                </Typography>
              </div>
            </PortletContent>
            {this.renderFooter()}
          </div>
        </Portlet>
        {this.renderConfirmDialog()}
      </div>
    );
  }
}

Zapier.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Zapier);
