import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  portletFooter: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  caption: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 2,
  },
  status: {
    color: theme.palette.text.primary,
  },
  field: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  url: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  helpIcon: {
    transform: "scale(0.85, 0.85)",
    color: theme.palette.text.secondary,
  },
  gated: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: fade(theme.palette.background.grey, 0.8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
});
