export default (theme) => ({
  root: {},
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionHeader: {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  indentedContent: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  footerButton: {
    display: "block",
  },
  personRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "&:hover": {
      backgroundColor: theme.palette.background.grey,
    },
  },
  name: {
    display: "block",
    fontWeight: 500,
  },
  email: {
    display: "block",
    fontSize: "12px",
  },
  generalAccessLabel: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme.palette.background.lightGrey,
    },
  },
  generalAccessDescription: {
    color: theme.palette.text.secondary,
  },
  shareContentCheckbox: {
    display: "flex",
    alignItems: "center",
  },
  emailInputContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
    flexWrap: "wrap",
    width: "min(max(50vw, 250px), 500px)",
    borderBottom: `1px solid ${theme.palette.common.black}`,
    fontSize: "14px",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.background.grey,
    borderRadius: "5px 5px 0 0",
    position: "relative",
    "&::before": {
      content: `""`,
      position: "absolute",
      left: "50%",
      bottom: "-1px", // -1px to cover up the bottom border
      width: 0,
      height: "2px",
      backgroundColor: theme.palette.primary.main,
      transition: "width 300ms, left 300ms",
    },
    "&:focus-within::before": {
      width: "100%",
      left: "0%",
    },
  },
  emailInput: {
    backgroundColor: "inherit",
    border: "none",
    padding: `${theme.spacing(1)}px ${theme.spacing(0.25)}px`,
    fontSize: "14px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  messageInputContainer: {
    marginBottom: theme.spacing(3),
    animation: "$fade-in 300ms",
  },
  accessContainer: {
    animation: "$fade-in 300ms",
  },
  clearIcon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  tooltipIcon: {
    width: 15,
    height: 15,
    color: theme.palette.text.secondary,
  },
  "@keyframes fade-in": {
    "0%": {
      transform: "translateY(5px)",
      opacity: 0,
    },
    "100%": {
      transform: "translateY(0)",
      opacity: 1,
    },
  },
});
