import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Custom components
import { ProTier, CheckoutForm, SubscriptionStatusForm } from "./components";

// Shared services
import {
  getProfile,
  createSubscription,
  changePaymentMethod,
  updateSubscription,
  applyDiscount,
  cancelSubscription,
  getSubscriptionDetails,
} from "services/speech";

import pricingTiers, { getPrice } from "helpers/pricingTiers";
import {
  stripePublishableKey,
  stripeProfessionalTierPriceId,
  stripeBusinessTierPriceId,
  stripeEssentialTierPriceId,
} from "helpers/appEnv";
import { isUserInOrg, isUserOrgOwner } from "helpers/user";

import ReactGA from "react-ga";
import queryString from "query-string";

// Stripe
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  error: {
    color: theme.palette.text.danger,
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  loadingContent: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
  },
  loadingContentMessage: {
    marginTop: theme.spacing.unit * 3,
  },
  cancelButton: {
    color: theme.palette.danger.main,
  },
});

class Billing extends Component {
  stripePromise = loadStripe(stripePublishableKey());

  state = {
    isLoading: false,
    showProcessingPaymentMessage: false,
    snackbarOpen: false,
    snackbarMessage: "",
    error: null,
    subscriptionStatus: "",
    currentPricingTier: pricingTiers.free,
    showCardWidget: false,
    showSeatWidget: false,
    openCancelSubscriptionDialog: false,
    numOfMinLicenses: 1,
    numOfUsers: 1,
    price: 34,
    amountOff: 0,
    percentOff: 0,
    pricingTier: pricingTiers.professional,
    billedAnnually: true,
    switchToPricingTier: null,
    redirectOnSubmit: null,
  };
  checkoutFormRef = React.createRef();

  componentDidMount() {
    const promises = [];
    this.setState({ isLoading: true });
    promises.push(
      this.getMyProfile().then(() => {
        this.getNumberOfUsers();
      })
    );
    promises.push(this.getSubscriptionStatus());
    Promise.all(promises).then(() => {
      this.updateStatusFromLocalStorage();
    });

    this.parseQueryParameters();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.numOfMinLicenses !== prevState.numOfMinLicenses) {
      this.getNumberOfUsers();
    }
  }

  getMyProfile = () => {
    return getProfile().then((response) => {
      if (response.status === 401) {
        this.props.history.push("/sign-in");
      }
    });
  };

  getNumberOfUsers = () => {
    const stripeSubStatus = localStorage.getItem("stripeSubStatus");
    const stripeSubQuantity = localStorage.getItem("stripeSubQuantity");
    this.setState({
      numOfUsers:
        stripeSubStatus === "active"
          ? stripeSubQuantity
          : this.state.numOfMinLicenses,
    });
  };

  getSubscriptionStatus = () => {
    getSubscriptionDetails().then((response) => {
      if (response.status === 200) {
        const details = response.data;
        if (details && details.status === "active") {
          let pricingTier = details.type;

          let billedAnnually = false;
          switch (details.billing_cycle) {
            case "year":
              billedAnnually = true;
              break;
            case "month":
            default:
              break;
          }

          let price = details.price;
          if (billedAnnually) {
            price /= 12;
          }

          let amountOff = 0;
          if (details.amount_off > 0) amountOff = details.amount_off;

          let percentOff = 0;
          if (details.percent_off > 0) percentOff = details.percent_off;

          this.setState(
            {
              pricingTier,
              billedAnnually,
              price,
              amountOff,
              percentOff,
            },
            this.parseQueryParameters
          );
        }
      } else if (response.status === 401) {
        this.props.history.push("/sign-in");
      }
    });
  };

  parseQueryParameters = () => {
    const params = queryString.parse(this.props.location.search);
    if (
      params.to === "professional" ||
      params.to === "business" ||
      params.to === "essential"
    ) {
      let switchToPricingTier;
      if (params.to === "professional") {
        switchToPricingTier = pricingTiers.professional;
      } else if (params.to === "business") {
        switchToPricingTier = pricingTiers.business;
      } else if (params.to === "essential") {
        switchToPricingTier = pricingTiers.essential;
      }
      this.setState({ switchToPricingTier, showSeatWidget: true });
      this.handleScrollToCheckoutForm(10);
    }
    if (params.from === "trackers") {
      this.setState({ redirectOnSubmit: "/trackers" });
    }
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  handleShowCardWidget = (open) => {
    this.setState({ showCardWidget: open });
  };

  handleShowSeatWidget = (open) => {
    this.setState({ showSeatWidget: open });
  };

  handleOpenCancelSubscriptionDialog = () => {
    this.setState({ openCancelSubscriptionDialog: true });
  };

  handleCloseCancelSubscriptionDialog = () => {
    this.setState({ openCancelSubscriptionDialog: false });
  };

  handleToggleAnnualBilling = () => {
    this.setState((prevState) => ({
      billedAnnually: !prevState.billedAnnually,
      price: getPrice(prevState.pricingTier, !prevState.billedAnnually),
    }));
  };

  handleChangePricingTier = (tier, numOfUsers = 0) => {
    if (numOfUsers === 0) {
      numOfUsers = this.state.numOfUsers;
    }
    if (tier === pricingTiers.essential && numOfUsers > 1) {
      this.openSnackbar("Essential subscriptions allow only up to 1 license");
      return;
    }
    if (tier === pricingTiers.professional && numOfUsers > 5) {
      this.openSnackbar(
        "Professional subscriptions allow only up to 5 licenses"
      );
      return;
    }
    this.setState((prevState) => ({
      pricingTier: tier,
      price: getPrice(tier, prevState.billedAnnually),
      numOfUsers: numOfUsers,
    }));
  };

  handleScrollToCheckoutForm = (retry = 0) => {
    if (this.checkoutFormRef.current) {
      const y =
        this.checkoutFormRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        50;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else if (retry > 0) {
      // Retry mechanism for when the page hasn't fully loaded
      setTimeout(() => {
        this.handleScrollToCheckoutForm(retry - 1);
      }, 250);
    }
  };

  handleNumOfUserChange = (event) => {
    if (
      event.target.value > 1 &&
      event.target.value <= 5 &&
      this.state.pricingTier === pricingTiers.essential
    ) {
      this.handleChangePricingTier(pricingTiers.professional);
    } else if (event.target.value > 5) {
      this.handleChangePricingTier(pricingTiers.business);
    }
    this.setState({
      numOfUsers: event.target.value,
      error: null,
    });
  };

  updateStatusFromLocalStorage = () => {
    var pricingTier = localStorage.getItem("pricingTier");
    var stripeSubStatus = localStorage.getItem("stripeSubStatus");

    var numOfMinLicenses = 1;
    var orgMembersCount = parseInt(localStorage.getItem("orgMembersCount"));
    if (orgMembersCount && orgMembersCount > 0) {
      numOfMinLicenses = orgMembersCount;
    }

    this.setState({
      isLoading: false,
      currentPricingTier: pricingTier,
      subscriptionStatus: stripeSubStatus,
      showCardWidget: false,
      showSeatWidget: false,
      openCancelSubscriptionDialog: false,
      showProcessingPaymentMessage: false,
      numOfMinLicenses: numOfMinLicenses,
    });
  };

  createNewSubscription = (
    billingDetails,
    paymentMethodId,
    numOfUsers,
    discountCode
  ) => {
    let priceId = "";
    if (this.state.pricingTier === pricingTiers.professional) {
      priceId = stripeProfessionalTierPriceId(this.state.billedAnnually);
    } else if (this.state.pricingTier === pricingTiers.business) {
      priceId = stripeBusinessTierPriceId(this.state.billedAnnually);
    } else if (this.state.pricingTier === pricingTiers.essential) {
      priceId = stripeEssentialTierPriceId(this.state.billedAnnually);
    }
    this.setState({
      isLoading: true,
      showProcessingPaymentMessage: true,
    });
    createSubscription(
      billingDetails,
      paymentMethodId,
      priceId,
      numOfUsers,
      discountCode
    ).then(
      function(response) {
        this.getMyProfile().then(() => {
          this.updateStatusFromLocalStorage();
          if (this.state.redirectOnSubmit && response.status === 200) {
            this.props.history.push(this.state.redirectOnSubmit);
          }
        });
        if (response.status === 200) {
          this.openSnackbar("Thank you for subscribing to Sonero!");
          ReactGA.event({
            category: "Billing",
            action: "Create subscription API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (response.status === 404) {
          this.openSnackbar("Sorry, the discount code you entered is invalid.");
        } else {
          this.openSnackbar(
            "Sorry, but an error occurred. Please try again or contact us to resolve any billing issues."
          );
          ReactGA.event({
            category: "Failure",
            action: "Create subscription API failed",
          });
        }
      }.bind(this)
    );
  };

  updateSubscriptionWithNewPaymentMethod = (paymentMethodId) => {
    this.setState({
      isLoading: true,
      showProcessingPaymentMessage: true,
    });
    changePaymentMethod(paymentMethodId).then(
      function(response) {
        this.updateStatusFromLocalStorage();
        if (response.status === 200) {
          this.openSnackbar("Your payment details have been updated");
          ReactGA.event({
            category: "Billing",
            action: "Add new payment method API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.openSnackbar(
            "Sorry, but an error occurred. Please try again or contact us to resolve any billing issues."
          );
          ReactGA.event({
            category: "Failure",
            action: "Add new payment method API failed",
          });
        }
      }.bind(this)
    );
  };

  updateSubscriptionDetails = (type, billedAnnually, seats) => {
    return updateSubscription(type, billedAnnually, seats).then(
      function(response) {
        this.getMyProfile().then(() => {
          this.updateStatusFromLocalStorage();
          if (this.state.redirectOnSubmit && response.status === 200) {
            this.props.history.push(this.state.redirectOnSubmit);
          }
        });
        if (response.status === 200) {
          this.openSnackbar("Your subscription has been updated");
          ReactGA.event({
            category: "Billing",
            action: "Update subscription API success",
          });
        } else {
          if (response.status === 401) {
            this.props.history.push("/sign-in");
          } else if (
            response.status === 400 &&
            response.data.error_code === "invalid_license_count"
          ) {
            this.openSnackbar(
              "At a minimum, you need to have enough licenses for the total users you have in your organization."
            );
          } else if (
            response.status === 403 &&
            response.data.error_code === "restricted_domain"
          ) {
            this.openSnackbar(
              "You must sign up with a work email address to purchase additional seats."
            );
          } else {
            this.openSnackbar(
              "Sorry, but an error occurred. Please try again or contact us to resolve any billing issues."
            );
          }
          ReactGA.event({
            category: "Failure",
            action: "Update subscription API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  applyDiscountToSubscription = (discountCode) => {
    return applyDiscount(discountCode).then(
      function(response) {
        if (response.status === 200) {
          this.openSnackbar("Discount applied");
          ReactGA.event({
            category: "Billing",
            action: "Apply discount API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else if (response.status === 404) {
          this.openSnackbar("Sorry, the discount code you entered is invalid.");
        } else {
          this.openSnackbar(
            "Sorry, but an error occurred. Please try again or contact us to resolve any billing issues."
          );
          ReactGA.event({
            category: "Failure",
            action: "Apply discount API failed",
          });
        }
        return response;
      }.bind(this)
    );
  };

  cancelExistingSubscription = () => {
    this.setState({
      isLoading: true,
      showProcessingPaymentMessage: true,
    });
    cancelSubscription().then(
      function(response) {
        this.getMyProfile().then(() => {
          this.updateStatusFromLocalStorage();
        });
        if (response.status === 200) {
          this.openSnackbar(
            "Your subscription has been canceled. We're sorry to see you go."
          );
          ReactGA.event({
            category: "Billing",
            action: "Cancel subscription API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.openSnackbar(
            "Sorry, but an error occurred. Please try again or contact us to resolve any billing issues."
          );
          ReactGA.event({
            category: "Failure",
            action: "Cancel subscription API failed",
          });
        }
      }.bind(this)
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Billing">
        <div className={classes.root}>
          <div className={classes.loadingContent}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
            <div>
              {this.state.showProcessingPaymentMessage ? (
                <Typography
                  className={classes.loadingContentMessage}
                  variant="h6"
                  align="center"
                >
                  Please do not refresh or move away from this page. We are
                  processing your payment information...
                </Typography>
              ) : (
                <Typography
                  className={classes.loadingContentMessage}
                  variant="h6"
                  align="center"
                >
                  Loading...
                </Typography>
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderUserMemberInOrg = () => {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Billing">
        <div className={classes.root}>
          <div className={classes.loadingContent}>
            <div>
              <Typography
                className={classes.loadingContentMessage}
                variant="h6"
                align="center"
              >
                Billing is managed by the organization owner
              </Typography>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  };

  renderCancelSubscriptionDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.openCancelSubscriptionDialog}
        onClose={() => this.handleCloseCancelSubscriptionDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">CANCEL SUBSCRIPTION</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to cancel your subscription?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.cancelExistingSubscription()}
            className={classes.cancelButton}
          >
            Yes, I'm Sure
          </Button>
          <Button
            onClick={() => this.handleCloseCancelSubscriptionDialog()}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderCheckoutForm = (isCheckout, renderCardWidget, renderSeatWidget) => {
    const { classes } = this.props;
    return (
      <Grid
        container
        wrap="wrap"
        justify="center"
        alignContent="center"
        spacing={4}
        ref={this.checkoutFormRef}
      >
        <Grid item lg={9} md={9} xl={3} xs={12}>
          <Elements stripe={this.stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <CheckoutForm
                  className={classes.item}
                  elements={elements}
                  stripe={stripe}
                  subscriptionStatus={this.state.subscriptionStatus}
                  createNewSubscription={this.createNewSubscription}
                  updateSubscriptionWithNewPaymentMethod={
                    this.updateSubscriptionWithNewPaymentMethod
                  }
                  updateSubscriptionDetails={this.updateSubscriptionDetails}
                  applyDiscountToSubscription={this.applyDiscountToSubscription}
                  numOfMinLicenses={this.state.numOfMinLicenses}
                  isCheckout={isCheckout}
                  renderCardWidget={renderCardWidget}
                  renderSeatWidget={renderSeatWidget}
                  numOfUsers={this.state.numOfUsers}
                  price={this.state.price}
                  amountOff={this.state.amountOff}
                  percentOff={this.state.percentOff}
                  pricingTier={this.state.pricingTier}
                  handleChangePricingTier={this.handleChangePricingTier}
                  handleNumOfUserChange={this.handleNumOfUserChange}
                  billedAnnually={this.state.billedAnnually}
                  handleToggleAnnualBilling={this.handleToggleAnnualBilling}
                  switchToPricingTier={this.state.switchToPricingTier}
                />
              )}
            </ElementsConsumer>
          </Elements>
        </Grid>
      </Grid>
    );
  };

  renderPaidView = () => {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          wrap="wrap"
          justify="center"
          alignContent="center"
          spacing={4}
        >
          <Grid item lg={9} md={9} xl={3} xs={12}>
            <SubscriptionStatusForm
              className={classes.item}
              subscriptionStatus={this.state.subscriptionStatus}
              handleShowCardWidget={this.handleShowCardWidget}
              handleShowSeatWidget={this.handleShowSeatWidget}
              handleOpenCancelSubscriptionDialog={
                this.handleOpenCancelSubscriptionDialog
              }
            />
          </Grid>
        </Grid>
        {this.renderCheckoutForm(
          false,
          this.state.showCardWidget,
          this.state.showSeatWidget
        )}
        {this.renderCancelSubscriptionDialog()}
      </div>
    );
  };

  renderUnpaidView = () => {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          wrap="wrap"
          justify="center"
          alignContent="center"
          spacing={4}
        >
          <Grid item lg={9} md={9} xl={3} xs={12}>
            <ProTier
              className={classes.item}
              pricingTier={this.state.currentPricingTier}
              billedAnnually={this.state.billedAnnually}
              handleToggleAnnualBilling={this.handleToggleAnnualBilling}
              handleChangePricingTier={this.handleChangePricingTier}
              handleScrollToCheckoutForm={this.handleScrollToCheckoutForm}
            />
          </Grid>
        </Grid>
        {this.renderCheckoutForm(true, true, true)}
      </div>
    );
  };

  renderComponents = () => {
    switch (this.state.subscriptionStatus) {
      case "active":
      case "past_due":
      case "incomplete":
        return this.renderPaidView();
      case "canceled":
      case "unpaid":
      default:
        return this.renderUnpaidView();
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    if (isUserInOrg() && isUserOrgOwner() === false) {
      return this.renderUserMemberInOrg();
    }

    return (
      <DashboardLayout title="Billing">
        <div className={classes.root}>
          {this.renderComponents()}
          <Snackbar
            open={this.state.snackbarOpen}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              className: classes.snackbar,
            }}
            message={this.state.snackbarMessage}
            action={
              <Button
                style={{ color: "white" }}
                size="small"
                onClick={this.handleSnackbarClose}
              >
                CLOSE
              </Button>
            }
          />
        </div>
      </DashboardLayout>
    );
  }
}

Billing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Billing);
