import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import { Paper, ProcessingInProgress } from "components";
import { getSpeakerString } from "helpers/meetingParticipants";
import { pacingResults, wpmCutoffs } from "helpers/meetingPacing";
import styles from "./styles";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { speechStatus } from "helpers";

class PacingBreakdown extends Component {
  state = {
    data: [],
    currentSpeaker: "",
  };

  componentDidMount() {
    this.getWpm();
  }

  getWpm = () => {
    const wordsPerMin = this.props.data.analytics.words_per_min;
    if (!wordsPerMin) {
      return;
    }

    const participantsDetails = this.props.data.participants_details;
    var data = [];
    for (const [speakerTag, value] of Object.entries(wordsPerMin)) {
      let wpm = 0;
      if (value.duration !== 0) {
        wpm = Math.round(value.word_count / (value.duration / 60));
      }
      data.push({
        speaker: getSpeakerString(Number(speakerTag), participantsDetails),
        tag: speakerTag,
        wpm: wpm,
      });
    }
    data.sort((first, second) => {
      if (first.tag < second.tag) {
        return -1;
      } else if (first.tag > second.tag) {
        return 1;
      }
      return 0;
    });
    this.setState({
      data: data,
      currentSpeaker: data.length > 0 ? data[0].tag : "",
    });
  };

  getPacingResult = (wpm) => {
    let pace;
    if (wpm < wpmCutoffs.optimal) {
      pace = pacingResults.slow;
    } else if (wpm > wpmCutoffs.fast) {
      pace = pacingResults.fast;
    } else if (wpm >= wpmCutoffs.optimal && wpm <= wpmCutoffs.fast) {
      pace = pacingResults.optimal;
    }
    return pace;
  };

  handleOnClickSpeaker = (speaker) => {
    this.setState({ currentSpeaker: speaker.tag });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User Clicked Change Speaker (Pacing)",
      label: "Analytics",
    });
  };

  renderSpeakerDetail = (tag) => {
    const { classes } = this.props;
    if (!tag) {
      return <></>;
    }
    const pacing = this.state.data.find((speaker) => speaker.tag === tag);
    let pacingResult = this.getPacingResult(pacing.wpm);

    return (
      <div className={classes.pacingDetail}>
        <Typography className={classes.speaker} variant="h6">
          {pacing.speaker}
        </Typography>
        <Typography
          className={classes.pacing + ` ${pacingResult.class}`}
          variant="h5"
        >
          {pacingResult.result}
        </Typography>
        <Typography className={classes.caption} variant="body1">
          {pacingResult.caption(pacing.speaker, pacing.wpm)}
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, className, meetingStatus, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    if (
      meetingStatus === speechStatus.completed &&
      this.state.data.length === 0
    ) {
      return <></>;
    }

    return (
      <div className={className}>
        {this.props.data.analytics && (
          <Paper {...rest} className={rootClassName}>
            <div className={classes.firstContent}>
              <div className={classes.iconWrapper}>
                <DirectionsRun className={classes.icon} />
              </div>
              <div>
                <Typography className={classes.title} variant="h5">
                  PACING
                </Typography>
              </div>
            </div>
            {meetingStatus === speechStatus.completed && (
              <div className={classes.content}>
                {this.state.data.map((speaker) => {
                  const pacingResult = this.getPacingResult(speaker.wpm);
                  return (
                    <div
                      className={
                        classes.speakerPacing +
                        ` ${speaker.tag === this.state.currentSpeaker &&
                          "selected"}`
                      }
                      onClick={() => this.handleOnClickSpeaker(speaker)}
                    >
                      <Typography className={classes.speaker} variant="h6">
                        {speaker.speaker}
                      </Typography>
                      <Typography
                        className={classes.pacing + ` ${pacingResult.class}`}
                        variant="h5"
                      >
                        {pacingResult.result}
                      </Typography>
                    </div>
                  );
                })}
                <div
                  className={classes.details}
                  style={{
                    gridRow: `1 / ${this.state.data.length + 1}`,
                  }}
                >
                  {this.renderSpeakerDetail(this.state.currentSpeaker)}
                </div>
              </div>
            )}
            {meetingStatus === speechStatus.processing && (
              <ProcessingInProgress copy="Processing Analytics..." />
            )}
          </Paper>
        )}
      </div>
    );
  }
}

PacingBreakdown.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PacingBreakdown);
