// Material helpers
import {
  Button,
  IconButton,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
// Material icons
import { Menu as MenuIcon } from "@material-ui/icons";
// Externals
import classNames from "classnames";
import ShareButton from "components/ShareButton";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import JoinAdHoc from "../JoinAdHoc";
import MeetingAccount from "./components/MeetingAccount";
// Component styles
import styles from "./styles";
import { isUserLoggedIn } from "services/speech";

class Topbar extends Component {
  renderActionButton = () => {
    const { classes, className } = this.props;
    return (
      <Button
        onClick={this.props.actionButtonHandler}
        className={classes.actionButton}
        color="primary"
        variant="outlined"
        size="small"
      >
        Options
      </Button>
    );
  };

  renderShareButton = () => {
    const { classes } = this.props;
    return (
      <ShareButton
        className={classes.shareButton}
        shareButtonHandler={this.props.shareButtonHandler}
      />
    );
  };

  renderJoinAdHoc = () => {
    return <JoinAdHoc />;
  };

  render() {
    const {
      classes,
      className,
      title,
      onToggleSidebar,
      showMeetingAccount,
      meetingAccount,
      meetingUuid,
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              variant="text"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logoWrapper}>
              <Link className={classes.logoLink} to="/">
                <Typography
                  className={classes.logoName}
                  variant="h1"
                  color="primary"
                >
                  sonero
                </Typography>
              </Link>
            </div>
            {showMeetingAccount && (
              <MeetingAccount
                meetingAccount={meetingAccount}
                meetingUuid={meetingUuid}
              />
            )}
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
            <div className={classes.buttonsContainer}>
              {isUserLoggedIn() && this.renderJoinAdHoc()}
              {this.props.showActionButton && this.renderActionButton()}
              {this.props.showShareButton && this.renderShareButton()}
            </div>
          </Toolbar>
        </div>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string,
};

Topbar.defaultProps = {
  onToggleSidebar: () => {},
};

export default compose(withRouter, withStyles(styles))(Topbar);
