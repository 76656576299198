import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
    minHeight: "250px",
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  value: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  valueSecondary: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.secondary,
  },
  valueGood: {
    marginTop: theme.spacing.unit,
    color: theme.palette.success.dark,
  },
  valueBad: {
    marginTop: theme.spacing.unit,
    color: theme.palette.danger.dark,
  },
  iconWrapper: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.brown,
    borderRadius: "50%",
    display: "inline-flex",
    height: "4rem",
    justifyContent: "center",
    marginLeft: "auto",
    width: "4rem",
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  avatar: {
    width: "30px",
    height: "30px",
    color: "#949494",
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
  },
  difference: {
    alignItems: "center",
    color: theme.palette.danger.dark,
    display: "inline-flex",
    fontWeight: 700,
  },
  caption: {
    color: theme.palette.text.secondary,
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
  },
  resultContent: {
    marginTop: theme.spacing.unit * 3,
  },
  transcriptBody: {
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit * 2,
  },
  list: {
    maxHeight: "100%",
    overflow: "auto",
    position: "absolute",
    paddingTop: 0,
    paddingBottom: 0,
    right: 0,
    left: 0,
  },
  listItem: {
    marginLeft: theme.spacing.unit,
  },
  shareButton: {
    marginLeft: theme.spacing.unit * 2,
  },
  enableAutomaticFeedbackButton: {
    marginBottom: theme.spacing.unit * 3,
    paddingLeft: 0,
  },
  url: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  dropDownButton: {
    padding: "1.5px",
    minWidth: 0,
  },
  participantNameInput: {
    marginBottom: "5px",
    width: "80%",
    padding: "2px",
    border: `0.5px solid ${fade(theme.palette.text.secondary, 0.5)}`,
    fontSize: "16px",
    fontWeight: 700,
    color: theme.palette.text.secondary,
    "&:focus": {
      outline: "none",
    },
  },
  participantEmailInput: {
    width: "80%",
    padding: "2px",
    border: `0.5px solid ${fade(theme.palette.text.secondary, 0.5)}`,
    fontSize: "12px",
    fontWeight: 400,
    color: theme.palette.text.secondary,
    "&:focus": {
      outline: "none",
    },
  },
  tabContainer: {
    width: "max(100%, 200px)",
    height: "100%",
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "1fr",
    alignItems: "strech",
    fontFamily: "Roboto",
    position: "relative",
  },
  tab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    fontSize: "17px",
    fontWeight: "500",
    textAlign: "center",
    "&.active": {
      color: theme.palette.primary.main,
    },
    "&.active > $highlightsNotification": {
      color: theme.palette.primary.main,
    },
  },
  activeUnderline: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "3px",
    backgroundColor: theme.palette.primary.main,
    transition: "left 0.3s",
  },
  highlightsNotification: {
    transform: "scale(0.75)",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
});
