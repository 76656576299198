export default (theme) => ({
  circularBarContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing.unit,
  },
  circularBarLabel: {
    width: "75%",
    height: "75%",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    left: "12.5%",
    top: "12.5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
