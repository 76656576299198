import React, { Component } from "react";
import PropTypes from "prop-types";

import { CircularProgress, withStyles } from "@material-ui/core";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

// Component styles
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  item: {
    height: "100%",
  },
  progressWrapper: {
    paddingTop: "100px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.secondary,
    paddingBottom: "100px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  },
  snackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  whiteSnackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
    backgroundColor: "white",
    color: "black",
  },
  deleteMeetingOption: {
    color: theme.palette.danger.main,
    marginTop: theme.spacing.unit,
  },
  deleteMeetingButton: {
    color: theme.palette.danger.main,
  },
  deleteMeetingCaption: {
    color: theme.palette.text.primary,
  },
  renameMeetingTextField: {
    width: "350px",
  },
  speechStatusChip: {
    marginLeft: "16px",
    padding: "5px",
  },
  tooltipSection: {
    margin: "7px",
  },
  analyticsGridContainer: {
    marginTop: theme.spacing.unit * 2,
  },
});

class DeleteDialog extends Component {
  render = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.onClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Delete {this.props.type}
        </DialogTitle>
        <DialogContent>
          <Typography
            className={classes.deleteMeetingCaption}
            variant="h5"
            align="center"
          >
            Are you sure you want to delete "{this.props.objectTitle}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.handleDelete()}
            color="primary"
            className={classes.deleteMeetingButton}
          >
            YES, I'M SURE
          </Button>
          <Button onClick={() => this.props.onClose()} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

DeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(DeleteDialog);
