import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "./styles";

import { Paper, ProcessingInProgress } from "components";

import {
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
  Tabs,
  Tab,
  Switch,
  IconButton,
  FormControl,
} from "@material-ui/core";

import { Timeline, List } from "./components";
import GetAppIcon from "@material-ui/icons/GetApp";
import { speechStatus, conversationHighlightTypes } from "helpers";
import { getSpeakerString } from "helpers/meetingParticipants";
import ReactGA from "react-ga";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";

const tabs = {
  questions: conversationHighlightTypes.questions,
  takeaways: conversationHighlightTypes.takeaways,
};

const views = {
  timeline: "timeline",
  list: "list",
};

class ConversationHighlights extends Component {
  hoverStart = new Date();
  state = {
    openNewTakeawayDialog: false,
    newTakeaway: "",
    newTakeawayType: "",
    sentence: "",
    endTime: null,
    error: null,
    tab: tabs.questions,
    view: views.list,
  };

  componentDidMount() {
    if (this.props.scrollToQuestion) {
      this.setState({ tab: tabs.questions });
    }
  }

  handleOpenNewTakeawayDialog = (transcriptResults, sentence, endTime) => {
    this.setState({
      openNewTakeawayDialog: true,
      sentence: sentence,
      newTakeaway: transcriptResults,
      endTime: endTime,
    });
  };

  handleCloseNewTakeawayDialog = () => {
    this.setState({ openNewTakeawayDialog: false });
  };

  handleNewTakeawayChange = (event) => {
    this.setState({ sentence: event.target.value });
  };

  handleNewTakeawayTypeChange = (event) => {
    this.setState({ newTakeawayType: event.target.value, error: null });
  };

  handleCreateNewTakeaway = () => {
    if (!this.state.newTakeawayType) {
      this.setState({
        error: "Takeaway type cannot be empty",
      });
    } else {
      this.handleAddTakeaway(
        this.state.newTakeaway,
        this.state.newTakeawayType,
        this.state.endTime,
        this.state.sentence
      );
    }
  };

  handleAddTakeaway = (transcriptResults, type, endTime, sentence) => {
    this.props
      .handleAddTakeaway(transcriptResults, type, endTime, sentence)
      .then(() => {
        this.setState({
          newTakeaway: null,
          newTakeawayType: null,
          openNewTakeawayDialog: false,
          endTime: null,
          sentence: null,
        });
      });
  };

  handleChangeTab = (_event, newValue) => {
    this.setState({ tab: newValue });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User switched to ${newValue} tab in Conversation Highlights`,
    });
  };

  handleToggleView = (event) => {
    let action = "";
    if (event.target.checked) {
      this.setState({ view: views.list });
      action = "User switched to list view in Conversation Highlights";
    } else {
      this.setState({ view: views.timeline });
      action = "User switched to timeline view in Conversation Highlights";
    }
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: action,
    });
  };

  renderNewTakeawayDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Dialog
        open={this.state.openNewTakeawayDialog}
        onClose={() => this.handleCloseNewTakeawayDialog()}
        fullWidth="true"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">NEW TAKEAWAY</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid container item>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  id="standard-basic"
                  defaultValue={this.state.sentence}
                  onChange={this.handleNewTakeawayChange}
                  label="Takeaway"
                  required
                />
              </FormControl>
            </Grid>
            <Grid container item>
              <FormControl className={classes.formControl}>
                <InputLabel id="new-takeaway-select-type-label">
                  Takeaway Type
                </InputLabel>
                <Select
                  labelId="new-takeaway-select-type-label"
                  id="new-takeaway-select-type"
                  value={this.state.newTakeawayType}
                  onChange={this.handleNewTakeawayTypeChange}
                  label="Takeaway Type"
                >
                  <MenuItem value={1}>Pain Point</MenuItem>
                  <MenuItem value={2}>Opportunity & Next Step</MenuItem>
                  <MenuItem value={3}>Win</MenuItem>
                </Select>
                {this.state.error && (
                  <Typography style={{ color: "red" }}>
                    {this.state.error}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseNewTakeawayDialog} color="primary">
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={this.handleCreateNewTakeaway}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleDownload = () => {
    const { data, questions, takeaways } = this.props;
    const participantsDetails = data.participants_details;
    let content = "";
    let filename = "insights.txt";
    if (data.title) {
      filename = data.title + ".insights.txt";
    }

    content += "------ Questions & Answers ------\n\n";
    questions.forEach((qa) => {
      let chunk = "";
      qa.transcriptSegments.forEach((segment) => {
        const speaker = getSpeakerString(segment.speaker, participantsDetails);
        segment.transcript.forEach((t) => {
          chunk += speaker + ": " + t.sentence + "\n";
        });
      });
      qa.answerSegments.forEach((segment) => {
        const speaker = getSpeakerString(segment.speaker, participantsDetails);
        segment.transcript.forEach((t) => {
          chunk += speaker + ": " + t.sentence + "\n";
        });
      });
      content += chunk + "\n";
    });

    content += "\n------ Takeaways ------\n\n";
    takeaways.forEach((takeaway) => {
      let chunk = "";
      takeaway.transcriptSegments.forEach((segment) => {
        const speaker = getSpeakerString(segment.speaker, participantsDetails);
        segment.transcript.forEach((t) => {
          chunk += speaker + ": " + t.sentence + "\n";
        });
      });
      content += chunk + "\n";
    });

    const pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(content)
    );
    pom.setAttribute("download", filename);
    if (document.createEvent) {
      const event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User downloaded Conversation Highlights",
    });
  };

  handleOnMouseEnter = () => {
    this.hoverStart = new Date();
  };

  handleOnMouseLeave = () => {
    const timeSpent = new Date() - this.hoverStart;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User hovered Conversation Highlights ${this.state.view} view on ${this.state.tab} tab`,
      value: timeSpent,
    });
  };

  render() {
    const { classes, meetingStatus } = this.props;
    const { tab, view, takeawayFilter } = this.state;
    return (
      <div>
        <Paper>
          <header className={classes.conversationHeader}>
            <Typography className={classes.headerText}>
              CONVERSATION HIGHLIGHTS
            </Typography>
            <div className={classes.options}>
              <div className={classes.viewToggle}>
                <Typography className={classes.toggleOption}>
                  Timeline
                </Typography>
                <Switch
                  checked={view === views.list}
                  onChange={this.handleToggleView}
                  color="primary"
                  size="small"
                />
                <Typography className={classes.toggleOption}>List</Typography>
              </div>
              <Tooltip title="Download Insights">
                <IconButton onClick={this.handleDownload}>
                  <GetAppIcon className={classes.conversationDownloadIcon} />
                </IconButton>
              </Tooltip>
            </div>
          </header>

          <Tabs
            value={tab}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label="Q&As"
              value={tabs.questions}
            ></Tab>
            <Tab
              className={classes.tab}
              label="KEY TAKEAWAYS"
              value={tabs.takeaways}
            ></Tab>
          </Tabs>
          {meetingStatus === speechStatus.completed && (
            <>
              <div
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
              >
                {view === views.list && (
                  <List
                    data={this.props.data}
                    handleInsightOnClick={this.props.handleInsightOnClick}
                    questions={this.props.questions}
                    takeaways={this.props.takeaways}
                    tab={tab}
                    takeawayFilter={takeawayFilter}
                    handleDeleteTakeaway={this.props.handleDeleteTakeaway}
                    scrollToQuestion={this.props.scrollToQuestion}
                    isSharedMeetingView={this.props.isSharedMeetingView}
                  />
                )}
                {view === views.timeline && (
                  <Timeline
                    data={this.props.data}
                    handleInsightOnClick={this.props.handleInsightOnClick}
                    setMediaPlayerCurrentTimeAndPlay={
                      this.props.setMediaPlayerCurrentTimeAndPlay
                    }
                    questions={this.props.questions}
                    takeaways={this.props.takeaways}
                    tab={tab}
                    handleDeleteTakeaway={this.props.handleDeleteTakeaway}
                    isSharedMeetingView={this.props.isSharedMeetingView}
                  />
                )}
              </div>
            </>
          )}
          {meetingStatus === speechStatus.processing && (
            <ProcessingInProgress copy="Processing Insights..." />
          )}
        </Paper>
        {this.renderNewTakeawayDialog()}
      </div>
    );
  }
}

ConversationHighlights.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConversationHighlights);
