import { fade } from "@material-ui/core";

export default (theme) => ({
  modal: {
    display: "flex",
  },
  modalContent: {
    width: "max(80%, 600px)",
    height: "max(80%, 400px)",
    margin: "auto",
    borderRadius: "10px",
    boxShadow: `0 10px 10px 2px ${fade(theme.palette.common.black, 0.25)}`,
    background: theme.palette.common.white,
    overflow: "hidden",
  },
  modalHeader: {
    width: "100%",
    height: "10%",
    backgroundColor: theme.palette.background.grey,
    padding: `0 ${theme.spacing.unit * 4}px`,
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "20px",
    fontWeight: 600,
  },
  modalFooter: {
    width: "100%",
    height: "10%",
    padding: `0 ${theme.spacing.unit * 2}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing.unit,
  },
  modalBody: {
    width: "100%",
    height: "41%",
    display: "flex",
    gap: theme.spacing.unit * 3,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`,
  },
  modalBodySharedView: {
    width: "100%",
    height: "80%",
    display: "flex",
    gap: theme.spacing.unit * 3,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0`,
  },
  modalVideoContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  modalTranscriptContainer: {
    width: "50%",
    height: "100%",
    overflowY: "scroll",
    display: "flex",
  },
  modalTranscriptSnippet: {
    margin: "auto",
  },
  modalSpaceDivision: {
    width: "100%",
    height: "4%",
  },
  modalCommentSection: {
    width: "100%",
    height: "35%",
    overflowY: "scroll",
    display: "block",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,
  },
  modalAddComment: {
    width: "80%",
    margin: "0 10% 0",
  },
  modalCommentInput: {
    width: "100%",
    height: "40px",
    marginBottom: theme.spacing.unit * 2,
  },
  modalCommentInputField: {
    height: "40px",
    borderBottomWidth: "0.5px",
    "&::before": {
      borderBottomWidth: "0.5px",
    },
    "&::after": {
      borderBottomWidth: "0.5px",
    },
  },
  modalCommentButtons: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  modalCommentButtonsWrapper: {
    display: "flex",
    gap: theme.spacing.unit * 2,
    margin: `0 ${theme.spacing.unit * 2}px 0`,
  },
  modalCommentList: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.unit * 3,
    margin: `${theme.spacing.unit * 2}px 10% 0`,
  },
  modalCommentContainer: {
    width: "100%",
  },
  modalCommentHeader: {
    width: "100%",
    display: "flex",
    gap: theme.spacing.unit * 2,
    margin: `0 0 ${theme.spacing.unit * 0.5}px`,
  },
  modalCommentName: {
    fontWeight: 700,
    color: theme.palette.text.darkGrey,
  },
  modalCommentTimestamp: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  modalCommentEditedStatus: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  },
  modalCommentContextContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalCommentContext: {
    color: theme.palette.text.darkGrey,
    width: "calc(100% - 60px)",
  },
  modalCommentEditContext: {
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 60px)",
  },
  modalCommentEditContextField: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    textAlign: "left",
    width: "100%",
    height: "auto",
    resize: "none",
    padding: theme.spacing.unit,
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  deleteIcon: {
    color: theme.palette.danger.mellow,
  },
  snippetSpeaker: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    "&:first-child": {
      marginTop: 0,
    },
  },
  snippetBody: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-wrap",
    "& > p": {
      marginBottom: theme.spacing.unit,
      lineHeight: "1.5",
      cursor: "pointer",
    },
  },
  closeIcon: {
    cursor: "pointer",
  },
});
