export default theme => ({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100vh'
    },
    contentWrapper: {},
    content: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing.unit * 5,
      paddingBototm: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    },
    backButton: {},
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px',
      flexShrink: 0,
      marginBottom: theme.spacing.unit * 5
    },
    logoLink: {
      fontSize: 0
    },
    logoImage: {
      cursor: 'pointer'
    },
    logoDivider: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    contentBody: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    form: {
      paddingLeft: '100px',
      paddingRight: '100px',
      paddingBottom: '125px',
      flexBasis: '700px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2
      }
    },
    title: {
      marginTop: theme.spacing.unit * 5
    },
    fields: {
      marginTop: theme.spacing.unit * 2
    },
    textField: {
      width: '100%',
      '& + & ': {
        marginTop: theme.spacing.unit * 2
      }
    },
    progress: {
      display: 'block',
      marginTop: theme.spacing.unit * 2,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    resetPasswordButton: {
      marginTop: theme.spacing.unit * 2,
      width: '100%'
    },
    fieldError: {
      color: theme.palette.danger.main,
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit
    },
    submitError: {
      color: theme.palette.danger.main,
      alignText: 'center',
      marginBottom: theme.spacing.unit,
      marginTop: theme.spacing.unit * 2
    }
  });
  