import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";

import { MetricBar } from "components";
import { Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import {
  getScoreColor,
  getAvgToneCopy,
  getAvgEnergyCopy,
  getAvgSpeakingDurationCopy,
  getAvgSentimentCopy,
  getLabelDescriptor,
} from "helpers/meetingScore";

function AudienceEngagement(props) {
  const { classes, avgEngagementScore, avgEngagement, handleCollapse } = props;

  return (
    <div className={classes.detailedView}>
      <div className={classes.detailedViewContent}>
        <div className={classes.backButton} onClick={handleCollapse}>
          <ChevronLeft className={classes.largerIcon} color="primary" />
          <div>Back</div>
        </div>
        <section className={classes.individualScore}>
          <div className={classes.detailedScore}>
            <Typography variant="h6" className={classes.scoreLabel}>
              Audience Engagement
              <span className={classes.score}>
                {Math.round(avgEngagementScore)}
                <span className={classes.outOfTen}>/10</span>
              </span>
            </Typography>
            <MetricBar
              barPercentage={avgEngagementScore * 10}
              barColor={getScoreColor(avgEngagementScore)}
              height="10px"
              fillAnimation
            />

            <div className={classes.details}>
              {avgEngagement.duration != null && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Speaking Duration
                    {getLabelDescriptor(avgEngagement.duration)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgSpeakingDurationCopy(avgEngagement.duration)}
                  </Typography>
                </div>
              )}

              {avgEngagement.sentiment && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Sentiment
                    {getLabelDescriptor(avgEngagement.sentiment)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgSentimentCopy(avgEngagement.sentiment)}
                  </Typography>
                </div>
              )}

              {avgEngagement.intensity && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Energy
                    {getLabelDescriptor(avgEngagement.intensity)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgEnergyCopy(avgEngagement.intensity, true)}
                  </Typography>
                </div>
              )}

              {avgEngagement.tone && (
                <div className={classes.subScoreContainer}>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " label"}
                  >
                    Delivery Tone
                    {getLabelDescriptor(avgEngagement.tone)}
                  </Typography>
                  <Typography
                    variant="body"
                    className={classes.subScoreText + " detail"}
                  >
                    {getAvgToneCopy(avgEngagement.tone, true)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default withStyles(styles)(AudienceEngagement);
