import { orange } from "@material-ui/core/colors";

export default theme => ({
    root: {
        padding: theme.spacing.unit * 3
    },
    refreshButton: {
      margin: -theme.spacing.unit * 2
    },
    chartWrapper: {
      position: 'relative',
      height: '300px'
    },
    stats: {
      marginTop: theme.spacing.unit * 2,
      display: 'flex',
      justifyContent: 'center'
    },
    device: {
      textAlign: 'center',
      padding: theme.spacing.unit
    },
    deviceIcon: {
      color: theme.palette.common.neutral
    },
    content: {
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 700,
      marginLeft: theme.spacing.unit
    },
    value: {
      marginTop: theme.spacing.unit,
      color: theme.palette.success.dark
    },
    iconWrapper: {
      alignItems: 'center',
      borderRadius: '50%',
      display: 'inline-flex',
      height: '2rem',
      justifyContent: 'center',
      width: '2rem'
    },
    icon: {
      color: theme.palette.text.secondary,
      fontSize: '2rem',
      height: '2rem',
      width: '2rem'
    },
    footer: {
      alignItems: 'center'
    },
    difference: {
      alignItems: 'center',
      color: theme.palette.danger.dark,
      display: 'inline-flex',
      fontWeight: 700
    },
    actionItem: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing.unit * 4,
        marginLeft: theme.spacing.unit * 2,
        fontWeight: 600
    },
    agendaTopic: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing.unit * 2,
      fontWeight: 600,
      "&:hover .iconOption": {
        visibility: "visible !important",
      },
      display: 'flex',
      alignItems: 'center',
    },
    addIcon: {
      visibility: "hidden", 
      marginRight: 0, 
      marginLeft: theme.spacing.unit,
    },
    deleteIcon: {
      visibility: "hidden", 
      marginLeft: 0,
    },
    topic: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit * 4,
      textTransform: 'capitalize',
      fontWeight: 600
    },
    notes: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
      marginLeft: theme.spacing.unit * 2,
    },
    phrase: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit * 4
    },
    frequentWords: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
      textTransform: 'capitalize'
    },
    pills: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing.unit,
      marginLeft: theme.spacing.unit,
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    summaryTitle: {
      marginTop: theme.spacing.unit * 2,
      marginLeft: theme.spacing.unit * 2,
      color: theme.palette.text.secondary,
    },
    treeRoot: {
      marginTop: theme.spacing.unit * 4,
    },
    summaryItem: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    agendaItem: {
      display: 'flex',
      flexDirection: 'row',
      background: "#ffffff",
      '&:hover': {
          background: "#f1f1f1",
      },
      padding: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    summaryPhrase: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    phraseDeleteButton: {
      marginLeft: "auto",
      width: theme.spacing.unit * 2.5,
      height: theme.spacing.unit * 2.5
    },
    phraseTime: {
      marginLeft: "5px"
    },
    summaryItemTitle: {
      display: "flex",
      alignItems: "center",
    },
    summaryItemWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '3px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '0'
    },
    summaryItemPhrase: {
      justifyContent: "flex-start",
      flexGrow: 1,
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
      marginLeft: theme.spacing.unit * 4,
    },
    summaryItemContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&:hover .iconOption": {
        visibility: "visible !important",
      },
    },
    summaryItemEditing: {
      color: theme.palette.text.secondary,
      whiteSpace: "pre-wrap",
      textAlign: "left",
      width: "85%",
      height: "auto",
      resize: "none",
      padding: theme.spacing.unit,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit,
    },
    summaryItemImportant: {
      color: orange[300],
    },
    questionPaper: {
      padding: theme.spacing.unit,
    },
    insightTitle: {
      fontWeight: 700,
      color: orange[300],
    },
    insightPhrase: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing.unit,
    },
    keyPointsCard: {
      display: 'flex',
      flexDirection: 'row',
      background: orange[50],
      padding: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      color: theme.palette.text.primary,
    },
    keyPointsBox: {
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '3px solid',
      borderColor: '#ffb74d',
      borderRadius: '0',
    },
    keyPointsHeader: {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing.unit * 2,
      fontWeight: 600,
      "&:hover .iconOption": {
        visibility: "visible !important",
      },
      display: 'flex',
      alignItems: 'center',
    },
    keyItemPhrase: {
      justifyContent: "flex-start",
      flexGrow: 1,
      color: theme.palette.text.primary,
      marginTop: theme.spacing.unit,
      marginLeft: theme.spacing.unit * 4,
    },
  });
  