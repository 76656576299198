export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progressWrapper: {
    paddingTop: "48px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.primary,
  },
  freeTierCaption: {
    color: theme.palette.text.secondary,
  },
  toggleButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  uploadButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    minWidth: "110px",
  },
  searchButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    minWidth: "100px",
  },
  snackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  alertCaption: {
    justifyContent: "flex-start",
    flexGrow: 1,
  },
  alertContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
});
