import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
} from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

class LanguageSettings extends Component {
  state = {
    statusButtonTitle: "SAVE",
    languageCode: "en-US",
  };

  componentDidMount() {
    var languageCode = localStorage.getItem("languageCode");
    this.setState({
      languageCode: languageCode,
    });
  }

  handleSave = () => {
    ReactGA.event({
      category: "User",
      action: "User clicked save language code",
    });
    this.props.saveLanguageSettings(this.state.languageCode);
  };

  handleChange = (event) => {
    this.setState({
      languageCode: event.target.value,
    });
  };

  renderFooter = () => {
    const { classes } = this.props;
    return (
      <PortletFooter className={classes.portletFooter}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="small"
          variant="text"
          onClick={this.handleSave}
        >
          {this.state.statusButtonTitle}
        </Button>
      </PortletFooter>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Transcription Language" />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <div className={classes.instructions}>
            <Typography className={classes.caption} variant="body1">
              We can provide more accurate transcription if you choose your
              English dialect from the following list
            </Typography>
          </div>
          <div className={classes.field}>
            <FormControl fullWidth>
              <InputLabel id="transcription-language">Language</InputLabel>
              <Select
                labelId="transcription-language"
                id="transcription-language-select"
                value={this.state.languageCode}
                label="Language"
                onChange={this.handleChange}
              >
                <MenuItem value="en-US">English, US</MenuItem>
                <MenuItem value="en-GB">English, British</MenuItem>
                <MenuItem value="en-AU">English, Australian</MenuItem>
              </Select>
            </FormControl>
          </div>
        </PortletContent>
        {this.renderFooter()}
      </Portlet>
    );
  }
}

LanguageSettings.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LanguageSettings);
