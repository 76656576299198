export const wpmCutoffs = {
  slow: 0,
  optimal: 120,
  fast: 160,
  limit: 220,
};

export const pacingResults = {
  slow: {
    result: "SLOW",
    class: "badPacing",
    caption: (name, data) =>
      `${name} was speaking at ${data} words/min!  This is below the average normal speaking rate and may affect audience engagement. ${name} should try quickening their pace!`,
  },
  optimal: {
    result: "OPTIMAL",
    class: "goodPacing",
    caption: (name, data) =>
      `${name} was speaking at ${data} words/min! This is a great speaking pace! Keep it up ${name}!`,
  },
  fast: {
    result: "FAST",
    class: "badPacing",
    caption: (name, data) =>
      `${name} was speaking at ${data} words/min! This is above the average normal speaking rate. ${name} should try slowing down to make sure their audience is hearing them!`,
  },
};
