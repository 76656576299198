import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SearchBar from "material-ui-search-bar";
import Highlighter from "react-highlight-words";

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Typography,
  Link
} from '@material-ui/core';

import palette from 'theme/palette';

import ReactGA from 'react-ga';

// Component styles
const styles = theme => ({
    transcriptBody: {
        whiteSpace: "pre-wrap",
        marginTop: theme.spacing.unit * 2,
    },
    transcriptSpeakerSegmentBody: {
      whiteSpace: "pre-wrap",
  }
});

class TranscriptDialog extends Component {

  transcript = ""
  searchResults = []
  transcriptResults = []
  transcriptSpeakerSegments = []

  state = {
    searchValue: "",
    showFullTranscript: true
  }

  componentWillMount() {
    this.transcript = this.props.data.transcript
    this.transcriptResults = this.props.data.transcript_results
    this.getTranscriptSpeakerSegments()
  }

  getTranscriptSpeakerSegments = () => {
    if (this.transcriptResults != null && this.transcriptResults.length > 0) {
      var currentSpeakerTag = this.transcriptResults[0].speaker_tag
      var currentTranscript = ''
      this.transcriptResults.forEach((result, index) => {
        if (currentSpeakerTag == result.speaker_tag) {
          currentTranscript += result.sentence + ' '
          if (index == (this.transcriptResults.length - 1)) {
            this.transcriptSpeakerSegments.push({'speaker': currentSpeakerTag, 'transcript': currentTranscript})
          }
        } else {
          this.transcriptSpeakerSegments.push({'speaker': currentSpeakerTag, 'transcript': currentTranscript})
          currentSpeakerTag = result.speaker_tag
          currentTranscript = result.sentence + ' '
        }
      })
    }
  }

  getSentencesFromTranscript = (transcript) => {
    var sentences = transcript.match(/\(?[^\.\?\!]+[\.!\?]\)?/g)
    sentences = sentences.map(s => s.trim());
    return sentences
  }

  searchTranscript = (q) => {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    
    var results = []
    this.transcriptResults.forEach((element, index) => {
        var sentence = element.sentence
        var timestamp = element.timestamp
        if (searchRegex.test(sentence)) {
            results.push({
                sentence: sentence,
                timestamp: timestamp
            })
        }
    })
    return results
  }

  resetTranscriptDialogBody = () => {
    this.searchResults = []
    this.setState({searchValue: "", showFullTranscript: true})
  }

  performSearch = (q) => {
    if (q == "") {
      this.resetTranscriptDialogBody()
      return
    }
    
    const results = this.searchTranscript(q)
    this.searchResults = results
  }

  handleSearchRequest = (q) => {
    this.performSearch(q)

    this.setState({showFullTranscript: false})

    ReactGA.event({
        category: 'Search Transcript',
        action: 'User completed search request'
    });
  }

  addSearchResultWithTimestamp = (result, searchValues) => {
    const { classes, className, ...rest } = this.props;
    
    var sentence = result.sentence + " "
    
    return (
        <div>
            <Highlighter
                highlightStyle={{
                backgroundColor: "transparent",
                color: palette.danger.main,
                fontWeight: 'bold'
                }}
                searchWords={searchValues}
                autoEscape={false}
                textToHighlight={sentence}
            />
            <Typography
                className={classes.phrase}
                variant="body1"
                display="inline"
            >
                ({this.setTimestampLink(result.timestamp)})
                <br /><br />
            </Typography>
        </div>
    )
  }

  setTimestampLink = (timestamp) => {
    return (
      <Link component="button" onClick={() => this.props.setMediaPlayerCurrentTimeAndPlay(timestamp)}>
        {this.timeFormatter(timestamp)}
      </Link>
    )
  }

  timeFormatter = (duration) => {   
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  addSpeakerSegment = (e) => {
    const { classes, className, ...rest } = this.props;
    return (
      <div>
        <Typography
            variant="h6"
            className={classes.transcriptBody}
          >
            Speaker {e.speaker}:
        </Typography>
        <Typography
            variant="body1"
            className={classes.transcriptSpeakerSegmentBody}
          >
            {e.transcript}
        </Typography>
      </div>
    )
  }

  renderFullTranscript = () => {
    return (
      <div>
        {
          this.transcriptSpeakerSegments.map(e => {
            return this.addSpeakerSegment(e)
          })
        }
      </div>
    )
  }

  renderTranscriptDialogBody = (searchValue) => {
    var searchValues = searchValue.split(" ")
    if (this.searchResults.length == 0 && this.state.showFullTranscript == true) {
      if (this.transcriptSpeakerSegments.length == 0){
        return this.transcript
      }
      return this.renderFullTranscript()
    } else {
        return (
            <div>
                {
                    this.searchResults.map(e => {
                        return this.addSearchResultWithTimestamp(e, searchValues)
                    })
                }
            </div>
        )
    }
  }

  renderTranscriptDialog = () => {
    const { classes, className, ...rest } = this.props;
    
    var transcript = this.props.transcript
    if (transcript == null) {
        transcript = ""
    }

    var searchValue = this.state.searchValue
    var title = "Search Transcript"
    var showSearchBar = true

    // If a search term was passed in the props then we want to do a search when the transcript dialog is opened instead of presenting the whole transcript
    if (searchValue == "" && this.props.searchWord != null && this.props.searchWord != "") {
      searchValue = this.props.searchWord
      title = "Talking Points"
      showSearchBar = false
      this.performSearch(searchValue)
    }
    
    return (
      <Dialog open={this.props.open} onClose={this.props.closeHandler} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {showSearchBar &&
            <SearchBar
              value={searchValue}
              onChange={(newValue) => this.setState({ searchValue: newValue })}
              onRequestSearch={() => this.handleSearchRequest(this.state.searchValue)}
              onCancelSearch={() => this.resetTranscriptDialogBody()}
              placeholder="Press Enter to Search"
            />
          }
          <Typography
            variant="body1"
            className={classes.transcriptBody}
          >
            {this.renderTranscriptDialogBody(searchValue)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeHandler} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { classes, className, ...rest } = this.props;

    return (
        <div>
            {this.renderTranscriptDialog()}
        </div>
    );
  }
}

TranscriptDialog.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TranscriptDialog);
