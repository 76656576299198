import React, { Component } from "react";
import styles from "./styles";
import { Link } from "react-router-dom";
import {
  withStyles,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import { dateFormatter, getDateRangeString } from "helpers/time";
import ShareDialog from "views/ViewMeeting/components/ShareDialog";
import OverflowTooltip from "components/OverflowTooltip";
import ReactGA from "react-ga";
import { gaCategoryDashboard } from "helpers/gaUtil";
import { getNumberOfHighlights, hasUnreadClipComments } from "helpers/meetingHighlights";
import { optOutTypes } from "helpers/meetingOptOut";

const dateOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
};
const timeOptions = { hour: "2-digit", minute: "2-digit" };
const rangeFormat = { month: "2-digit", day: "2-digit" };

const tabs = {
  past: 0,
  upcoming: 1,
};

class Meetings extends Component {
  timeSpentOnMeetings = new Date();
  timeSpentOnActionItems = new Date();

  state = {
    pastMeetingsByDate: {},
    upcomingMeetingsByDate: {},
    shareDialogMeetingUuid: null,
    tab: tabs.past,
  };

  componentDidMount() {
    this.groupMeetingsByDate(
      this.props.pastMeetings,
      this.props.upcomingMeetings
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pastMeetings !== prevProps.pastMeetings ||
      this.props.upcomingMeetings !== prevProps.upcomingMeetings
    ) {
      this.groupMeetingsByDate(
        this.props.pastMeetings,
        this.props.upcomingMeetings
      );
    }
  }

  groupMeetingsByDate = (pastMeetings, upcomingMeetings) => {
    const pastMeetingsByDate = {};
    const upcomingMeetingsByDate = {};
    const groupMeeting = (meetingsByDate) => (meeting) => {
      const meetingStartTime = new Date(
        meeting.start_time
      ).toLocaleDateString();
      if (meetingsByDate[meetingStartTime]) {
        meetingsByDate[meetingStartTime].push(meeting);
      } else {
        meetingsByDate[meetingStartTime] = [meeting];
      }
    };
    pastMeetings.forEach(groupMeeting(pastMeetingsByDate));
    upcomingMeetings.forEach(groupMeeting(upcomingMeetingsByDate));
    this.setState({ pastMeetingsByDate, upcomingMeetingsByDate });
  };

  handleClickMeeting = () => {
    this.handleOnMouseLeaveMeetings();

    const { tab } = this.state;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User clicked a meeting in ${
        tab === tabs.past ? "Past" : "Upcoming"
      } Meetings`,
    });
  };

  handleClickActionItemsIcon = (meetingUuid) => (event) => {
    ReactGA.event({
      category: "Dashboard",
      action: "User Clicked an Action Item Count Icon",
    });
    event.preventDefault();
    this.props.history.push(`/view-meeting/${meetingUuid}?widget=ACTION-ITEMS`);
  };

  handleOnMouseEnterMeetings = () => {
    this.timeSpentOnMeetings = new Date();
  };

  handleOnMouseLeaveMeetings = () => {
    let timeSpent = new Date() - this.timeSpentOnMeetings;
    const { tab } = this.state;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User hovered ${
        tab === tabs.past ? "Past" : "Upcoming"
      } Meetings widget`,
      value: timeSpent,
    });
  };

  handleClickShare = (meetingUuid) => (event) => {
    const { tab } = this.state;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User clicked share meeting in ${
        tab === tabs.past ? "Past" : "Upcoming"
      } Meetings`,
    });
    event.preventDefault();
    this.handleOpenShareDialog(meetingUuid);
  };

  handleOpenShareDialog = (meetingUuid) => {
    this.setState({ shareDialogMeetingUuid: meetingUuid });
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User opened quick share dialog",
    });
  };

  handleCloseShareDialog = () => {
    this.setState({ shareDialogMeetingUuid: null });
  };

  handleSeeMore = () => {
    const { tab } = this.state;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User clicked See More in ${
        tab === tabs.past ? "Past" : "Upcoming"
      } Meetings`,
    });
    this.props.history.push(
      "/my-meetings" + (tab === tabs.upcoming ? "?tab=scheduled" : "")
    );
  };

  handleSwitchTab = (tab) => {
    this.setState({ tab });
  };

  handleGoToIntegrations = () => {
    this.props.history.push("/integrations");
  };

  handleUpcomingMeetingBotJoiningTitle = (optOutStatus) => {
    if (optOutStatus === optOutTypes.none) {
      return "Remove Assistant from this meeting";
    } else if (optOutStatus === optOutTypes.byOwner) {
      return "Allow Assistant to join this meeting";
    } else {
      return "";
    }
  };

  handleOptOutOfMeetingByHost = (event, meetingUuid, optOut) => {
    event.preventDefault();
    this.props.optOutOfMeetingByHost(meetingUuid, optOut);
    let actionGA = optOut
      ? "Opt out of meeting clicked"
      : "Opt in meeting clicked";
    ReactGA.event({
      category: "Dashboard",
      action: actionGA,
    });
  };

  renderHeader = () => {
    const { classes } = this.props;
    const { tab } = this.state;
    return (
      <header className={classes.sectionHeader}>
        <div
          className={
            classes.headerOption + (tab === tabs.past ? " active" : "")
          }
          onClick={() => this.handleSwitchTab(tabs.past)}
        >
          PAST MEETINGS
        </div>
        <div className={classes.headerSeparator}></div>
        <div
          className={
            classes.headerOption + (tab === tabs.upcoming ? " active" : "")
          }
          onClick={() => this.handleSwitchTab(tabs.upcoming)}
        >
          UPCOMING MEETINGS
        </div>
      </header>
    );
  };

  renderMeeting = (meeting) => {
    const { classes } = this.props;
    const numOfHighlights = getNumberOfHighlights(meeting.highlights, meeting.clips);
    const [unreadMeetingClipComments, ] = hasUnreadClipComments(meeting.clips);
    const urlParameters = unreadMeetingClipComments ? "?widget=CLIPS" : "";
    const showIndicator = (numOfHighlights > 0 && !meeting.viewed) || unreadMeetingClipComments;
    const upcomingMeeting = this.state.tab === tabs.upcoming;
    const botWontJoin =
      this.state.tab === tabs.upcoming &&
      meeting.user_opted_out_of_assistant !== optOutTypes.none;
    let optOutTooltip = "Assistant will not join";
    if (meeting.user_opted_out_of_assistant === optOutTypes.byParticipant) {
      optOutTooltip += " - ATTENDEE SELECTION";
    } else if (meeting.user_opted_out_of_assistant === optOutTypes.byOwner) {
      optOutTooltip += " - HOST SELECTION";
    }
    const optOutStatus = meeting.user_opted_out_of_assistant;
    return (
      <div>
        <Link
          className={classes.meetingRow}
          onClick={this.handleClickMeeting}
          to={`/view-meeting/${meeting.uuid}${urlParameters}`}
        >
          {!botWontJoin ? (
            <Typography
              variant="body1"
              className={classes.meetingTitle + (showIndicator ? " bold" : "")}
            >
              {showIndicator && (
                <span className={classes.indicator}>
                  <NotificationsActiveIcon className={classes.indicatorIcon} />
                </span>
              )}
              <OverflowTooltip title={meeting.title} placement="bottom-start">
                {meeting.title}
              </OverflowTooltip>
            </Typography>
          ) : (
            <Tooltip title={optOutTooltip}>
              <Typography
                variant="body1"
                className={classes.meetingTitle + " disabled"}
              >
                <CloseIcon className={classes.willNotJoinIcon} />
                <span className={classes.disabled}>{meeting.title}</span>
              </Typography>
            </Tooltip>
          )}
          <Typography variant="body1" className={classes.meetingTime}>
            {new Date(meeting.start_time).toLocaleTimeString([], timeOptions)} (
            {Math.round(meeting.duration / 60)} mins)
          </Typography>
          <Typography variant="body1" className={classes.meetingParticipants}>
            <PeopleIcon />
            {meeting.participants_details.length}
          </Typography>
          {meeting.action_items && (
            <Tooltip
              title={
                "This meeting has " +
                (meeting.action_items.length > 0
                  ? meeting.action_items.length
                  : "no") +
                " action item" +
                (meeting.action_items.length === 1 ? "" : "s")
              }
            >
              <IconButton
                className={`${classes.meetingActionItemsCount} ${
                  meeting.action_items.length > 0
                    ? classes.actionItemsExist
                    : classes.actionItemsNone
                }
              `}
                size="small"
                onClick={this.handleClickActionItemsIcon(meeting.uuid)}
              >
                <AssignmentTurnedInIcon />
                {meeting.action_items.length}
              </IconButton>
            </Tooltip>
          )}
          {upcomingMeeting && (
            <Tooltip
              title={this.handleUpcomingMeetingBotJoiningTitle(optOutStatus)}
            >
              {optOutStatus === optOutTypes.none ? (
                <IconButton
                  size="small"
                  onClick={(event) =>
                    this.handleOptOutOfMeetingByHost(event, meeting.uuid, true)
                  }
                >
                  <PersonAddDisabledIcon
                    className={classes.removeAssistantIcon}
                  />
                </IconButton>
              ) : optOutStatus === optOutTypes.byOwner ? (
                <IconButton
                  size="small"
                  onClick={(event) =>
                    this.handleOptOutOfMeetingByHost(event, meeting.uuid, false)
                  }
                >
                  <PersonAddIcon className={classes.addAssistantIcon} />
                </IconButton>
              ) : (
                <IconButton size="small" disabled="true">
                  <PersonAddIcon color="disabled" />
                </IconButton>
              )}
            </Tooltip>
          )}
          <Tooltip title="Share this meeting">
            <IconButton
              size="small"
              onClick={this.handleClickShare(meeting.uuid)}
            >
              <ShareIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Link>
      </div>
    );
  };

  renderEmptyView = () => {
    const { classes, startDate, endDate } = this.props;
    const { tab } = this.state;
    const rangeSpecified = startDate && endDate;
    return (
      <div className={classes.emptyView}>
        {tab === tabs.past ? (
          <Typography variant="h4" className={classes.emptyCopy}>
            <div>
              There are no completed meetings{" "}
              {rangeSpecified
                ? getDateRangeString(startDate, endDate, rangeFormat)
                : "yet"}
              .
            </div>
            {!rangeSpecified && (
              <div>Use Sonero in a meeting or upload one to get started.</div>
            )}
          </Typography>
        ) : (
          <Typography variant="h4" className={classes.emptyCopy}>
            <div>
              There are no upcoming meetings
              {rangeSpecified &&
                ` ${getDateRangeString(startDate, endDate, rangeFormat)}`}
              .
            </div>
            {!rangeSpecified && (
              <>
                <div>
                  Integrate with Google Calendar or Outlook to get started.
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.handleGoToIntegrations}
                >
                  Go To Integrations
                </Button>
              </>
            )}
          </Typography>
        )}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      pastMeetingsByDate,
      upcomingMeetingsByDate,
      shareDialogMeetingUuid,
      tab,
    } = this.state;

    let meetingsByDate = {};
    if (tab === tabs.past) {
      meetingsByDate = pastMeetingsByDate;
    } else if (tab === tabs.upcoming) {
      meetingsByDate = upcomingMeetingsByDate;
    }

    return (
      <div className={classes.meetingsContainer}>
        {this.renderHeader()}
        <div className={classes.meetingsContent}>
          {Object.keys(meetingsByDate).length > 0 ? (
            <>
              <div
                className={classes.meetingsList}
                onMouseEnter={this.handleOnMouseEnterMeetings}
                onMouseLeave={this.handleOnMouseLeaveMeetings}
              >
                {Object.keys(meetingsByDate).map((date, i) => (
                  <div key={i}>
                    <Typography variant="h5" className={classes.dateHeader}>
                      {dateFormatter(new Date(date), dateOptions)}
                    </Typography>
                    {meetingsByDate[date].map((meeting) =>
                      this.renderMeeting(meeting)
                    )}
                  </div>
                ))}
              </div>
              {!this.props.memberView && (
                <Button
                  color="primary"
                  onClick={this.handleSeeMore}
                  className={classes.seeMoreButton}
                >
                  GO TO MY MEETINGS
                </Button>
              )}
            </>
          ) : (
            this.renderEmptyView()
          )}
        </div>
        <ShareDialog
          meetingUuid={shareDialogMeetingUuid}
          isShareDialogOpen={Boolean(shareDialogMeetingUuid)}
          handleClose={this.handleCloseShareDialog}
          openSnackbar={this.props.handleSnackbarOpen}
          isQuickShare={true}
          isScheduled={true}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Meetings);
