import React, { Component } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import { updateAccount, createAccount } from "services/speech";

import ReactGA from "react-ga";

// Component styles
const styles = (theme) => ({
  field: {
    minWidth: "300px",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
  },
});

class ClientAccountDialog extends Component {
  state = {
    accountName: this.props.accountName,
    projectLead: this.props.projectLead,
    status: this.props.status,
    projectLeadOptions: this.props.projectLeadOptions,
    selectedContacts: [],
    isLoading: false,
    contactOptionsByUuid: {},
    contactOptions: [],
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.accountName !== this.props.accountName ||
      prevProps.projectLead !== this.props.projectLead ||
      prevProps.projectLeadOptions !== this.props.projectLeadOptions ||
      prevProps.contacts !== this.props.contacts ||
      prevProps.contactsOptions !== this.props.contactsOptions ||
      prevProps.status !== this.props.status
    ) {
      var contactOptionsByUuid = {};
      var contactOptions = [];
      for (var i in this.props.contactsOptions) {
        const contact = this.props.contactsOptions[i];
        contactOptionsByUuid[contact.uuid] = contact;
        contactOptions.push(contact.uuid);
      }
      var selectedContacts = this.props.contacts.map((contact) => contact.uuid);
      this.setState({
        accountName: this.props.accountName,
        projectLead: this.props.projectLead,
        projectLeadOptions: this.props.projectLeadOptions,
        status: this.props.status,
        selectedContacts: selectedContacts,
        contactOptionsByUuid: contactOptionsByUuid,
        contactOptions: contactOptions,
      });
    }
  }

  handleSaveAccount = () => {
    var title = this.state.accountName;
    if (!title.trim()) {
      return;
    }
    this.setLoading(true);
    if (this.props.accountUuid !== null) {
      return updateAccount(
        this.props.accountUuid,
        this.state.accountName,
        this.state.projectLead,
        this.state.status,
        this.state.selectedContacts
      ).then(
        function(response) {
          if (response.status !== 200) {
            if (response.status == 401) {
              this.props.history.push("/sign-in");
              return response;
            }
            this.props.openSnackbar(
              "Sorry, but an unknown error occurred. Please try again."
            );
            this.setLoading(false);
            ReactGA.event({
              category: "Failure",
              action: "Update account API failed",
            });
            return response;
          }
          var newAccount = response.data;
          this.props.handleUpdateAccount(newAccount);
          this.setLoading(false);
          this.props.openSnackbar("Account successfully updated");
          ReactGA.event({
            category: "Client Accounts",
            action: "Update account API success",
          });
          this.props.onClose();
          return response;
        }.bind(this)
      );
    } else {
      return createAccount(
        this.state.accountName,
        this.state.projectLead,
        this.state.status,
        this.state.selectedContacts
      ).then(
        function(response) {
          if (response.status !== 201) {
            if (response.status == 401) {
              this.props.history.push("/sign-in");
              return response;
            }
            this.props.openSnackbar(
              "Sorry, but an unknown error occurred. Please try again."
            );
            this.setLoading(false);
            ReactGA.event({
              category: "Failure",
              action: "Create account API failed",
            });
            return response;
          }
          var newAccount = response.data;
          this.props.handleCreateAccount(newAccount);
          this.setLoading(false);
          this.props.openSnackbar("Account successfully created");
          ReactGA.event({
            category: "Client Accounts",
            action: "Create account API success",
          });
          this.props.onClose();
          return response;
        }.bind(this)
      );
    }
  };

  handleAccountNameChange = (value) => {
    this.setState({ accountName: value });
  };

  handleProjectLeadChange = (projectLead) => {
    this.setState({ projectLead: projectLead });
  };

  handleContactsChange = (contacts) => {
    this.setState({ selectedContacts: contacts });
  };

  handleStatusChange = (event) => {
    this.setState({ status: event.target.checked });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render = () => {
    const { classes, className, ...rest } = this.props;
    const dialogTitle = this.props.accountUuid
      ? "Edit Account"
      : "Create New Account";
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.field}
            label="Account Name"
            onChange={(event) =>
              this.handleAccountNameChange(event.target.value)
            }
            required
            value={this.state.accountName}
          />
          <FormControl className={classes.field}>
            <Autocomplete
              options={this.state.projectLeadOptions}
              getOptionLabel={(option) => option.name}
              value={this.state.projectLead}
              onChange={(event, newValue) =>
                this.handleProjectLeadChange(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Account Lead"
                  variant="standard"
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.field}>
            <Autocomplete
              multiple
              options={this.state.contactOptions}
              getOptionLabel={(uuid) => {
                const option = this.state.contactOptionsByUuid[uuid];
                return option.name + " (" + option.email + ")";
              }}
              value={this.state.selectedContacts}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Contacts" />
              )}
              onChange={(event, newValue) =>
                this.handleContactsChange(newValue)
              }
            />
          </FormControl>
          <FormControl className={classes.field}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.status}
                  onChange={this.handleStatusChange}
                  name="status"
                  color="primary"
                />
              }
              label="Active"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            CANCEL
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={() => this.handleSaveAccount()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

ClientAccountDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default withStyles(styles)(ClientAccountDialog);
