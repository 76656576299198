export default (theme) => ({
  header: {
    display: "flex",
    gap: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
  },
  welcomeBanner: {
    width: "100%",
    color: theme.palette.text.dark,
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 6,
    borderRadius: "15px",
    backgroundColor: theme.palette.background.purple,
    position: "relative",
  },
  bannerTextHeavy: {
    fontSize: "32px",
    marginBottom: theme.spacing.unit * 3,
    lineHeight: 1,
    position: "relative",
    zIndex: 1,
    "& > span": {
      color: theme.palette.primary.dark,
    },
  },
  bannerTextLight: {
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: theme.spacing.unit * 0.5,
    width: "fit-content",
    position: "relative",
    zIndex: 1,
  },
  emptyAnchorElem: {
    position: "absolute",
    right: "50%",
  },
  bannerImage: {
    position: "absolute",
    bottom: 0,
    right: theme.spacing.unit * 4,
    height: "200px",
  },
  popper: {
    zIndex: 20,
    marginTop: theme.spacing.unit * 3,
  },
  cardButton: {
    color: theme.palette.background.purple,
    boxSizing: "border-box",
    cursor: "pointer",
    textTransform: "none",
    font: "white",
    fontSize: "16px",
    marginLeft: "auto",
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    background: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  step1Container: {
    backgroundColor: theme.palette.background.purple,
    zIndex: 20,
    maxWidth: 700,
    minWidth: 400,
    minHeight: 200,
    borderRadius: "15px",
    border: `2px solid ${theme.palette.border}`,
    boxShadow: "1px 2px 3px rgba(0,0,0,.5)",
    overflow: "visible",
    position: "relative",
    "&::before": {
      content: `''`,
      width: 0,
      height: 0,
      position: "absolute",
      top: "-16px",
      left: "calc(50% - 16px)",
      border: "16px solid transparent",
      borderTop: "none",
      borderBottom: `16px solid ${theme.palette.background.purple}`,
    },
  },
  step2Container: {
    backgroundColor: theme.palette.background.purple,
    zIndex: 20,
    maxWidth: 500,
    minWidth: 200,
    minHeight: 200,
    borderRadius: "15px",
    border: `2px solid ${theme.palette.border}`,
    boxShadow: "1px 2px 3px rgba(0,0,0,.5)",
    overflow: "visible",
    position: "relative",
    "&::before": {
      content: `''`,
      width: 0,
      height: 0,
      position: "absolute",
      top: "calc(50% - 24px)",
      left: "-8px",
      border: "12px solid transparent",
      borderLeft: "none",
      borderRight: `12px solid ${theme.palette.background.purple}`,
    },
  },
  cardHeader: {
    zIndex: 2,
    fontFamily: "Roboto",
    fontSize: 21,
    margin: theme.spacing.unit * 2,
  },
  cardText: {
    zIndex: 2,
    fontFamily: "Roboto",
    fontSize: 18,
    margin: theme.spacing.unit * 2,
    wordWrap: "break-word",
    lineHeight: 1.75,
  },
  overlay: {
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 20,
    cursor: "pointer",
  },
  selectable: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  datepickerContainer: {
    display: "inline",
  },
  "@media (max-width: 750px)": {
    bannerImage: {
      display: "none",
    },
  },
});
