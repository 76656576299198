export default (theme) => ({
  scoresContainer: {
    width: "60%",
    paddingLeft: theme.spacing.unit * 1.5,
  },
  scoreContainer: {
    padding: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 0.75,
    paddingRight: 0,
    marginBottom: theme.spacing.unit * 2,
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    "& > div": {
      flexGrow: 1,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  scoreLabel: {
    fontSize: "16px",
    color: theme.palette.text.darkGrey,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: theme.spacing.unit * 0.5,
    "& > span": {
      float: "right",
    },
  },
  score: {
    fontWeight: "700",
    fontSize: "28px",
    color: theme.palette.text.darkGrey,
    "&.overall": {
      fontSize: "42px",
    },
  },
  outOfTen: {
    fontSize: "14px",
    color: theme.palette.text.light,
  },
  largerIcon: {
    transform: "scale(1.2)",
  },
});
