import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import {
  Typography,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { conversationHighlightTypes } from "helpers";
import { getSpeakerString } from "helpers/meetingParticipants";
import { timeFormatter } from "helpers/time";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import DeleteIcon from "@material-ui/icons/Delete";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import theme from "theme";

const CHUNK_SIZE = 300;

const takeawayFilters = {
  all: "all",
  generic: conversationHighlightTypes.takeaways,
  win: conversationHighlightTypes.wins,
  opportunity: conversationHighlightTypes.opportunities,
  painPoint: conversationHighlightTypes.painPoints,
};

class List extends Component {
  state = {
    takeawayFilter: takeawayFilters.all,
    showScrollTip: true,
    autoScrolled: false,
  };
  containerRef = React.createRef();
  questionRefs = {};
  hoverStart = new Date();

  componentDidMount() {
    this.setState({ showScrollTip: this.hasMoreContent() });
    this.handleAutoScroll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tab !== this.props.tab) {
      this.handleResetScroll();
      this.setState({ showScrollTip: this.hasMoreContent() });
      this.handleAutoScroll();
    }
  }

  handleResetScroll = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollTop = 0;
    }
  };

  handleAutoScroll = () => {
    if (!this.state.autoScrolled && this.props.scrollToQuestion) {
      this.handleScrollQuestionIntoView();
    }
  };

  handleScrollQuestionIntoView = () => {
    const timestamp = Math.round(this.props.scrollToQuestion);
    if (this.questionRefs[timestamp]) {
      this.questionRefs[timestamp].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      this.questionRefs[timestamp].style.backgroundColor =
        theme.palette.warning.light;
      this.setState({ autoScrolled: true });
    }
  };

  handleChangeTakeawayFilter = (event) => {
    this.setState({ takeawayFilter: event.target.value });
  };

  handleClickQuestionAnswer = (question) => {
    this.props.handleInsightOnClick(
      question,
      conversationHighlightTypes.questions
    );
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked on a question event in Conversation Highlights",
    });
  };

  handleClickTakeaway = (takeaway) => {
    this.props.handleInsightOnClick(
      takeaway,
      conversationHighlightTypes.takeaways
    );
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User clicked on a takeaway event in Conversation Highlights",
    });
  };

  handleOnMouseEnter = () => {
    this.hoverStart = new Date();
  };

  handleOnMouseLeave = (type) => {
    let timeSpent = new Date() - this.hoverStart;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User hovered ${type} event in Conversation Highlights`,
      value: timeSpent,
    });
  };

  handleDeleteTakeaway = (event, takeaway) => {
    event.stopPropagation();
    this.props.handleDeleteTakeaway(takeaway);
  };

  hasMoreContent = () => {
    if (this.containerRef.current) {
      return (
        this.containerRef.current.scrollHeight >
        this.containerRef.current.clientHeight
      );
    }
    return false;
  };

  atEndOfScroll = () => {
    const container = this.containerRef.current;
    if (container) {
      const { clientHeight, scrollTop, scrollHeight } = container;
      if (clientHeight + scrollTop + 1 >= scrollHeight) {
        this.setState({ showScrollTip: false });
      } else {
        this.setState({ showScrollTip: true });
      }
    }
  };

  chunkTranscriptSegment = (segment) => {
    const chunks = [];
    let chunk = "";
    segment.transcript.forEach((t) => {
      if (chunk.length > 0 && chunk.length + t.sentence.length > CHUNK_SIZE) {
        chunks.push(chunk);
        chunk = t.sentence + " ";
      } else {
        chunk += t.sentence + " ";
      }
    });
    chunks.push(chunk);
    return chunks;
  };

  renderQuestionsAndAnswers = () => {
    const { classes, questions, data } = this.props;
    const participantsDetails = data.participants_details;

    return (
      <>
        {questions.length === 0 && (
          <Typography className={classes.emptyText}>
            No questions found
          </Typography>
        )}
        {questions.map((qa) => (
          <div
            ref={(instance) => {
              this.questionRefs[Math.round(qa.timestamp)] = instance;
            }}
            className={classes.section}
            onClick={() => this.handleClickQuestionAnswer(qa)}
            onMouseEnter={this.handleOnMouseEnter}
            onMouseLeave={() => this.handleOnMouseLeave("question")}
          >
            <Typography className={classes.itemHeader}>
              <span className={`${classes.type} question`}>Question</span>
              <span className={classes.timestamp}>
                {timeFormatter(qa.timestamp)}
              </span>
            </Typography>
            <div className={classes.itemContent}>
              {qa.transcriptSegments.map((segment) => {
                const chunks = this.chunkTranscriptSegment(segment);
                return (
                  <div className={classes.segment}>
                    <Typography className={classes.segmentSpeaker}>
                      {getSpeakerString(segment.speaker, participantsDetails)}
                    </Typography>
                    <Typography className={classes.segmentContent}>
                      {chunks.map((chunk) => (
                        <p>{chunk} </p>
                      ))}
                    </Typography>
                  </div>
                );
              })}
            </div>

            <Typography className={classes.itemHeader}>
              <span className={`${classes.type} answer`}>Answer</span>
              <span className={classes.timestamp}>
                {timeFormatter(qa.answerTimestamp)}
              </span>
            </Typography>
            <div className={classes.itemContent}>
              {qa.answerSegments.map((segment) => {
                const chunks = this.chunkTranscriptSegment(segment);
                return (
                  <div className={classes.segment}>
                    <Typography className={classes.segmentSpeaker}>
                      {getSpeakerString(segment.speaker, participantsDetails)}
                    </Typography>
                    <Typography className={classes.segmentContent}>
                      {chunks.map((chunk) => {
                        const daStart = qa.directAnswer
                          ? chunk.indexOf(qa.directAnswer)
                          : -1;
                        if (daStart !== -1) {
                          const daEnd = daStart + qa.directAnswer.length;
                          return (
                            <p>
                              {chunk.slice(0, daStart)}
                              <Tooltip title="Insight: Direct Answer">
                                <span className={classes.highlight}>
                                  {chunk.slice(daStart, daEnd)}
                                </span>
                              </Tooltip>
                              {chunk.slice(daEnd)}
                            </p>
                          );
                        }
                        return <p>{chunk}</p>;
                      })}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </>
    );
  };

  renderKeyTakeaways = () => {
    const { classes, takeaways, data } = this.props;
    const { takeawayFilter } = this.state;
    const participantsDetails = data.participants_details;

    let filteredTakeaways = takeaways;
    if (takeawayFilter === takeawayFilters.generic) {
      filteredTakeaways = takeaways.filter(
        (t) => t.subtype === conversationHighlightTypes.takeaways
      );
    } else if (takeawayFilter === takeawayFilters.win) {
      filteredTakeaways = takeaways.filter(
        (t) => t.subtype === conversationHighlightTypes.wins
      );
    } else if (takeawayFilter === takeawayFilters.opportunity) {
      filteredTakeaways = takeaways.filter(
        (t) => t.subtype === conversationHighlightTypes.opportunities
      );
    } else if (takeawayFilter === takeawayFilters.painPoint) {
      filteredTakeaways = takeaways.filter(
        (t) => t.subtype === conversationHighlightTypes.painPoints
      );
    }
    filteredTakeaways.sort((t1, t2) => t1.timestamp - t2.timestamp);

    return (
      <>
        <div className={classes.filterContainer}>
          <Typography variant="h6" color="textSecondary">
            Filter:{" "}
          </Typography>
          <Select
            variant="outlined"
            value={takeawayFilter}
            onChange={this.handleChangeTakeawayFilter}
            classes={{ root: classes.filterSelectRoot }}
          >
            <MenuItem value={takeawayFilters.all}>All</MenuItem>
            <MenuItem value={takeawayFilters.generic}>Generic</MenuItem>
            <MenuItem value={takeawayFilters.win}>Wins</MenuItem>
            <MenuItem value={takeawayFilters.opportunity}>
              Opportunities & Next Steps
            </MenuItem>
            <MenuItem value={takeawayFilters.painPoint}>Pain Point</MenuItem>
          </Select>
        </div>
        {filteredTakeaways.length === 0 && (
          <Typography className={classes.emptyText}>
            No takeaways found
          </Typography>
        )}
        {filteredTakeaways.map((takeaway) => (
          <div
            className={classes.section}
            onClick={() => this.handleClickTakeaway(takeaway)}
            onMouseEnter={this.handleOnMouseEnter}
            onMouseLeave={() => this.handleOnMouseLeave("takeaway")}
          >
            <Typography className={classes.itemHeader}>
              <span className={`${classes.type} takeaway`}>Takeaway</span>
              <span className={classes.timestamp}>
                {timeFormatter(takeaway.timestamp)}
              </span>
              <div className={classes.chipsContainer}>
                {takeaway.subtype === conversationHighlightTypes.wins && (
                  <div className={classes.headerChip + " win"}>
                    <div className={classes.chipContent}>
                      <EmojiEventsIcon className="icon" /> Win
                    </div>
                  </div>
                )}
                {takeaway.subtype ===
                  conversationHighlightTypes.opportunities && (
                  <div className={classes.headerChip + " opportunity"}>
                    <div className={classes.chipContent}>
                      <EmojiObjectsIcon className="icon" /> Opportunity & Next
                      Step
                    </div>
                  </div>
                )}
                {takeaway.subtype === conversationHighlightTypes.painPoints && (
                  <div className={classes.headerChip + " painPoint"}>
                    <div className={classes.chipContent}>
                      <FitnessCenterIcon className="icon" /> Pain Point
                    </div>
                  </div>
                )}
                {takeaway.facialEmotion === "positive" && (
                  <div className={classes.headerChip + " thrilled expandable"}>
                    <div className={classes.chipContent}>
                      <InsertEmoticonIcon className="icon" /> People were
                      thrilled
                    </div>
                    <div className={classes.hoverTooltip}>
                      Smiles detected via facial analysis
                    </div>
                  </div>
                )}
                {takeaway.facialEmotion === "negative" && (
                  <div className={classes.headerChip + " down expandable"}>
                    <div className={classes.chipContent}>
                      <SentimentDissatisfiedIcon className="icon" /> People were
                      down
                    </div>
                    <div className={classes.hoverTooltip}>
                      Gloomy looks detected via facial analysis
                    </div>
                  </div>
                )}
              </div>
              {!this.props.isSharedMeetingView && (
                <IconButton
                  size="small"
                  onClick={(e) => this.handleDeleteTakeaway(e, takeaway)}
                  className={classes.deleteIcon}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Typography>
            <div className={classes.itemContent}>
              {takeaway.transcriptSegments.map((segment) => {
                const chunks = this.chunkTranscriptSegment(segment);
                return (
                  <div className={classes.segment}>
                    <Typography className={classes.segmentSpeaker}>
                      {getSpeakerString(segment.speaker, participantsDetails)}
                    </Typography>
                    <Typography className={classes.segmentContent}>
                      {chunks.map((chunk) => (
                        <p>{chunk} </p>
                      ))}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const { classes, tab } = this.props;
    return (
      <>
        <div
          className={classes.listContent}
          ref={this.containerRef}
          onScroll={this.atEndOfScroll}
        >
          {tab === conversationHighlightTypes.questions &&
            this.renderQuestionsAndAnswers()}
          {tab === conversationHighlightTypes.takeaways &&
            this.renderKeyTakeaways()}
        </div>
        {this.state.showScrollTip && (
          <Typography className={classes.scrollTip}>Scroll for more</Typography>
        )}
      </>
    );
  }
}

List.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);
