export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  addMeetingSummaryTextField: {
    width: "500px",
  },
});
