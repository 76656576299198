import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { QuestionAnswer } from "@material-ui/icons";
import classNames from "classnames";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import styles from "./styles";

class QuestionAnswerButton extends Component {
  handleClick = (event) => {
    this.props.onClick(
      event.currentTarget,
      this.props.sentence,
      this.props.keyPointOrTopic
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);
    return (
      <Tooltip title={this.props.tooltip}>
        <IconButton onClick={this.handleClick}>
          <QuestionAnswer className={classes.summaryItemImportant} />
        </IconButton>
      </Tooltip>
    );
  }
}

QuestionAnswerButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuestionAnswerButton);
