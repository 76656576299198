// Palette
import palette from 'theme/palette';

import 'chart.piecelabel.js';

// Chart options
export const options = {
  legend: {
    display: true,
    position: 'bottom',
    labels: {
        usePointStyle: true
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: true,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  pieceLabel: {
    render: 'value',
    position: 'outside',
    outsidePadding: 4
  },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  }
};
