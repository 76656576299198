import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";
import { TextField, Button, MenuItem } from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from "components";

// Component styles
import styles from "./styles";

import { meetingParticipants } from "helpers";
import { pricingTiers } from "helpers";

class UploadVideo extends Component {
  fileDuration = 0;
  fileURL = null;

  state = {
    title: "",
    type: "",
    radioValue: "",
    selectedFile: null,
    showTitleError: false,
    showFileError: false,
    showAgendaError: false,
    fileError: "Please select a recording",
    numOfParticipants: 2,
  };

  componentDidMount() {
    document.getElementById("audio").addEventListener(
      "canplaythrough",
      function(e) {
        this.fileDuration = Math.ceil(e.currentTarget.duration);
        if (this.fileURL != null) {
          URL.revokeObjectURL(this.fileURL);
          this.fileURL = null;
        }
      }.bind(this)
    );
  }

  handleTitleChange = (title) => {
    this.setState({ title: title, showTitleError: false });
  };

  handleNumOfParticipantsChange = (value) => {
    this.setState({ numOfParticipants: value });
  };

  handleFileChange = (selectedFile) => {
    this.fileURL = URL.createObjectURL(selectedFile);
    document.getElementById("audio").setAttribute("src", this.fileURL);
    this.setState({ selectedFile: selectedFile, showFileError: false });
  };

  validate = () => {
    const pricingTier = localStorage.getItem("pricingTier");
    if (this.state.title.length === 0 || !this.state.title.trim()) {
      this.setState({ showTitleError: true });
      return false;
    }
    if (this.state.selectedFile == null) {
      this.setState({
        showFileError: true,
        fileError: "Please select a recording",
      });
      return false;
    }
    var file = document.getElementById("raised-button-file").files[0];
    if (pricingTier == pricingTiers.free) {
      // Duration limit - 120 mins
      if (this.fileDuration > 7200) {
        this.setState({
          showFileError: true,
          fileError: "Please select a recording that is less than 120 minutes",
        });
        return false;
      }
      // Size limit - 150 MB
      if (file.size > 157286400) {
        this.setState({
          showFileError: true,
          fileError: "Please select a recording that is less than 150 MB",
        });
        return false;
      }
    } else {
      // Duration limit - 5 hrs
      if (this.fileDuration > 18000) {
        this.setState({
          showFileError: true,
          fileError: "Please select a recording that is less than 5 hours",
        });
        return false;
      }
      // Size limit - 500 MB
      if (file.size > 524288000) {
        this.setState({
          showFileError: true,
          fileError: "Please select a recording that is less than 500 MB",
        });
        return false;
      }
    }
    return true;
  };

  handleUpload = () => {
    if (this.validate() === false) {
      return;
    }

    this.props.uploadSpeech(
      this.state.selectedFile,
      this.state.title,
      this.state.numOfParticipants
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Upload Recording" />
        </PortletHeader>
        <PortletContent className={classes.content}>
          <form autoComplete="off" noValidate>
            <div className={classes.fieldFirst}>
              <TextField
                className={classes.textField}
                label="Meeting Title"
                margin="dense"
                onChange={(event) => this.handleTitleChange(event.target.value)}
                required
                variant="outlined"
                value={this.state.title}
              />
              {this.state.showTitleError && (
                <Typography className={classes.fieldError} variant="body2">
                  This field is required
                </Typography>
              )}
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Number of Participants"
                margin="dense"
                onChange={(event) =>
                  this.handleNumOfParticipantsChange(event.target.value)
                }
                required
                select
                value={this.state.numOfParticipants}
                variant="outlined"
              >
                {meetingParticipants.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={classes.field}>
              <input
                accept="audio/x-m4a,audio/wav,audio/mpeg,video/mp4,video/webm"
                className={classes.input}
                id="raised-button-file"
                type="file"
                required
                onChange={(event) =>
                  this.handleFileChange(event.target.files[0])
                }
              />
              <label htmlFor="raised-button-file">
                <Typography className={classes.fieldFormats} variant="body2">
                  Supported formats: m4a, mp3, wav, mp4, webm
                </Typography>
              </label>
              <audio id="audio"></audio>
              {this.state.showFileError && (
                <Typography className={classes.fieldError} variant="body2">
                  {this.state.fileError}
                </Typography>
              )}
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={this.handleUpload}
          >
            Submit
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

UploadVideo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadVideo);
