import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
  TrendingUp as TrendingUpIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class Delivery extends Component {
  state = {
    result: "",
    caption: "",
    valueClassName: ""
  }

  componentWillMount() {
    const data = this.props.data.delivery_tone;
    const classes = this.props.classes

    if (data === "no_emotion") {
      this.setState({
        result: "UNVARIED",
        caption: `You had very little emotion in your delivery which made it sound monotonous. Try emphasizing some phrases and action words to give some ebb and flow which will greatly improve your delivery!`,
        valueClassName: classes.valueBad
      })
    } else if (data === "conversational") {
      this.setState({
        result: "CONVERSATIONAL",
        caption: `You delivery sounded conversational. This is good for informing the audience! But if you also want to entertain your audience, try to sound a bit more passionate!`,
        valueClassName: classes.valueGood
      })
    } else if (data === "passionate") {
      this.setState({
        result: "PASSIONATE",
        caption: `You sounded very passionate in your delivery! Passion is critical in entertaining your audience! Keep it up!`,
        valueClassName: classes.valueGood
      })
    } else {
      this.setState({
        result: "UNKNOWN",
        caption: "Sorry, a problem occurred when analyzing your recording!",
        valueClassName: classes.valueBad
      })
    }
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h6"
            >
              DELIVERY
            </Typography>
            <Typography
              className={this.state.valueClassName}
              variant="h4"
            >
              {this.state.result}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <TrendingUpIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.footer}>
          <Typography
            className={classes.caption}
            variant="body1"
          >
            {this.state.caption}
          </Typography>
        </div>
      </Paper>
    );
  }
}

Delivery.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Delivery);
