export default theme => ({
  root: {},
  basicInfoFieldsContainer: {
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5em",
    '@media (max-width: 400px)': {
      flexDirection: "column",
      alignItems: "flex-start"
    },
  },
  fieldLabel: {
    color: "#66788A",
    fontWeight: "500",
    minWidth: "120px",
    fontSize: "14px"
  },
  fieldContent: {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  fieldIcon: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  editingInput: {
    fontSize: "16px",
    padding: "0.1em",
    width: "80%"
  }
});