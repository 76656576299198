import React, { Component } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import { Typography, Button, Select, MenuItem } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import Highlighter from "react-highlight-words";
import theme from "theme";
import ReactGA from "react-ga";
import { gaCategoryDashboard } from "helpers/gaUtil";
import { dateFormatter } from "helpers/time";

const tabs = {
  overview: "Overview",
  overtime: "Over Time",
};

const dateFormat = { month: "2-digit", day: "2-digit" };

const ALL = "f9799d6329bb465988ce46a318876ce3"

class Trackers extends Component {
  state = {
    tab: tabs.overview,
    tracker: null,
    selectedTrackerWord: "",
  };
  meetingsMap = {};
  hoverStart = new Date();

  componentDidMount() {
    this.buildMeetingsMap();
    this.updateSelectedTrackerWord();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pastMeetings !== this.props.pastMeetings) {
      this.buildMeetingsMap();
    }
    if (prevProps.trackers !== this.props.trackers) {
      this.updateSelectedTrackerWord();
    }
  }

  buildMeetingsMap = () => {
    const { pastMeetings } = this.props;
    pastMeetings.forEach((meeting) => {
      this.meetingsMap[meeting.uuid] = meeting;
    });
  };

  updateSelectedTrackerWord = () => {
    const { trackers } = this.props;
    if (!trackers || trackers.length === 0) return;
    this.setState({ selectedTrackerWord: ALL });
  };

  getUpperBoundAndIncrement = (values) => {
    const dataMaxValue = Math.max(...values);
    const upperBound = dataMaxValue > 5 ? dataMaxValue : 5;
    const increment =
      dataMaxValue > 7 ? undefined : [...Array(upperBound + 1).keys()];
    return [upperBound, increment];
  };

  handleTabChange = (_event, newValue) => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: `User switched to ${newValue} tab in Trackers widget`,
    });
    this.setState({ tab: newValue });
  };

  handleClickTrackerBar = (bar) => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User clicked on the tracker bar graph",
    });
    this.setState({ tracker: bar.data });
  };

  handleBackToTrackers = () => {
    this.setState({ tracker: null });
  };

  handleClickTrackerSentence = (meetingUuid, sentence) => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User clicked on a tracker sentence",
    });
    this.props.history.push(
      `/view-meeting/${meetingUuid}?tracker=${sentence.keyword}@${sentence.timestamp}`
    );
  };

  handleGoToTrackers = () => {
    this.props.history.push("/trackers");
  };

  handleSelectedTrackerWordChange = (event) => {
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: "User changed the tracker word on the over time graph",
    });
    this.setState({ selectedTrackerWord: event.target.value });
  };

  handleMouseEnter = () => {
    this.hoverStart = new Date();
  };

  handleMouseLeave = (action) => {
    const timeSpent = new Date() - this.hoverStart;
    ReactGA.event({
      category: gaCategoryDashboard(),
      action: action,
      value: timeSpent,
    });
  };

  renderTrackerView = () => {
    const { classes } = this.props;
    const { tracker } = this.state;

    const trackerData = [];
    for (let uuid of Object.keys(tracker.meetings)) {
      const meeting = this.meetingsMap[uuid];
      if (
        !meeting ||
        !tracker.meetings[uuid] ||
        tracker.meetings[uuid].length === 0
      ) {
        continue;
      }

      const data = {
        meetingTitle: meeting.title,
        meetingUuid: meeting.uuid,
        sentences: [],
      };
      const timestamps = tracker.meetings[uuid].timestamps;
      let timestamp_idx = 0;
      for (let result of meeting.transcript_results) {
        if (
          !result.timestamp ||
          !timestamps[timestamp_idx] ||
          result.timestamp.toFixed(2) !== timestamps[timestamp_idx].toFixed(2)
        ) {
          continue;
        }

        data.sentences.push({
          text: result.sentence,
          keyword: tracker.keyword,
          timestamp: timestamps[timestamp_idx],
        });
        timestamp_idx += 1;
        if (timestamp_idx >= timestamps.length) break;
      }
      trackerData.push(data);
    }

    return (
      <div className={classes.trackerContainer}>
        <header className={classes.trackerHeader}>
          <Typography className={classes.boldText}>
            ({tracker.count}) {tracker.keyword}
          </Typography>
          <Button color="primary" onClick={this.handleBackToTrackers}>
            Back to Overview
          </Button>
        </header>
        <div
          className={classes.trackerContent}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={() =>
            this.handleMouseLeave("User hovered tracker details")
          }
        >
          {trackerData.map((data) => (
            <div>
              <Typography className={classes.trackerMeetingTitle}>
                {data.meetingTitle}
              </Typography>
              {data.sentences.map((sentence, idx) => (
                <Typography
                  className={classes.trackerSentence}
                  onClick={() =>
                    this.handleClickTrackerSentence(data.meetingUuid, sentence)
                  }
                >
                  <Typography>{idx + 1}. </Typography>
                  <Highlighter
                    highlightClassName={classes.highlightText}
                    searchWords={[tracker.keyword]}
                    autoEscape={true}
                    textToHighlight={sentence.text}
                  />
                </Typography>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderOverviewGraphView = () => {
    const { classes, trackers } = this.props;

    // Reverse trackers to show in desc order
    // Can't use Array.reverse() because we can't modify props
    const data = [];
    for (let i = trackers.length - 1; i >= 0; i--) {
      data.push(trackers[i]);
    }
    // Compute upperBound and increment for ensuring graph scales correctly
    const [upperBound, increment] = this.getUpperBoundAndIncrement(
      data.map((element) => element.count)
    );

    return (
      <div
        className={classes.overviewGraphContainer}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={() =>
          this.handleMouseLeave("User hovered tracker overview graph")
        }
      >
        <ResponsiveBar
          data={data}
          maxValue={upperBound}
          indexBy="keyword"
          keys={["count"]}
          layout="horizontal"
          groupMode="grouped"
          padding={0.5}
          margin={{ top: 25, right: 30, bottom: 50, left: 90 }}
          borderRadius={5}
          colors={theme.palette.primary.orange}
          colorBy="indexValue"
          enableLabel={false}
          enableGridX={true}
          enableGridY={false}
          gridXValues={increment}
          axisLeft={{
            tickSize: 0,
            tickPadding: 3,
            format: (value) => {
              if (value.length > 17) {
                return value.slice(0, 16) + "...";
              }
              return value;
            },
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickValues: increment,
            legend: "Times mentioned",
            legendPosition: "middle",
            legendOffset: 30,
          }}
          tooltip={(bar) => {
            return (
              <>
                <b>{bar.data.keyword}</b> was mentioned {bar.data.count} times
              </>
            );
          }}
          onClick={this.handleClickTrackerBar}
        />
      </div>
    );
  };

  renderOvertimeGraphView = () => {
    const { classes, trackers } = this.props;
    const { selectedTrackerWord } = this.state;
    let groupData = [];
    let groupUpperBound = 0;
    let groupIncrement = 0;
    let minX = 0;
    let maxX = 0;
    if (selectedTrackerWord === ALL) {
      trackers.forEach((selectedTracker) => {
        // Get array of meeting uuids, sorted by start date
        const meetingUuids = Object.keys(selectedTracker.meetings).sort(
          (uuid1, uuid2) => {
            const startTime1 = new Date(this.meetingsMap[uuid1].start_time);
            const startTime2 = new Date(this.meetingsMap[uuid2].start_time);
            return startTime1 - startTime2;
          }
        );
        // Build line graph data
        const data = [];
        meetingUuids.forEach((uuid) => {
          const meeting = this.meetingsMap[uuid];
          const meetingStartDate = (new Date(meeting.start_time)).getTime();
          if (data.length === 0) {
            data.push({
              x: meetingStartDate,
              y: selectedTracker.meetings[uuid].count,
            });
          } else {
            const lastDataPoint = data[data.length - 1];
            if (dateFormatter(lastDataPoint.x, dateFormat) === dateFormatter(meetingStartDate, dateFormat)) {
              lastDataPoint.y += selectedTracker.meetings[uuid].count;
            } else {
              data.push({
                x: meetingStartDate,
                y: selectedTracker.meetings[uuid].count,
              });
            }
          }
          if (meetingStartDate < minX || minX === 0) {
            minX = meetingStartDate;
          }
          if (meetingStartDate > maxX) {
            maxX = meetingStartDate;
          }
        });
        // Compute upperBound and increment for ensuring graph scales correctly
        const [upperBound, increment] = this.getUpperBoundAndIncrement(
          data.map((meetingData) => meetingData.y)
        );
        // Update group values
        groupData.push({
          id: selectedTracker.keyword,
          data: data,
        });
        if (upperBound > groupUpperBound) {
          groupUpperBound = upperBound;
          groupIncrement = increment;
        }
      });
    } else {
      const selectedTracker = trackers.find(
        (tracker) => tracker.keyword === selectedTrackerWord
      );
      // Get array of meeting uuids, sorted by start date
      const meetingUuids = Object.keys(selectedTracker.meetings).sort(
        (uuid1, uuid2) => {
          const startTime1 = new Date(this.meetingsMap[uuid1].start_time);
          const startTime2 = new Date(this.meetingsMap[uuid2].start_time);
          return startTime1 - startTime2;
        }
      );
      // Build line graph data
      const data = [];
      meetingUuids.forEach((uuid) => {
        const meeting = this.meetingsMap[uuid];
        const meetingStartDate = (new Date(meeting.start_time)).getTime();
        if (data.length === 0) {
          data.push({
            x: meetingStartDate,
            y: selectedTracker.meetings[uuid].count,
          });
        } else {
          const lastDataPoint = data[data.length - 1];
          if (dateFormatter(lastDataPoint.x, dateFormat) === dateFormatter(meetingStartDate, dateFormat)) {
            lastDataPoint.y += selectedTracker.meetings[uuid].count;
          } else {
            data.push({
              x: meetingStartDate,
              y: selectedTracker.meetings[uuid].count,
            });
          }
        }
      });
      // Annotate line graph data with firstHalf field used to determine tooltip position
      data.forEach((datum, idx) => {
        datum.firstHalf = idx < data.length / 2;
      });
      // Compute upperBound and increment for ensuring graph scales correctly
      const [upperBound, increment] = this.getUpperBoundAndIncrement(
        data.map((meetingData) => meetingData.y)
      );
      // Update group values
      groupData.push({
        id: selectedTracker.keyword,
        data: data,
      });
      groupUpperBound = upperBound;
      groupIncrement = increment;
    }

    return (
      <div>
        <div className={classes.overtimeDropdown}>
          <Typography variant="h6">Tracker</Typography>
          <Select
            variant="outlined"
            value={selectedTrackerWord}
            onChange={this.handleSelectedTrackerWordChange}
            classes={{ root: classes.dropdownSelectRoot }}
          >
            <MenuItem key={-1} value={ALL}>
                All
              </MenuItem>
            {trackers.map((tracker, idx) => (
              <MenuItem key={idx} value={tracker.keyword}>
                {tracker.keyword}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          className={classes.overtimeGraphContainer}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={() =>
            this.handleMouseLeave("User hovered tracker over time graph")
          }
        >
          <ResponsiveLine
            data={groupData}
            yScale={{
              type: "linear",
              max: groupUpperBound + 1,
            }}
            xScale={(selectedTrackerWord !== ALL) ? { type: "point" } : { type: "linear", min: minX, max: maxX }}
            margin={{ top: 15, right: 45, bottom: 60, left: 60 }}
            axisBottom={{
              format: (value) => dateFormatter(value, dateFormat),
              tickValues: 8,
              tickSize: 0,
              tickRotation: 0,
              tickPadding: 10,
            }}
            axisLeft={{
              tickSize: 0,
              tickRotation: 0,
              tickPadding: 5,
              tickValues: groupIncrement,
            }}
            colors={(selectedTrackerWord !== ALL) ? theme.palette.primary.orange : { scheme: 'nivo' }}
            pointSize={3}
            pointColor={{ theme: "background" }}
            pointBorderWidth={1}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            enableSlices={false}
            useMesh={true}
            crosshairType="bottom"
            tooltip={(e) => {
              // Update tooltip position
              const style = (selectedTrackerWord !== ALL)
              ? {
                top: e.point.data.y > 5 && 20,
                bottom: e.point.data.y <= 5 && 20,
                left: e.point.data.firstHalf && 0,
                right: !e.point.data.firstHalf && 0,
              }
              : {
                top: e.point.data.y > 5 && 20,
                bottom: e.point.data.y <= 5 && 20,
                right: 0,
              };
              return (
                <div className={classes.tooltip} style={style}>
                  <b>{(selectedTrackerWord !== ALL) ? selectedTrackerWord : e.point.serieId}</b> was mentioned{" "}
                  {e.point.data.y} time{e.point.data.y !== 1 ? "s" : ""} on {dateFormatter(e.point.data.x, dateFormat)}
                </div>
              )
            }}
          />
        </div>
      </div>
    );
  };

  renderGraphView = () => {
    const { classes } = this.props;
    const { tab } = this.state;

    return (
      <div className={classes.graphViewContainer}>
        <ToggleButtonGroup
          className={classes.graphViewTabs}
          value={tab}
          onChange={this.handleTabChange}
          exclusive
        >
          <ToggleButton
            value={tabs.overview}
            className={classes.tabButton}
            classes={{ selected: classes.selected }}
            disabled={tab === tabs.overview}
          >
            Overview
          </ToggleButton>
          <ToggleButton
            value={tabs.overtime}
            className={classes.tabButton}
            classes={{ selected: classes.selected }}
            disabled={tab === tabs.overtime}
          >
            Over Time
          </ToggleButton>
        </ToggleButtonGroup>
        {tab === tabs.overview && this.renderOverviewGraphView()}
        {tab === tabs.overtime && this.renderOvertimeGraphView()}
      </div>
    );
  };

  renderEmptyView = () => {
    const { classes } = this.props;
    return (
      <div className={classes.emptyView}>
        <Typography className={classes.emptyText}>
          No trackers detected in meetings.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleGoToTrackers}
        >
          ADD TRACKERS
        </Button>
      </div>
    );
  };

  render() {
    const { classes, trackers } = this.props;
    const { tracker } = this.state;
    return (
      <div>
        <header className={classes.sectionHeader}>TRACKERS</header>
        {!trackers || trackers.length === 0
          ? this.renderEmptyView()
          : tracker
          ? this.renderTrackerView()
          : this.renderGraphView()}
      </div>
    );
  }
}

export default withStyles(styles)(Trackers);
