import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';
import { TextField, MenuItem } from '@material-ui/core';

// Shared helpers
import { speechTypes } from 'helpers';

// Shared components
import {
    Portlet,
    PortletHeader,
    PortletLabel,
    PortletContent
  } from 'components';

// Component styles
import styles from './styles';

class SpeechDetails extends Component {
  state = {
    title: "",
    type: "",
  }

  componentDidMount() {
    this.setState({
      title: this.props.title, 
      type: this.props.type
    })
  }

  handleTitleChange = (title) => {
    this.setState({title: title})
    this.props.updateTitle(title)
  }

  handleTypeChange = (type) => {
    this.setState({type: type})
    this.props.updateType(type)
  }
  
  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
        <Portlet
          {...rest}
          className={rootClassName}
        >
          <PortletHeader>
            <PortletLabel title="Details" />
          </PortletHeader>
          <PortletContent className={classes.content}>
            <div className={classes.instructions}>
              <Typography
                className={classes.caption}
                variant="body1"
              >
                Use the Sonero AI Coaching Assistant to help you run effective remote meetings.<br/><br/>
                For accurate results, please ensure that your face is clearly visible and there is minimal background noise.<br/>
              </Typography>
            </div>
            <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Recording Title"
                margin="dense"
                onChange={event =>
                  this.handleTitleChange(event.target.value)
                }
                required
                variant="outlined"
                value={this.state.title}
              />
              {this.props.showTitleError && (
                <Typography
                  className={classes.fieldError}
                  variant="body2"
                >
                  This field is required
                </Typography>
              )}
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Recording Type"
                margin="dense"
                onChange={event =>
                  this.handleTypeChange(event.target.value)
                }
                required
                select
                value={this.state.type}
                variant="outlined">
                  {speechTypes.map(option => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
              {this.props.showTypeError && (
                <Typography
                  className={classes.fieldError}
                  variant="body2"
                >
                  This field is required
                </Typography>
              )}
            </div>
          </form>
          </PortletContent>
        </Portlet>
      );
  }
}

SpeechDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SpeechDetails);
