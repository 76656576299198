import React, { Component } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { updateMeetingTasks } from "services/speech";

import styles from "./styles";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class DeleteMeetingTask extends Component {
  state = {
    showError: false,
    isLoading: false,
  };

  componentWillMount() {}

  setError = (message) => {
    this.setState({ showError: true, errorMsg: message });
  };

  handleClose = () => {
    this.setState({
      showError: false,
    });
    this.props.closeHandler();
  };

  handleUpdateTaskSuccess = (response) => {
    this.props.successHandler(response.data.tasks);
    this.handleClose();
  };

  handleUpdateTask = () => {
    this.setState({ isLoading: true, showError: false });
    updateMeetingTasks(this.props.meetingId, this.props.tasks).then(
      function(response) {
        this.setState({ isLoading: false });
        if (response.status !== 200) {
          if (response.status == 401) {
            this.props.history.push("/sign-in");
          }
          this.setError(response.data.message);
          ReactGA.event({
            category: "Failure",
            action: "Update meeting task API failed",
          });
        } else {
          ReactGA.event({
            category: gaCategoryViewMeeting(),
            action: "Update meeting task API success",
          });
          this.handleUpdateTaskSuccess(response);
        }
      }.bind(this)
    );
  };

  renderDialog = () => {
    const { classes, className, ...rest } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Next Step</DialogTitle>
        <DialogContent>
          <Typography
            className={classes.deleteCaption}
            variant="h5"
            align="center"
          >
            Are you sure you want to delete this next step from this meeting?
          </Typography>
          {this.state.showError && (
            <Typography className={classes.fieldError} variant="body2">
              {this.state.errorMsg}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button
              onClick={() => this.handleUpdateTask()}
              color="primary"
              className={classes.deleteButton}
            >
              YES, I'M SURE
            </Button>
          )}
          <Button onClick={() => this.handleClose()} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    return <div>{this.renderDialog()}</div>;
  }
}

DeleteMeetingTask.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteMeetingTask);
