import React, { Component } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { getAPIHost } from "services/speech";
import theme from "theme";

class GetFeedbackDialog extends Component {
  state = {
    isFeedbackEnabled: false,
    isFeedbackStatusLoading: false,
  };

  componentDidMount() {
    const isFeedbackEnabled = this.props.data.is_feedback_enabled;
    this.setState({ isFeedbackEnabled });
  }

  componentDidUpdate() {
    if (this.props.openFeedbackDialog && !this.state.isFeedbackEnabled) {
      this.handleEnableFeedback();
    }
  }

  handleEnableFeedback = () => {
    this.setState({ isFeedbackStatusLoading: true });
    this.props.updateFeedbackStatus(true).then(
      function(response) {
        var isEnabled = this.state.isFeedbackEnabled;
        if (response.status == 200) {
          isEnabled = response.data.is_feedback_enabled;
        }
        this.setState({
          isFeedbackEnabled: isEnabled,
          isFeedbackStatusLoading: false,
        });
      }.bind(this)
    );
  };

  handleGetFeedbackFromParticipants = () => {
    this.props.getFeedbackFromParticipants().then(
      function(response) {
        var isEnabled = this.state.isFeedbackEnabled;
        if (response.status == 200) {
          isEnabled = response.data.is_feedback_enabled;
        }
        this.setState({
          isFeedbackEnabled: isEnabled,
          isFeedbackStatusLoading: false,
        });
      }.bind(this)
    );
    this.props.handleCloseFeedbackDialog();
  };

  renderFeedbackDialogContent = (url) => {
    const { classes } = this.props;
    if (this.state.isFeedbackStatusLoading) {
      return <Typography variant="body1">Loading...</Typography>;
    } else {
      if (this.state.isFeedbackEnabled) {
        return (
          <div>
            <Typography
              variant="body1"
              style={{ marginTop: theme.spacing.unit }}
            >
              We'll send an email to the attendees requesting them to provide
              anonymous feedback on the meeting.
              <br />
              <br />
              They would complete the following questionnaire evaluating the
              effectiveness of the meeting: &nbsp;
              <a
                className={classes.url}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </a>
              <br />
              <br />
              You can also enable the option to automatically request feedback
              for your meetings processed by Sonero in the Settings page.
            </Typography>
          </div>
        );
      } else {
        return (
          <Typography variant="body1">
            Please enable feedback to request feedback from meeting attendees
          </Typography>
        );
      }
    }
  };

  renderEnableFeedbackButtonIfNeeded = () => {
    if (!this.state.isFeedbackEnabled) {
      return (
        <Button onClick={this.handleEnableFeedback} color="primary">
          Enable Feedback
        </Button>
      );
    }
  };

  renderGetFeedbackFromParticipantsButton = () => {
    return (
      <Button
        onClick={() => this.handleGetFeedbackFromParticipants()}
        color="primary"
        variant="outlined"
        size="small"
      >
        Request Feedback
      </Button>
    );
  };

  render = () => {
    var url = `${getAPIHost()}/rate-meeting/${this.props.data.uuid}`;
    return (
      <Dialog
        open={this.props.openFeedbackDialog}
        onClose={this.props.handleCloseFeedbackDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          REQUEST MEETING FEEDBACK
        </DialogTitle>
        <DialogContent>{this.renderFeedbackDialogContent(url)}</DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleCloseFeedbackDialog}
            color="primary"
          >
            Close
          </Button>
          {this.state.isFeedbackStatusLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            this.renderEnableFeedbackButtonIfNeeded()
          )}
          {this.renderGetFeedbackFromParticipantsButton()}
        </DialogActions>
      </Dialog>
    );
  };
}

export default withStyles(styles)(GetFeedbackDialog);
