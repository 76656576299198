export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  firstContent: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
  },
  pieContainer: {
    height: "250px",
    marginTop: theme.spacing.unit * 2,
  },
  selector: {
    fontFamily: "inherit",
    display: "flex",
    width: "100%",
    marginBottom: theme.spacing.unit * 1.5,
    marginTop: theme.spacing.unit * 3,
    justifyContent: "space-between",
    "& > span": {
      display: "inline-flex",
      alignItems: "center",
      marginRight: theme.spacing.unit * 2,
      fontWeight: 700,
      fontSize: "20px",
      "&.badFillers": {
        color: theme.palette.danger.dark,
      },
      "&.goodFillers": {
        color: theme.palette.success.dark,
      },
    },
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginLeft: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  feedback: {
    fontFamily: "inherit",
    marginTop: theme.spacing.unit,
    marginBotton: theme.spacing.unit * 2,
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
  speakerSelect: {
    marginRight: theme.spacing.unit * 4,
  },
});
