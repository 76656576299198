import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {},
  content: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    position: "relative",
  },
  videoPlayerContainer: {
    margin: "auto",
    marginTop: theme.spacing.unit * 2,
    width: "max-content",
  },
  textFieldsContainer: {
    display: "flex",
  },
  textField: {
    width: "100px",
    marginRight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
  },
  headerLabel: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
    height: "30px",
  },
  textFieldDescription: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  timeContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    "@media (max-width: 400px)": {
      display: "block",
    },
  },
  timeLabel: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.secondary,
    paddingBottom: "5px",
    width: "110%",
    textAlign: "center",
    "& > div": {
      width: "max-content",
    },
  },
  editingTimeLabel: {
    color: fade(theme.palette.text.secondary, 0.75),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    marginBottom: "-2px",
    "& > div": {
      borderRight: "1px solid",
      animation: "$blinkingBorder 1.5s step-end infinite",
    },
  },
  "@keyframes blinkingBorder": {
    "0%": {
      borderColor: theme.palette.text.secondary,
    },
    "50%": {
      borderColor: "transparent",
    },
    "100%": {
      borderColor: theme.palette.text.secondary,
    },
  },
  viewBookmarkContainer: {
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(6)}px`,
  },
});
