import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
import { withStyles } from "@material-ui/styles";
import { Typography, Tooltip, Tabs, Tab, IconButton } from "@material-ui/core";

import { GetApp } from "@material-ui/icons";

// Shared components
import { Paper, ProcessingInProgress } from "components";
import { Trackers, DecisionNotes } from "./components";

import { speechStatus, trackerNoteTypes } from "helpers";

// Component styles
import styles from "./styles";

import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import { getSpeakerString } from "helpers/meetingParticipants";
import { timeFormatter } from "helpers/time";
import { pricingTiers, verifyUserPricingTier } from "helpers/pricingTiers";

const tabs = {
  tracker: trackerNoteTypes.tracker,
  decision: trackerNoteTypes.decision,
  note: trackerNoteTypes.note,
};

class TrackerNotes extends Component {
  state = {
    tab: tabs.tracker,
  };

  constructor(props) {
    super(props);
    if (verifyUserPricingTier(pricingTiers.free)) {
      this.state = {
        tab: tabs.decision,
      };
    }
  }

  componentDidMount() {
    if (this.props.scrollToTracker) {
      this.setState({ tab: tabs.tracker });
    } else if (this.props.scrollToDecision) {
      this.setState({ tab: tabs.decision });
    } else if (this.props.scrollToNote) {
      this.setState({ tab: tabs.note });
    }
  }

  getParticipantById = (id, participants) => {
    return participants.find((p) => p.id === id);
  };

  handleChangeTab = (_event, newValue) => {
    this.setState({ tab: newValue });
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User switched to ${newValue} tab in Tracker and Notes`,
    });
  };

  handleDownload = () => {
    const { data } = this.props;
    const participantsDetails = data.participants_details;
    let content = "";
    let filename = "insights.txt";
    if (data.title) {
      filename = data.title + ".insights.txt";
    }

    content += "------ Trackers ------\n\n";
    data.keywords.forEach((keyword) => {
      let chunk = "Keyword: " + keyword.keyword + " (" + keyword.count + ")\n";
      keyword.timestamps.forEach((timestamp) => {
        const transcriptIdx = this.props.data.transcriptionResultsMap[
          timestamp.toString()
        ];
        const speaker = getSpeakerString(
          data.transcript_results[transcriptIdx].speaker_tag,
          participantsDetails
        );
        chunk +=
          "(" +
          timeFormatter(timestamp) +
          ") " +
          speaker +
          ": " +
          data.transcript_results[transcriptIdx].sentence +
          "\n";
      });
      content += chunk + "\n";
    });

    content += "\n------ Decisions ------\n\n";
    data.decisions.forEach((decision) => {
      let chunk = "";
      const transcriptIdx = this.props.data.transcriptionResultsMap[
        decision.timestamp.toString()
      ];
      const speaker = getSpeakerString(
        data.transcript_results[transcriptIdx].speaker_tag,
        participantsDetails
      );
      const owner = this.getParticipantById(
        decision.owner_participant_id,
        participantsDetails
      );
      chunk +=
        "(" +
        timeFormatter(decision.timestamp) +
        ") " +
        speaker +
        ": " +
        decision.description;
      if (owner) {
        chunk +=
          " (" +
          this.getParticipantById(
            decision.owner_participant_id,
            participantsDetails
          ).name +
          ")";
      } else {
        chunk += " (Needs Owner)";
      }
      content += chunk + "\n";
    });
    content += "\n";

    content += "\n------ Notes ------\n\n";
    data.notes.forEach((note) => {
      let chunk = "";
      const transcriptIdx = this.props.data.transcriptionResultsMap[
        note.timestamp.toString()
      ];
      const speaker = getSpeakerString(
        data.transcript_results[transcriptIdx].speaker_tag,
        participantsDetails
      );
      const owner = this.getParticipantById(
        note.owner_participant_id,
        participantsDetails
      );
      chunk +=
        "(" +
        timeFormatter(note.timestamp) +
        ") " +
        speaker +
        ": " +
        note.description;
      if (owner) {
        chunk +=
          " (" +
          this.getParticipantById(
            note.owner_participant_id,
            participantsDetails
          ).name +
          ")";
      } else {
        chunk += " (Needs Owner)";
      }
      content += chunk + "\n";
    });

    const pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(content)
    );
    pom.setAttribute("download", filename);
    if (document.createEvent) {
      const event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: "User downloaded Trackers and Notes",
    });
  };

  renderTrackers() {
    const { tab } = this.state;
    return (
      <Trackers
        tab={tab}
        data={this.props.data}
        handleInsightOnClick={this.props.handleInsightOnClick}
        keywords={this.props.keywords}
        scrollToTracker={this.props.scrollToTracker}
      />
    );
  }

  renderDecisions() {
    const { tab } = this.state;
    return (
      <DecisionNotes
        tab={tab}
        data={this.props.data.decisions}
        type={trackerNoteTypes.decision}
        participantsDetails={this.props.data.participants_details}
        handleInsightOnClick={this.props.handleInsightOnClick}
        scrollToElement={this.props.scrollToDecision}
        updateDecisionNote={this.props.updateDecisionNote}
        deleteDecisionNote={this.props.deleteDecisionNote}
        assignDecisionNoteOwner={this.props.assignDecisionNoteOwner}
      />
    );
  }

  renderNotes() {
    const { tab } = this.state;
    return (
      <DecisionNotes
        tab={tab}
        data={this.props.data.notes}
        type={trackerNoteTypes.note}
        participantsDetails={this.props.data.participants_details}
        handleInsightOnClick={this.props.handleInsightOnClick}
        scrollToElement={this.props.scrollToNote}
        updateDecisionNote={this.props.updateDecisionNote}
        deleteDecisionNote={this.props.deleteDecisionNote}
        assignDecisionNoteOwner={this.props.assignDecisionNoteOwner}
      />
    );
  }

  render() {
    const { classes, className, meetingStatus, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);
    const { tab } = this.state;

    return (
      <div id="trackerNotes" className={classes.container}>
        <Paper {...rest} className={rootClassName}>
          <header className={classes.header}>
            <Typography className={classes.headerText}>
              TRACKERS & NOTES
            </Typography>
            <Tooltip title="Download Insights">
              <IconButton onClick={this.handleDownload}>
                <GetApp className={classes.downloadIcon} />
              </IconButton>
            </Tooltip>
          </header>

          <Tabs
            value={tab}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
          >
            {!verifyUserPricingTier(pricingTiers.free) && (
              <Tab
                className={classes.tab}
                label="TRACKERS"
                value={tabs.tracker}
              ></Tab>
            )}
            <Tab
              className={classes.tab}
              label="DECISIONS"
              value={tabs.decision}
            ></Tab>
            <Tab className={classes.tab} label="NOTES" value={tabs.note}></Tab>
          </Tabs>
          {meetingStatus === speechStatus.completed && (
            <>
              {tab === tabs.tracker && this.renderTrackers()}
              {tab === tabs.decision && this.renderDecisions()}
              {tab === tabs.note && this.renderNotes()}
            </>
          )}
          {meetingStatus === speechStatus.processing && (
            <ProcessingInProgress copy="Processing Insights..." />
          )}
        </Paper>
      </div>
    );
  }
}

TrackerNotes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrackerNotes);
