import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { IconButton, Typography, Link } from "@material-ui/core";

// Material icons
import {
  AddCircle as AddIcon,
  AssignmentTurnedIn as SpellcheckIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

// Shared components
import { Paper } from "components";
import { timeFormatter } from "../../../../helpers/time";

import { AddMeetingTask } from "views/ViewMeeting/components";
import { DeleteMeetingTask } from "views/ViewMeeting/components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

// Material TreeView
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { gaCategoryViewMeeting } from "helpers/gaUtil";

class MeetingTasks extends Component {
  state = {
    addSubtaskPos: -1,
    caption: "",
    tasks: [],
    openAddTaskDialog: false,
    openDeleteTaskDialog: false,
    updateTasks: [],
    hoveredNodeId: "",
  };

  componentWillMount() {
    this.setState({
      tasks: this.props.data.tasks,
    });
  }

  addTasks = () => {
    const { classes, className, ...rest } = this.props;

    if (
      this.state.tasks == null ||
      this.state.tasks.length == null ||
      this.state.tasks.length == 0
    ) {
      return this.addEmptyState();
    }
    this.moveGeneralTaskToEnd();
    return (
      <TreeView
        className={classes.treeRoot}
        defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
        defaultExpandIcon={<ChevronRightIcon color="primary" />}
        defaultExpanded={this.state.tasks.map((_, index) => {
          return index;
        })}
        disableSelection={true}
      >
        {this.state.tasks.map((e) => {
          return this.addTask(
            e.action_item,
            e.relevant_phrases,
            this.state.tasks.indexOf(e)
          );
        })}
      </TreeView>
    );
  };

  addTask = (actionItem, phrases, taskPos) => {
    const { classes, className, ...rest } = this.props;
    return (
      <TreeItem
        nodeId={taskPos}
        onLabelClick={(event) => {
          event.preventDefault();
        }}
        onMouseEnter={() =>
          this.setState({ hoveredNodeId: taskPos + ", " + "-1" })
        }
        onMouseLeave={() => this.setState({ hoveredNodeId: "" })}
        label={
          <div className={classes.taskWrapper}>
            <div className={classes.taskTitle}>
              <Typography className={classes.actionItem} variant="h5">
                {this.setTaskName(actionItem)}
              </Typography>
              {this.props.data.is_participant_editing_enabled && (
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    this.addNewPhrase(taskPos);
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
              )}
            </div>
            {this.props.data.is_participant_editing_enabled &&
              this.state.hoveredNodeId === taskPos + ", " + -1 && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => this.deleteTask(taskPos)}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              )}
          </div>
        }
      >
        {phrases &&
          phrases.map((e) => {
            return this.addPhrase(
              e.phrase,
              e.timestamp,
              taskPos,
              phrases.indexOf(e)
            );
          })}
      </TreeItem>
    );
  };

  setTaskName = (actionItem) => {
    if (actionItem == "General next steps") {
      if (this.state.tasks.length == 1) {
        return "Potential action items";
      }
      return "Other potential action items";
    }
    return actionItem;
  };

  deleteTask = (taskPos) => {
    const tasks = JSON.parse(JSON.stringify(this.state.tasks));
    if (taskPos !== -1) {
      tasks.splice(taskPos, 1);
      this.setState({
        openDeleteTaskDialog: true,
        updateTasks: tasks,
      });
    }
  };

  addPhrase = (phrase, timestamp, taskPos, phrasePos) => {
    const { classes, className, ...rest } = this.props;
    return (
      <TreeItem
        nodeId={taskPos + "," + phrasePos}
        onMouseEnter={() =>
          this.setState({ hoveredNodeId: taskPos + ", " + phrasePos })
        }
        onMouseLeave={() =>
          this.setState({ hoveredNodeId: taskPos + ", " + "-1" })
        }
        onLabelClick={() => {
          if (timestamp) {
            this.handleTimestampClicked(timestamp);
          }
        }}
        label={
          <Typography className={classes.phraseText} variant="body1">
            <a>{phrase}</a>
            <a>{this.setTimestampLink(timestamp)}</a>
            {this.props.data.is_participant_editing_enabled &&
              this.state.hoveredNodeId === taskPos + ", " + phrasePos && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={classes.phraseDeleteButton}
                  onClick={() => this.deletePhrase(taskPos, phrasePos)}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              )}
          </Typography>
        }
      />
    );
  };

  addNewPhrase = (taskPos) => {
    const tasks = JSON.parse(JSON.stringify(this.state.tasks));
    if (taskPos !== -1) {
      this.setState({
        openAddTaskDialog: true,
        updateTasks: tasks,
        addSubtaskPos: taskPos,
      });
    }
  };

  deletePhrase = (taskPos, phrasePos) => {
    const tasks = JSON.parse(JSON.stringify(this.state.tasks));
    if (taskPos !== -1 && phrasePos !== -1) {
      tasks[taskPos].relevant_phrases.splice(phrasePos, 1);
      this.setState({
        openDeleteTaskDialog: true,
        updateTasks: tasks,
      });
    }
  };

  addNewTask = () => {
    const tasks = JSON.parse(JSON.stringify(this.state.tasks));
    this.setState({
      openAddTaskDialog: true,
      updateTasks: tasks,
      addSubtaskPos: -1,
    });
  };

  renderAddTaskDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <AddMeetingTask
        open={this.state.openAddTaskDialog}
        closeHandler={this.handleCloseAddTask}
        tasks={this.state.updateTasks}
        meetingId={this.props.data.meeting_id}
        successHandler={this.handleAddTaskSuccess}
        taskPos={this.state.addSubtaskPos}
      />
    );
  };

  renderDeleteTaskDialog = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <DeleteMeetingTask
        open={this.state.openDeleteTaskDialog}
        closeHandler={this.handleCloseDeleteTask}
        tasks={this.state.updateTasks}
        meetingId={this.props.data.meeting_id}
        successHandler={this.handleDeleteTaskSuccess}
      />
    );
  };

  handleCloseAddTask = () => {
    this.setState({
      addSubtaskPos: -1,
      openAddTaskDialog: false,
      updateTasks: [],
    });
  };

  handleCloseDeleteTask = () => {
    this.setState({
      openDeleteTaskDialog: false,
      updateTasks: [],
    });
  };

  handleAddTaskSuccess = (tasks) => {
    this.props.taskUpdateResponseHandler(tasks);
    this.setState({
      addSubtaskPos: -1,
      tasks: tasks,
    });
  };

  handleDeleteTaskSuccess = (tasks) => {
    this.props.taskUpdateResponseHandler(tasks);
    this.setState({
      tasks: tasks,
    });
  };

  handleTimestampClicked = (timestamp) => {
    this.props.setMediaPlayerCurrentTimeAndPlay(timestamp);
    ReactGA.event({
      category: gaCategoryViewMeeting(),
      action: "User clicked timestamp",
      label: "Next Steps",
    });
  };

  setTimestampLink = (timestamp) => {
    const { classes, className, ...rest } = this.props;
    if (timestamp === undefined) return;
    return (
      <Link
        component="button"
        className={classes.phraseTime}
        onClick={() => this.handleTimestampClicked(timestamp)}
      >
        ({timeFormatter(timestamp)})
      </Link>
    );
  };

  getGeneralTaskIndexIfExists = () => {
    for (var i = 0; i < this.state.tasks.length; i++) {
      if (this.state.tasks[i].action_item == "General next steps") {
        return i;
      }
    }
    return null;
  };

  moveGeneralTaskToEnd = () => {
    var index = this.getGeneralTaskIndexIfExists();
    if (index != null) {
      var generalTask = this.state.tasks.splice(index, 1)[0];
      this.state.tasks.push(generalTask);
    }
  };

  addEmptyState = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <div>
        <Typography className={classes.phrase} variant="body1">
          It didn't seem like there were any next steps or action items from the
          meeting!
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.content}>
          <div className={classes.iconWrapper}>
            <SpellcheckIcon className={classes.icon} />
          </div>
          <div className={classes.taskTitle}>
            <div className={classes.details}>
              <Typography className={classes.title} variant="h5">
                NEXT STEPS
              </Typography>
            </div>

            {this.props.data.is_participant_editing_enabled && (
              <IconButton
                aria-label="add"
                onClick={() => {
                  this.addNewTask();
                }}
              >
                <AddIcon color="primary" />
              </IconButton>
            )}
          </div>
        </div>
        {this.addTasks()}
        {this.renderAddTaskDialog()}
        {this.renderDeleteTaskDialog()}
      </Paper>
    );
  }
}

MeetingTasks.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MeetingTasks);
