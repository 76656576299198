import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import {
    SentimentVerySatisfied as SentimentVerySatisfiedIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class ContentSentiment extends Component {
  state = {
    result: "",
    caption: "",
    valueClassName: ""
  }

  componentWillMount() {
    const polarity = this.props.data.sentiment_polarity;
    const classes = this.props.classes

    if (polarity > 0 && polarity <= 0.5) {
        this.setState({
            result: "MODERATELY POSITIVE",
            caption: `Most of your content in the recording was positive. This is very good! Communicating positively can elicit a positive response from your audience and help create a better impression of yourself. Great job!`,
            valueClassName: classes.valueGood
        })
    } else if (polarity > 0.5 && polarity <= 1) {
        this.setState({
            result: "VERY POSITIVE",
            caption: `Most of your content in the recording was positive. This is very good! Communicating positively can elicit a positive response from your audience and help create a better impression of yourself. Great job!`,
            valueClassName: classes.valueGood
        })
    } else if (polarity < 0 && polarity >= -0.5) {
        this.setState({
            result: "MODERATELY NEGATIVE",
            caption: `Your content in the recording seemed quite negative. Avoiding negative content can help you convey a positive outlook and attitude to your audience. This in turn will help you elicit a positive response from them and create a better impression of yourself.`,
            valueClassName: classes.valueBad
        })
    } else if (polarity < -0.5 && polarity >= -1) {
        this.setState({
            result: "VERY NEGATIVE",
            caption: `Your content in the recording seemed very negative. Avoiding negative content can help you convey a positive outlook and attitude to your audience. This in turn will help you elicit a positive response from them and create a better impression of yourself.`,
            valueClassName: classes.valueBad
        })
    } else {
        this.setState({
            result: "NEUTRAL",
            caption: `Your content was neither particularly positive or negative. Try to use a few more positive words and elements in your content to help you project a positive outlook to your audience.`,
            valueClassName: classes.valueGood
        })
    }
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h6"
            >
              CONTENT SENTIMENT
            </Typography>
            <Typography
              className={this.state.valueClassName}
              variant="h4"
            >
              {this.state.result}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <SentimentVerySatisfiedIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.footer}>
          <Typography
            className={classes.caption}
            variant="body1"
          >
            {this.state.caption}
          </Typography>
        </div>
      </Paper>
    );
  }
}

ContentSentiment.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContentSentiment);
