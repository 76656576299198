export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  refreshButton: {
    margin: -theme.spacing.unit * 2,
  },
  chartWrapper: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing.unit,
  },
  deviceIcon: {
    color: theme.palette.common.neutral,
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit,
    fontWeight: 700,
  },
  value: {
    marginTop: theme.spacing.unit,
    color: theme.palette.success.dark,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "2rem",
    justifyContent: "center",
    width: "2rem",
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  footer: {
    alignItems: "center",
  },
  difference: {
    alignItems: "center",
    color: theme.palette.danger.dark,
    display: "inline-flex",
    fontWeight: 700,
  },
  treeRoot: {
    marginTop: theme.spacing.unit * 4,
  },
  actionItem: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  phraseText: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  phrase: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
    alignItems: "center",
  },
  phraseDeleteButton: {
    marginLeft: "auto",
    height: theme.spacing.unit * 2.5,
    width: theme.spacing.unit * 2.5,
    marginLeft: theme.spacing.unit,
  },
  phraseTime: {
    marginLeft: "5px",
  },
  taskTitle: {
    display: "flex",
    alignItems: "center",
  },
  taskWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
