import React, { Component } from "react";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/styles";
import {
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem,
  Box,
  TextareaAutosize,
} from "@material-ui/core";

// Component styles
import styles from "./styles";
import {
  Delete,
  MoreVert,
  CheckCircle,
  Cancel,
  Info,
} from "@material-ui/icons";

import { timeFormatter } from "helpers/time";
import { gaCategoryViewMeeting, logGAEvent } from "helpers/gaUtil";
import theme from "theme";
import Highlighter from "react-highlight-words";

class DecisionNotes extends Component {
  state = {
    showScrollTip: true,
    autoScrolled: false,
    elements: [],
    participants: [],
    isSavingElements: {},
    menuAnchorEl: null,
    optionsMenuAnchorEl: null,
    elementOverflowClicked: null,
    elementOwnerIdxOverflowClicked: "",
    isEditing: [],
    disableRipple: [],
    editingTask: {},
  };
  containerRef = React.createRef();
  elementRefs = {};
  hoverStart = new Date();

  componentWillMount() {
    const participants = this.props.participantsDetails;
    this.setState({
      participants: participants,
      elements: this.props.data,
    });
  }

  componentDidMount() {
    this.setState({ showScrollTip: this.hasMoreContent() });
    this.handleAutoScroll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tab !== this.props.tab) {
      this.handleResetScroll();
      this.setState({ showScrollTip: this.hasMoreContent() });
      this.handleAutoScroll();
    }
    if (this.props.data !== this.state.elements) {
      this.setState({ elements: this.props.data });
    }
  }

  handleResetScroll = () => {
    if (this.containerRef.current) {
      this.containerRef.current.scrollTop = 0;
    }
  };

  handleAutoScroll = () => {
    if (!this.state.autoScrolled && this.props.scrollToElement) {
      this.handleScrollElementIntoView();
    }
  };

  hasMoreContent = () => {
    if (this.containerRef.current) {
      return (
        this.containerRef.current.scrollHeight >
        this.containerRef.current.clientHeight
      );
    }
    return false;
  };

  atEndOfScroll = () => {
    const container = this.containerRef.current;
    if (container) {
      const { clientHeight, scrollTop, scrollHeight } = container;
      if (clientHeight + scrollTop + 1 >= scrollHeight) {
        this.setState({ showScrollTip: false });
      } else {
        this.setState({ showScrollTip: true });
      }
    }
  };

  handleOnMouseEnter = () => {
    this.hoverStart = new Date();
  };

  handleOnMouseLeave = (type) => {
    let timeSpent = new Date() - this.hoverStart;
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User hovered ${type} event in Trackers and Notes`,
      value: timeSpent,
    });
  };

  handleOnMouseClick = () => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User Clicked a ${this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}`,
    });
  };

  handleScrollElementIntoView = () => {
    const elementHashString = this.props.scrollToElement;
    const [elementWord, elementTimestamp] = elementHashString.split("@");
    if (this.elementRefs[elementHashString] && this.elementRefs[elementWord]) {
      this.elementRefs[elementWord].style.backgroundColor =
        theme.palette.warning.light;
      this.elementRefs[elementHashString].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      this.props.handleInsightOnClick(
        { timestamp: +elementTimestamp },
        this.props.type
      );
      this.setState({ autoScrolled: true });
    }
  };

  handleClickElementTimestamp = (timestamp) => {
    this.props.handleInsightOnClick({ timestamp }, this.props.type);
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User clicked on a ${this.props.type} in Trackers and Notes`,
    });
  };

  handleUpdateElement = (element, modifiedDescription) => {
    this.props.updateDecisionNote(element, modifiedDescription).then(
      function(response) {
        if (response.status === 200) {
          let newElements = this.state.elements;
          newElements = newElements.map((item) => {
            if (item.uuid === element.uuid) {
              item.description = response.data.data.description;
            }
            return item;
          });

          this.setState({
            elements: newElements,
            showScrollTip: this.hasMoreContent(),
          });
        }
      }.bind(this)
    );
  };

  handleDeleteElement = (element) => {
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `Delete ${this.props.type}s`,
      label: "Next Steps",
    });
    this.props.deleteDecisionNote(element).then(
      function(response) {
        if (response.status === 200) {
          const deletedElement = response.data.data;
          let newElements = this.state.elements;
          newElements = newElements.filter((item) => item.uuid !== deletedElement.uuid);

          this.setState({
            elements: newElements,
            showScrollTip: this.hasMoreContent(),
          });
        }
      }.bind(this)
    );
  };

  handleElementOwnerChange = (participantId, element) => {
    if (element.owner_participant_id === participantId) {
      return;
    }
    let isSavingElements = { ...this.state.isSavingElements };
    isSavingElements[element.uuid] = true;
    this.setState({
      isSavingElements: isSavingElements,
    });
    element.owner_participant_id = participantId;
    this.props.assignDecisionNoteOwner(element).then(
      function(response) {
        let isSavingElements = { ...this.state.isSavingElements };
        isSavingElements[element.uuid] = false;
        this.setState({
          isSavingElements: isSavingElements,
        });
        if (response.status === 200) {
          var newElements = this.props.data.filter(
            (ai) => ai.uuid !== element.uuid
          );
          newElements.push(element);
          this.setState({
            elements: newElements,
            showScrollTip: this.hasMoreContent(),
          });
        }
      }.bind(this)
    );
  };

  getParticipantById = (id, participants) => {
    return participants.find((p) => p.id === id);
  };

  groupElementsByOwner = (elements, participants) => {
    var newElements = [];
    elements.forEach((element) => {
      var owner = element.owner_participant_id;
      var participant = null;
      if (owner != null) {
        participant = this.getParticipantById(owner, participants);
      }
      var group = newElements.find((e) => e.owner === owner);
      if (group !== undefined) {
        element.participant = participant;
        group.elements.push(element);
      } else {
        newElements.push({
          owner: owner,
          participant: participant,
          elements: [element],
        });
      }
    });
    newElements.sort((first, second) => {
      if (first.participant && second.participant) {
        return first.participant.name.localeCompare(second.participant.name);
      } else if (!first.participant && !second.participant) {
        return 0;
      } else if (!first.participant) {
        return 1;
      } else if (!second.participant) {
        return -1;
      }
    });
    return newElements;
  };

  addEmptyState = () => {
    const { classes } = this.props;
    return (
      <div className={classes.emptyText}>
        <Typography className={classes.phrase} variant="body1">
          There are no {this.props.type}s to display.
        </Typography>
        <Typography className={classes.phrase} variant="body1">
          <Highlighter
            highlightStyle={{
              fontWeight: 600,
              backgroundColor: "transparent",
              color: theme.palette.text.secondary,
            }}
            searchWords={[`( |^)${`"as a ${this.props.type}"`}.*?(,|.|!|\\?| |$)`]}
            caseSensitive={false}
            autoEscape={false}
            textToHighlight={
              `Say "As a ${this.props.type}" to ensure a ${this.props.type} appears here.`
            }
          />
        </Typography>
      </div>
    );
  };

  handleOpenOverflowMenu = (anchorEl, element, owner, idx) => {
    this.setState({
      menuAnchorEl: anchorEl,
      elementOverflowClicked: element,
      elementOwnerIdxOverflowClicked: `${owner}@${idx}`,
    });
  };

  handleCloseOverflowMenu = () => {
    this.setState({
      menuAnchorEl: null,
      elementOverflowClicked: null,
      elementOwnerIdxOverflowClicked: "",
    });
  };

  exitEditElement = (elementOwnerIdx) => {
    let {
      isEditing,
      disableRipple,
      editingTask,
    } = this.state;
    isEditing.pop(elementOwnerIdx);
    disableRipple.pop(elementOwnerIdx);
    delete editingTask[elementOwnerIdx];
    this.setState({
      isEditing: isEditing,
      disableRipple: disableRipple,
      editingTask: editingTask,
    });
  };

  handleConfirmIconClick = (element, elementOwnerIdx) => {
    const newDescription = this.state.editingTask[elementOwnerIdx];
    if (element.description !== newDescription) {
      this.handleUpdateElement(element, newDescription);
    }
    this.exitEditElement(elementOwnerIdx);
  };

  handleCancelIconClick = (elementOwnerIdx) => {
    this.exitEditElement(elementOwnerIdx);
  };

  handleEditClick = (event) => {
    const {
      elementOverflowClicked,
      elementOwnerIdxOverflowClicked,
    } = this.state;
    if (
      elementOverflowClicked === null ||
      elementOwnerIdxOverflowClicked === "" ||
      this.state.isEditing.includes(elementOwnerIdxOverflowClicked) ||
      this.state.disableRipple.includes(elementOwnerIdxOverflowClicked)
    ) {
      event.stopPropagation();
      return;
    }
    logGAEvent({
      category: gaCategoryViewMeeting(),
      action: `User Clicked Modify ${this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}s`,
      label: "Insights",
    });
    let { isEditing, disableRipple, editingTask } = this.state;
    isEditing.push(elementOwnerIdxOverflowClicked);
    disableRipple.push(elementOwnerIdxOverflowClicked);
    editingTask[elementOwnerIdxOverflowClicked] =
      elementOverflowClicked.description;
    this.setState({
      isEditing: isEditing,
      disableRipple: disableRipple,
      editingTask: editingTask,
    });
    this.handleCloseOverflowMenu();
  };

  handleOptionsButtonClick = (event) => {
    event.stopPropagation();
    this.setState({
      optionsMenuAnchorEl: event.currentTarget,
    });
  };

  handleCloseOptionsMenu = () => {
    this.setState({ optionsMenuAnchorEl: null });
  };

  handleElementSelectionChange = (participantId) => {
    this.handleElementOwnerChange(
      participantId,
      this.state.elementOverflowClicked
    );
    this.handleCloseOptionsMenu();
    this.handleCloseOverflowMenu();
  };

  renderOwnerMenu = () => {
    return (
      <Menu
        anchorEl={this.state.optionsMenuAnchorEl}
        open={Boolean(this.state.optionsMenuAnchorEl)}
        onClose={this.handleCloseOptionsMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          value={null}
          onClick={() => {
            this.handleElementSelectionChange(null);
          }}
        >
          Assign Owner
        </MenuItem>
        {this.state.participants.map((participant) => {
          return (
            <MenuItem
              value={participant.id}
              onClick={() => {
                this.handleElementSelectionChange(participant.id);
              }}
            >
              {participant.name}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  renderOverflowMenu = () => {
    return (
      <Menu
        anchorEl={this.state.menuAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(this.state.menuAnchorEl)}
        onClose={this.handleCloseOverflowMenu}
      >
        <MenuItem onClick={this.handleEditClick}>Edit Description</MenuItem>
        <MenuItem onClick={this.handleOptionsButtonClick}>
          Change Owner
        </MenuItem>
      </Menu>
    );
  };

  addElementsByUser = (elementsByUser) => {
    const { classes } = this.props;
    var user = this.getParticipantById(
      elementsByUser.owner,
      this.state.participants
    );
    var elements = elementsByUser.elements;
    return (
      <>
        <div
          className={classes.elementSection}
          ref={(instance) => {
            this.elementRefs[elementsByUser.owner] = instance;
          }}
        >
          <ListItem button className={classes.elementOwner}>
            <ListItemText
              classes={{ primary: classes.elementText }}
              primary={user ? user.name : "Needs Owner"}
            />
          </ListItem>
          <div className={classes.elementContent}>
            {elements.map((element, idx) => {
              return (
                <>
                  {this.state.isEditing.includes(
                    `${elementsByUser.owner}@${idx}`
                  ) ? (
                    <ListItem className={classes.elementInstance}>
                      <Box className={classes.textAreaInset}>
                        <TextareaAutosize
                          className={classes.elementEditingTask}
                          value={
                            this.state.editingTask[
                              `${elementsByUser.owner}@${idx}`
                            ]
                          }
                          onChange={(event) => {
                            var { editingTask } = this.state;
                            editingTask[`${elementsByUser.owner}@${idx}`] =
                              event.target.value;
                            this.setState({ editingTask: editingTask });
                          }}
                          autoFocus
                        />
                      </Box>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() =>
                          this.handleConfirmIconClick(
                            element,
                            `${elementsByUser.owner}@${idx}`
                          )
                        }
                      >
                        <CheckCircle color="primary" />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() =>
                          this.handleCancelIconClick(
                            `${elementsByUser.owner}@${idx}`
                          )
                        }
                      >
                        <Cancel color="primary" />
                      </IconButton>
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      inset
                      disableRipple={this.state.disableRipple.includes(
                        `${elementsByUser.owner}@${idx}`
                      )}
                      ref={(instance) => {
                        this.elementRefs[
                          `${elementsByUser.owner}@${element.timestamp}`
                        ] = instance;
                      }}
                      className={classes.elementInstance}
                      onClick={() =>
                        this.handleClickElementTimestamp(element.timestamp)
                      }
                    >
                      <div className={classes.elementInfo}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography className={classes.elementTimestamp}>
                              {timeFormatter(element.timestamp)}
                            </Typography>
                          }
                        />
                        <ListItemText
                          className={classes.elementSentence}
                          primary={
                            <Highlighter
                              highlightStyle={{
                                fontWeight: 600,
                                backgroundColor: "transparent",
                              }}
                              searchWords={[
                                `( |^)${`as a ${this.props.type}`}.*?(,|.|!|\\?| |$)`,
                              ]}
                              caseSensitive={false}
                              autoEscape={false}
                              textToHighlight={element.description}
                            />
                          }
                        />
                      </div>
                      <div className={classes.elementButtons}>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              this.handleDeleteElement(element);
                            }}
                          >
                            <Delete className={classes.deleteIcon} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="More Settings">
                          <IconButton
                            className={classes.overflowButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.handleOpenOverflowMenu(
                                event.currentTarget,
                                element,
                                elementsByUser.owner,
                                idx
                              );
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </ListItem>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  renderElements = () => {
    const { classes } = this.props;

    let groupedElements = this.groupElementsByOwner(
      this.state.elements,
      this.state.participants
    );

    if (groupedElements.length === 0) {
      return this.addEmptyState();
    }
    return (
      <>
        <div className={classes.elementTipsAndShare}>
          <div className={classes.elementTips}>
            <Info className={classes.elementInfoIcon} />
            <Typography className={classes.elementTipsText}>
              Say "As a {this.props.type}" to ensure a {this.props.type} appears here
            </Typography>
          </div>
        </div>
        <List
          onClick={this.handleOnMouseClick}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={() => this.handleOnMouseLeave(this.props.type)}
        >
          {groupedElements.map((e) => this.addElementsByUser(e))}
        </List>
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          className={classes.elementMainContent}
          ref={this.containerRef}
          onScroll={this.atEndOfScroll}
        >
          {this.renderElements()}
        </div>
        {this.renderOverflowMenu()}
        {this.renderOwnerMenu()}
        {this.state.showScrollTip && (
          <Typography className={classes.scrollTip}>Scroll for more</Typography>
        )}
      </>
    );
  }
};

DecisionNotes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DecisionNotes);