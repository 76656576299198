export default (theme) => ({
  root: {},
  container: {
    height: "20px",
    width: "100%",
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "3px",
    position: "relative",
  },
  metrics: {
    position: "absolute",
    top: "0%",
    width: "10%",
    height: "100%",
    borderRight: `1px solid ${theme.palette.border}`,
    zIndex: 1,
    "&.one": {
      left: 0,
    },
    "&.two": {
      left: "10%",
    },
    "&.three": {
      left: "20%",
    },
    "&.four": {
      left: "30%",
    },
    "&.five": {
      left: "40%",
    },
    "&.six": {
      left: "50%",
    },
    "&.seven": {
      left: "60%",
    },
    "&.eight": {
      left: "70%",
    },
    "&.nine": {
      left: "80%",
    },
  },
  bar: {
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "3px",
    "&.fill-animation": {
      transformOrigin: "left",
      animation: "$fill 1s cubic-bezier(.53,0,.41,1.14)",
    },
  },
  "@keyframes fill": {
    "0%": {
      transform: "scaleX(0)",
    },
    "100%": {
      transform: "scaleX(1)",
    },
  },
});
