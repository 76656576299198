import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material components
import { Button, Fade, Typography, withStyles } from "@material-ui/core";

import {
  EmojiObjects as EmojiObjectsIcon,
  ThumbUp as ThumbsUpIcon,
  ThumbDown as ThumbsDownIcon,
} from "@material-ui/icons";

// Component styles
import styles from "./styles";

class ProTipAndFeedback extends Component {
  state = {
    showModal: false,
  };

  render() {
    const { classes, className, proTipText, ...rest } = this.props;

    return (
      <div className={classes.proTipAndFeedbackContainer}>
        {this.props.isShowProTip && (
          <div className={classes.actionItemTip}>
            <EmojiObjectsIcon className={classes.tipIcon} />
            <Typography variant="body1" className={classes.tipText}>
              <strong>Pro tip: </strong>
              {proTipText}{" "}
              {this.props.isShowFeedback && (
                <button
                  onClick={this.props.handleClickToggleFeedbackModal}
                  className={classes.buttonTogglePopper}
                >
                  Was this useful?
                </button>
              )}
            </Typography>
          </div>
        )}
        {this.props.isShowProTip &&
          this.props.isShowFeedback &&
          this.props.isShowModal && (
            <Fade
              className={classes.fade}
              in={true}
              timeout={{
                enter: 350,
                exit: 350,
              }}
            >
              <div>
                {/* Above div needed otherwise issues are caused by fade */}
                <div className={classes.upArrow}></div>
                <div className={classes.indicatorProTip}>
                  <div className={classes.indicatorHeading}>
                    Was this tip useful?
                  </div>
                  <div className={classes.iconContainer}>
                    <Button
                      variant="contained"
                      startIcon={<ThumbsUpIcon className={classes.thumbs} />}
                      className={classes.indicatiorProTipIcon}
                      onClick={this.props.handleClickFeedbackButtonYes}
                    >
                      <Typography
                        className={classes.indicatorText}
                        variant="h6"
                      >
                        Yes
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<ThumbsDownIcon className={classes.thumbs} />}
                      className={classes.indicatiorProTipIcon}
                      onClick={this.props.handleClickFeedbackButtonNo}
                    >
                      <Typography
                        className={classes.indicatorText}
                        variant="h6"
                      >
                        No
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          )}
      </div>
    );
  }
}

ProTipAndFeedback.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProTipAndFeedback);
