export const shareAccess = {
  restricted: "restricted",
  attendees: "attendees",
  team: "team",
  attendeesAndTeam: "attendees_and_team",
  anyone: "anyone",
  anyoneAndTeam: "anyone_and_team",
};

export const shareAccessCopies = {
  [shareAccess.restricted]: {
    label: "Restricted",
    description: "Only people with access can view this meeting",
  },
  [shareAccess.attendees]: {
    label: "Attendees",
    description: "Meeting attendees can view this meeting",
  },
  [shareAccess.team]: {
    label: "Team",
    description: "Your team members can view this meeting",
  },
  [shareAccess.attendeesAndTeam]: {
    label: "Team and Attendees",
    description: "Your team members and meeting attendees can view this meeting",
  },
  [shareAccess.anyone]: {
    label: "Anyone with link",
    description: "Anyone with the link can view this meeting",
  },
  [shareAccess.anyoneAndTeam]: {
    label: "Team and Anyone with link",
    description: "Your team members and anyone with the link can view this meeting",
  },
};

export const shareAccessTooltip = {
  [shareAccess.restricted]: "shared with only people you invite",
  [shareAccess.attendees]: "shared with the meeting attendees",
  [shareAccess.team]: "shared with your team members",
  [shareAccess.attendeesAndTeam]: "shared with your team members and meeting attndees",
  [shareAccess.anyone]: "shared with anyone with the link",
  [shareAccess.anyoneAndTeam]: "shared with your team members and anyone with the link",
};