import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';
import { InputBase, IconButton, Card, Box, Menu, MenuItem } from '@material-ui/core';

import MoreHoriz from '@material-ui/icons/MoreHoriz';

import AwesomeDebouncePromise from 'awesome-debounce-promise';

// Component styles
import styles from './styles';

class AgendaItem extends Component {

    searchAPIDebounced = AwesomeDebouncePromise(this.props.updateAgendaItem, 750);

    state = {
        optionsMenuAnchorEl: null,
        topic: '',
        description: '',
        author: '',
        item: {}
    };

    componentDidMount = () => {
        this.setState({
            topic: this.props.topic,
            description: this.props.description,
            author: this.props.author !== "" ? this.props.author : 'Anonymous',
            item: this.props.item
        })
        if (this.props.shouldFocus) {
            this.nameInput.focus();
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.item.uuid !== prevProps.item.uuid) {
            this.setState({
                topic: this.props.topic,
                description: this.props.description,
                author: this.props.author !== "" ? this.props.author : 'Anonymous',
                item: this.props.item
            })
        }
    };

    handleOptionsButtonClick = (event) => {
        this.setState({ optionsMenuAnchorEl: event.currentTarget });
    };

    handleCloseOptionsMenu = () => {
        this.setState({ optionsMenuAnchorEl: null });
    };    

    handleDelete = () => {
        this.props.deleteAgendaItem(this.props.item)
        this.handleCloseOptionsMenu()
    };

    handleCreate = () => {
        this.props.createAgendaItem()
    };

    handleTopicUpdate = async text => {
        var item = this.state.item;
        item.topic = text;
        this.setState({
            topic: text,
            item: item
        });
        const result = await this.searchAPIDebounced(item);
    };

    handleDescriptionUpdate = async text => {
        var item = this.state.item;
        item.notes = text;
        this.setState({
            description: text,
            item: item
        });
        const result = await this.searchAPIDebounced(item);
    };

    displayChangesSaved = () => {
        // wait 250ms before displaying message to prevent user from immediately clicking the snackbar away 
        setTimeout(() => this.props.openSnackbar("Changes saved."), 250);
    };

    renderOptionsMenu = () => {
    const { classes } = this.props;
    return (
            <Menu
            anchorEl={this.state.optionsMenuAnchorEl}
            keepMounted
            open={Boolean(this.state.optionsMenuAnchorEl)}
            onClose={this.handleCloseOptionsMenu}
            >
            <MenuItem
                className={classes.deleteMeetingOption}
                onClick={this.handleDelete}
            >
                Delete topic
            </MenuItem>
            </Menu>
        );
    };

    render() {
        const { classes, className, ...rest } = this.props;
    
        const rootClassName = classNames(classes.root, className);

        return (
            <Card
            {...rest}
            className={rootClassName + (this.props.isDragging ? ` ${classes.dragging}` : "")}
            elevation={0}
            >
                <Box className={classes.box} flexGrow={1}>
                    <Typography className={classes.author}>{this.state.author}</Typography>
                    <InputBase 
                        multiline 
                        inputRef={(input) => { this.nameInput = input; }}
                        className={classes.topic} 
                        placeholder="What is this topic about?" 
                        value={this.state.topic} 
                        inputProps={{ 'aria-label': 'naked' }}
                        onChange={(event) => this.handleTopicUpdate(event.target.value)}
                        onBlur={this.displayChangesSaved}
                    />
                    <InputBase 
                        multiline 
                        className={classes.description} 
                        placeholder="Additional notes" 
                        value={this.state.description} 
                        inputProps={{ 'aria-label': 'naked' }}
                        onChange={(event) => this.handleDescriptionUpdate(event.target.value)}
                        onBlur={this.displayChangesSaved}
                    />
                </Box>
                <Box>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={this.handleOptionsButtonClick}>
                        <MoreHoriz />
                    </IconButton>
                </Box>
                {this.renderOptionsMenu()}
            </Card>
        );
    }
};

AgendaItem.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AgendaItem);