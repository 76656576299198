import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Views
import SignUp from "./views/SignUp";
import SignIn from "./views/SignIn";
import UnderDevelopment from "./views/UnderDevelopment";
import NotFound from "./views/NotFound";
import NewSpeech from "./views/NewSpeech";
import MySpeeches from "./views/MySpeeches";
import ViewSpeech from "./views/ViewSpeech";
import Billing from "./views/Billing";
import EmailVerified from "./views/EmailVerified";
import MyMeetings from "./views/MyMeetings";
import ViewMeeting from "./views/ViewMeeting";
import NewMeeting from "./views/NewMeeting";
import ViewMeetingShare from "./views/ViewMeetingShare";
import Integrations from "./views/Integrations";
import UpdateMeeting from "./views/UpdateMeeting";
import Account from "./views/Account";
import Search from "./views/Search";
import RateMeeting from "./views/RateMeeting";
import Settings from "./views/Settings";
import ResetPassword from "./views/ResetPassword";
import EnrollSpeaker from "./views/EnrollSpeaker";
import Setup from "./views/Setup";
import ViewSharedFolder from "./views/ViewSharedFolder";
import Dashboard from "./views/Dashboard";
import ViewSharedClip from "./views/ViewSharedClip";
import ConfirmShared from "./views/ConfirmShared";
import RequestShare from "./views/RequestShare";
import ClientAccounts from "./views/ClientAccounts";
import Trackers from "./views/Trackers";
import TeamDashboard from "./views/TeamDashboard";
import DoNotJoin from "./views/DoNotJoin";
import UnsubscribeFromEmails from "./views/UnsubscribeFromEmails";

// Shared services
import { isUserLoggedIn, isSoneroUser } from "services/speech";

class ProtectedRoute extends Component {
  handleRender = (props) => {
    const { component: Component, location, ...rest } = this.props;
    let redirect = "";
    if (location && location.pathname) {
      redirect =
        "?redirect=" + location.pathname + encodeURIComponent(location.search);
    }

    if (isUserLoggedIn() === true) {
      return <Component {...props} {...rest} />;
    } else {
      return <Redirect to={"/sign-in" + redirect} />;
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={this.handleRender} />;
  }
}

class SoneroOnlyRoute extends Component {
  handleRender = (props) => {
    const { component: Component, ...rest } = this.props;

    if (isSoneroUser()) {
      return <Component {...props} {...rest} />;
    } else {
      return <Redirect to="/" />;
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={this.handleRender} />;
  }
}

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <ProtectedRoute component={Setup} exact path="/setup" />
        <Redirect exact from="/" to="/dashboard" />
        <ProtectedRoute component={NewSpeech} exact path="/new-recording" />
        <ProtectedRoute component={NewMeeting} exact path="/upload-meeting" />
        <ProtectedRoute
          component={MySpeeches}
          exact
          path="/coaching-assistant"
        />
        <ProtectedRoute component={Dashboard} exact path="/dashboard" />
        <ProtectedRoute
          component={TeamDashboard}
          exact
          path="/team-dashboard"
        />
        <ProtectedRoute component={ViewSpeech} exact path="/view-recording" />
        <ProtectedRoute component={MyMeetings} exact path="/my-meetings" />
        <ProtectedRoute component={ClientAccounts} exact path="/accounts" />
        <ProtectedRoute component={Trackers} exact path="/trackers" />

        <ProtectedRoute component={ViewMeeting} exact path="/view-meeting" />
        <ProtectedRoute
          component={ViewMeeting}
          exact
          path="/view-meeting/:uuid"
        />
        <ProtectedRoute
          component={UpdateMeeting}
          exact
          path="/update-meeting"
        />
        <ProtectedRoute
          component={UnderDevelopment}
          exact
          path="/under-development"
        />
        <ProtectedRoute component={Integrations} exact path="/integrations" />
        <ProtectedRoute component={Account} exact path="/account" />
        <ProtectedRoute component={Search} exact path="/search" />
        <ProtectedRoute component={Settings} exact path="/settings" />
        <ProtectedRoute
          component={EnrollSpeaker}
          exact
          path="/enroll-speaker"
        />
        <Route component={SignUp} exact path="/sign-up" />
        <Route component={SignIn} exact path="/sign-in" />
        <Route component={NotFound} exact path="/not-found" />
        <Route component={EmailVerified} exact path="/email-verified" />
        <Route component={ResetPassword} exact path="/reset-password" />
        <Route
          component={ViewMeetingShare}
          exact
          path="/view-meeting/share/:uuid"
        />
        <Route
          component={ViewSharedFolder}
          exact
          path="/view-folder/share/:uuid"
        />
        <Route component={ViewSharedClip} exact path="/view-clip/share/:uuid" />
        <Route component={RateMeeting} exact path="/rate-meeting/:uuid" />
        <Route component={RequestShare} exact path="/request-share/:uuid" />
        <ProtectedRoute
          component={ConfirmShared}
          exact
          path="/confirm-shared/:uuid"
        />
        <Route component={DoNotJoin} exact path="/do-not-join/:uuid" />
        <Route
          component={UnsubscribeFromEmails}
          exact
          path="/unsubscribe-from/emails/:uuid"
        />
        <Redirect to="/not-found" />
      </Switch>
    );
  }
}
