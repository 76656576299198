import React, { Component } from "react";
import MaterialTable from "material-table";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

// Material helpers
import { withStyles } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";

import validate from "validate.js";

import schema from "./schema";

// Material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from "components";

// Component styles
import styles from "./styles";

import { isUserInOrg, isUserOrgOwnerOrAdmin, ROLES } from "helpers/user";

const ROLES_INVERTED = Object.fromEntries(
  Object.entries(ROLES).map((r) => r.reverse())
);

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

class OrgMembersTable extends Component {
  state = {
    openDialog: false,
    newMemberEmail: "",
    isLoading: false,
    searchValue: "",
    openInviteDialog: false,
    isInviteLoading: false,
    openCreatedDialog: false,
    createdEmail: "",
  };
  userEmail = localStorage.getItem("email");

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleCloseInviteDialog = () => {
    this.setState({ openInviteDialog: false });
  };

  handleOpenCreatedDialog = (createdEmail) => {
    this.setState({
      openCreatedDialog: true,
      createdEmail: createdEmail,
    });
  };

  handleCloseCreatedDialog = () => {
    this.props.refreshOrgTable().finally(() => {
      this.setState({ openCreatedDialog: false, createdEmail: "" });
    });
  };

  handleEmailAddressChange = (value) => {
    this.setState({ newMemberEmail: value });
  };

  handleSaveMember = () => {
    const errors = validate({ email: this.state.newMemberEmail }, schema);
    if (errors) {
      return;
    } else {
      this.setState({ isLoading: true });
      this.props.addOrgMember(this.state.newMemberEmail).then(
        function(response) {
          if (response.status === 201) {
            this.setState({
              openDialog: false,
              newMemberEmail: "",
              isLoading: false,
            });
          } else if (
            response.status === 404 &&
            response.data.error_code == "member_does_not_exist"
          ) {
            this.setState({
              openDialog: false,
              isLoading: false,
              openInviteDialog: true,
            });
          } else {
            this.setState({ isLoading: false });
          }
        }.bind(this)
      );
    }
  };

  handleRemoveMember = (memberEmail, refreshAfter = false) => {
    return this.props.removeOrgMember(memberEmail).then(() => {
      if (refreshAfter) this.handleCloseCreatedDialog();
    });
  };

  handleRemoveInvitedUser = (memberEmail) => {
    return this.props.deleteInvitedUserFromOrg(memberEmail).then((response) => {
      if (
        response.status === 400 &&
        response.data.error_code === "account_created"
      ) {
        this.handleOpenCreatedDialog(memberEmail);
      }
    });
  };

  handleInviteUserToOrg = () => {
    this.setState({ isInviteLoading: true });
    this.props.inviteNewUserToOrg(this.state.newMemberEmail).then(
      function(response) {
        if (response.status === 200) {
          this.setState({
            openInviteDialog: false,
            newMemberEmail: "",
            isInviteLoading: false,
          });
        } else {
          this.setState({ isInviteLoading: false });
        }
      }.bind(this)
    );
  };

  renderAddMemberButton = () => {
    if (isUserInOrg() && isUserOrgOwnerOrAdmin() === false) {
      return;
    }
    const { classes, className, org, ...rest } = this.props;
    return (
      <Button
        className={classes.submitButton}
        color="primary"
        size="small"
        variant="text"
        onClick={this.handleOpenDialog}
      >
        Add Member
      </Button>
    );
  };

  renderMembersTable = () => {
    const { classes, className, org, invitedUsers, ...rest } = this.props;
    const isOwnerOrAdmin = isUserOrgOwnerOrAdmin();

    // filter which members to show based on what is typed into searchbar
    const filteredMemberList =
      org.members == null
        ? []
        : org.members.filter((member) => {
            const lowerCaseSearchValue = this.state.searchValue.toLowerCase();
            let toDisplay = member.name
              .toLowerCase()
              .includes(lowerCaseSearchValue);
            toDisplay =
              toDisplay ||
              member.email.toLowerCase().includes(lowerCaseSearchValue);
            return toDisplay;
          });

    const tableData = [...filteredMemberList, ...invitedUsers];

    return (
      <div className={classes.table}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: "Name",
              field: "name",
              width: "25%",
              cellStyle: { lineHeight: "20px" },
            },
            {
              title: "Email",
              field: "email",
              width: "25%",
              cellStyle: { lineHeight: "20px" },
            },
            {
              title: "Role",
              field: "role",
              width: "25%",
              cellStyle: { lineHeight: "20px" },
            },
          ]}
          actions={[
            (rowData) => ({
              icon: () => {
                if (rowData.role !== ROLES[3]) {
                  return <PersonAddIcon color="primary" />;
                }
                return <PersonAddDisabledIcon color="primary" />;
              },
              tooltip:
                rowData.role !== ROLES[3]
                  ? "Make user an admin"
                  : "Remove user's admin privileges",
              onClick: (_event, rowData) => {
                if (rowData.role !== ROLES[3]) {
                  this.props.updateOrgMemberRole(
                    rowData.email,
                    ROLES_INVERTED["Admin"]
                  );
                } else {
                  this.props.updateOrgMemberRole(
                    rowData.email,
                    ROLES_INVERTED["Member"]
                  );
                }
              },
              hidden:
                rowData.email === this.userEmail ||
                !isOwnerOrAdmin ||
                rowData.role === ROLES[1] ||
                rowData.role === ROLES[4],
            }),
            (rowData) => ({
              icon: () => <DeleteIcon color="primary" />,
              tooltip: "Remove user from organization",
              onClick: (_event, rowData) => {
                if (rowData.role === ROLES[4]) {
                  this.handleRemoveInvitedUser(rowData.email);
                } else {
                  this.handleRemoveMember(rowData.email);
                }
              },
              hidden:
                rowData.email === this.userEmail ||
                !isOwnerOrAdmin ||
                rowData.role === ROLES[1],
            }),
          ]}
          data={tableData}
          options={{
            rowStyle: {
              fontFamily: "Roboto",
              fontSize: "14px",
            },
            search: false,
            header: true,
            paging: true,
            actionsColumnIndex: -1,
            pageSize: 5,
            emptyRowsWhenPaging: false,
            toolbar: false,
          }}
          localization={{
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage:
                org.members != null && org.members.length > 0
                  ? "No members match the search result"
                  : "No members in the organization",
            },
          }}
          style={{
            boxShadow: "none",
          }}
        />
      </div>
    );
  };

  renderAccountCreatedDialog = () => {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.state.openCreatedDialog}
        onClose={this.handleCloseCreatedDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.dialogCaption} variant="h6">
          Can't remove this invitation because the user has created an account.
          Would you like to remove the user from the org instead?
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleCloseCreatedDialog}>
            No
          </Button>
          <Button
            color="primary"
            onClick={() =>
              this.handleRemoveMember(this.state.createdEmail, true)
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderInviteUserDialog = () => {
    const { classes, className, bookmarks, ...rest } = this.props;

    return (
      <Dialog
        open={this.state.openInviteDialog}
        onClose={this.handleCloseInviteDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Invite User To Join Sonero
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.dialogCaption} variant="h6">
            The user {this.state.newMemberEmail} does not have a Sonero account.
            Would you like us to send an email inviting them to join Sonero?
            <br />
            <br />
            Once they create an account, they will be added to your organization
            automatically.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseInviteDialog} color="primary">
            Cancel
          </Button>
          {this.state.isInviteLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={this.handleInviteUserToOrg} color="primary">
              Yes, Send Invite
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  renderDialog = () => {
    const { classes, className, bookmarks, ...rest } = this.props;

    return (
      <Dialog
        open={this.state.openDialog}
        onClose={this.handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Team Member</DialogTitle>
        <DialogContent>
          <Typography className={classes.dialogCaption} variant="h6">
            You can add team members using their email address. The email
            address should match the organization domain.
          </Typography>
          <TextField
            className={classes.textFieldDescription}
            label="Enter email address"
            onChange={(event) =>
              this.handleEmailAddressChange(event.target.value)
            }
            required
            value={this.state.newMemberEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            Cancel
          </Button>
          {this.state.isLoading ? (
            <CircularProgress size="1rem" className={classes.progress} />
          ) : (
            <Button onClick={this.handleSaveMember} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div>
        <Portlet {...rest} className={rootClassName}>
          <PortletHeader>
            <PortletLabel title="My Organization" />
            {this.renderAddMemberButton()}
          </PortletHeader>
          <PortletContent className={classes.content} noPadding>
            <SearchBar
              className={classes.searchBar}
              value={this.state.searchValue}
              onChange={(newValue) => this.setState({ searchValue: newValue })}
              onCancelSearch={() => this.setState({ searchValue: "" })}
              placeholder="Search for organization members"
            />
            {this.renderMembersTable()}
          </PortletContent>
        </Portlet>
        {this.renderDialog()}
        {this.renderInviteUserDialog()}
        {this.renderAccountCreatedDialog()}
      </div>
    );
  }
}

OrgMembersTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrgMembersTable);
