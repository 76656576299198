import React from "react";
import PropTypes from "prop-types";
import { getLabelGenerator, DotsItem, useTheme } from "@nivo/core";
import { CustomSymbol } from "./CustomSymbol";

export const CustomPointsLayer = ({
  points,
  symbol,
  pointSize,
  pointBorderWidth,
  enableLabel,
  label,
  labelYOffset,
}) => {
  const theme = useTheme();
  const getLabel = getLabelGenerator(label);

  /**
   * We reverse the `points` array so that points from the lower lines in stacked lines
   * graph are drawn on top. See https://github.com/plouc/nivo/issues/1051.
   */
  const mappedPoints = points.map((point) => {
    const mappedPoint = {
      serieId: point.serieId,
      id: point.id,
      x: point.x,
      y: point.y,
      datum: point.data,
      fill: point.color,
      stroke: point.borderColor,
      label: enableLabel ? getLabel(point.data) : null,
    };
    return mappedPoint;
  });
  return mappedPoints.map((point) => (
    <>
      {point.serieId === "moments" ? (
        <g transform={`translate(${point.x},${point.y})`}>
          <CustomSymbol
            size="14"
            color="#45B880"
            borderWidth="2"
            borderColor="#4c3ca6"
          />
        </g>
      ) : (
        <DotsItem
          key={point.id}
          x={point.x}
          y={point.y}
          datum={point.datum}
          symbol={symbol}
          size={pointSize}
          color={point.fill}
          borderWidth={pointBorderWidth}
          borderColor={point.stroke}
          label={point.label}
          labelYOffset={labelYOffset}
          theme={theme}
        />
      )}
    </>
  ));
};

CustomPointsLayer.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object),
  symbol: PropTypes.func,
  size: PropTypes.number.isRequired,
  color: PropTypes.func.isRequired,
  borderWidth: PropTypes.number.isRequired,
  borderColor: PropTypes.func.isRequired,
  enableLabel: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  labelYOffset: PropTypes.number,
};
