import { fade } from "@material-ui/core";

export default (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
    position: "relative",
  },
  content: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progressWrapper: {
    paddingTop: "48px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  snackbar: {
    height: "auto",
    fontSize: 16,
    lineHeight: "25px",
    whiteSpace: "pre-line",
  },
  editIcon: {
    color: theme.palette.text.secondary,
  },
  destructiveActionIcon: {
    color: theme.palette.danger.mellow,
  },
  infoBanner: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    fontSize: "16px",
    border: `1px solid ${theme.palette.border}`,
    borderRadius: "10px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  upgradeOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "calc(100vh - 63px)", // 63px is the height of the topbar
    backgroundColor: fade(theme.palette.background.lightGrey, 0.75),
    display: "grid",
    placeItems: "center",
  },
  upgradeDialog: {
    maxWidth: "480px",
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 6px 9px 3px ${theme.palette.background.grey}`,
    borderRadius: "10px",
  },
  upgradeIcon: {
    display: "block",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    color: theme.palette.success.main,
    border: `4px solid ${theme.palette.success.main}`,
    margin: `${theme.spacing(5)}px auto ${theme.spacing(2)}px`,
  },
  upgradeTitle: {
    maxWidth: "fit-content",
    margin: `${theme.spacing(2)}px auto`,
    padding: `0 ${theme.spacing(6)}px`,
    fontSize: "18px",
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: "center",
  },
  upgradeBody: {
    fontWeight: 400,
    margin: `${theme.spacing(2)}px auto ${theme.spacing(4)}px`,
    padding: `0 ${theme.spacing(6)}px`,
  },
  upgradeButton: {
    display: "block",
    width: "fit-content",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: `${theme.spacing(1)}px auto ${theme.spacing(5)}px`,
  },
});
