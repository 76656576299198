export const fillerFeedback = {
  bad: {
    getText: (name, fillerTotal, fillerRate) => `${name} used ${fillerTotal} fillers at an average of ${fillerRate} per minute. That’s a lot and it gets in the way of efficient communication. Try replacing fillers with simple pauses!`,
    className: 'badFillers',
    title: 'TOO MANY!',
  },
  good: {
    getText: (name, fillerTotal, fillerRate) => `${name} used ${fillerTotal} fillers at an average of ${fillerRate} per minute. That’s not too many but if possible, try eliminating them all together by replacing them with meaningful pauses.`,
    className: 'goodFillers',
    title: 'GOOD!',
  },
  great: {
    getText: (name) => `${name} had no filler words. Excellent work!`,
    className: 'goodFillers',
    title: 'GREAT!',
  },
};
