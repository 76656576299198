export default theme => ({
    root: {},
    portletFooter: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    caption: {
        color: theme.palette.text.secondary
    },
    title: {
        color: theme.palette.text.secondary,
        fontWeight: 700,
        marginTop: theme.spacing.unit * 2
    },
    resultScore: {
        display: 'flex',
        justifyContent: "space-between"
    },
    score: {
        color: theme.palette.text.secondary,
        fontWeight: 700,
        marginTop: theme.spacing.unit * 2
    },
    progress: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2
    },
    resultContent: {
        display: 'table-row',
        marginTop: theme.spacing.unit * 2
    },
    field: {
        marginTop: theme.spacing.unit * 2
    },
    textField: {
        width: '100%'
    },
    contentBody: {
        height: '100%',
        width: '100%',
        display: 'table'
    }
  });