import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  CircularProgress,
  Typography,
  Button,
  Snackbar,
} from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { TrackerDialog } from "./components";

// Shared services
import { getTrackers, deleteTracker } from "services/speech";

// Shared components
import { Portlet, PortletContent, PortletLabel } from "components";

// Component styles
import styles from "./styles";

import ReactGA from "react-ga";

import MaterialTable, { MTableToolbar } from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Delete,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  ViewColumn,
  ArrowUpward,
} from "@material-ui/icons";
import DeleteDialog from "components/DeleteDialog";
import { verifyUserPricingTier, pricingTiers } from "helpers/pricingTiers";

const tableIcons = {
  Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

class Trackers extends Component {
  state = {
    isLoading: false,
    trackers: [],
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    openEditDialog: false,
    openDeleteDialog: false,
    openUpgradeDialog: false,
    tracker: null,
  };

  componentWillMount() {
    const isFreeTier = verifyUserPricingTier(pricingTiers.free);
    if (isFreeTier) {
      this.setState({ openUpgradeDialog: true });
    } else {
      this.getTrackers();
    }
  }

  handleOpenNewTracker = () => {
    this.setState({
      tracker: null,
      openCreateTrackerDialog: true,
    });
    ReactGA.event({
      category: "Trackers",
      action: "User clicked new tracker button",
    });
  };

  renderTrackersTable() {
    const { classes } = this.props;
    const { isLoading, trackers } = this.state;

    if (isLoading) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </PortletContent>
        </Portlet>
      );
    }

    const actions = [
      {
        icon: () => <Edit className={classes.editIcon} />,
        tooltip: "Edit Tracker",
        onClick: (event, rowData) =>
          this.setState({
            openCreateTrackerDialog: true,
            tracker: rowData,
          }),
      },
      {
        icon: () => <Delete className={classes.destructiveActionIcon} />,
        tooltip: "Delete Tracker",
        onClick: (event, rowData) =>
          this.setState({
            openDeleteDialog: true,
            tracker: rowData,
          }),
      },
    ];

    if (trackers.length === 0) {
      return (
        <Portlet>
          <PortletContent className={classes.content}>
            <Typography className={classes.caption} variant="body1">
              You don't have any trackers, add one to get started!
            </Typography>
            <Button
              onClick={this.handleOpenNewTracker}
              color="primary"
              variant="contained"
              size="small"
            >
              New Tracker
            </Button>
          </PortletContent>
        </Portlet>
      );
    }

    const myTableRef = React.createRef();

    return (
      <Portlet>
        <MaterialTable
          tableRef={myTableRef}
          icons={tableIcons}
          title={<PortletLabel title="" />}
          columns={[
            {
              title: "Tracker",
              field: "title",
              render: (rowData) => this.getTitle(rowData.name, classes),
            },
            {
              title: "Added By",
              field: "owner",
              render: (rowData) =>
                this.renderOwnerColumn(rowData.owner_name, classes),
            },
            {
              title: "Status",
              field: "status",
              render: (rowData) =>
                this.renderStatusColumn(rowData.status, classes),
            },
          ]}
          data={trackers}
          options={{
            actionsColumnIndex: -1,
            rowStyle: {
              fontFamily: "Roboto",
              fontSize: "14px",
            },
            pageSize: 20,
            emptyRowsWhenPaging: false,
            toolbarButtonAlignment: "left",
          }}
          localization={{
            header: {
              actions: "",
            },
            body: {
              emptyDataSourceMessage: "",
            },
            toolbar: {
              searchPlaceholder: "Search",
            },
          }}
          style={{
            boxShadow: "none",
          }}
          actions={actions}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ marginLeft: "24px" }}
                  onClick={this.handleOpenNewTracker}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  New Tracker
                </Button>
                <MTableToolbar
                  {...props}
                  classes={{ root: classes.tableToolbar }}
                />
              </div>
            ),
          }}
        />
      </Portlet>
    );
  }

  getTitle = (name, classes) => {
    return (
      <div>
        <p>{name}</p>
      </div>
    );
  };

  renderOwnerColumn = (owner, classes) => {
    return (
      <div>
        <p>{owner}</p>
      </div>
    );
  };

  renderStatusColumn = (status, classes) => {
    return (
      <div>
        <p>{status ? "Active" : "Inactive"}</p>
      </div>
    );
  };

  renderTrackerDialog = () => {
    const { classes } = this.props;
    var trackerId = null;
    var trackerName = null;
    var status = true;
    if (this.state.tracker) {
      trackerId = this.state.tracker.id;
      trackerName = this.state.tracker.name;
      status = this.state.tracker.status;
    }
    return (
      <TrackerDialog
        classes={classes}
        open={this.state.openCreateTrackerDialog}
        trackerId={trackerId}
        trackerName={trackerName}
        status={status}
        onClose={this.handleCloseTrackerDialog}
        openSnackbar={this.openSnackbar}
        setLoading={this.setLoading}
        handleUpdateTracker={this.handleUpdateTracker}
        handleCreateTracker={this.handleCreateTracker}
      />
    );
  };

  handleCloseTrackerDialog = () => {
    this.setState({ openCreateTrackerDialog: false, tracker: null });
  };

  handleCreateTracker = (tracker) => {
    var trackers = this.state.trackers;
    trackers.unshift(tracker);
    this.setState({ trackers: trackers });
  };

  handleUpdateTracker = (tracker) => {
    var trackers = this.state.trackers.map((object) => {
      if (object.id === tracker.id) return tracker;
      return object;
    });
    this.setState({ trackers: trackers });
  };

  handleDelete = () => {
    if (this.state.openDeleteDialog && this.state.tracker) {
      this.deleteTracker(this.state.tracker);
    }
  };

  handleOpenDeleteDialog = () => {
    this.setState({ openDeleteDialog: true, menuAnchorEl: null });
  };

  renderDeleteDialog = () => {
    const { classes } = this.props;
    var type = "";
    var objectTitle = "";
    if (this.state.tracker) {
      type = "Tracker";
      objectTitle = this.state.tracker.name;
    }

    return (
      <DeleteDialog
        classes={classes}
        open={this.state.openDeleteDialog}
        type={type}
        objectTitle={objectTitle}
        onClose={this.handleCloseDeleteDialog}
        openSnackbar={this.openSnackbar}
        handleDelete={this.handleDelete}
      />
    );
  };

  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  addSnackbar = () => {
    const { classes, className, ...rest } = this.props;
    return (
      <Snackbar
        open={this.state.snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackbarClose}
        ContentProps={{
          className: classes.snackbar,
        }}
        message={this.state.snackbarMessage}
        action={
          <Button
            style={{ color: "white" }}
            size="small"
            onClick={this.handleSnackbarClose}
          >
            CLOSE
          </Button>
        }
      />
    );
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  getTrackers = () => {
    this.setState({ isLoading: true });
    getTrackers().then(
      function(response) {
        if (response.status === 200) {
          var trackers = response.data;

          this.setState({
            isLoading: false,
            trackers: trackers,
          });

          ReactGA.event({
            category: "Trackers",
            action: "Get trackers API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't load your trackers because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Get trackers API failed",
          });
        }
      }.bind(this)
    );
  };

  deleteTracker = (tracker) => {
    this.setState({ isLoading: true, openDeleteDialog: false });
    deleteTracker(tracker.id).then(
      function(response) {
        if (response.status === 200) {
          var trackers = this.state.trackers.filter(
            (obj) => obj.id !== tracker.id
          );

          this.setState({
            isLoading: false,
            trackers,
          });

          ReactGA.event({
            category: "Trackers",
            action: "Delete tracker API success",
          });
        } else if (response.status === 401) {
          this.props.history.push("/sign-in");
        } else {
          this.setState({
            isLoading: false,
            error:
              "Sorry, couldn't delete the tracker because an error occurred",
          });
          ReactGA.event({
            category: "Failure",
            action: "Delete tracker API failed",
          });
        }
      }.bind(this)
    );
  };

  renderUpgradeDialog = () => {
    if (!this.state.openUpgradeDialog) return;

    const { classes } = this.props;
    return (
      <div className={classes.upgradeOverlay}>
        <div className={classes.upgradeDialog}>
          <ArrowUpward className={classes.upgradeIcon} />
          <Typography className={classes.upgradeTitle}>
            Upgrade your account to create and view trackers!
          </Typography>
          <Typography variant="body1" className={classes.upgradeBody}>
            You are on our Free tier.
          </Typography>
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <DashboardLayout title="Manage Trackers">
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography className={classes.infoBanner}>
              <span>
                Trackers automatically identify and extract custom keywords or
                phrases from your meetings.
              </span>
              <span>
                To track keywords or phrases, add them below. To see if they are
                mentioned in your meeting, click on <b>Trackers</b> in
                Conversation Highlights for any meeting output.
              </span>
            </Typography>
            {this.renderTrackersTable()}
          </div>
          {this.renderUpgradeDialog()}
        </div>
        {this.renderTrackerDialog()}
        {this.renderDeleteDialog()}
        {this.addSnackbar()}
      </DashboardLayout>
    );
  }
}

Trackers.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Trackers);
