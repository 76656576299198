export default (theme) => ({
  root: {},
  portletFooter: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gap: theme.spacing.unit,
  },
  cost: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: "50px",
    marginTop: theme.spacing.unit * 2,
  },
  costSubtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit,
  },
  field: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    width: "100%",
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  snackbar: {
    height: "60px",
    fontSize: 16,
  },
  label: {
    color: "#FFF",
  },
  caption: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 3,
  },
  endCaption: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  actionButton: {
    marginLeft: theme.spacing.unit * 2,
    width: "250px",
  },
  contentText: {
    fontSize: "16px",
  },
  cancelButton: {
    color: theme.palette.danger.main,
    width: "250px",
  },
});
