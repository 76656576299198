import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';

// Shared helpers
import { speechTypes } from 'helpers';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

class SpeechesTable extends Component {

  handleSelect = (id) => {
    const { onSelect } = this.props;
    onSelect(id);
  };

  getSpeechType = (type) => {
    let speechType = speechTypes.filter((element) => element.value === type)
    if (speechType.length > 0) {
        return speechType[0].label
    }
    return ""
  }

  getDate = (speech) => {
    var date = speech.created
    if (date != null) {
      var localDate = new Date(date)
      return localDate.toLocaleDateString()
    }
    return ""
  }

  render() {
    const { classes, className, speeches } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="left">
                    Title
                  </TableCell>
                  <TableCell align="left">
                    Type
                  </TableCell>
                  <TableCell align="left">
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {speeches
                  .map(speech => (
                    <TableRow
                      className={classes.tableRow}
                      key={speech.id}
                      hover
                      onClick={() => this.handleSelect(speech.id)}
                    >
                      <TableCell className={classes.tableCell}>
                        <Typography
                            className={classes.nameText}
                            variant="body1"
                        >
                            {speech.title}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography
                            className={classes.nameText}
                            variant="body1"
                        >
                            {this.getSpeechType(speech.speech_type)}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography
                            className={classes.nameText}
                            variant="body1"
                        >
                            {this.getDate(speech)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

SpeechesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  speeches: PropTypes.array.isRequired
};

SpeechesTable.defaultProps = {
  speeches: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

export default withStyles(styles)(SpeechesTable);
